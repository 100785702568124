<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div
    class="row m-0 col-md-12 p-0 formborder d-flex justify-content-between align-items-center"
  >
    <div class="col-md-2">
      <span>To Date</span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="Todate"
          id="Todate"
          required
          #refTodate="ngModel"
          (dateChange)="LMS_Get_Loan_For_Auto_Closer()"
          class="form-control input-text-css"
          [(ngModel)]="Todate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
      <!-- <input
        name="Todate"
        [(ngModel)]="Todate"
        id="Todate"
        required
        #refTodate="ngModel"
        (ngModelChange)="LMS_Get_Loan_For_Auto_Closer()"
        placeholder="To date"
        class="form-control input-text-css"
        bsDatepicker
      /> -->
    </div>
    <div class="col-md-4">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="mt-3">
    <div class="table-responsive">
      <mat-table
        [dataSource]="dataSourceCustomer"
        #sortCustomerList="matSort"
        matSort
        id="exportReport"
        matTableExporter
        #exporter="matTableExporter"
        style="overflow: auto; max-width: 100%; height: 60vh"
      >
        <ng-container matColumnDef="LoanId">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 50px"
            *matHeaderCellDef
          >
            #
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px; max-height: 5px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="LoanNo">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 150px"
            *matHeaderCellDef
          >
            Loan No
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
          >
            {{ row.LoanNo }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="ApplicationNo">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 150px"
            *matHeaderCellDef
          >
            Aplication No
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
          >
            {{ row.ApplicationNo }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Product">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Product
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Product }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Branch
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Branch }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="CustomertName">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            *matHeaderCellDef
          >
            Customer Name
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.CustomertName + " " + row.RelationName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PhoneNo">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 150px"
            *matHeaderCellDef
          >
            Phone No
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
          >
            <div *ngIf="currentUser.IsMaskingMobile">
              {{ row.PhoneNo | mask }}
            </div>

            <div *ngIf="!currentUser.IsMaskingMobile">
              {{ row.PhoneNo }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Loan_Date">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 150px"
            *matHeaderCellDef
          >
            Loan Date
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
          >
            {{ row.Loan_Date }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="LoanAmount">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 150px"
            *matHeaderCellDef
          >
            Loan Amount
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
          >
            {{ row.LoanAmount }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="ExpiryDate">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 150px"
            *matHeaderCellDef
          >
            Expiry Date
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
          >
            {{ row.ExpiryDate }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="ODInterestBalance">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 150px"
            *matHeaderCellDef
          >
            OD Interest Balance
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell"
            style="max-width: 150px"
          >
            {{ row.ODInterestBalance }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="EditAction">
          <mat-header-cell
            mat-sort-header
            class="grid-header"
            style="max-width: 100px"
            *matHeaderCellDef
          >
            View Detail
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            class="grid-cell j-c-center"
            style="max-width: 100px"
          >
            <i
              class="fa fa-arrow-circle-right arrow-circle action-btn"
              (click)="goToDetail(row)"
              aria-label="true"
            ></i>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedCustomerColumns; sticky: true"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedCustomerColumns"
        ></mat-row>
      </mat-table>
      <div class="row m-0 align-items-center">
        <div class="col-md-4">
          <button class="btn font-size-12 button-btn" (click)="exportTable()">
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-8">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="lms_loan_auto_closer"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No.
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Relation Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Phone No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Expiry Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OD Interest Balance
    </th>
  </tr>
  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanNo }}</td>
    <td>{{ x.ApplicationNo }}</td>
    <td>{{ x.Product }}</td>

    <td>{{ x.Branch }}</td>
    <td>{{ x.CustomertName }}</td>
    <td>{{ x.RelationName }}</td>
    <td>
      <div *ngIf="currentUser.IsMaskingMobile">
        {{ x.PhoneNo | mask }}
      </div>

      <div *ngIf="!currentUser.IsMaskingMobile">
        {{ x.PhoneNo }}
      </div>
    </td>
    <td>{{ x.LoanAmount }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.ExpiryDate }}</td>
    <td>{{ x.ODInterestBalance }}</td>
  </tr>
</table>
