import { Component, OnInit, ViewChild } from "@angular/core";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { DialogService } from "../../Shared/dialog.service";
declare var $: any;

@Component({
  selector: "app-mst-role-master",
  templateUrl: "./mst-role-master.component.html",
  styleUrls: ["./mst-role-master.component.scss"],
})
export class MstRoleMasterComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  RoleList: any = [];
  dataSource: any;
  dataSourceRole: any;
  loading: boolean = false;

  RoleModel: any = { Designation: "", RoleName: "", MenuType: "" };
  DesigDropdown: any = [];
  MenuList: any = [];

  dialogreturn: any;
  Role_Id: any = 0;
  BtnName: any;
  InputName: any;

  ActionModel: any = { View: "", Add: "", Edit: "", Delete: "", Print: "" };

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = [
    "Id",
    "Role_Name",
    "Designation_Name",
    "Action",
  ];
  displayedColumnsRole: string[] = [
    "Id",
    "MM_MenuFor",
    "MM_Name",
    "Page_Name",
    "View",
    "Add",
    "Edit",
    "Delete",
    "Print",
  ];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  constructor(
    private dataSharingService: DataSharingService,
    private dialog: DialogService,
    public snackBar: MatSnackBar,
    private _MasterService: MasterService
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Role Master");
    this.getRoleList();
    this.GetDesignationList();
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  GetDesignationList() {
    this._MasterService.GetRoleForDropdown().subscribe((result) => {
      this.DesigDropdown = JSON.parse(JSON.stringify(result));
    });
  }

  getRoleList() {
    this.showSpinner = true;
    this._MasterService
      .Get_Roal_Designation_For_List({})
      .subscribe((result) => {
        this.RoleList = JSON.parse(JSON.stringify(result));
        this.dataSource = new MatTableDataSource(this.RoleList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
  }

  onAddRole() {
    this.dataSourceRole = "";
    this.RoleModel = { Designation: "", RoleName: "", MenuType: "" };
    this.ActionModel = {
      View: false,
      Add: false,
      Edit: false,
      Delete: false,
      Print: false,
    };
    this.MenuList = [];
    this.BtnName = "Save";
    $("#AddNewRoleModal").modal("show");
    $("#AddNewRoleModal").css("z-index", "1050");
  }
  onSaveRoleMaster() {
    this._MasterService
      .Save_Role_Designation({
        RoleId: this.Role_Id,
        Desig_Id: this.RoleModel.Designation,
        RoleName: this.RoleModel.RoleName,
        LoginUserId: this.currentUser.userId,
        JSON: JSON.stringify(this.MenuList),
      })
      .subscribe((response) => {
        this.MenuList = [];
        if (response[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getRoleList();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getRoleList();
          this.OnClose();
        }
        this.Role_Id = 0;
      });
  }

  applyFilterDesignation(filterValue: string) {
    this.dataSourceRole.filter = filterValue.trim().toLowerCase();
  }

  OnEditRoleData(row: any) {
    this.BtnName = "Update";
    $("#AddNewRoleModal").modal("show");
    $("#AddNewRoleModal").css("z-index", "1050");
    this.Role_Id = row.id;
    this.RoleModel = {
      Designation: row.Desig_Id,
      RoleName: row.Role_Name,
      MenuType: "",
    };
    this._MasterService
      .Get_Page_Role_Rights({ RoleId: row.id })
      .subscribe((result) => {
        this.MenuList = JSON.parse(JSON.stringify(result));
        this.dataSourceRole = new MatTableDataSource(this.MenuList);
        if (this.MenuList.length == 0) this.getMenuListByRole();
      });
  }

  OnDeleteRoleData(row: any) {
    this.dialogreturn = this.dialog.openConfirmDialog(
      "Are you sure you want to delete?"
    );
    this.dialogreturn.afterClosed().subscribe((dialogResult) => {
      if (dialogResult == true) {
        this._MasterService
          .Delete_Role_Designation({ RoleId: row.id })
          .subscribe((response) => {
            if (response[0].CODE == 0) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
              this.getRoleList();
            } else {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: response[0].MSG,
                ...this.configSuccess,
              });
            }
          });
      }
    });
  }

  OnClose() {
    $("#AddNewRoleModal").modal("hide");
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getMenuListByRole() {
    this.MenuList = [];
    this._MasterService
      .Get_MenuPages_By_Role({ Desig_Id: this.RoleModel.Designation })
      .subscribe((result) => {
        this.MenuList = JSON.parse(JSON.stringify(result));
        this.MenuList.forEach((x) => {
          x.View = false;
          x.Add = false;
          x.Edit = false;
          x.Delete = false;
          x.Print = false;
        });
        this.dataSourceRole = new MatTableDataSource(this.MenuList);
      });
  }

  getMenuListByType() {
    this.dataSourceRole = new MatTableDataSource(this.MenuList);
    this.ActionModel = {
      View: false,
      Add: false,
      Edit: false,
      Delete: false,
      Print: false,
    };
    if (this.RoleModel.MenuType == "") {
      this.dataSourceRole = new MatTableDataSource(this.MenuList);
    } else {
      if (this.dataSourceRole.filteredData.length > 0) {
        this.dataSourceRole = this.dataSourceRole.filteredData.filter(
          (item) => item.MM_MenuFor == this.RoleModel.MenuType
        );
      }
    }
  }

  onCheckboxChange(row: any) {
    if (row.Add || row.Edit || row.Delete) {
      row.View = true;
    }

    if (!row.Add && !row.Edit && !row.Delete) {
      row.View = false;
    }
  }

  onViewChange(row: any) {
    if (!row.View) {
      row.Add = false;
      row.Edit = false;
      row.Delete = false;
    }
  }

  checkUncheckAll(evt: any) {
    let type = evt.source.name;
    const isChecked = evt.checked;

    this.dataSourceRole = new MatTableDataSource(this.MenuList);
    if (this.RoleModel.MenuType !== "") {
      this.dataSourceRole = this.dataSourceRole.filteredData.filter(
        (item) => item.MM_MenuFor === this.RoleModel.MenuType
      );
    }

    this.dataSourceRole.filteredData.forEach((item) => {
      item[type] = isChecked;
      if (type === "Add" || type === "Edit" || type === "Delete") {
        item.View = item.Add || item.Edit || item.Delete;
      }
    });
  }
}
