<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form #df="ngForm" novalidate (keyup.enter)="onSearchLendingAssign()">
  <div class="row m-0 col-md-12 p-0 formborder d-flex justify-content-between align-items-center">
    <div class="col-md-2">
      <span class="required-lable">Report Date</span>
      <div class="datepicker_feild">
        <input [matDatepicker]="picker"
               dateConvert
               placeholder="DD/MM/YYYY"
               name="ReportDate"
               id="ReportDate"
               required
               #refReportDate="ngModel"
               class="form-control input-text-css"
               [(ngModel)]="LendingModel.ReportDate"
               [ngClass]="{
            'is-invalid': df.submitted && refReportDate.invalid,
            'alert-warning':
              refReportDate.invalid &&
              (refReportDate.dirty ||
                refReportDate.touched ||
                refReportDate.untouched)
          }" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
      <!-- <input
        name="ReportDate"
        id="ReportDate"
        required
        #refReportDate="ngModel"
        placeholder="Report Date"
        class="form-control input-text-css"
        bsDatepicker
        [(ngModel)]="LendingModel.ReportDate"
        [ngClass]="{
          'is-invalid': df.submitted && refReportDate.invalid,
          'alert-warning':
            refReportDate.invalid &&
            (refReportDate.dirty ||
              refReportDate.touched ||
              refReportDate.untouched)
        }"
      /> -->
    </div>
    <div class="col-md-4">
      <span class="required-lable">Loan Assign</span>
      <input type="text"
             [formControl]="AccountControl"
             [matAutocomplete]="auto"
             class="form-control input-text-css"
             [(ngModel)]="chhead"
             name="chhead"
             required
             [class.alert-warning]="chhead == ''"
             placeholder="Account Head" />
      <mat-autocomplete #auto="matAutocomplete"
                        [displayWith]="displayFn"
                        (optionSelected)="onAccountHeadhSelect($event)">
        <mat-option *ngFor="let account of filteredAccountHead | async"
                    [value]="account">
          {{ account.Account_Name }}
        </mat-option>
      </mat-autocomplete>
      <!-- <select name="AssignToCompany" id="AssignToCompany" #refAssignToCompany="ngModel"
        [ngClass]="{ 'is-invalid': df.submitted && refAssignToCompany.invalid, 'alert-warning': refAssignToCompany.invalid  && (refAssignToCompany.dirty || refAssignToCompany.touched || refAssignToCompany.untouched) }"
        [(ngModel)]="LendingModel.AssignToCompany" class="form-control input-text-css" required>
        <option value=""> Assign Type</option>
        <option value="All">All</option>
        <option value="Pending">Pending</option>
        <option value="0">Others</option>
      </select> -->
    </div>

    <div class="col-md-1 mt-3">
      <button type="button"
              (click)="onOpenSearchModal()"
              class="btn font-size-12 button-btn"
              [disabled]="!df.form.valid"
              *ngIf="AccountHead <= 0">
        Filter
      </button>
      <button type="button"
              (click)="onSearchLendingAssign()"
              class="btn font-size-12 button-btn"
              [disabled]="!df.form.valid"
              *ngIf="AccountHead > 0">
        Search
      </button>
    </div>
    <div class="col-md-4 mt-3">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input type="text"
                   id="FilterInputSearch"
                   (keyup)="applyFilter($event.target.value)"
                   name="FilterInputSearch"
                   placeholder="What're you searching for?"
                   class="form-control border-0 bg-light" />
            <div class="input-group-append">
              <button id="button-addon1"
                      type="submit"
                      class="btn btn-link text-primary">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</form>

<div class="mt-2" *ngIf="dataSource">
  <div class="">
    <div class="table-responsive">
      <mat-table class="table-bordered"
                 matSort
                 style="width: 7346px; max-height: 300px; overflow: auto"
                 [dataSource]="dataSource"
                 cdkDropList
                 cdkDropListOrientation="horizontal"
                 (cdkDropListDropped)="DragNDrop($event)"
                 matTableExporter
                 #exporter="matTableExporter"
                 [hiddenColumns]="[
          displayedColumns.indexOf('Select'),
          displayedColumns.indexOf('Loan_Id')
        ]">
        <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns">
          <mat-header-cell mat-sort-header
                           *matHeaderCellDef
                           cdkDrag
                           cdkDragLockAxis="x">
            <div *ngIf="col == 'Select'; else elsediv">
              <mat-checkbox name="Select"
                            id="Select"
                            [(ngModel)]="SelectAll"
                            (change)="checkUncheckAll($event)"
                            [checked]="IsSelected"></mat-checkbox>
            </div>
            <ng-template #elsediv>
              {{
                col == "Loan_Id"
                  ? "#"
                  : col == "LoanAcNo"
                  ? "Loan No"
                  : col == "Application_No"
                  ? "Application No"
                  : col == "Loan_Date"
                  ? "Loan Date"
                  : col == "ExpiryDate"
                  ? "Expiry Date"
                  : col == "LoanClosed_Date"
                  ? "Closed Date"
                  : col == "FirstEMIDate"
                  ? "First EMI Date"
                  : col == "Product_Name"
                  ? "Product"
                  : col == "Branch_Name"
                  ? "Branch"
                  : col == "CollectionExecutive"
                  ? "Collection Executive"
                  : col == "Area_Name"
                  ? "Area"
                  : col == "Sub_Area_Name"
                  ? "Sub Area"
                  : col == "CustomerName"
                  ? "Customer Name"
                  : col == "Customer_PhoneNo"
                  ? "Phone No"
                  : col == "RelationName"
                  ? "Relation Name"
                  : col == "LoanAmount"
                  ? "Loan Amount"
                  : col == "EMI_Frequency"
                  ? "EMI Frequency"
                  : col == "EMIAmount"
                  ? "EMI Amount"
                  : col == "Loan_Tenure"
                  ? "Tenure"
                  : col == "TotalEMI_NOS"
                  ? "Total EMI NOS"
                  : col == "DPD_Days"
                  ? "DPD Days"
                  : col == "TotalEMI_Amt"
                  ? "Total EMI Amt"
                  : col == "MatureEMI_Amt"
                  ? "Mature EMI Amt"
                  : col == "ReceivedEMI_Amt"
                  ? "Received EMI Amt"
                  : col == "OverDueEMI_Amt"
                  ? "OverDue EMI Amt"
                  : col == "FutureEMI_Amt"
                  ? "Future EMI Amt"
                  : col == "TotalEMI_No"
                  ? "Total EMI No"
                  : col == "MatureEMI_No"
                  ? "Mature EMI No"
                  : col == "ReceivedEMI_No"
                  ? "Received EMI No"
                  : col == "OverDueEMI_No"
                  ? "OverDue EMI No"
                  : col == "FutureEMI_No"
                  ? "Future EMI No"
                  : col == "TotalPrincipal_Amt"
                  ? "Total Principal Amt"
                  : col == "PrincipalDUE_Amt"
                  ? "Principal DUE Amt"
                  : col == "PrincipalReceived_Amt"
                  ? "Principal Received Amt"
                  : col == "PrincipalOverdue_Amt"
                  ? "Principal Overdue Amt"
                  : col == "PrincipalFuture_Amt"
                  ? "Principal Future Amt"
                  : col == "TotalInterest_Amt"
                  ? "Total Interest Amt"
                  : col == "InterestDUE_Amt"
                  ? "Interest DUE Amt"
                  : col == "InterestReceived_Amt"
                  ? "Interest Received Amt"
                  : col == "InterestOverdue_Amt"
                  ? "Interest Overdue Amt"
                  : col == "InterestFuture_Amt"
                  ? "Interest Future Amt"
                  : col == "OtherDues_Amt"
                  ? "Other Dues Amt"
                  : col == "Chq_Bounce"
                  ? "Chq Bounce"
                  : col == "Panel_Interest"
                  ? "Panel Interest"
                  : col == "AssignToCompany"
                  ? "Assign To Company"
                  : col == "DisbursmentAmount"
                  ? "DisbursmentAmount"
                  : col == "ROI"
                  ? "ROI"
                  : col == "LTV"
                  ? "LTV"
                  : col == "Gender"
                  ? "Gender"
                  : col
              }}
            </ng-template>
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index"
                    class="grid-cell"
                    [ngClass]="
              col == 'LoanAmount' ||
              col == 'EMIAmount' ||
              col == 'TotalEMI_Amt' ||
              col == 'MatureEMI_Amt' ||
              col == 'ReceivedEMI_Amt' ||
              col == 'OverDueEMI_Amt' ||
              col == 'FutureEMI_Amt' ||
              col == 'TotalPrincipal_Amt' ||
              col == 'PrincipalDUE_Amt' ||
              col == 'PrincipalReceived_Amt' ||
              col == 'PrincipalOverdue_Amt' ||
              col == 'PrincipalFuture_Amt' ||
              col == 'TotalInterest_Amt' ||
              col == 'InterestDUE_Amt' ||
              col == 'InterestReceived_Amt' ||
              col == 'InterestOverdue_Amt' ||
              col == 'InterestFuture_Amt' ||
              col == 'OtherDues_Amt' ||
              col == 'Panel_Interest'
                ? 'text-right'
                : col == 'RelationName'
                ? 'text-center'
                : ''
            ">
            <div *ngIf="col == 'Select'; else elsedivMask1">
              <mat-checkbox name="IsSelected{{ i }}"
                            id="IsSelected{{ i }}"
                            [(ngModel)]="row.IsSelected"
                            [checked]="row.IsSelected"></mat-checkbox>
            </div>
            <ng-template #elsedivMask1>
              <div *ngIf=" col == 'Customer_PhoneNo'; else elseBlock">
                <div *ngIf="row[col] != '' && currentUser.IsMaskingMobile; else elsedivMask2">
                  {{ row[col] |mask }}
                </div>
                <ng-template #elsedivMask2>
                  {{ row[col] }}
                </ng-template>
              </div>
              <ng-template #elseBlock>
                {{ col == "Loan_Id" ? i + 1 : row[col] }}
              </ng-template>
            </ng-template>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef
                           class="grid-header"
                           [ngClass]="
              col == 'LoanAmount' ||
              col == 'EMIAmount' ||
              col == 'TotalEMI_Amt' ||
              col == 'MatureEMI_Amt' ||
              col == 'ReceivedEMI_Amt' ||
              col == 'OverDueEMI_Amt' ||
              col == 'FutureEMI_Amt' ||
              col == 'TotalPrincipal_Amt' ||
              col == 'PrincipalDUE_Amt' ||
              col == 'PrincipalReceived_Amt' ||
              col == 'PrincipalOverdue_Amt' ||
              col == 'PrincipalFuture_Amt' ||
              col == 'TotalInterest_Amt' ||
              col == 'InterestDUE_Amt' ||
              col == 'InterestReceived_Amt' ||
              col == 'InterestOverdue_Amt' ||
              col == 'InterestFuture_Amt' ||
              col == 'OtherDues_Amt' ||
              col == 'Panel_Interest'
                ? 'text-right'
                : col == 'RelationName'
                ? 'text-center'
                : ''
            ">
            {{
              col == "RelationName"
                ? "Total"
                : col == "LoanAmount"
                ? totaltypewise("LoanAmount")
                : col == "EMIAmount"
                ? dataSource
                  ? totaltypewise("EMIAmount")
                  : ""
                : col == "TotalEMI_Amt"
                ? dataSource
                  ? totaltypewise("TotalEMI_Amt")
                  : ""
                : col == "MatureEMI_Amt"
                ? dataSource
                  ? totaltypewise("MatureEMI_Amt")
                  : ""
                : col == "ReceivedEMI_Amt"
                ? dataSource
                  ? totaltypewise("ReceivedEMI_Amt")
                  : ""
                : col == "OverDueEMI_Amt"
                ? dataSource
                  ? totaltypewise("OverDueEMI_Amt")
                  : ""
                : col == "FutureEMI_Amt"
                ? dataSource
                  ? totaltypewise("FutureEMI_Amt")
                  : ""
                : col == "TotalPrincipal_Amt"
                ? dataSource
                  ? totaltypewise("TotalPrincipal_Amt")
                  : ""
                : col == "PrincipalDUE_Amt"
                ? dataSource
                  ? totaltypewise("PrincipalDUE_Amt")
                  : ""
                : col == "PrincipalReceived_Amt"
                ? dataSource
                  ? totaltypewise("PrincipalReceived_Amt")
                  : ""
                : col == "PrincipalOverdue_Amt"
                ? dataSource
                  ? totaltypewise("PrincipalOverdue_Amt")
                  : ""
                : col == "PrincipalFuture_Amt"
                ? dataSource
                  ? totaltypewise("PrincipalFuture_Amt")
                  : ""
                : col == "TotalInterest_Amt"
                ? dataSource
                  ? totaltypewise("TotalInterest_Amt")
                  : ""
                : col == "InterestDUE_Amt"
                ? dataSource
                  ? totaltypewise("InterestDUE_Amt")
                  : ""
                : col == "InterestReceived_Amt"
                ? dataSource
                  ? totaltypewise("InterestReceived_Amt")
                  : ""
                : col == "InterestOverdue_Amt"
                ? dataSource
                  ? totaltypewise("InterestOverdue_Amt")
                  : ""
                : col == "InterestFuture_Amt"
                ? dataSource
                  ? totaltypewise("InterestFuture_Amt")
                  : ""
                : col == "OtherDues_Amt"
                ? dataSource
                  ? totaltypewise("OtherDues_Amt")
                  : ""
                : col == "Panel_Interest"
                ? dataSource
                  ? totaltypewise("Panel_Interest")
                  : ""
                : ""
            }}
          </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row (click)="highlightRow(row)"
                 [class.highlight]="row.Loan_Id == selectedId"
                 *matRowDef="let row; columns: displayedColumns"></mat-row>
        <mat-footer-row class="sticky-footer fontcolor"
                        *matFooterRowDef="displayedColumns; sticky: true"
                        style="background: #4dc3a3"></mat-footer-row>
      </mat-table>
    </div>
    <div class="row m-0 align-items-center" style="background: #fff">
      <div class="col-md-4"></div>
      <div class="col-md-8">
        <mat-paginator #paginatorRef
                       [pageSizeOptions]="[20, 50]"
                       showFirstLastButtons>
        </mat-paginator>
      </div>
    </div>
    <div class="row m-0 pt-2 pb-2 d-flex align-items-center formborder mt-2">
      <div class="col-md-3">
        <button class="btn font-size-12 button-btn" (click)="exportTable()">
          <i class="fa fa-file-export mr-1"
             style="font-size: medium; cursor: pointer"></i>
          Export
        </button>
      </div>
      <div class="col-md-9">
        <form #ddf="ngForm" novalidate (keyup.enter)="OnSaveCollExAssign()">
          <div class="row m-0" *ngIf="AccountHead <= 0">
            <div class="col-md-10">
              <span class="required-lable">Loan Assign To</span>
              <input type="text"
                     [formControl]="AccountControl1"
                     [matAutocomplete]="auto"
                     class="form-control input-text-css"
                     [(ngModel)]="chhead1"
                     name="chhead1"
                     required
                     [class.alert-warning]="chhead1 == ''"
                     placeholder="Loan Assign To" />
              <mat-autocomplete #auto="matAutocomplete"
                                [displayWith]="displayFn1"
                                (optionSelected)="onAccountHeadhSelect1($event)">
                <mat-option *ngFor="let account of filteredAccountHead1 | async"
                            [value]="account">
                  {{ account.Account_Name }}
                </mat-option>
              </mat-autocomplete>
            </div>
            <div class="col-md-2 text-right">
              <button type="button"
                      (click)="OnSaveCollExAssign()"
                      class="btn font-size-12 button-btn mt-3"
                      [disabled]="!ddf.form.valid">
                Assign
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade in"
     id="SearchInfo"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel"
     data-backdrop="false"
     style="background-color: rgba(0, 0, 0, 0.5); overflow: auto">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Search Data
        </h6>
        <button type="button"
                (click)="OnCloseSearchModal()"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 col-md-12 p-1">
          <form #df="ngForm"
                class="row m-0 col-md-12 p-0 formborder"
                (ngSubmit)="onSearchLendingAssign()"
                novalidate>
            <div class="row m-0 col-md-12 p-0">
              <div class="col-md-3">
                <span>Loan Date From</span>
                <div class="datepicker_feild">
                  <input [matDatepicker]="picker2"
                         dateConvert
                         placeholder="DD/MM/YYYY"
                         name="LoanFrom"
                         id="LoanFrom"
                         #refVoucher_Date="ngModel"
                         class="form-control input-text-css"
                         [(ngModel)]="LendingModel.LoanFrom" />
                  <mat-datepicker-toggle matSuffix
                                         [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </div>

                <!-- <input
                  type="text"
                  name="LoanFrom"
                  [(ngModel)]="LendingModel.LoanFrom"
                  id="LoanFrom"
                  placeholder="Loan Date From"
                  #refLoanFrom="ngModel"
                  bsDatepicker
                  class="form-control input-text-css"
                /> -->
              </div>
              <div class="col-md-3">
                <span>Loan Date To</span>
                <div class="datepicker_feild">
                  <input [matDatepicker]="picker3"
                         dateConvert
                         placeholder="DD/MM/YYYY"
                         name="LoanTo"
                         id="LoanTo"
                         #refLoanTo="ngModel"
                         class="form-control input-text-css"
                         [(ngModel)]="LendingModel.LoanTo" />
                  <mat-datepicker-toggle matSuffix
                                         [for]="picker3"></mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                </div>
                <!-- <input
                  type="text"
                  name="LoanTo"
                  [(ngModel)]="LendingModel.LoanTo"
                  id="LoanTo"
                  placeholder="Loan Date To"
                  #refLoanTo="ngModel"
                  bsDatepicker
                  class="form-control input-text-css"
                /> -->
              </div>
              <div class="col-md-3">
                <span>Loan Amount Min</span>
                <input type="text"
                       name="LoanAmountMin"
                       [(ngModel)]="LendingModel.LoanAmountMin"
                       id="LoanAmountMin"
                       placeholder="Loan Amount Min"
                       #refLoanAmountMin="ngModel"
                       class="form-control input-text-css"
                       numbersOnly />
              </div>
              <div class="col-md-3">
                <span>Loan Amount Max</span>
                <input type="text"
                       name="LoanAmountMax"
                       [(ngModel)]="LendingModel.LoanAmountMax"
                       id="LoanAmountMax"
                       placeholder="Loan Amount Max"
                       #refLoanAmountMax="ngModel"
                       class="form-control input-text-css"
                       numbersOnly />
              </div>
              <div class="col-md-3">
                <span>Expiry From</span>
                <div class="datepicker_feild">
                  <input [matDatepicker]="picker4"
                         dateConvert
                         placeholder="DD/MM/YYYY"
                         name="ExpiryFrom"
                         id="ExpiryFrom"
                         #refExpiryFrom="ngModel"
                         class="form-control input-text-css"
                         [(ngModel)]="LendingModel.ExpiryFrom" />
                  <mat-datepicker-toggle matSuffix
                                         [for]="picker4"></mat-datepicker-toggle>
                  <mat-datepicker #picker4></mat-datepicker>
                </div>
                <!-- <input
                  type="text"
                  name="ExpiryFrom"
                  [(ngModel)]="LendingModel.ExpiryFrom"
                  id="ExpiryFrom"
                  placeholder="Expiry From"
                  #refExpiryFrom="ngModel"
                  bsDatepicker
                  class="form-control input-text-css"
                /> -->
              </div>
              <div class="col-md-3">
                <span>Expiry To</span>
                <div class="datepicker_feild">
                  <input [matDatepicker]="picker5"
                         dateConvert
                         placeholder="DD/MM/YYYY"
                         name="ExpiryTo"
                         id="ExpiryTo"
                         #refExpiryTo="ngModel"
                         class="form-control input-text-css"
                         [(ngModel)]="LendingModel.ExpiryTo" />
                  <mat-datepicker-toggle matSuffix
                                         [for]="picker5"></mat-datepicker-toggle>
                  <mat-datepicker #picker5></mat-datepicker>
                </div>
                <!-- <input
                  type="text"
                  name="ExpiryTo"
                  [(ngModel)]="LendingModel.ExpiryTo"
                  id="ExpiryTo"
                  placeholder="Expiry To"
                  #refExpiryTo="ngModel"
                  bsDatepicker
                  class="form-control input-text-css"
                /> -->
              </div>
              <div class="col-md-3">
                <span>IRR From</span>
                <input type="text"
                       name="IrrFrom"
                       [(ngModel)]="LendingModel.IrrFrom"
                       id="IrrFrom"
                       placeholder="IRR From"
                       #refIrrFrom="ngModel"
                       class="form-control input-text-css" />
              </div>
              <div class="col-md-3">
                <span>IRR To</span>
                <input type="text"
                       name="IrrTo"
                       [(ngModel)]="LendingModel.IrrTo"
                       id="IrrTo"
                       placeholder="IRR To"
                       #refIrrTo="ngModel"
                       class="form-control input-text-css" />
              </div>
              <div class="col-md-3">
                <span>Product</span>
                <form [formGroup]="ProductForm">
                  <ng-multiselect-dropdown name="Products"
                                           [placeholder]="'Select Product'"
                                           [data]="ProductDropdown"
                                           formControlName="Products"
                                           [settings]="ProductDropdownSettings"
                                           [(ngModel)]="Products"
                                           (onDeSelect)="onProductsSelect($event)"
                                           (onSelect)="onProductsSelect($event)">
                  </ng-multiselect-dropdown>
                </form>
              </div>
              <div class="col-md-3">
                <span>Branch</span>
                <form [formGroup]="BranchForm">
                  <ng-multiselect-dropdown name="Branches"
                                           [placeholder]="'Select Branch'"
                                           [data]="BrancheDropdown"
                                           formControlName="Branches"
                                           [settings]="BranchDropdownSettings"
                                           [(ngModel)]="Branches"
                                           (onDeSelect)="onBranchSelect($event)"
                                           (onSelect)="onBranchSelect($event)">
                  </ng-multiselect-dropdown>
                </form>
              </div>
              <div class="col-md-3">
                <span>EMI Mature</span>
                <input type="text"
                       name="EMIMature"
                       [(ngModel)]="LendingModel.EMIMature"
                       id="EMIMature"
                       placeholder="EMI Mature"
                       #refEMIMature="ngModel"
                       class="form-control input-text-css"
                       numbersOnly />
              </div>
              <div class="col-md-3">
                <span>Overdue EMI</span>
                <input type="text"
                       name="OverdueEMI"
                       [(ngModel)]="LendingModel.OverdueEMI"
                       id="OverdueEMI"
                       placeholder="Overdue EMI"
                       #refOverdueEMI="ngModel"
                       class="form-control input-text-css"
                       numbersOnly />
              </div>
              <div class="col-md-3">
                <span>DPD Days</span>
                <input type="text"
                       name="DPDDays"
                       [(ngModel)]="LendingModel.DPDDays"
                       id="DPDDays"
                       placeholder="DPD Days"
                       #refDPDDays="ngModel"
                       class="form-control input-text-css"
                       numbersOnly />
              </div>
              <div class="col-md-3">
                <span>Remaning EMI</span>
                <input type="text"
                       name="RemaningEMI"
                       [(ngModel)]="LendingModel.RemaningEMI"
                       id="RemaningEMI"
                       placeholder="Remaning EMI"
                       #refRemaningEMI="ngModel"
                       class="form-control input-text-css"
                       numbersOnly />
              </div>
            </div>
            <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
              <button type="button"
                      (click)="OnCloseSearchModal()"
                      class="btn font-size-12 button-btn mr-3">
                Cancel
              </button>
              <button type="button"
                      (click)="onSearchLendingAssign()"
                      class="btn font-size-12 button-btn">
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<table id="lending_assignment"
       class="table table-bordered bg-white"
       style="font-family: 'Times New Roman', Times, serif; display: none">
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Expiry Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Closed Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">ROI</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">LTV</th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      First EMI Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Area
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Sub Area
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Gender
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Phone No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Relation Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Frequency
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Tenure
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total EMI Nos
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DPD Days
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total EMI Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Mature EMI Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Received EMI Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OverDue EMI Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Future EMI Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total EMI No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Mature EMI No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Received EMI No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OverDue EMI No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Future EMI No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Principal Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principal DUE Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principal Received Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principal OverDue Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principal Future Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Interest Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest DUE Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest Received Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest OverDue Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest Future Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Other Dues Amt
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Chq Bounce
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Panel Interest
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DisbursmentAmount
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Assign to Company
    </th>
  </tr>
  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Application_No }}</td>

    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.ExpiryDate }}</td>
    <td>{{ x.LoanClosed_Date }}</td>
    <td>{{ x.ROI }}</td>
    <td>{{ x.LTV }}</td>

    <td>{{ x.FirstEMIDate }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.CollectionExecutive }}</td>
    <td>{{ x.Area_Name }}</td>
    <td>{{ x.Sub_Area_Name }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.Gender }}</td>
    <td *ngIf="currentUser.IsMaskingMobile">{{ x.Customer_PhoneNo|mask }}</td>
    <td *ngIf="!currentUser.IsMaskingMobile">{{ x.Customer_PhoneNo }}</td>
    <td>{{ x.RelationName }}</td>
    <td>{{ x.LoanAmount }}</td>
    <td>{{ x.EMI_Frequency }}</td>
    <td>{{ x.EMIAmount }}</td>
    <td>{{ x.Loan_Tenure }}</td>
    <td>{{ x.TotalEMI_NOS }}</td>
    <td>{{ x.DPD_Days }}</td>
    <td>{{ x.TotalEMI_Amt }}</td>
    <td>{{ x.MatureEMI_Amt }}</td>
    <td>{{ x.ReceivedEMI_Amt }}</td>
    <td>{{ x.OverDueEMI_Amt }}</td>
    <td>{{ x.FutureEMI_Amt }}</td>
    <td>{{ x.TotalEMI_No }}</td>
    <td>{{ x.MatureEMI_No }}</td>
    <td>{{ x.ReceivedEMI_No }}</td>
    <td>{{ x.OverDueEMI_No }}</td>
    <td>{{ x.FutureEMI_No }}</td>
    <td>{{ x.TotalPrincipal_Amt }}</td>
    <td>{{ x.PrincipalDUE_Amt }}</td>
    <td>{{ x.PrincipalReceived_Amt }}</td>
    <td>{{ x.PrincipalReceived_Amt }}</td>
    <td>{{ x.PrincipalOverdue_Amt }}</td>
    <td>{{ x.PrincipalFuture_Amt }}</td>
    <td>{{ x.TotalInterest_Amt }}</td>
    <td>{{ x.InterestDUE_Amt }}</td>
    <td>{{ x.InterestReceived_Amt }}</td>
    <td>{{ x.InterestOverdue_Amt }}</td>
    <td>{{ x.InterestFuture_Amt }}</td>
    <td>{{ x.OtherDues_Amt }}</td>
    <td>{{ x.Chq_Bounce }}</td>
    <td>{{ x.Panel_Interest }}</td>
    <td>{{ x.DisbursmentAmount }}</td>
    <td>{{ x.AssignToCompany }}</td>
  </tr>
  <tfoot>
    <tr>
      <td colspan="17"
          style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <b>Total:</b>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("LoanAmount") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong> </strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong> {{ totaltypewise("EMIAmount") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <b></b>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <b></b>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <b></b>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("TotalEMI_Amt") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("MatureEMI_Amt") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("ReceivedEMI_Amt") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("OverDueEMI_Amt") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("FutureEMI_Amt") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <b></b>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong></strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong></strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong></strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "></td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("TotalPrincipal_Amt") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("PrincipalDUE_Amt") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("PrincipalReceived_Amt") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("PrincipalOverdue_Amt") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("PrincipalFuture_Amt") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("TotalInterest_Amt") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("InterestDUE_Amt") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("InterestReceived_Amt") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("InterestOverdue_Amt") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("InterestFuture_Amt") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong></strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong></strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong>{{ totaltypewise("Panel_Interest") }}</strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong></strong>
      </td>
      <td style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        ">
        <strong></strong>
      </td>
    </tr>
  </tfoot>
</table>
