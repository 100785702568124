<div class="grid-container">
  <div style="text-align: center; font-size: 12px; margin-bottom: 10px">
    <span class="titleHeadP">Income Details</span>
  </div>
  <div class="grid-row-con">
    <div class="grid-items">
      <div
        class="grid-sub-item-value font-weight-bold"
        style="width: 200px !important"
      >
        Customer Name
      </div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold">Income Type</div>
    </div>

    <div class="grid-items">
      <div class="grid-items font-weight-bold">Remarks</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold">Amount</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold">Considered</div>
    </div>

    <div class="grid-items">
      <div class="grid-items font-weight-bold">Attachement</div>
    </div>
  </div>

  <div class="mt-1 grid-row-con" *ngFor="let row of IncomeModel">
    <div class="grid-items">
      <div class="grid-sub-item-value" style="width: 200px !important">
        {{ row.CustomerName }}
      </div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value">{{ row.Inc_and_Exp_Type }}</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value">{{ row.Remark }}</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value text-center">{{ row.Amount }}</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value text-center">
        {{ row.FOIR_Amount }}
      </div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value">
        {{ row.Doc_FileName }}
      </div>
    </div>
  </div>

  <div class="mt-1 grid-row-con">
    <div class="grid-items">
      <div
        class="grid-items font-weight-bold"
        style="width: 200px !important"
      ></div>
    </div>
    <div class="grid-items">
      <div class="grid-items font-weight-bold"></div>
    </div>

    <div class="grid-items">
      <div class="grid-items font-weight-bold text-center">Total:</div>
    </div>
    <div class="grid-items">
      <div class="grid-items font-weight-bold text-center">
        {{ totalMonthlyIncomeCalculated?.total }}
      </div>
    </div>

    <div class="grid-items">
      <div class="grid-items font-weight-bold text-center">
        {{ totalCalculator?.total }}
      </div>
    </div>
    <div class="grid-items">
      <div class="grid-items font-weight-bold"></div>
    </div>
  </div>

  <div>
    <hr />
  </div>

  <div style="text-align: center; font-size: 12px; margin-bottom: 10px">
    <span class="titleHeadP">Expense Details</span>
  </div>
  <div class="grid-row-con">
    <div class="grid-items">
      <div
        class="grid-sub-item-value font-weight-bold"
        style="width: 200px !important"
      >
        Customer Name
      </div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold">Expense Type</div>
    </div>

    <div class="grid-items">
      <div class="grid-items font-weight-bold">Remarks</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold">Amount</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold">Considered</div>
    </div>

    <div class="grid-items">
      <div class="grid-items font-weight-bold">Attachement</div>
    </div>
  </div>

  <div class="mt-1 grid-row-con" *ngFor="let row of ExpenseModel">
    <div class="grid-items">
      <div class="grid-sub-item-value" style="width: 200px !important">
        {{ row.CustomerName }}
      </div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value">{{ row.Inc_and_Exp_Type }}</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value">{{ row.Remark }}</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value">{{ row.Amount }}</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value text-center">
        {{ row.FOIR_Amount }}
      </div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value text-center">
        {{ row.Doc_FileName }}
      </div>
    </div>
  </div>

  <div class="mt-1 grid-row-con">
    <div class="grid-items">
      <div
        class="grid-items font-weight-bold"
        style="width: 200px !important"
      ></div>
    </div>
    <div class="grid-items">
      <div class="grid-items font-weight-bold"></div>
    </div>

    <div class="grid-items">
      <div class="grid-items font-weight-bold text-center">Total:</div>
    </div>
    <div class="grid-items">
      <div class="grid-items font-weight-bold">
        {{ totalMonthlyExpenseCalculated?.total }}
      </div>
    </div>

    <div class="grid-items">
      <div class="grid-items font-weight-bold text-center">
        {{ totalCalculator1?.expAmount }}
      </div>
    </div>
  </div>

  <div>
    <hr />
  </div>

  <div style="text-align: center; font-size: 12px; margin-bottom: 10px">
    <span class="titleHeadP">Obligation Details</span>
  </div>

  <div class="grid-row-con">
    <div class="grid-items">
      <div
        class="grid-sub-item-value font-weight-bold"
        style="width: 200px !important"
      >
        Customer
      </div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold">Obligation Type</div>
    </div>

    <div class="grid-items">
      <div class="grid-items font-weight-bold">Obligation From</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold">Obligation</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold">Considered</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value font-weight-bold">Remarks</div>
    </div>

    <div class="grid-items">
      <div class="grid-items font-weight-bold">Document</div>
    </div>
  </div>

  <div class="mt-1 grid-row-con" *ngFor="let row of ObligationModel">
    <div class="grid-items">
      <div class="grid-sub-item-value" style="width: 200px !important">
        {{ row.CustomerName }}
      </div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value">{{ row.Type }}</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value">{{ row.Inc_and_Exp_From }}</div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value">{{ row.Amount }}</div>
    </div>
    <div class="grid-items">
      <div class="grid-sub-item-value text-center">
        {{ row.FOIR_Amount }}
      </div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value text-center">
        {{ row.Remarks }}
      </div>
    </div>

    <div class="grid-items">
      <div class="grid-sub-item-value text-center">
        {{ row.Doc_FileName }}
      </div>
    </div>
  </div>

  <div class="mt-1 grid-row-con">
    <div class="grid-items">
      <div
        class="grid-items font-weight-bold"
        style="width: 200px !important"
      ></div>
    </div>
    <div class="grid-items">
      <div class="grid-items font-weight-bold"></div>
    </div>

    <div class="grid-items">
      <div class="grid-items font-weight-bold text-center">Total:</div>
    </div>
    <div class="grid-items">
      <div class="grid-items font-weight-bold">
        {{ totalMonthlyObligationCalculated?.total }}
      </div>
    </div>

    <div class="grid-items">
      <div class="grid-items font-weight-bold text-center">
        {{ totalCalculatorObligation?.total }}
      </div>
    </div>
  </div>

  <div>
    <hr />
  </div>

  <div style="text-align: center; font-size: 12px; margin-bottom: 10px">
    <span class="titleHeadP">Ratio Analysis</span>
  </div>

  <div class="mt-2">
    <table class="table table-bordered font-size-12">
      <thead>
        <tr>
          <th colspan="2" class="text-center">Expenses To Income Ratio</th>
          <th colspan="2" class="text-center">
            Obligation To Gross Income Ratio
          </th>
          <th colspan="2" class="text-center">
            Fixed Obligation to Income Ratio (FOIR)
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center">
         
          <td>Gross Income</td>
          <td>{{ totalCalculator.total }}</td>
          <td>Gross Income</td>
          <td>{{ totalCalculator.total }}</td>
          <td>Net Income</td>
          <td>{{ totalCalculator.total - totalCalculator1.expAmount }}</td>
        </tr>
        <tr class="text-center">
       
          <td>Gross Expenses</td>
          <td>{{ totalCalculator1.expAmount }}</td>
          <td>Previous Obligation</td>
          <td>{{ totalCalculatorObligation.total }}</td>
          <td>Previous Obligation</td>
          <td>{{ totalCalculatorObligation.total }}</td>
        </tr>
        <tr class="text-center">
         
          <td>Net Income</td>
          <td>{{ totalCalculator.total - totalCalculator1.expAmount }}</td>
          <td>Current Obligation</td>
          <td>{{ CurrentEmi }}</td>
          <td>Current Obligation</td>
          <td>{{ CurrentEmi }}</td>
        </tr>
        <tr class="text-center">
          <td></td>
          <td></td>
          <td>Margin</td>
          <td>
            {{
              totalCalculator.total -
                totalCalculatorObligation.total -
                CurrentEmi
            }}
          </td>
          <td>Margin</td>
          <td>
            {{
              totalCalculator.total -
                totalCalculator1.expAmount -
                totalCalculatorObligation.total -
                CurrentEmi
            }}
          </td>
        </tr>
        <tr
          class="text-center"
          style="font-weight: bold; background-color: #0e5c8654"
        >
          <td>Ratio</td>
          <td>
            {{ getPercentage() }}
            %
          </td>
          <td>Ratio</td>
          <td>
            {{ getObligationPercentage() }} %
            <!-- {{(((TotalObligation)/(totalCalculator.total))*100=='Infinity'?0:((TotalObligation)/(totalCalculator.total))*100).toFixed(2)}}% -->
          </td>
          <td>Ratio</td>
          {{
            getAdjustedObligationPercentage()
          }}
          %
          <!-- <td>{{(((TotalObligation)/(totalCalculator.total-totalCalculator1.expAmount))*100=='Infinity'?0:((TotalObligation)/(totalCalculator.total-totalCalculator1.expAmount))*100).toFixed(2)}}%</td> -->
        </tr>
      </tbody>
    </table>
  </div>
</div>
