import { Component, OnInit, ViewChild } from "@angular/core";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { MasterService } from "../../Shared/app.Masters.Service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { ParamMap, Router, ActivatedRoute } from "@angular/router";
declare var $: any;
import { LmsService } from "../../_Lms/services/lms.service";
import { QuickLoanService } from "../../_QuickLoan/services/quickLoan.service";

@Component({
  selector: "app-quickloan-process-assignment",
  templateUrl: "./quickloan-process-assignment.component.html",
  styleUrls: ["./quickloan-process-assignment.component.scss"],
})
export class QuickloanProcessAssignmentComponent implements OnInit {
  private _MasterService;
  showSpinner: boolean = false;
  currentUser: any;
  FilterProductId: any = "";

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  SelectProductCategory: any = "";
  ProductName: string = "";
  ProductShortName: string = "";
  ProductCategoryDropdown: any = [];

  ProcessDropdown: any = [];
  ProcessId: any = "";
  ProcessAll: any = [];

  SaveBtn: boolean = false;

  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private dialog: DialogService,
    private _EncrDecrService: EncrDecrService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService,
    private lmsService: LmsService,
    private _QuickLoanService: QuickLoanService
  ) {
    this._MasterService = MasterService;
  }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next("QLS Process Assignment");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.route.paramMap.subscribe((param: ParamMap) => {
      this.FilterProductId = this._EncrDecrService.decrypt(
        decodeURIComponent(param.get("Id"))
      );
      console.log("this.FilterProductId", this.FilterProductId);
      if (this.FilterProductId) {
        this.getProductDetail();
        this.QuickLoan_Get_ProcessLevel();
      }
    });
    this.getProductCategory();
  }
  getProductCategory() {
    this._MasterService.GetProductCategory().subscribe((result) => {
      this.ProductCategoryDropdown = JSON.parse(JSON.stringify(result));
    });
  }

  getProductDetail() {
    this._MasterService
      .GetProductListById({ ProductId: this.FilterProductId })
      .subscribe((result) => {
        if (result.length > 0) {
          this.SelectProductCategory = result[0].ProductCatId;
          this.ProductName = result[0].Product;
          this.ProductShortName = result[0].ShortName;
        }
      });
  }

  QuickLoan_Get_ProcessLevel() {
    this._QuickLoanService
      .QuickLoan_Get_ProcessLevel({ ProductId: this.FilterProductId })
      .subscribe((res: any) => {
        console.log("res", res);
        this.ProcessDropdown = res;
      });
  }

  OnChangeProcess() {
    this.ProcessAll = null;
    this.SaveBtn = false;

    this._QuickLoanService
      .QuickLoan_Get_Process_MenuTabs({
        ProductId: this.FilterProductId,
        ProcessId: this.ProcessId,
      })
      .subscribe((res: any) => {
        console.log("this.res", res);
        this.ProcessAll = res;

        this.ProcessAll.forEach((obj: any) => {
          if (obj.IsMandatory && obj.IsApplicable) {
            obj.IsChecked = true;
            this.SaveBtn = false;
          } else if (obj.IsApplicable) {
            obj.IsChecked = true;
            this.SaveBtn = true;
          } else {
            obj.IsChecked = false;
          }
        });
      });
  }

  OnChangeChecked(event, Process) {
    this.SaveBtn = true;
    if (!event.checked) {
      Process.IsOptionalPage = false;
    }

    if (!event.checked) {
      Process.IsLMSOptionalPage = false;
    }
  }

  SaveProcessAssign() {
    let PData = this.ProcessAll.filter(
      (x) => x.IsMandatory == false && x.IsChecked == true
    );
    /*if(PData.length == 0){
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please select atleast Single content.", ...this.configSuccess });
      return;
    }*/

    PData.forEach((obj: any) => {
      delete obj.IsApplicable;
      delete obj.IsChecked;
      delete obj.IsMandatory;
      delete obj.MM_Name;
    });

    let _data = {
      Data: PData,
    };
    console.log("_data", _data);
    this._QuickLoanService
      .QuickLoan_Save_Process_MenuTabs({
        JSON: JSON.stringify(_data),
        ProductId: this.FilterProductId,
        ProcessId: this.ProcessId,
      })
      .subscribe((res: any) => {
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.OnChangeProcess();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
        this.showSpinner = false;
      });
  }
}
