import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { QuickLoanService } from "../services/quickLoan.service";
import { Router, ActivatedRoute } from "@angular/router";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
declare var $: any;
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LosService } from "../../_LOS/services/los.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-quickloan-dashboard",
  templateUrl: "./quickloan-dashboard.component.html",
  styleUrls: ["./quickloan-dashboard.component.scss"],
})
export class QuickLoanDashboardComponent implements OnInit {
  currentUser: any;
  showSpinner: boolean = false;
  loading: boolean = false;
  teamListData: any[] = [];
  dashboardData: any[] = [];
  iconname: string = "../../assets/images/";
  stateDropdown: any[] = [];
  showSelfEnquiry: boolean = false;
  districkDropdown: any[] = [];
  tehasilDropdown: any[] = [];
  inquiryModel: any = {};
  tab: any = "mytask";
  tab1: any;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  leadSourceDropdown: any[] = [];
  AddLoan: boolean = true;
  isSelsPerson: boolean = false;
  partnerDropdown: any[] = [];
  employeeBranchDropdown: any[] = [];
  processListData: any[] = [];
  selectedProcess: any;
  dataProcessSource: any;
  displayedProcessColumns: string[] = [
    "ApplicationId",
    "Application_No",
    "Customer",
    "Product_Name",
    "Branch_Name",
    "EmpName",
    "SourceType",
    "SourceName",
    "AssignOn",
    "AP_ProcessStatus",
    "TAT_Hr",
    "Action",
  ];
  Status = "Pending";
  JsonData: any[] = [];

  JsonDataTeam: any[] = [];
  dataTeamSource: any;
  displayedTeamColumns: string[] = [
    "ApplicationId",
    "Application_No",
    "Customer",
    "Product_Name",
    "Branch_Name",
    "Process_Name",
    "SourceType",
    "SourceName",
    "AssignOn",
    "AP_ProcessStatus",
    "TAT_Hr",
    "Action",
  ];
  selectedTeam: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  //  gradientColors = [
  //    "linear-gradient(135deg, #66a6ff, #89f7fe)",
  //    "linear-gradient(135deg, #ff6b6b, #f5ddb5)",
  //    "linear-gradient(135deg, #4caf50, #a5d6a7)",
  //    "linear-gradient(135deg, #9c27b0, #e1bee7)",
  //    "linear-gradient(135deg, #ffa726, #ffd95b)",
  //    "linear-gradient(135deg, #2196f3, #81d4fa)",
  //    // "linear-gradient(135deg, #d50000, #ff8a80)",
  //    "linear-gradient(135deg, #8bc34a, #c5e1a5)",
  //    "linear-gradient(135deg, #ffab40, #ffdd71)",
  //    "linear-gradient(135deg, #673ab7, #d1c4e9)",
  //    "linear-gradient(135deg, #ff5722, #ffccbc)",
  //    "linear-gradient(135deg, #607d8b, #cfd8dc)",
  //    "linear-gradient(135deg, #ff9800, #ffecb3)",
  //    "linear-gradient(135deg, #3f51b5, #9fa8da)",
  //    "linear-gradient(135deg, #e91e63, #f8bbd0)",
  //];
  gradientColors = [
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(225, 190, 231))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 201 95))",
    "linear-gradient(135deg,  rgb(33 141 134), rgb(226 95 95))",
    "linear-gradient(135deg, rgb(141 21 83), rgb(150 52 186))",
  ];

  constructor(
    private QuickLoanService: QuickLoanService,
    private dataSharingService: DataSharingService,
    private _Route: Router,
    private _MasterService: MasterService,
    private _EncrDecrService: EncrDecrService,
    private losService: LosService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("QuickLoan Dashboard");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (new Date(this.currentUser.SubscriptionDate) <= new Date()) {
      this.AddLoan = false;
    } else {
      this.AddLoan = true;
    }
    this.inquiryModel = {
      Source: "",
      CustomerState: "",
      CustomerDistrict: "",
      CustomerTehsil: "",
      InquirySelfAssigned: false,
      LeadReference: "",
      BranchId: "",
      LoginUserId: this.currentUser.userId,
    };

    this.GetQuickLoanDashboardCount();
    this.getLeadSourceDropdown();
    this.GetStateDropdown();
    this.Get_EmployeeBranchForDropdown();
  }

  GetQuickLoanDashboardCount() {
    this.showSpinner = true;
    this.QuickLoanService.GetQuickLoanDashboardCount({
      LoginUserId: this.currentUser.userId,
    }).subscribe((res: any) => {
      this.dashboardData = res;
      this.showSpinner = false;
    });
  }

  goToPage(data) {
    console.log("data", data);
    if (data.EmpProcessId == 5) {
      this._Route.navigate([
        `quickloan-dashboard/quick-loan/DeviationList/${this._EncrDecrService.encrypt(
          encodeURIComponent(data.EmpProcessId)
        )}`,
      ]);
    } else if (data.EmpProcessId == 13) {
      this._Route.navigate([`quickloan-dashboard/quick-loan/inquiry/`]);
    } else if (data.EmpProcessId == 12) {
      this._Route.navigate([`quickloan-dashboard/quick-loan/my-lead/`]);
    } else {
      this._Route.navigate([
        `quickloan-dashboard/quick-loan/list/${this._EncrDecrService.encrypt(
          encodeURIComponent(data.EmpProcessId)
        )}/${this._EncrDecrService.encrypt(
          encodeURIComponent(data.EmpProcessName)
        )}`,
      ]);
    }
  }
  onOpenNewInquiryModel() {
    if (this.dashboardData.find((item) => item.EmpProcessId == 1)) {
      this.showSelfEnquiry = true;
      $("#addNewInquiry").modal("show");
      $("#addNewInquiry").css("z-index", "1050");
    } else {
      this.showSelfEnquiry = false;
      $("#addNewInquiry").modal("show");
      $("#addNewInquiry").css("z-index", "1050");
    }
  }
  OnClose() {
    this.inquiryModel = {};
    $("#addNewInquiry").modal("hide");
  }
  getLeadSourceDropdown() {
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
    });
  }
  onSaveInquiry() {
    this.loading = true;
    this.inquiryModel.InquirySelfAssigned = this.inquiryModel
      .InquirySelfAssigned
      ? 1
      : 0;
    if (+this.inquiryModel.LoanAmount <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Loan Amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    this.losService
      .saveNewInquiry({ JSON: JSON.stringify({ Inquiry: this.inquiryModel }) })
      .subscribe((res: any) => {
        this.loading = false;
        if (res[0].CODE == 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.GetQuickLoanDashboardCount();
          this.OnClose();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  GetStateDropdown() {
    this.losService.GetStateDropdown({}).subscribe((res: any) => {
      this.stateDropdown = res;

      if (this.inquiryModel.CustomerState != "") this.GetDistrickDropdown();
    });
  }
  GetDistrickDropdown() {
    this.inquiryModel.CustomerDistrict = "";
    this.inquiryModel.CustomerTehsil = "";
    if (this.inquiryModel.CustomerState != "") {
      let _find = this.stateDropdown.find((obj: any) => {
        return (
          obj.State_Name.toUpperCase() ==
          this.inquiryModel.CustomerState.toUpperCase()
        );
      });

      this.losService
        .GetDistrickDropdown({ StateID: _find.StateId })
        .subscribe((res: any) => {
          this.districkDropdown = res;
        });
    }
  }
  GetTehasilDropdown() {
    let _find = this.districkDropdown.find((obj: any) => {
      return obj.District_Name == this.inquiryModel.CustomerDistrict;
    });
    this.inquiryModel.CustomerTehsil = "";
    this.losService
      .GetTehasilDropdown({ DistrictId: _find.DistrictId })
      .subscribe((res: any) => {
        this.tehasilDropdown = res;
      });
  }
  onChangeSource() {
    this.inquiryModel.LeadReference = "";
    if (
      this.inquiryModel.Source == "Agent" ||
      this.inquiryModel.Source == "DSA" ||
      this.inquiryModel.Source == "Dealer"
    ) {
      this.Get_PartnerForDropdown();
    }
  }
  Get_PartnerForDropdown() {
    this.losService
      .Get_PartnerForDropdown({ PartnerType: this.inquiryModel.Source })
      .subscribe((res: any) => {
        this.partnerDropdown = res;
      });
  }
  Get_EmployeeBranchForDropdown() {
    this._MasterService
      .Get_EmployeeBranchForDropdown({ EmployeeId: this.currentUser.userId })
      .subscribe((res: any) => {
        this.employeeBranchDropdown = res;
      });
  }
  changeTab(tab: any) {
    if (tab != this.tab) {
      this.tab = tab;
      // this.selectedTeam = undefined;
      // this.selectedProcess = undefined;
      $(".filterInput").val("");
      if (tab == "mytask") {
        this.GetQuickLoanDashboardCount();
      } else {
        this.tab1 = "team";
        this.Quick_Loan_Get_Manager_Dashboard_ByTeam();
      }
    }
  }
  changeTab1(tab: any) {
    if (tab != this.tab1) {
      this.tab1 = tab;
      this.selectedTeam = undefined;
      this.selectedProcess = undefined;
      $(".filterInput").val("");
      if (tab == "process") {
        this.Quick_Loan_GetManagerDashboardByProcess();
      } else {
        this.Quick_Loan_Get_Manager_Dashboard_ByTeam();
      }
    }
  }
  getTeamDetail(item: any) {
    this.showSpinner = true;
    this.selectedTeam = item;
    this.QuickLoanService.Quick_Loan_Get_Manager_Dashboard_ByTeam({
      ManagerId: this.currentUser.userId,
      TeamMemberId: item.EmpId,
    }).subscribe((res: any) => {
      this.dataTeamSource = new MatTableDataSource(res.Item2);
      this.JsonDataTeam = res.Item2;
      console.log(this.dataTeamSource.data);
      this.dataTeamSource.sort = this.sort;
      this.dataTeamSource.paginator = this.paginator;
      this.showSpinner = false;
    });
  }
  Quick_Loan_Get_Manager_Dashboard_ByTeam() {
    this.showSpinner = true;
    this.QuickLoanService.Quick_Loan_Get_Manager_Dashboard_ByTeam({
      ManagerId: this.currentUser.userId,
      TeamMemberId: "0",
    }).subscribe((res: any) => {
      this.teamListData = res.Item1;
      this.showSpinner = false;
    });
  }

  Quick_Loan_GetManagerDashboardByProcess() {
    this.showSpinner = true;
    this.QuickLoanService.QuickLoan_Get_Manager_Dashboard_ByProcess({
      ManagerId: this.currentUser.userId,
      ProcessId: "0",
      Status: "Pending",
    }).subscribe((res: any) => {
      this.processListData = res.Item1;
      this.showSpinner = false;
    });
  }

  getDetail(item: any) {
    this.showSpinner = true;
    this.selectedProcess = item;
    console.log("this.selectedProcess", item.ProcessId);

    this.displayedProcessColumns = [
      "ApplicationId",
      "Application_No",
      "Customer",
      "Product_Name",
      "Branch_Name",
      "SourceType",
      "SourceName",
      "EmpName",
      "AssignOn",
      "AP_ProcessStatus",
      "TAT_Hr",
      "Action",
    ];

    this.QuickLoanService.QuickLoan_Get_Manager_Dashboard_ByProcess({
      ManagerId: this.currentUser.userId,
      ProcessId: item.ProcessId,
      Status: "Pending",
    }).subscribe((res: any) => {
      if (res.Item2.length > 0) {
        this.dataProcessSource = new MatTableDataSource(res.Item2);
        this.JsonData = res.Item2;
        this.dataProcessSource.sort = this.sort;
        this.dataProcessSource.paginator = this.paginator;

        this.showSpinner = false;
      } else {
        this.dataProcessSource = null;
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "No Data Found",
          ...this.configSuccess,
        });
      }
    });
  }

  onChangeStatusofProcess() {
    console.log("change of Process Id", this.selectedProcess.ProcessId);

    this.showSpinner = true;

    this.displayedProcessColumns = [
      "ApplicationId",
      "Application_No",
      "Customer",
      "Product_Name",
      "Branch_Name",
      "SourceType",
      "SourceName",
      "EmpName",
      "AssignOn",
      "AP_ProcessStatus",
      "TAT_Hr",
      "Action",
    ];

    this.QuickLoanService.QuickLoan_Get_Manager_Dashboard_ByProcess({
      ManagerId: this.currentUser.userId,
      ProcessId: this.selectedProcess.ProcessId,
      Status: this.Status,
    }).subscribe((res: any) => {
      if (res.Item2.length > 0) {
        this.dataProcessSource = new MatTableDataSource(res.Item2);
        this.JsonData = res.Item2;
        this.dataProcessSource.sort = this.sort;
        this.dataProcessSource.paginator = this.paginator;

        this.showSpinner = false;
      } else {
        this.dataProcessSource = null;
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "No Data Found",
          ...this.configSuccess,
        });
        this.showSpinner = false;
      }
    });
    this.showSpinner = false;
  }

  onOpenReport(loanId: any) {
    // console.log("Apid"+ loanId)
    this._Route.navigate([
      `quickloan-dashboard/MainCardInfo/${this._EncrDecrService.encrypt(
        loanId
      )}/`,
    ]);
  }

  applyFilter(filterValue: string) {
    if (
      this.dataProcessSource != undefined &&
      this.dataProcessSource.filteredData.length > 0
    ) {
      this.dataProcessSource.filter = filterValue.trim().toLowerCase();
    }
    if (
      this.dataTeamSource != undefined &&
      this.dataTeamSource.filteredData.length > 0
    ) {
      this.dataTeamSource.filter = filterValue.trim().toLowerCase();
    }
  }

  exportexcelByProcess(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "quickLoan_Dashboard_export"
    ) as HTMLTableElement;
    const worksheetName = "QUICKLOAN VIEW BY PROCESS EXPORT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  exportexcelByTeam(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "quickLoan_export_byTeam"
    ) as HTMLTableElement;
    const worksheetName = "QUICKLOAN VIEW BY TEAM EXPORT"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
