<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <div class="row m-0 col-md-12 p-0 formborder">
    <div class="col-md-5">
      <form #df="ngForm" novalidate (keyup.enter)="getSearchCusList('Source')">
        <h6>Source Customer</h6>
        <div class="row col-md-12 p-0">
          <div class="col-md-4">
            <span> Search By: </span>
            <select
              name="FilterSearchBy"
              id="FilterSearchBySource"
              class="form-control input-text-css"
              [(ngModel)]="FilterSearchBySource"
            >
              <option value="">Select</option>
              <option value="Name">Customer Name</option>
              <option value="Phone">Customer Phone</option>
              <option value="Pan">PAN Card</option>
              <option value="Passport">Passport Copy</option>
              <option value="VoterId">Voter ID Card</option>
              <option value="DL">Driving License</option>
              <option value="Aadhar">Aadhaar Card</option>
            </select>
          </div>
          <div class="col-md-4">
            <span> value: </span>
            <input
              name="FilterSearchValue"
              id="FilterSearchValue"
              placeholder="Value"
              [(ngModel)]="FilterSearchValueSource"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-4">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="getSearchCusList('Source')"
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-5">
      <form
        #df="ngForm"
        novalidate
        (keyup.enter)="getSearchCusList('Destination')"
      >
        <h6>Destination Customer</h6>
        <div class="row col-md-12 p-0">
          <div class="col-md-4">
            <span> Search By: </span>
            <select
              name="FilterSearchBy"
              id="FilterSearchByDestination"
              class="form-control input-text-css"
              [(ngModel)]="FilterSearchByDestination"
            >
              <option value="">Select</option>
              <option value="Name">Customer Name</option>
              <option value="Phone">Customer Phone</option>
              <option value="Pan">PAN Card</option>
              <option value="Passport">Passport Copy</option>
              <option value="VoterId">Voter ID Card</option>
              <option value="DL">Driving License</option>
              <option value="Aadhar">Aadhaar Card</option>
            </select>
          </div>
          <div class="col-md-4">
            <span> value: </span>
            <input
              name="FilterSearchValue"
              id="FilterSearchValue"
              placeholder="Value"
              [(ngModel)]="FilterSearchValueDestination"
              class="form-control input-text-css"
            />
          </div>
          <div class="col-md-4">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="getSearchCusList('Destination')"
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
    <div
      class="col-md-2"
      style="justify-content: end; display: flex; align-items: end"
    >
      <button
        type="button"
        (click)="Reset()"
        class="mt-3 btn font-size-12 button-btn"
      >
        Reset
      </button>
    </div>
  </div>

  <div *ngIf="CustomerIdSource > 0 || CustomerIdDestination > 0">
    <table class="responstable" id="MainTable">
      <tr>
        <th>Particulars</th>
        <th>Source CustomerID-{{ CustomerIdSource }}</th>
        <th>
          <mat-checkbox
            class="example-margin mt-4"
            name="selectAll"
            id="selectAll"
            [(ngModel)]="selectAll"
            (change)="checkUncheckAll($event)"
          >
          </mat-checkbox>
        </th>
        <th>Destination CustomerID-{{ CustomerIdDestination }}</th>
      </tr>
      <tr>
        <th colspan="4" class="slide-header">Demographic Information</th>
      </tr>
      <tr>
        <td>Name</td>
        <td>{{ customerSourceData.CustomerName }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.CustomerName"
            name="CustomerName"
            id="CustomerName"
            [(ngModel)]="DeDupeData.CustomerName"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.CustomerName }}</td>
      </tr>
      <tr>
        <td>Relation Name</td>
        <td>{{ customerSourceData.RelationName }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.RelationName"
            name="RelationName"
            id="RelationName"
            [(ngModel)]="DeDupeData.RelationName"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.RelationName }}</td>
      </tr>

      <tr>
        <td>Gender</td>
        <td>{{ customerSourceData.Gender }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.Gender"
            name="Gender"
            id="Gender"
            [(ngModel)]="DeDupeData.Gender"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.Gender }}</td>
      </tr>
      <tr>
        <td>Occupation</td>
        <td>{{ customerSourceData.Occupation }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.Occupation"
            name="Occupation"
            id="Occupation"
            [(ngModel)]="DeDupeData.Occupation"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.Occupation }}</td>
      </tr>
      <tr>
        <td>DOB</td>
        <td>{{ customerSourceData.Customer_DOB }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.Customer_DOB"
            name="Customer_DOB"
            id="Customer_DOB"
            [(ngModel)]="DeDupeData.Customer_DOB"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.Customer_DOB }}</td>
      </tr>
      <tr>
        <td>Primary Contact No.</td>
        <td>{{ customerSourceData.PrimaryContact }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.PrimaryContact"
            name="PrimaryContact"
            id="PrimaryContact"
            [(ngModel)]="DeDupeData.PrimaryContact"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.PrimaryContact }}</td>
      </tr>

      <tr>
        <td>Alternate Contact No.</td>
        <td>{{ customerSourceData.PrimaryContact }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.AlternateContact"
            name="AlternateContact"
            id="AlternateContact"
            [(ngModel)]="DeDupeData.AlternateContact"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.AlternateContact }}</td>
      </tr>
      <tr>
        <td>WhatsApp Contact No.</td>
        <td>{{ customerSourceData.WhatsAppNo }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.WhatsAppNo"
            name="WhatsAppNo"
            id="WhatsAppNo"
            [(ngModel)]="DeDupeData.WhatsAppNo"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.WhatsAppNo }}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>{{ customerSourceData.Email }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.Email"
            name="Email"
            id="Email"
            [(ngModel)]="DeDupeData.Email"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.Email }}</td>
      </tr>
      <tr>
        <td>Customer MaritalStatus</td>
        <td>{{ customerSourceData.MaritalStatus }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.MaritalStatus"
            name="MaritalStatus"
            id="MaritalStatus"
            [(ngModel)]="DeDupeData.MaritalStatus"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.MaritalStatus }}</td>
      </tr>
      <tr>
        <td>Customer Religion</td>
        <td>{{ customerSourceData.Religion }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.Religion"
            name="Religion"
            id="Religion"
            [(ngModel)]="DeDupeData.Religion"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.Religion }}</td>
      </tr>
      <tr>
        <td>Customer Cast</td>
        <td>{{ customerSourceData.Cast }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.Cast"
            name="Cast"
            id="Cast"
            [(ngModel)]="DeDupeData.Cast"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.Cast }}</td>
      </tr>
      <tr>
        <td>Customer Image</td>
        <td>{{ customerSourceData.ProfilePic }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.ProfilePic"
            name="ProfilePic"
            id="ProfilePic"
            [(ngModel)]="DeDupeData.ProfilePic"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.ProfilePic }}</td>
      </tr>

      <tr>
        <th colspan="4" class="slide-header">Address</th>
      </tr>
      <tr *ngIf="customerSourceData.Permanent">
        <td>Permanent</td>
        <td>{{ customerSourceData.Permanent }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.Permanent"
            name="Permanent"
            id="Permanent"
            [(ngModel)]="DeDupeData.Permanent"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.Permanent }}</td>
      </tr>
      <tr *ngIf="customerSourceData.Present">
        <td>Present</td>
        <td>{{ customerSourceData.Present }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.Present"
            name="Present"
            id="Present"
            [(ngModel)]="DeDupeData.Present"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.Present }}</td>
      </tr>
      <tr *ngIf="customerSourceData.Work">
        <td>Work</td>
        <td>{{ customerSourceData.Work }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.Work"
            name="Work"
            id="Work"
            [(ngModel)]="DeDupeData.Work"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.Work }}</td>
      </tr>
      <tr *ngIf="customerSourceData.Registered">
        <td>Registered</td>
        <td>{{ customerSourceData.Registered }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.Registered"
            name="Registered"
            id="Registered"
            [(ngModel)]="DeDupeData.Registered"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.Registered }}</td>
      </tr>
      <tr *ngIf="customerSourceData.Corporate">
        <td>Corporate</td>
        <td>{{ customerSourceData.Corporate }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.Corporate"
            name="Corporate"
            id="Corporate"
            [(ngModel)]="DeDupeData.Corporate"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.Corporate }}</td>
      </tr>

      <tr>
        <th colspan="4" class="slide-header">KYC</th>
      </tr>
      <tr *ngIf="customerSourceData.PANCard || customerDestinationData.PANCard">
        <td>PAN Card</td>
        <td>{{ customerSourceData.PANCard }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.PANCard"
            name="PANCard"
            id="PANCard"
            [(ngModel)]="DeDupeData.PANCard"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.PANCard }}</td>
      </tr>

      <tr
        *ngIf="
          customerSourceData.ElectricityBill ||
          customerDestinationData.ElectricityBill
        "
      >
        <td>Electricity Bill</td>
        <td>{{ customerSourceData.ElectricityBill }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.ElectricityBill"
            name="ElectricityBill"
            id="ElectricityBill"
            [(ngModel)]="DeDupeData.ElectricityBill"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.ElectricityBill }}</td>
      </tr>

      <tr
        *ngIf="
          customerSourceData.PassportCopy ||
          customerDestinationData.PassportCopy
        "
      >
        <td>Passport Copy</td>
        <td>{{ customerSourceData.PassportCopy }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.PassportCopy"
            name="PassportCopy"
            id="PassportCopy"
            [(ngModel)]="DeDupeData.PassportCopy"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.PassportCopy }}</td>
      </tr>

      <tr
        *ngIf="
          customerSourceData.VoterIDCard || customerDestinationData.VoterIDCard
        "
      >
        <td>Voter ID Card</td>
        <td>{{ customerSourceData.VoterIDCard }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.VoterIDCard"
            name="VoterIDCard"
            id="VoterIDCard"
            [(ngModel)]="DeDupeData.VoterIDCard"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.VoterIDCard }}</td>
      </tr>

      <tr
        *ngIf="
          customerSourceData.DrivingLicense ||
          customerDestinationData.DrivingLicense
        "
      >
        <td>Driving License</td>
        <td>{{ customerSourceData.DrivingLicense }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.DrivingLicense"
            name="DrivingLicense"
            id="DrivingLicense"
            [(ngModel)]="DeDupeData.DrivingLicense"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.DrivingLicense }}</td>
      </tr>

      <tr
        *ngIf="
          customerSourceData.AadhaarCard || customerDestinationData.AadhaarCard
        "
      >
        <td>Aadhaar Card</td>
        <td>{{ customerSourceData.AadhaarCard }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.AadhaarCard"
            name="AadhaarCard"
            id="AadhaarCard"
            [(ngModel)]="DeDupeData.AadhaarCard"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.AadhaarCard }}</td>
      </tr>

      <tr
        *ngIf="
          customerSourceData.OtherPhotoID ||
          customerDestinationData.OtherPhotoID
        "
      >
        <td>Other Photo ID</td>
        <td>{{ customerSourceData.OtherPhotoID }}</td>
        <td class="check-Row">
          <mat-checkbox
            class="example-margin mt-4"
            *ngIf="customerSourceData.OtherPhotoID"
            name="OtherPhotoID"
            id="OtherPhotoID"
            [(ngModel)]="DeDupeData.OtherPhotoID"
          >
          </mat-checkbox>
        </td>
        <td>{{ customerDestinationData.OtherPhotoID }}</td>
      </tr>
    </table>

    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <button
          type="button"
          (click)="De_DupeCustomer()"
          class="btn font-size-12 button-btn"
        >
          De-Dupe Customer
        </button>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade in"
  id="CustomerList"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" novalidate>
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Search Customer
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0 col-md-12 p-0" [hidden]="!dataSource">
            <div class="col-md-12">
              <div class="table-responsive">
                <mat-table
                  [dataSource]="dataSource"
                  #sortList="matSort"
                  matSort
                  id="exportReport"
                  style="height: 370px; max-width: 100%; overflow: auto"
                >
                  <ng-container matColumnDef="CustomerId">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      style="max-width: 50px"
                      *matHeaderCellDef
                      >#</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                      style="max-width: 50px; max-height: 5px"
                    >
                      {{
                        paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1)
                      }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="CustomertName">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Customer</mat-header-cell
                    >

                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.CustomertName
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="RelationName">
                    at
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Father Name</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.RelationName
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="Customer_Gender">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      style="max-width: 50px"
                      *matHeaderCellDef
                      >Gender</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 50px"
                      >{{ row.Customer_Gender }}</mat-cell
                    >
                  </ng-container>

                  <ng-container matColumnDef="GenderAge">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      style="max-width: 50px"
                      *matHeaderCellDef
                      >Age</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 50px"
                      >{{ row.GenderAge }}</mat-cell
                    >
                  </ng-container>

                  <ng-container matColumnDef="PhoneNo">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      style="max-width: 150px; justify-content: center"
                      *matHeaderCellDef
                    >
                      Phone No
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 150px; justify-content: center"
                    >
                      <div *ngIf="currentUser.IsMaskingMobile">
                        {{ row.PhoneNo | mask }}
                      </div>

                      <div *ngIf="!currentUser.IsMaskingMobile">
                        {{ row.PhoneNo }}
                      </div>

                      <i
                        *ngIf="row.Customer_PhoneNo_IsVerified == true"
                        class="fa fa-check-circle ml-2"
                        style="color: green"
                      ></i>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="TotalApplication">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      style="max-width: 150px; justify-content: center"
                      *matHeaderCellDef
                    >
                      Total Application
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell"
                      style="max-width: 150px; justify-content: center"
                    >
                      {{ row.TotalApplication }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="EditAction">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      style="max-width: 100px; justify-content: center"
                      *matHeaderCellDef
                    >
                      View Detail
                    </mat-header-cell>
                    <mat-cell
                      *matCellDef="let row; let i = index"
                      class="grid-cell"
                      style="max-width: 100px; justify-content: center"
                    >
                      <input
                        type="checkbox"
                        [value]="row.CustomerId"
                        [checked]="
                          this.CustomerIdSource == row.CustomerId ||
                          this.CustomerIdDestination == row.CustomerId
                        "
                        (change)="onCustomerChange($event, row)"
                      />
                    </mat-cell>
                  </ng-container>

                  <mat-header-row
                    class="sticky-footer"
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></mat-row>
                </mat-table>
                <mat-paginator
                  #paginatorRef
                  #PaginatorList
                  [pageSizeOptions]="[20, 50]"
                  showFirstLastButtons
                ></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
