<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div
  style="height: 335px; max-width: 100%; overflow-y: auto; overflow-x: hidden"
>
  <div class="row m-0 mt-1">
    <div class="col-md-12 mt-2" style="margin: 0 -18px">
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-interval="false"
        data-wrap="false"
      >
        <div class="carousel-item active" *ngIf="customerData.length > 0">
          <div class="row m-0">
            <div
              class="col-md-3 p-1"
              *ngFor="let item of customerData.slice(0, 4); let i = index"
              (click)="OnClickPDQuestions(item)"
            >
              <div
                class="card"
                style="cursor: pointer"
                [ngClass]="{ active: item.CustomerId === CustomerId }"
              >
                <div class="card-body p-2">
                  <div class="row m-0 mb-3 align-items-center">
                    <div class="col-md-3 p-0">
                      <img
                        style="height: 45px; width: 45px"
                        src="{{ customerProfileBaseUrl }}{{
                          item.CustomerId
                        }}/{{ item.ProfilePic }}"
                        class="user-img rounded-circle img-thumbnail"
                        alt=""
                        onerror="this.onerror=null;this.src='assets/images/default-user.png';"
                      />
                    </div>
                    <div class="col-md-9 p-0">
                      <h6 class="col-md-12 mb-2 fs-12">
                        {{ item.CustomerName }}
                      </h6>
                      <p class="col-md-12 card-subtitle fs-12 text-muted">
                        {{
                          item.CustomerType == "Hirer"
                            ? "Borrower"
                            : item.CustomerType
                        }}
                      </p>
                      <div class="row m-0">
                        <div class="col-md-12">
                          <B>Status</B> : {{ item.PD_Status }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="customerData">
          <div class="carousel-item" *ngIf="customerData.length > 4">
            <div class="row m-0">
              <div
                class="col-md-3 p-1"
                *ngFor="let item of customerData.slice(4, 8); let i = index"
                (click)="OnClickPDQuestions(item)"
              >
                <div
                  class="card"
                  style="cursor: pointer"
                  [ngClass]="{ active: item.CustomerId === CustomerId }"
                >
                  <div class="card-body p-2">
                    <div class="row m-0 mb-3 align-items-center">
                      <div class="col-md-3 p-0">
                        <img
                          style="height: 45px; width: 45px"
                          src="{{ customerProfileBaseUrl }}{{
                            item.CustomerId
                          }}/{{ item.ProfilePic }}"
                          class="user-img rounded-circle img-thumbnail"
                          alt=""
                          onerror="this.onerror=null;this.src='assets/images/default-user.png';"
                        />
                      </div>
                      <div class="col-md-9 p-0">
                        <h6 class="col-md-12 mb-2 fs-12">
                          {{ item.CustomerName }}
                        </h6>
                        <p class="col-md-12 card-subtitle fs-12 text-muted">
                          {{
                            item.CustomerType == "Hirer"
                              ? "Borrower"
                              : item.CustomerType
                          }}
                        </p>
                        <div class="row m-0">
                          <div class="col-md-12">
                            <B>Status</B> : {{ item.PD_Status }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item" *ngIf="customerData.length > 8">
            <div class="row m-0">
              <div
                class="col-md-3 p-1"
                *ngFor="let item of customerData.slice(8, 12); let i = index"
                (click)="OnClickPDQuestions(item)"
              >
                <div
                  class="card"
                  style="cursor: pointer"
                  [ngClass]="{ active: item.CustomerId === CustomerId }"
                >
                  <div class="card-body p-2">
                    <div class="row m-0 mb-3 align-items-center">
                      <div class="col-md-3 p-0">
                        <img
                          style="height: 45px; width: 45px"
                          src="{{ customerProfileBaseUrl }}{{
                            item.CustomerId
                          }}/{{ item.ProfilePic }}"
                          class="user-img rounded-circle img-thumbnail"
                          alt=""
                          onerror="this.onerror=null;this.src='assets/images/default-user.png';"
                        />
                      </div>
                      <div class="col-md-9 p-0">
                        <h6 class="col-md-12 mb-2 fs-12">
                          {{ item.CustomerName }}
                        </h6>
                        <p class="col-md-12 card-subtitle fs-12 text-muted">
                          {{
                            item.CustomerType == "Hirer"
                              ? "Borrower"
                              : item.CustomerType
                          }}
                        </p>
                        <div class="row m-0">
                          <div class="col-md-12">
                            <B>Status</B> : {{ item.PD_Status }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <a
          class="carousel-control-prev ml-2"
          href="#carouselExampleControls"
          role="button"
          style="width: auto; color: #000; font-size: 24px"
          data-slide="prev"
          *ngIf="customerData.length > 4"
        >
          <i class="fa fa-arrow-circle-left" aria-hidden="true"></i>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleControls"
          role="button"
          style="width: auto; color: #000; font-size: 24px"
          data-slide="next"
          *ngIf="customerData.length > 4"
        >
          <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>

  <form
    #f="ngForm"
    (ngSubmit)="onSaveCustomerPDAnswer()"
    novalidate
    *ngIf="ShowDiv"
    class="formborder"
  >
    <div class="row m-0 mt-2 h-b">
      <div class="col-md-1 pl-1"><b>#</b></div>
      <div class="col-md-3 pl-1"><b>Question</b></div>
      <div class="col-md-3 pl-1"><b>Question (Hindi)</b></div>
      <div class="col-md-5 pl-1"><b>Response</b></div>
    </div>
    <div
      class="row m-0 mt-2"
      *ngFor="let question of questionData; let i = index"
    >
      <div class="col-md-1">{{ i + 1 }}</div>
      <div class="col-md-3">{{ question.Question }}</div>
      <div class="col-md-3 pl-1">{{ question.Question_Hindi }}</div>
      <div class="col-md-5 pl-1">
        <ng-container *ngIf="question.QuestionType == 'Boolean'">
          <div class="form-check form-check-inline">
            <input
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              class="form-check-input"
              #refQR="ngModel"
              [(ngModel)]="question.PD_Answer"
              [required]="question.Is_Mandatory > 0"
              value="true"
              [disabled]="IsDisabled || Processid == 5"
              [ngClass]="{
                'is-invalid': f.submitted && refQR.invalid,
                'alert-warning':
                  refQR.invalid &&
                  (refQR.dirty || refQR.touched || refQR.untouched)
              }"
            />
            <label class="form-check-label" for="inlineRadio1">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              class="form-check-input"
              #refQR="ngModel"
              [(ngModel)]="question.PD_Answer"
              [required]="question.Is_Mandatory > 0"
              value="false"
              [disabled]="IsDisabled || Processid == 5"
              [ngClass]="{
                'is-invalid': f.submitted && refQR.invalid,
                'alert-warning':
                  refQR.invalid &&
                  (refQR.dirty || refQR.touched || refQR.untouched)
              }"
            />
            <label class="form-check-label" for="inlineRadio2">No</label>
          </div>
        </ng-container>

        <ng-container *ngIf="question.QuestionType == 'Selection'">
          <span> </span>
          <select
            name="QS{{ i }}"
            id="QS{{ i }}"
            #refQS="ngModel"
            class="form-control input-text-css"
            [required]="question.Is_Mandatory > 0"
            [(ngModel)]="question.PD_Answer"
            [disabled]="IsDisabled || Processid == 5"
            [ngClass]="{
              'is-invalid': f.submitted && refQS.invalid,
              'alert-warning':
                refQS.invalid &&
                (refQS.dirty || refQS.touched || refQS.untouched)
            }"
          >
            <option value="" [selected]="question.PD_Answer === ''">
              Select
            </option>
            <ng-container *ngFor="let option of question.questionOptionData">
              <option [value]="option">{{ option }}</option>
            </ng-container>
          </select>
        </ng-container>

        <ng-container *ngIf="question.QuestionType == 'Multi-Selection'">
          <span> </span>
          <select
            multiple
            name="QMS{{ i }}"
            id="QMS{{ i }}"
            #refQMS="ngModel"
            class="form-control input-text-css"
            [required]="question.Is_Mandatory > 0"
            [(ngModel)]="question.PD_Answer"
            [disabled]="IsDisabled || Processid == 5"
            [ngClass]="{
              'is-invalid': f.submitted && refQMS.invalid,
              'alert-warning':
                refQMS.invalid &&
                (refQMS.dirty || refQMS.touched || refQMS.untouched)
            }"
          >
            <ng-container *ngFor="let option of question.questionOptionData">
              <option [value]="option">{{ option }}</option>
            </ng-container>
          </select>
        </ng-container>

        <ng-container *ngIf="question.QuestionType == 'Integer'">
          <span></span>
          <input
            [required]="question.Is_Mandatory > 0"
            type="number"
            #refQI="ngModel"
            name="QI{{ i }}"
            id="QI{{ i }}"
            [disabled]="IsDisabled || Processid == 5"
            class="form-control input-text-css"
            [(ngModel)]="question.PD_Answer"
            placeholder="Your Answer"
            [ngClass]="{
              'is-invalid': f.submitted && refQI.invalid,
              'alert-warning':
                refQI.invalid &&
                (refQI.dirty || refQI.touched || refQI.untouched)
            }"
          />
        </ng-container>

        <ng-container *ngIf="question.QuestionType == 'Text'">
          <span></span>
          <!--<input [required]="question.Is_Mandatory>0" type="text" #refQT="ngModel" name="QT{{ i }}" id="QT{{ i }}" [disabled]="IsDisabled || Processid==5"
          class="form-control input-text-css" [(ngModel)]="question.PD_Answer" placeholder="Your Answer"
          [ngClass]="{'is-invalid': f.submitted && refQT.invalid, 'alert-warning': refQT.invalid && (refQT.dirty || refQT.touched || refQT.untouched)}" />-->
          <textarea
            required
            rows="2"
            #refQT="ngModel"
            name="QT{{ i }}"
            id="QT{{ i }}"
            [required]="question.Is_Mandatory > 0"
            [disabled]="IsDisabled || Processid == 5"
            class="form-control input-text-css"
            [(ngModel)]="question.PD_Answer"
            placeholder="Your Answer"
            [ngClass]="{
              'is-invalid': f.submitted && refQT.invalid,
              'alert-warning':
                refQT.invalid &&
                (refQT.dirty || refQT.touched || refQT.untouched)
            }"
          ></textarea>
        </ng-container>

        <ng-container
          *ngIf="question.QuestionType == 'Upload'"
          [disabled]="IsDisabled || Processid == 5"
        >
          <i
            class="fa fa-upload"
            *ngIf="!question.PD_Answer"
            (click)="docFile.click()"
            aria-hidden="true"
          ></i>
          <ng-container *ngIf="question.PD_Answer">
            <a
              href="{{ docBaseUrl }}{{ loanSummary.ApplicationNo }}/{{
                question.PD_Answer
              }}"
              target="_blank"
            >
              <i class="fa fa-file-pdf mr-2 fs-16" aria-hidden="true"></i
              >{{ question.PD_Answer }}
            </a>
            <i
              class="fa fa-times ml-2"
              aria-hidden="true"
              style="cursor: pointer"
              (click)="removeFile(question, docFile)"
            ></i>
          </ng-container>
          <input
            #docFile
            type="file"
            [multiple]="false"
            accept="application/pdf,application/vnd.ms-excel"
            (change)="fileChangeListener(question, docFile.files)"
            style="display: none"
          />
        </ng-container>
      </div>
    </div>

    <hr />
    <div class="row m-0 col-md-12" *ngIf="Processid != 5">
      <div class="text-right" [ngClass]="'col-md-2'" *ngIf="Processid != 5">
        <button
          type="button"
          (click)="onEditCustomerPDAnswer()"
          class="btn font-size-12 button-btn mt-4"
          *ngIf="PageAcess && PageAcess[0].Edit == 1"
        >
          Edit Answer
        </button>
      </div>

      <div class="text-right" [ngClass]="'col-md-2'" *ngIf="Processid != 5">
        <button
          type="button"
          (click)="onSaveCustomerPDAnswer()"
          *ngIf="IsSave"
          class="btn font-size-12 button-btn mt-4"
          [class.spinner]="loading"
          [disabled]="!f.form.valid"
        >
          Save Answer
        </button>
      </div>

      <div class="text-right" [ngClass]="'col-md-2'" *ngIf="Processid != 5">
        <button
          type="button"
          (click)="onSaveCustomerPDAnswer()"
          *ngIf="IsUpdate"
          class="btn font-size-12 button-btn mt-4"
          [class.spinner]="loading"
          [disabled]="!f.form.valid"
          style="width: max-content;"
        >
          Update Answer
        </button>
      </div>
    </div>
  </form>
</div>

<div class="col-md-12">
  <button
    type="button"
    (click)="onNext()"
    class="ml-4 mt-2 btn font-size-12 button-btn"
    [class.spinner]="loading"
    style="float: right"
  >
    Next <i class="fa fa-arrow-right"></i>
  </button>
</div>
