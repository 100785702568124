<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row m-0 col-md-12">
  <form #df="ngForm"
        novalidate
        (keydown.enter)="onSubmitFoirCart()"
        class="formborder col-md-12">
    <div class="col-md-12 row">
      <div class="col-md-3 p-0">
        <span class="required-lable">Customer</span>
        <!-- (change)="changeCustomer()" -->
        <select name="CustomerId"
                id="CustomerId"
                required
                placeholder="Select Customer Name"
                [(ngModel)]="CustomerId"
                class="form-control input-text-css"
                #refCustomerId="ngModel"
                [ngClass]="{
            'is-invalid': df.submitted && refCustomerId.invalid,
            'alert-warning':
              refCustomerId.invalid &&
              (refCustomerId.dirty ||
                refCustomerId.touched ||
                refCustomerId.untouched)
          }">
          <option value="">Select Customer</option>
          <option *ngFor="let customer of CustomerList"
                  [value]="customer.CustomerId">
            {{ customer.Customer }} ({{
              customer.Customer_Type == "H"
                ? "Borrower"
                : customer.Customer_Type == "C"
                ? "Co-Borrower"
                : "Guarantor"
            }})
          </option>
        </select>
      </div>

      <div class="col-md-3">
        <span class="required-lable">Bank Name</span>
        <select name="bankNameFoirCart"
                id="bankNameFoirCart"
                [(ngModel)]="bankNameFoirCart"
                class="form-control input-text-css"
                disabled
                required>
          <option value="other">Other</option>
        </select>
      </div>

      <div class="col-md-3">
        <span class="required-lable">File No.</span>
        <input name="fileNoFoirCart"
               [(ngModel)]="fileNoFoirCart"
               id="fileNoFoirCart"
               placeholder="File No."
               class="form-control input-text-css"
               disabled
               required />
      </div>
      <div class="col-md-3">
        <span class="required-lable">Product Type:</span>
        <select required
                name="productTypeFoirCart"
                id="productTypeFoirCart"
                #refproductTypeFoirCart="ngModel"
                [(ngModel)]="productTypeFoirCart"
                class="form-control input-text-css">
          <option value="salariedLoan">Salaried Loan</option>
          <option value="businessLoan">Business Loan</option>
        </select>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable">Bank Statement Upload</span>

        <p class="mt-1">
          <i class="fa fa-upload fileUpload"
             *ngIf="DocName == ''"
             (click)="docFile.click()"
             aria-hidden="true"
             style="cursor: pointer"></i>

          <ng-container *ngIf="DocName">
            <a href="{{ docBaseUrl }}{{ fileNoFoirCart }}/{{ DocName }}"
               target="_blank">
              <i class="fa fa-file-pdf mr-2 fs-16" aria-hidden="true"></i>
            </a>
            <i class="fa fa-times ml-2 fs-16"
               aria-hidden="true"
               style="cursor: pointer"
               (click)="removeFileFoir(docFile)"></i>
          </ng-container>
        </p>

        <input #docFile
               type="file"
               style="display: none"
               accept=".pdf,.zip"
               (change)="fileChangeListenerFOIR(docFile.files)" />
      </div>

      <div class="col-md-3">
        <span>Password (If required)</span>
        <input type="password"
               name="passwordFoirCart"
               [(ngModel)]="passwordFoirCart"
               id="passwordFoirCart"
               placeholder="Password"
               class="form-control input-text-css" />
      </div>

      <div class="col-md-6 text-right mt-3">
        <button type="button"
                (click)="onSubmitFoirCart()"
                class="btn font-size-12 button-btn"
                [disabled]="!df.form.valid">
          Submit
        </button>
      </div>
    </div>
    <div class="col-md-12 row mt-3">
      <div class="col-md-3" [hidden]="!dataSourceFoirCart">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input type="text"
                     id="FilterInputSearch"
                     (keyup)="applyFilter($event.target.value)"
                     name="FilterInputSearch"
                     placeholder="What're you searching for?"
                     class="form-control border-0 bg-light" />
              <div class="input-group-append">
                <button id="button-addon1"
                        type="submit"
                        class="btn btn-link text-primary">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>
</div>

<div class="table-responsive" style="margin-top: 10px">
  <mat-table [dataSource]="dataSource"
             matSort
             style="height: 60vh; overflow: auto">
    <ng-container matColumnDef="CustomerId">
      <mat-header-cell mat-sort-header
                       class="grid-header"
                       style="max-width: 40px"
                       *matHeaderCellDef>
        #
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 40px; max-height: 5px">
        {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Application_No">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        App No
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.Application_No }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Customer_Name">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        Name
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.Customer_Name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Status">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        Status
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.Status }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Product_Name">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        Product
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.Product_Name }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="EmployeeName">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        Employee Name
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        {{ row.EmployeeName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="DocName">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        Bank Statement
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        <a href="{{ docBaseUrl }}{{ loanSummary.ApplicationNo }}/{{
            row.DocName
          }}"
           target="_blank">
          <button type="button"
                  class="font-size-12 button-btn"
                  mat-raised-button>
            View
          </button>
        </a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="View">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>Bank Analysis</mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        <button *ngIf="row.Status === 'Pending'"
                type="button"
                (click)="onSearchPendingStatus(row)"
                class="font-size-12 button-btn"
                mat-raised-button>
          Run Analysis
        </button>
        <a *ngIf="row.Status != 'Pending'"
           href="{{ docBaseUrl }}{{ loanSummary.ApplicationNo }}/{{
            row.DocId
          }}.xlsx"
           target="_blank">
          <!--  <i class="fa fa-eye" style="font-size: large"></i> -->
          <button type="button"
                  class="font-size-12 button-btn"
                  mat-raised-button>
            View
          </button>
        </a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Delete">
      <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
        Delete
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="grid-cell">
        <!--    <button type="button"
                           (click)="onDeleteBankStatement(row)"
                           class="font-size-12 button-btn"
                           mat-raised-button>
                       Delete
                   </button> -->

        <i aria-label="true"
           class="fa fa-trash style-delete"
           (click)="onDeleteBankStatement(row)"
           *ngIf="row.Role_Level != 0"></i>
      </mat-cell>
    </ng-container>
    <!-- Header and Row Declarations -->
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <div class="row m-0 align-items-center">
    <div class="col-md-12">
      <mat-paginator #paginatorRef
                     [pageSizeOptions]="[20, 50]"
                     showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>
<div class="row col-md-12 m-0 mt-2 justify-content-end">
  <button type="button"
          (click)="next()"
          class="mt-3 btn font-size-12 button-btn">
    Next <i class="fa fa-arrow-right"></i>
  </button>
</div>
