<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row m-0 col-md-12 p-0 formborder d-flex justify-content-between">
  <div class="col-md-4">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-4 text-right">
    <button
      type="button"
      (click)="goToDetail({})"
      class="mt-2 btn font-size-12 button-btn"
    >
      Add New
    </button>
  </div>
</div>

<div class="mt-3 table-responsive" [hidden]="!dataSource">
  <mat-table
    class="table-bordered"
    matSort
    [dataSource]="dataSource"
    matTableExporter
    #exporter="matTableExporter"
    [hiddenColumns]="[
      displayedColumns.indexOf('AccountId'),
      displayedColumns.indexOf('Action')
    ]"
    style="overflow: auto; max-width: 100%; height: 60vh"
  >
    <ng-container matColumnDef="AccountId">
      <mat-header-cell mat-sort-header *matHeaderCellDef> # </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index">
        {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Name">
      <mat-header-cell mat-sort-header *matHeaderCellDef>
        Account Name
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.Name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Group">
      <mat-header-cell mat-sort-header *matHeaderCellDef>Group</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.Group }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="OpenDate">
      <mat-header-cell mat-sort-header *matHeaderCellDef
        >Open Date</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.OpenDate }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="CloseDate">
      <mat-header-cell mat-sort-header *matHeaderCellDef
        >Close Date</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.CloseDate }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="OpeningBalance">
      <mat-header-cell mat-sort-header *matHeaderCellDef>
        Opening Balance
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.OpeningBalance }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="LoanChargeable">
      <mat-header-cell mat-sort-header *matHeaderCellDef>
        Loan Chargeable
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.LoanChargeable }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="GSTEnable">
      <mat-header-cell mat-sort-header *matHeaderCellDef
        >GST Enable</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.GSTEnable }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Branches">
      <mat-header-cell mat-sort-header *matHeaderCellDef
        >Branches</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.Branches }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Action">
      <mat-header-cell
        mat-sort-header
        class="grid-header"
        style="max-width: 60px"
        *matHeaderCellDef
      >
        Action
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        class="grid-cell j-c-center"
        style="max-width: 60px"
      >
        <div style="display: flex; align-items: center; gap: 10px">
          <i
            class="fa fa-eye"
            (click)="goToDetail(row)"
            style="cursor: pointer; font-size: 14px"
            aria-label="true"
            *ngIf="row.Level != 0"
          ></i>
          <i
            class="fa fa-trash style-delete"
            (click)="onDelete(row)"
            style="cursor: pointer"
            aria-label="true"
            *ngIf="row.Level != 0"
          ></i>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>

<div class="row m-0 ml-3 mr-3 pt-2 pb-1" [hidden]="!dataSource">
  <div class="col-md-4">
    <button class="mt-3 btn font-size-12 button-btn" (click)="exportTable()">
      <i
        class="fa fa-file-export mr-1"
        style="font-size: medium; cursor: pointer"
      ></i>
      Export
    </button>
  </div>
  <div class="col-md-8 text-right">
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

<table
  id="accounting_master"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Account Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Group
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Open Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Close Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Opening Balance
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Chargeable
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      GST Enable
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branches
    </th>
  </tr>

  <tr *ngFor="let x of accountList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Name }}</td>
    <td>{{ x.Group }}</td>
    <td>{{ x.OpenDate }}</td>
    <td>{{ x.CloseDate }}</td>
    <td>{{ x.OpeningBalance }}</td>
    <td>{{ x.LoanChargeable }}</td>
    <td>{{ x.GSTEnable }}</td>
    <td>{{ x.Branches }}</td>
  </tr>
</table>
