<!-- Top Menu -->
<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form #df="ngForm" class="formborder">
  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-2">
      <span class="required-lable"> From Date </span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="FromDate"
          [(ngModel)]="datefrom"
          id="FromDate"
          class="form-control input-text-css"
          required
          #refFromDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refFromDate.invalid,
            'alert-warning':
              refFromDate.invalid &&
              (refFromDate.dirty ||
                refFromDate.touched ||
                refFromDate.untouched)
          }"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
      <!-- <input
        name="FromDate"
        [(ngModel)]="datefrom"
        id="FromDate"
        placeholder="From Date"
        class="form-control input-text-css"
        bsDatepicker
        required
        #refFromDate="ngModel"
        [ngClass]="{
          'is-invalid': df.submitted && refFromDate.invalid,
          'alert-warning':
            refFromDate.invalid &&
            (refFromDate.dirty || refFromDate.touched || refFromDate.untouched)
        }"
      /> -->
    </div>
    <div class="col-md-2">
      <span class="required-lable"> To Date </span>
      <div class="datepicker_feild">
        <input
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="ToDate"
          [(ngModel)]="dateto"
          id="ToDate"
          [min]="datefrom"
          class="form-control input-text-css"
          required
          #refToDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
      <!-- <input
        name="ToDate"
        [(ngModel)]="dateto"
        id="ToDate"
        placeholder="To Date"
        [minDate]="datefrom"
        class="form-control input-text-css"
        bsDatepicker
        required
        #refToDate="ngModel"
        [ngClass]="{
          'is-invalid': df.submitted && refToDate.invalid,
          'alert-warning':
            refToDate.invalid &&
            (refToDate.dirty || refToDate.touched || refToDate.untouched)
        }"
      /> -->
    </div>
    <div class="col-md-2">
      <span> Receipt Type</span>
      <select
        name="Receipt_Type"
        id="Receipt_Type"
        required
        [(ngModel)]="Receipt_Type"
        class="form-control input-text-css"
      >
        <option value="All">All</option>
        <option value="CASH">Cash</option>
        <option value="Bank">Bank</option>
        <option value="Other">Other</option>
      </select>
    </div>

    <div class="col-md-2">
      <span> Charges Head</span>
      <select
        name="ChargesHead"
        id="ChargesHead"
        [(ngModel)]="ChargesHead"
        class="form-control input-text-css"
      >
        <option value="0">Select Charge Head</option>
        <option
          *ngFor="let charges of chargesHeadDropdown"
          [value]="charges.Id"
        >
          {{ charges.ChagesHead }}
        </option>
      </select>
    </div>
    <div class="col-md-2">
      <span> Loan No. </span>
      <input
        name="CaseNo"
        [(ngModel)]="CaseNo"
        id="CaseNo"
        placeholder="Enter Loan Number"
        class="form-control input-text-css"
      />
    </div>

    <div class="col-md-2">
      <button
        type="button"
        (click)="getReceiptBookDetails()"
        class="btn font-size-12 button-btn mt-3"
        [disabled]="!df.form.valid"
      >
        Search
      </button>
    </div>

    <div class="col-md-4" [hidden]="!ReceiptDatasource">
      <form action="" class="search-text mt-3">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</form>
<!-- Top MEnu ends Here -->

<!-- Table Start Here -->
<div class="mt-3" [hidden]="!ReceiptDatasource">
  <div
    class="table-responsive table-striped"
    style="overflow: auto; height: 65vh"
  >
    <table
      mat-table
      matSort
      class="table-bordered"
      [dataSource]="ReceiptDatasource"
      style="width: 2200px !important"
    >
      <ng-container class="columnclass" matColumnDef="Number">
        <th
          mat-header-cell
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
        >
          #
        </th>
        <td mat-cell *matCellDef="let row; let i = index" class="grid-cell">
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>

      <ng-container class="columnclass" matColumnDef="ReceiptNo">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>
          Receipt No.
        </th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ReceiptNo }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>
      <ng-container class="columnclass" matColumnDef="Voucher_Date">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>
          Voucher Date.
        </th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Voucher_Date }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>
      <ng-container class="columnclass" matColumnDef="ReceiptDate">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>
          Receipt Date
        </th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ReceiptDate }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>
      <ng-container class="columnclass" matColumnDef="CaseNo">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>Loan No.</th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CaseNo }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>
      <ng-container
        class="columnclass"
        style="width: 200px !important"
        matColumnDef="Customer_Name"
      >
        <th mat-header-cell class="grid-header" *matHeaderCellDef>
          Customer Name
        </th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer_Name }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>
      <ng-container class="columnclass" matColumnDef="ChagesHead">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>
          Charges Head
        </th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ChagesHead }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>

      <ng-container class="columnclass" matColumnDef="Account_Name">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>
          Account Name
        </th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Account_Name }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>

      <ng-container class="columnclass" matColumnDef="Branch_Name">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>
          Branch Name
        </th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch_Name }}
        </td>
        <th mat-footer-cell *matFooterCellDef>Total:</th>
      </ng-container>
      <ng-container class="columnclass" matColumnDef="Amount">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let row" class="grid-cell text-right">
          {{ row.Amount }}
        </td>
        <th mat-footer-cell *matFooterCellDef class="text-right">
          {{ totaltypewise("Amount") }}
        </th>
      </ng-container>
      <ng-container class="columnclass" matColumnDef="TranType">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>TranType</th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.TranType }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>
      <ng-container class="columnclass" matColumnDef="Voucher_CreateBy">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>
          Voucher Created By
        </th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Voucher_CreateBy }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>
      <ng-container class="columnclass" matColumnDef="Collection_Executive">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>
          Collection Executive
        </th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Collection_Executive }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>
      <ng-container class="columnclass" matColumnDef="Collection_By">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>
          Collection By
        </th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Collection_By }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>
      <ng-container class="columnclass" matColumnDef="Origin">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>Origin</th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Origin }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>
      <ng-container class="columnclass" matColumnDef="location">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          <a
            *ngIf="
              row.location != '' && row.location != null && row.location != ','
            "
            href="http://maps.google.com/maps?q={{ row.location }}"
            target="_blank"
            >{{ row.location }}</a
          >
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>
      <ng-container class="columnclass" matColumnDef="Voucher_Narration">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>
          Voucher Narration
        </th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Voucher_Narration }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>
      <ng-container class="columnclass" matColumnDef="Voucher_Tag">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>
          Voucher Tag
        </th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Voucher_Tag }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>
      <ng-container class="columnclass" matColumnDef="Voucher_Type">
        <th mat-header-cell class="grid-header" *matHeaderCellDef>
          Voucher Type
        </th>
        <td mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Voucher_Type }}
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="DisplayedColumn"></tr>
      <tr mat-row *matRowDef="let row; columns: DisplayedColumn"></tr>
      <tr
        mat-footer-row
        class="sticky-footer fontcolor"
        *matFooterRowDef="DisplayedColumn; sticky: true"
      ></tr>
    </table>
  </div>
  <div class="row col-md-12 pt-2 pb-1" [hidden]="!ReceiptDatasource">
    <div class="col-md-4">
      <button class="btn font-size-12 button-btn" (click)="exportexcelNew()">
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Export
      </button>
    </div>
    <div class="col-md-8 text-right">
      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>
<!-- Table Ends Here -->

<table
  id="receipt_details"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Receipt No.
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Receipt Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No.
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Charges Head
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Account Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Amount
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      TranType
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Created By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Origin
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Location
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Narration
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Tag
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Type
    </th>
  </tr>
  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.ReceiptNo }}</td>
    <td>{{ x.Voucher_Date }}</td>
    <td>{{ x.ReceiptDate }}</td>
    <td>{{ x.CaseNo }}</td>
    <td>{{ x.Customer_Name }}</td>
    <td>{{ x.ChagesHead }}</td>
    <td>{{ x.Account_Name }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td style="text-align: right">{{ x.Amount }}</td>
    <td>{{ x.TranType }}</td>
    <td>{{ x.Voucher_CreateBy }}</td>
    <td>{{ x.Collection_Executive }}</td>
    <td>{{ x.Collection_By }}</td>
    <td>{{ x.Origin }}</td>
    <td>{{ x.location }}</td>
    <td>{{ x.Voucher_Narration }}</td>
    <td>{{ x.Voucher_Tag }}</td>
    <td>{{ x.Voucher_Type }}</td>
  </tr>

  <tr>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    >
      Total:
    </td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    >
      <strong>{{ totaltypewise("Amount") }}</strong>
    </td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
    <td
      style="
        background: #4dc3a3 !important;
        border: 1px solid black;
        text-align: right;
      "
    ></td>
  </tr>
</table>
