<!-- <div class="row m-0 mt-3"> -->
<div [class.FixHeight]="PageAcess[0].View == 0">
  <div class="row mt-2" *ngIf="PageAcess[0].View == 1">
    <div class="col-md-12">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataCSource"
          matSort
          matTableExporter
          style="max-width: 100%; overflow: auto"
        >
          <ng-container matColumnDef="Int_Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
            >
              #
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Type">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Type
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Name">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Name
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Company Name">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Company Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.CompanyName
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="IDV/Sum Assured">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >IDV/Sum Assured</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.IDVOrSumAssured
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Expire Date">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Expire Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.ExpireDate
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="View Detail">
            <mat-header-cell
              class="grid-header j-c-center"
              style="max-height: 5px"
              *matHeaderCellDef
              >View Detail</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell j-c-center"
              style="max-width: 150px; max-height: 5px"
            >
              <i
                class="fa fa-eye"
                style="font-size: large"
                (click)="goToDetail(row)"
                aria-label="true"
              ></i>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              class="grid-header j-c-center"
              style="max-height: 5px"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell j-c-center"
              style="max-width: 150px; max-height: 5px"
            >
              <i
                class="fas fa-lg fa-edit margin-right-4xs cursor-style"
                (click)="goToUpdate(row)"
                aria-label="true"
                *ngIf="
                  PageAcess && PageAcess[0].Edit == 1 && !loanSummary.CloseDate
                "
              ></i>
              <i
                class="fa fa-trash style-delete ml-3"
                (click)="Delete(row)"
                aria-label="true"
                *ngIf="PageAcess[0].Delete == 1 && !loanSummary.CloseDate"
              ></i>
            </mat-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="displayedInsuranceColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedInsuranceColumns"
          ></mat-row>
        </mat-table>

        <div class="row m-0 align-items-center">
          <div class="col-md-4"></div>
          <div class="col-md-8">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row m-0 mt-2">
  <div class="col-md-6">
    <div *ngIf="PageAcess[0].View == 1">
      <button
        type="button"
        (click)="OpenModelInsurance()"
        class="mt-2 btn font-size-12 button-btn"
        [class.spinner]="loading"
        *ngIf="PageAcess && PageAcess[0].Add == 1 && !loanSummary.CloseDate"
      >
        Add Insurance Detail
      </button>
    </div>
  </div>
  <div class="col-md-6 text-right">
    <button
      type="button"
      (click)="onNext()"
      class="ml-4 mt-2 btn font-size-12 button-btn"
      [class.spinner]="loading"
    >
      Next <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</div>

<div
  class="modal fade in"
  id="InsuranceModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Insurance Detail
        </h6>
        <button
          type="button"
          (click)="onCloseInsurance()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form #rdf="ngForm" novalidate class="formborder">
          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-6">
              <span class="required-lable">Insurance Type</span>
              <select
                name="Type"
                id="Type"
                #refType="ngModel"
                (change)="ShowForm($event)"
                [ngClass]="{
                  'is-invalid': rdf.submitted && refType.invalid,
                  'alert-warning':
                    refType.invalid &&
                    (refType.dirty || refType.touched || refType.untouched)
                }"
                [(ngModel)]="Type"
                class="form-control input-text-css"
                required
              >
                <option value="Assets" selected>Assets</option>
                <option value="Customer">Customer</option>
              </select>
            </div>
            <div class="col-md-6">
              <span class="required-lable"> Customer/Assets Name</span>
              <select
                name="Id"
                id="Id"
                #refId="ngModel"
                [ngClass]="{
                  'is-invalid': rdf.submitted && refId.invalid,
                  'alert-warning':
                    refId.invalid &&
                    (refId.dirty || refId.touched || refId.untouched)
                }"
                [(ngModel)]="Id"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Customer/Assets Name</option>
                <option
                  *ngFor="let list of CustomerAssetsList"
                  [value]="list.CustomerId"
                >
                  {{ list.Customer }}
                </option>
              </select>
            </div>
          </div>
          <div [hidden]="!AssetsShow">
            <div class="row m-0 col-md-12 p-0">
              <div class="col-md-4">
                <span class="required-lable">Insurance Company </span>
                <!--<input type="text" [(ngModel)]="AssetInsuranceCompany"
                       placeholder="Insurance Company"
                       name="company" id="company" class="form-control input-text-css">-->
                <select
                  name="AssetInsuranceCompany"
                  id="AssetInsuranceCompany"
                  #refAssetInsuranceCompany="ngModel"
                  (change)="getInsuranceBranch($event)"
                  [ngClass]="{
                    'is-invalid':
                      rdf.submitted && refAssetInsuranceCompany.invalid,
                    'alert-warning':
                      refAssetInsuranceCompany.invalid &&
                      (refId.dirty ||
                        refAssetInsuranceCompany.touched ||
                        refAssetInsuranceCompany.untouched)
                  }"
                  [(ngModel)]="AssetInsuranceCompany"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Insurance Company</option>
                  <option
                    *ngFor="let list of Insurance_Company_List"
                    [value]="list.Int_Id"
                  >
                    {{ list.Insurance_company }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <span class="required-lable">Insurance Company Branch </span>
                <!--<input type="text" [(ngModel)]="AssetInsuranceCompanyBranch" placeholder="Insurance Company Branch Name"
                       name="branch" id="branch" class="form-control input-text-css">-->
                <select
                  name="branch"
                  id="branch"
                  #refbranch="ngModel"
                  [ngClass]="{
                    'is-invalid': rdf.submitted && refbranch.invalid,
                    'alert-warning':
                      refbranch.invalid &&
                      (refbranch.dirty ||
                        refbranch.touched ||
                        refbranch.untouched)
                  }"
                  [(ngModel)]="AssetInsuranceCompanyBranch"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Branch Name</option>
                  <option
                    *ngFor="let list of Insurance_Branch_List"
                    [value]="list.Int_Id"
                  >
                    {{ list.Insurance_Branch }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <span class="required-lable">Agent Name </span>
                <input
                  type="text"
                  #refAssetAgentName="ngModel"
                  required
                  maxLength="30"
                  [ngClass]="{
                    'is-invalid': rdf.submitted && refAssetAgentName.invalid,
                    'alert-warning':
                      refAssetAgentName.invalid &&
                      (refAssetAgentName.dirty ||
                        refAssetAgentName.touched ||
                        refAssetAgentName.untouched)
                  }"
                  [(ngModel)]="AssetAgentName"
                  name="AssetAgentName"
                  id="AssetAgentName"
                  class="form-control input-text-css"
                />
              </div>
            </div>

            <div class="row m-0 col-md-12 p-0">
              <div class="col-md-4">
                <span class="required-lable">Insurance Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="First_EMI_Date1"
                    [(ngModel)]="AssetInsuranceDate"
                    (dateChange)="changeAssetRenewalDate($event)"
                    id="First_EMI_Date1"
                    #refAssetInsuranceDate="ngModel"
                    required
                    [ngClass]="{
                      'is-invalid':
                        rdf.submitted && refAssetInsuranceDate.invalid,
                      'alert-warning':
                        refAssetInsuranceDate.invalid &&
                        (refAssetInsuranceDate.dirty ||
                          refAssetInsuranceDate.touched ||
                          refAssetInsuranceDate.untouched)
                    }"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <!-- <input name="First_EMI_Date1" [(ngModel)]="AssetInsuranceDate" (ngModelChange)="changeAssetRenewalDate($event)" 
                  id="First_EMI_Date1" [minDate]="CurrentDate" #refAssetInsuranceDate="ngModel" required
                  [ngClass]="{ 'is-invalid': rdf.submitted && refAssetInsuranceDate.invalid, 'alert-warning': refAssetInsuranceDate.invalid  && (refAssetInsuranceDate.dirty || refAssetInsuranceDate.touched || refAssetInsuranceDate.untouched) }"
                  placeholder="DD/MM/YYYY" class="form-control input-text-css" bsDatepicker> -->
                <!-- <input
                  name="First_EMI_Date1"
                  [(ngModel)]="AssetInsuranceDate"
                  (ngModelChange)="changeAssetRenewalDate($event)"
                  id="First_EMI_Date1"
                  #refAssetInsuranceDate="ngModel"
                  required
                  [ngClass]="{
                    'is-invalid':
                      rdf.submitted && refAssetInsuranceDate.invalid,
                    'alert-warning':
                      refAssetInsuranceDate.invalid &&
                      (refAssetInsuranceDate.dirty ||
                        refAssetInsuranceDate.touched ||
                        refAssetInsuranceDate.untouched)
                  }"
                  placeholder="DD/MM/YYYY"
                  class="form-control input-text-css"
                  bsDatepicker
                /> -->
              </div>

              <div class="col-md-4">
                <span class="required-lable">Renewal Date</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker2"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="First_EMI_Date2"
                    [(ngModel)]="AssetRenewalDate"
                    (dateChange)="changeRenewalDate($event)"
                    id="First_EMI_Date2"
                    [min]="AssetInsuranceDate"
                    #refAssetRenewalDate="ngModel"
                    required
                    [ngClass]="{
                      'is-invalid':
                        rdf.submitted && refAssetRenewalDate.invalid,
                      'alert-warning':
                        refAssetRenewalDate.invalid &&
                        (refAssetRenewalDate.dirty ||
                          refAssetRenewalDate.touched ||
                          refAssetRenewalDate.untouched)
                    }"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker2"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </div>
                <!-- <input
                  name="First_EMI_Date2"
                  [(ngModel)]="AssetRenewalDate"
                  (ngModelChange)="changeRenewalDate($event)"
                  id="First_EMI_Date2"
                  [minDate]="AssetInsuranceDate"
                  #refAssetRenewalDate="ngModel"
                  required
                  [ngClass]="{
                    'is-invalid': rdf.submitted && refAssetRenewalDate.invalid,
                    'alert-warning':
                      refAssetRenewalDate.invalid &&
                      (refAssetRenewalDate.dirty ||
                        refAssetRenewalDate.touched ||
                        refAssetRenewalDate.untouched)
                  }"
                  placeholder="DD/MM/YYYY"
                  class="form-control input-text-css"
                  bsDatepicker
                /> -->
              </div>

              <div class="col-md-4">
                <span class="required-lable">Cover Note No/Policy No.</span>
                <input
                  type="text"
                  [(ngModel)]="AssetCoverNoteNo"
                  placeholder="Cover Note No/Policy No."
                  name="covernote"
                  id="covernote"
                  #refAssetCoverNoteNo="ngModel"
                  required
                  maxLength="30"
                  [ngClass]="{
                    'is-invalid': rdf.submitted && refAssetCoverNoteNo.invalid,
                    'alert-warning':
                      refAssetCoverNoteNo.invalid &&
                      (refAssetCoverNoteNo.dirty ||
                        refAssetCoverNoteNo.touched ||
                        refAssetCoverNoteNo.untouched)
                  }"
                  class="form-control input-text-css"
                />
              </div>
            </div>

            <div class="row m-0 col-md-12 p-0">
              <div class="col-md-4">
                <span class="required-lable">Policy Type</span>
                <!--<input type="text" [(ngModel)]="AssetPolicyType"
                       placeholder="Policy Type"
                       name="policyType" id="policyType" class="form-control input-text-css">-->
                <select
                  name="policyType"
                  id="policyType"
                  #refpolicyType="ngModel"
                  [ngClass]="{
                    'is-invalid': rdf.submitted && refpolicyType.invalid,
                    'alert-warning':
                      refpolicyType.invalid &&
                      (refpolicyType.dirty ||
                        refpolicyType.touched ||
                        refpolicyType.untouched)
                  }"
                  [(ngModel)]="AssetPolicyType"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Policy Type</option>
                  <option
                    *ngFor="let Type of PolicyTypeAssetsOption"
                    [value]="Type.Id"
                  >
                    {{ Type.Value }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <span class="required-lable">IDV Amount</span>
                <input
                  type="number"
                  [(ngModel)]="AssetIDVAmount"
                  placeholder="IDV Amount"
                  name="IDV"
                  id="IDV"
                  class="form-control input-text-css"
                  #refAssetIDVAmount="ngModel"
                  required
                  onkeydown="notDecimal(event)"
                  oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                  [ngClass]="{
                    'is-invalid': rdf.submitted && refAssetIDVAmount.invalid,
                    'alert-warning':
                      refAssetIDVAmount.invalid &&
                      (refAssetIDVAmount.dirty ||
                        refAssetIDVAmount.touched ||
                        refAssetIDVAmount.untouched)
                  }"
                />
              </div>
              <div class="col-md-4">
                <span>Premium Amount</span>
                <input
                  type="number"
                  [(ngModel)]="AssetPremiumAmount"
                  placeholder="Premium Amount"
                  name="PremiumAmount"
                  id="PremiumAmount"
                  #refAssetPremiumAmount="ngModel"
                  required
                  onkeydown="notDecimal(event)"
                  oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                  [ngClass]="{
                    'is-invalid':
                      rdf.submitted && refAssetPremiumAmount.invalid,
                    'alert-warning':
                      refAssetPremiumAmount.invalid &&
                      (refAssetPremiumAmount.dirty ||
                        refAssetPremiumAmount.touched ||
                        refAssetPremiumAmount.untouched)
                  }"
                  class="form-control input-text-css"
                />
              </div>
            </div>

            <div class="row m-0 col-md-12 p-0">
              <div class="col-md-4">
                <span class="required-lable">Nominee Name</span>
                <input
                  type="text"
                  [(ngModel)]="AssetNomineeName"
                  placeholder="Nominee Name"
                  name="Nominee"
                  id="Nominee"
                  class="form-control input-text-css"
                  #refAssetNomineeName="ngModel"
                  required
                  maxLength="30"
                  [ngClass]="{
                    'is-invalid': rdf.submitted && refAssetNomineeName.invalid,
                    'alert-warning':
                      refAssetNomineeName.invalid &&
                      (refAssetNomineeName.dirty ||
                        refAssetNomineeName.touched ||
                        refAssetNomineeName.untouched)
                  }"
                />
              </div>
              <div class="col-md-4">
                <span class="required-lable"
                  >Nominee Relation with Borrower</span
                >
                <!--<input type="text" [(ngModel)]="AssetNomineeRelationwithHirer"
                       placeholder="Nominee Relation with Borrower"
                       name="Relation" id="Relation" class="form-control input-text-css">-->
                <select
                  name="Relation"
                  id="Relation"
                  #refRelation="ngModel"
                  [ngClass]="{
                    'is-invalid': rdf.submitted && refRelation.invalid,
                    'alert-warning':
                      refRelation.invalid &&
                      (refRelation.dirty ||
                        refRelation.touched ||
                        refRelation.untouched)
                  }"
                  [(ngModel)]="AssetNomineeRelationwithHirer"
                  class="form-control input-text-css"
                  required
                >
                  <option *ngFor="let Type of RelationList" [value]="Type.Id">
                    {{ Type.Value }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <input
                  style="margin: 20px 0px 0px 0px; height: 24px; width: 22px"
                  (change)="InsuranceEndorsedChange($event)"
                  class="form-check-input"
                  type="checkbox"
                  name="exampleRadios1"
                  id="Endorsed1"
                  value="option2"
                />
                <label
                  style="margin: 19px 0px 0px 31px"
                  class="form-check-label"
                  for="exampleRadios1"
                  >Insurance endorsed</label
                >
              </div>
            </div>

            <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
              <button
                type="button"
                (click)="onCloseInsurance()"
                class="mt-3 btn font-size-12 button-btn mr-3"
              >
                Cancel
              </button>
              <button
                type="button"
                (click)="onSaveAssetsDetail()"
                class="mt-3 btn font-size-12 button-btn"
                [class.spinner]="loading"
                [hidden]="!isShowSave"
                [disabled]="!rdf.form.valid"
              >
                Save
              </button>
              <button
                type="button"
                (click)="onSaveAssetsDetail()"
                class="mt-3 btn font-size-12 button-btn"
                [class.spinner]="loading"
                [hidden]="!isShowUpdate"
                [disabled]="!rdf.form.valid"
              >
                Update
              </button>
            </div>
          </div>

          <div class="row m-0 col-md-12 p-0" [hidden]="!CustomerShow">
            <form #ddf="ngForm" novalidate>
              <div class="row m-0 col-md-12 p-0">
                <div class="row m-0 col-md-12 p-0">
                  <div class="col-md-4">
                    <span class="required-lable">Insurance Company</span>

                    <select
                      name="Asset_Cost1"
                      id="Asset_Cost1"
                      (change)="getInsuranceBranch($event)"
                      #refAsset_Cost1="ngModel"
                      [ngClass]="{
                        'is-invalid': ddf.submitted && refAsset_Cost1.invalid,
                        'alert-warning':
                          refAsset_Cost1.invalid &&
                          (refAsset_Cost1.dirty ||
                            refAsset_Cost1.touched ||
                            refAsset_Cost1.untouched)
                      }"
                      [(ngModel)]="CustomerInsHirerLifeInsCmny"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Insurance Company</option>
                      <option
                        *ngFor="let list of Insurance_Company_List"
                        [value]="list.Int_Id"
                      >
                        {{ list.Insurance_company }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable"
                      >Insurance Company Branch Name
                    </span>

                    <select
                      name="Asset_Cost2"
                      id="Asset_Cost2"
                      class="form-control input-text-css"
                      [(ngModel)]="CustomerInsAssetInsuranceCompanyBranch"
                      #refAsset_Cost2="ngModel"
                      required
                      [ngClass]="{
                        'is-invalid': ddf.submitted && refAsset_Cost2.invalid,
                        'alert-warning':
                          refAsset_Cost2.invalid &&
                          (refAsset_Cost2.dirty ||
                            refAsset_Cost2.touched ||
                            refAsset_Cost2.untouched)
                      }"
                    >
                      <option value="">Select Branch Name</option>
                      <option
                        *ngFor="let list of Insurance_Branch_List"
                        [value]="list.Int_Id"
                      >
                        {{ list.Insurance_Branch }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable">Agent Name </span>
                    <input
                      type="text"
                      [(ngModel)]="CustomerInsAgentName"
                      placeholder="Agent Name"
                      name="Asset_Cost3"
                      id="Asset_Cost3"
                      class="form-control input-text-css"
                      #refAsset_Cost3="ngModel"
                      required
                      [ngClass]="{
                        'is-invalid': ddf.submitted && refAsset_Cost3.invalid,
                        'alert-warning':
                          refAsset_Cost3.invalid &&
                          (refAsset_Cost3.dirty ||
                            refAsset_Cost3.touched ||
                            refAsset_Cost3.untouched)
                      }"
                    />
                  </div>
                </div>

                <div class="row m-0 col-md-12 p-0">
                  <div class="col-md-4">
                    <span class="required-lable">Insurance Date</span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker3"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="First_EMI_Date3"
                        [(ngModel)]="CustomerInsInsuranceDate"
                        (dateChange)="changeInsuranceDate($event)"
                        id="First_EMI_Date3"
                        class="form-control input-text-css"
                        #refFirst_EMI_Date3="ngModel"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ddf.submitted && refFirst_EMI_Date3.invalid,
                          'alert-warning':
                            refFirst_EMI_Date3.invalid &&
                            (refFirst_EMI_Date3.dirty ||
                              refFirst_EMI_Date3.touched ||
                              refFirst_EMI_Date3.untouched)
                        }"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker3"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker3></mat-datepicker>
                    </div>

                    <!-- <input
                      name="First_EMI_Date3"
                      [(ngModel)]="CustomerInsInsuranceDate"
                      (ngModelChange)="changeInsuranceDate($event)"
                      id="First_EMI_Date3"
                      placeholder="DD/MM/YYYY"
                      class="form-control input-text-css"
                      bsDatepicker
                      #refFirst_EMI_Date3="ngModel"
                      required
                      [ngClass]="{
                        'is-invalid':
                          ddf.submitted && refFirst_EMI_Date3.invalid,
                        'alert-warning':
                          refFirst_EMI_Date3.invalid &&
                          (refFirst_EMI_Date3.dirty ||
                            refFirst_EMI_Date3.touched ||
                            refFirst_EMI_Date3.untouched)
                      }"
                    /> -->
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable">Expire Date</span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker4"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="First_EMI_Date4"
                        [(ngModel)]="CustomerInsExpireDate"
                        [min]="CustomerInsInsuranceDate"
                        (dateChange)="changeExpireDate($event)"
                        id="First_EMI_Date4"
                        class="form-control input-text-css"
                        #refFirst_EMI_Date4="ngModel"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ddf.submitted && refFirst_EMI_Date4.invalid,
                          'alert-warning':
                            refFirst_EMI_Date4.invalid &&
                            (refFirst_EMI_Date4.dirty ||
                              refFirst_EMI_Date4.touched ||
                              refFirst_EMI_Date4.untouched)
                        }"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker4"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker4></mat-datepicker>
                    </div>
                    <!-- <input
                      name="First_EMI_Date4"
                      [(ngModel)]="CustomerInsExpireDate"
                      [minDate]="CustomerInsInsuranceDate"
                      (ngModelChange)="changeExpireDate($event)"
                      id="First_EMI_Date4"
                      placeholder="DD/MM/YYYY"
                      class="form-control input-text-css"
                      bsDatepicker
                      #refFirst_EMI_Date4="ngModel"
                      required
                      [ngClass]="{
                        'is-invalid':
                          ddf.submitted && refFirst_EMI_Date4.invalid,
                        'alert-warning':
                          refFirst_EMI_Date4.invalid &&
                          (refFirst_EMI_Date4.dirty ||
                            refFirst_EMI_Date4.touched ||
                            refFirst_EMI_Date4.untouched)
                      }" -->
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable">policy No</span>
                    <input
                      type="text"
                      [(ngModel)]="CustomerInsPolicyNo"
                      placeholder="Policy No"
                      name="Policy1"
                      id="Policy1"
                      class="form-control input-text-css"
                      #refPolicy1="ngModel"
                      required
                      [ngClass]="{
                        'is-invalid': ddf.submitted && refPolicy1.invalid,
                        'alert-warning':
                          refPolicy1.invalid &&
                          (refPolicy1.dirty ||
                            refPolicy1.touched ||
                            refPolicy1.untouched)
                      }"
                    />
                  </div>
                </div>

                <div class="row m-0 col-md-12 p-0">
                  <div class="col-md-4">
                    <span class="required-lable">Policy Type</span>

                    <select
                      name="Type1"
                      id="Type1"
                      #refSelectGender="ngModel"
                      [(ngModel)]="CustomerInsPolicyType"
                      class="form-control input-text-css"
                      #refType1="ngModel"
                      required
                      [ngClass]="{
                        'is-invalid': ddf.submitted && refType1.invalid,
                        'alert-warning':
                          refType1.invalid &&
                          (refType1.dirty ||
                            refType1.touched ||
                            refType1.untouched)
                      }"
                    >
                      <option value="">Select Policy Type</option>
                      <option
                        *ngFor="let Type of PolicyTypeCustomerOption"
                        [value]="Type.Id"
                      >
                        {{ Type.Value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable">Sum Assured</span>
                    <input
                      type="number"
                      [(ngModel)]="CustomerInsSumAssured"
                      placeholder="Sum Assured"
                      name="Sum"
                      id="Sum"
                      class="form-control input-text-css"
                      #refSum="ngModel"
                      required
                      [ngClass]="{
                        'is-invalid': ddf.submitted && refSum.invalid,
                        'alert-warning':
                          refSum.invalid &&
                          (refSum.dirty || refSum.touched || refSum.untouched)
                      }"
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable">Premium Amount</span>
                    <input
                      type="number"
                      [(ngModel)]="CustomerInsPremiumAmount"
                      placeholder="Premium Amount"
                      name="Amount1"
                      id="Amount1"
                      class="form-control input-text-css"
                      #refAmount1="ngModel"
                      required
                      [ngClass]="{
                        'is-invalid': ddf.submitted && refAmount1.invalid,
                        'alert-warning':
                          refAmount1.invalid &&
                          (refAmount1.dirty ||
                            refAmount1.touched ||
                            refAmount1.untouched)
                      }"
                    />
                  </div>
                </div>

                <div class="row m-0 col-md-12 p-0">
                  <div class="col-md-4">
                    <span class="required-lable">Nominee Name</span>
                    <input
                      type="text"
                      [(ngModel)]="CustomerInsNomineeName"
                      placeholder="Nominee Name"
                      name="Nominee1"
                      id="Nominee1"
                      class="form-control input-text-css"
                      #refNominee1="ngModel"
                      required
                      [ngClass]="{
                        'is-invalid': ddf.submitted && refNominee1.invalid,
                        'alert-warning':
                          refNominee1.invalid &&
                          (refNominee1.dirty ||
                            refNominee1.touched ||
                            refNominee1.untouched)
                      }"
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable"
                      >Nominee Relation with Borrower</span
                    >

                    <select
                      name="Relation1"
                      id="Relation1"
                      #refSelectRelation="ngModel"
                      [(ngModel)]="AssetNomineeRelationwithHirer"
                      class="form-control input-text-css"
                      #refRelation1="ngModel"
                      required
                      [ngClass]="{
                        'is-invalid': ddf.submitted && refRelation1.invalid,
                        'alert-warning':
                          refRelation1.invalid &&
                          (refRelation1.dirty ||
                            refRelation1.touched ||
                            refRelation1.untouched)
                      }"
                    >
                      <option
                        *ngFor="let Type of RelationList"
                        [value]="Type.Id"
                      >
                        {{ Type.Value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <input
                      style="
                        margin: 20px 0px 0px 0px;
                        height: 24px;
                        width: 22px;
                      "
                      (change)="NomineeRelationHirerChange($event)"
                      class="form-check-input"
                      type="checkbox"
                      name="exampleRadios2"
                      id="NomineeRelation2"
                      value="option2"
                    />
                    <label
                      style="margin: 19px 0px 0px 31px"
                      class="form-check-label"
                      for="exampleRadios2"
                    >
                      Insurance Lien Marked
                    </label>
                  </div>
                </div>

                <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
                  <button
                    type="button"
                    (click)="onCloseInsurance()"
                    class="mt-3 btn font-size-12 button-btn mr-3"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    (click)="onSaveCustomerDetails()"
                    class="mt-3 btn font-size-12 button-btn"
                    [class.spinner]="loading"
                    [hidden]="!isShowSave"
                    [disabled]="!ddf.form.valid"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    (click)="onSaveCustomerDetails()"
                    class="mt-3 btn font-size-12 button-btn"
                    [class.spinner]="loading"
                    [hidden]="!isShowUpdate"
                    [disabled]="!ddf.form.valid"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade in"
  id="InsuranceDetailModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Details
        </h6>
        <button
          type="button"
          (click)="onCloseInsuranceDetail()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body formborder">
        <h1 class="fs-12 h-b">Insurance Details</h1>
        <div class="row m-0 mt-3" [hidden]="!AssetsDetailShow">
          <div class="col-md-2">
            <h6 class="fs-12">Insurance Type</h6>
            <p class="lead fs-12">{{ Type }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">{{ Type }} Name</h6>
            <p class="lead fs-12">{{ Name }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Insurance Company</h6>
            <p class="lead fs-12">{{ AssetInsuranceCompanyName }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Insurance Company Branch</h6>
            <p class="lead fs-12">{{ AssetInsuranceCompanyBranchName }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Agent Name</h6>
            <p class="lead fs-12">{{ AssetAgentName }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Insurance Date</h6>
            <p class="lead fs-12">{{ AssetInsuranceDate }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Renewal Date</h6>
            <p class="lead fs-12">{{ AssetRenewalDate }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Cover Note No/Policy No.</h6>
            <p class="lead fs-12">{{ AssetCoverNoteNo }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Policy Type</h6>
            <p class="lead fs-12">{{ AssetPolicyTypeName }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">IDV Amount</h6>
            <p class="lead fs-12">{{ AssetIDVAmount }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Premium Amount</h6>
            <p class="lead fs-12">{{ AssetPremiumAmount }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Nominee Name</h6>
            <p class="lead fs-12">{{ AssetNomineeName }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Nominee Relation with Borrower</h6>
            <p class="lead fs-12">{{ AssetNomineeRelationwithHirerName }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Insurance endorsed</h6>
            <p class="lead fs-12">{{ AssetInsuranceEndorsed }}</p>
          </div>
        </div>

        <div class="row m-0 col-md-12 p-0" [hidden]="!CustomerDetailShow">
          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-2">
              <h6 class="fs-12">Insurance Type</h6>
              <p class="lead fs-12">{{ Type }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">{{ Type }} Name</h6>
              <p class="lead fs-12">{{ Name }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Insurance Company</h6>
              <p class="lead fs-12">{{ CustomerInsHirerLifeInsCmnyName }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Insurance Company Branch</h6>
              <p class="lead fs-12">
                {{ CustomerInsAssetInsuranceCompanyBranchName }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Agent Name</h6>
              <p class="lead fs-12">{{ CustomerInsAgentName }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Insurance Date</h6>
              <p class="lead fs-12">{{ CustomerInsInsuranceDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Expiry Date</h6>
              <p class="lead fs-12">{{ CustomerInsExpireDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Policy No.</h6>
              <p class="lead fs-12">{{ CustomerInsPolicyNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Policy Type</h6>
              <p class="lead fs-12">{{ CustomerInsPolicyTypeName }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Sum Assured</h6>
              <p class="lead fs-12">{{ CustomerInsSumAssured }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Premium Amount</h6>
              <p class="lead fs-12">{{ CustomerInsPremiumAmount }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Nominee Name</h6>
              <p class="lead fs-12">{{ CustomerInsNomineeName }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Nominee Relation with Borrower</h6>
              <p class="lead fs-12">{{ AssetNomineeRelationwithHirerName }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Insurance endorsed</h6>
              <p class="lead fs-12">{{ CustomerInsNomineeRelationHirer }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
