import { Component, OnInit, ViewChild, Input, Injectable } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatSortModule } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { ReportsService } from "../services/reports.service";
import { DatePipe } from "@angular/common";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { ActivatedRoute, Params } from "@angular/router";
import * as moment from "moment";

// Excel export code start here
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { LosService } from "src/app/_LOS/services/los.service";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable()
// Excel export code end here

@Component({
  selector: "app-due-list",
  templateUrl: "./due-list.component.html",
  styleUrls: ["./due-list.component.scss"],
})
export class DueListComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  BranchesList: any = [];
  ProductList: any = [];
  dataSource: any;
  Search: any = "LoanNo";
  CheckType: any;
  DueUpto: any;
  ReceiptUpTo: any;
  SelectSourceType: any = "";
  //DueDateFrom: any = new Date();
  //DueDateTo: any = new Date();

  DueDateFrom: any;
  DueDateTo: any;
  SelectBranch: any = "";
  SelectProduct: any = "";
  collection_exec: any = "";
  SearchStatus: any = "";
  CollectionExecutiveData: any = [];
  loanSummary: any = {};
  private _MasterService;
  displayedColumns: string[] = [];
  JsonData: any[] = [];
  div_due_list: boolean = false;
  Loan_Statement: boolean = false;
  filterValues: any = {
    LoanNo: "",
    ApplicationNo: "",
    CustomerName: "",
    Customer_PhoneNo: "",
    Branch_Name: "",
    Area_Name: "",
    Sub_Area_Name: "",
    CollectionExecutive: "",
  };
  SlabId: any = "";
  SlabData: any[] = [];
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  FyearId: any;
  maxDate: any;
  minDate: any;

  selectedId: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  @Input() DueListfrom: any;
  leadSourceDropdown: any;
  partnerDropdown: any;
  LeadSourceName: any;
  SalesExecutiveData: any = [];

  constructor(
    private dataSharingService: DataSharingService,
    private lmsService: LmsService,
    private MasterService: MasterService,
    private reportsService: ReportsService,
    public datepipe: DatePipe,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private losService: LosService
  ) {
    this._MasterService = MasterService;
    sessionStorage.removeItem("LoanSummary");
  }

  ngOnInit() {
    this.CheckType = "OD Cases";
    this.getLeadSourceDropdown();
    this.GetSalesExecutive();
    this.dataSharingService.HeaderTitle.next("Due List");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getBranchesList();
    this.getProductDropdown();
    this.getCollectionExecutive();

    this.getSlabsList("DPD");

    this.displayedColumns = [
      "Id",
      "Application_No",
      "LoanAcNo",
      "Loan_Date",
      "Loan_Acc_Category",
      "CustomerName",
      "RelationName",
      "LoanAmount",
      "EMIAmount",
      "Loan_Tenure",
      "FirstEMIDate",
      "DPD_Days",
      "OverDUE_EMI",
      "Total_OverDUE_EMI__Amount",
      "Total_OverDUE_Principal_Amount",
      "overDue_Interest_AMount",
      "Last_Recv_Date",
      "Last_Recv_Amount",
      "TotalEMI_NOS",
      "Total_EMI_AMount",
      "Total_Principal_Amount",
      "Total_Interest_AMount",
      "EMI_DUE_NOS",
      "EMI_DUE",
      "Principal_DUE",
      "Interest_DUE",
      "LastDue_Date",
      "LastDue_Amt",
      "LastDue_EMINo",
      "receved_EMI",
      "Total_Recv_EMI__Amount",
      "Total_Recv_Principal_Amount",
      "Total_Recv_Interest_AMount",
      "Balance_Principle",
      "Balance_Interest",
      "FuturePrincipal",
      "FutureInterest",
      "Other_Charges",
      "Penal_Interest",
      "EMI_Frequency",
      "Product_Name",
      "Branch_Name",
      "SalesExecutive",
      "SourceType",
      "SourceName",
      "Area_Name",
      "Sub_Area_Name",
      "CollectionExecutive",
      "Customer_PhoneNo",
      "Alternate_PhoneNo",
      "Address",
      "Co_Borrower_Name",
      "Co_Borrower_MobileNo",
      "Co_Borrower_Address",
      "Guarantor_Name",
      "Guarantor_MobileNo",
      "Guarantor_Address",
      "EMIType",
      "DueDate",
      "ExpiryDate",
      "Bucket",
      "UMRNNumber",
      "CompanyBankName",
      "AssignTo",
      "Legal_Status",
      "VehicleRegistationNo",
      //"Action",
    ];

    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      //console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          this.maxDate = new Date(moment(res[0].fyent).format("MM/DD/YYYY"));
          //this.DueDateFrom = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));

          if (this.maxDate >= new Date() && this.minDate <= new Date()) {
            this.DueDateTo = new Date();
            this.DueDateFrom = new Date();
          } else {
            this.DueDateFrom = this.maxDate;
            this.DueDateTo = this.maxDate;
          }
          this.dataSource = null;
        });
    });
  }

  getLeadSourceDropdown() {
    this.showSpinner = true;
    this.losService.getLeadSourceDropdown({}).subscribe((res: any) => {
      this.leadSourceDropdown = res;
      console.log("Lead Source Dropdown", this.leadSourceDropdown);
      this.showSpinner = false;
    });
  }

  Get_PartnerForDropdown() {
    console.log("Partner Dropdown API Function");

    if (
      this.SelectSourceType == "Agent" ||
      this.SelectSourceType == "DSA" ||
      this.SelectSourceType == "Dealer"
    ) {
      this.losService
        .Get_PartnerForDropdown({
          PartnerType: this.SelectSourceType,
          branchId: this.SelectBranch || 0,
        })
        .subscribe((res: any) => {
          this.partnerDropdown = res;
        });
      this.LeadSourceName = 0;
    } else if (this.SelectSourceType == "Sales Executive") {
      this.partnerDropdown = [];
      this.SalesExecutiveData.forEach((obj: any) => {
        this.partnerDropdown.push({
          PartnerId: obj.EmpId,
          Partner_Name: obj.EmpName,
        });
      });
      this.LeadSourceName = 0;
    } else {
      this.LeadSourceName = "";
    }
  }

  GetSalesExecutive() {
    this.losService
      .getEmployeeDropdown({
        BranchId: this.SelectBranch || 0,
        ProcessId: 1,
        ProductId: this.SelectProduct || 0,
      })
      .subscribe((res: any) => {
        // this.employeeDropdown = res;
        this.SalesExecutiveData = res;
        this.Get_PartnerForDropdown();
        //console.log('res', res);
      });
  }

  getSlabsList(Slab_Type) {
    this._MasterService
      .Get__Slab_dropdown({
        Type: Slab_Type,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((result) => {
        this.SlabData = JSON.parse(JSON.stringify(result));
      });
  }

  onSearchDueList() {
    this.showSpinner = true;

    //console.log(_finalData);
    if (this.DueListfrom != undefined && this.DueListfrom == "LMS") {
      let _finalData = {
        Search: {
          DueUpto: new Date(),
          LoginUserId: this.currentUser.userId,
          ReceiptUpTo: new Date(),
          Type: this.CheckType,
          BranchId: this.SelectBranch,
          ProductId: this.SelectProduct,
          CollectionExecutive: this.collection_exec,
          SourceType: this.SelectSourceType,
          SourceId: this.LeadSourceName,
          slabId: this.SlabId
        },
      };
      this.lmsService
        .Customer_Get_DueList({ JSON: JSON.stringify(_finalData) })
        .subscribe((res: any) => {
          console.log("LMS :", res);
          if (res.length > 0) {
            this.JsonData = JSON.parse(JSON.stringify(res));
            this.dataSource = new MatTableDataSource(
              JSON.parse(JSON.stringify(res))
            );
            this.dataSource.paginator = this.paginator;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.showSpinner = false;
            //this.dataSource.filterPredicate = this.createFilter();
            this.div_due_list = true;
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "No data found",
              ...this.configSuccess,
            });
            this.showSpinner = false;
          }
        });
    } else {
      let _finalData = {
        Search: {
          DueUpto: this.DueDateFrom,
          LoginUserId: this.currentUser.userId,
          ReceiptUpTo: this.DueDateTo,
          Type: this.CheckType,
          BranchId: this.SelectBranch,
          ProductId: this.SelectProduct,
          CollectionExecutive: this.collection_exec,
          SourceType: this.SelectSourceType || "",
          SourceId: this.LeadSourceName || 0,
          slabId: this.SlabId,
        },
      };

      console.log("Request For GET DUE LIST-FinalDATA", _finalData);
      this.reportsService
        .Get_Due_List({ JSON: JSON.stringify(_finalData) })
        .subscribe((res: any) => {
          //console.log("Report :", res);
          if (res.length > 0) {
            this.JsonData = JSON.parse(JSON.stringify(res));

            this.dataSource = new MatTableDataSource(
              JSON.parse(JSON.stringify(res))
            );
            this.dataSource.sort = this.sort;
            this.showSpinner = false;
            this.dataSource.paginator = this.paginator;
            // this.dataSource.filterPredicate = this.createFilter();
            this.div_due_list = true;
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "No data found",
              ...this.configSuccess,
            });
            this.dataSource = null;
            this.showSpinner = false;
          }
        });
    }
  }
  onGenerateDueList() {
    this.showSpinner = true;
    this.lmsService.LMS_Save_DueList({}).subscribe((res: any) => {
      if (res[0].CODE >= 0) {
        this.onSearchDueList();
        this.showSpinner = false;
      }
      this.showSpinner = false;
    });
  }
  getProductDropdown() {
    this._MasterService
      .GetProductList({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.ProductList = JSON.parse(JSON.stringify(result));
      });
  }
  getBranchesList() {
    this.lmsService
      .GetBranches({ Emp_Id: this.currentUser.userId })
      .subscribe((result) => {
        this.BranchesList = JSON.parse(JSON.stringify(result));
      });
  }
  getCollectionExecutive() {
    this.lmsService
      .Get_Lms_CollectionExecutive({ Branch_Id: 0 })
      .subscribe((result) => {
        this.CollectionExecutiveData = JSON.parse(JSON.stringify(result));
      });
  }

  exportexcelNew(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById("export-due") as HTMLTableElement;
    const worksheetName = "DUE LIST"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  saveAsExcelFile(excelBuffer: any, excelFileName: any) {
    throw new Error("Method not implemented.");
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  //applyFilter(filterValues: string) {
  //  if (this.Search == 'LoanNo') {
  //    this.filterValues.LoanNo = filterValues;
  //    this.filterValues.ApplicationNo = '';
  //    this.filterValues.CustomerName = '';
  //    this.filterValues.Customer_PhoneNo = '';
  //    this.filterValues.Branch_Name = '';
  //    this.filterValues.Area_Name = '';
  //    this.filterValues.Sub_Area_Name = '';
  //    this.filterValues.CollectionExecutive = '';
  //    this.dataSource.filter = JSON.stringify(this.filterValues);
  //  }
  //  if (this.Search == 'ApplicationNo') {
  //    this.filterValues.ApplicationNo = filterValues;
  //    this.filterValues.LoanNo = '';
  //    this.filterValues.CustomerName = '';
  //    this.filterValues.Customer_PhoneNo = '';
  //    this.filterValues.Branch_Name = '';
  //    this.filterValues.Area_Name = '';
  //    this.filterValues.Sub_Area_Name = '';
  //    this.filterValues.CollectionExecutive = '';
  //    this.dataSource.filter = JSON.stringify(this.filterValues);
  //  }
  //  if (this.Search == 'CustomerName') {
  //    this.filterValues.CustomerName = filterValues;
  //    this.filterValues.LoanNo = '';
  //    this.filterValues.ApplicationNo = '';
  //    this.filterValues.Customer_PhoneNo = '';
  //    this.filterValues.Branch_Name = '';
  //    this.filterValues.Area_Name = '';
  //    this.filterValues.Sub_Area_Name = '';
  //    this.filterValues.CollectionExecutive = '';
  //    this.dataSource.filter = JSON.stringify(this.filterValues);
  //  }
  //  if (this.Search == 'Customer_PhoneNo') {
  //    this.filterValues.CustomerName = '';
  //    this.filterValues.LoanNo = '';
  //    this.filterValues.ApplicationNo = '';
  //    this.filterValues.Customer_PhoneNo = filterValues;
  //    this.filterValues.Branch_Name = '';
  //    this.filterValues.Area_Name = '';
  //    this.filterValues.Sub_Area_Name = '';
  //    this.filterValues.CollectionExecutive = '';
  //    this.dataSource.filter = JSON.stringify(this.filterValues);
  //  }
  //  if (this.Search == 'Branch_Name') {
  //    this.filterValues.CustomerName = '';
  //    this.filterValues.LoanNo = '';
  //    this.filterValues.ApplicationNo = '';
  //    this.filterValues.Customer_PhoneNo = '';
  //    this.filterValues.Branch_Name = filterValues;
  //    this.filterValues.Area_Name = '';
  //    this.filterValues.Sub_Area_Name = '';
  //    this.filterValues.CollectionExecutive = '';
  //    this.dataSource.filter = JSON.stringify(this.filterValues);
  //  }
  //  if (this.Search == 'Area_Name') {
  //    this.filterValues.CustomerName = '';
  //    this.filterValues.LoanNo = '';
  //    this.filterValues.ApplicationNo = '';
  //    this.filterValues.Customer_PhoneNo = '';
  //    this.filterValues.Branch_Name = '';
  //    this.filterValues.Area_Name = filterValues;
  //    this.filterValues.Sub_Area_Name = '';
  //    this.filterValues.CollectionExecutive = '';
  //    this.dataSource.filter = JSON.stringify(this.filterValues);
  //  }
  //  if (this.Search == 'Sub_Area_Name') {
  //    this.filterValues.CustomerName = '';
  //    this.filterValues.LoanNo = '';
  //    this.filterValues.ApplicationNo = '';
  //    this.filterValues.Customer_PhoneNo = '';
  //    this.filterValues.Branch_Name = '';
  //    this.filterValues.Area_Name = '';
  //    this.filterValues.Sub_Area_Name = filterValues;
  //    this.filterValues.CollectionExecutive = '';
  //    this.dataSource.filter = JSON.stringify(this.filterValues);
  //  }
  //  if (this.Search == 'CollectionExecutive') {
  //    this.filterValues.CustomerName = '';
  //    this.filterValues.LoanNo = '';
  //    this.filterValues.ApplicationNo = '';
  //    this.filterValues.Customer_PhoneNo = '';
  //    this.filterValues.Branch_Name = '';
  //    this.filterValues.Area_Name = '';
  //    this.filterValues.Sub_Area_Name = '';
  //    this.filterValues.CollectionExecutive = filterValues;
  //    this.dataSource.filter = JSON.stringify(this.filterValues);
  //  }
  //}

  //createFilter() {
  //  let filterFunction = function (data, filter): boolean {
  //    let searchTerms = JSON.parse(filter);
  //    //console.log(searchTerms);
  //    return data.LoanAcNo.indexOf(searchTerms.LoanNo) !== -1
  //      && data.Application_No.indexOf(searchTerms.ApplicationNo) !== -1
  //      && data.CustomerName.indexOf(searchTerms.CustomerName) !== -1
  //      && data.Customer_PhoneNo.indexOf(searchTerms.Customer_PhoneNo) !== -1
  //      && data.Branch_Name.indexOf(searchTerms.Branch_Name) !== -1
  //      && data.Area_Name.indexOf(searchTerms.Area_Name) !== -1
  //      && data.Sub_Area_Name.indexOf(searchTerms.Sub_Area_Name) !== -1
  //      && data.CollectionExecutive.indexOf(searchTerms.CollectionExecutive) !== -1;
  //  }
  //  return filterFunction;
  //}

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `due-list`,
      sheet: "due-list",
    });
  }

  StatusChange() {}
  goToLoanStatement(row) {
    this.loanSummary.LoanNo = row.LoanAcNo;
    this.loanSummary.LoanId = row.Loan_Id;
    this.Loan_Statement = true;
    this.dataSharingService.LmsViewLoanStatement.next(this.loanSummary);
    $("#ViewLoanStatementModel").modal("show");
    $("#ViewLoanStatementModel").css("z-index", "1050");
  }
  onCloseLoanStatementModel() {
    $("#ViewLoanStatementModel").modal("hide");
  }

  totaltypewise(type) {
    if (this.dataSource != null) {
      let sum = 0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i][type]);
      }
      return sum > 0 ? sum.toFixed(2) : 0;
    }
    return 0;
  }

  public highlightRow(row) {
    this.selectedId = row.Loan_Id;
  }

  // Excel export in backend start here
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    //this.saveAsExcelFile(excelBuffer, excelFileName);
    this.saveAsExcelFile(excelBuffer, excelFileName);

    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      excelFileName + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  exportExcel() {
    this.JsonData.forEach((obj: any) => {
      delete obj.Loan_Id;
    });
    this.exportAsExcelFile(this.JsonData, "Due-List");
  }
  // Excel export in backend code end here
}
