<div>
  <!-- Form Starts Here -->
  <app-mat-spinner-overlay *ngIf="showSpinner"></app-mat-spinner-overlay>
  <form
    #rl="ngForm"
    novalidate
    (keydown.enter)="repossessList()"
    class="formborder"
    style="display: flex; align-items: center; gap: 20px"
  >
    <div class="row col-md-12 p-0 d-flex">
      <div class="col-md-2 row m-0">
        <span class="required-lable">Status</span>
        <select
          name="status_rl"
          id="status_rl"
          [(ngModel)]="StatusRl"
          required
          class="form-control input-text-css"
          #refstatus_rl="ngModel"
          [ngClass]="{
            'is-invalid': rl.submitted && refstatus_rl.invalid,
            'alert-warning':
              refstatus_rl.invalid &&
              (refstatus_rl.dirty ||
                refstatus_rl.touched ||
                refstatus_rl.untouched)
          }"
        >
          <option value="Pending">Pending</option>
          <option value="Repossessed">Repossessed</option>
          <option value="Released">Released</option>
        </select>
      </div>
      <div class="col-md-2">
        <button
          type="button"
          class="mt-3 btn font-size-12 button-btn"
          (click)="repossessList()"
          [disabled]="!rl.form.valid"
        >
          Search
        </button>
      </div>
      <div class="col-md-3 mt-2">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-3 p-1 d-flex align-items-end justify-content-end">
        <button
          type="button"
          (click)="AddNewRepossess()"
          class="mt-3 btn font-size-12 button-btn"
        >
          Add New Repossess
        </button>
      </div>
    </div>
  </form>
  <!-- Form Ends Here -->

  <div class="mt-3" [hidden]="!dataSource">
    <div class="">
      <div class="table-responsive" id="reposseslist" style="overflow: auto">
        <table
          mat-table
          class="table-bordered"
          matSort
          [dataSource]="dataSource"
          id="repossessList"
          style="max-height: 5px"
        >
          <ng-container matColumnDef="Loan_Id">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let row; let i = index">
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Repossessed_Id">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let row; let i = index">
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="LoanAcNo">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Loan Account No
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.LoanAcNo }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="LoanAmount">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Loan Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.LoanAmount }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Customer_Name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Customer Name
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Customer_Name }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="DPD">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>DPD</th>
            <td mat-cell *matCellDef="let row" class="text-center">
              {{ row.DPD }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="VehicleRegistationNo">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Vehicle Registation No
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.VehicleRegistationNo }}
            </td>

            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Vehicle_Model">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Vehicle Model
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Vehicle_Model }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="OverDueAmount">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Over Due Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.OverDueAmount }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="Parking_Name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Parking Yard
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Parking_Name }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>
          <ng-container matColumnDef="LoanOutStanding">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Loan OutStanding
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.LoanOutStanding }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="AssetName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Asset Name
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.AssetName }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Repossessed_By">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Repossessed By
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Repossessed_By }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Repossessed_on">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Repossessed on
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Repossessed_on | date : "MM/dd/yyyy" }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Type">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let row">
              {{ row.Type }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="ReleaseAmount">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Release Amount
            </th>
            <td mat-cell *matCellDef="let row" class="text-right">
              {{ row.ReleaseAmount }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="ReleaseOn">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Release On
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.ReleaseOn | date : "MM/dd/yyyy" }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Action">
            <mat-header-cell
              class="grid-header j-c-center"
              style="max-width: 60px"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell j-c-center"
              style="max-width: 60px"
            >
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                (click)="goToReposses(row)"
                aria-label="true"
              ></i>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="editParkingYard">
            <mat-header-cell
              class="grid-header j-c-center"
              style="max-width: 70px"
              *matHeaderCellDef
              >Edit Parking Yard</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="
                max-width: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                (click)="editParkingYardFn(row)"
                aria-label="true"
              ></i>
            </mat-cell>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="sticky-footer fontcolor"
            style="background: #4dc3a3; max-height: 5px"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="row m-0 ml-3" [hidden]="!dataSource">
        <div class="col-md-4 mt-3">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportTable()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-8 mt-3">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>

  <!-- Table Ends Here -->
</div>

<div
  class="modal fade in"
  id="AddReposses"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Reposses
        </h6>
        <button
          type="button"
          (click)="onCloseAddRepossesModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0">
          <!-- [ngClass]="{'fixheight': loanSummary != undefined && !PageType, 'fixheight2': loanSummary != undefined && PageType == 'A' }" -->
          <div class="col-md-12 p-0">
            <h1 class="fs-12 h-b slideDiv">
              <div class="row m-0" style="position: sticky">
                <div class="col-md-2">Loan Details</div>
              </div>
            </h1>
          </div>
          <div class="col-md-12 p-0 mb-2" id="sectionToPrint">
            <div class="row m-0">
              <div class="col-md-3">
                <h6 class="fs-12">Application No</h6>
                <p class="lead fw-7 fs-12">
                  {{ ApplicationDetail.ApplicationNo }}
                </p>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">Application Date</h6>
                <p class="lead fs-12">
                  {{ ApplicationDetail.ApplicationCreateOn }}
                </p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Loan No</h6>
                <p class="lead fs-12">
                  {{
                    ApplicationDetail != undefined
                      ? ApplicationDetail.LoanAcNo
                      : ""
                  }}
                </p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Loan Date</h6>
                <p class="lead fs-12">{{ ApplicationDetail.Loan_Date }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Branch</h6>
                <p class="lead fs-12">{{ ApplicationDetail.Branch }}</p>
              </div>

              <div class="col-md-3">
                <h6 class="fs-12">Product</h6>
                <p class="lead fs-12">{{ ApplicationDetail.Product }}</p>
              </div>
              <div class="col-md-3">
                <h6 class="fs-12">Loan Purpose</h6>
                <p class="lead fs-12">{{ ApplicationDetail.LoanPurpose }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Net Finance</h6>
                <p class="lead fs-12">
                  ₹
                  {{
                    ApplicationDetail.NetFinance
                      ? ApplicationDetail.NetFinance.toFixed(2)
                      : 0
                  }}
                </p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Expiry Date</h6>
                <p class="lead fs-12">{{ ApplicationDetail.ExpiryDate }}</p>
              </div>
              <div class="col-md-2" *ngIf="ApplicationDetail.CloseDate">
                <h6 class="fs-12">Close Date</h6>
                <p
                  class="lead fs-12"
                  [ngClass]="{
                    'alert-danger':
                      ApplicationDetail.CloseDate != '' ||
                      ApplicationDetail.CloseDate != null
                  }"
                >
                  {{ ApplicationDetail.CloseDate }}
                </p>
              </div>
            </div>

            <div class="table-responsive">
              <h1 class="fs-12 m-0 mb-1">Customer Details:</h1>
              <mat-table
                [dataSource]="customerDataSource"
                matSort
                matTableExporter
                #exporter="matTableExporter"
              >
                <ng-container matColumnDef="CustomerId">
                  <mat-header-cell class="grid-header mw50" *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell mw50"
                  >
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Customer">
                  <mat-header-cell class="grid-header mw200" *matHeaderCellDef
                    >Customer Name</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell mw200"
                  >
                    <app-lms-customer-view-model
                      [customerId]="row.CustomerId"
                      [index]="i"
                      >{{ row.Customer }}</app-lms-customer-view-model
                    >
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="PhoneNo">
                  <mat-header-cell class="grid-header mw100" *matHeaderCellDef
                    >Phone no</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw100">
                    <div *ngIf="currentUser.IsMaskingMobile">
                      {{ row.PhoneNo | mask }}
                    </div>

                    <div *ngIf="!currentUser.IsMaskingMobile">
                      {{ row.PhoneNo }}
                    </div>

                    <i
                      *ngIf="row.PhoneNoIsVerified"
                      class="fa fa-check-circle ml-2"
                      style="color: green"
                    ></i>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="CustomerType">
                  <mat-header-cell class="grid-header mw100" *matHeaderCellDef
                    >Customer Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw100">
                    {{
                      row.CustomerType == "Hirer"
                        ? "Borrower"
                        : row.CustomerType
                    }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="GenderAge">
                  <mat-header-cell class="grid-header mw100" *matHeaderCellDef
                    >Gender/Age</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
                    row.GenderAge
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="ExistingCustomer">
                  <mat-header-cell class="grid-header mw100" *matHeaderCellDef
                    >Is Existing</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
                    row.ExistingCustomer
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Relation_With_Hirer">
                  <mat-header-cell class="grid-header mw100" *matHeaderCellDef
                    >Relation</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
                    row.Relation_With_Hirer
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Present_Address">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Present Address</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Present_Address
                  }}</mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedCustomerDetailColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedCustomerDetailColumns"
                ></mat-row>
              </mat-table>
            </div>

            <div class="table-responsive mt-2" [hidden]="!dataSourceAssets">
              <h1 class="fs-12 m-2 mb-1">Assets Details:</h1>
              <mat-table
                [dataSource]="dataSourceAssets"
                matSort
                matTableExporter
                #exporter="matTableExporter"
              >
                <ng-container matColumnDef="Collateral_Type">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw200"
                    *matHeaderCellDef
                    >Collateral Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw200">
                    {{ row.Collateral_Type }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Collateral">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw200"
                    *matHeaderCellDef
                    >Collateral</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw200">
                    {{ row.Collateral }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ValuationType">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw200"
                    *matHeaderCellDef
                    >ValuationType</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw200">
                    {{ row.ValuationType }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ValuationAmount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw200"
                    *matHeaderCellDef
                    >ValuationAmount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw200">
                    {{ row.ValuationAmount }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ValuationDoneBy">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >ValuationDoneBy</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ValuationDoneBy }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ValuationDate">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw200"
                    *matHeaderCellDef
                    >ValuationDate</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw200">
                    {{ row.ValuationDate }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ActionView">
                  <mat-header-cell
                    class="grid-header j-c-center"
                    style="max-width: 60px"
                    *matHeaderCellDef
                    >Action</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell j-c-center"
                    style="max-width: 60px"
                  >
                    <a
                      href="javascript:void(0)"
                      class="mr-2"
                      (click)="getAssetDetail(row, false)"
                      >View</a
                    >
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Action">
                  <mat-header-cell
                    class="grid-header justify-content-center mw200"
                    *matHeaderCellDef
                  >
                    <button
                      class="staticIcon"
                      style="
                        float: right;
                        margin-top: -10px;
                        margin-left: 120px;
                      "
                      mat-icon-button
                    >
                      <mat-icon
                        *ngIf="!showLess_AssetsDetail"
                        (click)="showAssetsResult()"
                        >expand_more</mat-icon
                      >
                      <mat-icon
                        *ngIf="showLess_AssetsDetail"
                        (click)="hideAssetsResult()"
                        >expand_less</mat-icon
                      >
                    </button>
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell mw200"
                  ></mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedAssetsColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedAssetsColumns"
                ></mat-row>
              </mat-table>
            </div>
            <div class="m-2" style="background-color: #c8e1be">
              <form #Repo="ngForm" novalidate>
                <div class="row m-0 align-items-center">
                  <div class="col-md-3 p-1">
                    <span class="required-lable">Assets :</span>
                    <select
                      name="AssetsId"
                      AssetsId="Id"
                      #refId="ngModel"
                      (change)="assetsChange()"
                      [ngClass]="{
                        'is-invalid': Repo.submitted && refId.invalid,
                        'alert-warning':
                          refId.invalid &&
                          (refId.dirty || refId.touched || refId.untouched)
                      }"
                      [(ngModel)]="RepoForm.AssetsId"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Assets Name</option>
                      <option
                        *ngFor="let list of AssetsList"
                        [value]="list.AssetId"
                      >
                        {{ list.AssetName }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3 p-1">
                    <span class="required-lable">Parking Yard :</span>
                    <select
                      name="ParkingYardId"
                      id="ParkingYardId"
                      #refParkingYardId="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          Repo.submitted && refParkingYardId.invalid,
                        'alert-warning':
                          refParkingYardId.invalid &&
                          (refParkingYardId.dirty ||
                            refParkingYardId.touched ||
                            refParkingYardId.untouched)
                      }"
                      [(ngModel)]="RepoForm.ParkingYardId"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Parking Yard</option>
                      <option
                        *ngFor="let list of ParkingYardList"
                        [value]="list.Yard_Id"
                      >
                        {{ list.Parking_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2 p-1">
                    <span class="required-lable">Repossed By :</span>
                    <select
                      name="RepossedBy"
                      id="RepossedBy"
                      #refRepossedBy="ngModel"
                      [ngClass]="{
                        'is-invalid': Repo.submitted && refRepossedBy.invalid,
                        'alert-warning':
                          refRepossedBy.invalid &&
                          (refRepossedBy.dirty ||
                            refRepossedBy.touched ||
                            refRepossedBy.untouched)
                      }"
                      [(ngModel)]="RepoForm.RepossedBy"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Repossed By</option>
                      <option
                        *ngFor="let partner of PartnerList"
                        [value]="partner.PartnerId"
                      >
                        {{ partner.Partner_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2 p-1">
                    <span class="required-lable">Repossed On :</span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="RepossedOn"
                        id="RepossedOn"
                        #refRepossedOn="ngModel"
                        [(ngModel)]="RepoForm.RepossedOn"
                        required
                        (dateChange)="initializeVoucherDetail()"
                        [ngClass]="{
                          'is-invalid': Repo.submitted && refRepossedOn.invalid,
                          'alert-warning':
                            refRepossedOn.invalid &&
                            (refRepossedOn.dirty ||
                              refRepossedOn.touched ||
                              refRepossedOn.untouched)
                        }"
                        class="form-control input-text-css"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">Loan OutStanding </span>
                    <input
                      type="text"
                      readonly
                      name="AssetsValue"
                      id="AssetsValue"
                      #refAssetsValue="ngModel"
                      [(ngModel)]="RepoForm.AssetsValue"
                      class="form-control input-text-css"
                    />
                  </div>
                </div>
              </form>

              <h1 class="fs-12 m-2 mb-1">Seized Charges:</h1>
              <form #df="ngForm" novalidate>
                <div
                  class="row m-0 add-remove"
                  *ngFor="
                    let vdData of voucherModel.Voucher_Detail;
                    let i = index
                  "
                >
                  <div class="col-md-12 row m-0 type-column">
                    <div class="type mr_top" style="width: 60px">
                      <div
                        [ngClass]="{ 'bg-success1': i == 0 }"
                        *ngIf="i == 0"
                        style="width: 60px"
                      >
                        <span class="required-lable">Type</span>
                      </div>
                      <span class="form-control1" >{{
                        vdData.TranType
                      }}</span>

                      <!--<select name="TranType{{ i }}" id="TranType{{ i }}" required #refTranType="ngModel" disabled
                  [ngClass]="{ 'is-invalid': df.submitted && refTranType.invalid, 'alert-warning': refTranType.invalid  && (refTranType.dirty || refTranType.touched || refTranType.untouched), 'mt-1' : i==0  }"
                  [(ngModel)]="vdData.TranType" class="form-control pl-1 input-text-css">
            <option [value]="'DR'"> DR </option>
            <option [value]="'CR'"> CR </option>
          </select>-->
                    </div>
                    <div class="row m-0 feild">
                      <ng-container>
                        <div class="col-md-4 mr_top pr-0">
                          <div
                            [ngClass]="{ 'bg-success1': i == 0 }"
                            *ngIf="i == 0"
                          >
                            <span
                              class=""
                              [ngClass]="{
                                'required-lable': vdData.IsLoanChargeable
                              }"
                              >Category</span
                            >
                          </div>
                          <input
                            type="text"
                            name="ChargeHeadId{{ i }}"
                            id="ChargeHeadId{{ i }}"
                            required
                            disabled
                            #refChargeHeadId="ngModel"
                            [ngClass]="{
                              'is-invalid':
                                df.submitted && refChargeHeadId.invalid,
                              'alert-warning':
                                refChargeHeadId.invalid &&
                                (refChargeHeadId.dirty ||
                                  refChargeHeadId.touched ||
                                  refChargeHeadId.untouched),
                              'mt-1': i == 0
                            }"
                            [(ngModel)]="vdData.ChargeHead"
                            class="form-control input-text-css pr-3"
                          />

                          <!--<select name="ChargeHeadId{{ i }}" id="ChargeHeadId{{ i }}" required #refChargeHeadId="ngModel"
                      [ngClass]="{ 'is-invalid': df.submitted && refChargeHeadId.invalid, 'alert-warning': refChargeHeadId.invalid  && (refChargeHeadId.dirty || refChargeHeadId.touched || refChargeHeadId.untouched), 'mt-1' : i==0 }"
                      [(ngModel)]="vdData.ChargeHeadId" class="form-control input-text-css"
                      [disabled]="!vdData.ProductId">
                <option value=""> Select Category </option>
                <option *ngFor="let item of chargesHeadDropdown[i]" [value]="item.Id">{{item.ChagesHead}}</option>
              </select>-->
                        </div>
                        <div
                          class="col-md-4 mr_top pr-0"
                          *ngIf="vdData.CaseNo != ''"
                        >
                          <div
                            [ngClass]="{ 'bg-success1': i == 0 }"
                            *ngIf="i == 0"
                          >
                            <span class="required-lable">Account Head</span>
                          </div>

                          <input
                            type="text"
                            name="AccountId{{ i }}"
                            id="AccountId{{ i }}"
                            required
                            #refAccountId="ngModel"
                            disabled
                            [ngClass]="{
                              'is-invalid':
                                df.submitted && refAccountId.invalid,
                              'alert-warning':
                                refAccountId.invalid &&
                                (refAccountId.dirty ||
                                  refAccountId.touched ||
                                  refAccountId.untouched),
                              'mt-1': i == 0
                            }"
                            [(ngModel)]="vdData.ChargeHeadCategory"
                            class="form-control input-text-css pr-3"
                          />

                          <!--<select name="AccountId{{ i }}" id="AccountId{{ i }}" required #refAccountId="ngModel" disabled
                      [ngClass]="{ 'is-invalid': df.submitted && refAccountId.invalid, 'alert-warning': refAccountId.invalid  && (refAccountId.dirty || refAccountId.touched || refAccountId.untouched), 'mt-1' : i==0 }"
                      [(ngModel)]="vdData.AccountId" class="form-control input-text-css">
                <option value=""> Select Head </option>
                <option *ngFor="let item of accountHeadDropdown" [value]="item.AccountId">{{item.Account_Name}}</option>
              </select>-->
                        </div>

                        <div class="col-md-2 mr_top pr-0 pl-1">
                          <div
                            [ngClass]="{ 'bg-success1': i == 0 }"
                            *ngIf="i == 0"
                          >
                            <span
                              class=""
                              [ngClass]="{
                                'required-lable': vdData.TranType == 'DR'
                              }"
                              >Amt (DR)</span
                            >
                          </div>
                          <input
                            type="text"
                            min="0"
                            name="Amount{{ i }}"
                            id="Amount{{ i }}"
                            *ngIf="vdData.TranType == 'DR'"
                            appTwoDigitDecimaNumber
                            [disabled]="vdData.ChargeHeadId == 35"
                            class="form-control input-text-css text-right pr-3"
                            [required]="vdData.TranType == 'DR'"
                            #refAmount="ngModel"
                            [(ngModel)]="vdData.Amount"
                            (change)="onChangeAmount(i, vdData)"
                            (keyup.enter)="onChangeAmount(i, vdData)"
                            [ngClass]="{
                              'is-invalid': df.submitted && refAmount.invalid,
                              'alert-warning':
                                refAmount.invalid &&
                                (refAmount.dirty ||
                                  refAmount.touched ||
                                  refAmount.untouched),
                              'mt-1': i == 0
                            }"
                          />
                          <input
                            type="text"
                            name="AmountD{{ i }}"
                            id="AmountD{{ i }} "
                            appTwoDigitDecimaNumber
                            readonly
                            [ngClass]="{ 'mt-1': i == 0 }"
                            class="form-control input-text-css text-right pr-3"
                            *ngIf="vdData.TranType == 'CR'"
                          />
                        </div>
                        <div class="col-md-2 mr_top pr-0 pl-1">
                          <div
                            [ngClass]="{ 'bg-success1': i == 0 }"
                            *ngIf="i == 0"
                          >
                            <span
                              class=""
                              [ngClass]="{
                                'required-lable': vdData.TranType == 'CR'
                              }"
                              >Amt (CR)</span
                            >
                          </div>
                          <input
                            type="text"
                            min="0"
                            name="Amount{{ i }}"
                            id="Amount{{ i }}"
                            *ngIf="vdData.TranType == 'CR'"
                            appTwoDigitDecimaNumber
                            [disabled]="vdData.ChargeHeadId == 35"
                            [(ngModel)]="vdData.Amount"
                            class="form-control input-text-css text-right pr-3"
                            [required]="vdData.TranType == 'CR'"
                            #refAmount="ngModel"
                            (change)="onChangeAmount(i, vdData)"
                            (keyup.enter)="onChangeAmount(i, vdData)"
                            [ngClass]="{
                              'is-invalid': df.submitted && refAmount.invalid,
                              'alert-warning':
                                refAmount.invalid &&
                                (refAmount.dirty ||
                                  refAmount.touched ||
                                  refAmount.untouched),
                              'mt-1': i == 0
                            }"
                          />
                          <input
                            type="text"
                            name="AmountD{{ i }}"
                            id="AmountD{{ i }}"
                            appTwoDigitDecimaNumber
                            readonly
                            [ngClass]="{ 'mt-1': i == 0 }"
                            class="form-control input-text-css text-right pr-3"
                            *ngIf="vdData.TranType == 'DR'"
                          />
                        </div>
                      </ng-container>
                    </div>
                  </div>

                  <ng-container
                    *ngIf="vdData.TaxSlab1 && vdData.TaxSlab1.length > 0"
                  >
                    <div
                      class="col-md-12 row m-0 p-0 mt-2"
                      *ngFor="let taxData of vdData.TaxSlab1; let ti = index"
                    >
                      <div class="col-md-12 row m-0 type-column">
                        <div class="type mr_top">
                          <span class="form-control1" style="width: 60px">{{
                            vdData.TranType
                          }}</span>
                        </div>
                        <div class="row m-0 feild">
                          <div class="col-md-4 mr_top pr-0">
                            <span class="form-control1">{{
                              taxData.TaxSlab_Type
                            }}</span>
                          </div>
                          <div
                            class="col-md-4 text-right mr_top pr-0"
                            *ngIf="
                              voucherModel.Voucher.Voucher_Sub_Type == 'Loan' ||
                              voucherModel.Voucher.Voucher_Sub_Type == 'Partner'
                            "
                          >
                            <span class="form-control1">
                              {{ taxData.TaxSlab_Rate }}%</span
                            >
                          </div>
                          <div
                            class="mr_top"
                            [ngClass]="
                              voucherModel.Voucher.Voucher_Sub_Type ==
                              'Accounting'
                                ? 'col-md-2 pl-3 pr-2'
                                : 'col-md-2 pl-1 pr-0'
                            "
                          >
                            <input
                              type="text"
                              [disabled]="vdData.ChargeHeadId == 35"
                              required
                              *ngIf="vdData.TranType == 'DR'"
                              appTwoDigitDecimaNumber
                              name="TaxAmount{{ i }}{{ ti }}"
                              id="TaxAmount{{ i }}{{ ti }}"
                              [(ngModel)]="taxData.TaxAmount"
                              (change)="onChangeTax()"
                              class="form-control input-text-css text-right pr-3"
                            />
                            <span
                              class="form-control1"
                              *ngIf="vdData.TranType == 'CR'"
                              >&nbsp;</span
                            >
                          </div>
                          <div
                            class="mr_top pr-0"
                            [ngClass]="
                              voucherModel.Voucher.Voucher_Sub_Type ==
                              'Accounting'
                                ? 'col-md-2 pl-3'
                                : 'col-md-2 pl-1'
                            "
                          >
                            <input
                              type="text"
                              required
                              [disabled]="vdData.ChargeHeadId == 35"
                              *ngIf="vdData.TranType == 'CR'"
                              appTwoDigitDecimaNumber
                              name="TaxAmount{{ i }}{{ ti }}"
                              id="TaxAmount{{ i }}{{ ti }}"
                              [(ngModel)]="taxData.TaxAmount"
                              (change)="onChangeTax()"
                              class="form-control input-text-css text-right pr-3"
                            />
                            <span
                              class="form-control1"
                              *ngIf="vdData.TranType == 'DR'"
                              >&nbsp;</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </form>

              <div
                class="col-md-12 row pl-3 m-0"
                *ngIf="totalCalculator"
                style="width: 96.3%"
              >
                <div class="col-md-12 bg-success1 p-1">
                  <div class="row">
                    <div
                      [ngClass]="{
                        'col-md-8':
                          voucherModel.Voucher.Voucher_Sub_Type == 'Loan'
                      }"
                    >
                      Grand Total :
                    </div>
                    <div
                      class="text-right pr-1"
                      [ngClass]="{
                        'col-md-2':
                          voucherModel.Voucher.Voucher_Sub_Type == 'Loan'
                      }"
                    >
                      <span>{{ totalCalculator.totalDR.toFixed(2) }}</span>
                    </div>
                    <div
                      class="text-right"
                      [ngClass]="{
                        'col-md-2':
                          voucherModel.Voucher.Voucher_Sub_Type == 'Loan'
                      }"
                    >
                      <span>{{ totalCalculator.totalCR.toFixed(2) }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="table-responsive m-2"
                *ngIf="
                  InstallmentStopInReposses == 1 &&
                  (RepoForm.AssetsId.split('-')[1] == 'Primary Collateral' ||
                    RepoForm.AssetsId.split('-')[1] == 'Primary')
                "
              >
                <h1 class="fs-12 mt-2 mb-1">Current Balance:</h1>
                <mat-table
                  [dataSource]="dataSourceLoanODCharges"
                  #sortList="matSort"
                  matSort
                  class="Table-Css"
                  id="exportReport11"
                >
                  <ng-container matColumnDef="ChagesHead">
                    <mat-header-cell
                      mat-sort-header
                      class="grid-header"
                      *matHeaderCellDef
                      >Charges Head</mat-header-cell
                    >
                    <mat-cell *matCellDef="let row" class="grid-cell">{{
                      row.ChagesHead
                    }}</mat-cell>
                    <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="TranTypeDR">
                    <mat-header-cell
                      class="grid-header text-right"
                      *matHeaderCellDef
                      >Debit</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell text-right"
                      >{{ row.TranTypeDR }}</mat-cell
                    >
                    <mat-footer-cell *matFooterCellDef class="text-right">{{
                      calculateChargesTranTypeDR("OD")
                    }}</mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="TranTypeCR">
                    <mat-header-cell
                      class="grid-header text-right"
                      *matHeaderCellDef
                      >Credit</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell text-right"
                      >{{ row.TranTypeCR }}</mat-cell
                    >
                    <mat-footer-cell *matFooterCellDef class="text-right">{{
                      calculateChargesTranTypeCR("OD")
                    }}</mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="TranTypeTotal">
                    <mat-header-cell
                      class="grid-header text-right"
                      *matHeaderCellDef
                      >Balance</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell text-right"
                      >{{ row.TranTypeTotal }}</mat-cell
                    >
                    <mat-footer-cell *matFooterCellDef class="text-right">{{
                      calculateChargesTranTypeTotal("OD")
                    }}</mat-footer-cell>
                  </ng-container>

                  <ng-container matColumnDef="Action">
                    <mat-header-cell
                      class="grid-header text-center"
                      *matHeaderCellDef
                      >View Detail</mat-header-cell
                    >
                    <mat-cell
                      *matCellDef="let row"
                      class="grid-cell text-center"
                    >
                      <i
                        class="fa fa-arrow-circle-right btn_detail"
                        (click)="goToChargesDetail(row)"
                        aria-label="true"
                      ></i>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="displayedColumnsODCharges"
                  ></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: displayedColumnsODCharges"
                  ></mat-row>
                  <mat-footer-row
                    class="sticky-footer fontcolor"
                    *matFooterRowDef="displayedColumnsODCharges"
                  ></mat-footer-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer non-printable">
        <div class="row m-0 col-md-12 p-1 align-items-center upload">
          <p class="m-0 row align-items-center">
            Browse Doc:<i
              class="fa fa-upload ml-2"
              *ngIf="docModal.DocumentImages.length == 0"
              (click)="docUFile.click()"
              aria-hidden="true"
            ></i>
            <ng-container *ngIf="docModal.DocumentImages.length > 0">
              <div *ngFor="let doc of docModal.DocumentImages; let i = index">
                <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i
                >{{ doc.DocFileName }}
                <i
                  class="fa fa-times ml-2"
                  aria-hidden="true"
                  style="cursor: pointer"
                  (click)="removeDFile(i)"
                ></i>
              </div>
            </ng-container>
          </p>
          <input
            #docUFile
            type="file"
            [multiple]="true"
            accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
            (change)="fileChangeListenerD(docUFile.files, docUFile)"
            style="display: none"
          />
        </div>
        <button
          type="button"
          class="mt-3 btn font-size-12 button-btn"
          (click)="onCloseAddRepossesModel()"
        >
          Cancel
        </button>
        <button
          type="button"
          class="mt-3 btn font-size-12 button-btn"
          (click)="onReposses()"
          [disabled]="!df.form.valid || !Repo.form.valid"
        >
          Reposses
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="GSTModalWindow"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Party Detail
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseGSTModal()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <form #cf="ngForm" novalidate>
        <div class="modal-body">
          <div class="row m-0 mt-1">
            <div class="col-md-4">
              <span class="form-check-inline">
                <label class="form-check-label" for="Existing Party">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="PartyType"
                    name="PartyType"
                    [(ngModel)]="GSTModel.PartyType"
                    required
                    value="Existing_Party"
                    (change)="onPartyTypeChange()"
                  />
                  Existing Party
                </label>
              </span>
            </div>
            <div class="col-md-8">
              <span class="form-check-inline">
                <label class="form-check-label" for="New Party">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="PartyType"
                    name="PartyType"
                    [(ngModel)]="GSTModel.PartyType"
                    required
                    value="New_Party"
                    (change)="onPartyTypeChange()"
                  />
                  New Party
                </label>
              </span>
            </div>
          </div>

          <div class="row m-0 mt-2">
            <div class="col-md-3">
              <span class="required-lable">Tax Slab</span>
            </div>
            <div class="col-md-9">
              <select
                name="Slab_Tax"
                id="Slab_Tax"
                #refSlab_Tax="ngModel"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': cf.submitted && refSlab_Tax.invalid,
                  'alert-warning':
                    refSlab_Tax.invalid &&
                    (refSlab_Tax.dirty ||
                      refSlab_Tax.touched ||
                      refSlab_Tax.untouched)
                }"
                [(ngModel)]="GSTModel.Slab_Tax"
              >
                <option value="">Select Slab</option>
                <option
                  [value]="slab.TaxSlab_Name"
                  *ngFor="let slab of slabList"
                >
                  {{ slab.TaxSlab_Name }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="row m-0 mt-2"
            *ngIf="
              GSTModel.PartyType == 'Existing_Party' &&
              voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'
            "
          >
            <div class="col-md-3"><span>Account</span></div>
            <div class="col-md-9">
              <select
                name="AccountHeadId"
                id="AccountHeadId"
                #refAccountHeadId="ngModel"
                [(ngModel)]="GSTModel.AccountHeadId"
                class="form-control input-text-css"
                (change)="onAccountHeadChange()"
              >
                <option value="">Select Account</option>
                <option
                  *ngFor="let item of AccountHeadForGST"
                  [value]="item.AccountId"
                >
                  {{ item.Account_Name }}
                </option>
              </select>
            </div>
          </div>

          <div
            class="row m-0 mt-2"
            *ngIf="
              GSTModel.PartyType == 'New_Party' ||
              (GSTModel.PartyType == 'Existing_Party' &&
                (voucherModel.Voucher.Voucher_Sub_Type == 'Loan' ||
                  voucherModel.Voucher.Voucher_Sub_Type == 'Partner'))
            "
          >
            <div class="col-md-3"><span class="required-lable">Name</span></div>
            <div class="col-md-9">
              <input
                type="text"
                #refCustomer="ngModel"
                class="form-control input-text-css"
                placeholder="Name"
                required
                [ngClass]="{
                  'is-invalid': cf.submitted && refCustomer.invalid,
                  'alert-warning':
                    refCustomer.invalid &&
                    (refCustomer.dirty ||
                      refCustomer.touched ||
                      refCustomer.untouched)
                }"
                [(ngModel)]="GSTModel.Customer"
                name="Customer"
                id="Customer"
                [disabled]="GSTModel.PartyType == 'Existing_Party'"
              />
            </div>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-3"><span>GST No.</span></div>
            <div class="col-md-9">
              <input
                type="text"
                #refAcc_GSTNo="ngModel"
                class="form-control input-text-css"
                placeholder="GST No."
                [(ngModel)]="GSTModel.Acc_GSTNo"
                name="Acc_GSTNo"
                id="Acc_GSTNo"
              />
              <!--[disabled]="GSTModel.PartyType == 'Existing_Party'"-->
            </div>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-3"><span>State</span></div>
            <div class="col-md-9">
              <select
                name="State"
                id="State"
                #refState="ngModel"
                class="form-control input-text-css"
                [(ngModel)]="GSTModel.StateId"
                [disabled]="GSTModel.PartyType == 'Existing_Party'"
              >
                <option value="">Select State</option>
                <option
                  *ngFor="let state of stateDropdown"
                  [value]="state.StateId"
                >
                  {{ state.State_Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 mt-2">
            <div class="col-md-3"><span>HSN Code</span></div>
            <div class="col-md-9">
              <input
                name="HSNCode"
                id="HSNCode"
                [(ngModel)]="GSTModel.HSNCode"
                placeholder="HSN Code"
                class="form-control input-text-css"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light font-size-12"
            (click)="OnCloseGSTModal()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="ml-4 btn font-size-12 button-btn"
            (click)="saveGSTDetails()"
            [class.spinner]="loading"
            [disabled]="!cf.form.valid"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="RepossesFinalModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Are you sure you want to Reposses
        </h6>
        <button
          type="button"
          class="close"
          (click)="OnCloseRepossesFinalModal()"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <form #Rf="ngForm" novalidate>
        <div class="modal-body">
          <div
            class="row m-0 add-remove"
            *ngFor="let vdData of RepossessModel.Voucher_Detail; let i = index"
          >
            <div
              class="col-md-12 row m-0 type-column"
              *ngIf="vdData.Amount != 0"
            >
              <div class="type mr_top">
                <div
                  [ngClass]="{ 'bg-success1': i == 0 }"
                  *ngIf="i == 0"
                  style="width: 60px"
                >
                  <span class="required-lable">Type</span>
                </div>
                <span class="form-control1" style="width: 60px">{{
                  vdData.TranType
                }}</span>
              </div>
              <div class="row m-0 feild">
                <ng-container>
                  <div class="col-md-4 mr_top pr-0">
                    <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                      <span
                        class=""
                        [ngClass]="{
                          'required-lable': vdData.IsLoanChargeable
                        }"
                        >Category</span
                      >
                    </div>
                    <input
                      type="text"
                      name="ChargeHeadId{{ i }}"
                      id="ChargeHeadId{{ i }}"
                      required
                      disabled
                      #refChargeHeadId="ngModel"
                      [ngClass]="{
                        'is-invalid': df.submitted && refChargeHeadId.invalid,
                        'alert-warning':
                          refChargeHeadId.invalid &&
                          (refChargeHeadId.dirty ||
                            refChargeHeadId.touched ||
                            refChargeHeadId.untouched),
                        'mt-1': i == 0
                      }"
                      [(ngModel)]="vdData.ChargeHead"
                      class="form-control input-text-css pr-3"
                    />
                  </div>
                  <div class="col-md-4 mr_top pr-0" *ngIf="vdData.CaseNo != ''">
                    <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                      <span class="required-lable">Account Head</span>
                    </div>

                    <input
                      type="text"
                      name="AccountId{{ i }}"
                      id="AccountId{{ i }}"
                      required
                      #refAccountId="ngModel"
                      disabled
                      [ngClass]="{
                        'is-invalid': df.submitted && refAccountId.invalid,
                        'alert-warning':
                          refAccountId.invalid &&
                          (refAccountId.dirty ||
                            refAccountId.touched ||
                            refAccountId.untouched),
                        'mt-1': i == 0
                      }"
                      [(ngModel)]="vdData.ChargeHeadCategory"
                      class="form-control input-text-css pr-3"
                    />
                  </div>

                  <div class="col-md-2 mr_top pr-0 pl-1">
                    <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                      <span
                        class=""
                        [ngClass]="{
                          'required-lable': vdData.TranType == 'DR'
                        }"
                        >Amt (DR)</span
                      >
                    </div>
                    <input
                      type="text"
                      min="0"
                      name="Amount{{ i }}"
                      id="Amount{{ i }}"
                      *ngIf="vdData.TranType == 'DR'"
                      disabled
                      appTwoDigitDecimaNumber
                      [disabled]="vdData.ChargeHeadId == 35"
                      class="form-control input-text-css text-right pr-3"
                      [required]="vdData.TranType == 'DR'"
                      #refAmount="ngModel"
                      [(ngModel)]="vdData.Amount"
                      (change)="onChangeAmount(i, vdData)"
                      (keyup.enter)="onChangeAmount(i, vdData)"
                      [ngClass]="{
                        'is-invalid': df.submitted && refAmount.invalid,
                        'alert-warning':
                          refAmount.invalid &&
                          (refAmount.dirty ||
                            refAmount.touched ||
                            refAmount.untouched),
                        'mt-1': i == 0
                      }"
                    />
                    <input
                      type="text"
                      name="AmountD{{ i }}"
                      id="AmountD{{ i }} "
                      appTwoDigitDecimaNumber
                      readonly
                      [ngClass]="{ 'mt-1': i == 0 }"
                      class="form-control input-text-css text-right pr-3"
                      *ngIf="vdData.TranType == 'CR'"
                    />
                  </div>
                  <div class="col-md-2 mr_top pr-0 pl-1">
                    <div [ngClass]="{ 'bg-success1': i == 0 }" *ngIf="i == 0">
                      <span
                        class=""
                        [ngClass]="{
                          'required-lable': vdData.TranType == 'CR'
                        }"
                        >Amt (CR)</span
                      >
                    </div>
                    <input
                      type="text"
                      min="0"
                      name="Amount{{ i }}"
                      id="Amount{{ i }}"
                      *ngIf="vdData.TranType == 'CR'"
                      disabled
                      appTwoDigitDecimaNumber
                      [disabled]="vdData.ChargeHeadId == 35"
                      [(ngModel)]="vdData.Amount"
                      class="form-control input-text-css text-right pr-3"
                      [required]="vdData.TranType == 'CR'"
                      #refAmount="ngModel"
                      (change)="onChangeAmount(i, vdData)"
                      (keyup.enter)="onChangeAmount(i, vdData)"
                      [ngClass]="{
                        'is-invalid': df.submitted && refAmount.invalid,
                        'alert-warning':
                          refAmount.invalid &&
                          (refAmount.dirty ||
                            refAmount.touched ||
                            refAmount.untouched),
                        'mt-1': i == 0
                      }"
                    />
                    <input
                      type="text"
                      name="AmountD{{ i }}"
                      id="AmountD{{ i }}"
                      appTwoDigitDecimaNumber
                      readonly
                      [ngClass]="{ 'mt-1': i == 0 }"
                      class="form-control input-text-css text-right pr-3"
                      *ngIf="vdData.TranType == 'DR'"
                    />
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div
            class="col-md-12 row pl-3 m-0"
            *ngIf="totalRepossessCalculator"
            style="width: 96.3%"
          >
            <div class="col-md-12 bg-success1 p-1">
              <div class="row">
                <div
                  [ngClass]="{
                    'col-md-8':
                      RepossessModel.Voucher.Voucher_Sub_Type == 'Loan'
                  }"
                >
                  Grand Total :
                </div>
                <div
                  class="text-right pr-1"
                  [ngClass]="{
                    'col-md-2':
                      RepossessModel.Voucher.Voucher_Sub_Type == 'Loan'
                  }"
                >
                  <span>{{ totalRepossessCalculator.totalDR.toFixed(2) }}</span>
                </div>
                <div
                  class="text-right"
                  [ngClass]="{
                    'col-md-2':
                      RepossessModel.Voucher.Voucher_Sub_Type == 'Loan'
                  }"
                >
                  <span>{{ totalRepossessCalculator.totalCR.toFixed(2) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light font-size-12"
            (click)="OnCloseRepossesFinalModal()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="ml-4 btn font-size-12 button-btn"
            (click)="saveRepossessDetail()"
            [class.spinner]="loading"
            [disabled]="!Rf.form.valid"
          >
            Confirm
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="CustomerReceipt"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Customer Receipt
        </h6>
        <button
          type="button"
          (click)="onCloseReceipt()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #f="ngForm"
          (keydown.enter)="onSearchCustomerDetail()"
          novalidate
          class="row m-0 col-md-12 p-0 formborder"
        >
          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-2">
              <span>Loan No.</span>
              <input
                name="SLoanNo"
                [(ngModel)]="Customer.LoanNo"
                id="SLoanNo"
                placeholder="Loan No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Customer Name</span>
              <input
                name="CustomerName"
                [(ngModel)]="Customer.CustomerName"
                id="CustomerName"
                placeholder="Customer Name"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Customer Phone</span>
              <input
                name="CustomerPhone"
                [(ngModel)]="Customer.CustomerPhone"
                id="CustomerPhone"
                placeholder="Customer Phone"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>PAN Card</span>
              <input
                name="PANCard"
                [(ngModel)]="Customer.PANCard"
                id="PANCard"
                placeholder="PAN Card"
                class="form-control input-text-css"
              />
            </div>

            <div class="col-md-2">
              <span>Passport No.</span>
              <input
                name="Passport"
                [(ngModel)]="Customer.Passport"
                id="Passport"
                placeholder="Passport No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Voter ID Card</span>
              <input
                name="VoterID"
                [(ngModel)]="Customer.VoterID"
                id="VoterID"
                placeholder="Voter ID Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Driving License</span>
              <input
                name="DL"
                [(ngModel)]="Customer.DL"
                id="DL"
                placeholder="Driving License"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Aadhaar Card</span>
              <input
                name="Aadhaar"
                [(ngModel)]="Customer.Aadhaar"
                id="Aadhaar"
                placeholder="Aadhaar Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Vehicle No</span>
              <input
                name="VehicleNo"
                [(ngModel)]="Customer.VehicleNo"
                id="VehicleNo"
                placeholder="Vehicle No"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-2">
              <span>Date From</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="LoanDateFrom"
                  id="LoanDateFrom"
                  class="form-control input-text-css"
                  [(ngModel)]="Customer.LoanDateFrom"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
              <!-- <input
                name="LoanDateFrom"
                [(ngModel)]="Customer.LoanDateFrom"
                id="LoanDateFrom"
                placeholder="Date From"
                bsDatepicker
                class="form-control input-text-css"
              /> -->
            </div>
            <div class="col-md-2">
              <span>Date To</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker2"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="LoanDateTo"
                  id="LoanDateTo"
                  class="form-control input-text-css"
                  [(ngModel)]="Customer.LoanDateTo"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </div>
              <!-- <input
                name="LoanDateTo"
                [(ngModel)]="Customer.LoanDateTo"
                id="LoanDateTo"
                placeholder="Date To"
                bsDatepicker
                class="form-control input-text-css"
              /> -->
            </div>

            <!--<div class="col-md-3">
    <span>Close Case</span>
    <select name="CloseCase" id="CloseCase" class="form-control input-text-css" [(ngModel)]="Customer.CloseCase">
      <option value="">Select Close Case</option>
      <option value="1">Close Case</option>
    </select>
  </div>-->
            <div class="col-md-3">
              <span>Select Type</span>
              <select
                name="SelectType"
                id="SelectType"
                class="form-control input-text-css"
                [(ngModel)]="SelectType"
                (change)="SearchChange()"
              >
                <option value="">Select Type</option>
                <option value="Branch">Branch</option>
                <option value="CollArea">Collection Area</option>
                <option value="CollSubArea">Collection Sub Area</option>
                <option value="CollExecutive">Collection Executive</option>
                <option value="SalesExecutive">Sales Executive</option>
                <option value="EngineNo">Engine No.</option>
                <option value="ChassisNo">Chassis No.</option>
                <option value="LoanAccCatg">Loan A/C Category</option>
              </select>
            </div>
            <div class="col-md-3">
              <div *ngIf="SelectType == 'Branch'">
                <span> Branch</span>
                <select
                  name="BranchId"
                  id="BranchId"
                  #refSelectGender="ngModel"
                  [(ngModel)]="Customer.BranchId"
                  class="form-control input-text-css"
                >
                  <option value="">Select Branch</option>
                  <option
                    *ngFor="let branch of BranchesList"
                    [value]="branch.BranchId"
                  >
                    {{ branch.Branch_Name }}
                  </option>
                </select>
              </div>

              <div *ngIf="SelectType == 'EngineNo'">
                <span>Engine No.</span>
                <input
                  name="EngineNo"
                  [(ngModel)]="Customer.EngineNo"
                  id="EngineNo"
                  placeholder="Engine No."
                  class="form-control input-text-css"
                />
              </div>

              <div *ngIf="SelectType == 'ChassisNo'">
                <span>Chassis No.</span>
                <input
                  name="ChassisNo"
                  [(ngModel)]="Customer.ChassisNo"
                  id="ChassisNo"
                  placeholder="Chassis No."
                  class="form-control input-text-css"
                />
              </div>

              <div *ngIf="SelectType == 'CollArea'">
                <span> Collection Area</span>
                <select
                  name="Area"
                  id="Area"
                  [(ngModel)]="Customer.Area"
                  class="form-control input-text-css"
                >
                  <option value="">Select Collection Area</option>
                  <ng-container *ngFor="let area of areaData">
                    <option
                      *ngIf="area.Type.trim() == 'Collection Area'"
                      [value]="area.Int_Id"
                    >
                      {{ area.Area_Name }}
                    </option>
                  </ng-container>
                </select>
              </div>

              <div *ngIf="SelectType == 'CollSubArea'">
                <span> Collection Sub Area</span>
                <select
                  name="SubArea"
                  id="SubArea"
                  [(ngModel)]="Customer.SubArea"
                  class="form-control input-text-css"
                >
                  <option value="">Select Collection SubArea</option>
                  <option
                    *ngFor="let subarea of subAreaData"
                    [value]="subarea.Int_Id"
                  >
                    {{ subarea.Sub_Area_Name }}
                  </option>
                </select>
              </div>

              <div *ngIf="SelectType == 'SalesExecutive'">
                <span> Sales Executive</span>
                <select
                  name="SalesEx"
                  id="SalesEx"
                  [(ngModel)]="Customer.SalesEx"
                  class="form-control input-text-css"
                >
                  <option value="">Select Sales Executive</option>
                  <option
                    *ngFor="let SalesExec of SalesExecutiveData"
                    [value]="SalesExec.EmpId"
                  >
                    {{ SalesExec.EmpName }}
                  </option>
                </select>
              </div>

              <div *ngIf="SelectType == 'CollExecutive'">
                <span> Collection Executive</span>
                <select
                  name="CollectionEx"
                  id="CollectionEx"
                  [(ngModel)]="Customer.CollectionEx"
                  class="form-control input-text-css"
                >
                  <option value="">Select Collection Executive</option>
                  <option
                    *ngFor="let CollExec of CollectionExecutiveData"
                    [value]="CollExec.EmpId"
                  >
                    {{ CollExec.Emp_FirstName }}
                  </option>
                </select>
              </div>

              <div *ngIf="SelectType == 'LoanAccCatg'">
                <span>Accounting Category </span>
                <select
                  name="LoanAccountingCatId"
                  id="LoanAccountingCatId"
                  [(ngModel)]="Customer.LoanAccountingCatId"
                  class="form-control input-text-css"
                >
                  <option value="">Select Accounting Category</option>
                  <option
                    *ngFor="let AcCat of AccCatDropdown"
                    [value]="AcCat.Loan_CategoryId"
                  >
                    {{ AcCat.Loan_category }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-3 text-right">
              <button
                type="button"
                (click)="onSearchCustomerDetail()"
                class="mt-3 btn font-size-12 button-btn"
              >
                Search
              </button>
            </div>
          </div>
        </form>

        <div class="mt-2" [hidden]="!dataSourceCustomer">
          <div class="">
            <div class="table-responsive">
              <mat-table
                [dataSource]="dataSourceCustomer"
                #sortList="matSort"
                matSort
                id="exportReport"
                style="max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="CustomerId">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw50"
                    *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell mw50"
                  >
                    {{
                      paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1)
                    }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Type">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw50"
                    *matHeaderCellDef
                    >Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
                    row.Type
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Application_No">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw100"
                    *matHeaderCellDef
                    >Aplication No</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw100">
                    {{ row.Application_No }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="LoanAcNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw100"
                    *matHeaderCellDef
                  >
                    Loan No</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw100">{{
                    row.LoanAcNo
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="CustomertName">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Customer</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell"
                    >{{ row.CustomertName }}({{
                      row.AC_CustomerType
                    }})</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="FatherName">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Relation Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.FatherName
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Customer_Gender">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw50"
                    *matHeaderCellDef
                    >Gender</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
                    row.Customer_Gender
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="GenderAge">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw50"
                    *matHeaderCellDef
                  >
                    Age</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell mw50">{{
                    row.GenderAge
                  }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="PhoneNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Phone No</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    <div *ngIf="currentUser.IsMaskingMobile">
                      {{ row.PhoneNo | mask }}
                    </div>

                    <div *ngIf="!currentUser.IsMaskingMobile">
                      {{ row.PhoneNo }}
                    </div>

                    <i
                      *ngIf="row.Customer_PhoneNo_IsVerified == true"
                      class="fa fa-check-circle ml-2"
                      style="color: green"
                    ></i>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Vehicle_Model_Name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Vehicle Model_Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Vehicle_Model_Name }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="VehicleRegistationNo">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Registation No</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.VehicleRegistationNo }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="EditAction">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header mw100"
                    *matHeaderCellDef
                  >
                    View Detail</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell j-c-center mw100"
                  >
                    <i
                      class="fa fa-arrow-circle-right arrow-circle action-btn"
                      (click)="goToDetail(row)"
                      aria-label="true"
                    ></i>
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  class="sticky-footer"
                  *matHeaderRowDef="displayedCustomerColumns; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedCustomerColumns"
                ></mat-row>
              </mat-table>

              <mat-paginator
                #paginatorRef
                #PaginatorList
                [pageSizeOptions]="[20, 50]"
                showFirstLastButtons
              ></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="reposseslist"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Account No.
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">DPD</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Vehicle Registation No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Vehicle Model
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OverDue Amount
    </th>
    <th
      *ngIf="StatusRl == 'Repossessed'"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      Parking Yard
    </th>
    <th
      *ngIf="StatusRl == 'Repossessed'"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      Loan OutStanding
    </th>
  </tr>
  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.LoanAmount }}</td>
    <td>{{ x.Customer_Name }}</td>

    <td>{{ x.DPD }}</td>
    <td>{{ x.VehicleRegistationNo }}</td>
    <td>{{ x.Vehicle_Model }}</td>
    <td>{{ x.OverDueAmount }}</td>
    <td *ngIf="StatusRl == 'Repossessed'">{{ x.Parking_Name }}</td>
    <td *ngIf="StatusRl == 'Repossessed'">{{ x.LoanOutStanding }}</td>
  </tr>
</table>

<div
  class="modal fade in"
  id="editParkingYard"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Edit Parking Yard
        </h6>
        <button
          type="button"
          (click)="OnClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <div class="modal-body formborder m-3">
        <form #py="ngForm" (ngSubmit)="onUpdateparkingYard()" novalidate>
          <div class="modal-body">
            <div class="row m-0 font-size-12 align-items-center">
              <div class="col-md-4 p-1">
                <span class="required-lable">Parking Yard </span>
              </div>
              <div class="col-md-8 p-1">
                <input
                  type="text"
                  name="parking_name"
                  id=""
                  [(ngModel)]="parking_name"
                  class="form-control input-text-css"
                  required
                  #refparking_name="ngModel"
                  placeholder="Add Parking Name"
                  [ngClass]="{
                    'is-invalid': py.submitted && refparking_name.invalid,
                    'alert-warning':
                      refparking_name.invalid &&
                      (refparking_name.dirty ||
                        refparking_name.touched ||
                        refparking_name.untouched)
                  }"
                />
              </div>

              <div class="col-md-4 p-1">
                <span class="required-lable">Parking Yard </span>
              </div>
              <div class="col-md-8 p-1">
                <select
                  name="ParkingYardName"
                  id="ParkingYardName"
                  #refParkingYardName="ngModel"
                  [ngClass]="{
                    'is-invalid': py.submitted && refParkingYardName.invalid,
                    'alert-warning':
                      refParkingYardName.invalid &&
                      (refParkingYardName.dirty ||
                        refParkingYardName.touched ||
                        refParkingYardName.untouched)
                  }"
                  [(ngModel)]="ParkingYardName"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Parking Yard</option>
                  <option
                    *ngFor="let list of ParkingYardList"
                    [value]="list.Yard_Id"
                  >
                    {{ list.Parking_Name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer" style="border: none">
        <button
          type="button"
          (click)="onUpdateparkingYard()"
          [disabled]="!py.form.valid"
          class="mt-3 btn font-size-12 button-btn"
          data-dismiss="modal"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="assetModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add {{ assetModel.Collateral_Type }} Collateral
        </h6>
        <button
          type="button"
          (click)="onClosePrimaryAsset()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container>
          <ng-container *ngIf="assetModel.Collateral == 'Vehicle'">
            <div class="row mt-2">
              <div class="col-md-6">
                <p class="lead fs-12">
                  {{ assetModel.Vehicle_Type }} &nbsp;
                  {{ assetModel.Vehicle_SubType }} &nbsp; Vehicle
                </p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-2">
                <h6 class="fs-12">Vehicle Manufacture</h6>
                <p class="lead fs-12">{{ assetModel.VehicleManufacture }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Vehicle Category</h6>
                <p class="lead fs-12">{{ assetModel.VehicleCategory }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Vehicle Model Name</h6>
                <p class="lead fs-12">{{ assetModel.VehicleModelName }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Variant</h6>
                <p class="lead fs-12">{{ assetModel.Variant }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Manufacture Year</h6>
                <p class="lead fs-12">
                  {{ assetModel.MefgYear | date : "yyyy" }}
                </p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Vehicle Registation No</h6>
                <p class="lead fs-12">{{ assetModel.VehicleRegistationNo }}</p>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-2">
                <h6 class="fs-12">Registation Date</h6>
                <p class="lead fs-12">{{ assetModel.RegistationDate }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Registation Expiry Date</h6>
                <p class="lead fs-12">{{ assetModel.RegistationExpiryDate }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Fuel Type</h6>
                <p class="lead fs-12">{{ assetModel.FuelType }}</p>
              </div>
              <div
                class="col-md-2"
                *ngIf="assetModel.Vehicle_SubType == 'Commercial'"
              >
                <h6 class="fs-12">Road Tax Upto</h6>
                <p class="lead fs-12">{{ assetModel.RoadTaxUpto }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Color</h6>
                <p class="lead fs-12">{{ assetModel.Color }}</p>
              </div>
              <div
                class="col-md-2"
                *ngIf="assetModel.Vehicle_SubType == 'Commercial'"
              >
                <h6 class="fs-12">Fitness Upto</h6>
                <p class="lead fs-12">{{ assetModel.FitnessUpto }}</p>
              </div>
            </div>

            <div class="row mt-2">
              <div
                class="col-md-2"
                *ngIf="assetModel.Vehicle_SubType == 'Commercial'"
              >
                <h6 class="fs-12">Permit Upto</h6>
                <p class="lead fs-12">{{ assetModel.PermitUpto }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Vehicle Cost</h6>
                <p class="lead fs-12">{{ assetModel.VehicleCost }}</p>
              </div>
              <div
                class="col-md-2"
                *ngIf="assetModel.Vehicle_SubType == 'Commercial'"
              >
                <h6 class="fs-12">Route</h6>
                <p class="lead fs-12">{{ assetModel.Rout }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Engine No</h6>
                <p class="lead fs-12">{{ assetModel.EngineNo }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Chassis No</h6>
                <p class="lead fs-12">{{ assetModel.ChassisNo }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Key No</h6>
                <p class="lead fs-12">{{ assetModel.KeyNo }}</p>
              </div>
            </div>
            <hr />

            <ng-container *ngIf="assetModel.Vehicle_Type == 'New'">
              <div class="row mt-2">
                <div class="col-md-12">
                  <h5 style="color: black">New Vehicle</h5>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-2">
                  <h6 class="fs-12">DO. No.</h6>
                  <p class="lead fs-12">{{ assetModel.DONo }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">DO. Do Date.</h6>
                  <p class="lead fs-12">{{ assetModel.RegistationDate }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Estimation Amount</h6>
                  <p class="lead fs-12">{{ assetModel.EstimationAmount }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Invoice No.</h6>
                  <p class="lead fs-12">{{ assetModel.InvoiceNo }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Invoice Date</h6>
                  <p class="lead fs-12">{{ assetModel.InvoiceDate }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Invoice Value</h6>
                  <p class="lead fs-12">{{ assetModel.InvoiceValue }}</p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-2">
                  <h6 class="fs-12">Dealer Contact Person</h6>
                  <p class="lead fs-12">{{ assetModel.DealerContactPerson }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Dealer Contact No.</h6>
                  <p class="lead fs-12">{{ assetModel.DealerContactNo }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Quotation in favour of</h6>
                  <p class="lead fs-12">
                    {{ assetModel.DOIssuedinfavourofName }}
                  </p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Remark</h6>
                  <p class="lead fs-12">{{ assetModel.Remark }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Dealer</h6>
                  <p class="lead fs-12">{{ assetModel.DealorName }}</p>
                </div>
              </div>
              <hr />
            </ng-container>

            <ng-container *ngIf="assetModel.Vehicle_Type == 'Used'">
              <div class="row mt-2">
                <div class="col-md-12">
                  <h5 style="color: black">Used Vehicles</h5>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-2">
                  <h6 class="fs-12">Valuation Done By</h6>
                  <p class="lead fs-12">{{ assetModel.ValuationDoneBy }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Valuation Date</h6>
                  <p class="lead fs-12">{{ assetModel.ValuationDate }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Valuation Amount</h6>
                  <p class="lead fs-12">{{ assetModel.ValuationAmount }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Valuation Type</h6>
                  <p class="lead fs-12">{{ assetModel.ValuationType }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Other Valuation Type</h6>
                  <p class="lead fs-12">{{ assetModel.OtherValuationType }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Other Valuation Amount</h6>
                  <p class="lead fs-12">{{ assetModel.OtherValuationAmt }}</p>
                </div>
              </div>
              <hr />
            </ng-container>
            <div class="row mt-2">
              <div
                class="col-md-12 mt-2 upload"
                *ngIf="AssetsAttachment.length > 0"
              >
                <b> Uploaded Vehicle Images </b>

                <div
                  class="row m-0 mt-2 align-items-center"
                  *ngFor="let report of AssetsAttachment; let i = index"
                >
                  <div class="col-md-1 pl-0">
                    {{ i + 1 }}
                  </div>
                  <div class="col-md-3">
                    <p class="lead fs-12">{{ report.Title }}</p>
                  </div>
                  <div class="col-md-3">
                    <p class="m-0 row">
                      <a
                        href="{{ applicationDocUrl }}{{
                          ApplicationDetail.ApplicationNo
                        }}/{{ report.DocFileName }}"
                        target="_blank"
                        *ngIf="report.DocFileName"
                        >{{ report.DocFileName }}</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right mt-2 mb-2">
              <button
                type="button"
                (click)="onClosePrimaryAsset()"
                class="btn btn-light font-size-12"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="assetModel.Collateral == 'Property'">
            <div class="row mt-2">
              <div class="col-md-4">
                <h6 class="fs-12">Property Owner Name</h6>
                <p class="lead fs-12">{{ assetModel.CustomerId }}</p>
              </div>
              <div class="col-md-8">
                <h6 class="fs-12">Address Of Property</h6>
                <p class="lead fs-12">{{ assetModel.Address }}</p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-2">
                <h6 class="fs-12">Reg State</h6>
                <p class="lead fs-12">{{ assetModel.State }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Reg District</h6>
                <p class="lead fs-12">{{ assetModel.District }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Reg Tehsil</h6>
                <p class="lead fs-12">{{ assetModel.tasil }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Type Of Property</h6>
                <p class="lead fs-12">{{ assetModel.TypeofProperty }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Nature Of Property</h6>
                <p class="lead fs-12">{{ assetModel.NatureofProperty }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Ownership Document</h6>
                <p class="lead fs-12">{{ assetModel.OwnershipDocument }}</p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-2">
                <h6 class="fs-12">Ownership Type</h6>
                <p class="lead fs-12">{{ assetModel.OwnershipType }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Unit Of Measurement</h6>
                <p class="lead fs-12">{{ assetModel.UnitofMeasurement }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Total Area</h6>
                <p class="lead fs-12">{{ assetModel.TotalArea }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Constructed Area</h6>
                <p class="lead fs-12">{{ assetModel.ConstructedArea }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Type Of Mortgage</h6>
                <p class="lead fs-12">{{ assetModel.TypeofMortgage }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Mortgage singed by</h6>
                <p class="lead fs-12">{{ assetModel.MortgageSingedBy }}</p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-2">
                <h6 class="fs-12">Valution Amount</h6>
                <p class="lead fs-12">{{ assetModel.ValuationAmount }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Valution Done By</h6>
                <p class="lead fs-12">{{ assetModel.ValuationDoneBy }}</p>
              </div>
              <div
                class="col-md-2"
                *ngIf="
                  assetModel.ValuationDoneByID != '' &&
                  assetModel.ValuationDoneByID == 0
                "
              >
                <h6 class="fs-12">Valution Done By Name</h6>
                <p class="lead fs-12">{{ assetModel.ValuationDoneByOther }}</p>
              </div>
              <div class="col-md-6">
                <h6 class="fs-12">Valution Remark</h6>
                <p class="lead fs-12">{{ assetModel.ValuationRemark }}</p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-2">
                <h6 class="fs-12">Search Report Done By</h6>
                <p class="lead fs-12">{{ assetModel.SearchReportDoneBy }}</p>
              </div>
              <div
                class="col-md-2"
                *ngIf="
                  assetModel.SearchReportDoneBy != '' &&
                  assetModel.SearchReportDoneBy == 0
                "
              >
                <h6 class="fs-12">Search Report Done By Name</h6>
                <p class="lead fs-12">
                  {{ assetModel.SearchReportDoneByOther }}
                </p>
              </div>
              <div class="col-md-8">
                <h6 class="fs-12">Search Report Remark</h6>
                <p class="lead fs-12">{{ assetModel.SearchReportRemark }}</p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-2">
                <b style="color: black"> Location </b>
              </div>
              <div class="col-md-4">
                <h6 class="fs-12">Latitude</h6>
                <p class="lead fs-12">{{ assetModel.Latitude }}</p>
              </div>
              <div class="col-md-4">
                <h6 class="fs-12">Longitude</h6>
                <p class="lead fs-12">{{ assetModel.Longitude }}</p>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-6 p-1">
                <h6 class="fs-12">Valuation Report</h6>
                <p class="lead fs-12">
                  <a
                    href="{{ docBaseUrl }}{{ assetModel.LoanAcNo }}/{{
                      assetModel.ValuationRemarkDoc
                    }}"
                    class="ml-3"
                    target="_blank"
                    *ngIf="!assetModel.ValuationDocData"
                  >
                    {{ assetModel.ValuationRemarkDoc }}
                  </a>
                </p>
              </div>
              <div class="col-md-6 p-1">
                <h6 class="fs-12">Search Report</h6>
                <p class="lead fs-12">
                  <a
                    href="{{ docBaseUrl }}{{ assetModel.LoanAcNo }}/{{
                      assetModel.SearchReportRemarkDoc
                    }}"
                    class="ml-3"
                    target="_blank"
                    *ngIf="!assetModel.SearchDocData"
                  >
                    {{ assetModel.SearchReportRemarkDoc }}
                  </a>
                </p>
              </div>
            </div>
            <div class="row mt-2">
              <div
                class="col-md-12 mt-2 upload"
                *ngIf="AssetsAttachment.length > 0"
              >
                <b> Uploaded Vehicle Images </b>

                <div
                  class="row m-0 mt-2 align-items-center"
                  *ngFor="let report of AssetsAttachment; let i = index"
                >
                  <div class="col-md-1 pl-0">
                    {{ i + 1 }}
                  </div>
                  <div class="col-md-3">
                    <p class="lead fs-12">{{ report.Title }}</p>
                  </div>
                  <div class="col-md-3">
                    <p class="m-0 row">
                      <a
                        href="{{ applicationDocUrl }}{{
                          ApplicationDetail.ApplicationNo
                        }}/{{ report.DocFileName }}"
                        target="_blank"
                        *ngIf="report.DocFileName"
                        >{{ report.DocFileName }}</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right mt-2 mb-2">
              <button
                type="button"
                (click)="onClosePrimaryAsset()"
                class="btn btn-light font-size-12"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="assetModel.Collateral == 'Gold'">
            <ng-container *ngIf="assetModel.Item && assetModel.ItemShow">
              <div class="row mt-2">
                <div class="col-md-2">
                  <h6 class="fs-12">Item Name</h6>
                  <p class="lead fs-12">{{ assetModel.Item.ItemName }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Gold Type</h6>
                  <p class="lead fs-12">{{ assetModel.Item.ItemType }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Qty</h6>
                  <p class="lead fs-12">{{ assetModel.Item.Qty }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Purity CT</h6>
                  <p class="lead fs-12">{{ assetModel.Item.PurityCT }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Gross Wt</h6>
                  <p class="lead fs-12">{{ assetModel.Item.GrossWt }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Stone Wt</h6>
                  <p class="lead fs-12">{{ assetModel.Item.StoneWt }}</p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-2">
                  <h6 class="fs-12">Net Wt</h6>
                  <p class="lead fs-12">{{ assetModel.Item.OtherWt }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Valulation Rate PerCT</h6>
                  <p class="lead fs-12">
                    {{ assetModel.Item.ValulationRate_PerCT }}
                  </p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Valulation Amount</h6>
                  <p class="lead fs-12">
                    {{ assetModel.Item.ValulationAmount }}
                  </p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Image 1</h6>
                  <p class="lead fs-12">
                    <a
                      href="{{ docBaseUrl }}{{ assetModel.LoanAcNo }}/{{
                        assetModel.Item.Image1
                      }}"
                      class="ml-3"
                      target="_blank"
                      *ngIf="!assetModel.Item.Image1Data"
                    >
                      {{ assetModel.Item.Image1 }}
                    </a>
                  </p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Image 2</h6>
                  <p class="lead fs-12">
                    <a
                      href="{{ docBaseUrl }}{{ assetModel.LoanAcNo }}/{{
                        assetModel.Item.Image2
                      }}"
                      class="ml-3"
                      target="_blank"
                      *ngIf="!assetModel.Item.Image1Data"
                    >
                      {{ assetModel.Item.Image2 }}
                    </a>
                  </p>
                </div>
              </div>
            </ng-container>

            <div class="table-responsive mt-4 mb-4">
              <mat-table [dataSource]="dataAssetItemSource">
                <ng-container matColumnDef="Id">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ItemName">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Item Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ItemName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ItemType">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Item Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ItemType }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Qty">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Qty</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Qty }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="PurityCT">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Purity CT</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.PurityCT }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="GrossWt">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Gross Wt</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.GrossWt }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="StoneWt">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Stone Wt</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.StoneWt }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="OtherWt">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Other Wt</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.OtherWt }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ValulationRate_PerCT">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Valution Rate PerCT</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ValulationRate_PerCT }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ValulationAmount">
                  <mat-header-cell class="grid-header" *matHeaderCellDef>
                    Valution Amount
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ValulationAmount }}
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedAssetItemColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedAssetItemColumns"
                ></mat-row>
              </mat-table>

              <div class="row m-0 mt-4" *ngIf="assetTotal">
                <div class="col-md-3">Total Qty:{{ assetTotal.Qty }}</div>
                <div class="col-md-3">
                  Total Gross Wt:{{ assetTotal.GrossWt }}
                </div>
                <div class="col-md-3">
                  Total Stone Wt:{{ assetTotal.StoneWt }}
                </div>
                <div class="col-md-3">
                  Total Net Wt:{{ assetTotal.OtherWt }}
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-2">
                <h6 class="fs-12">Valution Done By</h6>
                <p class="lead fs-12">{{ assetModel.ValuationDoneBy }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Valution Date</h6>
                <p class="lead fs-12">{{ assetModel.ValulationDate }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Total Valution Amount</h6>
                <p class="lead fs-12">{{ assetModel.TotalValulationAmount }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Valuer PhoneNo</h6>
                <p class="lead fs-12">{{ assetModel.ValuerPhoneNo }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Valuer FirmName</h6>
                <p class="lead fs-12">{{ assetModel.ValuerFirmName }}</p>
              </div>
            </div>
            <div class="text-right mt-2 mb-2">
              <button
                type="button"
                (click)="onClosePrimaryAsset()"
                class="btn btn-light font-size-12"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="assetModel.Collateral == 'Consumer Durable'">
            <ng-container *ngIf="assetModel.Item && assetModel.ItemShow">
              <div class="row mt-2">
                <div class="col-md-2">
                  <h6 class="fs-12">Item Name</h6>
                  <p class="lead fs-12">{{ assetModel.Item.ItemName }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Item Category</h6>
                  <p class="lead fs-12">{{ assetModel.Item.ItemCategory }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Qty</h6>
                  <p class="lead fs-12">{{ assetModel.Item.ItemQty }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Item Make</h6>
                  <p class="lead fs-12">{{ assetModel.Item.ItemMake }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Item SeralNo</h6>
                  <p class="lead fs-12">{{ assetModel.Item.ItemSeralNo }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Amount</h6>
                  <p class="lead fs-12">{{ assetModel.Item.Amount }}</p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <h6 class="fs-12">Remark</h6>
                  <p class="lead fs-12">{{ assetModel.Item.Remark }}</p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-2">
                  <h6 class="fs-12">Other Wt</h6>
                  <p class="lead fs-12">{{ assetModel.Item.OtherWt }}</p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Valulation Rate PerCT</h6>
                  <p class="lead fs-12">
                    {{ assetModel.Item.ValulationRate_PerCT }}
                  </p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Valulation Amount</h6>
                  <p class="lead fs-12">
                    {{ assetModel.Item.ValulationAmount }}
                  </p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Image 1</h6>
                  <p class="lead fs-12">
                    <a
                      href="{{ docBaseUrl }}{{
                        ApplicationDetail.ApplicationNo
                      }}/{{ assetModel.Item.Image1 }}"
                      class="ml-3"
                      target="_blank"
                      *ngIf="!assetModel.Item.Image1Data"
                    >
                      {{ assetModel.Item.Image1 }}
                    </a>
                  </p>
                </div>
                <div class="col-md-2">
                  <h6 class="fs-12">Image 2</h6>
                  <p class="lead fs-12">
                    <a
                      href="{{ docBaseUrl }}{{
                        ApplicationDetail.ApplicationNo
                      }}/{{ assetModel.Item.Image2 }}"
                      class="ml-3"
                      target="_blank"
                      *ngIf="!assetModel.Item.Image2Data"
                    >
                      {{ assetModel.Item.Image2 }}
                    </a>
                  </p>
                </div>
              </div>
            </ng-container>
            <div class="table-responsive mt-4 mb-4">
              <mat-table [dataSource]="dataAssetItemSource">
                <ng-container matColumnDef="Id">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ItemName">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Item Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ItemName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ItemCategory">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Item Category</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ItemCategory }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ItemMake">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Item Make</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ItemMake }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ItemQty">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Qty</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ItemQty }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ItemSeralNo">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Item SeralNo</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ItemSeralNo }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Amount">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Amount }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Remark">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Remark</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.Remark }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="OtherWt">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Other Wt</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.OtherWt }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ValulationRate_PerCT">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Valution Rate PerCT</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ValulationRate_PerCT }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="ValulationAmount">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Valution Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.ValulationAmount }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Action">
                  <mat-header-cell
                    class="grid-header j-c-center"
                    style="max-width: 60px"
                    *matHeaderCellDef
                    >Action</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell j-c-center"
                    style="max-width: 60px"
                  >
                    <!-- <a href="javascript:void(0)" (click)="editAssetItem(row,i)" *ngIf="!isViewMode" style="margin-right: 10px;">Edit</a>
                    <a href="javascript:void(0)" (click)="editAssetItem(row,i)" *ngIf="isViewMode" style="margin-right: 10px;">View</a>
                    <a href="javascript:void(0)" (click)="deleteAssetItem(i)" *ngIf="!isViewMode">Delete</a> -->
                    <a href="javascript:void(0)" style="margin-right: 10px"
                      >Edit</a
                    >
                    <a href="javascript:void(0)" style="margin-right: 10px"
                      >View</a
                    >
                    <a href="javascript:void(0)">Delete</a>
                  </mat-cell>
                </ng-container>
                <mat-header-row
                  *matHeaderRowDef="displayedAssetItemColumns"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedAssetItemColumns"
                ></mat-row>
              </mat-table>
            </div>

            <div class="row mt-2">
              <div class="col-md-12">
                <h6 class="fs-12">Remark</h6>
                <p class="lead fs-12">{{ assetModel.Remark }}</p>
              </div>
            </div>
            <div class="text-right mt-2 mb-2">
              <button
                type="button"
                (click)="onClosePrimaryAsset()"
                class="btn btn-light font-size-12"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
