<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row m-0 mb-2">
  <div class="tab row m-0 col-md-12">
    <div
      class="row m-0 col-md-6 pt-2 pb-2 align-items-center justify-content-center bdr"
      [ngClass]="{ active: tab == 'mytask' }"
      (click)="changeTab('mytask')"
    >
      My Task
    </div>
    <div
      class="row m-0 col-md-6 pt-2 pb-2 align-items-center justify-content-center bdr"
      [ngClass]="{ active: tab == 'myreport' }"
      (click)="changeTab('myreport')"
    >
      My Report
    </div>
  </div>
</div>

<div class="mb-3" *ngIf="tab == 'mytask'">
  <div class="text-right my-1 pr-2">
    <button
      class="mt-2 btn font-size-12 button-btn"
      style="width: max-content"
      *ngIf="AddLoan"
    >
      <a
        class="text-underling-cursor"
        href="javascript:void(0)"
        (click)="onOpenNewInquiryModel()"
        style="
          text-decoration: none !important;
          color: #fff !important;
          font-size: 14px;
        "
      >
        Register A New Enquiry
      </a>
    </button>
  </div>
  <div class="row m-0">
    <div class="col-md-3 p-2 box" *ngFor="let item of dashboardData">
      <div
        style="
          border: 1px solid #2a0505dd;
          border-radius: 5px;
          background: #fff;
        "
        (click)="goToPage(item)"
      >
        <!-- <div class="box-img">
          <img src="{{iconname}}{{item.IconName}}" alt="img" style="width: 38px;height: 38px;">
        </div> -->

        <div
          class="d-flex align-items-center pl-1 pr-1 justify-content-between box-content"
        >
          <!--  style="width: 80%;" -->
          <div class="row m-0">
            <div class="col-md-12 p-1 text-center">
              <B> {{ item.EmpProcessName }} </B>
            </div>
            <div class="col-md-6 p-1">
              Pending :
              <span class="badge badge-warning1 fs-12">
                {{ item.ItemCount }}
              </span>
            </div>
            <div class="col-md-6 p-1 text-right">
              Reverted :
              <span class="badge badge-info fs-12">
                {{ item.RevertCount }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row m-0 mb-2" *ngIf="tab == 'myreport'">
  <div class="tab row m-0 col-md-12">
    <div
      class="row m-0 col-md-6 pt-2 pb-2 align-items-center justify-content-center bdr"
      [ngClass]="{ active: tab1 == 'team' }"
      (click)="changeTab1('team')"
    >
      View By Team
    </div>
    <div
      class="row m-0 col-md-6 pt-2 pb-2 align-items-center justify-content-center bdr"
      [ngClass]="{ active: tab1 == 'process' }"
      (click)="changeTab1('process')"
    >
      View By Process
    </div>
  </div>
</div>
<div
  class="row m-0 col-md-12 p-1"
  *ngIf="tab1 == 'process' && tab == 'myreport'"
>
  <div class="row m-0 col-md-12 p-0 mb-4">
    <div
      class="col-md-4 p-2 box"
      *ngFor="let item of processListData; let ind = index"
      (click)="getDetail(item)"
    >
      <!-- <div class="d-flex align-items-center pl-4 pr-4 justify-content-between box-content"
             style="width: 80%;">
          <span> {{item.Process_Name}} </span>
          <span> {{item.ApplicationCount}} </span>
        </div> -->
      <div
        class="card"
        style="height: 62px; padding: 14px 2px 0px 6px"
        [style.background]="gradientColors[ind]"
        [ngClass]="{ dataactive: selectedProcess?.ProcessId == item.ProcessId }"
      >
        <div
          style="
            color: antiquewhite;
            display: flex;
            justify-content: space-between;
          "
          class="font-weight-bolder"
        >
          <span style="font-size: 20px"> {{ item.Process_Name }} </span>
          <span
            class="badge"
            [ngClass]="
              selectedProcess?.ProcessId == item.ProcessId
                ? 'badge-success'
                : 'badge-dark'
            "
            style="font-size: 18px"
          >
            {{ item.ApplicationCount }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="table-responsive" *ngIf="selectedProcess">
    <div
      class="row m-0 col-md-12"
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <div class="col-md-2" style="margin-bottom: 10px">
        <span>Status</span>
        <!--  -->
        <select
          name="Status"
          id="Status"
          #refStatus="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="Status"
          (change)="onChangeStatusofProcess()"
        >
          <option value="Pending">Pending/Reverted</option>
          <option value="Reject">Rejected</option>
        </select>
      </div>

      <div class="col-md-4 p-1 text-right" style="margin-bottom: 10px">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <mat-table [dataSource]="dataProcessSource" matSort>
      <ng-container matColumnDef="ApplicationId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Application_No">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Application No
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          <a
            (click)="onOpenReport(row.ApplicationIdentity)"
            aria-label="true"
            style="color: blue; cursor: pointer"
            >{{ row.Application_No }}</a
          >
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Customer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Product_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Product Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Product_Name }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Branch_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Branch Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="SourceType">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Source Type
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.SourceType }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="SourceName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Source Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.SourceName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="EmpName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Employee
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.EmpName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AssignOn">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Assign On
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.AssignOn }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AP_ProcessStatus">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 150px"
          *matHeaderCellDef
        >
          Status
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 150px"
        >
          {{ row.AP_ProcessStatus }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="TAT_Hr">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          TAT(Hr)
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.TAT_Hr }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Action">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Action
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          <i
            class="fa fa-arrow-circle-right arrow-circle action-btn"
            (click)="onOpenReport(row.ApplicationIdentity)"
          ></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="InquiryId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="InquiryNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Inquiry No
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.InquiryNo }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CustomerName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CustomerAddress">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer Address
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerAddress }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ContactNumber">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Contact Number
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ContactNumber }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Source">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Source
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Source }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Purpose">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Purpose
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Purpose }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="LoanAmount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          LoanAmount
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.LoanAmount }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Status">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Status
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Status }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CreateOn">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Create On
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CreateOn }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CreatedBy">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Created By
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CreatedBy }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="SaleExcutive">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          SaleExcutive
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.SaleExcutive }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Branch">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Branch
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch }}
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedProcessColumns"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedProcessColumns"></mat-row>
    </mat-table>

    <div class="col-md-12 row">
      <div class="col-md-4">
        <button
          class="mt-2 btn font-size-12 button-btn"
          (click)="exportexcelByProcess()"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
<div class="row m-0 col-md-12 p-1" *ngIf="tab1 == 'team' && tab == 'myreport'">
  <div class="row m-0 col-md-12 p-0 mb-4">
    <div
      class="col-md-4 p-2 box"
      *ngFor="let item of teamListData; let ind = index"
      (click)="getTeamDetail(item)"
    >
      <div
        class="card"
        style="height: 62px; padding: 14px 2px 0px 6px"
        [style.background]="gradientColors[ind]"
        [ngClass]="{ dataactive: selectedTeam?.EmpId == item.EmpId }"
      >
        <div
          style="
            color: antiquewhite;
            display: flex;
            justify-content: space-between;
          "
          class="font-weight-bolder"
        >
          <span style="font-size: 20px"> {{ item.EmpName }} </span>
          <span
            class="badge"
            [ngClass]="
              selectedTeam?.EmpId == item.EmpId ? 'badge-success' : 'badge-dark'
            "
            style="font-size: 18px"
          >
            {{ item.ApplicationCount }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="table-responsive" *ngIf="selectedTeam">
    <div class="row m-0 col-md-12 float-end">
      <div class="col-md-5 p-1">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <mat-table [dataSource]="dataTeamSource" matSort>
      <ng-container matColumnDef="ApplicationId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px; max-height: 5px"
        >
          {{ tpaginatorRef.pageIndex * tpaginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Application_No">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Application No
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          <a
            (click)="onOpenReport(row.ApplicationIdentity)"
            aria-label="true"
            style="color: blue; cursor: pointer"
            >{{ row.Application_No }}</a
          >
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Customer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Product_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Product Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Product_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Branch_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Branch Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="SourceType">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Source Type
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.SourceType }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="SourceName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Source Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.SourceName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Process_Name">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Process Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Process_Name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AssignOn">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Assign On
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.AssignOn }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="AP_ProcessStatus">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 150px"
          *matHeaderCellDef
        >
          Status
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 150px"
        >
          {{ row.AP_ProcessStatus }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="TAT_Hr">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          TAT(Hr)
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.TAT_Hr }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Action">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Action
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          <i
            class="fa fa-arrow-circle-right arrow-circle action-btn"
            (click)="onOpenReport(row.ApplicationIdentity)"
          ></i>
        </mat-cell>
      </ng-container>

      <!--<ng-container matColumnDef="View">
    <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef> View </mat-header-cell>
    <mat-cell *matCellDef="let row" class="grid-cell">
      <a href="javascript:void(0)" (click)="onOpenApplication(row)">view</a>
    </mat-cell>
  </ng-container>-->
      <mat-header-row *matHeaderRowDef="displayedTeamColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedTeamColumns"></mat-row>
    </mat-table>

    <div class="col-md-12 row">
      <div class="col-md-4">
        <button
          class="mt-2 btn font-size-12 button-btn"
          (click)="exportexcelByTeam()"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #tpaginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="addNewInquiry"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveInquiry()" novalidate>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Register a new Enquiry
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row m-0 formborder">
            <div class="col-md-12 p-1">
              <span class="required-lable"> Lead Source </span>
              <select
                name="Source"
                id="Source"
                #refSource="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refSource.invalid,
                  'alert-warning':
                    refSource.invalid &&
                    (refSource.dirty ||
                      refSource.touched ||
                      refSource.untouched)
                }"
                [(ngModel)]="inquiryModel.Source"
                class="form-control input-text-css"
                required
                (change)="onChangeSource()"
              >
                <option value="">Select Lead Source</option>
                <option
                  *ngFor="let lead of leadSourceDropdown"
                  [value]="lead.Lead_Source"
                >
                  {{ lead.Lead_Source }}
                </option>
              </select>
            </div>
            <div
              class="col-md-12 p-1"
              *ngIf="
                inquiryModel.Source == 'Agent' ||
                inquiryModel.Source == 'DSA' ||
                inquiryModel.Source == 'Dealer' ||
                inquiryModel.Source == 'Reference'
              "
            >
              <span class="required-lable"> Lead Reference </span>
              <select
                name="LeadReference"
                id="LeadReference"
                #refLeadReference="ngModel"
                *ngIf="inquiryModel.Source != 'Reference'"
                [ngClass]="{
                  'is-invalid': f.submitted && refLeadReference.invalid,
                  'alert-warning':
                    refLeadReference.invalid &&
                    (refLeadReference.dirty ||
                      refLeadReference.touched ||
                      refLeadReference.untouched)
                }"
                [(ngModel)]="inquiryModel.LeadReference"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Lead Reference</option>
                <option
                  *ngFor="let partner of partnerDropdown"
                  [value]="partner.PartnerId"
                >
                  {{ partner.Partner_Name }}
                </option>
              </select>
              <input
                required
                type="text"
                #refLeadReference="ngModel"
                *ngIf="inquiryModel.Source == 'Reference'"
                [ngClass]="{
                  'is-invalid': f.submitted && refLeadReference.invalid,
                  'alert-warning':
                    refLeadReference.invalid &&
                    (refLeadReference.dirty ||
                      refLeadReference.touched ||
                      refLeadReference.untouched)
                }"
                [(ngModel)]="inquiryModel.LeadReference"
                placeholder="Lead Reference"
                name="LeadReference"
                id="LeadReference"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> Customer </span>
              <input
                required
                type="text"
                #refCustomerName="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomerName.invalid,
                  'alert-warning':
                    refCustomerName.invalid &&
                    (refCustomerName.dirty ||
                      refCustomerName.touched ||
                      refCustomerName.untouched)
                }"
                [(ngModel)]="inquiryModel.CustomerName"
                placeholder="Customer Name"
                name="CustomerName"
                id="CustomerName"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-6 p-1">
              <span> Customer Email </span>
              <input
                type="email"
                #refCustomerEmail="ngModel"
                [(ngModel)]="inquiryModel.CustomerEmail"
                placeholder="Customer Email"
                name="CustomerEmail"
                id="CustomerEmail"
                class="form-control input-text-css"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomerEmail.invalid,
                  'alert-warning':
                    refCustomerEmail.invalid &&
                    (refCustomerEmail.dirty ||
                      refCustomerEmail.touched ||
                      refCustomerEmail.untouched)
                }"
              />
            </div>
            <div class="col-md-12 p-1">
              <span class="required-lable"> Customer Address </span>
              <textarea
                required
                rows="3"
                #refCustomerAddress="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomerAddress.invalid,
                  'alert-warning':
                    refCustomerAddress.invalid &&
                    (refCustomerAddress.dirty ||
                      refCustomerAddress.touched ||
                      refCustomerAddress.untouched)
                }"
                [(ngModel)]="inquiryModel.CustomerAddress"
                placeholder="Customer Address"
                name="CustomerAddress"
                id="CustomerAddress"
                class="form-control input-text-css"
              ></textarea>
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> State </span>
              <select
                name="CustomerState"
                id="CustomerState"
                #refCustomerState="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomerState.invalid,
                  'alert-warning':
                    refCustomerState.invalid &&
                    (refCustomerState.dirty ||
                      refCustomerState.touched ||
                      refCustomerState.untouched)
                }"
                [(ngModel)]="inquiryModel.CustomerState"
                class="form-control input-text-css"
                required
                (change)="GetDistrickDropdown()"
              >
                <option value="">Select State</option>
                <option
                  *ngFor="let state of stateDropdown"
                  [value]="state.State_Name"
                >
                  {{ state.State_Name }}
                </option>
              </select>
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> District </span>
              <select
                name="CustomerDistrict"
                id="CustomerDistrict"
                #refCustomerDistrict="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomerDistrict.invalid,
                  'alert-warning':
                    refCustomerDistrict.invalid &&
                    (refCustomerDistrict.dirty ||
                      refCustomerDistrict.touched ||
                      refCustomerDistrict.untouched)
                }"
                [(ngModel)]="inquiryModel.CustomerDistrict"
                class="form-control input-text-css"
                required
                (change)="GetTehasilDropdown()"
              >
                <option value="">Select District</option>
                <option
                  *ngFor="let district of districkDropdown"
                  [value]="district.District_Name"
                >
                  {{ district.District_Name }}
                </option>
              </select>
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> Tehsil </span>
              <select
                name="CustomerTehsil"
                id="CustomerTehsil"
                #refCustomerTehsil="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomerTehsil.invalid,
                  'alert-warning':
                    refCustomerTehsil.invalid &&
                    (refCustomerTehsil.dirty ||
                      refCustomerTehsil.touched ||
                      refCustomerTehsil.untouched)
                }"
                [(ngModel)]="inquiryModel.CustomerTehsil"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Tehsil</option>
                <option
                  *ngFor="let tehsil of tehasilDropdown"
                  [value]="tehsil.Tehsil_Name"
                >
                  {{ tehsil.Tehsil_Name }}
                </option>
              </select>
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> Pincode </span>
              <input
                required
                type="text"
                maxlength="6"
                minlength="6"
                numbersOnly
                #refCustomerPincode="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refCustomerPincode.invalid,
                  'alert-warning':
                    refCustomerPincode.invalid &&
                    (refCustomerPincode.dirty ||
                      refCustomerPincode.touched ||
                      refCustomerPincode.untouched)
                }"
                [(ngModel)]="inquiryModel.CustomerPincode"
                placeholder="Customer Pincode"
                name="CustomerPincode"
                id="CustomerPincode"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable"> Contact No </span>
              <input
                required
                maxlength="10"
                minlength="10"
                numbersOnly
                type="text"
                #refContactNumber="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refContactNumber.invalid,
                  'alert-warning':
                    refContactNumber.invalid &&
                    (refContactNumber.dirty ||
                      refContactNumber.touched ||
                      refContactNumber.untouched)
                }"
                [(ngModel)]="inquiryModel.ContactNumber"
                placeholder="Contact No"
                name="ContactNumber"
                id="ContactNumber"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-6 p-1">
              <span class="required-lable">Required Loan Amount </span>
              <input
                required
                type="text"
                numbersOnly
                #refLoanAmount="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refLoanAmount.invalid,
                  'alert-warning':
                    refLoanAmount.invalid &&
                    (refLoanAmount.dirty ||
                      refLoanAmount.touched ||
                      refLoanAmount.untouched)
                }"
                [(ngModel)]="inquiryModel.LoanAmount"
                placeholder="Loan Amount"
                name="LoanAmount"
                id="LoanAmount"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-12 p-1">
              <span class="required-lable"> Purpose </span>
              <textarea
                required
                rows="2"
                maxlength="150"
                #refPurpose="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refPurpose.invalid,
                  'alert-warning':
                    refPurpose.invalid &&
                    (refPurpose.dirty ||
                      refPurpose.touched ||
                      refPurpose.untouched)
                }"
                [(ngModel)]="inquiryModel.Purpose"
                placeholder="Purpose"
                name="Purpose"
                id="Purpose"
                class="form-control input-text-css"
              ></textarea>
            </div>
            <div class="col-md-6 p-1">
              <mat-checkbox
                class="example-margin"
                name="InquirySelfAssigned"
                *ngIf="showSelfEnquiry"
                id="InquirySelfAssigned"
                #refInquirySelfAssigned="ngModel"
                [(ngModel)]="inquiryModel.InquirySelfAssigned"
              >
                Inquiry Self Assigned
              </mat-checkbox>
            </div>
            <div class="col-md-6 p-1" *ngIf="inquiryModel.InquirySelfAssigned">
              <span class="required-lable"> Branch </span>
              <select
                name="BranchId"
                id="BranchId"
                #refBranchId="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refBranchId.invalid,
                  'alert-warning':
                    refBranchId.invalid &&
                    (refBranchId.dirty ||
                      refBranchId.touched ||
                      refBranchId.untouched)
                }"
                [(ngModel)]="inquiryModel.BranchId"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Branch</option>
                <option
                  *ngFor="let branch of employeeBranchDropdown"
                  [value]="branch.BranchId"
                >
                  {{ branch.Branch_Name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnClose()"
            class="btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveInquiry()"
            class="btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<table
  id="quickLoan_Dashboard_export"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Employee
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Type
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Name
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Assign On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Status
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      TAT(Hr)
    </th>
  </tr>
  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.Customer }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.EmpName }}</td>
    <td>{{ x.SourceType }}</td>
    <td>{{ x.SourceName }}</td>
    <td>{{ x.AssignOn }}</td>
    <td>{{ x.AP_ProcessStatus }}</td>
    <td>{{ x.TAT_Hr }}</td>
  </tr>
</table>

<table
  id="quickLoan_export_byTeam"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Process Name
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Type
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Name
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Assign On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Status
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      TAT(Hr)
    </th>
  </tr>
  <tr *ngFor="let x of JsonDataTeam; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.Customer }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Process_Name }}</td>
    <td>{{ x.SourceType }}</td>
    <td>{{ x.SourceName }}</td>

    <td>{{ x.AssignOn }}</td>
    <td>{{ x.AP_ProcessStatus }}</td>
    <td>{{ x.TAT_Hr }}</td>
  </tr>
</table>
