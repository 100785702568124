<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

<div *ngIf="resultforcondition.length > 0; else elseBlock">
  <form #f="ngForm" novalidate class="formborder">
    <ng-container>
      <div class="row m-0 mr-2">
        <div class="col-md-12 ml-1 bg-success1">Income:</div>
      </div>

      <div class="row m-0" *ngFor="let income of IncomeModel; let i = index">
        <div class="col-md-2" style="padding: 5px 1px 5px 1px !important">
          <span class="required-lable" *ngIf="i == 0">Customer</span>

          <input
            type="search"
            class="form-control input-text-css"
            [(ngModel)]="income.CustomerName"
            id="CustomerName{{ i }}"
            name="CustomerName{{ i }}"
            autocomplete="off"
            required
            #refCustomerName="ngModel"
            placeholder="Customer Name"
            [disabled]="IsDisabled"
            [ngClass]="{
              'is-invalid': f.submitted && refCustomerName.invalid,
              'alert-warning':
                refCustomerName.invalid &&
                (refCustomerName.dirty ||
                  refCustomerName.touched ||
                  refCustomerName.untouched)
            }"
          />
        </div>

        <div class="col-md-2" style="padding: 5px 1px 5px 1px !important">
          <span class="required-lable" *ngIf="i == 0">Income Type</span>

          <input
            type="search"
            class="form-control input-text-css"
            [(ngModel)]="income.Inc_and_Exp_Type"
            id="Inc_and_Exp_Type{{ i }}"
            name="Inc_and_Exp_Type{{ i }}"
            list="dynmicIncomeType"
            autocomplete="off"
            required
            #refInc_and_Exp_Type="ngModel"
            placeholder="Income Type"
            [disabled]="IsDisabled"
            [ngClass]="{
              'is-invalid': f.submitted && refInc_and_Exp_Type.invalid,
              'alert-warning':
                refInc_and_Exp_Type.invalid &&
                (refInc_and_Exp_Type.dirty ||
                  refInc_and_Exp_Type.touched ||
                  refInc_and_Exp_Type.untouched)
            }"
          />
        </div>

        <div class="col-md-2" style="padding: 5px 1px 5px 1px !important">
          <span
            class="required-lable"
            *ngIf="i == 0"
            style="font-size: 14px; font-weight: semibold"
            >Income From</span
          >
          <input
            required
            type="text"
            #refInc_and_Exp_From="ngModel"
            [(ngModel)]="income.Inc_and_Exp_From"
            [disabled]="IsDisabled"
            placeholder="Income From"
            name="Inc_and_Exp_From{{ i }}"
            id="Inc_and_Exp_From{{ i }}"
            class="form-control input-text-css"
            [ngClass]="{
              'is-invalid': f.submitted && refInc_and_Exp_From.invalid,
              'alert-warning':
                refInc_and_Exp_From.invalid &&
                (refInc_and_Exp_From.dirty ||
                  refInc_and_Exp_From.touched ||
                  refInc_and_Exp_From.untouched)
            }"
          />
        </div>

        <div class="col-md-1" style="padding: 5px 1px 5px 1px !important">
          <span class="required-lable" *ngIf="i == 0">Income</span>
          <input
            required
            type="number"
            numbersOnly
            #refAmount="ngModel"
            [(ngModel)]="income.Amount"
            [disabled]="IsDisabled"
            name="Amount{{ i }}"
            id="Amount{{ i }}"
            placeholder="Amount"
            class="form-control input-text-css text-right pr-3"
            [ngClass]="{
              'is-invalid': f.submitted && refAmount.invalid,
              'alert-warning':
                refAmount.invalid &&
                (refAmount.dirty || refAmount.touched || refAmount.untouched)
            }"
          />
        </div>

        <div class="col-md-1" style="padding: 5px 1px 5px 1px !important">
          <span *ngIf="i == 0">Remarks</span>
          <textarea
            rows="1"
            name="Remarks{{ i }}"
            id="Remarks{{ i }}"
            [(ngModel)]="income.Remarks"
            #refRemark="ngModel"
            placeholder="Remark"
            class="form-control input-text-css"
            [disabled]="IsDisabled"
          ></textarea>
        </div>
        <div class="col-md-1 p-1">
          <span *ngIf="i == 0"> Document</span>
          <p
            class="mt-1"
            style="
              overflow-wrap: break-word;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <i
              class="fa fa-paperclip fileUpload"
              *ngIf="!income.Doc_FileName"
              aria-hidden="true"
            ></i>
            <ng-container *ngIf="income.Doc_FileName">
              <a
                href="{{ docBaseUrl }}{{ income.CustomerName }}/{{
                  income.Doc_FileName
                }}"
                target="_blank"
              >
                <i
                  class="fa fa-paperclip mr-2"
                  aria-hidden="true"
                  style="font-size: 18px"
                ></i>
              </a>

              <!-- <i
                class="fa fa-times ml-2 fs-16"
                aria-hidden="true"
                style="cursor: pointer"
                (click)="removeFileIncome(income, docFile)"
              ></i> -->
            </ng-container>
          </p>
          <input
            #docFile
            type="file"
            [multiple]="false"
            accept="application/pdf,application/vnd.ms-excel"
            (change)="fileChangeListenerForIncome(income, docFile.files)"
            style="display: none"
            [disabled]="IsDisabled"
          />
        </div>

        <div
          class="col-md-1"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5px 0px 5px 0px !important;
          "
        >
          <span *ngIf="i == 0" required>Is Consider</span>
          <input
            style="height: 20px; width: 20px"
            (change)="toggleCheckbox(income, '', '')"
            type="checkbox"
            #refIsEligible="ngModel"
            [(ngModel)]="income.IsEligible"
            name="IsEligible{{ i }}"
            id="IsEligible{{ i }}"
            class="input-text-css text-right pr-3 mt-2"
            [ngClass]="{
              'is-invalid': f.submitted && refIsEligible.invalid,
              'alert-warning':
                refIsEligible.invalid &&
                (refIsEligible.dirty ||
                  refIsEligible.touched ||
                  refIsEligible.untouched)
            }"
            [disabled]="IsEdit"
          />
        </div>

        <div class="col-md-2" style="padding: 5px 1px 5px 1px !important">
          <span class="required-lable" *ngIf="i == 0">Considered Income</span>
          <input
            required
            type="text"
            appTwoDigitDecimaNumber
            #refFOIR_Amount="ngModel"
            [(ngModel)]="income.FOIR_Amount"
            (change)="onChangeIncomeAmount(income)"
            (keyup.enter)="onChangeIncomeAmount(income)"
            name="FOIR_Amount{{ i }}"
            id="FOIR_Amount{{ i }}"
            placeholder="FOIR Amount"
            class="form-control input-text-css text-right pr-3"
            [ngClass]="{
              'is-invalid': f.submitted && refFOIR_Amount.invalid,
              'alert-warning':
                refFOIR_Amount.invalid &&
                (refFOIR_Amount.dirty ||
                  refFOIR_Amount.touched ||
                  refFOIR_Amount.untouched)
            }"
            [disabled]="!income.IsEligible || IsEdit"
          />
          <!-- [disabled]="!income.IsEligible && IsEdit" -->
        </div>
      </div>

      <div class="row m-0" style="display: flex">
        <div class="col-md-6 p-1">
          <div class="bg-success1">Total:</div>
        </div>
        <div class="col-md-2 p-1">
          <span class="form-control1 input-text-css text-right pr-3">
            {{ totalMonthlyIncomeCalculated.total }}
          </span>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-2 p-1" style="float: right">
          <span class="form-control1 input-text-css text-right pr-3">
            {{ totalCalculator.total }}
          </span>
        </div>
      </div>
    </ng-container>
  </form>

  <div class="row m-0">
    <div class="col-md-12 p-1"><hr /></div>
  </div>

  <form #rf="ngForm" novalidate class="formborder">
    <ng-container>
      <div class="row m-0 mt-1 mr-2">
        <div class="col-md-12 ml-1 bg-success1">Expense:</div>
      </div>
      <div class="row m-0" *ngFor="let expense of ExpenseModel; let i = index">
        <div class="col-md-2" style="padding: 5px 1px 5px 1px !important">
          <span class="required-lable" *ngIf="i == 0">Customer</span>

          <input
            type="search"
            class="form-control input-text-css"
            [(ngModel)]="expense.CustomerName"
            id="CustomerName1{{ i }}"
            name="CustomerName1{{ i }}"
            autocomplete="off"
            required
            #refCustomerName="ngModel"
            placeholder="Customer Name"
            [disabled]="IsDisabled"
            [ngClass]="{
              'is-invalid': f.submitted && refCustomerName.invalid,
              'alert-warning':
                refCustomerName.invalid &&
                (refCustomerName.dirty ||
                  refCustomerName.touched ||
                  refCustomerName.untouched)
            }"
          />
        </div>

        <div class="col-md-2" style="padding: 5px 1px 5px 1px !important">
          <span class="required-lable" *ngIf="i == 0">Expense Type</span>
          <input
            required
            type="text"
            #refInc_and_Exp_Type="ngModel"
            [(ngModel)]="expense.Inc_and_Exp_Type"
            placeholder="Expense Type"
            name="Inc_and_Exp_Type1{{ i }}"
            id="Inc_and_Exp_Type1{{ i }}"
            class="form-control input-text-css"
            [disabled]="IsDisabled"
            [ngClass]="{
              'is-invalid': rf.submitted && refInc_and_Exp_Type.invalid,
              'alert-warning':
                refInc_and_Exp_Type.invalid &&
                (refInc_and_Exp_Type.dirty ||
                  refInc_and_Exp_Type.touched ||
                  refInc_and_Exp_Type.untouched)
            }"
          />
        </div>
        <div class="col-md-2" style="padding: 5px 1px 5px 1px !important">
          <span class="required-lable" *ngIf="i == 0">Expense From</span>
          <input
            required
            type="text"
            #refInc_and_Exp_From="ngModel"
            [(ngModel)]="expense.Inc_and_Exp_From"
            [disabled]="IsDisabled"
            placeholder="Expense From"
            name="Inc_and_Exp_From1{{ i }}"
            id="Inc_and_Exp_From1{{ i }}"
            class="form-control input-text-css"
            [ngClass]="{
              'is-invalid': rf.submitted && refInc_and_Exp_From.invalid,
              'alert-warning':
                refInc_and_Exp_From.invalid &&
                (refInc_and_Exp_From.dirty ||
                  refInc_and_Exp_From.touched ||
                  refInc_and_Exp_From.untouched)
            }"
          />
        </div>
        <div class="col-md-1" style="padding: 5px 1px 5px 1px !important">
          <span class="required-lable" *ngIf="i == 0">Expense</span>
          <input
            required
            type="number"
            numbersOnly
            #refAmount="ngModel"
            [(ngModel)]="expense.Amount"
            [disabled]="IsDisabled"
            name="Amount1{{ i }}"
            id="Amount1{{ i }}"
            placeholder="Amount"
            class="form-control input-text-css text-right pr-3"
            [ngClass]="{
              'is-invalid': rf.submitted && refAmount.invalid,
              'alert-warning':
                refAmount.invalid &&
                (refAmount.dirty || refAmount.touched || refAmount.untouched)
            }"
          />
        </div>

        <div class="col-md-1" style="padding: 5px 1px 5px 1px !important">
          <span *ngIf="i == 0">Remarks</span>
          <textarea
            rows="1"
            name="Remarks1{{ i }}"
            id="Remarks1{{ i }}"
            [(ngModel)]="expense.Remarks"
            #refRemark="ngModel"
            placeholder="Remark"
            class="form-control input-text-css"
            [disabled]="IsDisabled"
          ></textarea>
        </div>
        <div class="col-md-1 p-1">
          <span *ngIf="i == 0"> Document</span>
          <p
            class="mt-1"
            style="
              overflow-wrap: break-word;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <i
              class="fa fa-paperclip fileUpload"
              *ngIf="!expense.Doc_FileName"
              aria-hidden="true"
            ></i>
            <ng-container *ngIf="expense.Doc_FileName">
              <a
                href="{{ docBaseUrl }}{{ expense.Customer_Id }}/{{
                  expense.Doc_FileName
                }}"
                target="_blank"
              >
                <i
                  class="fa fa-paperclip mr-2"
                  aria-hidden="true"
                  style="font-size: 18px"
                ></i>
              </a>

              <!-- <i
                class="fa fa-times ml-2 fs-16"
                aria-hidden="true"
                style="cursor: pointer"
                (click)="removeFileExpense(expense, docFile)"
              ></i> -->
            </ng-container>
          </p>
          <input
            #docFile
            type="file"
            [multiple]="false"
            accept="application/pdf,application/vnd.ms-excel"
            (change)="fileChangeListenerExpense(expense, docFile.files)"
            style="display: none"
            [disabled]="IsDisabled"
          />
        </div>
        <div
          class="col-md-1"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5px 0px 5px 0px !important;
          "
        >
          <span *ngIf="i == 0" required>Is Consider</span>
          <input
            style="outline: none; height: 20px; width: 20px"
            onfocus="this.style.outline='none';"
            (change)="toggleCheckbox('', expense, '')"
            type="checkbox"
            #refIsEligible="ngModel"
            [(ngModel)]="expense.IsEligible"
            name="IsEligible1{{ i }}"
            id="IsEligible1{{ i }}"
            class="input-text-css text-right pr-3 mt-2"
            [ngClass]="{
              'is-invalid': rf.submitted && refIsEligible.invalid,
              'alert-warning':
                refIsEligible.invalid &&
                (refIsEligible.dirty ||
                  refIsEligible.touched ||
                  refIsEligible.untouched)
            }"
            [disabled]="IsEdit"
            *ngIf="expense.Amount != 0"
          />
        </div>

        <div class="col-md-2" style="padding: 5px 1px 5px 1px !important">
          <span class="required-lable" *ngIf="i == 0">Considered Expense</span>
          <input
            required
            type="text"
            appTwoDigitDecimaNumber
            (change)="onChangeExpenseAmount(expense)"
            (keyup.enter)="onChangeExpenseAmount(expense)"
            #refFOIR_Amount="ngModel"
            [(ngModel)]="expense.FOIR_Amount"
            name="FOIR_Amount1{{ i }}"
            id="FOIR_Amount1{{ i }}"
            placeholder="FOIR Amount"
            class="form-control input-text-css text-right pr-3"
            [ngClass]="{
              'is-invalid': rf.submitted && refFOIR_Amount.invalid,
              'alert-warning':
                refFOIR_Amount.invalid &&
                (refFOIR_Amount.dirty ||
                  refFOIR_Amount.touched ||
                  refFOIR_Amount.untouched)
            }"
            [disabled]="!expense.IsEligible || IsEdit"
          />
        </div>
      </div>

      <div class="row m-0" style="display: flex">
        <div class="col-md-6 p-1">
          <div class="bg-success1">Total:</div>
        </div>
        <div class="col-md-2 p-1">
          <span class="form-control1 input-text-css text-right pr-3">
            {{ totalMonthlyExpenseCalculated.total }}
          </span>
        </div>

        <div class="col-md-2"></div>
        <div class="col-md-2 p-1 order-md-12">
          <span class="form-control1 input-text-css text-right pr-3">
            {{ totalCalculator1.expAmount }}
          </span>
        </div>
      </div>
    </ng-container>
  </form>

  <div class="row m-0">
    <div class="col-md-12 p-1"><hr /></div>
  </div>

  <form #rrf="ngForm" novalidate class="formborder">
    <ng-container>
      <div class="row m-0 mt-1 mr-2">
        <div class="col-md-12 ml-1 bg-success1">Obligations:</div>
      </div>
      <div
        class="row m-0 col-md-12"
        *ngFor="let obligation of ObligationModel; let i = index"
      >
        <div class="col-md-2" style="padding: 5px 1px 5px 1px !important">
          <span class="required-lable" *ngIf="i == 0">Customer</span>

          <input
            type="search"
            class="form-control input-text-css"
            [(ngModel)]="obligation.CustomerName"
            id="CustomerName2{{ i }}"
            name="CustomerName2{{ i }}"
            autocomplete="off"
            required
            #refCustomerName1="ngModel"
            placeholder="Customer Name"
            [disabled]="IsDisabled"
            [ngClass]="{
              'is-invalid': rrf.submitted && refCustomerName1.invalid,
              'alert-warning':
                refCustomerName1.invalid &&
                (refCustomerName1.dirty ||
                  refCustomerName1.touched ||
                  refCustomerName1.untouched)
            }"
          />
        </div>

        <div class="col-md-2" style="padding: 5px 1px 5px 1px !important">
          <span class="required-lable" *ngIf="i == 0">Obligation Type</span>
          <input
            required
            type="text"
            #refInc_and_Exp_Type1="ngModel"
            [(ngModel)]="obligation.Inc_and_Exp_Type"
            placeholder="Obligation Type"
            name="Inc_and_Exp_Type2{{ i }}"
            id="Inc_and_Exp_Type2{{ i }}"
            class="form-control input-text-css"
            [disabled]="IsDisabled"
            [ngClass]="{
              'is-invalid': rrf.submitted && refInc_and_Exp_Type1.invalid,
              'alert-warning':
                refInc_and_Exp_Type1.invalid &&
                (refInc_and_Exp_Type1.dirty ||
                  refInc_and_Exp_Type1.touched ||
                  refInc_and_Exp_Type1.untouched)
            }"
          />
        </div>
        <div class="col-md-2" style="padding: 5px 1px 5px 1px !important">
          <span class="required-lable" *ngIf="i == 0">Obligation From</span>
          <input
            required
            type="text"
            #refInc_and_Exp_From1="ngModel"
            [(ngModel)]="obligation.Inc_and_Exp_From"
            [disabled]="IsDisabled"
            placeholder="Obligation From"
            name="Inc_and_Exp_From2{{ i }}"
            id="Inc_and_Exp_From2{{ i }}"
            class="form-control input-text-css"
            [ngClass]="{
              'is-invalid': rrf.submitted && refInc_and_Exp_From1.invalid,
              'alert-warning':
                refInc_and_Exp_From1.invalid &&
                (refInc_and_Exp_From1.dirty ||
                  refInc_and_Exp_From1.touched ||
                  refInc_and_Exp_From1.untouched)
            }"
          />
        </div>
        <div class="col-md-1" style="padding: 5px 1px 5px 1px !important">
          <span class="required-lable" *ngIf="i == 0">Obligation</span>
          <input
            required
            type="number"
            numbersOnly
            #refAmount2="ngModel"
            [(ngModel)]="obligation.Amount"
            [disabled]="IsDisabled"
            name="Amount2{{ i }}"
            id="Amount2{{ i }}"
            placeholder="Amount"
            class="form-control input-text-css text-right pr-3"
            [ngClass]="{
              'is-invalid': rrf.submitted && refAmount2.invalid,
              'alert-warning':
                refAmount2.invalid &&
                (refAmount2.dirty || refAmount2.touched || refAmount2.untouched)
            }"
          />
        </div>

        <div class="col-md-1" style="padding: 5px 1px 5px 1px !important">
          <span *ngIf="i == 0">Remarks</span>
          <textarea
            rows="1"
            name="Remarks2{{ i }}"
            id="Remarks2{{ i }}"
            [(ngModel)]="obligation.Remarks"
            #refRemark="ngModel"
            placeholder="Remark"
            class="form-control input-text-css"
            [disabled]="IsDisabled"
          ></textarea>
        </div>

        <div class="col-md-1 p-1">
          <span *ngIf="i == 0"> Document</span>
          <p
            class="mt-1"
            style="
              overflow-wrap: break-word;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <i
              class="fa fa-paperclip fileUpload"
              *ngIf="!obligation.Doc_FileName"
              aria-hidden="true"
            ></i>
            <ng-container *ngIf="obligation.Doc_FileName">
              <a
                href="{{ docBaseUrl }}{{ obligation.Customer_Id }}/{{
                  obligation.Doc_FileName
                }}"
                target="_blank"
              >
                <i
                  class="fa fa-paperclip mr-2"
                  aria-hidden="true"
                  style="font-size: 18px"
                ></i>
              </a>

              <!-- <i
                class="fa fa-times ml-2 fs-16"
                aria-hidden="true"
                style="cursor: pointer"
                (click)="removeFileExpense(expense, docFile)"
              ></i> -->
            </ng-container>
          </p>
          <input
            #docFile1
            type="file"
            [multiple]="false"
            accept="application/pdf,application/vnd.ms-excel"
            (change)="fileChangeListenerExpense(obligation, docFile1.files)"
            style="display: none"
            [disabled]="IsDisabled"
          />
        </div>

        <div
          class="col-md-1"
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5px 0px 5px 0px !important;
          "
        >
          <span *ngIf="i == 0" required>Is Consider</span>
          <input
            style="outline: none; height: 20px; width: 20px"
            onfocus="this.style.outline='none';"
            (change)="toggleCheckbox('', '', obligation)"
            type="checkbox"
            #refIsEligible2="ngModel"
            [(ngModel)]="obligation.IsEligible"
            name="IsEligible2{{ i }}"
            id="IsEligible2{{ i }}"
            class="input-text-css text-right pr-3 mt-2"
            [ngClass]="{
              'is-invalid': rrf.submitted && refIsEligible2.invalid,
              'alert-warning':
                refIsEligible2.invalid &&
                (refIsEligible2.dirty ||
                  refIsEligible2.touched ||
                  refIsEligible2.untouched)
            }"
            [disabled]="IsEdit"
            *ngIf="obligation.Amount != 0"
          />
        </div>

        <div class="col-md-2" style="padding: 5px 1px 5px 1px !important">
          <span class="required-lable" *ngIf="i == 0" style="float: right"
            >Considered Obligation</span
          >
          <input
            required
            type="text"
            appTwoDigitDecimaNumber
            (change)="onChangeObligationAmount(obligation)"
            (keyup.enter)="onChangeObligationAmount(obligation)"
            #refFOIR_Amount2="ngModel"
            [(ngModel)]="obligation.FOIR_Amount"
            name="FOIR_Amount2{{ i }}"
            id="FOIR_Amount2{{ i }}"
            placeholder="FOIR Amount"
            class="form-control input-text-css text-right pr-3"
            [ngClass]="{
              'is-invalid': rrf.submitted && refFOIR_Amount2.invalid,
              'alert-warning':
                refFOIR_Amount2.invalid &&
                (refFOIR_Amount2.dirty ||
                  refFOIR_Amount2.touched ||
                  refFOIR_Amount2.untouched)
            }"
            [disabled]="!obligation.IsEligible || IsEdit"
          />
        </div>
      </div>

      <div class="row m-0" style="display: flex">
        <div class="col-md-6 p-1">
          <div class="bg-success1">Total:</div>
        </div>
        <div class="col-md-2 p-1">
          <span class="form-control1 input-text-css text-right pr-3">
            {{ totalMonthlyObligationCalculated.total }}
          </span>
        </div>

        <div class="col-md-2"></div>
        <div
          class="col-md-2 p-1 order-md-12"
          style="margin-left: -12px !important"
        >
          <span class="form-control1 input-text-css text-right pr-3">
            {{ totalCalculatorObligation.total }}
          </span>
        </div>
      </div>
    </ng-container>
  </form>

  <div class="row m-0">
    <div class="col-md-12 p-1"><hr /></div>
  </div>
  <div class="table-responsive simple-table mt-2">
    <table class="table table-bordered font-size-12">
      <thead>
        <tr>
          <th colspan="2" class="text-center">Expenses To Income Ratio</th>
          <th colspan="2" class="text-center">
            Obligation To Gross Income Ratio
          </th>
          <th colspan="2" class="text-center">
            Fixed Obligation to Income Ratio (FOIR)
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center">
          <td>Gross Income</td>
          <td>{{ totalCalculator.total }}</td>
          <td>Gross Income</td>
          <td>{{ totalCalculator.total }}</td>
          <td>Net Income</td>
          <td>{{ totalCalculator.total - totalCalculator1.expAmount }}</td>
        </tr>
        <tr class="text-center">
          <td>Gross Expenses</td>
          <td>{{ totalCalculator1.expAmount }}</td>
          <td>Previous Obligation</td>
          <td>{{ totalCalculatorObligation.total }}</td>
          <td>Previous Obligation</td>
          <td>{{ totalCalculatorObligation.total }}</td>
        </tr>
        <tr class="text-center">
          <td>Net Income</td>
          <td>{{ totalCalculator.total - totalCalculator1.expAmount }}</td>
          <td>Current Obligation</td>
          <td>{{ CurrentEmi }}</td>
          <td>Current Obligation</td>
          <td>{{ CurrentEmi }}</td>
        </tr>
        <tr class="text-center">
          <td></td>
          <td></td>
          <td>Margin</td>
          <td>
            {{
              totalCalculator.total -
                totalCalculatorObligation.total -
                CurrentEmi
            }}
          </td>
          <td>Margin</td>
          <td>
            {{
              totalCalculator.total -
                totalCalculator1.expAmount -
                totalCalculatorObligation.total -
                CurrentEmi
            }}
          </td>
        </tr>
        <tr
          class="text-center"
          style="font-weight: bold; background-color: #0e5c8654"
        >
          <td>Ratio</td>
          <td>
            {{
              ((totalCalculator1.expAmount == 0 &&
                totalCalculator.total == 0) ||
              (totalCalculator1.expAmount / totalCalculator.total) * 100 ==
                "Infinity"
                ? 0
                : (totalCalculator1.expAmount / totalCalculator.total) * 100
              ).toFixed(2)
            }}
            %
          </td>
          <td>Ratio</td>
          <td>
            {{
              ((TotalObligation / totalCalculator.total) * 100 == "Infinity"
                ? 0
                : (TotalObligation / totalCalculator.total) * 100
              ).toFixed(2)
            }}%
          </td>
          <td>Ratio</td>
          <td>
            {{
              ((TotalObligation /
                (totalCalculator.total - totalCalculator1.expAmount)) *
                100 ==
              "Infinity"
                ? 0
                : (TotalObligation /
                    (totalCalculator.total - totalCalculator1.expAmount)) *
                  100
              ).toFixed(2)
            }}%
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!--<div class="row m-0 mt-2">
    <div class="col-md-2 p-1">
      <div class="bg-success1">Margin:</div>
    </div>
    <div class="col-md-2 p-1">
      <input
        required
        type="text"
        #refMargin="ngModel"
        [(ngModel)]="Margin"
        placeholder="Margin"
        name="Margin"
        id="Margin"
        class="form-control1 input-text-css text-right pr-3"
        disabled
      />
    </div>
  </div>

  <div class="row m-0 mt-2">
    <div class="col-md-2 p-1">
      <div class="bg-success1">
        Eligibility:
        <input [disabled]="IsEdit"
               type="text"
               #refEligibility="ngModel"
               [(ngModel)]="Eligibility"
               placeholder="Eligibility Ratio %"
               name="Eligibility"
               id="Eligibility"
               class="form-control input-text-css text-right pr-3 ml-4"
               maxLength="3"
               numbersOnly
               (change)="onChangeEligibilityRatio()"
               style="height: calc(1.3em + 0.75rem + 2px); width: 45%; display: inline;" />
        <span class="mt-2">%</span>
      </div>
    </div>

    <div class="col-md-2 p-1">
      <input
        type="text"
        #refEMIEligibility="ngModel"
        [(ngModel)]="EMIEligibility"
        placeholder="EMI Eligibility"
        name="EMIEligibility"
        id="EMIEligibility"
        class="form-control1 input-text-css text-right pr-3"
        disabled
      />
    </div>
  </div>

  <div class="row m-0 mt-2">
    <div class="col-md-2 p-1">
      <div class="bg-success1">Average Current EMI:</div>
    </div>
    <div class="col-md-2 p-1">
      <input
        disabled
        type="number"
        #refCurrentEmi="ngModel"
        [(ngModel)]="CurrentEmi"
        placeholder="Current Emi"
        name="CurrentEmi"
        id="CurrentEmi"
        class="form-control1 input-text-css text-right pr-3"
      />
    </div>
  </div>

  <div class="row m-0 mt-2">
    <div class="col-md-2 p-1">
      <div class="bg-success1">Saving:</div>
    </div>
    <div class="col-md-2 p-1">
      <input
       disabled
        type="text"
        #refSavingAmount="ngModel"
        [(ngModel)]="SavingAmount"
        placeholder="Saving Amount"
        name="SavingAmount"
        id="SavingAmount"
        class="form-control1 input-text-css text-right pr-3"
      />
    </div>
  </div>-->

  <div class="col-md-12 mt-2">
    <div class="col-md-3" style="float: right">
      <button
        *ngIf="
          IsEdit &&
          !loanSummary.CloseDate &&
          PageAcess &&
          PageAcess[0].Edit == 1
        "
        type="button"
        (click)="onEditFoirAmount()"
        [class.spinner]="loading"
        class="btn font-size-12 button-btn"
      >
        Edit
      </button>

      <button
        *ngIf="!IsEdit && !loanSummary.CloseDate"
        type="button"
        class="btn font-size-12 button-btn"
        (click)="QuickLoan_Save_FOIR_Detail()"
      >
        Save
      </button>
      <button
        type="button"
        style="float: right"
        (click)="CheckCustomerFOIRInfo()"
        class="font-size-12 button-btn"
      >
        Next <i class="fa fa-arrow-right"></i>
      </button>
    </div>
  </div>
</div>

<ng-template #elseBlock>
  <div><h1>Please fill Income and Expense details</h1></div>
  <div class="row col-md-12 m-0 mt-2 justify-content-end">
    <button
      type="button"
      (click)="CheckCustomerFOIRInfo()"
      class="font-size-12 button-btn"
    >
      Next <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</ng-template>
