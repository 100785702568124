<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="formborder">
    <ng-container *ngFor="let loanDetailObj of LoanAllDetail">
      <div class="row m-0 h-b w-b" style="width: 100%">
        <h1 class="fs-12 m-0">Loan Details</h1>
      </div>
      <div class="row m-0 mt-3">
        <div class="col-md-2">
          <h6 class="fs-12">Loan No</h6>
          <p class="lead fs-12">{{ loanDetailObj.LoanNo }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Loan Date</h6>
          <p class="lead fs-12">{{ loanDetailObj.LoanDate }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Application No</h6>
          <p class="lead fs-12">{{ loanDetailObj.ApplicationNo }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Product</h6>
          <p class="lead fs-12">{{ loanDetailObj.Product }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Branch</h6>
          <p class="lead fs-12">{{ loanDetailObj.Branch }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Asset Cost</h6>
          <p class="lead fs-12 fw-7">
            ₹ {{ loanDetailObj.AssetCost ? loanDetailObj.AssetCost : "0" }}
          </p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Net Finance</h6>
          <!-- .toFixed(2) -->
          <p class="lead fs-12">₹ {{ loanDetailObj.LoanAmount }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Tenure (Months)</h6>
          <p class="lead fs-12">{{ loanDetailObj.LoanTenure }} Months</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">No of Installment</h6>
          <p class="lead fs-12">{{ loanDetailObj.No_Of_Installment }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Agreemnent Value</h6>
          <p class="lead fs-12">₹ {{ loanDetailObj.AgreementValue }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Interest Amount</h6>
          <p class="lead fs-12">₹ {{ loanDetailObj.InterestAmt }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">LTV</h6>
          <p class="lead fs-12">{{ loanDetailObj.LTV }}%</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">EMI Amount</h6>
          <p class="lead fs-12">₹ {{ loanDetailObj.LoanEMIAmount }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">ROI</h6>
          <p class="lead fs-12">{{ loanDetailObj.ROI }}%</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">IRR Calculate By</h6>
          <p class="lead fs-12">{{ loanDetailObj.IRR_CalculateBy }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Case IRR</h6>
          <p class="lead fs-12">{{ loanDetailObj.Case_IRR }}%</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">EMI Due Date</h6>
          <p class="lead fs-12">{{ loanDetailObj.EMI_DueDate }}</p>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="table-responsive">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="Int_Id">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px; max-height: 5px"
        >
          {{ i + 1 }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerId">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer Id
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Customer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
          <app-los-customer-view-model
            [customerId]="row.CustomerId"
            [index]="i"
          >
            {{ row.Customer }}
          </app-los-customer-view-model>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerType">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer Type
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="GenderAge">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Gender Age
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.GenderAge }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PhoneNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Phone No
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          

          <div *ngIf="currentUser.IsMaskingMobile">
            {{ row.PhoneNo | mask }}
          </div>

          <div *ngIf="!currentUser.IsMaskingMobile">
            {{ row.PhoneNo }}
          </div>



          <i
            class="fa fa-check-circle ml-2"
            style="color: green; font-size: 13px"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Relation_With_Hirer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Relation With Borrower
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Relation_With_Hirer }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Present_Address">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="min-width: 300px; max-width: 300px"
          *matHeaderCellDef
        >
          Address
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="min-width: 300px; max-width: 300px"
        >
          {{ row.Present_Address }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ExistingCustomer">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="min-width: 90px; max-width: 90px"
          *matHeaderCellDef
        >
          Existing
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="min-width: 90px; max-width: 90px"
        >
          {{ row.ExistingCustomer }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>

  <ng-container *ngFor="let previousReschObj of PreviousRescheduleList">
    <div class="row m-0 h-b w-b" style="width: 100%">
      <h1 class="fs-12 m-0">
        Previous Re-Scheduled:
        <b style="color: #000000">{{ previousReschObj.Reschedule_Date }}</b>
      </h1>
    </div>
    <div class="row m-0 mt-3">
      <div class="col-md-2">
        <h6 class="fs-12">Type</h6>
        <p class="lead fs-12">{{ previousReschObj.Type }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Reschedule Date</h6>
        <p class="lead fs-12">{{ previousReschObj.Reschedule_Date }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Reschedule Type</h6>
        <p class="lead fs-12">{{ previousReschObj.Reschedule_Type }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Request Status</h6>
        <p class="lead fs-12">{{ previousReschObj.Request_Status }}</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">Asset Cost</h6>
        <p class="lead fs-12 fw-7">
          ₹ {{ previousReschObj.AssetCost ? previousReschObj.AssetCost : "0" }}
        </p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">Net Finance</h6>
        <p class="lead fs-12">₹ {{ previousReschObj.LoanAmount }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Tenure (Months)</h6>
        <p class="lead fs-12">{{ previousReschObj.LoanTenure }} Months</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">No of Installment</h6>
        <p class="lead fs-12">{{ previousReschObj.No_Of_Installment }}</p>
      </div>
      <!-- <div class="col-md-2">
        <h6 class="fs-12">Adv. Installment</h6>
        <p class="lead fs-12">{{ previousReschObj.AdvInstl }}</p>
      </div> -->
      <div class="col-md-2">
        <h6 class="fs-12">ROI</h6>
        <p class="lead fs-12">{{ previousReschObj.ROI }}%</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">EMI Due Date</h6>
        <p class="lead fs-12">{{ previousReschObj.EMI_DueDate }}</p>
      </div>

      <!--<div class="col-md-12 mb-3" *ngIf="previousReschObj.EMIAmountList.length > 0">
        <div class="row m-0 mt-3 justify-content-center">
          <div class="table-border">
            <div class="item-row header">
              <div class="lead w50 fs-14">#</div>
              <div class="lead w120 fs-14">From EMI</div>
              <div class="lead w120 fs-14">To EMI</div>
              <div class="lead fs-14">EMI Amount</div>
              <div class="lead w120 fs-14">No of EMI</div>
              <div class="lead w150 fs-14">Total Amount</div>
            </div>
            <div class="item-row" *ngFor="let semi of previousReschObj.EMIAmountList; let i = index">
              <div class="w50">{{ i + 1 }}</div>
              <div class="w120">
                <input type="text" numbersOnly readonly [(ngModel)]="semi.FromEMI" placeholder="From EMI"
                  name="FromEMI{{ i }}" id="FromEMI{{ i }}" class="form-control input-text-css" />
              </div>
              <div class="w120">
                <input type="text" numbersOnly readonly [(ngModel)]="semi.ToEMI" placeholder="To EMI"
                  name="ToEMI{{ i }}" id="ToEMI{{ i }}" class="form-control input-text-css" />
              </div>
              <div class="">
                <input type="text" numbersOnly readonly [(ngModel)]="semi.EMIAmount" placeholder="EMI Amount"
                  name="EMIAmount{{ i }}" id="EMIAmount{{ i }}" class="form-control input-text-css mr-3 emi-amount" />
              </div>
              <div class="w120">{{ semi.NoOfEMI }}&nbsp;</div>
              <div class="w150">{{ semi.TotalAmount }}&nbsp;</div>
            </div>
            <div class="item-row header" *ngIf="previousReschObj.stepEmiData.totalEmi">
              <div class="lead w50 fs-14">&nbsp;</div>
              <div class="lead w120 fs-14"><b>Total</b></div>
              <div class="lead w120 fs-14">&nbsp;</div>
              <div class="lead fs-14">&nbsp;</div>
              <div class="lead w120 fs-14">
                <b>{{ previousReschObj.stepEmiData.totalEmi }}</b>
              </div>
              <div class="lead w150 fs-14">
                <b>{{ previousReschObj.stepEmiData.totalAmount }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>-->

      <div class="col-md-2">
        <h6 class="fs-12">Agreemnent Value</h6>
        <p class="lead fs-12">₹ {{ previousReschObj.AgreementValue }}</p>
      </div>
      <div class="col-md-2">
        <h6 class="fs-12">Interest Amount</h6>
        <p class="lead fs-12">₹ {{ previousReschObj.InterestAmt }}</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">LTV</h6>
        <p class="lead fs-12">{{ previousReschObj.LTV }}%</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">EMI Amount</h6>
        <p class="lead fs-12">₹ {{ previousReschObj.LoanEMIAmount }}</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">IRR Calculate By</h6>
        <p class="lead fs-12">{{ previousReschObj.IRR_CalculateBy }}</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">Case IRR</h6>
        <p class="lead fs-12">{{ previousReschObj.Case_IRR }}%</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">Reschedule Request By</h6>
        <p class="lead fs-12">{{ previousReschObj.Reschedule_RequestBy }}</p>
      </div>

      <div class="col-md-2">
        <h6 class="fs-12">Reschedule Request On</h6>
        <p class="lead fs-12">{{ previousReschObj.Reschedule_RequestOn }}</p>
      </div>

      <div class="col-md-4">
        <h6 class="fs-12">Reschedule Remark</h6>
        <p class="lead fs-12">{{ previousReschObj.Reschedule_Remark }}</p>
      </div>

      <div class="col-md-2" *ngIf="previousReschObj.Authorised_By != null">
        <h6 class="fs-12">Authorised By</h6>
        <p class="lead fs-12">{{ previousReschObj.Authorised_By }}</p>
      </div>

      <div class="col-md-2" *ngIf="previousReschObj.Authorised_On != null">
        <h6 class="fs-12">Authorised On</h6>
        <p class="lead fs-12">{{ previousReschObj.Authorised_On }}</p>
      </div>

      <div class="col-md-4" *ngIf="previousReschObj.Authorised_Remark != null">
        <h6 class="fs-12">Authorised Remark</h6>
        <p class="lead fs-12">{{ previousReschObj.Authorised_Remark }}</p>
      </div>
    </div>
  </ng-container>

  <ng-container>
    <div
      class="row m-0 h-b w-b justify-content-between align-items-center mt-2"
      style="width: 100%"
    >
      <h1 class="fs-12 m-0">New Re-Scheduling Request</h1>
      <h1 class="fs-12 m-0">Last Trasaction Date {{LoanAllDetail[0].lastTrasactionDate | date : "dd-MM-yyyy"}}</h1>
      <button
        type="button"
        (click)="onViewLoanStatement()"
        class="btn btn-light font-size-12"
        style="padding: 3px 5px"
      >
        View Loan Statement
      </button>
    </div>
    <form #eif="ngForm"
          class="row m-0"
          (ngSubmit)="onCalculateEmiAndIRR()"
          novalidate>

      <div class="row m-0 p-0 col-md-12 mt-3">
        <div class="col-md-3 mb-3">
          <span> Type of Re-Scheduling</span>
          <select name="ReSchedulingType"
                  id="ReSchedulingType"
                  (change)="OnRescheduleChange($event)"
                  #refReSchedulingType="ngModel"
                  [disabled]="
              isMaker == 'A' || !ProposedRescheduledata.isRequestStatus
            "
                  [ngClass]="{
              'is-invalid': eif.submitted && refReSchedulingType.invalid,
              'alert-warning':
                refReSchedulingType.invalid &&
                (refReSchedulingType.dirty ||
                  refReSchedulingType.touched ||
                  refReSchedulingType.untouched)
            }"
                  [(ngModel)]="ProposedRescheduledata.Reschedule_Type"
                  class="form-control input-text-css"
                  required>
            <option value="">Select Re-Scheduling Type</option>
            <option value="EMI AMOUNT CHANGE">EMI Amount Change-Auto</option>
            <option value="EMI AMOUNT CHANGE MANUAL">
              EMI Amount Change-Manual
            </option>
            <option value="NO OF INSTALLMENT CHANGE">
              No of Installment Change-Auto
            </option>
            <option value="NO OF INSTALLMENT CHANGE MANUAL">
              No of Installment Change-Manual
            </option>
            <option value="IRR CHANGE">IRR Change</option>
            <option value="DUE DATE CHANGE">Due Date Change</option>
          </select>
        </div>
        <div class="col-md-3 mb-3">
          <span> Impact ON </span>
          <select name="ImpactOn"
                  required
                  id="ImpactOn"
                  #refImpactOn="ngModel"
                  [disabled]="
              TypeofRescheduedisable ||
              isMaker == 'A' ||
              !ProposedRescheduledata.isRequestStatus
            "
                  [ngClass]="{
              'is-invalid': eif.submitted && refImpactOn.invalid,
              'alert-warning':
                refImpactOn.invalid &&
                (refImpactOn.dirty ||
                  refImpactOn.touched ||
                  refImpactOn.untouched)
            }"
                  [(ngModel)]="ProposedRescheduledata.ImpactOn"
                  class="form-control input-text-css">
            <option value="">Select Impact On</option>
            <option value="EMI AMOUNT">EMI Amount</option>
            <option value="NO OF INSTALLMENT">No Of Installment</option>
            <option *ngIf="ProposedRescheduledata.Reschedule_Type != 'IRR CHANGE'"
                    value="DUE DATE">
              Due Date
            </option>
          </select>
        </div>
        <div class="col-md-3 mb-3"
             *ngIf="
            ProposedRescheduledata.Reschedule_Type == 'EMI AMOUNT CHANGE MANUAL'
          ">
          <span> New EMI Amount </span>
          <input type="number"
                 [(ngModel)]="EMIAmount_New"
                 name="EMIAmount_New"
                 id="EMIAmount_New"
                 (change)="AnyChange()"
                 placeholder="New EMI Amount"
                 class="form-control input-text-css"
                 style="width: 160px" />
        </div>
        <div class="col-md-3 mb-3"
             *ngIf="
            ProposedRescheduledata.Reschedule_Type ==
            'NO OF INSTALLMENT CHANGE MANUAL'
          ">
          <span> New No of Installment </span>
          <input type="number"
                 [(ngModel)]="NoOfInstallment_New"
                 name="NoOfInstallment_New"
                 (change)="AnyChange()"
                 id="NoOfInstallment_New"
                 placeholder="New No of Inatallment"
                 class="form-control input-text-css"
                 style="width: 160px" />
        </div>
        <div class="col-md-3 mb-3"
             *ngIf="ProposedRescheduledata.Reschedule_Type == 'IRR CHANGE'">
          <span> New IRR </span>
          <input type="text"
                 [(ngModel)]="IRR_New1"
                 name="IRR_New1"
                 (change)="AnyChange()"
                 id="IRR_New1"
                 placeholder="New IRR"
                 class="form-control input-text-css"
                 style="width: 160px" />
        </div>
        <div class="col-md-3 mb-3"
             *ngIf="ProposedRescheduledata.Reschedule_Type == 'DUE DATE CHANGE'">
          <span> New Due Date </span>
          <div class="datepicker_feild">
            <input [matDatepicker]="picker"
                   dateConvert
                   placeholder="DD/MM/YYYY"
                   [(ngModel)]="EMI_DueDate1"
                   name="EMI_DueDate1"
                   (change)="AnyChange()"
                   id="EMI_DueDate1"
                   [min]="CurrentDate"
                   [max]="newDueDate"
                   [disabled]="
                isMaker == 'A' || !ProposedRescheduledata.isRequestStatus
              "
                   class="form-control input-text-css"
                   style="width: 160px" />
            <mat-datepicker-toggle matSuffix
                                   [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
          <!-- <input
        type="text"
        [(ngModel)]="EMI_DueDate1"
        name="EMI_DueDate1"
        id="EMI_DueDate1"
        [minDate]="CurrentDate"
        [maxDate]="newDueDate"
        [disabled]="
          isMaker == 'A' || !ProposedRescheduledata.isRequestStatus
        "
        value="{{ EMI_DueDate1 | date : 'dd/MM/yyyy' }}"
        placeholder="DD/MM/YYYY"
        [(bsValue)]="EMI_DueDate1"
        class="form-control input-text-css"
        bsDatepicker
        style="width: 160px"
      /> -->
        </div>
        <div class="col-md-3">
          <h6 class="fs-12">Reschedule Date</h6>
          <div class="datepicker_feild">
            <input [matDatepicker]="picker"
                   dateConvert
                   placeholder="DD/MM/YYYY"
                   [(ngModel)]="RescheduleDate"
                   [min]="minRescheduleDate"
                   (dateChange)="RescheduleDateChange()"
                   [max]="CurrentDate"
                        [disabled]=" isMaker == 'A' || !ProposedRescheduledata.isRequestStatus"
                   name="LoanCloser_EffectiveDate"
                   id="LoanCloser_EffectiveDate"
                   class="form-control input-text-css" />
            <mat-datepicker-toggle matSuffix
                                   [for]="picker"
                                   style="right: 0 !important"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>

        <div class="col-md-12 mb-3">
          <span *ngIf="
              ProposedRescheduledata.Reschedule_Type == 'EMI Amount Change'
            ">
            New EMI Amount will be Auto Calculated. Reshduling date onwards
            previous vocuhers of amortisation will be deleted and new vocuhers
            will be added for new EMI Amount.
          </span>
          <span *ngIf="
              ProposedRescheduledata.Reschedule_Type ==
              'No of Installment Change'
            ">
            New Tenure will be Auto Calculated. Reshduling date onwards previous
            vocuhers of amortisation will be deleted and new vocuhers will be
            added for new tenure.
          </span>
          <span *ngIf="ProposedRescheduledata.Reschedule_Type == 'IRR Change'">
            System will ask for impact on : EMI Amount/Tenure. Reshduling date
            onwards previous vocuhers of amortisation will be deleted and new
            vocuhers will be added according to impact.
          </span>
          <span *ngIf="ProposedRescheduledata.Reschedule_Type == 'Due Date Change'">
            No calculation for plan but Pre-emi amount to becalculated for
            broken days, if any. Reshduling dateonwards previous vocuhers of
            amortisation will bedeleted and new vocuhers will be added with new
            date.
          </span>
        </div>

        <div class="table-responsive mt-3 box-shadow">
          <mat-table [dataSource]="dataSourceLoanODCharges"
                     #sortList="matSort"
                     matSort
                     id="exportReport">
            <ng-container matColumnDef="ChagesHead">
              <mat-header-cell mat-sort-header
                               class="grid-header"
                               *matHeaderCellDef>Charges Head</mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{
        row.ChagesHead
                }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef>Total</mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeDR">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef>Debit</mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell text-right">
                {{row.TranTypeDR}}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right">
                {{ calculateChargesTranTypeDR("OD") }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeCR">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef>Credit</mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell text-right">
                {{
        row.TranTypeCR
                }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right">
                {{ calculateChargesTranTypeCR("OD") }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeTotal">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef>Balance</mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell text-right">
                {{
        row.TranTypeTotal
                }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right">
                {{ calculateChargesTranTypeTotal("OD") }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="TranTypeTotalInclude">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef>Balance Include</mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell text-right">
                {{
row.TranTypeTotalInclude
                }}
              </mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right">
                {{ calculateChargesTranTypeTotalInclude("OD") }}
              </mat-footer-cell>
            </ng-container>

            
            <ng-container matColumnDef="excludedcharges">
              <mat-header-cell class="grid-header text-right" *matHeaderCellDef>Excluded Charges</mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell text-right">
                <input type="checkbox"
                       *ngIf="row.HeadId !=27 "
                         [disabled]=" isMaker == 'A' || !ProposedRescheduledata.isRequestStatus"
                       [value]="row.excludedcharges"
                       [checked]="row.excludedcharges"
                       (change)="excludedcharges(row,$event)" />
              </mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right">
              </mat-footer-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumnsODCharges"></mat-header-row>
            <mat-row *matRowDef="
        let row;
        let i = index;
        columns: displayedColumnsODCharges
      "></mat-row>
            <mat-footer-row class="sticky-footer fontcolor"
                            *matFooterRowDef="displayedColumnsODCharges"></mat-footer-row>
          </mat-table>
        </div>

        <div class="col-md-12 mb-3 mt-3 text-right" style="align-items: end" *ngIf="errorShow == false && errorShowChequeinTransit == false">
          <button type="button"
                  *ngIf="isMaker == 'R' && ProposedRescheduledata.isRequestStatus"
                  class="pl-5 pr-5 btn font-size-12 button-btn"
                  (click)="OnCalculate()"
                  [disabled]="
            !(
              ProposedRescheduledata.Request_Status == '' ||
              ProposedRescheduledata.Request_Status == 'Created'
            )
          ">
            Calculate
          </button>
        </div>
        <div class="col-md-12 mb-3" *ngIf="errorShow == false && errorShowChequeinTransit == false">
          <div class="row m-0 mt-3 justify-content-center">
            <div class="table-border" style="width: 80%">
              <div class="item-row header">
                <div class="lead fs-14"><b>Perticulars</b></div>
                <div class="lead fs-14"><b>Existing Case Detail</b></div>
                <div class="lead fs-14"><b>Proposed Calculation</b></div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">Reschedule Date</div>
                <div class="lead fs-14">
                  {{ CurrentRescheduledata.Reschedule_Date }}
                </div>
                <div class="lead fs-14 justify-content-start">
                  <input type="text"
                         disabled
                         [(ngModel)]="ProposedRescheduledata.Reschedule_Date"
                         placeholder="Reschedule Date"
                         name="Reschedule_Date"
                         id="Reschedule_Date"
                         class="form-control input-text-css"
                         style="width: 160px" />
                </div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">Asset Cost (Rs.)</div>
                <div class="lead fs-14">
                  {{ CurrentRescheduledata.AssetCost }}
                </div>
                <div class="lead fs-14 justify-content-start">
                  <input type="text"
                         disabled
                         numbersOnly
                         [(ngModel)]="ProposedRescheduledata.AssetCost"
                         style="width: 160px"
                         placeholder="Asset Cost"
                         name="Asset_Cost"
                         id="Asset_Cost"
                         class="form-control input-text-css" />
                </div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">LTV (%)</div>
                <div class="lead fs-14">{{ CurrentRescheduledata.LTV }}</div>
                <div class="lead fs-14 justify-content-start">
                  <input type="text"
                         numbersOnly
                         disabled
                         [(ngModel)]="ProposedRescheduledata.LTV"
                         placeholder="LTV"
                         name="LTV"
                         id="LTV"
                         class="form-control input-text-css"
                         style="width: 160px" />
                </div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">Loan Amount (Rs.)</div>
                <div class="lead fs-14">
                  {{ CurrentRescheduledata.LoanAmount }}
                </div>
                <div class="lead fs-14 justify-content-start">
                  <input type="text"
                         numbersOnly
                         disabled
                         [(ngModel)]="ProposedRescheduledata.LoanAmount"
                         style="width: 160px"
                         placeholder="Loan Amount"
                         name="LoanAmount"
                         id="LoanAmount"
                         class="form-control input-text-css" />
                </div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">Interest Amount (Rs.)</div>
                <div class="lead fs-14">
                  {{ CurrentRescheduledata.InterestAmt }}
                </div>
                <div class="lead fs-14 justify-content-start">
                  <input type="text"
                         numbersOnly
                         disabled
                         [(ngModel)]="ProposedRescheduledata.InterestAmt"
                         placeholder="Interest Amount"
                         name="InterestAmt"
                         id="InterestAmt"
                         class="form-control input-text-css"
                         style="width: 160px" />
                </div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">Agreement Value (Rs.)</div>
                <div class="lead fs-14">
                  {{ CurrentRescheduledata.AgreementValue }}
                </div>
                <div class="lead fs-14 justify-content-start">
                  <input type="text"
                         numbersOnly
                         disabled
                         [(ngModel)]="ProposedRescheduledata.AgreementValue"
                         placeholder="Agreement Value"
                         name="AgreementValue"
                         id="AgreementValue"
                         class="form-control input-text-css"
                         style="width: 160px" />
                </div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">EMI Amount (Rs.)</div>
                <div class="lead fs-14">
                  {{ CurrentRescheduledata.LoanEMIAmount }}
                </div>
                <div class="lead fs-14 justify-content-start">
                  <input type="text"
                         numbersOnly
                         disabled
                         [(ngModel)]="ProposedRescheduledata.LoanEMIAmount"
                         placeholder="Loan EMI Amount"
                         name="LoanEMIAmount"
                         id="LoanEMIAmount"
                         class="form-control input-text-css"
                         style="width: 160px" />
                </div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">No Of Installment</div>
                <div class="lead fs-14">
                  {{ CurrentRescheduledata.No_Of_Installment }}
                  <span style="font-size: 10px"></span>
                </div>
                <div class="lead fs-14 d-flex justify-content-between">
                  <input type="text"
                         numbersOnly
                         disabled
                         [(ngModel)]="ProposedRescheduledata.No_Of_Installment"
                         placeholder="No Of Installments"
                         name="No_Of_Inst"
                         id="No_Of_Inst"
                         class="form-control input-text-css"
                         style="width: 160px" />
                  <span style="font-size: 10px">(As Per Voucher)</span>
                </div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">No Of Installment Decimal</div>
                <div class="lead fs-14">
                  {{ CurrentRescheduledata.No_Of_Installment_Decimal }}
                  <span style="font-size: 10px"></span>
                </div>
                <div class="lead fs-14 d-flex justify-content-between">
                  <input type="text"
                         disabled
                         [(ngModel)]="
                      ProposedRescheduledata.No_Of_Installment_Decimal
                    "
                         placeholder="No Of Installments"
                         name="No_Of_Installment_Decimal"
                         id="No_Of_Installment_Decimal"
                         class="form-control input-text-css"
                         style="width: 160px" />
                  <span style="font-size: 10px">(As Per Voucher)</span>
                </div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">Tenure (Month)</div>
                <div class="lead fs-14">
                  {{ CurrentRescheduledata.LoanTenure }}
                  <span style="font-size: 10px"></span>
                </div>
                <div class="lead fs-14 d-flex justify-content-between">
                  <input type="text"
                         numbersOnly
                         disabled
                         [(ngModel)]="ProposedRescheduledata.LoanTenure"
                         placeholder="Loan Tenure"
                         name="LoanTenure"
                         id="LoanTenure"
                         class="form-control input-text-css"
                         style="width: 160px" />
                  <span style="font-size: 10px">(As Per Voucher)</span>
                </div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">Tenure Decimal (Month)</div>
                <div class="lead fs-14">
                  {{ CurrentRescheduledata.LoanTenure_Decimal }}
                  <span style="font-size: 10px"></span>
                </div>
                <div class="lead fs-14 d-flex justify-content-between">
                  <input type="text"
                         numbersOnly
                         disabled
                         [(ngModel)]="ProposedRescheduledata.LoanTenure_Decimal"
                         placeholder="Loan Tenure"
                         name="LoanTenure_Decimal"
                         id="LoanTenure_Decimal"
                         class="form-control input-text-css"
                         style="width: 160px" />
                  <span style="font-size: 10px">(As Per Voucher)</span>
                </div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">EMI Frequancy</div>
                <div class="lead fs-14">
                  {{ CurrentRescheduledata.EMIType }}
                </div>
                <div class="lead fs-14 justify-content-start">
                  <select name="EMIType"
                          id="EMIType"
                          disabled
                          [(ngModel)]="ProposedRescheduledata.EMIType"
                          class="form-control input-text-css"
                          style="width: 160px">
                    <!-- <option value=""> EMI Frequancy </option> -->
                    <!-- <option value="Daily"> Daily</option> -->
                    <!-- <option value="Weekly"> Weekly</option> -->
                    <!-- <option value="Fortnightly"> Fortnightly</option> -->
                    <option value="Monthly">Monthly</option>
                    <option value="ByMonthly">Bi-Monthly</option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="HalfYearly">Half Yearly</option>
                    <option value="Yearly">Yearly</option>
                    <option value="Bullet">Bullet</option>
                  </select>
                </div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">Case IRR (%)</div>
                <div class="lead fs-14">
                  {{ CurrentRescheduledata.Case_IRR }}
                </div>
                <div class="lead fs-14 justify-content-start">
                  <input type="text"
                         numbersOnly
                         disabled
                         [(ngModel)]="ProposedRescheduledata.Case_IRR"
                         placeholder="Case IRR"
                         name="Case_IRR"
                         id="Case_IRR"
                         style="width: 160px"
                         class="form-control input-text-css" />
                </div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">ROI (%)</div>
                <div class="lead fs-14">{{ CurrentRescheduledata.ROI }}</div>
                <div class="lead fs-14 justify-content-start">
                  <input type="text"
                         disabled
                         [(ngModel)]="ProposedRescheduledata.ROI"
                         placeholder="ROI"
                         name="ROI"
                         id="ROI"
                         class="form-control input-text-css"
                         style="width: 160px" />
                </div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">IRR Calculate By</div>
                <div class="lead fs-14">
                  {{ CurrentRescheduledata.IRR_CalculateBy }}
                </div>
                <div class="lead fs-14 justify-content-start">
                  <input type="text"
                         disabled
                         [(ngModel)]="ProposedRescheduledata.IRR_CalculateBy"
                         placeholder="IRR Calculate By"
                         name="IRR_CalculateBy"
                         id="IRR_CalculateBy"
                         class="form-control input-text-css"
                         style="width: 160px" />
                </div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">EMI Due Date</div>
                <div class="lead fs-14">
                  {{ CurrentRescheduledata.EMI_DueDate | date : "dd/MM/yyyy" }}
                </div>
                <div class="lead fs-14 d-flex align-items-center justify-content-between">
                  <div class="datepicker_feild">
                    <input [matDatepicker]="picker2"
                           dateConvert
                           placeholder="DD/MM/YYYY"
                           disabled
                           [(ngModel)]="ProposedRescheduledata.EMI_DueDate"
                           name="EMI_DueDate"
                           id="EMI_DueDate"
                           [min]="CurrentDate"
                           [max]="newDueDate"
                           class="form-control input-text-css"
                           style="width: 160px" />
                    <mat-datepicker-toggle matSuffix
                                           [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </div>

                  <!-- <input
                type="text"
                disabled
                [(ngModel)]="ProposedRescheduledata.EMI_DueDate"
                name="EMI_DueDate"
                id="EMI_DueDate"
                [minDate]="CurrentDate"
                [maxDate]="newDueDate"
                value="{{
                  ProposedRescheduledata.EMI_DueDate | date : 'dd/MM/yyyy'
                }}"
                placeholder="DD/MM/YYYY"
                [(bsValue)]="ProposedRescheduledata.EMI_DueDate"
                class="form-control input-text-css"
                bsDatepicker
                style="width: 160px"
              /> -->

                  <span *ngIf="
                      ProposedRescheduledata.No_Of_Days_Diff != '' &&
                      ProposedRescheduledata.No_Of_Days_Diff != null
                    "
                        style="font-size: 12px">
                    <span>Days: {{ ProposedRescheduledata.No_Of_Days_Diff }}</span><br />
                    <span>
                      Interest:
                      {{ ProposedRescheduledata.No_Of_Days_Interest }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="item-row">
                <div class="lead fs-14">Principle Outstanding (Rs.)</div>
                <div class="lead fs-14">
                  {{ CurrentRescheduledata.Net_Finance_OS }}
                  <span style="font-size: 10px">(As Per Plan)</span>
                </div>
                <div class="lead fs-14 d-flex justify-content-between">
                  <input type="text"
                         disabled
                         [(ngModel)]="ProposedRescheduledata.Net_Finance_OS"
                         placeholder="Net Finance OS"
                         name="Net_Finance_OS"
                         id="Net_Finance_OS"
                         class="form-control input-text-css"
                         style="width: 160px" />
                  <span style="font-size: 10px">(As Per Voucher)</span>
                </div>
              </div>
            </div>
            <div style="width: 80%">
              <div *ngIf="
                  isMaker == 'A' &&
                  ProposedRescheduledata.Reschedule_RequestOn != '' &&
                  ProposedRescheduledata.Reschedule_RequestOn != null
                "
                   class="row m-0 mt-3 align-items-center">
                <div class="col-md-2 p-0">Reschedule Request By:</div>
                <div class="col-md-3 p-0">
                  {{ ProposedRescheduledata.Reschedule_RequestBy }}
                </div>
                <div class="col-md-2 p-0">Reschedule Request On:</div>
                <div class="col-md-3 p-0">
                  {{ ProposedRescheduledata.Reschedule_RequestOn }}
                </div>
              </div>
              <div class="row m-0 mt-3 align-items-center">
                <div class="col-md-2 p-0">Reschedule Remark:</div>
                <div class="col-md-10 p-0">
                  <textarea [(ngModel)]="ProposedRescheduledata.Reschedule_Remark"
                            placeholder="Reschedule Remark"
                            name="Reschedule_Remark"
                            id="Reschedule_Remark"
                            class="form-control input-text-css"
                            [disabled]="
                      isMaker == 'A' || !ProposedRescheduledata.isRequestStatus
                    "></textarea>
                </div>
              </div>
              <div class="row m-0 mt-3 align-items-center">
                <div class="col-md-2 p-0">Request Doc:</div>
                <div class="col-md-10 p-0">
                  <input type="file"
                         *ngIf="ProposedRescheduledata.Request_Doc == ''"
                         (change)="fileChangeEvent($event)"
                         name="Request_Doc"
                         accept=".png, .jpg, .jpeg"
                         id="Request_Doc"
                         [disabled]="
                      isMaker == 'A' || !ProposedRescheduledata.isRequestStatus
                    " />
                  <a href="{{ loandocBaseUrl }}{{ Loan_id }}/{{
                      ProposedRescheduledata.Request_Doc
                    }}"
                     target="_blank"
                     *ngIf="ProposedRescheduledata.Request_Doc != ''">
                    {{ ProposedRescheduledata.Request_Doc }}
                  </a>
                  <i class="fa fa-times ml-2"
                     style="cursor: pointer; font-size: larger"
                     (click)="onRemoveImg()"
                     aria-label="true"
                     *ngIf="ProposedRescheduledata.Request_Doc != ''"></i>
                </div>
              </div>
              <div class="row m-0 mt-3 align-items-center"
                   *ngIf="isMaker == 'A'">
                <div class="col-md-2 p-0">Status:</div>
                <div class="col-md-4 p-0">
                  <select name="Request_Status_new"
                          id="Request_Status_new"
                          [disabled]="!ProposedRescheduledata.isRequestStatus"
                          [(ngModel)]="ProposedRescheduledata.Request_Status_new"
                          class="form-control input-text-css">
                    <option value="">Select</option>
                    <option value="Authorized">Authorized</option>
                    <option value="Rejected">Rejected</option>
                  </select>
                </div>
              </div>
              <div *ngIf="
                  isMaker == 'A' &&
                  ProposedRescheduledata.Authorised_By != '' &&
                  ProposedRescheduledata.Authorised_By != null &&
                  ProposedRescheduledata.Authorised_On != '' &&
                  ProposedRescheduledata.Authorised_On != null
                "
                   class="row m-0 mt-3 align-items-center">
                <div class="col-md-2 p-0">Authorised By:</div>
                <div class="col-md-3 p-0">
                  {{ ProposedRescheduledata.Authorised_By }}
                </div>
                <div class="col-md-2 p-0">Authorised On:</div>
                <div class="col-md-3 p-0">
                  {{ ProposedRescheduledata.Authorised_On }}
                </div>
              </div>
              <div class="row m-0 mt-3 align-items-center"
                   *ngIf="isMaker == 'A'">
                <div class="col-md-2 p-0">Authorised Remark:</div>
                <div class="col-md-10 p-0">
                  <textarea [(ngModel)]="ProposedRescheduledata.Authorised_Remark"
                            [disabled]="!ProposedRescheduledata.isRequestStatus"
                            placeholder="Authorised Remark"
                            name="Authorised_Remark"
                            id="Authorised_Remark"
                            class="form-control input-text-css"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row col-md-12 m-0 mt-3" style="display: inline-block" *ngIf="errorShow == false && errorShowChequeinTransit == false">
          <button type="button"
                  (click)="onDeleteRequest()"
                  *ngIf="isMaker=='R' &&  ProposedRescheduledata.Rescheduled_Id>  0 &&
            ProposedRescheduledata.Request_Status == 'Created' &&  ProposedRescheduledata.Reschedule_By_Id == currentUser.userId "
                  class="ml-4 btn font-size-12 button-btn">
            Delete Request
          </button>
          <div style="float: right">
            <button type="button"
                    (click)="onCancel()"
                    class="ml-4 btn font-size-12 button-btn">
              Cancel
            </button>
            <button *ngIf="isMaker == 'R' && ProposedRescheduledata.isRequestStatus && OnCalculateActive"
                    type="button"
                    (click)="onSaveReschedule()"
                    class="ml-4 btn font-size-12 button-btn"
                    [disabled]="
                !(
                  ProposedRescheduledata.Request_Status == '' ||
                  ProposedRescheduledata.Request_Status == 'Created'
                )
              ">
              Reschedule
            </button>
            <button *ngIf="isMaker == 'A' && ProposedRescheduledata.isRequestStatus"
                    type="button"
                    (click)="onSubmitReschedule()"
                    class="ml-4 btn font-size-12 button-btn">
              Submit
            </button>
          </div>
        </div>
      </div>
      <div class="row m-0 p-0 col-md-12 mt-3 center"
           style="color: rgb(238, 51, 51); font-size: 15px"
           *ngIf="errorShow == true">
        This Loan is not eligible for restructure as balance is negative.
      </div>

      <div class="row m-0 p-0 col-md-12 mt-3 center"
           style="color: rgb(238, 51, 51); font-size: 15px"
           *ngIf="errorShowChequeinTransit == true">
        This Loan is not eligible for restructure as balance in Cheque in Transit.
      </div>
      <!-- <div class="row m-0 p-0 col-md-12 mt-3">

    <div class="col-md-2 mb-3">
      <span> Type of Re-Scheduling</span>
      <select name="ReSchedulingType" id="ReSchedulingType" #refReSchedulingType="ngModel"
        [ngClass]="{ 'is-invalid': eif.submitted && refReSchedulingType.invalid, 'alert-warning': refReSchedulingType.invalid  && (refReSchedulingType.dirty || refReSchedulingType.touched || refReSchedulingType.untouched) }"
        [(ngModel)]="cEIModel.Reschedule_Type" class="form-control input-text-css" required>
        <option value=""> Select Re-Scheduling Type </option>
        <option value="EMI Amount Change"> EMI Amount Change </option>
        <option value="No of Installment Change"> No of Installment Change </option>
        <option value="ROI Change"> ROI Change </option>
        <option value="Due Date Change"> Due Date Change </option>
      </select>
      <input type="text" style="display:none" numbersOnly #refAsset_Cost="ngModel" [(ngModel)]="cEIModel.Asset_Cost"
        disabled placeholder="Asset Cost" [ngClass]="{
        'is-invalid': eif.submitted && refAsset_Cost.invalid,
        'alert-warning':
          refAsset_Cost.invalid &&
          (refAsset_Cost.dirty ||
            refAsset_Cost.touched ||
            refAsset_Cost.untouched)
      }" name="Asset_Cost" id="Asset_Cost" class="form-control input-text-css" />
    </div>
    <div class="col-md-2 mb-3">
      <span> Outstanding Amount (Rs.) </span>
      <input type="text" numbersOnly required disabled #refNetFinance_Amt="ngModel"
        [(ngModel)]="cEIModel.NetFinance_Amt" placeholder="Net Finance" [ngClass]="{
        'is-invalid': eif.submitted && refNetFinance_Amt.invalid,
        'alert-warning':
          refNetFinance_Amt.invalid &&
          (refNetFinance_Amt.dirty ||
            refNetFinance_Amt.touched ||
            refNetFinance_Amt.untouched)
      }" name="NetFinance_Amt" id="NetFinance_Amt" class="form-control input-text-css" />
    </div>
    <div class="col-md-3 mb-3">
      <span class="form-check-inline">
        <label class="form-check-label" for="CalculateByFlat">
          ROI (%)
          <input type="radio" class="form-check-input" id="CalculateByFlat" name="IRR_CalculateBy"
            #refIRR_CalculateBy="ngModel" [ngClass]="{
            'is-invalid': eif.submitted && refIRR_CalculateBy.invalid,
            'alert-warning':
              refIRR_CalculateBy.invalid &&
              (refIRR_CalculateBy.dirty ||
                refIRR_CalculateBy.touched ||
                refIRR_CalculateBy.untouched)
          }" [(ngModel)]="cEIModel.IRR_CalculateBy" required value="ROI" (change)="handleByChange($event)" />
        </label>
      </span>
      <input type="text" placeholder="Flat Rate" name="Flat_Rate" id="Flat_Rate" class="form-control input-text-css"
        disabled *ngIf="cEIModel.IRR_CalculateBy !== 'ROI'" />
      <input type="text" numbersOnly="true" required #refFlat_Rate="ngModel" [(ngModel)]="cEIModel.Flat_Rate"
        placeholder="Flat Rate" *ngIf="cEIModel.IRR_CalculateBy == 'ROI'" [ngClass]="{
        'is-invalid': eif.submitted && refFlat_Rate.invalid,
        'alert-warning':
          refFlat_Rate.invalid &&
          (refFlat_Rate.dirty ||
            refFlat_Rate.touched ||
            refFlat_Rate.untouched)
      }" name="Flat_Rate" id="Flat_Rate" class="form-control input-text-css" />
    </div>
    <div class="col-md-3 mb-3">
      <span class="form-check-inline">
        <label class="form-check-label" for="CalculateByEMI">
          Flat EMI Amount (Rs.)
          <input type="radio" class="form-check-input" id="CalculateByEMI" name="IRR_CalculateBy"
            #refIRR_CalculateBy="ngModel" [ngClass]="{
            'is-invalid': eif.submitted && refIRR_CalculateBy.invalid,
            'alert-warning':
              refIRR_CalculateBy.invalid &&
              (refIRR_CalculateBy.dirty ||
                refIRR_CalculateBy.touched ||
                refIRR_CalculateBy.untouched)
          }" [(ngModel)]="cEIModel.IRR_CalculateBy" required value="FLAT_EMI" (change)="handleByChange($event)" />
        </label>
      </span>
      <input type="text" placeholder="EMI Amount" name="EMI_Amount" id="EMI_Amounts"
        class="form-control input-text-css" disabled *ngIf="cEIModel.IRR_CalculateBy !== 'FLAT_EMI'" />
      <input type="text" numbersOnly="true" required #refEMI_Amount="ngModel" [(ngModel)]="cEIModel.EMI_Amount"
        placeholder="EMI Amount" *ngIf="cEIModel.IRR_CalculateBy == 'FLAT_EMI'" [ngClass]="{
        'is-invalid': eif.submitted && refEMI_Amount.invalid,
        'alert-warning':
          refEMI_Amount.invalid &&
          (refEMI_Amount.dirty ||
            refEMI_Amount.touched ||
            refEMI_Amount.untouched)
      }" name="EMI_Amount" id="EMI_Amount" class="form-control input-text-css" />
    </div>
    <div class="col-md-2 mb-3">
      <span class="form-check-inline">
        <label class="form-check-label" for="CalculateByEMI">
          Step EMI
          <input type="radio" class="form-check-input" id="CalculateByEMI" name="IRR_CalculateBy"
            #refIRR_CalculateBy="ngModel" [ngClass]="{
            'is-invalid': eif.submitted && refIRR_CalculateBy.invalid,
            'alert-warning':
              refIRR_CalculateBy.invalid &&
              (refIRR_CalculateBy.dirty ||
                refIRR_CalculateBy.touched ||
                refIRR_CalculateBy.untouched)
          }" [(ngModel)]="cEIModel.IRR_CalculateBy" required value="STEP_EMI" (change)="handleByChange($event)" />
        </label>
      </span>
    </div>
  </div>
  <div class="row m-0 p-0 col-md-12">
    <div class="col-md-3 mb-3">
      <span> EMI Frequency</span>
      <select name="EMI_Type" id="EMI_Type" #refEMI_Type="ngModel" [ngClass]="{
        'is-invalid': eif.submitted && refEMI_Type.invalid,
        'alert-warning':
          refEMI_Type.invalid &&
          (refEMI_Type.dirty ||
            refEMI_Type.touched ||
            refEMI_Type.untouched)
      }" [(ngModel)]="cEIModel.EMI_Type" class="form-control input-text-css" (change)="setTenure()" required>
        <option value="Monthly">Monthly</option>
        <option value="ByMonthly">By Monthly</option>
        <option value="Quarterly">Quarterly</option>
        <option value="HalfYearly">Half Yearly</option>
        <option value="Yearly">Yearly</option>
        <option value="Bullet">Bullet</option>
      </select>
    </div>
    <div class="col-md-3 mb-3">
      <span> No Of Installments </span>
      <input type="text" numbersOnly required #refNo_Of_Inst="ngModel" [(ngModel)]="cEIModel.No_Of_Inst"
        placeholder="No Of Installments" (blur)="setTenure()" [ngClass]="{
        'is-invalid': eif.submitted && refNo_Of_Inst.invalid,
        'alert-warning':
          refNo_Of_Inst.invalid &&
          (refNo_Of_Inst.dirty ||
            refNo_Of_Inst.touched ||
            refNo_Of_Inst.untouched)
      }" name="No_Of_Inst" id="No_Of_Inst" class="form-control input-text-css" />
    </div>
    <div class="col-md-3 mb-3">
      <span> Tenure (Month) </span>
      <input type="text" numbersOnly required #refTenure="ngModel" [(ngModel)]="cEIModel.Tenure"
        placeholder="Tenure" [ngClass]="{
        'is-invalid': eif.submitted && refTenure.invalid,
        'alert-warning':
          refTenure.invalid &&
          (refTenure.dirty || refTenure.touched || refTenure.untouched)
      }" name="Tenure" id="Tenure" class="form-control input-text-css" />
    </div>
    <div class="col-md-3 mb-3">
      <span> Instl In Adv. </span>
      <input type="text" numbersOnly required #refAdv_Inst="ngModel" [(ngModel)]="cEIModel.Adv_Inst"
        placeholder="Instl In Adv." [ngClass]="{
        'is-invalid': eif.submitted && refAdv_Inst.invalid,
        'alert-warning':
          refAdv_Inst.invalid &&
          (refAdv_Inst.dirty ||
            refAdv_Inst.touched ||
            refAdv_Inst.untouched)
      }" name="Adv_Inst" id="Adv_Inst" class="form-control input-text-css" />
    </div>

    // <div class="col-md-12 mb-3" *ngIf="cEIModel.IRR_CalculateBy == 'STEP_EMI'">
    <div class="col-md-12 mb-3" *ngIf="stepEmiForm">
      <div class="row m-0 mt-3 justify-content-center">
        <div class="table-border">
          <div class="item-row header">
            <div class="lead w50 fs-14">#</div>
            <div class="lead w120 fs-14">From EMI</div>
            <div class="lead w120 fs-14">To EMI</div>
            <div class="lead fs-14">EMI Amount</div>
            <div class="lead w120 fs-14">No of EMI</div>
            <div class="lead w150 fs-14">Total Amount</div>
          </div>
          <div class="item-row" *ngFor="let semi of stepEMI; let i = index">
            <div class="w50">{{ i + 1 }}</div>
            <div class="w120">
              <input type="text" numbersOnly readonly [(ngModel)]="semi.FromEMI" placeholder="From EMI"
                name="FromEMI{{ i }}" id="FromEMI{{ i }}" class="form-control input-text-css" />
            </div>
            <div class="w120">
              <input type="text" numbersOnly [disabled]="semi.disable" [(ngModel)]="semi.ToEMI" placeholder="To EMI"
                name="ToEMI{{ i }}" id="ToEMI{{ i }}" class="form-control input-text-css" />
            </div>
            <div class="">
              <input type="text" numbersOnly [disabled]="semi.disable" [(ngModel)]="semi.EMI_Amount"
                placeholder="EMI Amount" name="EMI_Amount{{ i }}" id="EMI_Amount{{ i }}"
                class="form-control input-text-css mr-3 emi-amount" />
              <button type="button" *ngIf="!semi.disable" class="primary-btn" (click)="addNextStep(semi)">
                <i class="fa fa-check-circle" style="font-size: 18px"></i>
              </button>
              <button type="button" class="primary-btn" style="margin-left: 10px" *ngIf="!semi.disable && i > 0"
                (click)="removeLastStep(i)">
                <i class="fa fa-minus-circle" style="font-size: 18px"></i>
              </button>
            </div>
            <div class="w120">{{ semi.NoOfEMI }}&nbsp;</div>
            <div class="w150">{{ semi.TotalAmount }}&nbsp;</div>
          </div>
          <div class="item-row header" *ngIf="stepEmiData">
            <div class="lead w50 fs-14">&nbsp;</div>
            <div class="lead w120 fs-14"><b>Total</b></div>
            <div class="lead w120 fs-14">&nbsp;</div>
            <div class="lead fs-14">&nbsp;</div>
            <div class="lead w120 fs-14">
              <b>{{ stepEmiData.totalEmi }}</b>
            </div>
            <div class="lead w150 fs-14">
              <b>{{ stepEmiData.totalAmount }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row m-0 col-md-12 justify-content-center">
    <button type="button" (click)="onCalculateEmiAndIRR()" class="pl-5 pr-5"
      style="background-color: #28a745; color: #ffffff; font-size: 12px" mat-raised-button color="primary"
      [class.spinner]="loading">
      Calculate EMI and IRR
    </button>
  </div>
  <div class="row col-md-12 m-0 p-0 mt-3" id="scrollto" *ngIf="emiIrr">
    <div class="col-md-3">
      <h6 class="fs-12">Disbursement Amount</h6>
      <p class="lead fs-12">₹ {{ emiIrr.Disbursement_Amt }}</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Interest Amount</h6>
      <p class="lead fs-12">₹ {{ emiIrr.Interest_Amt }}</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Agreement Value</h6>
      <p class="lead fs-12">₹ {{ emiIrr.Agreement_Value }}</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">LTV</h6>
      <p class="lead fs-12">{{ emiIrr.LTV }} %</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Case IRR</h6>
      <p class="lead fs-12">{{ emiIrr.Case_IRR }} %</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Disbursement IRR</h6>
      <p class="lead fs-12">{{ emiIrr.Disbursement_IRR }} %</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Margin</h6>
      <p class="lead fs-12">₹ {{ emiIrr.Margin }}</p>
    </div>
    <div class="col-md-12">
      <h6 class="fs-12">Remarks</h6>
      <textarea [(ngModel)]="RestructureRemark" required placeholder="Remarks" name="RestructureRemark"
        id="RestructureRemark" class="form-control input-text-css"></textarea>
    </div>
  </div>
  <div class="row col-md-12 m-0 mt-3 justify-content-end">
    <button type="button" (click)="onCloseDialog()" class="btn btn-light font-size-12" data-dismiss="modal">
      Cancel
    </button>
    <button type="button" (click)="onSaveApplication()" class="ml-4"
      style="background-color: #28a745; color: #ffffff; font-size: 12px" mat-raised-button color="primary">
      Save
    </button>
  </div> -->
    </form>
  </ng-container>
</div>

<div
  class="modal fade in"
  id="ViewLoanStatementModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          View loan statement
        </h6>
        <button
          type="button"
          (click)="onCloseViewLoanStatement()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <app-lms-loanStatement
          [isShowHeader]="false"
          [loanSummary]="loanSummary"
        ></app-lms-loanStatement>
      </div>
    </div>
  </div>
</div>
