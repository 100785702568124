<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div
    class="col-md-12 formborder d-flex align-items-center justify-content-between"
  >
    <div class="col-md-4">
      <label>Status</label>
      <select
        name="FilterStatusId"
        id="FilterStatusId"
        class="form-control input-text-css"
        [(ngModel)]="status"
        (change)="LMS_Get_Loan_WriteOff_Settled_List()"
      >
        <option value="All">All</option>
        <option *ngFor="let item of StatusList" [value]="item.Id">
          {{ item.Value }}
        </option>
      </select>
    </div>
    <div class="col-md-4 text-right">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-4 text-right">
      <button
        type="button"
        class="btn font-size-12 button-btn"
        (click)="onNewWriteOff_Settled()"
      >
        New WriteOff/Settled
      </button>
    </div>
  </div>

  <div class="mt-3">
    <div class="">
      <div class="table-responsive" style="overflow: auto">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="CloserId">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
            >
              #
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ApplicationNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Application No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.ApplicationNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="LoanNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Loan No
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              <a
                (click)="goToSaveDetail(row)"
                aria-label="true"
                style="color: blue; cursor: pointer"
              >
                {{ row.LoanNo }}</a
              >
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Customer_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer_Name }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Branch">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Branch
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Product_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Product</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Product_Name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="EffectiveDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Effective Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell"
              >{{ row.EffectiveDate }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="RequestBy">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Created By
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.RequestBy }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="RequestStatus">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Status
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.RequestStatus }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="RequestOn">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >RequestOn/ CloseOn</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.RequestOn }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Waiver_GivenBy">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              style="max-width: 100px"
              >Settled/Sold Given By</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="max-width: 100px"
            >
              {{ row.Waiver_GivenBy }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="TotalWaiver_Amount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              style="max-width: 100px"
              >Settled/Sold Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell textset">
              {{ row.TotalWaiver_Amount }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Closer_Type">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Closer_Type }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Action">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <i
                class="fa fa-arrow-circle-right arrow-circle action-btn"
                (click)="goToSaveDetail(row)"
                aria-label="true"
              ></i>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-4">
            <button class="btn font-size-12 button-btn" (click)="exportTable()">
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8">
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[20, 50]"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="SearchLoanModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Search Loan For Closer
        </h6>
        <button
          type="button"
          (click)="onCloseSearchLoanModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #f="ngForm"
          (keydown.enter)="onSearchCustomerDetail()"
          novalidate
          class="row m-0 col-md-12 p-0 formborder"
        >
          <div class="row m-0 col-md-12 p-0">
            <!--<div class="col-md-3">
            <span>Type</span>
            <select name="Type" id="Type" class="form-control input-text-css" [(ngModel)]="Type">
              <option [value]="0">All</option>
              <option [value]="1">LOS Only</option>
              <option [value]="2">LMS Only</option>
            </select>
          </div>-->
            <div class="col-md-3">
              <span>Loan No.</span>
              <input
                name="SLoanNo"
                [(ngModel)]="Customer.LoanNo"
                id="SLoanNo"
                placeholder="Loan No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Application No.</span>
              <input
                name="SApplicationNo"
                [(ngModel)]="Customer.ApplicationNo"
                id="SApplicationNo"
                placeholder="Application No."
                class="form-control input-text-css"
              />
            </div>

            <div class="col-md-3">
              <span>Customer Name</span>
              <input
                name="CustomerName"
                [(ngModel)]="Customer.CustomerName"
                id="CustomerName"
                placeholder="Customer Name"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Customer Phone</span>
              <input
                name="CustomerPhone"
                [(ngModel)]="Customer.CustomerPhone"
                id="CustomerPhone"
                placeholder="Customer Phone"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>PAN Card</span>
              <input
                name="PANCard"
                [(ngModel)]="Customer.PANCard"
                id="PANCard"
                placeholder="PAN Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Passport No.</span>
              <input
                name="Passport"
                [(ngModel)]="Customer.Passport"
                id="Passport"
                placeholder="Passport No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Voter ID Card</span>
              <input
                name="VoterID"
                [(ngModel)]="Customer.VoterID"
                id="VoterID"
                placeholder="Voter ID Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Driving License</span>
              <input
                name="DL"
                [(ngModel)]="Customer.DL"
                id="DL"
                placeholder="Driving License"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Aadhaar Card</span>
              <input
                name="Aadhaar"
                [(ngModel)]="Customer.Aadhaar"
                id="Aadhaar"
                placeholder="Aadhaar Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-9 row m-0 justify-content-end align-items-end">
              <button
                type="button"
                (click)="onSearchCustomerDetail()"
                class="btn font-size-12 button-btn"
              >
                Search
              </button>
            </div>
          </div>
        </form>
        <div class="table-responsive mt-3">
          <mat-table
            [dataSource]="dataSourceCustomer"
            #sortCustomerList="matSort"
            matSort
            id="exportReport"
          >
            <ng-container matColumnDef="LoanId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 50px"
                *matHeaderCellDef
                >#
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 50px; max-height: 5px"
              >
                {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="LoanNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 150px"
                *matHeaderCellDef
                >Loan No
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 150px"
                >{{ row.LoanNo }}</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="ApplicationNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 150px"
                *matHeaderCellDef
              >
                Aplication No</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 150px"
                >{{ row.ApplicationNo }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Product">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Product</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Product
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Branch">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Branch</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Branch
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="CustomertName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer Name</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.CustomertName
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="FatherName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Father Name</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.FatherName
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="PhoneNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 150px"
                *matHeaderCellDef
                >Phone No
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 150px"
              >
                <div *ngIf="currentUser.IsMaskingMobile">
                  {{ row.PhoneNo | mask }}
                </div>

                <div *ngIf="!currentUser.IsMaskingMobile">
                  {{ row.PhoneNo }}
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Loan_Date">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 150px"
                *matHeaderCellDef
                >Loan Date
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 150px"
                >{{ row.Loan_Date }}</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="EditAction">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 100px"
                *matHeaderCellDef
                >View Detail</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell j-c-center"
                style="max-width: 100px"
              >
                <i
                  class="fa fa-arrow-circle-right btn_detail"
                  (click)="goToDetail(row)"
                  aria-label="true"
                ></i>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedCustomerColumns"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedCustomerColumns"
            ></mat-row>
          </mat-table>
          <mat-paginator
            #paginatorRef
            #paginatorCustomerList
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="loan_WriteOff_Settled"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Effective Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Created By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Status
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Request On
    </th>
  </tr>
  <tr *ngFor="let x of Data; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.ApplicationNo }}</td>
    <td>{{ x.LoanNo }}</td>

    <td>{{ x.Customer_Name }}</td>
    <td>{{ x.Branch }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.EffectiveDate }}</td>
    <td>{{ x.RequestBy }}</td>
    <td>{{ x.RequestStatus }}</td>
    <td>{{ x.RequestOn }}</td>
  </tr>
</table>
