import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { LosService } from "src/app/_LOS/services/los.service";
import { QuickLoanService } from "src/app/_QuickLoan/services/quickLoan.service";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { MobileService } from "src/app/Shared/app.Mobile.Service";
import { constantUrl } from "src/app/Shared/constantUrl";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { LmsService } from "../services/lms.service";

@Component({
  selector: "app-lms-personal-discussion",
  templateUrl: "./lms-personal-discussion.component.html",
  styleUrls: ["./lms-personal-discussion.component.scss"],
})
export class LmsPersonalDiscussionComponent implements OnInit {
  showSpinner: boolean = false;
  QuestionModel: any = {};
  loading: boolean = false;
  currentUser: any;
  @Input() Revert_IsEdit: any;
  questionData: any[] = [];
  questionOptionData: any[] = [];

  //Final Submit
  Recommendation: any = "";
  Remarks: any;
  AllAnswer: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  AllowExtension: any = constantUrl.AllowExtension;

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  @Input() loanSummary: any;
  @Input() Processid: any;
  @Input() PageData: any;
  @Input() isNew: boolean;
  @Input() Status: any;
  @Input() ProcessName: any;
  Nature_of_workId: any;
  CustomerId: any;
  customerData: any = {};
  Customer: any = {};
  ShowDiv: boolean = false;

  IsSave: boolean = false;
  IsUpdate: boolean = false;
  IsEdit: boolean = false;
  IsDisabled: boolean = false;
  PageAcess: any;

  constructor(
    private dataSharingService: DataSharingService,
    private _MobileService: MobileService,
    private LmsService: LmsService,
    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private router: Router,
    private _QuickLoanService: QuickLoanService,
    private _LosService: LosService,
    private route: ActivatedRoute
  ) {
    let AllPage = JSON.parse(sessionStorage.getItem("AllPageAcess"));
    this.PageAcess = AllPage.filter(
      (x) => x.Page_Name == "PD"
    );
  }

  ngOnInit(): void {
    this.dataSharingService.HeaderTitle.next(this.ProcessName);
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.Get_Customer_For_PD();
  }

  Get_Customer_For_PD() {
    this.showSpinner = true;
    this._MasterService
      .Get_Customer_For_PD({ LoanId: this.loanSummary.LoanId })
      .subscribe((res: any) => {
        console.log("LMS_GetLoanDetails", res);
        this.showSpinner = false;
        this.customerData = res;
        if (res[0]) {
          this.OnClickPDQuestions(res[0]);
        }
      });
  }
  OnClickPDQuestions(row) {
    this.ShowDiv = true;
    console.log("row", row);

    this.CustomerId = row.CustomerId;
    this.Nature_of_workId = row.Nature_of_work;

    this.GetLosCustomerPDQuestionAnswer(0);
  }

  GetLosCustomerPDQuestionAnswer(Edit) {
    //this.showSpinner = true;
    this._QuickLoanService
      .QuickLoan_Get_PD_Questions_List({
        Nature_Of_WorkId: this.Nature_of_workId,
        LoanId: this.loanSummary.LoanId,
        CustomerId: this.CustomerId,
        Edit: Edit,
      })
      .subscribe((res: any) => {
        console.log("RES :", res);
        this.showSpinner = false;
        this.questionData = res;
        this.AllAnswer = res.filter(
          (x) => x.PD_Answer != "" && x.PD_Answer != null
        );
        console.log("this.AllAnswer", this.AllAnswer);
        if (this.AllAnswer.length > 0 && Edit == 0) {
          this.IsEdit = true;
          this.IsSave = false;
          this.IsDisabled = true;
        } else {
          if (Edit == 1) {
            this.IsEdit = false;
            this.IsSave = false;
            this.IsDisabled = false;
          } else {
            this.IsEdit = false;
            this.IsSave = true;
            this.IsDisabled = false;
          }
        }

        this.questionData.forEach((obj: any) => {
          if (obj.QuestionType == "Multi-Selection" && obj.PD_Answer != null) {
            obj.PD_Answer = obj.PD_Answer.split(",");
          }
        });
        this.questionData.forEach((obj: any) => {
          if (
            obj.QuestionType == "Selection" ||
            obj.QuestionType == "Multi-Selection"
          ) {
            obj.questionOptionData = obj.QuestionOptions.split("@@");
          }
        });
      });
  }

  fileChangeListener(data: any, files: any) {
    /*console.log("this.questionData[ this.questionData.length - 1].title", this.questionData[ this.questionData.length - 1].title);
    
    if (!this.questionData[ this.questionData.length - 1].title ) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please enter title.", ...this.configSuccess});
      files.value = "";
      return;
    }*/
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.PD_Answer = "";
      delete data.DocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.PD_Answer = "";
      delete data.DocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.PD_Answer = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }

  removeFile(data: any, input: any) {
    input.value = "";
    data.PD_Answer = "";
    delete data.DocData;
  }

  onSaveCustomerPDAnswer() {
    this.loading = true;
    var PD_Answer;
    var arr = [];
    console.log("this.questionData : ", this.questionData);
    this.questionData.forEach((obj: any) => {
      if (obj.QuestionType == "Multi-Selection") {
        arr.push({
          ApplicationId: this.loanSummary.LoanId,
          CustomerId: this.CustomerId,
          QuestionId: obj.Ques_Id,
          Answer: obj.PD_Answer.join(","),
          LoginUserId: this.currentUser.userId,
        });
      } else if (obj.QuestionType == "Upload") {
        arr.push({
          ApplicationId: this.loanSummary.LoanId,
          CustomerId: this.CustomerId,
          QuestionId: obj.Ques_Id,
          Answer: obj.PD_Answer,
          DocData: obj.DocData,
          LoginUserId: this.currentUser.userId,
        });

        this.uploadDoc({
          ApplicationNo: this.loanSummary.ApplicationNo,
          DocName: obj.PD_Answer,
          DocData: obj.DocData,
        });
      } else {
        arr.push({
          ApplicationId: this.loanSummary.LoanId,
          CustomerId: this.CustomerId,
          QuestionId: obj.Ques_Id,
          Answer: obj.PD_Answer,
          LoginUserId: this.currentUser.userId,
        });
      }
    });
    this.QuestionModel = arr;
    this.Customer.ApplicationId = this.loanSummary.LoanId;
    this.Customer.CustomerId = this.CustomerId;
    this.Customer.LoginUserId = this.currentUser.userId;

    PD_Answer = { Answer: this.QuestionModel, Customer: this.Customer };

    console.log("this.Answer : ", PD_Answer);
    this._QuickLoanService
      .QuickLoan_Save_Customer_PDAnswer({ JSON: JSON.stringify(PD_Answer) })
      .subscribe((response: any) => {
        //console.log(response);
        if (response[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.GetLosCustomerPDQuestionAnswer(0);
          this.QuickLoan_Save_PageProcess("PD Questions");
          this.action.emit("next");
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
        this.loading = false;
      });
  }

  uploadDoc(data: any) {
    this._LosService.UploadCrcDoc(data).subscribe((res: any) => {});
  }

  onEditCustomerPDAnswer() {
    this.GetLosCustomerPDQuestionAnswer(1);
    this.IsEdit = false;
    this.IsUpdate = true;
    this.IsSave = false;
    this.IsDisabled = false;
  }

  CheckCustomerPDQuestions() {
    this.action.emit("next");
    /*if (this.AllAnswer && this.AllAnswer.length > 0) {
      this.action.emit("next");
    }
    else {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Add FI Questions.", ...this.configSuccess });
    }*/
  }

  QuickLoan_Save_PageProcess(PageType) {
    this._QuickLoanService
      .QuickLoan_Save_PageProcess({
        PageType: PageType,
        ProductId: this.loanSummary.ProductId,
        Loan_Id: this.loanSummary.LoanId,
        Process_Id: this.Processid,
        Page_Id: this.PageData.MM_Id,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((res) => {
        //return res[0].CODE;
      });
  }

  onNext() {
    this.LmsService.SaveNext_Loan({
      Loan_Id: this.loanSummary.LoanId,
      CommandName: "Personal Discussion",
      isStatus: 1,
    }).subscribe((response) => {
      this.action.emit("next");
    });
  }
}
