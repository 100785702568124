<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<form
  #f="ngForm"
  (keydown.enter)="QuickLoanTATReportList()"
  novalidate
  class="row m-0 col-md-12 p-0 formborder"
>
  <div class="row m-0 col-md-12 p-0">
    <div class="col-md-3">
      <span>Application No.</span>
      <input
        name="ApplicationNo"
        [(ngModel)]="Customer.ApplicationNo"
        id="ApplicationNo"
        placeholder="Application No."
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-3">
      <span>Customer Name</span>
      <input
        name="CustomerName"
        [(ngModel)]="Customer.CustomerName"
        id="CustomerName"
        placeholder="Customer Name"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-3">
      <span>Customer Phone</span>
      <input
        name="CustomerPhone"
        [(ngModel)]="Customer.CustomerPhone"
        id="CustomerPhone"
        placeholder="Customer Phone"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-3">
      <span>PAN Card</span>
      <input
        name="PANCard"
        [(ngModel)]="Customer.PANCard"
        id="PANCard"
        placeholder="PAN Card"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-3">
      <span>Passport No.</span>
      <input
        name="Passport"
        [(ngModel)]="Customer.Passport"
        id="Passport"
        placeholder="Passport No."
        class="form-control input-text-css"
      />
    </div>

    <div class="col-md-3">
      <span>Voter ID Card</span>
      <input
        name="VoterID"
        [(ngModel)]="Customer.VoterID"
        id="VoterID"
        placeholder="Voter ID Card"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-3">
      <span>Driving License</span>
      <input
        name="DL"
        [(ngModel)]="Customer.DL"
        id="DL"
        placeholder="Driving License"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-3">
      <span>Aadhaar Card</span>
      <input
        name="Aadhaar"
        [(ngModel)]="Customer.Aadhaar"
        id="Aadhaar"
        placeholder="Aadhaar Card"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-3">
      <span>Vehicle No</span>
      <input
        name="VehicleNo"
        [(ngModel)]="Customer.VehicleNo"
        id="VehicleNo"
        placeholder="Vehicle No"
        class="form-control input-text-css"
      />
    </div>
    <div class="col-md-3">
      <span>Status</span>
      <select
        name="Status"
        id="Status"
        class="form-control input-text-css"
        [(ngModel)]="Customer.Status"
      >
        <option value="Pending">Pending</option>
        <option value="Completed">Completed</option>
      </select>
    </div>

    <div class="col-md-3">
      <span> Source Type </span>
      <select
        name="SelectSourceType"
        id="SelectSourceType"
        #refSelectSourceType="ngModel"
        [ngClass]="{
          'is-invalid': f.submitted && refSelectSourceType.invalid,
          'alert-warning':
            refSelectSourceType.invalid &&
            (refSelectSourceType.dirty ||
              refSelectSourceType.touched ||
              refSelectSourceType.untouched)
        }"
        (change)="Get_PartnerForDropdown()"
        [(ngModel)]="SelectSourceType"
        class="form-control input-text-css"
      >
        <option value="">Select Source Type</option>
        <option
          *ngFor="let lead of leadSourceDropdown"
          [value]="lead.Lead_Source"
        >
          {{ lead.Lead_Source }}
        </option>
      </select>
    </div>

    <div class="col-md-3">
      <div
        *ngIf="
          SelectSourceType == 'Agent' ||
          SelectSourceType == 'DSA' ||
          SelectSourceType == 'Dealer' ||
          SelectSourceType == 'Sales Executive'
        "
      >
        <span> Source Name</span>
        <select
          name="LeadSourceName"
          id="LeadSourceName"
          #refLeadSourceName="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && refLeadSourceName.invalid,
            'alert-warning':
              refLeadSourceName.invalid &&
              (refLeadSourceName.dirty ||
                refLeadSourceName.touched ||
                refLeadSourceName.untouched)
          }"
          [(ngModel)]="LeadSourceName"
          class="form-control input-text-css"
        >
          <option value="">Select Lead Reference</option>
          <option
            *ngFor="let partner of partnerDropdown"
            [value]="partner.PartnerId"
          >
            {{ partner.Partner_Name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-md-4 mt-2">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-md-8">
      <button
        type="button"
        (click)="QuickLoanTATReportList()"
        class="mt-3 btn font-size-12 button-btn float-right"
      >
        Search
      </button>
    </div>
  </div>
</form>

<div class="mt-3" [hidden]="!dataSource">
  <div class="">
    <div class="table-responsive">
      <mat-table
        [dataSource]="dataSource"
        matTableExporter
        #exporter="matTableExporter"
        style="max-width: 100%; overflow: auto; height: 60vh"
      >
        <ng-container matColumnDef="ApplicationId">
          <mat-header-cell
            class="grid-header"
            style="max-width: 50px"
            *matHeaderCellDef
            >#</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row; let i = index"
            class="grid-cell"
            style="max-width: 50px"
          >
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_Status">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Application Status</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Application_Status }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Application_LoanAmount">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Loan Amount</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Application_LoanAmount }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Branch_Name">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Branch</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Branch_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_Date">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Application Date</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Application_Date }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Product_Name">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Loan Product</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Product_Name }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Application_No">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Application No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            <!-- style="cursor: pointer; text-decoration: underline" -->
            <a
              (click)="GoToTATReportDetail(row)"
              aria-label="true"
              style="color: blue; cursor: pointer"
              >{{ row.Application_No }}</a
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="AC_CustomerType">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Customer Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{
              row.AC_CustomerType == "H"
                ? "Borrower"
                : row.AC_CustomerType == "C"
                ? "Co-Borrower"
                : "Guaranter"
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="CustomerName">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Customer</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.CustomerName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="RelationName">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Relation Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.RelationName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Customer_Gender">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Gender</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{
              row.Customer_Gender == "M"
                ? "Male"
                : row.Customer_Gender == "F"
                ? "Female"
                : row.Customer_Gender
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="GenderAge">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Age</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.GenderAge }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Status">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Status</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.Status }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="PhoneNo">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Phone No</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            <div *ngIf="currentUser.IsMaskingMobile">
              {{ row.PhoneNo | mask }}
            </div>

            <div *ngIf="!currentUser.IsMaskingMobile">
              {{ row.PhoneNo }}
            </div>

            <i
              *ngIf="row.Customer_PhoneNo_IsVerified == true"
              class="fa fa-check-circle ml-2"
              style="color: green"
            ></i>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="SourceType">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Source Type</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.SourceType }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="SourceName">
          <mat-header-cell class="grid-header" *matHeaderCellDef
            >Source Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row" class="grid-cell">
            {{ row.SourceName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell
            class="grid-header"
            style="max-width: 60px"
            *matHeaderCellDef
            >Action</mat-header-cell
          >
          <mat-cell
            *matCellDef="let row"
            class="grid-cell j-c-center"
            style="max-width: 60px"
          >
            <i
              class="fa fa-arrow-circle-right arrow-circle action-btn"
              (click)="GoToTATReportDetail(row)"
              aria-label="true"
            ></i>
          </mat-cell>
        </ng-container>

        <mat-header-row
          class="sticky-footer"
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <div class="row m-0 align-items-center">
        <div class="col-md-4">
          <button
            class="mt-3 btn font-size-12 button-btn"
            (click)="exportTable()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-8">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="ViewTATReportModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div
        cdkDragHandle
        class="modal-header card shadow"
        style="
          padding: 10px;
          flex-direction: row;
          border: 1px solid rgb(15, 138, 84);
        "
      >
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          View Timeline
        </h6>
        <button
          type="button"
          (click)="onCloseTATReportModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <table class="formborder">
          <tbody>
            <tr>
              <td>
                <div><strong>Application No:</strong></div>
                {{ Application_No }}
              </td>
              <td>
                <div><strong>Customer:</strong></div>
                {{ CustomerInfo }}
              </td>
              <td>
                <div><strong>Product:</strong></div>
                {{ Product }}
              </td>
              <td>
                <div><strong>Branch:</strong></div>
                {{ Branch }}
              </td>
              <td>
                <div><strong>Loan Amount:</strong></div>
                ₹ {{ LoanAmount }}
              </td>
              <td>
                <div><strong>Loan Duration(Month):</strong></div>
                {{ LoanDuration_Month }}
                {{ LoanDuration_Month != "" ? "Months" : "" }}
              </td>
              <td>
                <div><strong>Created On:</strong></div>
                {{ ApplicationCreatedOn }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="table-responsive mt-3">
          <mat-table
            [dataSource]="dataSourceDetail"
            matSort
            style="max-width: 100%; overflow: auto"
            matTableExporter
            #exporter1="matTableExporter"
          >
            <ng-container matColumnDef="ProcessId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 50px"
                *matHeaderCellDef
              >
                #
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 50px"
              >
                {{ i + 1 }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Process">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Process
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Process }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ProcessExecutive">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Executive
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.ProcessExecutive }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ProcessStatus">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Status
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.ProcessStatus }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="AssignedOn">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Assign On
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.AssignedOn }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="CompletedOn">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Completed On
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.CompletedOn }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="TAT_Hr">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                TAT (Hrs)
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.TAT_Hr }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Score">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Score
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Score }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Remark">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Remark
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Remark }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="LatLong">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                LatLong
              </mat-header-cell>
              <mat-cell *matCellDef="let row" class="grid-cell">
                <a
                  target="_blank"
                  *ngIf="
                    row.LatLong != '' &&
                    row.LatLong != null &&
                    row.LatLong != ','
                  "
                  [href]="'http://maps.google.com/maps?q=' + row.LatLong"
                >
                  <i class="fa-solid fa-location-dot"></i>
                </a>
              </mat-cell>
            </ng-container>
            <mat-header-row
              class="sticky-footer"
              *matHeaderRowDef="displayedDetailColumns; sticky: true"
            ></mat-header-row>
            <mat-row
              [ngClass]="
                !!row.AssignedOn && !!!row.CompletedOn ? 'Pending' : ''
              "
              [ngClass]="{
                'pending-row':
                  row.ProcessStatus === 'Pending' ||
                  row.ProcessStatus === 'Reverted'
              }"
              *matRowDef="let row; columns: displayedDetailColumns"
            ></mat-row>
          </mat-table>
          <div class="row m-0 align-items-center text-right">
            <div class="col-md-12">
              <button
                class="mt-3 btn font-size-12 button-btn"
                (click)="exportTableTat()"
              >
                <i
                  class="fa fa-file-export mr-1"
                  style="font-size: medium; cursor: pointer"
                ></i
                >Export
              </button>
            </div>
          </div>
          <!-- <mat-paginator #paginatorRef [pageSizeOptions]="[50]" showFirstLastButtons> </mat-paginator> -->
        </div>
        <hr />

        <table style="text-align: center">
          <tbody>
            <tr>
              <td class="time-content">
                <div>
                  <strong
                    >Process Completion Time Taken:
                    {{ ProcessComplition }}</strong
                  >
                </div>
              </td>
              <td class="time-content">
                <div>
                  <strong>Pending Since: {{ PendingSince }}</strong>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<table
  id="tat_report1"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application Status
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Relation Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Gender
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">Age</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Phone No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Status
    </th>
  </tr>

  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.Application_LoanAmount }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Application_Date }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.Application_Status }}</td>
    <td>
      {{
        x.AC_CustomerType == "H"
          ? "Borrower"
          : x.AC_CustomerType == "C"
          ? "Co-Borrower"
          : "Guaranter"
      }}
    </td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.RelationName }}</td>
    <td>
      {{
        x.Customer_Gender == "M"
          ? "Male"
          : x.Customer_Gender == "F"
          ? "Female"
          : x.Customer_Gender
      }}
    </td>
    <td>{{ x.GenderAge }}</td>
    <td>
      <div *ngIf="currentUser.IsMaskingMobile">
        {{ x.PhoneNo | mask }}
      </div>

      <div *ngIf="!currentUser.IsMaskingMobile">
        {{ x.PhoneNo }}
      </div>
    </td>
    <td>{{ x.Status }}</td>
  </tr>
</table>

<table
  id="tat_report2"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Process
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Status
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Assign On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Completed On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      TAT(Hrs)
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Score
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Remarks
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Process }}</td>
    <td>{{ x.ProcessExecutive }}</td>
    <td>{{ x.ProcessStatus }}</td>
    <td>{{ x.AssignedOn }}</td>
    <td>{{ x.CompletedOn }}</td>
    <!-- <td>{{ x.Application_Status }}</td> -->
    <td>{{ x.TAT_Hr }}</td>
    <td>{{ x.Score }}</td>
    <td>{{ x.Remark }}</td>
  </tr>
</table>
