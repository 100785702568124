<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="onSearchReport()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <span class="required-lable"> From Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="FromDate"
            id="FromDate"
            required
            #refFromDate="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="FromDate"
            [ngClass]="{
              'is-invalid': df.submitted && refFromDate.invalid,
              'alert-warning':
                refFromDate.invalid &&
                (refFromDate.dirty ||
                  refFromDate.touched ||
                  refFromDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="FromDate"
          [(ngModel)]="FromDate"
          id="FromDate"
          placeholder="From Date"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refFromDate="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refFromDate.invalid,
            'alert-warning':
              refFromDate.invalid &&
              (refFromDate.dirty ||
                refFromDate.touched ||
                refFromDate.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-2">
        <span class="required-lable"> To Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            id="ToDate"
            required
            #refToDate="ngModel"
            class="form-control input-text-css"
            [min]="FromDate"
            [(ngModel)]="ToDate"
            [ngClass]="{
              'is-invalid': df.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="ToDate"
          [(ngModel)]="ToDate"
          id="ToDate"
          placeholder="To Date"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refToDate="ngModel"
          [minDate]="FromDate"
          [ngClass]="{
            'is-invalid': df.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }"
        /> -->
      </div>

      <div class="col-md-2">
        <span class="required-lable"> Group By </span>
        <select
          name="Search"
          id="SearchBy"
          [(ngModel)]="SearchBy"
          (change)="SearchByChange()"
          class="form-control input-text-css"
          #refSearchBy="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refSearchBy.invalid,
            'alert-warning':
              refSearchBy.invalid &&
              (refSearchBy.dirty ||
                refSearchBy.touched ||
                refSearchBy.untouched)
          }"
        >
          <option value="0" selected>Select Type</option>
          <option value="Branch">Branch</option>
          <option value="Product">Product</option>
          <option value="CollectionExecutive">Collection Executive</option>
        </select>
      </div>

      <div class="col-md-2">
        <span class="required-lable">Report For</span>
        <select
          name="Search"
          id="SearchBy"
          [(ngModel)]="ReportFor"
          class="form-control input-text-css"
          #refReportFor="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refReportFor.invalid,
            'alert-warning':
              refReportFor.invalid &&
              (refReportFor.dirty ||
                refReportFor.touched ||
                refReportFor.untouched)
          }"
        >
          <option value="0" selected>OnlyDue</option>
          <option value="All">All</option>
          <!-- <option value="OnlyDue">Only Due</option> -->
        </select>
      </div>

      <div class="col-md-2" *ngIf="Div_Branch">
        <span> Branch</span>
        <select
          name="BranchId"
          id="BranchId"
          #refBranchId="ngModel"
          [(ngModel)]="BranchId"
          class="form-control input-text-css"
          required
          [ngClass]="{
            'is-invalid': df.submitted && refBranchId.invalid,
            'alert-warning':
              refBranchId.invalid &&
              (refBranchId.dirty ||
                refBranchId.touched ||
                refBranchId.untouched)
          }"
        >
          >
          <option value="0" disabled selected>Select Branch</option>
          <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">
            {{ branch.Branch_Name }}
          </option>
        </select>
      </div>

      <div class="col-md-2" *ngIf="Div_Product">
        <span> Product</span>
        <select
          name="ProductId"
          id="ProductId"
          #refProductId="ngModel"
          [(ngModel)]="ProductId"
          class="form-control input-text-css"
          required
          [ngClass]="{
            'is-invalid': df.submitted && refProductId.invalid,
            'alert-warning':
              refProductId.invalid &&
              (refProductId.dirty ||
                refProductId.touched ||
                refProductId.untouched)
          }"
        >
          >
          <option value="0" disabled>Select Product</option>
          <option
            *ngFor="let product of ProductList"
            [value]="product.ProductId"
          >
            {{ product.Product }}
          </option>
        </select>
      </div>
      <div class="col-md-2" *ngIf="Div_CollectionExecutive">
        <span>Collection Executive</span>
        <select
          name="CollectionEx"
          id="CollectionEx"
          #refCollectionEx="ngModel"
          [(ngModel)]="CollectionEx"
          class="form-control input-text-css"
          required
          [ngClass]="{
            'is-invalid': df.submitted && refCollectionEx.invalid,
            'alert-warning':
              refCollectionEx.invalid &&
              (refCollectionEx.dirty ||
                refCollectionEx.touched ||
                refCollectionEx.untouched)
          }"
        >
          >>
          <option value="0" selected disabled>
            Select Collection Executive
          </option>
          <option *ngFor="let Coll of CollectionExList" [value]="Coll.EmpId">
            {{ Coll.EmpName }}
          </option>
        </select>
      </div>

      <div class="col-md-2">
        <span> Source Type </span>
        <select
          name="SelectSourceType"
          id="SelectSourceType"
          #refSelectSourceType="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refSelectSourceType.invalid,
            'alert-warning':
              refSelectSourceType.invalid &&
              (refSelectSourceType.dirty ||
                refSelectSourceType.touched ||
                refSelectSourceType.untouched)
          }"
          (change)="Get_PartnerForDropdown()"
          [(ngModel)]="SelectSourceType"
          class="form-control input-text-css"
        >
          <option value="">Select Source Type</option>
          <option
            *ngFor="let lead of leadSourceDropdown"
            [value]="lead.Lead_Source"
          >
            {{ lead.Lead_Source }}
          </option>
        </select>
      </div>

      <ng-container
        *ngIf="
          SelectSourceType == 'Agent' ||
          SelectSourceType == 'DSA' ||
          SelectSourceType == 'Dealer' ||
          SelectSourceType == 'Sales Executive'
        "
      >
        <div class="col-md-3">
          <span> Source Name</span>
          <select
            name="LeadSourceName"
            id="LeadSourceName"
            #refLeadSourceName="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refLeadSourceName.invalid,
              'alert-warning':
                refLeadSourceName.invalid &&
                (refLeadSourceName.dirty ||
                  refLeadSourceName.touched ||
                  refLeadSourceName.untouched)
            }"
            [(ngModel)]="LeadSourceName"
            class="form-control input-text-css"
          >
            <option value="">Select Lead Reference</option>
            <option
              *ngFor="let partner of partnerDropdown"
              [value]="partner.PartnerId"
            >
              {{ partner.Partner_Name }}
            </option>
          </select>
        </div>
      </ng-container>

      <div class="col-md-2">
        <button
          type="button"
          (click)="onSearchReport()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>

      <div
        class="float-end col-md-4 mt-2"
        *ngIf="dataSourceTree != null && dataSourceTree.data.length > 0"
      >
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <div class="mt-3" *ngIf="dataSourceTree != null">
    <div class="">
      <div
        class="table-responsive"
        *ngIf="dataSourceTree != null && dataSourceTree.data.length > 0"
      >
        <div class="table-responsive table-striped">
          <mat-table
            [dataSource]="dataSourceTree"
            matSort
            matTableExporter
            #exporter="matTableExporter"
            style="overflow: auto; height: 65vh; max-width: 100%"
          >
            <ng-container matColumnDef="Id">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                style="max-width: 50px"
                >#</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell mw50"
              >
                {{ i + 1 }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                style="max-width: 57px"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Application_No">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Application No</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Application_No }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="text-left grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Loan_Date">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Loan Date</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Loan_Date }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="text-left grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Branch_Name">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Branch Name</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Branch_Name }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="text-left grid-header"
              ></mat-footer-cell>
            </ng-container>
            <ng-container matColumnDef="Product_Name">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Product Name</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Product_Name }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="text-left grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="LoanTenure">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Loan Tenure</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-center">
                {{ row.LoanTenure }}
              </mat-cell>
              <mat-footer-cell
                *matFooterCellDef
                class="text-left grid-header"
              ></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="LoanAcNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw200 text-right"
                *matHeaderCellDef
                >Loan Ac No</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell mw200 text-right"
                >{{ row.LoanAcNo }}</mat-cell
              >
              <mat-footer-cell *matFooterCellDef class="grid-header">
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="CustomerName">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw200 text-right"
                *matHeaderCellDef
                >Customer</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell mw200">{{
                row.CustomerName
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                Total:
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="LoanAmount">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw200 text-right"
                *matHeaderCellDef
                >Loan Amount</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell mw200 text-right"
                >{{ row.LoanAmount }}</mat-cell
              >
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{ totaltypewise("LoanAmount") }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="SalesExecutive">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw200 text-right"
                *matHeaderCellDef
                >Sales Executive</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell mw200">{{
                row.SalesExecutive
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="CollectionExecutive">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw200 text-right"
                *matHeaderCellDef
                >Collection Executive</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell mw200">{{
                row.CollectionExecutive
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Period">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw200 text-right"
                *matHeaderCellDef
                >Period</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell mw200 text-right"
                >{{ row.Period }}</mat-cell
              >
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="DueDate">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw200 text-right"
                *matHeaderCellDef
                >Due Date</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell mw200 text-right"
                >{{ row.DueDate | date : "dd-MM-YYYY" }}</mat-cell
              >
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="EMI_Amount">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw200 text-right"
                *matHeaderCellDef
                >EMI Amount</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell mw200 text-right"
                >{{ row.EMI_Amount }}</mat-cell
              >
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{ totaltypewise("EMI_Amount") }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="OD_Amount">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw200 text-right"
                *matHeaderCellDef
                >OD Amount</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell mw200 text-right"
                >{{ row.OD_Amount }}</mat-cell
              >
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
                {{ totaltypewise("OD_Amount") }}
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="PDC_Type">
              <mat-header-cell
                mat-sort-header
                class="grid-header mw200 text-right"
                *matHeaderCellDef
                >PDC Type</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell mw200 text-right"
                >{{ row.PDC_Type }}</mat-cell
              >
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Customer_PhoneNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header text-right"
                *matHeaderCellDef
                >Mobile Number</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">
                <div *ngIf="currentUser.IsMaskingMobile">
                  {{ row.Customer_PhoneNo | mask }}
                </div>

                <div *ngIf="!currentUser.IsMaskingMobile">
                  {{ row.Customer_PhoneNo }}
                </div>
              </mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="SourceType">
              <mat-header-cell
                mat-sort-header
                class="grid-header text-right"
                *matHeaderCellDef
                >Source Type</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.SourceType
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="SourceName">
              <mat-header-cell
                mat-sort-header
                class="grid-header text-center"
                *matHeaderCellDef
                >Source Name</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
                row.SourceName
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
              </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="Address">
              <mat-header-cell
                mat-sort-header
                class="grid-header text-right"
                *matHeaderCellDef
                >Address</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Address
              }}</mat-cell>
              <mat-footer-cell *matFooterCellDef class="text-right grid-header">
              </mat-footer-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
              class="sticky-footer fontcolor"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              [ngClass]="{ 'highlight-row': selectedRow === row }"
              (click)="selectRow(row)"
            ></mat-row>
            <mat-footer-row
              class="sticky-footer fontcolor"
              *matFooterRowDef="displayedColumns"
            ></mat-footer-row>
          </mat-table>
        </div>
      </div>
      <div class="row m-0 ml-3" [hidden]="!dataSourceTree">
        <div class="col-md-4 mt-3">
          <button
            class="mt-2 btn font-size-12 button-btn"
            (click)="exportexcelNew()"
          >
            <i
              class="fa fa-file-export mr-1"
              style="font-size: medium; cursor: pointer"
            ></i>
            Export
          </button>
        </div>
        <div class="col-md-8 mt-2">
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="future_due_report"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Acc No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Sales Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Period
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Due Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      OD Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      PDC Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Mobile Number
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Address
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.SalesExecutive }}</td>
    <td>{{ x.CollectionExecutive }}</td>

    <td>{{ x.LoanAmount }}</td>
    <td>{{ x.Period }}</td>
    <td>{{ x.DueDate | date : "dd-MM-YYYY" }}</td>
    <td>{{ x.EMI_Amount }}</td>
    <td>{{ x.OD_Amount }}</td>
    <td>{{ x.PDC_Type }}</td>
    <td>{{ x.SourceType }}</td>
    <td>{{ x.SourceName }}</td>
    <td>
      <div *ngIf="currentUser.IsMaskingMobile">
        {{ x.Customer_PhoneNo | mask }}
      </div>

      <div *ngIf="!currentUser.IsMaskingMobile">
        {{ x.Customer_PhoneNo }}
      </div>
    </td>
    <td style="text-align: left">{{ x.Address }}</td>
  </tr>

  <tfoot>
    <tr>
      <td
        colspan="9"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("LoanAmount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("EMI_Amount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ totaltypewise("OD_Amount") }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
    </tr>
  </tfoot>
</table>
