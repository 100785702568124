import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { Location } from "@angular/common";
import { userlayoutService } from "../../_LOS/_layout/Services/app.userlayout.Service";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { catchError, tap, filter } from "rxjs/operators";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { DialogService } from "../../Shared/dialog.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import { LmsService } from "../services/lms.service";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { LmsLoanInfoComponent } from "../loan-info/loan-info.component";
import { MatTableDataSource } from "@angular/material/table";
declare var $: any;

@Component({
  selector: "app-lms-app-layout",
  templateUrl: "./lms-app-layout.component.html",
  styleUrls: ["./lms-app-layout.component.scss"],
  // encapsulation: ViewEncapsulation.None,
  // providers: [DatePipe],
  // animations: [
  //   trigger('slide', [
  //     state('up', style({ height: 0 })),
  //     state('down', style({ height: '*' })),
  //     transition('up <=> down', animate(400))
  //   ])
  // ]
})
export class LmsAppLayoutComponent implements OnInit {
  displayedColumnsNotes: string[] = [
    "Id",
    "Type",
    "Title",
    "Remarks",
    "Executive",
    "CreatedOn",
    "Date",
    "FollowUpBy",
    "Status",
  ];
  dataSourceNotes: any;
  isNew: boolean = false;
  currentUser: any;
  LoanId: any;
  Type: any;
  loanSummary: any = {};
  isGetDetail: boolean = false;
  currentTab: number = 0;
  lastFillTab: number = 0;
  Tenure_Name: any;

  PageAcess: any;
  AllPage: any;
  FICount: any;
  IncomeCount: any;
  BankAnalysCount: any;
  FOIRCount: any;
  FamilyCount: any;
  ChequeCount: any;
  insuranceCount: any;
  AttachmentCount: any;
  CRCCount: any;
  TVRCount: any;
  FileCheckingCount: any;
  SanctionConditionCount: any;
  PDCount: any;
  CustomerCount: any;
  AccountCount: any;
  AssetsCount: any;
  AllPageId: any;
  constructor(
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private MasterService: MasterService,
    private _Route: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    private http: HttpClient,
    private _userlayoutservice: userlayoutService,
    private EncrDecr: EncrDecrService,
    private _location: Location,
    private dataSharingService: DataSharingService,
    private dialog: DialogService,
    private LmsService: LmsService
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.LoanId = decodeURIComponent(param.get("id"));
      this.Type = decodeURIComponent(param.get("Type"));
      this.isNew = param.keys.length == 0 || this.Type.toLowerCase() == "a";
      this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
      if (this.isNew) {
        this.isGetDetail = true;
      } else {
        this.AllPageId = JSON.parse(sessionStorage.getItem("AllPageAcess"));
        console.log("layout page", this.AllPageId);
        this.getLoanInfo();
      }
    });

    // this.dataSharingService.getLmsHeaderLoanNo_Or_AppNo().subscribe((No) => {
    //   this.LoanAcNo = No;
    // });
    // this.dataSharingService.getHeaderTitle().subscribe(value => {
    //   this.HeaderTitle = value;
    // });
    // this.LMS_GetPageSubmitted();
    // this.dataSharingService.getLmsHeaderType().subscribe(value => {
    //   if (value) {
    //     this.Type = value;

    //     this.dataSharingService.getLmsHeaderApplicationId().subscribe(value => {
    //       if (value) {
    //         this.ApplicationId = value;
    //       }
    //     });
    //     this.dataSharingService.getLmsHeaderLoanId().subscribe(value => {
    //       if (value) {
    //         this.LoanId = value;
    //         if (this.Type == "L") {

    //           this.dataSharingService.getLmsHeaderLoanNo_Or_AppNo().subscribe((No) => {
    //             this.ParamLoanAcNo = No;
    //           });

    //           this.ButtonShow = false;

    //           this.LmsService.LMS_GetLoanDetail({ LoanId: this.LoanId }).subscribe((res: any) => {
    //             this.disabled = true;
    //             this.LoanMaster = res.Item1[0];
    //             this.SelectBranch = this.LoanMaster.Branch_Id;
    //             this.ApplicationNo = this.LoanMaster.ApplicationNo;
    //             this.ApplicationDate = new Date(moment(this.LoanMaster.ApplicationDate).format('MM/DD/YYYY'));
    //             this.LoanAcNo = this.LoanMaster.LoanACNo;
    //             this.LoanDate = new Date(moment(this.LoanMaster.LoanDate).format('MM/DD/YYYY'));
    //             this.ProductId = this.LoanMaster.ProductCategory;
    //             this.dataSharingService.LmsHeaderBranch.next(this.SelectBranch);
    //             this.dataSharingService.LmsHeaderLoanNo.next(this.LoanAcNo);
    //             this.setLoanId(this.LoanId);
    //             this.setLoanNo(this.LoanAcNo);
    //             this.Product(this.LoanMaster.ProductCategory);
    //             this.LMS_GetPageSubmitted();
    //           })

    //         }
    //       }
    //     });

    //     if (this.Type == "A") {

    //       this.dataSharingService.getLmsHeaderLoanNo_Or_AppNo().subscribe((No) => {
    //         this.ApplicationNo = No;

    //         this.LmsService.GET_LOANINFOBYLOS({ ApplicationNo: this.ApplicationNo, SearchBy: 'LoanInfo' }).subscribe((res: any) => {
    //           this.SelectBranch = res[0].BranchId;

    //           this.ApplicationDate = new Date(moment(res[0].ApplicationDate).format('MM/DD/YYYY'));

    //           this.ProductId = res[0].ProductCatId + '-' + res[0].LoanProduct;

    //           this.Product(this.ProductId);
    //           this.LmsService.getAppNo_AND_LoanNo({ Branch_Id: this.SelectBranch }).subscribe((response: any) => {
    //             if (response.length > 0) {
    //               this.LoanAcNo = response[0].LoanNo;
    //               this.ParamLoanAcNo = response[0].LoanNo;
    //             }
    //           });
    //           this.dataSharingService.LmsHeaderBranch.next(this.SelectBranch);
    //           this.ApplicationNo = No;
    //           var element1 = <HTMLInputElement>document.getElementById("SelectBranch");
    //           element1.disabled = true;
    //           var element1 = <HTMLInputElement>document.getElementById("ApplicationDate");
    //           element1.disabled = true;
    //           var element1 = <HTMLInputElement>document.getElementById("refLoanProduct");
    //           element1.disabled = true;

    //         });

    //       });
    //       this.dataSharingService.getLmsHeaderLoanId().subscribe(value => {
    //         this.LoanId = value;
    //       })
    //     }

    //   }
  }
  ngOnInit() {

  }
  getLoanInfo() {
    this.LmsService.LMS_Get_Loan_Summary({ EncLoanId: this.LoanId, MM_ID: this.AllPageId[0].MM_ID, LoginUserId: this.currentUser.userId }).subscribe(
      (res: any) => {
        //console.log("LMS_Get_Loan_Summary :", res);
        this.loanSummary = res[0];
        if (
          this.loanSummary.EMI_Type == "Daily" ||
          this.loanSummary.EMI_Type == "Weekly" ||
          this.loanSummary.EMI_Type == "Fortnightly" ||
          this.loanSummary.EMI_Type == "ByWeekly" ||
          this.loanSummary.EMI_Type == "FourthWeekly"
        )
          this.Tenure_Name = "Days";
        else this.Tenure_Name = "Months";
        this.getActiveTab();

        //if (
        //  JSON.parse(sessionStorage.getItem("AllPageAcess")) &&
        //  JSON.parse(sessionStorage.getItem("AllPageAcess")) != null
        //) {
        //  let AllPage = JSON.parse(sessionStorage.getItem("AllPageAcess"));
        //  if (
        //    AllPage.filter((x) => x.Page_Name == "Loan Generate") != "" &&
        //    AllPage.filter((x) => x.Page_Name == "Loan Generate") != null
        //  ) {
        //    this.PageAcess = AllPage.filter(
        //      (x) => x.Page_Name == "Loan Generate"
        //    );
        //  }
        //}
        if (
          this.loanSummary != "" &&
          this.loanSummary != undefined &&
          this.loanSummary.IsLoanNo
        ) {
          this.LmsService.LMS_Get_NoteRecommendation({
            Loan_Id: this.loanSummary.LoanId,
          }).subscribe((res: any) => {
            var noteList = [];
            res.forEach((obj: any) => {
              if (obj.Status == "Pending" || obj.Type == "Notes") {
                noteList.push(obj);
              }
            });

            this.dataSourceNotes = new MatTableDataSource(noteList);

            if (
              noteList.length > 0 &&
              this.loanSummary != "" &&
              this.loanSummary != undefined
            ) {
              $("#noteModal").modal("show");
              $("#noteModal").css("z-index", "1050");
            }
          });
        }

        // CustomerCount logic
        this.CustomerCount = this.getTabCount(
          this.loanSummary.IsLoanInfoTab
        );
        // AccountCount logic
        this.AccountCount = this.getTabCount(
          this.loanSummary.IsCustomerInfoTab,
          this.loanSummary.IsLoanInfoTab
        );

        // AssetsCount logic
        this.AssetsCount = this.getTabCount(
          this.loanSummary.IsAccountInfoTab,
          this.loanSummary.IsCustomerInfoTab,
          this.loanSummary.IsLoanInfoTab
        );
        // CRCCount logic
        this.CRCCount = this.getTabCount(
          this.loanSummary.IsAssetTab,
          this.loanSummary.IsAccountInfoTab,
          this.loanSummary.IsCustomerInfoTab,
          this.loanSummary.IsLoanInfoTab
        );

        // TVRCount logic
        this.TVRCount = this.getTabCount(
          this.loanSummary.IsAssetTab,
          this.loanSummary.IsCRCTab,
          this.loanSummary.IsAccountInfoTab,
          this.loanSummary.IsCustomerInfoTab,
          this.loanSummary.IsLoanInfoTab
        );

        // FICount logic
        this.FICount = this.getTabCount(
          this.loanSummary.IsAssetTab,
          this.loanSummary.IsCRCTab,
          this.loanSummary.IsTVRTab,
          this.loanSummary.IsAccountInfoTab,
          this.loanSummary.IsCustomerInfoTab,
          this.loanSummary.IsLoanInfoTab
        );

        // IncomeCount logic
        this.IncomeCount = this.getTabCount(
          this.loanSummary.IsAssetTab,
          this.loanSummary.IsCRCTab,
          this.loanSummary.IsTVRTab,
          this.loanSummary.IsFITab,
          this.loanSummary.IsAccountInfoTab,
          this.loanSummary.IsCustomerInfoTab,
          this.loanSummary.IsLoanInfoTab
        );

        // BankAnalysCount logic
        this.BankAnalysCount = this.getTabCount(
          this.loanSummary.IsAssetTab,
          this.loanSummary.IsCRCTab,
          this.loanSummary.IsTVRTab,
          this.loanSummary.IsFITab,
          this.loanSummary.IsIncomeTab,
          this.loanSummary.IsAccountInfoTab,
          this.loanSummary.IsCustomerInfoTab,
          this.loanSummary.IsLoanInfoTab
        );

        // FOIRCount logic
        this.FOIRCount = this.getTabCount(
          this.loanSummary.IsAssetTab,
          this.loanSummary.IsCRCTab,
          this.loanSummary.IsTVRTab,
          this.loanSummary.IsFITab,
          this.loanSummary.IsIncomeTab,
          this.loanSummary.IsBankAnalysisTab,
          this.loanSummary.IsAccountInfoTab,
          this.loanSummary.IsCustomerInfoTab,
          this.loanSummary.IsLoanInfoTab

        );
        // FamilyCount logic
        this.FamilyCount = this.getTabCount(
          this.loanSummary.IsAssetTab,
          this.loanSummary.IsCRCTab,
          this.loanSummary.IsTVRTab,
          this.loanSummary.IsFITab,
          this.loanSummary.IsIncomeTab,
          this.loanSummary.IsBankAnalysisTab,
          this.loanSummary.IsFOIRTab,
          this.loanSummary.IsAccountInfoTab,
          this.loanSummary.IsCustomerInfoTab,
          this.loanSummary.IsLoanInfoTab

        );

        // ChequeCount logic
        this.ChequeCount = this.getTabCount(
          this.loanSummary.IsAssetTab,
          this.loanSummary.IsCRCTab,
          this.loanSummary.IsTVRTab,
          this.loanSummary.IsFITab,
          this.loanSummary.IsIncomeTab,
          this.loanSummary.IsBankAnalysisTab,
          this.loanSummary.IsFOIRTab,
          this.loanSummary.IsFamilyTab,
          this.loanSummary.IsAccountInfoTab,
          this.loanSummary.IsCustomerInfoTab,
          this.loanSummary.IsLoanInfoTab


        );
        // insuranceCount logic
        this.insuranceCount = this.getTabCount(
          this.loanSummary.IsAssetTab,
          this.loanSummary.IsCRCTab,
          this.loanSummary.IsTVRTab,
          this.loanSummary.IsFITab,
          this.loanSummary.IsIncomeTab,
          this.loanSummary.IsBankAnalysisTab,
          this.loanSummary.IsFOIRTab,
          this.loanSummary.IsFamilyTab,
          this.loanSummary.IsChequeTab,
          this.loanSummary.IsAccountInfoTab,
          this.loanSummary.IsCustomerInfoTab,
          this.loanSummary.IsLoanInfoTab


        );

        //PDCount
        this.PDCount = this.getTabCount(
          this.loanSummary.IsAssetTab,
          this.loanSummary.IsCRCTab,
          this.loanSummary.IsTVRTab,
          this.loanSummary.IsFITab,
          this.loanSummary.IsIncomeTab,
          this.loanSummary.IsBankAnalysisTab,
          this.loanSummary.IsFOIRTab,
          this.loanSummary.IsFamilyTab,
          this.loanSummary.IsChequeTab,
          this.loanSummary.IsinsuranceTab,
          this.loanSummary.IsAccountInfoTab,
          this.loanSummary.IsCustomerInfoTab,
          this.loanSummary.IsLoanInfoTab


        );
        //SanctionConditionCount
        this.SanctionConditionCount = this.getTabCount(
          this.loanSummary.IsAssetTab,
          this.loanSummary.IsCRCTab,
          this.loanSummary.IsTVRTab,
          this.loanSummary.IsFITab,
          this.loanSummary.IsIncomeTab,
          this.loanSummary.IsBankAnalysisTab,
          this.loanSummary.IsFOIRTab,
          this.loanSummary.IsFamilyTab,
          this.loanSummary.IsChequeTab,
          this.loanSummary.IsinsuranceTab,
          this.loanSummary.IsPDTab,
          this.loanSummary.IsAccountInfoTab,
          this.loanSummary.IsCustomerInfoTab,
          this.loanSummary.IsLoanInfoTab


        );
        // FileCheckingCount logic
        this.FileCheckingCount = this.getTabCount(
          this.loanSummary.IsAssetTab,
          this.loanSummary.IsCRCTab,
          this.loanSummary.IsTVRTab,
          this.loanSummary.IsFITab,
          this.loanSummary.IsIncomeTab,
          this.loanSummary.IsBankAnalysisTab,
          this.loanSummary.IsFOIRTab,
          this.loanSummary.IsFamilyTab,
          this.loanSummary.IsChequeTab,
          this.loanSummary.IsinsuranceTab,
          this.loanSummary.IsPDTab,
          this.loanSummary.IsSanctionConditionTab,
          this.loanSummary.IsAccountInfoTab,
          this.loanSummary.IsCustomerInfoTab,
          this.loanSummary.IsLoanInfoTab


        );
        // AttachmentCount logic
        this.AttachmentCount = this.getTabCount(
          this.loanSummary.IsAssetTab,
          this.loanSummary.IsCRCTab,
          this.loanSummary.IsTVRTab,
          this.loanSummary.IsFITab,
          this.loanSummary.IsIncomeTab,
          this.loanSummary.IsBankAnalysisTab,
          this.loanSummary.IsFOIRTab,
          this.loanSummary.IsFamilyTab,
          this.loanSummary.IsChequeTab,
          this.loanSummary.IsinsuranceTab,
          this.loanSummary.IsPDTab,
          this.loanSummary.IsSanctionConditionTab,
          this.loanSummary.IsFileCheckingTab,
          this.loanSummary.IsAccountInfoTab,
          this.loanSummary.IsCustomerInfoTab,
          this.loanSummary.IsLoanInfoTab


        );
      }
    );
  }
  getTabCount(...conditions: boolean[]): number {
    const truthyCount = conditions.filter(Boolean).length;

    switch (truthyCount) {
      case 18: return 18;
      case 17: return 17;
      case 16: return 16;
      case 15: return 15;
      case 14: return 14;
      case 13: return 13;
      case 12: return 12;
      case 11: return 11;
      case 10: return 10;
      case 9: return 9;
      case 8: return 8;
      case 7: return 7;
      case 6: return 6;
      case 5: return 5;
      case 4: return 4;
      case 3: return 3;
      case 2: return 2;
      case 1: return 1;
      default: return 0;
    }
  }
  getActiveTab() {
    if (this.loanSummary.IsLoanInfo && this.loanSummary.IsLoanInfoTab) {
      this.currentTab = 0;
      this.lastFillTab = 0;
    }
    if (this.loanSummary.IsCustomerInfo && this.loanSummary.IsCustomerInfoTab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsAccountInfo && this.loanSummary.IsAccountInfoTab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsAssetInfo && this.loanSummary.IsAssetTab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsCrcInfo && this.loanSummary.IsCRCTab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsTvrInfo && this.loanSummary.IsTVRTab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsFiInfo && this.loanSummary.IsFITab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsIncomeExp && this.loanSummary.IsIncomeTab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsFOIR && this.loanSummary.IsFOIRTab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsFamilyMember && this.loanSummary.IsFamilyTab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsChequeDetailInfo && this.loanSummary.IsChequeTab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsInsuranceInfo && this.loanSummary.IsinsuranceTab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsBankAnalysis && this.loanSummary.IsBankAnalysisTab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsPersonalDiscussion && this.loanSummary.IsPDTab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsSanctionCondition && this.loanSummary.IsSanctionConditionTab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsFileChecking && this.loanSummary.IsFileCheckingTab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsAttachment && this.loanSummary.IsAttachmentTab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsNoteRecommendation && this.loanSummary.IsIsNoteRecommendationTab) {
      this.currentTab = this.currentTab + 1;
      this.lastFillTab = this.lastFillTab + 1;
    }
    if (this.loanSummary.IsLoanNo || this.loanSummary.IsAmortizationInfos) {
      // this.currentTab = 10;
      // this.lastFillTab = 10;
      this.currentTab = 18;
      this.lastFillTab = 18;
    }

    // if (this.loanSummary.IsAmortizationInfos) {
    //   this.currentTab = 11;
    //   this.lastFillTab = 11;
    // }
    console.log(" this.currentTab1", this.currentTab);
    console.log(" this.lastFillTab1", this.lastFillTab);

    this.isGetDetail = true;
  }
  getLoan() {
    this.LmsService.LMS_Get_Loan_Summary({ EncLoanId: this.LoanId, MM_ID: this.AllPageId[0].MM_ID, LoginUserId: this.currentUser.userId }).subscribe(
      (res: any) => {
        //console.log("res :", res);
        this.loanSummary = res[0];
        if (
          this.loanSummary.EMI_Type == "Daily" ||
          this.loanSummary.EMI_Type == "Weekly" ||
          this.loanSummary.EMI_Type == "Fortnightly" ||
          this.loanSummary.EMI_Type == "ByWeekly" ||
          this.loanSummary.EMI_Type == "FourthWeekly"
        )
          this.Tenure_Name = "Days";
        else this.Tenure_Name = "Months";
      }
    );
  }
  onChildAction(event: any) {
    if (event == "next") {
      if (this.currentTab - 1 == this.lastFillTab) {
        this.lastFillTab = this.lastFillTab + 1;
      }
      if (this.currentTab == 14 && !this.loanSummary.LoanNo) {
        this.getLoan();
      }
      if (this.currentTab == 1 && !this.loanSummary.Customer) {
        this.getLoan();
      }
      if (this.currentTab == 2 && !this.loanSummary.LoanNo) {
        this.getLoan();
      }
      this.currentTab = this.currentTab + 1;
     // this.getActiveTab();
        console.log(" this.currentTab", this.currentTab);
        console.log(" this.lastFillTab", this.lastFillTab);
    }
  }
  onTabChange(event: any) {
    this.currentTab = event.index;
  }

  OnNoteClose() {
    $("#noteModal").modal("hide");
  }
}
