<a href="javascript:void(0)" (click)="onOpenViewDetailModal()">
  <ng-content></ng-content>
</a>
<div
  class="modal fade in CustomerInformation{{ index }}"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Customer Information
        </h6>
        <button
          type="button"
          (click)="onCloseReceipt()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Loan Information Starts-->

        <div class="loan_details_div formborder">
          <!-- style="position: sticky; top: -10px; z-index: 100;" -->
          <div class="row m-0 mt-0">
            <div class="col-md-12 mb-1">
              <div class="row">
                <div class="col-md-3">
                  <span class="fs-14"
                    ><B>Loan No : </B>{{ originalData?.LoanAcNo }}</span
                  >
                </div>
                <div class="col-md-3">
                  <span class="fs-12"
                    ><B>Loan Date :</B>{{ originalData?.Loan_Date }}</span
                  >
                </div>
                <div class="col-md-3">
                  <span class="fs-12">
                    <B>Application No :</B>{{ originalData?.ApplicationNo }}
                  </span>
                </div>
                <div class="col-md-3">
                  <span class="fs-12">
                    <B>Application Date :</B
                    >{{ originalData?.ApplicationCreateOn }}
                  </span>
                </div>

                <div class="col-md-3">
                  <span class="fs-12"
                    ><B>Branch :</B>{{ originalData?.Branch }}</span
                  >
                </div>
                <div class="col-md-3">
                  <span class="fs-12">
                    <B>Loan Amount :</B>{{ originalData?.AgreementValue }}
                  </span>
                </div>
                <div class="col-md-3">
                  <span class="fs-12"
                    ><B>Product :</B>{{ originalData?.Product }}</span
                  >
                </div>
                <div class="col-md-3">
                  <span class="fs-12"
                    ><B>Borrower :</B> {{ originalData?.Customer }}</span
                  >
                </div>

                <div class="col-md-3">
                  <span class="fs-12">
                    <B>Tenure :</B>{{ originalData?.Tenure }} (MONTHS)
                  </span>
                </div>
                <div class="col-md-3">
                  <span class="fs-12"
                    ><B>Expiry Date :</B>{{ originalData?.ExpiryDate }}</span
                  >
                </div>
                <!-- <div class="col-md-3">
            <span class="fs-12"
              ><B>Close Date :</B>{{ originalData?.CloseDate }}</span
            >
          </div> -->
                <!-- <div class="col-md-3">
            <span class="fs-12"
              ><B>Loan A/C Category :</B>
              {{ originalData?.LoanAcNo }}</span
            >
          </div> -->

                <div class="col-md-3">
                  <span class="fs-12"
                    ><B>Total Waiver :</B>{{ originalData?.TotalWaiwer }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Loan Information Ends -->

        <!-- Customer Details Table-- start -->

        <div
          class="row m-0 mb-1 mt-2 col-md-12 align-items-center justify-content-between"
        >
          <h1 class="fs-12 m-0">Customer Details</h1>
        </div>

        <div class="row m-0 mb-2 col-md-12">
          <div class="table-responsive">
            <mat-table [dataSource]="dataCustomerSource">
              <ng-container matColumnDef="Number">
                <mat-header-cell
                  class="grid-header"
                  style="max-width: 50px"
                  *matHeaderCellDef
                  >#</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                  style="max-width: 50px; max-height: 5px"
                >
                  {{ i + 1 }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="CustomerId" style="display: none">
                <mat-header-cell
                  class="grid-header"
                  style="max-width: 100px; max-height: 5px; display: none"
                  *matHeaderCellDef
                >
                  CustomerId
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row"
                  class="grid-cell"
                  style="max-width: 100px; display: none; max-height: 5px"
                >
                  {{ row.CustomerId }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Customer">
                <mat-header-cell class="grid-header" *matHeaderCellDef>
                  Customer
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                >
                  <!--<app-los-customer-view-model [customerId]="row.CustomerId">{{row.Customer}}</app-los-customer-view-model>-->
                  <app-lms-customer-view-model
                    [customerId]="row.CustomerId"
                    [index]="i"
                  >
                    {{ row.Customer }}
                  </app-lms-customer-view-model>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="CustomerType">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Customer Type</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{
                    row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType
                  }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="GenderAge">
                <mat-header-cell class="grid-header" *matHeaderCellDef>
                  Gender Age
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.GenderAge }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="PhoneNo">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Phone No</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                 

                  <div *ngIf="currentUser.IsMaskingMobile">
                    {{ row.PhoneNo | mask }}
                  </div>
    
                  <div *ngIf="!currentUser.IsMaskingMobile">
                    {{ row.PhoneNo }}
                  </div>
                  



                  <i
                    *ngIf="row.PhoneNoIsVerified.toLowerCase() == 'true'"
                    class="fa fa-check-circle ml-2"
                    style="color: green"
                  ></i>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Relation_With_Hirer">
                <mat-header-cell class="grid-header" *matHeaderCellDef>
                  Relation With Borrower
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Relation_With_Hirer }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="ExistingCustomer">
                <mat-header-cell class="grid-header" *matHeaderCellDef>
                  Existing
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.ExistingCustomer }}
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedCustomerColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedCustomerColumns"
              ></mat-row>
            </mat-table>
          </div>
        </div>
        <div
          class="loan_details_div"
          *ngIf="Waivar_Id > 0 && ChargesModel.Charges_Detail.length > 0"
        >
          <div class="row m-0 mt-0">
            <div class="col-md-12 mb-1">
              <div class="row">
                <div class="col-md-3">
                  <span class="fs-14"
                    ><B>Create By: </B
                    >{{
                      Waivar_Id > 0 && ChargesModel.Charges_Detail.length > 0
                        ? ChargesModel.Charges_Detail[0].CreatedBy
                        : ""
                    }}</span
                  >
                </div>
                <div class="col-md-3">
                  <span class="fs-12"
                    ><B>Create On :</B
                    >{{
                      Waivar_Id > 0 && ChargesModel.Charges_Detail.length > 0
                        ? ChargesModel.Charges_Detail[0].CreateOn
                        : ""
                    }}</span
                  >
                </div>
                <div class="col-md-3" *ngIf="Status != 'Rejected'">
                  <span class="fs-12">
                    <B>Authoriz By :</B
                    >{{
                      Waivar_Id > 0 && ChargesModel.Charges_Detail.length > 0
                        ? ChargesModel.Charges_Detail[0].ApprovedBy
                        : ""
                    }}
                  </span>
                </div>
                <div class="col-md-3" *ngIf="Status == 'Approved'">
                  <span class="fs-12">
                    <B>Authoriz On :</B
                    >{{
                      Waivar_Id > 0 && ChargesModel.Charges_Detail.length > 0
                        ? ChargesModel.Charges_Detail[0].AuthorizOn
                        : ""
                    }}
                  </span>
                </div>

                <div class="col-md-3" *ngIf="Status == 'Rejected'">
                  <span class="fs-12"
                    ><B>Rejected By :</B
                    >{{
                      Waivar_Id > 0 && ChargesModel.Charges_Detail.length > 0
                        ? ChargesModel.Charges_Detail[0].ApprovedBy
                        : ""
                    }}</span
                  >
                </div>
                <div class="col-md-3" *ngIf="Status == 'Rejected'">
                  <span class="fs-12">
                    <B>Rejected On :</B
                    >{{
                      Waivar_Id > 0 && ChargesModel.Charges_Detail.length > 0
                        ? ChargesModel.Charges_Detail[0].AuthorizOn
                        : ""
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row m-0 mb-1 mt-2 col-md-12 align-items-center justify-content-between"
          *ngIf="dataSourceApprovedWaiver?.filteredData.length > 0"
        >
          <h1 class="fs-12 m-0">Approved Waiver</h1>
        </div>

        <div
          class="row m-0 mb-2 col-md-12"
          *ngIf="dataSourceApprovedWaiver?.filteredData.length > 0"
        >
          <div class="table-responsive">
            <mat-table [dataSource]="dataSourceApprovedWaiver">
              <ng-container matColumnDef="Number">
                <mat-header-cell
                  class="grid-header"
                  style="max-width: 50px"
                  *matHeaderCellDef
                  >#</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                  style="max-width: 50px; max-height: 5px"
                >
                  {{ i + 1 }}
                </mat-cell>
                <mat-footer-cell
                  *matFooterCellDef
                  style="max-width: 50px; max-height: 5px"
                ></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="ChagesHead">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Charges Head</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.ChagesHead }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Request_Amount">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Request Amount</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Request_Amount }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef
                  >{{ totaltypewise("Request_Amount") }}
                </mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="Approved_Amount">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Approved Amount</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Approved_Amount }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                  {{ totaltypewise("Approved_Amount") }}</mat-footer-cell
                >
              </ng-container>
              <ng-container matColumnDef="CreatedBy">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Created By</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.CreatedBy }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="CreateOn">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Create On</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.CreateOn }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="ApprovedBy">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Approved By</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.ApprovedBy }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="ApprovedOn">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Approved On</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.ApprovedOn }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="displayedApprovedWaiver"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedApprovedWaiver"
              ></mat-row>
              <mat-footer-row
                class="sticky-footer fontcolor"
                *matFooterRowDef="displayedApprovedWaiver; sticky: true"
                style="background: #4dc3a3"
              ></mat-footer-row>
            </mat-table>
          </div>
        </div>

        <!-- Customer Details Table--end -->
        <!-- Pending Waiver--start -->

        <div
          class="row m-0 mb-1 mt-2 col-md-12 align-items-center justify-content-between"
          *ngIf="dataSourcePendingWaiver?.filteredData.length > 0"
        >
          <h1 class="fs-12 m-0">Pending Waiver</h1>
        </div>

        <div
          class="row m-0 mb-2 col-md-12"
          *ngIf="dataSourcePendingWaiver?.filteredData.length > 0"
        >
          <div class="table-responsive">
            <mat-table [dataSource]="dataSourcePendingWaiver">
              <ng-container matColumnDef="Number">
                <mat-header-cell
                  class="grid-header"
                  style="max-width: 50px"
                  *matHeaderCellDef
                  >#</mat-header-cell
                >
                <mat-cell
                  *matCellDef="let row; let i = index"
                  class="grid-cell"
                  style="max-width: 50px; max-height: 5px"
                >
                  {{ i + 1 }}
                </mat-cell>
                <mat-footer-cell
                  *matFooterCellDef
                  style="max-width: 50px; max-height: 5px"
                ></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="ChagesHead">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Charges Head</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.ChagesHead }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="Request_Amount">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Request Amount</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.Request_Amount }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef>
                  {{ totalPendingtypewise("Request_Amount") }}</mat-footer-cell
                >
              </ng-container>

              <ng-container matColumnDef="CreatedBy">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Created By</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.CreatedBy }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>

              <ng-container matColumnDef="CreateOn">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Create On</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.CreateOn }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>
              <ng-container matColumnDef="ApprovedBy">
                <mat-header-cell class="grid-header" *matHeaderCellDef
                  >Approved By</mat-header-cell
                >
                <mat-cell *matCellDef="let row" class="grid-cell">
                  {{ row.ApprovedBy }}
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="displayedPendingWaiver"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedPendingWaiver"
              ></mat-row>
            </mat-table>
          </div>
        </div>

        <!-- Pending Waiver--end -->

        <div class="row m-0 mt-1 col-md-12 p-0">
          <div class="col-md-12"><hr /></div>
        </div>

        <form
          #f="ngForm"
          novalidate
          class="mt-1 p-2 formborder"
          style="background-color: #c4e3ff"
          *ngIf="WaiwerStatus == 0 || (WaiwerStatus > 0 && Status != 'Pending')"
        >
          <form #df="ngForm" novalidate class="mt-2">
            <div
              class="row m-0 add-remove"
              *ngFor="let vdData of ChargesModel.Charges_Detail; let i = index"
            >
              <div class="col-md-12 row m-0 type-column">
                <div class="row m-0 feild">
                  <ng-container>
                    <div class="col-md-6 mr_top pr-0">
                      <div [ngClass]="{ 'bg-success1': i == 0 }">
                        <span class="required-lable" *ngIf="i == 0"
                          >Charges Head</span
                        >
                      </div>
                      <select
                        name="ChargeHeadId{{ i }}"
                        id="ChargeHeadId{{ i }}"
                        required
                        [disabled]="Status != 'Pending' && Status != 'Reverted'"
                        #refChargeHeadId="ngModel"
                        [ngClass]="{
                          'is-invalid': df.submitted && refChargeHeadId.invalid,
                          'alert-warning':
                            refChargeHeadId.invalid &&
                            (refChargeHeadId.dirty ||
                              refChargeHeadId.touched ||
                              refChargeHeadId.untouched),
                          'mt-1': i == 0
                        }"
                        [(ngModel)]="vdData.ChargeHeadId"
                        class="form-control input-text-css"
                        (change)="onChangeChargesHead(vdData, i)"
                      >
                        <option value="">Select Category</option>
                        <option
                          *ngFor="let item of chargesHeadDropdown"
                          [value]="item.Id"
                        >
                          {{ item.ChagesHead }}
                        </option>
                      </select>
                    </div>

                    <div
                      class="mr_top"
                      [ngClass]="{
                        'col-md-6': Status == 'Pending' || Status == 'Rejected',
                        'col-md-3':
                          Status == 'Auth-Pending' || Status == 'Approved'
                      }"
                    >
                      <div [ngClass]="{ 'bg-success1': i == 0 }">
                        <span class="required-lable" *ngIf="i == 0"
                          >Request Amount</span
                        >
                      </div>
                      <input
                        type="text"
                        min="0"
                        name="Request_Amount{{ i }}"
                        id="Request_Amount{{ i }}"
                        appTwoDigitDecimaNumber
                        class="form-control input-text-css text-right pr-3"
                        required
                        [disabled]="Status != 'Pending'"
                        #refAmount="ngModel"
                        [(ngModel)]="vdData.Request_Amount"
                        (change)="onChangeAmount($event, vdData)"
                        (keyup.enter)="onChangeAmount($event, vdData)"
                        [ngClass]="{
                          'is-invalid': df.submitted && refAmount.invalid,
                          'alert-warning':
                            refAmount.invalid &&
                            (refAmount.dirty ||
                              refAmount.touched ||
                              refAmount.untouched),
                          'mt-1': i == 0
                        }"
                      />
                    </div>
                    <div
                      class="col-md-3 mr_top"
                      *ngIf="Status == 'Auth-Pending' || Status == 'Approved'"
                    >
                      <div [ngClass]="{ 'bg-success1': i == 0 }">
                        <span class="required-lable" *ngIf="i == 0"
                          >Approved Amount</span
                        >
                      </div>
                      <input
                        type="text"
                        [max]="vdData.Request_Amount"
                        name="Approved_Amount{{ i }}"
                        id="Approved_Amount{{ i }}"
                        appTwoDigitDecimaNumber
                        class="form-control input-text-css text-right pr-3"
                        required
                        [disabled]="Status == 'Approved'"
                        #refApproved_Amount="ngModel"
                        [(ngModel)]="vdData.Approved_Amount"
                        (change)="onChangeAmount($event, vdData)"
                        (keyup.enter)="onChangeAmount($event, vdData)"
                        [ngClass]="{
                          'is-invalid':
                            df.submitted && refApproved_Amount.invalid,
                          'alert-warning':
                            refApproved_Amount.invalid &&
                            (refApproved_Amount.dirty ||
                              refApproved_Amount.touched ||
                              refApproved_Amount.untouched),
                          'mt-1': i == 0
                        }"
                      />
                    </div>
                  </ng-container>
                </div>
              </div>
              <button
                type="button"
                class="remove"
                [hidden]="Status != 'Pending' && Status != 'Reverted'"
                (click)="removeData(i)"
                [ngClass]="{ 'no-head': i != 0 }"
                *ngIf="ChargesModel.Charges_Detail.length > 1"
              >
                -
              </button>
              <button
                type="button"
                class="addmore"
                (click)="addMoreData(i)"
                [hidden]="Status != 'Pending' && Status != 'Reverted'"
                [ngClass]="{
                  both: ChargesModel.Charges_Detail.length > 1,
                  'no-head': i != 0
                }"
                [disabled]="
                  !df.form.valid ||
                  (Status != 'Pending' && Status != 'Reverted')
                "
                *ngIf="i == ChargesModel.Charges_Detail.length - 1"
              >
                +
              </button>
            </div>

            <div class="col-md-12 row p-1 pl-3 m-3" style="width: 92.8%">
              <div class="col-md-12 bg-success1 p-1">
                <div class="row">
                  <div class="col-md-6">Grand Total :</div>
                  <div
                    class="text-right"
                    [ngClass]="{
                      'col-md-6': Status == 'Pending' || Status == 'Rejected',
                      'col-md-3':
                        Status == 'Auth-Pending' || Status == 'Approved'
                    }"
                  >
                    {{ Request_Total_Amount.toFixed(2) }}
                  </div>
                  <div
                    class="col-md-3 text-right"
                    *ngIf="Status == 'Auth-Pending' || Status == 'Approved'"
                  >
                    {{ Approved_Total_Amount.toFixed(2) }}
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div
            class="row m-0 m-3 mt-2"
            *ngIf="Status != 'Approved' && Status != 'Rejected'"
          >
            <div class="col-md-6">
              <span class="">Remark</span>
              <input
                type="text"
                required
                *ngIf="Status != 'Approved'"
                #refRemark="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refRemark.invalid,
                  'alert-warning':
                    refRemark.invalid &&
                    (refRemark.dirty ||
                      refRemark.touched ||
                      refRemark.untouched)
                }"
                [(ngModel)]="ChargesModel.Charges.Remark"
                name="Remark"
                id="Remark"
                class="form-control input-text-css"
              />
            </div>

            <div
              class="col-md-4"
              *ngIf="Status == 'Pending' || Status == 'Reverted'"
            >
              <span class="">Authoriz By</span>
              <input
                type="text"
                readonly
                [(ngModel)]="AuthorizByName"
                name="AuthorizByName"
                id="AuthorizByName"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-4" *ngIf="Status == 'Auth-Pending'">
              <span class="">Type</span>
              <select
                name="Type"
                id="Type"
                class="form-control input-text-css"
                [(ngModel)]="Type"
              >
                <option value="Approved">Approve</option>
                <option value="Rejected">Reject</option>
              </select>
            </div>
            <div class="col-md-2 mt-3 text-right">
              <button
                type="button"
                [hidden]="Status != 'Pending' && Status != 'Reverted'"
                class="btn font-size-12 button-btn"
                (click)="saveWaiverRequest()"
                [disabled]="
                  !f.form.valid ||
                  !df.form.valid ||
                  AuthorizBy == 0 ||
                  Request_Total_Amount <= 0 ||
                  (Status != 'Pending' && Status != 'Reverted')
                "
              >
                Save
              </button>
              <button
                type="button"
                *ngIf="Status == 'Auth-Pending'"
                class="btn font-size-12 button-btn"
                (click)="saveWaiverApproved()"
                [disabled]="
                  !f.form.valid || !df.form.valid || Approved_Total_Amount <= 0
                "
              >
                Save
              </button>
            </div>
          </div>
        </form>

        <div
          class="row m-0 m-3 mt-2"
          *ngIf="WaiwerStatus > 0 && Status == 'Pending'"
        >
          <div class="col-md-12">
            <h4 style="color: red">
              You are not eligible to create new Waiver request because Previous
              request pending for Approval.
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
