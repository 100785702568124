<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <div class="formborder">
    <ng-container *ngFor="let loanDetailObj of LoanAllDetail">
      <div class="row m-0 h-b w-b" style="width: 100%">
        <h1 class="fs-12 m-0">Loan Details</h1>
      </div>
      <div class="row m-0 mt-3">
        <div class="col-md-2">
          <h6 class="fs-12">Loan No</h6>
          <p class="lead fs-12">{{ loanDetailObj.LoanNo }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Loan Date</h6>
          <p class="lead fs-12">{{ loanDetailObj.LoanDate }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Application No</h6>
          <p class="lead fs-12">{{ loanDetailObj.ApplicationNo }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Product</h6>
          <p class="lead fs-12">{{ loanDetailObj.Product }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Branch</h6>
          <p class="lead fs-12">{{ loanDetailObj.Branch }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Asset Cost</h6>
          <p class="lead fs-12 fw-7">
            ₹ {{ loanDetailObj.AssetCost ? loanDetailObj.AssetCost : "0" }}
          </p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Net Finance</h6>
          <p class="lead fs-12">₹ {{ loanDetailObj.LoanAmount }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">
            Tenure ({{ loanDetailObj != undefined ? LoanTenureFreq : "" }})
          </h6>
          <p class="lead fs-12">
            {{ loanDetailObj.LoanTenure }}
            {{ loanDetailObj != undefined ? LoanTenureFreq : "" }}
          </p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">No of Installment</h6>
          <p class="lead fs-12">{{ loanDetailObj.No_Of_Installment }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Agreemnent Value</h6>
          <p class="lead fs-12">₹ {{ loanDetailObj.AgreementValue }}</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">Interest Amount</h6>
          <p class="lead fs-12">₹ {{ loanDetailObj.InterestAmt }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">LTV</h6>
          <p class="lead fs-12">{{ loanDetailObj.LTV?.toFixed(2) }}%</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">EMI Amount</h6>
          <p class="lead fs-12">₹ {{ loanDetailObj.LoanEMIAmount }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">ROI</h6>
          <p class="lead fs-12">{{ loanDetailObj.ROI?.toFixed(2) }}%</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">IRR Calculate By</h6>
          <p class="lead fs-12">{{ loanDetailObj.IRR_CalculateBy }}</p>
        </div>

        <div class="col-md-2">
          <h6 class="fs-12">Case IRR</h6>
          <p class="lead fs-12">{{ loanDetailObj.Case_IRR?.toFixed(2) }}%</p>
        </div>
        <div class="col-md-2">
          <h6 class="fs-12">EMI Due Date</h6>
          <p class="lead fs-12">
            {{ loanDetailObj.EMI_DueDate | date : "dd/MM/yyyy" }}
          </p>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="table-responsive mt-2">
    <mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="Int_Id">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px; max-height: 5px"
        >
          {{ i + 1 }}
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="CustomerId">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer Id
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerId }}
        </mat-cell>
      </ng-container> -->

      <ng-container matColumnDef="Customer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
          <app-los-customer-view-model
            [customerId]="row.CustomerId"
            [index]="i"
          >
            {{ row.Customer }}
          </app-los-customer-view-model>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerType">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Type
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="GenderAge">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Gender Age
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.GenderAge }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PhoneNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Phone No
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          
          <div *ngIf="data.IsMaskingMobile">
            {{ row.PhoneNo | mask }}
          </div>

          <div *ngIf="!data.IsMaskingMobile">
            {{ row.PhoneNo }}
          </div>

          

          <!-- 
          <i
            class="fa fa-arrow-circle-right arrow-circle action-btn ml-2"
            aria-label="true"
          ></i> -->
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Relation_With_Hirer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Relation
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Relation_With_Hirer }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Present_Address">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="min-width: 300px; max-width: 300px"
          *matHeaderCellDef
        >
          Address
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="min-width: 300px; max-width: 300px"
        >
          {{ row.Present_Address }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ExistingCustomer">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="min-width: 90px; max-width: 90px"
          *matHeaderCellDef
        >
          Existing
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="min-width: 90px; max-width: 90px"
        >
          {{ row.ExistingCustomer }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>

  <div class="formborder mt-2">
    <ng-container>
      <div
        class="row m-0 mt-2 h-b w-b justify-content-between align-items-center"
        style="width: 100%"
      >
        <h1 class="fs-12 m-0">Pre Closer Detail</h1>
        <button
          type="button"
          (click)="onViewLoanStatement()"
          class="btn btn-light font-size-12"
          style="padding: 3px 5px"
        >
          View Loan Statement
        </button>
      </div>

      <div class="row m-0 mt-3" id="printData">
        <div style="width: 100%; padding-left: 20px; padding-right: 20px">
          <div class="row m-0 mt-3">
            <div class="col-md-3">
              <h6 class="fs-12">Request Status</h6>
              <p
                [ngClass]="{
                  'text-danger':
                    PreCloserDetails.Closer_RequestStatus == 'Waiver Rejected',
                  'text-success':
                    PreCloserDetails.Closer_RequestStatus == 'Waiver Approved'
                }"
              >
                {{ PreCloserDetails.Closer_RequestStatus }}
              </p>
              <p *ngIf="PreCloserDetails.TotalWaiver_Approved">
                Approved Waiver Amount:<b>
                  ₹{{ PreCloserDetails.TotalWaiver_Approved }}</b
                >
              </p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Closer Type</h6>
              <select
                name="CloserType"
                id="CloserType"
                [(ngModel)]="PreCloserDetails.CloserType"
                class="form-control input-text-css"
                style="width: 185px; display: inline-block"
              >
                <option value="">Select Closer Type</option>
                <option value="Regular Close">Regular Close</option>
                <option value="Pre-Closer">Pre-Closer</option>
                <option value="Repossess Sold">Repossess Sold</option>
                <option value="Settled Case">Settled Case</option>
                <option value="Cancelled Case">Cancelled Case</option>
                <option value="Account Sold">Account Sold</option>
              </select>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Last Transaction Date</h6>
              <p>{{ PreCloserDetails.lastTrasactionDate }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Pre-Closure Effective Date</h6>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  [(ngModel)]="PreCloserDetails.LoanCloser_EffectiveDate"
                  [min]="MinEffectiveDate"
                  [max]="PreCloserDetails.NextDueDate"
                  name="LoanCloser_EffectiveDate"
                  id="LoanCloser_EffectiveDate"
                  (dateChange)="onEffectiveDateChange()"
                  [disabled]="
                    PreCloserDetails.Closer_RequestStatus != 'Created'
                  "
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                  style="right: 0 !important"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
              <!-- <input
                type="text"
                [(ngModel)]="PreCloserDetails.LoanCloser_EffectiveDate"
                [minDate]="MinEffectiveDate"
                [maxDate]="PreCloserDetails.NextDueDate"
                name="LoanCloser_EffectiveDate"
                id="LoanCloser_EffectiveDate"
                value="{{
                  PreCloserDetails.LoanCloser_EffectiveDate
                    | date : 'dd/MM/yyyy'
                }}"
                placeholder="DD/MM/YYYY"
                [(bsValue)]="PreCloserDetails.LoanCloser_EffectiveDate"
                class="form-control input-text-css"
                bsDatepicker
                style="width: 160px"
                (bsValueChange)="onEffectiveDateChange()"
                [disabled]="PreCloserDetails.Closer_RequestStatus != 'Created'"
              /> -->
            </div>
          </div>

          <table style="margin-bottom: 20px">
            <tbody>
              <tr style="background: #120eeb29">
                <td style="border: 1px solid #b5b0b0; padding: 5px">
                  <b>Account Head</b>
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <b>Due</b>
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <b>Received</b>
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <b>Waiver</b>
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <b>Balance</b>
                </td>
              </tr>
              <tr
                *ngFor="let preCloserData of PreCloserMethodList; let i = index"
                style="height: 43px"
              >
                <td style="border: 1px solid #b5b0b0; padding: 5px">
                  {{ preCloserData.ChagesHead }}
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  {{ preCloserData.DueAmount }}
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  {{ preCloserData.ReceiveAmount }}
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <input
                    type="text"
                    [(ngModel)]="preCloserData.WaiverAmount"
                    placeholder="amount"
                    name="WaiverAmount{{ i }}"
                    id="WaiverAmount{{ i }}"
                    *ngIf="preCloserData.IsWaiver_Eligible"
                    class="form-control input-text-css"
                    [disabled]="
                      totalCalculator?.totalBalance < 0 ||
                      isExpired ||
                      isShowForward ||
                      PreCloserDetails.Closer_RequestStatus ==
                        'Waiver Pending' ||
                      PreCloserDetails.Closer_RequestStatus ==
                        'Waiver Rejected' ||
                      (PreCloserDetails.Closer_RequestStatus == 'Closed' &&
                        !showSpinner)
                    "
                    style="width: 90px; margin: 0px auto"
                    (ngModelChange)="onChangeWaiverAmt($event, preCloserData)"
                  />
                  <ng-container
                    *ngIf="
                      !preCloserData.IsWaiver_Eligible &&
                      preCloserData.WaiverAmount > 0
                    "
                  >
                    {{ preCloserData.WaiverAmount }}
                  </ng-container>
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  {{ preCloserData.Balance }}
                </td>
              </tr>
              <tr
                *ngIf="PreCloserMethodList.length > 0 && totalCalculator"
                style="background: #120eeb29"
              >
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <b>Total :</b>
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <b>{{ totalCalculator.totalDueAmount?.toFixed(2) }}</b>
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <b>{{ totalCalculator.totalReceiveAmount?.toFixed(2) }}</b>
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <b>{{ totalCalculator.totalWaiverAmount?.toFixed(2) }}</b>
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <b>{{ totalCalculator.totalBalance?.toFixed(2) }}</b>
                </td>
              </tr>
            </tbody>
          </table>

          <div
            class="row m-0 h-b w-b"
            style="width: 100%"
            *ngIf="LoanChargesRecoverableList"
          >
            <h1 class="fs-12 m-0">Detail Of Loan Charges Recoverable</h1>
          </div>
          <table
            style="margin-bottom: 20px; width: 50%"
            *ngIf="LoanChargesRecoverableList"
          >
            <tbody>
              <tr style="background: #120eeb29">
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <b>Head</b>
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <b>Amount</b>
                </td>
              </tr>
              <tr
                *ngFor="
                  let LoanCharges of LoanChargesRecoverableList;
                  let i = index
                "
                style="height: 43px"
              >
                <td style="border: 1px solid #b5b0b0; padding: 5px">
                  {{ LoanCharges.ChagesHead }}
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  {{ LoanCharges.Amount?.toFixed(2) }}
                </td>
              </tr>
              <tr>
                <td style="border: 1px solid #b5b0b0; padding: 5px">
                  <b>Total</b>
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <b>{{ LoanChargesRecoverable.Due_Amount?.toFixed(2) }}</b>
                </td>
              </tr>
              <tr>
                <td style="border: 1px solid #b5b0b0; padding: 5px">
                  <b>Received</b>
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <b>{{
                    LoanChargesRecoverable.Received_Amount?.toFixed(2)
                  }}</b>
                </td>
              </tr>
              <tr style="background: #120eeb29">
                <td style="border: 1px solid #b5b0b0; padding: 5px">
                  <b>Balance</b>
                </td>
                <td
                  style="
                    border: 1px solid #b5b0b0;
                    padding: 5px;
                    text-align: center;
                  "
                >
                  <b>{{ LoanChargesRecoverable_Balance?.toFixed(2) }}</b>
                </td>
              </tr>
            </tbody>
          </table>

          <table style="margin-bottom: 20px">
            <tbody>
              <tr>
                <td style="padding: 5px">
                  <h6 style="font-size: 12px">Net Receivable Amount:</h6>
                </td>
                <td style="padding: 5px">
                  <p style="font-size: 12px">
                    <B>{{ totalCalculator?.totalBalance?.toFixed(2) }}</B>
                  </p>
                </td>
                <td style="padding: 5px">
                  <p style="font-size: 12px; display: none">
                    Your Waiver Limit On this Loan No:
                    <b>{{ PreCloserDetails.Waiver_Limit_Per_Amt_R }}</b> Rs
                  </p>
                </td>
              </tr>
              <tr>
                <td style="padding: 5px">
                  <h6 style="font-size: 12px">Amount To Be Deposit:</h6>
                </td>
                <td style="padding: 5px">
                  <input
                    type="text"
                    [(ngModel)]="PreCloserDetails.CustomerAgreed_Amount"
                    placeholder="amount"
                    name="CustomerAgreed_Amount"
                    id="CustomerAgreed_Amount"
                    (blur)="onChangeAgreeAmount()"
                    style="width: 300px"
                    [disabled]="
                      PreCloserDetails.Closer_RequestStatus ==
                        'Waiver Pending' ||
                      PreCloserDetails.Closer_RequestStatus ==
                        'Waiver Approved' ||
                      isExpired ||
                      totalCalculator?.totalBalance < 0 ||
                      (PreCloserDetails.Closer_RequestStatus == 'Closed' &&
                        !showSpinner)
                    "
                    class="form-control input-text-css"
                  />
                </td>
                <td
                  style="padding: 5px"
                  *ngIf="
                    isShowForward ||
                    PreCloserDetails.Closer_RequestStatus != 'Created'
                  "
                >
                  <div style="display: inline-block; margin-right: 20px">
                    <h6 style="font-size: 12px">
                      Select User For Waiver Approval:
                    </h6>
                  </div>
                  <div style="display: inline-block">
                    <select
                      name="Waiver_GivenBy"
                      id="Waiver_GivenBy"
                      [(ngModel)]="PreCloserDetails.Waiver_GivenBy"
                      class="form-control input-text-css"
                      style="width: 300px"
                      [disabled]="
                        isExpired ||
                        PreCloserDetails.Closer_RequestStatus != 'Created'
                      "
                    >
                      <option value="">Select</option>
                      <option
                        *ngFor="let user of wavieruserList"
                        [value]="user.EmpId"
                      >
                        {{ user.Employee }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr *ngIf="PreCloserMethodList.length > 0 && totalCalculator">
                <td style="padding: 5px">
                  <h6 style="font-size: 12px">Waiver Required Amount</h6>
                </td>
                <td style="padding: 5px">
                  <p style="font-size: 12px">
                    {{ PreCloserDetails?.totalWaiverAmount?.toFixed(2) }}
                  </p>
                </td>
              </tr>
              <tr>
                <td style="padding: 5px">
                  <h6 style="font-size: 12px">Upload Closer Request Doc:</h6>
                </td>
                <td style="padding: 5px">
                  <input
                    type="file"
                    (change)="fileChangeEvent($event)"
                    *ngIf="!PreCloserDetails.CloserRequestDoc"
                    name="Request_Doc"
                    accept=".png, .jpg, .jpeg"
                    id="Request_Doc"
                  />
                  <a
                    href="{{ loandocBaseUrl }}{{ LoanId }}/{{
                      PreCloserDetails.CloserRequestDoc
                    }}"
                    target="_blank"
                    *ngIf="PreCloserDetails.CloserRequestDoc"
                  >
                    {{ PreCloserDetails.CloserRequestDoc }}
                  </a>
                  <i
                    class="fa fa-times ml-2"
                    style="cursor: pointer; font-size: larger"
                    (click)="onRemoveImg()"
                    aria-label="true"
                    *ngIf="PreCloserDetails.CloserRequestDoc"
                  ></i>
                </td>
              </tr>

              <tr>
                <td style="padding: 5px">
                  <h6 style="font-size: 12px">Pre-Closer Remark:</h6>
                </td>
                <td style="padding: 5px" colspan="2">
                  <textarea
                    [(ngModel)]="PreCloserDetails.LoanCloser_Remark"
                    placeholder="Pre-Closer Remark"
                    name="LoanCloser_Remark"
                    id="LoanCloser_Remark"
                    class="form-control input-text-css"
                    [disabled]="
                      PreCloserDetails.Closer_RequestStatus ==
                        'Waiver Pending' ||
                      isExpired ||
                      (PreCloserDetails.Closer_RequestStatus == 'Closed' &&
                        !showSpinner)
                    "
                  ></textarea>
                </td>
              </tr>
              <tr
                *ngIf="
                  PreCloserDetails.Closer_RequestStatus == 'Waiver Pending' ||
                  PreCloserDetails.Waiver_Remark?.length > 0
                "
              >
                <td style="padding: 5px">
                  <h6 style="font-size: 12px">Waiver Remark:</h6>
                </td>
                <td style="padding: 5px" colspan="2">
                  <textarea
                    [(ngModel)]="PreCloserDetails.Waiver_Remark"
                    placeholder="Waiver Remark"
                    name="Waiver_Remark"
                    id="Waiver_Remark"
                    class="form-control input-text-css"
                    [disabled]="isExpired || !isWavierGivenByMe"
                  ></textarea>
                </td>
              </tr>
              <tr *ngIf="isUndoRemark">
                <td style="padding: 5px">
                  <h6 style="font-size: 12px">Undo Remark:</h6>
                </td>
                <td style="padding: 5px" colspan="2">
                  <textarea
                    [(ngModel)]="undoRemark"
                    placeholder="Undo Remark"
                    name="undoRemark"
                    id="undoRemark"
                    class="form-control input-text-css"
                  ></textarea>
                </td>
              </tr>
              <tr *ngIf="PreCloserDetails?.LoanCloserRequestBy_Name">
                <td style="padding: 5px">
                  <h6 style="font-size: 12px">Loan Closer Request By</h6>
                </td>
                <td style="padding: 5px">
                  <p style="font-size: 12px">
                    {{ PreCloserDetails?.LoanCloserRequestBy_Name }} on
                    {{
                      PreCloserDetails?.LoanCloser_RequestOn
                        | date : "dd/MM/yyyy hh:mm aa"
                    }}
                  </p>
                </td>
              </tr>
              <tr *ngIf="PreCloserDetails?.WaiverGivenBy_Name">
                <td style="padding: 5px">
                  <h6 style="font-size: 12px">Waiver Given By</h6>
                </td>
                <td style="padding: 5px">
                  <p style="font-size: 12px">
                    {{ PreCloserDetails?.WaiverGivenBy_Name }} on
                    {{
                      PreCloserDetails?.Waiver_GivenOn
                        | date : "dd/MM/yyyy hh:mm aa"
                    }}
                  </p>
                </td>
              </tr>
              <tr *ngIf="PreCloserDetails?.LoanCloserBy_Name">
                <td style="padding: 5px">
                  <h6 style="font-size: 12px">Loan Closed By</h6>
                </td>
                <td style="padding: 5px">
                  <p style="font-size: 12px">
                    {{ PreCloserDetails?.LoanCloserBy_Name }} on
                    {{
                      PreCloserDetails?.LoanClosed_Date
                        | date : "dd/MM/yyyy hh:mm aa"
                    }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          *ngIf="
            (accountName_Not_Bind != '' ||
              Waiver_Account_Id == 0 ||
              Waiver_Account_Id == '') &&
            PreCloserDetails.Closer_RequestStatus != 'Closed'
          "
          class="row col-md-12 m-0"
        >
          <div class="col-md-9">
            <p style="color: #db1f1f; font-weight: bold">
              This Loan Product Is Not Bind of thease Accounts
              {{ accountName_Not_Bind != "" ? accountName_Not_Bind : ""
              }}{{
                Waiver_Account_Id == 0 || Waiver_Account_Id == ""
                  ? "  Waiver Account"
                  : ""
              }}.Please Bind Charges Head.
            </p>
          </div>
          <div class="col-md-3 justify-content-end">
            <button
              type="button"
              (click)="onCancel()"
              class="btn btn-light font-size-12 ml-2"
            >
              Cancel
            </button>
          </div>
        </div>
        <div
          *ngIf="
            PreCloserDetails.hasOwnProperty('IsEligbleForPreCloser') &&
            !PreCloserDetails.IsEligbleForPreCloser &&
            !showSpinner
          "
        >
          <p style="color: #db1f1f; text-align: right; font-weight: bold">
            This Loan Is Not Eligible For Pre Closer
          </p>
        </div>
        <div
          *ngIf="
            PreCloserDetails.Closer_RequestStatus == 'Closed' && !showSpinner
          "
        >
          <p style="color: #db1f1f; font-weight: bold">
            This Loan Has Been Already Closed.
          </p>
        </div>

        <div *ngIf="isExpired && !showSpinner">
          <p style="color: #db1f1f; font-weight: bold">
            Pre-Closer Effective Date Is Expired. To Create New Request, Please
            Delete This Request
          </p>
        </div>
      </div>
      <div *ngIf="isMaker == 'N' && LoanAllDetail[0].NOC_Block_Remark != ''">
        <p style="color: #db1f1f; font-weight: bold">
          {{ LoanAllDetail[0].NOC_Block_Remark }}
        </p>
      </div>
      <div
        class="row col-md-12 m-0 mt-5 justify-content-end"
        *ngIf="
          (accountName_Not_Bind == '' && Waiver_Account_Id > 0) ||
          PreCloserDetails.Closer_RequestStatus == 'Closed'
        "
      >
        <button
          type="button"
          *ngIf="
            PreCloserDetails.IsEligbleForPreCloser &&
            PreCloserDetails.Closer_RequestStatus != 'Closed' &&
            isMyCloserRequest
          "
          (click)="onDeleteCloserRequest()"
          class="btn font-size-12 button-btn"
          mat-raised-button
          color="primary"
        >
          Delete Request
        </button>

        <ng-conatiner
          *ngIf="
            PreCloserDetails.IsEligbleForPreCloser &&
            PreCloserDetails.Closer_RequestStatus !== 'Closed' &&
            !isExpired
          "
        >
          <button
            type="button"
            (click)="onClickPrintStatement()"
            mat-raised-button
            color="primary"
            class="btn font-size-12 button-btn ml-2"
          >
            Print Statement
          </button>
          <button
            type="button"
            *ngIf="
              PreCloserDetails.Closer_RequestStatus == 'Created' &&
              totalCalculator.totalWaiverAmount == 0
            "
            (click)="onSaveAsDraft()"
            class="btn font-size-12 button-btn ml-2"
            mat-raised-button
            color="primary"
          >
            Save As Draft
          </button>

          <button
            type="button"
            *ngIf="
              !isShowForward &&
              PreCloserDetails.Closer_RequestStatus !== 'Closed' &&
              PreCloserDetails.Closer_RequestStatus !== 'Waiver Pending' &&
              PreCloserDetails.Closer_RequestStatus !== 'Waiver Rejected' &&
              (PreCloserDetails.Closer_RequestStatus == 'Created' ||
                PreCloserDetails.Closer_RequestStatus == 'Waiver Approved')
            "
            (click)="onPreClosureClose()"
            class="ml-2 btn font-size-12 button-btn"
            mat-raised-button
            color="primary"
          >
            Loan Closed
          </button>
          <button
            type="button"
            *ngIf="
              isShowForward &&
              PreCloserDetails.Closer_RequestStatus !== 'Closed' &&
              PreCloserDetails.Closer_RequestStatus !== 'Waiver Pending' &&
              PreCloserDetails.Closer_RequestStatus !== 'Waiver Approved'
            "
            (click)="onForwardToSeniorWaiverApproval()"
            class="ml-2 btn font-size-12 button-btn"
            mat-raised-button
            color="primary"
          >
            Forward To Senior For Waiver Approval
          </button>
          <button
            type="button"
            *ngIf="
              PreCloserDetails.Closer_RequestStatus == 'Waiver Pending' &&
              isWavierGivenByMe
            "
            (click)="onWaieverApproved()"
            class="ml-2 btn font-size-12 button-btn"
            mat-raised-button
            color="primary"
          >
            Waiver Approved
          </button>
          <button
            type="button"
            *ngIf="
              PreCloserDetails.Closer_RequestStatus == 'Waiver Pending' &&
              isWavierGivenByMe
            "
            (click)="onWaieverReject()"
            class="ml-2 btn font-size-12 button-btn"
            mat-raised-button
            color="primary"
          >
            Waiver Rejected
          </button>
        </ng-conatiner>
        <button
          type="button"
          (click)="onCancel()"
          class="btn font-size-12 button-btn ml-2"
          mat-raised-button
        >
          Cancel
        </button>
        <app-printnocletter
          *ngIf="
            isMaker == 'N' &&
            LoanAllDetail[0].LMS_Is_Noc_Block == 0 &&
            ((LoanAllDetail[0].Master_Is_NOC_Block == 1 &&
              LoanAllDetail[0].LiveLoan == 0) ||
              LoanAllDetail[0].Master_Is_NOC_Block == 0)
          "
          [LoanId]="LoanId"
          [index]="0"
        ></app-printnocletter>
      </div>
    </ng-container>
  </div>
</div>

<div
  class="modal fade in"
  id="ViewLoanStatementPreCloserModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div class="modal-header" cdkDragHandle style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          View loan statement
        </h6>
        <button
          type="button"
          (click)="onCloseViewLoanStatement()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <app-lms-loanStatement
          [isShowHeader]="false"
          [loanSummary]="loanSummary"
        ></app-lms-loanStatement>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="PrintLoanStatementPreCloserModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Print loan statement
        </h6>
        <button
          type="button"
          (click)="onClosePrintLoanStatement()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body" *ngIf="loanStatement" id="pdfPrintReport">
        <div style="padding: 50px">
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              margin-right: -15px;
              margin-left: -15px;
            "
          >
            <div style="width: 70%; float: left">
              <p class="printStatemant_P">To,</p>
              <p></p>
              <p class="printStatemant_P">{{ CustomerName }}</p>
              <p [innerHtml]="CustomerAddress"></p>
              <p style="text-align: left; font-weight: 600">
                Subject :- Pre-Payment outstanding of your
                {{ LoanAllDetail[0].Product }} Loan Account No :
                {{ LoanAllDetail[0].LoanNo }}
              </p>
              <p>Dear {{ CustomerName }},</p>
            </div>
            <div style="width: 30%; float: left; text-align: right">
              <!-- <img src="../../../assets/images/logo.png" alt="" style="width: 100px;"> -->
              <img src="{{ BasePath }}logo.png" alt="" style="width: 100px" />
              <p>
                {{ data.FullName }}<br />
                Date : {{ today | date : "dd/MM/yyyy" }} {{ time }}
              </p>
            </div>
          </div>
          <br />

          <p>
            We value your relationship with {{ loanStatement.Item1[0].Comapny }}
            In response to your request for Pre-Payment outstanding of your
            above-mentioned Loan No.Please Find the detail as mentioned Below:
          </p>
          <div>
            <table style="width: 60%; margin-bottom: 10px">
              <tbody>
                <tr style="background: #120eeb29">
                  <td
                    style="
                      border: 1px solid #ddd;
                      padding: 5px 10px 2px;
                      font-weight: bold;
                    "
                  >
                    Account Head
                  </td>
                  <td
                    style="
                      border: 1px solid #ddd;
                      padding: 5px 10px 2px;
                      text-align: right;
                      font-weight: bold;
                    "
                  >
                    Balance (₹)
                  </td>
                </tr>
                <tr
                  *ngFor="
                    let preCloserData of PrintCloserMethodList;
                    let i = index
                  "
                >
                  <td style="border: 1px solid #ddd; padding: 5px 10px 2px">
                    {{ preCloserData.ChagesHead }}
                  </td>
                  <td
                    style="
                      border: 1px solid #ddd;
                      padding: 5px 10px 2px;
                      text-align: right;
                    "
                  >
                    {{ preCloserData.Balance }}
                  </td>
                </tr>
                <tr style="background: #120eeb29">
                  <td style="border: 1px solid #ddd; padding: 5px 10px 2px">
                    <b>Total</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #ddd;
                      padding: 5px 10px 2px;
                      text-align: right;
                    "
                  >
                    <b>{{ totalCalculator.totalBalance?.toFixed(2) }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div *ngIf="LoanChargesRecoverableList">
            <p style="text-align: left; font-weight: 600">
              Detail Of Loan Charges Recoverable
            </p>
            <table style="width: 55%; margin-bottom: 10px">
              <tbody>
                <tr style="background: #120eeb29">
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>Head</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>Amount</b>
                  </td>
                </tr>
                <tr
                  *ngFor="
                    let LoanCharges of LoanChargesRecoverableList;
                    let i = index
                  "
                  style="height: 43px"
                >
                  <td style="border: 1px solid #b5b0b0; padding: 5px">
                    {{ LoanCharges.ChagesHead }}
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    {{ LoanCharges.Amount }}
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid #b5b0b0; padding: 5px">
                    <b>Total</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>{{ LoanChargesRecoverable.Due_Amount?.toFixed(2) }}</b>
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid #b5b0b0; padding: 5px">
                    <b>Received</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>{{
                      LoanChargesRecoverable.Received_Amount?.toFixed(2)
                    }}</b>
                  </td>
                </tr>
                <tr style="background: #120eeb29">
                  <td style="border: 1px solid #b5b0b0; padding: 5px">
                    <b>Balance</b>
                  </td>
                  <td
                    style="
                      border: 1px solid #b5b0b0;
                      padding: 5px;
                      text-align: center;
                    "
                  >
                    <b>{{ LoanChargesRecoverable_Balance?.toFixed(2) }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p style="text-align: left; font-weight: 600">Kindly note that:</p>
          <p>
            1. Above calculation assumes that your last instalment has been
            cleared.
          </p>
          <p>
            2. We have taken the date of prepayment as
            <b>{{
              PreCloserDetails.LoanCloser_EffectiveDate | date : "dd/MM/yyyy"
            }}</b
            >. For each day beyond this date as additional interest will be
            charged.
          </p>
          <p>
            3. On prepayment of the loan, the company shall try to prevent
            payment of the subsequence month's instalment. As a precaution, we
            advise you to make a stop payment request for your next month's
            instalment. In case the next month's instalment is debited from your
            account, the amount will be refunded, subject to clearance.
          </p>
          <p>
            4. The above-mentioned amount is valid subject to clearance of all
            the cheques / instalments till date.
          </p>
          <p>
            5. Prepayment charges is applicable on the outstanding amount of the
            facility or total interest (unexpired period interest and
            outstanding interest) whichever is lower
          </p>
          <p>
            6. To release the Collateral/NOC of this loan, please close all the
            loan accounts which are linked to this collateral.
          </p>
          <p>
            7. Following documents would be refunded to you within 30 days of
            Prepayment of your Loan.
          </p>
          <p class="ml-4">
            &bull; No Objection Certificate and The document of Title in respect
            of the immovable Property under Pledge (if any)
          </p>
          <p class="ml-4">
            &bull; Subject to all other obligation towards
            {{ loanStatement.Item1[0].Comapny }} is satisfied including cross
            liability.
          </p>
          <p>
            8. This FC outstanding is having charges accrued up till
            <b>{{
              PreCloserDetails.LoanCloser_EffectiveDate | date : "dd/MM/yyyy"
            }}</b
            >.
          </p>
          <p>
            9. All charges which got accrued today does not include in this FC
            and has to be paid in addition.
          </p>
          <p>
            If you require any further details of your LOAN account, please
            contact us on the Telephone Number given below. Our Customer Service
            Representative would be glad to assist you. Thanking you and your
            patronage in future.
          </p>

          <p>
            <B>{{ loanStatement.Item1[0].Comapny }}</B
            ><br />
            {{ loanStatement.Item1[0].ComapnyAddress }}<br />
            {{ loanStatement.Item1[0].ComapnyPhoneno }}<br />
            {{ loanStatement.Item1[0].ComapnyEmail }}<br />
            {{ loanStatement.Item1[0].ComapnyGSTNo }}
          </p>
          <p class="text-center">
            No signature is required as this is system generated statement
          </p>
        </div>
      </div>
      <div style="width: 100%; text-align: right; padding: 20px">
        <button class="btn font-size-12 button-btn" (click)="downloadPdf()">
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Print
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="loanClosed"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Balance exist in these charges head,to be settled before closed
        </h6>
        <button
          type="button"
          (click)="onCloseDueListDetails()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body" style="border: none">
        <div class="table-responsive">
          <div class="table-responsive mt-3">
            <table
              mat-table
              matSort
              [dataSource]="dataSource"
              id="loanPreCloserModal"
              style="max-height: 5px"
            >
              <ng-container matColumnDef="HeadId">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
                <td mat-cell *matCellDef="let row; let i = index">
                  {{ i + 1 }}
                </td>
                <th mat-footer-cell *matFooterCellDef></th>
              </ng-container>

              <ng-container matColumnDef="ChagesHead">
                <th
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  style="height: 20px !important"
                >
                  Charges Head
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ChagesHead }}
                </td>
                <th mat-footer-cell *matFooterCellDef>Total:</th>
              </ng-container>
              <ng-container matColumnDef="TranTypeDR">
                <th
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  style="height: 20px !important"
                >
                  Debit
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.TranTypeDR }}
                </td>
                <th mat-footer-cell *matFooterCellDef>
                  <!-- {{calculateChargesTranTypeDR("OD")}} -->
                  {{ calculateChargesDR() }}
                </th>
              </ng-container>
              <ng-container matColumnDef="TranTypeCR">
                <th
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  style="height: 20px !important"
                >
                  Credit
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.TranTypeCR }}
                </td>
                <th mat-footer-cell *matFooterCellDef>
                  {{ calculateChargesCR() }}
                </th>
              </ng-container>
              <ng-container matColumnDef="TranTypeTotal">
                <th
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  style="height: 20px !important"
                >
                  Balance
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.TranTypeTotal }}
                </td>
                <th mat-footer-cell *matFooterCellDef>
                  {{ calculateChargesTotal() }}
                </th>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="
                  displayedColumnsModelForLoanCloser;
                  sticky: true
                "
                style="height: 30px !important"
                class="sticky-footer fontcolor"
              ></tr>
              <tr
                style="height: 30px !important"
                mat-row
                *matRowDef="
                  let row;
                  columns: displayedColumnsModelForLoanCloser
                "
              ></tr>
              <tr
                mat-footer-row
                class="sticky-footer fontcolor"
                *matFooterRowDef="
                  displayedColumnsModelForLoanCloser;
                  sticky: true
                "
                style="background: #4dc3a3; height: 30px !important"
              ></tr>
            </table>
          </div>

          <div
            class="col-md-12"
            style="display: flex; align-items: center; justify-content: end"
          >
            <div class="col-md-2">
              <button
                class="mt-3 btn font-size-12 button-btn"
                (click)="onCancelLoanPreCloserModel()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
