<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"></app-mat-spinner-overlay>

  <div class="d-flex align-items-center formborder">
    <div class="col-md-2">
      <span>Claim Status</span>
      <select
        name="claimStatus"
        id="claimStatus"
        [(ngModel)]="claimStatus"
        class="form-control input-text-css"
        (change)="getDeathTheftList()"
      >
        <option value="Pending">Pending</option>
        <option value="Settled">Settled</option>
      </select>
    </div>

    <div class="col-md-4" [hidden]="!dataSource">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-md-3 p-1 text-right">
      <button
        type="button"
        class="mt-3 btn font-size-12 button-btn float-right"
        (click)="newDeathTheftClaim()"
      >
        New Claim
      </button>
    </div>
  </div>

  <div class="mt-3" [hidden]="!dataSource">
    <div class="">
      <div class="table-responsive" style="overflow: auto; height: 65vh">
        <table
          mat-table
          class="table-bordered"
          matSort
          [dataSource]="dataSource"
          matTableExporter
          #exporter="matTableExporter"
          id="deathTheftLoss"
        >
          <ng-container matColumnDef="Id">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let row; let i = index">
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="LoanAcNo">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Loan No</th>
            <td mat-cell *matCellDef="let row">{{ row.LoanAcNo }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Customer_Name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Customer Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Customer_Name }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="InsuranceName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Insurance Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.InsuranceName }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="ClaimType">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>ClaimType</th>
            <td mat-cell *matCellDef="let row">{{ row.ClaimType }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Police_Station">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Police Station
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Police_Station }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Claim_Status">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Claim Status
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Claim_Status }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Loss_Theft_Date">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Loss/Theft Date
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Loss_Theft_Date }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="FIR_Date">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>FIR Date</th>
            <td mat-cell *matCellDef="let row">{{ row.FIR_Date }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="FIR_No">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>FIR No</th>
            <td mat-cell *matCellDef="let row">{{ row.FIR_No }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Death_Cerificate_Doc">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Death Cerificate Doc
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Death_Cerificate_Doc }}
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="FIR_Copy_Doc">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>FIR Copy</th>
            <td mat-cell *matCellDef="let row">{{ row.FIR_Copy_Doc }}</td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <ng-container matColumnDef="Action">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let row">
              <i
                class="fas fa-lg fa-edit margin-right-4xs cursor-style"
                aria-label="true"
                (click)="editDeathLossClaim(row)"
              ></i>

              <i
                class="fa fa-trash style-delete"
                (click)="deleteDeathTheftLoss(row)"
                aria-label="true"
              ></i>
            </td>
            <th mat-footer-cell *matFooterCellDef></th>
          </ng-container>

          <!-- <ng-container matColumnDef="Action">
            <th
              mat-header-cell
              class="grid-header j-c-center"
              *matHeaderCellDef
            >
              Action
            </th>
            <td mat-cell *matCellDef="let row" class="grid-cell j-c-center">
              <i
                class="fas fa-lg fa-edit margin-right-4xs cursor-style"
                aria-label="true"
                (click)="editDeathLossClaim(row)"
              ></i>
            </td>
          </ng-container> -->
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="sticky-footer fontcolor"
            style="background: #4dc3a3"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <!-- (click)="highlightRow(row)"
          [class.highlight]="row.Loan_Id == selectedId" -->
          <tr
            mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="row m-0 ml-3" [hidden]="!dataSource">
    <div class="col-md-4 mt-3">
      <button
        class="mt-2 btn font-size-12 button-btn"
        (click)="exportexcelNew()"
      >
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Export
      </button>
    </div>
    <div class="col-md-8 mt-3">
      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </div>
</div>

<table
  id="death_theft_table"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Insurance Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Claim Type
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loss Theft Date
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      FIR Date
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      FIR No
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Police Station
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Claim Status
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Death Cerificate Doc
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      FIR Copy Doc
    </th>
  </tr>
  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Customer_Name }}</td>
    <td>{{ x.InsuranceName }}</td>
    <td>{{ x.ClaimType }}</td>
    <td>{{ x.Loss_Theft_Date }}</td>
    <td>{{ x.FIR_Date }}</td>
    <td>{{ x.FIR_No }}</td>
    <td>{{ x.Police_Station }}</td>
    <td>{{ x.Claim_Status }}</td>
    <td>{{ x.Death_Cerificate_Doc }}</td>
    <td>{{ x.FIR_Copy_Doc }}</td>
  </tr>
</table>

<div
  class="modal fade in"
  id="SearchLoanPreCloserModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Search Loan For Claim
        </h6>
        <button
          type="button"
          (click)="onCloseSearchLoanPreCloser()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #f="ngForm"
          (keydown.enter)="onSearchCustomerDetail()"
          novalidate
          class="row m-0 col-md-12 p-0 formborder"
        >
          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-3">
              <span>Loan No.</span>
              <input
                name="SLoanNo"
                [(ngModel)]="Customer.LoanNo"
                id="SLoanNo"
                placeholder="Loan No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Application No.</span>
              <input
                name="SApplicationNo"
                [(ngModel)]="Customer.ApplicationNo"
                id="SApplicationNo"
                placeholder="Application No."
                class="form-control input-text-css"
              />
            </div>

            <div class="col-md-3">
              <span>Customer Name</span>
              <input
                name="CustomerName"
                [(ngModel)]="Customer.CustomerName"
                id="CustomerName"
                placeholder="Customer Name"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Customer Phone</span>
              <input
                name="CustomerPhone"
                [(ngModel)]="Customer.CustomerPhone"
                id="CustomerPhone"
                placeholder="Customer Phone"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>PAN Card</span>
              <input
                name="PANCard"
                [(ngModel)]="Customer.PANCard"
                id="PANCard"
                placeholder="PAN Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Passport No.</span>
              <input
                name="Passport"
                [(ngModel)]="Customer.Passport"
                id="Passport"
                placeholder="Passport No."
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Voter ID Card</span>
              <input
                name="VoterID"
                [(ngModel)]="Customer.VoterID"
                id="VoterID"
                placeholder="Voter ID Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Driving License</span>
              <input
                name="DL"
                [(ngModel)]="Customer.DL"
                id="DL"
                placeholder="Driving License"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span>Aadhaar Card</span>
              <input
                name="Aadhaar"
                [(ngModel)]="Customer.Aadhaar"
                id="Aadhaar"
                placeholder="Aadhaar Card"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-9 row m-0 justify-content-end align-items-end">
              <button
                type="button"
                (click)="onSearchCustomerDetail()"
                class="mt-3 btn font-size-12 button-btn"
              >
                Search
              </button>
            </div>
          </div>
        </form>
        <div class="table-responsive mt-3">
          <mat-table
            [dataSource]="dataSourceCustomer"
            #sortCustomerList="matSort"
            matSort
            id="exportReport"
          >
            <ng-container matColumnDef="LoanId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 50px"
                *matHeaderCellDef
                >#
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row; let i = index"
                class="grid-cell"
                style="max-width: 50px; max-height: 5px"
              >
                {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="LoanNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 150px"
                *matHeaderCellDef
                >Loan No
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 150px"
                >{{ row.LoanNo }}</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="ApplicationNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 150px"
                *matHeaderCellDef
              >
                Aplication No</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 150px"
                >{{ row.ApplicationNo }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Product">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Product</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Product
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Branch">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Branch</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.Branch
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="CustomertName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customert Name</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.CustomertName
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="FatherName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Father Name</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">{{
                row.FatherName
              }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="PhoneNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 150px"
                *matHeaderCellDef
                >Phone No
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 150px"
              >
                <div *ngIf="currentUser.IsMaskingMobile">
                  {{ row.PhoneNo | mask }}
                </div>

                <div *ngIf="!currentUser.IsMaskingMobile">
                  {{ row.PhoneNo }}
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Loan_Date">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 150px"
                *matHeaderCellDef
                >Loan Date
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                style="max-width: 150px"
                >{{ row.Loan_Date }}</mat-cell
              >
            </ng-container>

            <ng-container matColumnDef="EditAction">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                style="max-width: 100px"
                *matHeaderCellDef
                >View Detail</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                class="grid-cell j-c-center"
                style="max-width: 100px"
              >
                <i
                  class="fa fa-arrow-circle-right arrow-circle action-btn"
                  (click)="goToDetail(row)"
                  aria-label="true"
                ></i>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedCustomerColumns"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedCustomerColumns"
            ></mat-row>
          </mat-table>
          <mat-paginator
            #paginatorRef
            #paginatorCustomerList
            [pageSizeOptions]="[20, 50]"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="claimFormModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Claim Form
        </h6>
        <button
          type="button"
          (click)="onCloseClaimForm()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #f="ngForm"
          (keydown.enter)="onSearchCustomerDetail()"
          novalidate
          class="row m-0 col-md-12 p-0 formborder"
        >
          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-2">
              <span class="required-lable"> Claim Type </span>
              <select
                name="claimType"
                id="claimType"
                [(ngModel)]="claimType"
                class="form-control input-text-css"
                (change)="getInsuranceDetails()"
              >
                <option value="">Select Claim Type</option>
                <option value="Death">Death</option>
                <option value="Tefth">Theft</option>
              </select>
            </div>

            <div class="col-md-2">
              <span class="required-lable">Insurance</span>
              <select
                name="Insurance"
                id="Insurance"
                [(ngModel)]="Insurance"
                class="form-control input-text-css"
              >
                <option value="">Select Insurance</option>
                <option
                  *ngFor="let option of filteredInsuranceDetails"
                  [value]="option.Int_Id"
                >
                  {{ option.Name }}
                </option>
              </select>
            </div>

            <div class="datepicker_feild" style="padding-left: 15px !important">
              <span>Theft/Death Date</span>
              <input
                [matDatepicker]="picker"
                dateConvert
                placeholder="DD/MM/YYYY"
                name="theftDeathDate"
                id="theftDeathDate"
                #reftheftDeathDate="ngModel"
                class="form-control input-text-css"
                [(ngModel)]="theftDeathDate"
                [ngClass]="{
                  'is-invalid': f.submitted && reftheftDeathDate.invalid,
                  'alert-warning':
                    reftheftDeathDate.invalid &&
                    (reftheftDeathDate.dirty ||
                      reftheftDeathDate.touched ||
                      reftheftDeathDate.untouched)
                }"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
                style="top: 24px"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>

            <div class="datepicker_feild" style="padding-left: 15px !important">
              <span>FIR Date</span>
              <input
                [matDatepicker]="picker2"
                dateConvert
                placeholder="DD/MM/YYYY"
                name="firDate"
                id="firDate"
                #reffirDate="ngModel"
                class="form-control input-text-css"
                [(ngModel)]="firDate"
                [ngClass]="{
                  'is-invalid': f.submitted && reffirDate.invalid,
                  'alert-warning':
                    reffirDate.invalid &&
                    (reffirDate.dirty ||
                      reffirDate.touched ||
                      reffirDate.untouched)
                }"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
                style="top: 24px"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </div>

            <div class="col-md-3">
              <span>FIR No.</span>
              <input
                name="firNumber"
                [(ngModel)]="firNumber"
                id="firNumber"
                placeholder="FIR No."
                class="form-control input-text-css"
              />
            </div>

            <div class="col-md-3">
              <span>Police Station</span>
              <input
                name="policeStation"
                [(ngModel)]="policeStation"
                id="policeStation"
                placeholder="Police Station"
                class="form-control input-text-css"
              />
            </div>

            <div class="col-md-2">
              <span>Claim Status</span>
              <select
                name="claimStatusModel"
                id="claimStatusModel"
                [(ngModel)]="claimStatusModel"
                class="form-control input-text-css"
              >
                <option value="Pending">Pending</option>
                <option value="Settled">Settled</option>
              </select>
            </div>

            <div class="col-md-3 mt-4">
              <!-- xz    -->
              <mat-checkbox
                [checked]="stopInstalmentNumeric === 1"
                (change)="onCheckboxChange($event)"
                [(ngModel)]="stopInstalment"
                id="stopInstalment"
                name="stopInstalment"
              >
                Instalment Stop
              </mat-checkbox>
            </div>

            <div class="col-md-3 align-items-center">
              <span class="">Death Certificate </span>
              <p class="m-0 mt-2 row">
                <!-- Upload Icon -->
                <i
                  class="fa fa-upload ml-2"
                  *ngIf="!deathCertificateModel.DeathCertificateDoc"
                  (click)="DeathCertificateDoc.click()"
                  aria-hidden="true"
                ></i>

                <!-- Uploaded File Display -->
                <ng-container *ngIf="deathCertificateModel.DeathCertificateDoc">
                  <div>
                    <a
                      href="{{ docBaseUrl }}{{ applicatioNumber }}/{{
                        deathCertificateModel.DeathCertificateDoc
                      }}"
                      target="_blank"
                      style="cursor: pointer"
                    >
                      <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i
                      >{{ deathCertificateModel.DeathCertificateDoc }}
                    </a>
                    <i
                      class="fa fa-times ml-2"
                      aria-hidden="true"
                      style="cursor: pointer"
                      (click)="removeDeathCertificate(DeathCertificateDoc)"
                    ></i>
                  </div>
                </ng-container>
              </p>

              <!-- Hidden File Input -->
              <input
                #DeathCertificateDoc
                type="file"
                [multiple]="false"
                accept="application/pdf,application/vnd.ms-excel"
                (change)="
                  fileChangeListenerDeathCertificate(DeathCertificateDoc.files)
                "
                style="display: none"
              />
            </div>

            <div class="col-md-3 align-items-center">
              <span class="">FIR Report </span>
              <p class="m-0 mt-2 row">
                <!-- Upload Icon -->
                <i
                  class="fa fa-upload ml-2"
                  *ngIf="!firReportModel.FIRReportDoc"
                  (click)="FIRReportDoc.click()"
                  aria-hidden="true"
                ></i>

                <!-- Uploaded File Display -->
                <ng-container *ngIf="firReportModel.FIRReportDoc">
                  <div>
                    <a
                      href="{{ docBaseUrl }}{{ applicatioNumber }}/{{
                        firReportModel.FIRReportDoc
                      }}"
                      target="_blank"
                      style="cursor: pointer"
                    >
                      <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i
                      >{{ firReportModel.FIRReportDoc }}
                    </a>
                    <i
                      class="fa fa-times ml-2"
                      aria-hidden="true"
                      style="cursor: pointer"
                      (click)="removeFIRReport(FIRReportDoc)"
                    ></i>
                  </div>
                </ng-container>
              </p>

              <!-- Hidden File Input -->
              <input
                #FIRReportDoc
                type="file"
                [multiple]="false"
                accept="application/pdf,application/vnd.ms-excel"
                (change)="fileChangeListenerFIRReport(FIRReportDoc.files)"
                style="display: none"
              />
            </div>

            <div class="col-md-3 mt-2">
              <button
                *ngIf="editCliamForm == 'Save'"
                type="button"
                (click)="SaveDeathTheftDetails()"
                class="btn font-size-12 button-btn"
                [disabled]="!f.form.valid"
              >
                Save
              </button>

              <button
                *ngIf="editCliamForm == 'Update'"
                type="button"
                (click)="updateDeathTheftLoss()"
                class="btn font-size-12 button-btn"
                [disabled]="!f.form.valid"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
