<div class="row m-0">
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div class="col-md-12">
    <form
      #f="ngForm"
      (ngSubmit)="onSaveAccount()"
      novalidate
      class="formborder"
    >
      <div class="row mt-2">
        <div class="col-md-4">
          <span class="required-lable">Account Name</span>
          <input
            [disabled]="!isEditMode"
            required
            type="text"
            #refAccount_Name="ngModel"
            maxlength="200"
            placeholder="Account Name"
            [ngClass]="{
              'is-invalid': f.submitted && refAccount_Name.invalid,
              'alert-warning':
                refAccount_Name.invalid &&
                (refAccount_Name.dirty ||
                  refAccount_Name.touched ||
                  refAccount_Name.untouched)
            }"
            [(ngModel)]="accountModel.Account.Account_Name"
            name="Account_Name"
            id="Account_Name"
            class="form-control input-text-css"
          />
        </div>
        <div class="col-md-4">
          <span class="">Alias</span>
          <input
            [disabled]="!isEditMode"
            type="text"
            #refAccount_AliasName="ngModel"
            maxlength="200"
            placeholder="Alias"
            [ngClass]="{
              'is-invalid': f.submitted && refAccount_AliasName.invalid,
              'alert-warning':
                refAccount_AliasName.invalid &&
                (refAccount_AliasName.dirty ||
                  refAccount_AliasName.touched ||
                  refAccount_AliasName.untouched)
            }"
            [(ngModel)]="accountModel.Account.Account_AliasName"
            name="Account_AliasName"
            id="Account_AliasName"
            class="form-control input-text-css"
          />
        </div>
        <div class="col-md-4">
          <span class="required-lable">Select Group</span>

          <select
            name="Account_GroupId"
            id="Account_GroupId"
            required
            #refAccount_GroupId="ngModel"
            [ngClass]="{
              'is-invalid': f.submitted && refAccount_GroupId.invalid,
              'alert-warning':
                refAccount_GroupId.invalid &&
                (refAccount_GroupId.dirty ||
                  refAccount_GroupId.touched ||
                  refAccount_GroupId.untouched)
            }"
            [(ngModel)]="accountModel.Account.Account_GroupId"
            class="form-control input-text-css"
            (change)="setGroupTag()"
            [disabled]="isGroupDisable || !isEditMode"
          >
            <option value="">Select Group</option>
            <option *ngFor="let data of groupDropdown" [value]="data.GroupId">
              {{ data.Group_Name }}
              <ng-container *ngIf="data.Group_Tag"
                >({{ data.Group_Tag }})</ng-container
              >
            </option>
          </select>

          <div *ngIf="isGroupDisable">
            <p style="color: red">Transaction exists before Freeze date.</p>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4">
          <span class="required-lable">Open Date</span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="Account_OpenDate"
              id="Account_OpenDate"
              #refAccount_OpenDate="ngModel"
              [(ngModel)]="accountModel.Account.Account_OpenDate"
              required
              [ngClass]="{
                'is-invalid': f.submitted && refAccount_OpenDate.invalid,
                'alert-warning':
                  refAccount_OpenDate.invalid &&
                  (refAccount_OpenDate.dirty ||
                    refAccount_OpenDate.touched ||
                    refAccount_OpenDate.untouched)
              }"
              class="form-control input-text-css"
              [disabled]="!isEditMode"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
          <!--<input name="Account_OpenDate"
                 id="Account_OpenDate"
                 #refAccount_OpenDate="ngModel"
                 [ngClass]="{
              'is-invalid': f.submitted && refAccount_OpenDate.invalid,
              'alert-warning':
                refAccount_OpenDate.invalid &&
                (refAccount_OpenDate.dirty ||
                  refAccount_OpenDate.touched ||
                  refAccount_OpenDate.untouched)
            }"
                 [(ngModel)]="accountModel.Account.Account_OpenDate"
                 [(bsValue)]="accountModel.Account.Account_OpenDate"
                 value="{{
              accountModel.Account.Account_OpenDate | date : 'dd/MM/yyyy'
            }}"
                 placeholder="DD/MM/YYYY"
                 class="form-control input-text-css"
                 bsDatepicker
                 required />-->
        </div>
        <div class="col-md-4">
          <span class="">Close Date</span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker2"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="Account_CloseDate"
              id="Account_CloseDate"
              #refAccount_CloseDate="ngModel"
              [ngClass]="{
                'is-invalid': f.submitted && refAccount_CloseDate.invalid,
                'alert-warning':
                  refAccount_CloseDate.invalid &&
                  (refAccount_CloseDate.dirty ||
                    refAccount_CloseDate.touched ||
                    refAccount_CloseDate.untouched)
              }"
              [(ngModel)]="accountModel.Account.Account_CloseDate"
              class="form-control input-text-css"
              [disabled]="!isEditMode"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </div>
          <!-- <input
            name="Account_CloseDate"
            id="Account_CloseDate"
            #refAccount_CloseDate="ngModel"
            [ngClass]="{
              'is-invalid': f.submitted && refAccount_CloseDate.invalid,
              'alert-warning':
                refAccount_CloseDate.invalid &&
                (refAccount_CloseDate.dirty ||
                  refAccount_CloseDate.touched ||
                  refAccount_CloseDate.untouched)
            }"
            [(ngModel)]="accountModel.Account.Account_CloseDate"
            [(bsValue)]="accountModel.Account.Account_CloseDate"
            value="{{
              accountModel.Account.Account_CloseDate | date : 'dd/MM/yyyy'
            }}"
            placeholder="DD/MM/YYYY"
            class="form-control input-text-css"
            bsDatepicker
          /> -->
        </div>
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-8">
              <span class="required-lable">Opening Balance</span>
              <input
                required
                type="number"
                min="0"
                #refAccount_OpeningBalance="ngModel"
                placeholder="Opening Balance"
                [ngClass]="{
                  'is-invalid':
                    f.submitted && refAccount_OpeningBalance.invalid,
                  'alert-warning':
                    refAccount_OpeningBalance.invalid &&
                    (refAccount_OpeningBalance.dirty ||
                      refAccount_OpeningBalance.touched ||
                      refAccount_OpeningBalance.untouched)
                }"
                [(ngModel)]="accountModel.Account.Account_OpeningBalance"
                name="Account_OpeningBalance"
                id="Account_OpeningBalance"
                class="form-control input-text-css"
                [disabled]="!isEditMode"
              />
            </div>
            <div class="col-md-4">
              <span class="required-lable">Debit/Credit</span>
              <select
                name="Account_DebitCredit"
                required
                id="Account_DebitCredit"
                #refAccount_DebitCredit="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refAccount_DebitCredit.invalid,
                  'alert-warning':
                    refAccount_DebitCredit.invalid &&
                    (refAccount_DebitCredit.dirty ||
                      refAccount_DebitCredit.touched ||
                      refAccount_DebitCredit.untouched)
                }"
                [(ngModel)]="accountModel.Account.Account_DebitCredit"
                class="form-control input-text-css"
                [disabled]="!isEditMode"
              >
                <option value="">Select</option>
                <option value="DR">DR</option>
                <option value="CR">CR</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-2">
          <mat-checkbox
            name="Account_IsLoanChargeable"
            [(ngModel)]="accountModel.Account.Account_IsLoanChargeable"
            [disabled]="
              !accountModel.Account.IsEditLoanChargeable || !isEditMode
            "
            class="mt-3"
          >
            Loan Chargeable
          </mat-checkbox>
        </div>
        <div class="col-md-2">
          <mat-checkbox
            name="Account_IsGSTEnable"
            [(ngModel)]="accountModel.Account.Account_IsGSTEnable"
            class="mt-3"
            (change)="handleIsGSTEnableChange()"
            [disabled]="!isEditMode"
          >
            GST Enable
          </mat-checkbox>
        </div>
        <div class="col-md-2" *ngIf="accountModel.Account.AccountId > 0">
          <mat-checkbox
            name="Opening_Enable"
            [(ngModel)]="accountModel.Account.Opening_Enable"
            class="mt-3"
            [disabled]="!isEditMode"
          >
            Opening Enable
          </mat-checkbox>
        </div>
        <div class="col-md-2">
          <mat-checkbox
            name="Interest_Enable"
            [(ngModel)]="accountModel.Account.Interest_Enable"
            class="mt-3"
            [disabled]="!isEditMode"
          >
            Interest Enable
          </mat-checkbox>
        </div>
        <div class="col-md-2" *ngIf="accountModel.Account.Interest_Enable">
          <span class="">Interest</span>
          <input
            type="text"
            #refInterest="ngModel"
            [(ngModel)]="accountModel.Account.Interest"
            appTwoDigitDecimaNumber
            [ngClass]="{
              'is-invalid': f.submitted && refInterest.invalid,
              'alert-warning':
                refInterest.invalid &&
                (refInterest.dirty ||
                  refInterest.touched ||
                  refInterest.untouched)
            }"
            name="Interest"
            id="Interest"
            class="form-control input-text-css"
            [disabled]="!isEditMode"
          />
        </div>
        <div class="col-md-2" *ngIf="accountModel.Type == 'Bank'">
          <span class=""> NACH Enable / NACH Code</span>
          <div class="check-input">
            <mat-checkbox
              name="Account_IsNACH_Enable"
              [(ngModel)]="accountModel.Account.Account_IsNACH_Enable"
              (change)="
                onChangeCheckbox('Account_IsNACH_Enable', 'Account_NACH_Code')
              "
              [disabled]="!isEditMode"
            >
            </mat-checkbox>
            <input
              type="text"
              #refAccount_NACH_Code="ngModel"
              [disabled]="
                !accountModel.Account.Account_IsNACH_Enable || !isEditMode
              "
              [(ngModel)]="accountModel.Account.Account_NACH_Code"
              name="Account_NACH_Code"
              id="Account_NACH_Code"
              class="form-control input-text-css"
            />
          </div>
        </div>
      </div>
      <div class="row mt-2" *ngIf="accountModel.Account.Account_IsGSTEnable">
        <div class="col-md-6">
          <span class="required-lable"
            ><b>Account Tax Slab (if GST Enable)</b></span
          >
        </div>
      </div>
      <div class="row" *ngIf="accountModel.Account.Account_IsGSTEnable">
        <div class="col-md-4">
          <select
            name="FilterStatusId"
            id="FilterStatusId"
            class="form-control input-text-css"
            (change)="Get_TaxSlab_Details()"
            [(ngModel)]="accountModel.Account.Account_TaxSlab"
            [disabled]="!isEditMode"
          >
            <option value="">Select tax Slab</option>
            <option [value]="slab.TaxSlab_Name" *ngFor="let slab of slabList">
              {{ slab.TaxSlab_Name }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="table-responsive mt-2"
        *ngIf="
          taxSlabData.length > 0 && accountModel.Account.Account_IsGSTEnable
        "
      >
        <mat-table [dataSource]="dataTaxSlabSource" >
          <ng-container matColumnDef="Id">
            <mat-header-cell
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              <mat-checkbox
              [disabled]="!isEditMode"
                name="Account_Tax{{ i }}"
                id="Account_Tax{{ i }}"
                [(ngModel)]="row.IsChecked"
              ></mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="TaxSlab_Type">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              TaxSlab Type
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TaxSlab_Type }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="TaxSlab_Rate">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              TaxSlab Rate
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TaxSlab_Rate }}%
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Account_Name">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              TaxSlab Account Name
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Account_Name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="TaxType">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Tax Type
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TaxType }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="IsInterState">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Inter State
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.IsInterState }}
            </mat-cell>
          </ng-container>

          <!-- Header and Row Declarations -->
          <mat-header-row
            *matHeaderRowDef="displayedTaxSlabColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedTaxSlabColumns"
          ></mat-row>
        </mat-table>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <span class="required-lable"><b>Account Branches</b></span>
        </div>
      </div>
      <div class="table-responsive mt-2" *ngIf="BranchesList.length > 0">
        <mat-table [dataSource]="dataBranchSource" matSort>
          <ng-container matColumnDef="BranchId">
            <mat-header-cell
              class="grid-header"
              style="max-width: 100px"
              *matHeaderCellDef
            >
              #
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 100px; max-height: 5px"
            >
              <mat-checkbox
                [disabled]="!isEditMode"
                name="Account_BranchIds{{ i }}"
                id="Account_BranchIds{{ i }}"
                [(ngModel)]="row.IsChecked"
              ></mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Branch_Name">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Branch
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch_Name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Branch_Code">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Code
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch_Code }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Branch_Type">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Type
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch_Type }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="District_Name">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              District
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.District_Name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Tehsil_Name">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Tehsil
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Tehsil_Name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Branch_PhoneNo">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Phone No
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch_PhoneNo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Branch_Head">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Branch Head
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Branch_Head }}
            </mat-cell>
          </ng-container>

          <!-- Header and Row Declarations -->
          <mat-header-row
            *matHeaderRowDef="displayedBranchColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedBranchColumns"
          ></mat-row>
        </mat-table>
      </div>
      <ng-container *ngIf="accountModel.Account.Account_GroupId">
        <ng-container
          *ngIf="
            accountModel.AccountDetail &&
            (accountModel.Type == 'Sundry' || accountModel.Type == 'Loan')
          "
        >
          <div class="row mt-2">
            <div class="col-md-12">
              <h6 class="h-b">
                Account -
                {{ accountModel.Type == "Sundry" ? "Sundry" : "Loan" }} Detail
              </h6>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3">
              <span class="">Contact Person Name</span>
              <input
                [disabled]="!isEditMode"
                type="text"
                #refContact_Person_Name="ngModel"
                maxlength="50"
                [ngClass]="{
                  'is-invalid': f.submitted && refContact_Person_Name.invalid,
                  'alert-warning':
                    refContact_Person_Name.invalid &&
                    (refContact_Person_Name.dirty ||
                      refContact_Person_Name.touched ||
                      refContact_Person_Name.untouched)
                }"
                [(ngModel)]="accountModel.AccountDetail.Contact_Person_Name"
                name="Contact_Person_Name"
                id="Contact_Person_Name"
                class="form-control input-text-css"
                placeholder="Contact Person Name"
              />
            </div>
            <div class="col-md-3">
              <span class="">Mobile No</span>
              <input
                [disabled]="!isEditMode"
                type="text"
                numbersOnly
                #refMobileNo="ngModel"
                minlength="10"
                maxlength="10"
                placeholder="Mobile No"
                [(ngModel)]="accountModel.AccountDetail.MobileNo"
                name="MobileNo"
                id="MobileNo"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-3">
              <span class="">Email</span>
              <input
                [disabled]="!isEditMode"
                type="email"
                #refEmail="ngModel"
                maxlength="100"
                [(ngModel)]="accountModel.AccountDetail.Email"
                name="Email"
                id="Email"
                class="form-control input-text-css"
                placeholder="Email"
              />
            </div>
            <div class="col-md-3">
              <span class="">Pin Code</span>
              <input
                [disabled]="!isEditMode"
                type="text"
                numbersOnly
                #refPinCode="ngModel"
                minlength="6"
                maxlength="6"
                placeholder="Pin Code"
                [(ngModel)]="accountModel.AccountDetail.PinCode"
                name="PinCode"
                id="PinCode"
                class="form-control input-text-css"
              />
            </div>
            <div class="mt-2 col-md-3">
              <span class="">Address</span>
              <textarea
                [disabled]="!isEditMode"
                #refAddress="ngModel"
                maxlength="150"
                [(ngModel)]="accountModel.AccountDetail.Address"
                placeholder="Address"
                name="Address"
                id="Address"
                class="form-control input-text-css"
              ></textarea>
            </div>
            <div class="mt-2 col-md-3">
              <span class="">State</span>
              <select
                [disabled]="!isEditMode"
                name="State"
                id="State"
                #refState="ngModel"
                [(ngModel)]="accountModel.AccountDetail.State"
                class="form-control input-text-css"
                (change)="GetDistrickDropdown()"
              >
                <option value="">Select State</option>
                <option
                  *ngFor="let state of stateDropdown"
                  [value]="state.StateId"
                >
                  {{ state.State_Name }}
                </option>
              </select>
            </div>
            <div class="mt-2 col-md-3">
              <span class="">District</span>
              <select
                [disabled]="!isEditMode"
                name="District"
                id="District"
                #refDistrict="ngModel"
                [(ngModel)]="accountModel.AccountDetail.District"
                class="form-control input-text-css"
                (change)="GetTehasilDropdown()"
              >
                <option value="">Select District</option>
                <option
                  *ngFor="let district of districkDropdown"
                  [value]="district.DistrictId"
                >
                  {{ district.District_Name }}
                </option>
              </select>
            </div>
            <div class="mt-2 col-md-3">
              <span class="">Tehsil</span>
              <select
                [disabled]="!isEditMode"
                name="Teshil"
                id="Teshil"
                #refTasil="ngModel"
                [(ngModel)]="accountModel.AccountDetail.Teshil"
                class="form-control input-text-css"
              >
                <option value="">Select Tehsil</option>
                <option
                  *ngFor="let tehsil of tehasilDropdown"
                  [value]="tehsil.TehsilId"
                >
                  {{ tehsil.Tehsil_Name }}
                </option>
              </select>
            </div>
            <div class="mt-2 col-md-3">
              <span class="">Pan No</span>
              <input
                [disabled]="!isEditMode"
                type="text"
                #refPanNo="ngModel"
                maxlength="20"
                [(ngModel)]="accountModel.AccountDetail.PanNo"
                name="PanNo"
                id="PanNo"
                class="form-control input-text-css"
                placeholder="Pan No"
              />
            </div>

            <div class="mt-2 col-md-3">
              <span class=""> GST Applicable/GST No</span>
              <div class="check-input">
                <mat-checkbox
                  name="GSTApplicable"
                  id="GSTApplicable"
                  [(ngModel)]="accountModel.AccountDetail.GSTApplicable"
                  (ngModelChange)="onChangeCheckbox('GSTApplicable', 'GSTNo')"
                  [disabled]="!isEditMode"
                >
                </mat-checkbox>
                <input
                  type="text"
                  #refGSTNo="ngModel"
                  [disabled]="
                    !accountModel.AccountDetail.GSTApplicable && !isEditMode
                  "
                  maxlength="20"
                  [(ngModel)]="accountModel.AccountDetail.GSTNo"
                  name="GSTNo"
                  id="GSTNo"
                  class="form-control input-text-css"
                  placeholder="GST No"
                />
              </div>
            </div>
            <div class="mt-2 col-md-3 row m-0 align-items-center">
              <mat-checkbox
                name="TDSApplicable"
                [(ngModel)]="accountModel.AccountDetail.TDSApplicable"
                [disabled]="!isEditMode"
              >
                TDS Applicable
              </mat-checkbox>
            </div>
            <div class="mt-2 col-md-3">
              <span class="">Reg No</span>
              <input
                [disabled]="!isEditMode"
                type="text"
                #reRegNo="ngModel"
                maxlength="20"
                [(ngModel)]="accountModel.AccountDetail.RegNo"
                name="RegNo"
                id="RegNo"
                class="form-control input-text-css"
                placeholder="Reg No"
              />
            </div>
            <div class="mt-2 col-md-3">
              <span class="">Bank Account Name</span>
              <input
                [disabled]="!isEditMode"
                type="text"
                #reBank_AccountName="ngModel"
                maxlength="50"
                [(ngModel)]="accountModel.AccountDetail.Bank_AccountName"
                name="Bank_AccountName"
                id="Bank_AccountName"
                class="form-control input-text-css"
                placeholder="Reg No"
              />
            </div>
            <div class="mt-2 col-md-3">
              <span class="">Bank Name</span>
              <input
                [disabled]="!isEditMode"
                type="text"
                #reBank_Name="ngModel"
                maxlength="50"
                [(ngModel)]="accountModel.AccountDetail.Bank_Name"
                name="Bank_Name"
                id="Bank_Name"
                class="form-control input-text-css"
                placeholder="Bank Name"
              />
            </div>
            <div class="mt-2 col-md-3">
              <span class="">Bank IFSC</span>
              <input
                [disabled]="!isEditMode"
                type="text"
                #reBank_IFSC="ngModel"
                maxlength="50"
                [(ngModel)]="accountModel.AccountDetail.Bank_IFSC"
                name="Bank_IFSC"
                id="Bank_IFSC"
                class="form-control input-text-css"
                placeholder="Bank IFSC"
              />
            </div>
            <div class="mt-2 col-md-3">
              <span class="">Bank Branch</span>
              <input
                [disabled]="!isEditMode"
                type="text"
                #reBank_Branch="ngModel"
                maxlength="50"
                [(ngModel)]="accountModel.AccountDetail.Bank_Branch"
                name="Bank_Branch"
                id="Bank_Branch"
                class="form-control input-text-css"
                placeholder="Bank Branch"
              />
            </div>
            <ng-container *ngIf="accountModel.Type == 'Loan'">
              <div class="mt-2 col-md-3">
                <span class="">Loan Type</span>
                <select
                  [disabled]="!isEditMode"
                  name="LoanType"
                  id="LoanType"
                  #refLoanType="ngModel"
                  [(ngModel)]="accountModel.AccountDetail.LoanType"
                  class="form-control input-text-css"
                >
                  <option value="">Select LoanType</option>
                  <option value="Term">Term</option>
                  <option value="CC">CC</option>
                  <option value="OD">OD</option>
                  <option value="On Interest">On Interest</option>
                </select>
              </div>
              <div class="mt-2 col-md-3">
                <span class="">Loan No</span>
                <input
                  [disabled]="!isEditMode"
                  type="text"
                  #refLoanNo="ngModel"
                  maxlength="50"
                  [(ngModel)]="accountModel.AccountDetail.LoanNo"
                  name="LoanNo"
                  id="LoanNo"
                  class="form-control input-text-css"
                  placeholder="Loan No"
                />
              </div>
              <div class="mt-2 col-md-3">
                <span class="">Loan Amount</span>
                <input
                  [disabled]="!isEditMode"
                  type="number"
                  min="0"
                  #refLoanAmount="ngModel"
                  [(ngModel)]="accountModel.AccountDetail.LoanAmount"
                  name="LoanAmount"
                  id="LoanAmount"
                  class="form-control input-text-css"
                  placeholder="Loan Amount"
                />
              </div>
              <div class="mt-2 col-md-3">
                <span class="">Loan ROI</span>
                <input
                  [disabled]="!isEditMode"
                  type="text"
                  numbersOnly
                  maxlength="2"
                  #refLoanROI="ngModel"
                  [(ngModel)]="accountModel.AccountDetail.LoanROI"
                  name="LoanROI"
                  id="LoanROI"
                  class="form-control input-text-css"
                  placeholder="Loan ROI"
                />
              </div>
              <div class="mt-2 col-md-3">
                <span class="">No Of Installment</span>
                <input
                  [disabled]="!isEditMode"
                  type="number"
                  min="0"
                  #refNoOfInstallment="ngModel"
                  [(ngModel)]="accountModel.AccountDetail.NoOfInstallment"
                  name="NoOfInstallment"
                  id="NoOfInstallment"
                  class="form-control input-text-css"
                  placeholder="No Of Installment"
                />
              </div>
              <div class="mt-2 col-md-3">
                <span class="">First EMIDate</span>
                <div class="datepicker_feild">
                  <input
                    [disabled]="!isEditMode"
                    [matDatepicker]="picker3"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="FirstEMIDate"
                    id="FirstEMIDate"
                    #refFirstEMIDate="ngModel"
                    class="form-control input-text-css"
                    [(ngModel)]="accountModel.AccountDetail.FirstEMIDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker3"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker3></mat-datepicker>
                </div>
                <!-- <input
                  name="FirstEMIDate"
                  id="FirstEMIDate"
                  #refFirstEMIDate="ngModel"
                  class="form-control input-text-css"
                  bsDatepicker
                  [(ngModel)]="accountModel.AccountDetail.FirstEMIDate"
                  [(bsValue)]="accountModel.AccountDetail.FirstEMIDate"
                  value="{{
                    accountModel.AccountDetail.FirstEMIDate
                      | date : 'dd/MM/yyyy'
                  }}"
                  placeholder="DD/MM/YYYY"
                /> -->
              </div>
              <div class="mt-2 col-md-3">
                <span class="">Expiry Date</span>
                <div class="datepicker_feild">
                  <input
                    [disabled]="!isEditMode"
                    [matDatepicker]="picker4"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="ExpiryDate"
                    id="ExpiryDate"
                    #refExpiryDate="ngModel"
                    class="form-control input-text-css"
                    [(ngModel)]="accountModel.AccountDetail.ExpiryDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker4"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker4></mat-datepicker>
                </div>
                <!-- <input
                  name="ExpiryDate"
                  id="ExpiryDate"
                  #refExpiryDate="ngModel"
                  class="form-control input-text-css"
                  bsDatepicker
                  [(ngModel)]="accountModel.AccountDetail.ExpiryDate"
                  [(bsValue)]="accountModel.AccountDetail.ExpiryDate"
                  value="{{
                    accountModel.AccountDetail.ExpiryDate | date : 'dd/MM/yyyy'
                  }}"
                  placeholder="DD/MM/YYYY"
                /> -->
              </div>
              <div class="mt-2 col-md-12">
                <span class="">Collateral Detail</span>
                <textarea
                  [disabled]="!isEditMode"
                  #refCollateralDetail="ngModel"
                  [(ngModel)]="accountModel.AccountDetail.CollateralDetail"
                  placeholder="Collateral Detail"
                  name="CollateralDetail"
                  id="CollateralDetail"
                  class="form-control input-text-css"
                ></textarea>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container
          *ngIf="accountModel.AccountDetail && accountModel.Type == 'Tax'"
        >
          <div class="row mt-2">
            <div class="col-md-12">
              <h6 class="h-b">Account - TAX Detail</h6>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-4">
              <span class="">Tax Type</span>
              <select
                [disabled]="!isEditMode"
                name="TaxType"
                id="TaxType"
                #refTaxType="ngModel"
                [(ngModel)]="accountModel.AccountDetail.TaxType"
                class="form-control input-text-css"
              >
                <option value="">Select TaxType</option>
                <option value="GST">GST</option>
                <option value="TDS">TDS</option>
                <option value="TCS">TCS</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div class="col-md-4">
              <span class="">Tax Parcentage</span>
              <input
                [disabled]="!isEditMode"
               
                type="number"
                min="0"
                max="100"
                #refAccount_Name="ngModel"
                [(ngModel)]="accountModel.AccountDetail.TaxParcentage"
                name="TaxParcentage"
                id="TaxParcentage"
                class="form-control input-text-css"
                placeholder="Tax Parcentage"
              />
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="accountModel.AccountDetail && accountModel.Type == 'Bank'"
        >
          <div class="row mt-2">
            <div class="col-md-12">
              <h6 class="h-b">Account - Bank Detail</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <span class="">Bank Name</span>
              <input
                [disabled]="!isEditMode"
                type="text"
                #refBank_Name="ngModel"
                maxlength="50"
                [(ngModel)]="accountModel.AccountDetail.Bank_Name"
                name="Bank_Name"
                id="Bank_Name"
                class="form-control input-text-css"
                placeholder="Bank Name"
              />
            </div>
            <div class="col-md-3">
              <span class="">Bank IFSC</span>
              <input
                [disabled]="!isEditMode"
                type="text"
                #refBank_IFSC="ngModel"
                maxlength="50"
                [(ngModel)]="accountModel.AccountDetail.Bank_IFSC"
                name="Bank_IFSC"
                id="Bank_IFSC"
                class="form-control input-text-css"
                placeholder="Bank IFSC"
              />
            </div>
            <div class="col-md-2">
              <span class="">Bank Branch</span>
              <input
                [disabled]="!isEditMode"
                type="text"
                #refBank_Branch="ngModel"
                maxlength="50"
                [(ngModel)]="accountModel.AccountDetail.Bank_Branch"
                name="Bank_Branch"
                id="Bank_Branch"
                class="form-control input-text-css"
                placeholder="Bank Branch"
              />
            </div>
            <div class="col-md-4">
              <span class="">Branch Address</span>
              <textarea
                [disabled]="!isEditMode"
                #refBranch_Address="ngModel"
                [(ngModel)]="accountModel.AccountDetail.Branch_Address"
                placeholder="Branch Address"
                name="Branch_Address"
                id="Branch_Address"
                class="form-control input-text-css"
                rows="1"
              ></textarea>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3">
              <span class="">Bank Account No</span>
              <input
                [disabled]="!isEditMode"
                type="number"
                min="0"
                #refBank_AccountNo="ngModel"
                [(ngModel)]="accountModel.AccountDetail.Bank_AccountNo"
                name="Bank_AccountNo"
                id="Bank_AccountNo"
                class="form-control input-text-css"
                placeholder="Bank Account No"
              />
            </div>
            <div class="col-md-3">
              <span class="">Bank Account Type</span>
              <select
                [disabled]="!isEditMode"
                name="Bank_AccountType"
                id="Bank_AccountType"
                #refBank_AccountType="ngModel"
                [(ngModel)]="accountModel.AccountDetail.Bank_AccountType"
                class="form-control input-text-css"
              >
                <option value="">Select Bank_AccountType</option>
                <option value="CC">CC</option>
                <option value="OD">OD</option>
                <option value="CA">CA</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div class="col-md-2">
              <span class="">Bank Phone No</span>
              <input
                [disabled]="!isEditMode"
                type="text"
                numbersOnly
                maxlength="10"
                minlength="10"
                #refBank_PhoneNo="ngModel"
                class="form-control input-text-css"
                [(ngModel)]="accountModel.AccountDetail.Bank_PhoneNo"
                name="Bank_PhoneNo"
                id="Bank_PhoneNo"
                placeholder="Bank Phone No"
              />
            </div>

            <div class="col-md-4">
              <span class=""> Bank Disbursment Payment Allowed/Limit</span>
              <div class="check-input">
                <mat-checkbox
                  name="Bank_Disbursment_Payment_Allowed"
                  [(ngModel)]="
                    accountModel.AccountDetail.Bank_Disbursment_Payment_Allowed
                  "
                  (change)="
                    onChangeCheckbox(
                      'Bank_Disbursment_Payment_Allowed',
                      'Bank_Disbursment_Payment_Limit'
                    )
                  "
                  [disabled]="!isEditMode"
                >
                </mat-checkbox>
                <input
                  [disabled]="!isEditMode"
                  type="number"
                  min="0"
                  [disabled]="
                    !accountModel.AccountDetail.Bank_Disbursment_Payment_Allowed
                  "
                  #refBank_Disbursment_Payment_Limit="ngModel"
                  [(ngModel)]="
                    accountModel.AccountDetail.Bank_Disbursment_Payment_Limit
                  "
                  name="Bank_Disbursment_Payment_Limit"
                  id="Bank_Disbursment_Payment_Limit"
                  class="form-control input-text-css"
                />
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3 mt-3">
              <mat-checkbox
                [disabled]="!isEditMode"
                name="Bank_Cash_Deposit_Allowed"
                [(ngModel)]="
                  accountModel.AccountDetail.Bank_Cash_Deposit_Allowed
                "
              >
                Bank Cash Deposit Allowed
              </mat-checkbox>
            </div>
            <div class="col-md-3 mt-3">
              <mat-checkbox
                [disabled]="!isEditMode"
                name="Bank_Cash_Withdrawal_Allowed"
                [(ngModel)]="
                  accountModel.AccountDetail.Bank_Cash_Withdrawal_Allowed
                "
              >
                Bank Cash Withdrawal Allowed
              </mat-checkbox>
            </div>

            <div class="col-md-3">
              <span class="">Bank Other Payment Allowed/Limit</span>
              <div class="check-input">
                <mat-checkbox
                  [disabled]="!isEditMode"
                  name="Bank_Other_Payment_Allowed"
                  [(ngModel)]="
                    accountModel.AccountDetail.Bank_Other_Payment_Allowed
                  "
                  (change)="
                    onChangeCheckbox(
                      'Bank_Other_Payment_Allowed',
                      'Bank_Other_Payment_Limit'
                    )
                  "
                >
                </mat-checkbox>
                <input
                  type="number"
                  [disabled]="
                    !accountModel.AccountDetail.Bank_Other_Payment_Allowed &&
                    !isEditMode
                  "
                  #refBank_Other_Payment_Limit="ngModel"
                  min="0"
                  [(ngModel)]="
                    accountModel.AccountDetail.Bank_Other_Payment_Limit
                  "
                  name="Bank_Other_Payment_Limit"
                  id="Bank_Other_Payment_Limit"
                  class="form-control input-text-css"
                />
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="accountModel.AccountDetail && accountModel.Type == 'Cash'"
        >
          <div class="row mt-2">
            <div class="col-md-12">
              <h6 class="h-b">Account - Cash Detail</h6>
            </div>
          </div>
          <div class="row">
            <div class="mt-2 col-md-3">
              <span class=""> Cash Disbursment Payment Allowed/Limit</span>
              <div class="check-input">
                <mat-checkbox
                  [disabled]="!isEditMode"
                  name="Cash_Disbursment_Payment_Allowed"
                  [(ngModel)]="
                    accountModel.AccountDetail.Cash_Disbursment_Payment_Allowed
                  "
                  (change)="
                    onChangeCheckbox(
                      'Cash_Disbursment_Payment_Allowed',
                      'Cash_Disbursment_Payment_Limit'
                    )
                  "
                >
                </mat-checkbox>
                <input
                  type="number"
                  [disabled]="
                    !accountModel.AccountDetail
                      .Cash_Disbursment_Payment_Allowed && !isEditMode
                  "
                  #refCash_Disbursment_Payment_Limit="ngModel"
                  [(ngModel)]="
                    accountModel.AccountDetail.Cash_Disbursment_Payment_Limit
                  "
                  name="Cash_Disbursment_Payment_Limit"
                  id="Cash_Disbursment_Payment_Limit"
                  class="form-control input-text-css"
                />
              </div>
            </div>

            <div class="mt-2 col-md-3">
              <span class=""> Cash Other Payment Allowed/Limit</span>
              <div class="check-input">
                <mat-checkbox
                  name="Cash_Other_Payment_Allowed"
                  [(ngModel)]="
                    accountModel.AccountDetail.Cash_Other_Payment_Allowed
                  "
                  (change)="
                    onChangeCheckbox(
                      'Cash_Other_Payment_Allowed',
                      'Cash_Other_Payment_Limit'
                    )
                  "
                  [disabled]="!isEditMode"
                >
                </mat-checkbox>
                <input
                  type="number"
                  [disabled]="
                    !accountModel.AccountDetail.Cash_Other_Payment_Allowed &&
                    !isEditMode
                  "
                  #refCash_Other_Payment_Limit="ngModel"
                  min="0"
                  [(ngModel)]="
                    accountModel.AccountDetail.Cash_Other_Payment_Limit
                  "
                  name="Cash_Other_Payment_Limit"
                  id="Cash_Other_Payment_Limit"
                  class="form-control input-text-css"
                />
              </div>
            </div>
            <div class="mt-2 col-md-3">
              <span class="">Cash Received Limit</span>
              <input
                [disabled]="!isEditMode"
                type="number"
                #refCash_Cash_Received_Limit="ngModel"
                min="0"
                [(ngModel)]="
                  accountModel.AccountDetail.Cash_Cash_Received_Limit
                "
                name="Cash_Cash_Received_Limit"
                id="Cash_Cash_Received_Limit"
                class="form-control input-text-css"
              />
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="accountModel.AccountDetail && accountModel.Type == 'Asset'"
        >
          <div class="row mt-2">
            <div class="col-md-12">
              <h6 class="h-b">Account - Asset Detail</h6>
            </div>
          </div>
          <div
            class="row border-bottom"
            *ngFor="let item of accountModel.AccountDetail; let i = index"
          >
            <div class="mt-2 col-md-3">
              <span class="">Assets Name</span>
              <input
                [disabled]="!isEditMode"
                type="text"
                #refAssets_Name="ngModel"
                maxlength="50"
                [(ngModel)]="item.Assets_Name"
                name="Assets_Name{{ i }}"
                id="Assets_Name{{ i }}"
                class="form-control input-text-css"
              />
            </div>
            <div class="mt-2 col-md-3">
              <span class="">Description</span>
              <textarea
                [disabled]="!isEditMode"
                #refAssets_Description="ngModel"
                maxlength="500"
                [(ngModel)]="item.Assets_Description"
                name="Assets_Description{{ i }}"
                id="Assets_Description{{ i }}"
                class="form-control input-text-css"
              ></textarea>
            </div>
            <div class="mt-2 col-md-3">
              <span class="">SerialNo</span>
              <input
                [disabled]="!isEditMode"
                type="text"
                #refAssets_SerialNo="ngModel"
                maxlength="50"
                [(ngModel)]="item.Assets_SerialNo"
                name="Assets_SerialNo{{ i }}"
                id="Assets_SerialNo{{ i }}"
                class="form-control input-text-css"
              />
            </div>
            <div class="mt-2 col-md-3">
              <span class="">Invoice No</span>
              <input
                type="text"
                #refInvoice_No="ngModel"
                maxlength="50"
                [(ngModel)]="item.Invoice_No"
                name="Invoice_No{{ i }}"
                id="Invoice_No{{ i }}"
                class="form-control input-text-css"
                [disabled]="!isEditMode"
              />
            </div>
            <div class="mt-2 col-md-3">
              <span class="">Invoice Date</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker5"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="Invoice_Date{{ i }}"
                  id="Invoice_Date{{ i }}"
                  #refInvoice_Date="ngModel"
                  class="form-control input-text-css"
                  [(ngModel)]="item.Invoice_Date"
                  [disabled]="!isEditMode"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker5"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker5></mat-datepicker>
              </div>
              <!-- <input
                name="Invoice_Date{{ i }}"
                id="Invoice_Date{{ i }}"
                #refInvoice_Date="ngModel"
                [(ngModel)]="item.Invoice_Date"
                [(bsValue)]="item.Invoice_Date"
                value="{{ item.Invoice_Date | date : 'dd/MM/yyyy' }}"
                placeholder="DD/MM/YYYY"
                class="form-control input-text-css"
                bsDatepicker
              /> -->
            </div>
            <div class="mt-2 col-md-3">
              <span class="">Invoice Amount</span>
              <input
                type="number"
                #refInvoice_Amount="ngModel"
                min="0"
                [(ngModel)]="item.Invoice_Amount"
                name="Invoice_Amount{{ i }}"
                id="Invoice_Amount{{ i }}"
                class="form-control input-text-css"
                [disabled]="!isEditMode"
              />
            </div>
            <div class="mt-2 col-md-3">
              <span class="">Depreciation Rate IT Act</span>
              <input
                type="text"
                #refDepreciation_Rate_IT_Act="ngModel"
                maxlength="250"
                [(ngModel)]="item.Depreciation_Rate_IT_Act"
                name="Depreciation_Rate_IT_Act{{ i }}"
                id="Depreciation_Rate_IT_Act{{ i }}"
                class="form-control input-text-css"
                [disabled]="!isEditMode"
              />
            </div>
            <div class="mt-2 col-md-3">
              <span class="">Depreciation Rate Co Act</span>
              <input
                type="text"
                #refDepreciation_Rate_Co_Act="ngModel"
                maxlength="250"
                [(ngModel)]="item.Depreciation_Rate_Co_Act"
                name="Depreciation_Rate_Co_Act{{ i }}"
                id="Depreciation_Rate_Co_Act{{ i }}"
                class="form-control input-text-css"
                [disabled]="!isEditMode"
              />
            </div>
            <div
              class="mt-2 col-md-12 text-right"
              *ngIf="accountModel.AccountDetail.length > 1"
            >
              <i
                class="fa fa-minus-circle font-size-12"
                (click)="removeAsset(i)"
                style="cursor: pointer"
                aria-label="true"
              ></i>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12 text-right">
              <button
                type="button"
                (click)="addMoreAsset({})"
                style="color: #ffffff; font-size: 12px"
                mat-raised-button
                color="primary"
                [disabled]="!isEditMode"
              >
                Add More Asset
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="row mt-2">
        <div class="col-md-12 text-right">
          <button
            type="button"
            (click)="goToback()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>

          <button
            type="button"
            (click)="toggleEditMode()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
            *ngIf="!isEditMode"
          >
            Edit
          </button>
          <button
            *ngIf="isEditMode"
            type="button"
            (click)="onSaveAccount()"
            class="mt-3 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
