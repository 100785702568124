<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keyup.enter)="onSearchCollExAssign()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-3">
        <span class="required-lable">Assign Collection Executive</span>
        <select
          name="collection_exe_id"
          id="collection_exe_id"
          #refSelectGender="ngModel"
          #refcollection_exe_id="ngModel"
          [(ngModel)]="collection_exe_id"
          class="form-control input-text-css"
          (change)="ChangeAssigncollection_exe()"
          [ngClass]="{
            'is-invalid': df.submitted && refcollection_exe_id.invalid,
            'alert-warning':
              refcollection_exe_id.invalid &&
              (refcollection_exe_id.dirty ||
                refcollection_exe_id.touched ||
                refcollection_exe_id.untouched)
          }"
        >
          <option value="">Select Collection Executive</option>
          <option
            *ngFor="let Coll_exce of CollectionExecutive_Dropdown"
            [value]="Coll_exce.EmpId"
          >
            {{ Coll_exce.EmpName }}
          </option>
        </select>
      </div>

      <div class="col-md-2 text-right">
        <button
          type="button"
          (click)="onSearchCollExAssign()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>

      <div class="col-md-3 text-right">
        <button
          style="width: max-content"
          type="button"
          (click)="onClickExportTemplate()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Export Template Coll. Exec.
        </button>
      </div>
      <div class="col-md-2">
        <span class="button-wrap">
          <label
            class="mt-3 btn font-size-12 button-btn"
            for="ImportCustomerData"
            style="width: max-content"
            >Import Coll. Exec.</label
          >
          <input
            #fileInput
            type="file"
            id="ImportCustomerData"
            (change)="onClickImportTemplate($event)"
            style="display: none"
            accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </span>
      </div>

      <div class="col-md-2 text-right">
        <button
          type="button"
          class="mt-3 btn font-size-12 button-btn"
          (click)="GenerateDueList()"
        >
          Generate Due List
        </button>
      </div>
    </div>

    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-3">
        <span>Branch</span>
        <form [formGroup]="BranchForm">
          <ng-multiselect-dropdown
            name="Branches"
            [placeholder]="'Select Branch'"
            [data]="BrancheDropdown"
            formControlName="Branches"
            [settings]="BranchDropdownSettings"
            [(ngModel)]="Branches"
            (onDeSelect)="onBranchSelect($event)"
            (onSelect)="onBranchSelect($event)"
          >
          </ng-multiselect-dropdown>
        </form>
      </div>
      <div class="col-md-3">
        <span>Area</span>
        <form [formGroup]="AreaForm">
          <ng-multiselect-dropdown
            name="Areas"
            [placeholder]="'Select Area'"
            [data]="AreaDropdown"
            formControlName="Areas"
            [settings]="AreaDropdownSettings"
            [(ngModel)]="Areas"
            (onDeSelect)="onAresSelect($event)"
            (onSelect)="onAresSelect($event)"
          >
          </ng-multiselect-dropdown>
        </form>
      </div>
      <div class="col-md-3">
        <span>Sub Area</span>
        <form [formGroup]="SubAreaForm">
          <ng-multiselect-dropdown
            name="SubAreas"
            [placeholder]="'Select Sub Area'"
            [data]="SubAreaDropdown"
            formControlName="SubAreas"
            [settings]="SubAreaDropdownSettings"
            [(ngModel)]="SubAreas"
            (onDeSelect)="onSubAreaSelect($event)"
            (onSelect)="onSubAreaSelect($event)"
          >
          </ng-multiselect-dropdown>
        </form>
      </div>
      <div class="col-md-3">
        <span>Product</span>
        <form [formGroup]="ProductForm">
          <ng-multiselect-dropdown
            name="Products"
            [placeholder]="'Select Product'"
            [data]="ProductDropdown"
            formControlName="Products"
            [settings]="ProductDropdownSettings"
            [(ngModel)]="Products"
            (onDeSelect)="onProductsSelect($event)"
            (onSelect)="onProductsSelect($event)"
          >
          </ng-multiselect-dropdown>
        </form>
      </div>
    </div>
    <div class="row m-0 mt-2 col-md-12 p-0">
      <div class="col-md-3">
        <span>Collection Executive</span>
        <form [formGroup]="CollExecForm">
          <ng-multiselect-dropdown
            name="CollExec"
            [placeholder]="'Select Collection Executive'"
            [data]="CollectionExDropdown"
            formControlName="CollExec"
            [settings]="CollExecDropdownSettings"
            [(ngModel)]="CollExec"
            (onDeSelect)="onCollExecSelect($event)"
            (onSelect)="onCollExecSelect($event)"
          >
          </ng-multiselect-dropdown>
        </form>
      </div>
      <div class="col-md-3">
        <span>DPD From</span>
        <input
          type="number"
          name="DPDFrom"
          [(ngModel)]="DPDFrom"
          id="DPDFrom"
          placeholder="DPD From"
          #refDPDFrom="ngModel"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3">
        <span>DPD To</span>
        <input
          type="number"
          name="DPDTo"
          [(ngModel)]="DPDTo"
          id="DPDTo"
          placeholder="DPD To"
          #refDPDTo="ngModel"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-3">
        <span>Loan Date From</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="LoanDateFrom"
            id="LoanDateFrom"
            #refLoanDateFrom="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="LoanDateFrom"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          type="text"
          name="LoanDateFrom"
          [(ngModel)]="LoanDateFrom"
          id="LoanDateFrom"
          placeholder="Loan Date From"
          #refLoanDateFrom="ngModel"
          bsDatepicker
          class="form-control input-text-css"
        /> -->
      </div>
    </div>
    <div class="row m-0 mt-2 col-md-12 p-0">
      <div class="col-md-3">
        <span>Loan Date To</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="LoanDateTo"
            id="LoanDateTo"
            #refLoanDateTo="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="LoanDateTo"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          type="text"
          name="LoanDateTo"
          [(ngModel)]="LoanDateTo"
          id="LoanDateTo"
          placeholder="Loan Date To"
          #refLoanDateTo="ngModel"
          bsDatepicker
          class="form-control input-text-css"
        /> -->
      </div>
      <div class="col-md-3">
        <input
          style="margin: 20px 0px 0px 0px; height: 24px; width: 22px"
          [(ngModel)]="NonStarterCase"
          class="form-check-input"
          type="checkbox"
          name="NonStarterCase"
          id="NonStarterCase"
          value="option2"
        />
        <label
          style="margin: 19px 0px 0px 31px"
          class="form-check-label"
          for="exampleRadios1"
          >Non Starter Case</label
        >
      </div>
      <div class="col-md-3 float-right">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <div class="mt-3" *ngIf="dataSource">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto; height: 60vh"
        >
          <ng-container matColumnDef="Select">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              <mat-checkbox
                name="Select"
                id="Select"
                [(ngModel)]="SelectAll"
                (change)="checkUncheckAll($event)"
                [checked]="IsSelected"
              ></mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
              <mat-checkbox
                class="example-margin"
                name="IsSelected{{ i }}"
                id="IsSelected{{ i }}"
                [(ngModel)]="row.IsSelected"
                [checked]="row.IsSelected"
              ></mat-checkbox>
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 50px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Loan_Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw50"
            >
              {{ i + 1 }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 50px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Application_No">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Application No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Application_No
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAcNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Ac No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.LoanAcNo
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Loan_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Loan_Date
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Product_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Product Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Product_Name
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Branch_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Branch Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Branch_Name
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="CollectionExecutive">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Collection Executive</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.CollectionExecutive
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Area_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Area Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Area_Name
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Sub_Area_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Sub Area Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Sub_Area_Name
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.CustomerName
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Customer_PhoneNo">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >Customer Phone No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <div *ngIf="currentUser.IsMaskingMobile">
                {{ row.Customer_PhoneNo | mask }}
              </div>

              <div *ngIf="!currentUser.IsMaskingMobile">
                {{ row.Customer_PhoneNo }}
              </div>
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Address">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Address</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Address
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="RelationName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Relation Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.RelationName
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-left grid-header"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="LoanAmount">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Loan Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.LoanAmount
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("LoanAmount") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="EMIAmount">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >EMI Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.EMIAmount
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("EMIAmount") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Loan_Tenure">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Loan Tenure</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Loan_Tenure
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="EMI_Frequency">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >EMI Frequency</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.EMI_Frequency
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TotalEMI_NOS">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Total EMI NOS</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.TotalEMI_NOS
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="EMI_DUE">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >EMI DUE</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.EMI_DUE
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("EMI_DUE") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Total_Recv_EMI_Amount">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Total Recv EMI Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Total_Recv_EMI_Amount
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("Total_Recv_EMI_Amount") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Total_OverDUE_EMI__Amount">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Total Over Due EMI Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Total_OverDUE_EMI__Amount
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("Total_OverDUE_EMI__Amount") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Last_Recv_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Last Recv Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Last_Recv_Date
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Last_Recv_Amount">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Last Recv Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Last_Recv_Amount
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("Last_Recv_Amount") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="current_Due_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Current Due Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.current_Due_Date
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="current_Due_Amount">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Current Due Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.current_Due_Amount
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("current_Due_Amount") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="current_Due_EMI_No">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Current Due EMI No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.current_Due_EMI_No
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Total_EMI_AMount">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Agreed Amt.</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Total_EMI_AMount
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("Total_EMI_AMount") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Total_Principal_Amount">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Total Principal Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Total_Principal_Amount
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("Total_Principal_Amount") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Total_Interest_AMount">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Total Interest Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Total_Interest_AMount
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("Total_Interest_AMount") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="EMI_DUE_NOS">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >EMI DUE NOS</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.EMI_DUE_NOS
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Principal_DUE">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Principal DUE</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Principal_DUE
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("Principal_DUE") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Interest_DUE">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Interest DUE</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Interest_DUE
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("Interest_DUE") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Total_Recv_Principal_Amount">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Total Recv Principal Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Total_Recv_Principal_Amount
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{
                dataSource ? totaltypewise("Total_Recv_Principal_Amount") : ""
              }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Total_Recv_Interest_AMount">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Total Recv Interest Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Total_Recv_Interest_AMount
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{
                dataSource ? totaltypewise("Total_Recv_Interest_AMount") : ""
              }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="receved_EMI">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Receved EMI</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.receved_EMI
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="OverDUE_EMI">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Over DUE EMI</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.OverDUE_EMI
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Total_OverDUE_Principal_Amount">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Over DUE Principal Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Total_OverDUE_Principal_Amount
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{
                dataSource
                  ? totaltypewise("Total_OverDUE_Principal_Amount")
                  : ""
              }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="overDue_Interest_AMount">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Over Due Interest Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.overDue_Interest_AMount
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("overDue_Interest_AMount") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Penal_Interest">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Panel Intrest</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Penal_Interest
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("Penal_Interest") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Other_Charges">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Other Dues</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Other_Charges
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("Other_Charges") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Balance_Principle">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Balance Principle</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Balance_Principle
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("Balance_Principle") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Balance_Interest">
            <mat-header-cell class="grid-header text-right" *matHeaderCellDef
              >Balance Interest</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.Balance_Interest
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">
              {{ dataSource ? totaltypewise("Balance_Interest") : "" }}
            </mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="DPD_Days">
            <mat-header-cell
              mat-sort-header
              class="grid-header text-right"
              *matHeaderCellDef
              >DPD</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell text-right">{{
              row.DPD_Days
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <!-- <mat-row (click)="highlightRow(row)" [class.highlight]="i == selectedId" *matRowDef="let row; let i = index; columns: displayedColumns"></mat-row> -->
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></mat-footer-row>
        </mat-table>
        <div class="row m-0 pt-2 pb-2 align-items-center">
          <div class="col-md-4">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTable(exporter)"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8">
            <div class="row m-0">
              <div class="col-md-12 text-right mt-3">
                <button
                  type="button"
                  (click)="OnSaveCollExAssign()"
                  class="mt-3 btn font-size-12 button-btn"
                  [disabled]="!df.form.valid"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="Import1Model"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Import Collection Executive
        </h6>
        <button
          type="button"
          (click)="onCloseImport1Model()"
          class="close"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <div class="modal-body formborder">
        <h6
          *ngIf="
            dataSourceBlankDataFilldata != null && InValidDataInDataBase == 1
          "
        >
          Please fill all the field
        </h6>
        <h6
          *ngIf="
            dataSourceBlankDataFilldata != null && InValidDataInDataBase == 2
          "
        >
          Please fill correct data
        </h6>
        <h6
          *ngIf="
            dataSourceBlankDataFilldata != null && InValidDataInDataBase == 3
          "
        >
          Everything is correctly filled
        </h6>
        <div
          class="table-responsive mt-2"
          style="width: 100%"
          *ngIf="dataSourceBlankDataFilldata != null"
        >
          <mat-table
            [dataSource]="dataSourceBlankDataFilldata"
            matSort
            style="width: 100%';"
            [style.column-width]="columnWidth"
          >
            <ng-container matColumnDef="Emp_Id">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Employee Id
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                [ngClass]="
                  row.Emp_Id == null || row.Emp_Id == '' ? 'pending-danger' : ''
                "
                class="grid-cell"
              >
                {{ row.Emp_Id }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Emp_Name">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Employee Name
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Emp_Name == null || row.Emp_Name == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Emp_Name }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Loan_No">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
              >
                Loan No
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                class="grid-cell"
                [ngClass]="
                  row.Loan_No == null || row.Loan_No == ''
                    ? 'pending-danger'
                    : ''
                "
              >
                {{ row.Loan_No }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Error">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Error
              </mat-header-cell>
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Error == null || row.Error == '' ? 'pending-danger' : ''
                "
                class="grid-cell"
              >
                {{ row.Error }}
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayImportData; sticky: true"
            ></mat-header-row>
            <mat-row
              [ngClass]="
                row.Error != '' && row.Error != null ? 'pending-danger' : ''
              "
              *matRowDef="let row; columns: displayImportData"
            ></mat-row>
          </mat-table>
        </div>

        <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
          <button
            type="button"
            *ngIf="showSaveButton == true"
            (click)="OnSaveCollExec()"
            [class.spinner]="loading"
            class="btn font-size-12 button-btn"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="collection_executive_assignment"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none;"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Ac No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Collection Executive
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Area Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Sub Area Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Phone No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Address
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Relation Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Tenure
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Frequency
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total EMI Nos
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI Due
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Recv EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Over Due EMI Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Last Recv Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Last Recv Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Current Due Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Current Due Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Current Due EMI No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Agreed Amt.
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Principal Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Interest Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      EMI DUE NOS
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Principal DUE
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Interest DUE
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Recv Principal Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Recv Interest Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Receved EMI
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Over DUE EMI
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Over DUE Principal Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Over Due Interest Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Panel Intrest
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Other Dues
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Balance Principle
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Balance Interest
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">DPD</th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Application_No }}</td>
    <td>{{ x.LoanAcNo }}</td>
    <td>{{ x.Loan_Date }}</td>
    <td>{{ x.Product_Name }}</td>
    <td>{{ x.Branch_Name }}</td>
    <td>{{ x.CollectionExecutive }}</td>
    <td>{{ x.Area_Name }}</td>
    <td>{{ x.Sub_Area_Name }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>
      <div *ngIf="currentUser.IsMaskingMobile">
        {{ x.Customer_PhoneNo | mask }}
      </div>

      <div *ngIf="!currentUser.IsMaskingMobile">
        {{ x.Customer_PhoneNo }}
      </div>
    </td>
    <td>{{ x.Address }}</td>
    <td>{{ x.RelationName }}</td>
    <td>{{ x.LoanAmount }}</td>
    <td>{{ x.EMIAmount }}</td>
    <td>{{ x.Loan_Tenure }}</td>
    <td>{{ x.EMI_Frequency }}</td>
    <td>{{ x.TotalEMI_NOS }}</td>
    <td>{{ x.EMI_DUE }}</td>
    <td>{{ x.Total_Recv_EMI_Amount }}</td>
    <td>{{ x.Total_OverDUE_EMI__Amount }}</td>
    <td>{{ x.Last_Recv_Date }}</td>
    <td>{{ x.Last_Recv_Amount }}</td>
    <td>{{ x.current_Due_Date }}</td>
    <td>{{ x.current_Due_Amount }}</td>
    <td>{{ x.current_Due_EMI_No }}</td>
    <td>{{ x.Total_EMI_AMount }}</td>
    <td>{{ x.Total_Principal_Amount }}</td>
    <td>{{ x.Total_Interest_AMount }}</td>
    <td>{{ x.EMI_DUE_NOS }}</td>
    <td>{{ x.Principal_DUE }}</td>
    <td>{{ x.Interest_DUE }}</td>
    <td>{{ x.Total_Recv_Principal_Amount }}</td>
    <td>{{ x.Total_Recv_Interest_AMount }}</td>
    <td>{{ x.receved_EMI }}</td>
    <td>{{ x.OverDUE_EMI }}</td>
    <td>{{ x.Total_OverDUE_Principal_Amount }}</td>
    <td>{{ x.overDue_Interest_AMount }}</td>
    <td>{{ x.Penal_Interest }}</td>
    <td>{{ x.Other_Charges }}</td>
    <td>{{ x.Balance_Principle }}</td>
    <td>{{ x.Balance_Interest }}</td>
    <td>{{ x.DPD_Days }}</td>
  </tr>

  <tfoot>
    <tr>
      <td
        colspan="13"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ dataSource ? totaltypewise("LoanAmount") : "" }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ dataSource ? totaltypewise("EMIAmount") : "" }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong> {{ dataSource ? totaltypewise("EMI_DUE") : "" }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{
          dataSource ? totaltypewise("Total_Recv_EMI_Amount") : ""
        }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{
          dataSource ? totaltypewise("Total_OverDUE_EMI__Amount") : ""
        }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{
          dataSource ? totaltypewise("Last_Recv_Amount") : ""
        }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{
          dataSource ? totaltypewise("current_Due_Amount") : ""
        }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{
          dataSource ? totaltypewise("Total_EMI_AMount") : ""
        }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{
          dataSource ? totaltypewise("Total_Principal_Amount") : ""
        }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>
          {{ dataSource ? totaltypewise("Total_Interest_AMount") : "" }}</strong
        >
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong> {{ dataSource ? totaltypewise("Principal_DUE") : "" }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong> {{ dataSource ? totaltypewise("Interest_DUE") : "" }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>
          {{
            dataSource ? totaltypewise("Total_Recv_Principal_Amount") : ""
          }}</strong
        >
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>
          {{
            dataSource ? totaltypewise("Total_Recv_Interest_AMount") : ""
          }}</strong
        >
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{
          dataSource ? totaltypewise("Total_OverDUE_Principal_Amount") : ""
        }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>
          {{
            dataSource ? totaltypewise("overDue_Interest_AMount") : ""
          }}</strong
        >
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>
          {{ dataSource ? totaltypewise("Penal_Interest") : "" }}</strong
        >
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong> {{ dataSource ? totaltypewise("Other_Charges") : "" }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>
          {{ dataSource ? totaltypewise("Balance_Principle") : "" }}</strong
        >
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>
          {{ dataSource ? totaltypewise("Balance_Interest") : "" }}</strong
        >
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      ></td>
    </tr>
  </tfoot>
</table>

<table
  id="exported_data"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Emp Id
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Employee Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Designation Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Product Name
    </th>
  </tr>

  <tr *ngFor="let x of exportedData; let i = index">
    <td>{{ x.EmpId }}</td>
    <td>{{ x.Employee_Name }}</td>
    <td>{{ x.Designation_Name }}</td>
    <td>{{ x.BranchNames }}</td>
    <td>{{ x.ProductNames }}</td>
  </tr>
</table>
