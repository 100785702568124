<form #dbf="ngForm" (ngSubmit)="onSearchInquiryReport()" novalidate>
  <div
    class="row m-0 formborder d-flex align-items-center justify-content-between"
  >
    <div class="col-md-2 p-1">
      <span class="required-lable">From Date</span>
      <div class="datepicker_feild">
        <input
          required
          [matDatepicker]="picker"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="fromDate"
          id="fromDate"
          #reffromDate="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="fromDate"
          [ngClass]="{
            'is-invalid': dbf.submitted && reffromDate.invalid,
            'alert-warning':
              reffromDate.invalid &&
              (reffromDate.dirty ||
                reffromDate.touched ||
                reffromDate.untouched)
          }"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2 p-1">
      <span class="required-lable">To Date</span>
      <div class="datepicker_feild">
        <input
          required
          [matDatepicker]="picker2"
          dateConvert
          placeholder="DD/MM/YYYY"
          name="toDate"
          id="toDate"
          #reftoDate="ngModel"
          class="form-control input-text-css"
          [(ngModel)]="toDate"
          [ngClass]="{
            'is-invalid': dbf.submitted && reftoDate.invalid,
            'alert-warning':
              reftoDate.invalid &&
              (reftoDate.dirty || reftoDate.touched || reftoDate.untouched)
          }"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </div>
    </div>
    <div class="col-md-2 p-1">
      <span>Employee</span>
      <select
        name="empId"
        id="empId"
        [(ngModel)]="empId"
        #refempId="ngModel"
        [ngClass]="{
          'is-invalid': dbf.submitted && refempId.invalid,
          'alert-warning':
            refempId.invalid &&
            (refempId.dirty || refempId.touched || refempId.untouched)
        }"
        class="form-control input-text-css"
      >
        <option value="All">All</option>
        <option *ngFor="let list of EmployeeNameList" [value]="list.EmpId">
          {{ list.EmpName }}
        </option>
      </select>
    </div>
    <div class="col-md-2 p-1">
      <span>Status</span>
      <select
        name="Status"
        id="Status"
        class="form-control input-text-css"
        [(ngModel)]="Status"
      >
        <option value="All">All</option>
        <option value="Hold">Hold</option>
        <option value="Assigned">Assigned</option>
        <option value="Pending">Pending</option>
        <option value="Rejected">Rejected</option>
        <option value="Completed">Convert To Application</option>
      </select>
    </div>

    <div class="col-md-1 p-1 text-right">
      <button
        type="button"
        (click)="onSearchInquiryReport()"
        class="mt-3 btn font-size-12 button-btn"
        [disabled]="!dbf.form.valid"
      >
        Search
      </button>
    </div>
    <div class="col-md-3 mt-1">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</form>

<div class="mt-3" [hidden]="!dataSource">
  <div class="">
    <div
      class="table-responsive table-striped"
      style="overflow: auto; height: 65vh"
    >
      <table
        mat-table
        class="table-bordered"
        matSort
        [dataSource]="dataSource"
        matTableExporter
        #exporter="matTableExporter"
        id="inquiryReport"
      >
        <ng-container matColumnDef="InquiryId">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let row; let i = index">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Inq_Source">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Inquiry Source
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.Inq_Source }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Inq_InquiryNo">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Inquiry No</th>
          <td mat-cell *matCellDef="let row">{{ row.Inq_InquiryNo }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Inq_CustomerName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Customer Name
          </th>
          <td mat-cell *matCellDef="let row">{{ row.Inq_CustomerName }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Inq_CustomerAddress">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Address</th>
          <td mat-cell *matCellDef="let row">{{ row.Inq_CustomerAddress }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Inq_State">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>State</th>
          <td mat-cell *matCellDef="let row">{{ row.Inq_State }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>
        <ng-container matColumnDef="Inq_District">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>District</th>
          <td mat-cell *matCellDef="let row">{{ row.Inq_District }}</td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Inq_Tehsil">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Tehsil</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Inq_Tehsil }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="Inq_PinCode">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>PinCode</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Inq_PinCode }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>

        <ng-container matColumnDef="Inq_ContactNumber">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Contact Number
          </th>
          <td mat-cell *matCellDef="let row" class="text-right">
            <div *ngIf="currentUser.IsMaskingMobile">
              {{ row.Inq_ContactNumber | mask }}
            </div>

            <div *ngIf="!currentUser.IsMaskingMobile">
              {{ row.Inq_ContactNumber }}
            </div>
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>

        <ng-container matColumnDef="Inq_Email">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Inq_Email }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>

        <ng-container matColumnDef="Inq_Purpose">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Purpose</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Inq_Purpose }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>

        <ng-container matColumnDef="Inq_LoanAmount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Loan Amount</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Inq_LoanAmount }}
          </td>
          <th mat-footer-cell *matFooterCellDef></th>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>status</th>
          <td mat-cell *matCellDef="let row">{{ row.status }}</td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>

        <ng-container matColumnDef="Inq_CreatedBy">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Created By</th>
          <td mat-cell *matCellDef="let row">
            {{ row.Inq_CreatedBy }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>

        <ng-container matColumnDef="Inq_AssignedBy">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Assigned By</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Inq_AssignedBy }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-center"></th>
        </ng-container>
        <ng-container matColumnDef="Inq_AssignedTo">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Assigned To</th>
          <td mat-cell *matCellDef="let row" class="text-right">
            {{ row.Inq_AssignedTo }}
          </td>
          <th mat-footer-cell *matFooterCellDef class="text-right"></th>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="sticky-footer fontcolor"
          style="background: #4dc3a3"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          mat-footer-row
          class="sticky-footer fontcolor"
          *matFooterRowDef="displayedColumns; sticky: true"
          style="background: #4dc3a3"
        ></tr>
      </table>
    </div>
  </div>
</div>

<div class="row m-0 ml-3" [hidden]="!dataSource">
  <div class="col-md-4 mt-3">
    <button class="mt-3 btn font-size-12 button-btn" (click)="exportexcelNew()">
      <i
        class="fa fa-file-export mr-1"
        style="font-size: medium; cursor: pointer"
      ></i>
      Export
    </button>
  </div>
  <div class="col-md-8 mt-3">
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

<table
  id="inquiry_report"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Inquiry Source
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Inquiry No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Address
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      State
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      District
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Tehsil
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      PinCode
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Contact Number
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Email
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Purpose
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Status
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Created By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Assigned By
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Assigned To
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.Inq_Source }}</td>
    <td>{{ x.Inq_InquiryNo }}</td>
    <td>{{ x.Inq_CustomerName }}</td>
    <td>{{ x.Inq_CustomerAddress }}</td>
    <td>{{ x.Inq_State }}</td>
    <td>{{ x.Inq_District }}</td>
    <td>{{ x.Inq_Tehsil }}</td>
    <td>{{ x.Inq_PinCode }}</td>
    <td>
      <div *ngIf="currentUser.IsMaskingMobile">
        {{ x.Inq_ContactNumber | mask }}
      </div>

      <div *ngIf="!currentUser.IsMaskingMobile">
        {{ x.Inq_ContactNumber }}
      </div>
    </td>
    <td>{{ x.Inq_Email }}</td>
    <td>{{ x.Inq_Purpose }}</td>
    <td>{{ x.Inq_LoanAmount }}</td>
    <td>{{ x.status }}</td>
    <td>{{ x.Inq_CreatedBy }}</td>
    <td>{{ x.Inq_AssignedBy }}</td>
    <td>{{ x.Inq_AssignedTo }}</td>
  </tr>
</table>
