<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>

  <!-- Form Starts Here-->
  <form #at="ngForm" novalidate (keydown.enter)="onSearchAttendance()">
    <div class="row m-0 col-md-12 p-0 formborder d-flex align-items-end">
      <div class="row m-0 col-md-3">
        <span class="required-lable">Type</span>
        <select
          name="Type"
          id="Type"
          [(ngModel)]="SelectedType"
          required
          class="form-control input-text-css"
          #refType="ngModel"
          [ngClass]="{
            'is-invalid': at.submitted && refType.invalid,
            'alert-warning':
              refType.invalid &&
              (refType.dirty || refType.touched || refType.untouched)
          }"
        >
          <option value="">Select Type</option>
          <option value="ByTeam">By Team</option>
          <option value="ByEmployee">By Employee</option>
        </select>
      </div>
      <div
        *ngIf="SelectedType == 'ByEmployee'"
        [ngClass]="{
          'col-md-2': SelectedType == 'ByEmployee',
          'col-md-3': SelectedType != 'ByEmployee'
        }"
      >
        <span class="required-lable">Employee</span>
        <select
          name="EmployeeName"
          id="EmployeeName"
          [(ngModel)]="SelectedEmployee"
          required
          #refEmployeeNameList="ngModel"
          [ngClass]="{
            'is-invalid': at.submitted && refEmployeeNameList.invalid,
            'alert-warning':
              refEmployeeNameList.invalid &&
              (refEmployeeNameList.dirty ||
                refEmployeeNameList.touched ||
                refEmployeeNameList.untouched)
          }"
          class="form-control input-text-css"
        >
          <option value="">Select Employee Name</option>
          <option *ngFor="let list of EmployeeNameList" [value]="list.EmpId">
            {{ list.EmpName }}
          </option>
        </select>
      </div>
      <div class="col-md-2" *ngIf="SelectedType == 'ByEmployee'">
        <span class="required-lable">From Date</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="FromDate"
            id="FromDate"
            #refFromDate="ngModel"
            class="form-control input-text-css"
            (dateChange)="DateChange()"
            required
            [(ngModel)]="FromDate"
            [ngClass]="{
              'is-invalid': at.submitted && refFromDate.invalid,
              'alert-warning':
                refFromDate.invalid &&
                (refFromDate.dirty ||
                  refFromDate.touched ||
                  refFromDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="FromDate"
          id="FromDate"
          [(ngModel)]="FromDate"
          placeholder="From Date"
          (ngModelChange)="DateChange()"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refFromDate="ngModel"
          [ngClass]="{
            'is-invalid': at.submitted && refFromDate.invalid,
            'alert-warning':
              refFromDate.invalid &&
              (refFromDate.dirty ||
                refFromDate.touched ||
                refFromDate.untouched)
          }"
        /> -->
      </div>
      <div class="col-md-3">
        <span class="required-lable"> To Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ToDate"
            id="ToDate"
            required
            #refToDate="ngModel"
            class="form-control input-text-css"
            [(ngModel)]="ToDate"
            (dateChange)="DateChange()"
            [ngClass]="{
              'is-invalid': at.submitted && refToDate.invalid,
              'alert-warning':
                refToDate.invalid &&
                (refToDate.dirty || refToDate.touched || refToDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="ToDate"
          [(ngModel)]="ToDate"
          id="ToDate"
          placeholder="To Date"
          (ngModelChange)="DateChange()"
          class="form-control input-text-css"
          bsDatepicker
          required
          #refToDate="ngModel"
          [ngClass]="{
            'is-invalid': at.submitted && refToDate.invalid,
            'alert-warning':
              refToDate.invalid &&
              (refToDate.dirty || refToDate.touched || refToDate.untouched)
          }"
        /> -->
      </div>

      <div class="col-md-2">
        <button
          type="button"
          class="btn font-size-12 button-btn"
          (click)="onSearchAttendance()"
          [disabled]="!at.form.valid"
        >
          Search
        </button>
      </div>
      <div class="float-end col-md-3" *ngIf="SelectedType == 'ByTeam'">
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>
  <!-- Table Starts Here -->

  <div class="table-responsive mt-3" *ngIf="dataSource">
    <mat-table
      class="table-bordered"
      matSort
      [dataSource]="dataSource"
      matTableExporter
      #exporter="matTableExporter"
      id="AttendanceList"
      style="height: 65vh; overflow: auto; max-width: 100%"
    >
      <ng-container matColumnDef="EmpId">
        <mat-header-cell mat-sort-header *matHeaderCellDef>#</mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
          {{ i + 1 }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="EMP_Name">
        <mat-header-cell mat-sort-header *matHeaderCellDef
          >Employee Name</mat-header-cell
        >
        <mat-cell *matCellDef="let row">
          {{ row.EMP_Name }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="EMP_Branch">
        <mat-header-cell mat-sort-header *matHeaderCellDef
          >Branch</mat-header-cell
        >
        <mat-cell *matCellDef="let row">
          {{ row.EMP_Branch }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="Role_Name">
        <mat-header-cell mat-sort-header *matHeaderCellDef
          >Role Name</mat-header-cell
        >
        <mat-cell *matCellDef="let row">
          {{ row.Role_Name }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="Attendance_Date">
        <mat-header-cell mat-sort-header *matHeaderCellDef>
          Attendance Date
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.Attendance_Date }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="In_Time">
        <mat-header-cell mat-sort-header *matHeaderCellDef
          >In Time</mat-header-cell
        >
        <mat-cell *matCellDef="let row">
          {{ row.In_Time }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="In_LatLong">
        <mat-header-cell mat-sort-header *matHeaderCellDef
          >In Lat Long</mat-header-cell
        >
        <mat-cell *matCellDef="let row">
          <a
            target="_blank"
            *ngIf="
              row.In_LatLong != '' &&
              row.In_LatLong != null &&
              row.In_LatLong != ','
            "
            [href]="'http://maps.google.com/maps?q=' + row.In_LatLong"
          >
            <i class="fa-solid fa-location-dot"></i>
          </a>
        </mat-cell>

        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="In_Attachment">
        <mat-header-cell mat-sort-header *matHeaderCellDef
          >In Attachment</mat-header-cell
        >
        <mat-cell *matCellDef="let row">
          <a target="_blank" [href]="apiDocUrl + row.In_Attachment">
            {{ row.In_Attachment }}</a
          >
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="OUT_Time">
        <mat-header-cell mat-sort-header *matHeaderCellDef
          >Out Time</mat-header-cell
        >
        <mat-cell *matCellDef="let row">
          {{ row.OUT_Time }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="OUT_LatLong">
        <mat-header-cell mat-sort-header *matHeaderCellDef
          >Out Lat Long</mat-header-cell
        >
        <mat-cell *matCellDef="let row">
          <a
            target="_blank"
            *ngIf="
              row.OUT_LatLong != '' &&
              row.OUT_LatLong != null &&
              row.OUT_LatLong != ','
            "
            [href]="'http://maps.google.com/maps?q=' + row.OUT_LatLong"
          >
            <i class="fa-solid fa-location-dot"></i>
          </a>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="OUT_Attachment">
        <mat-header-cell mat-sort-header *matHeaderCellDef>
          Out Attachment
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <a target="_blank" [href]="apiDocUrl + row.OUT_Attachment">
            {{ row.OUT_Attachment }}</a
          >
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="TotalTime">
        <mat-header-cell mat-sort-header *matHeaderCellDef
          >Total Time</mat-header-cell
        >
        <mat-cell *matCellDef="let row">
          {{ row.TotalTime }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
        class="sticky-footer fontcolor"
        style="background: #4dc3a3; max-height: 5px"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <button class="mt-3 btn font-size-12 button-btn" (click)="exportExcel()">
      <i class="fa fa-file-export mr-1"></i> Export
    </button>
  </div>
</div>
<!-- <div class="col-md-4 mt-3">
</div> -->

<table
  id="reports_attendance"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th
      style="background: #4dc3a3 !important; border: 1px solid white"
      *ngIf="SelectedType == 'ByTeam'"
    >
      Employee Name
    </th>
    <th
      style="background: #4dc3a3 !important; border: 1px solid white"
      *ngIf="SelectedType == 'ByTeam'"
    >
      Role Name
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>

    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Attendance Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      In Time
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      In Lat Long
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      In Attachment
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Out Time
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Out Lat Long
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Out Attachment
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Total Time
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>{{ i + 1 }}</td>
    <td *ngIf="SelectedType == 'ByTeam'">{{ x.EMP_Name }}</td>
    <td *ngIf="SelectedType == 'ByTeam'">{{ x.Role_Name }}</td>
    <td>{{ x.EMP_Branch }}</td>

    <td>{{ x.Attendance_Date }}</td>
    <td>{{ x.In_Time }}</td>
    <td>{{ x.In_LatLong }}</td>
    <td>{{ x.In_Attachment }}</td>
    <td>{{ x.OUT_Time }}</td>
    <td>{{ x.OUT_LatLong }}</td>
    <td>{{ x.OUT_Attachment }}</td>
    <td>{{ x.TotalTime }}</td>
  </tr>
</table>
