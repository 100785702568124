import { Component, OnInit, ViewChild } from '@angular/core';
import { DataSharingService } from 'src/app/AuthGuard/DataSharingService';
import { EncrDecrService } from 'src/app/AuthGuard/EncrDecrService';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LmsService } from '../services/lms.service';
import { SnackbarComponent } from 'src/app/snackbar/snackbar.component';
import { DialogService } from 'src/app/Shared/dialog.service';
import { constantUrl } from 'src/app/Shared/constantUrl';
import { DecimalPipe } from '@angular/common';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-loan-reschedule-details',
  templateUrl: './loan-reschedule-details.component.html',
  styleUrls: ['./loan-reschedule-details.component.scss'],
  providers: [DecimalPipe]
})
export class LoanRescheduleDetailsComponent implements OnInit {

  currentUser: any; Loan_id: any; RequestId: any;
  showSpinner: boolean = false;
  errorShow: boolean = false;
  LoanAllDetail: any = []; DueDateCurrentDetail: any = {};
  LoanCustomerList: any = [];
  dataSource: any;
  displayedColumns: string[] = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  PreviousRescheduleList: any = [];
  displayedColumnsODCharges: any[] = [];
  dataSourceLoanODCharges: any;
  cEIModel: any = { Asset_Cost: 0, IRR_Type: 'Flat', EMI_Type: 'Monthly', ProductId: "", IRR_CalculateBy: 'ROI' };
  stepEMI: any[] = [];
  emiIrr: any;
  stepEmiData: any;
  selectedScheme: any = {};
  LoanDetail: any;
  stepEmiForm: boolean = true;
  errorShowChequeinTransit: boolean = false;
  isCalEMITableView: boolean = true; loading: boolean = false;
  RestructureRemark: any = ""; TypeofRescheduedisable: boolean = true; IsDuedateDisable: boolean = true;
  options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  todayDate = new Date();
  newDueDate: any;
  CurrentDate: any = new Date();
  EMI_DueDate1: any = new Date();
  loanSummary: any = {};
  IRR_New1: any;
  NoOfInstallment_New: any;
  EMIAmount_New: any;
  OnCalculateActive: boolean = false;
  isMaker: string = "";
  NewLoanAmount: any;
  CurrentRescheduledata: any = {
    "Reschedule_Date": '',
    "AssetCost": null,
    "LoanAmount": null,
    "Net_Finance_OS": null,
    "No_Of_Installment": null,
    "LoanTenure": null,
    "AgreementValue": null,
    "InterestAmt": null,
    "LTV": null,
    "LoanEMIAmount": null,
    "EMIType": "",
    "ROI": null,
    "IRR_CalculateBy": "",
    "Case_IRR": null,
    "EMI_DueDate": "",
    "No_Of_Days_Diff": null,
    "No_Of_Days_Interest": null,
    "Request_Status": "",
    "Reschedule_RequestBy": "",
    "Reschedule_RequestOn": "",
    "Reschedule_Remark": "",
    "Authorised_By": "",
    "Authorised_On": "",
    "No_Of_Installment_Decimal": null,
    "LoanTenure_Decimal": null,
    "Authorised_Remark": ""
  };
  ProposedRescheduledata: any = {
    "ImpactOn": "EMI AMOUNT",
    "Reschedule_Date": '',
    "Reschedule_Type": "EMI AMOUNT CHANGE",
    "AssetCost": null,
    "LoanAmount": null,
    "Net_Finance_OS": null,
    "No_Of_Installment": null,
    "LoanTenure": null,
    "AgreementValue": null,
    "InterestAmt": null,
    "LTV": null,
    "LoanEMIAmount": null,
    "EMIType": "",
    "ROI": null,
    "IRR_CalculateBy": "",
    "Case_IRR": null,
    "EMI_DueDate": "",
    "No_Of_Days_Diff": null,
    "No_Of_Days_Interest": null,
    "Request_Status": "",
    "Reschedule_RequestBy": "",
    "Reschedule_RequestOn": "",
    "Reschedule_Remark": "",
    "Authorised_By": "",
    "Authorised_On": "",
    "Authorised_Remark": "",
    "Request_Status_new": "",
    "No_Of_Installment_Decimal": null,
    "LoanTenure_Decimal": null,
    "isRequestStatus": true,
    "Request_Doc": "",
    "Reschedule_By_Id": 0
  };

  RequestDocBase64: any = "";
  RequestDocName: any = "";
  loandocBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  AllowExtension: any = constantUrl.AllowExtension;
  RescheduleDate: any = new Date();
  minRescheduleDate: any = new Date();
  constructor(
    private dataSharingService: DataSharingService,
    private _EncrDecrService: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: DialogService,
    private route: ActivatedRoute,
    private lmsService: LmsService,
    private decimalPipe: DecimalPipe
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.Loan_id = this._EncrDecrService.decrypt(decodeURIComponent(param.get("loanId")));
      this.RequestId = this._EncrDecrService.decrypt(decodeURIComponent(param.get("requestId")));

      this.isMaker = param.get("isMaker");

      this.getLoanRescheduleDetail();

      this.ProposedRescheduledata.ImpactOn = "EMI AMOUNT";

    });
  }

  ngOnInit() {
    this.ProposedRescheduledata.ImpactOn = "EMI AMOUNT";

    this.dataSharingService.HeaderTitle.next("My Rescheduled Details");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.displayedColumns = [
      "Int_Id",
      "CustomerId",
      "Customer",
      "CustomerType",
      "GenderAge",
      "PhoneNo",
      "Relation_With_Hirer",
      "Present_Address",
      "ExistingCustomer"
    ];

    //  console.log(this.ProposedRescheduledata.Reschedule_Type, this.ProposedRescheduledata.ImpactOn);
    // if (this.ProposedRescheduledata.Reschedule_Type == "EMI Amount Change") {

    // this.TypeofRescheduedisable = true;
    //}

  }

  onCloseDialog() {
    $('#loanFinancialDetailsModel').modal('hide');
  }

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  OnCalculate() {
    this.OnCalculateActive = true;
    if (this.ProposedRescheduledata.Reschedule_Type == "") {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Select Reschedule Type.", ...this.configSuccess });
      return;
    }

    if (this.ProposedRescheduledata.ImpactOn == "") {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Select Impact On Value.", ...this.configSuccess });
      return;
    }

    // if (!(this.ProposedRescheduledata.Reschedule_Type == "DUE DATE CHANGE" && this.ProposedRescheduledata.ImpactOn == "DUE DATE")) {
    let APIReq = {
      "Loan_Id": this.Loan_id,
      "Reschedule_Type": this.ProposedRescheduledata.Reschedule_Type,
      "Impact_On": this.ProposedRescheduledata.ImpactOn,
      "DueDate_NEW": this.EMI_DueDate1,
      "IRR_New": this.IRR_New1,
      "EMIAmount_New": this.EMIAmount_New,
      "NoOfInstallment_New": this.NoOfInstallment_New,
      "LoanAmount": this.NewLoanAmount.toFixed(2)
    }

    this.lmsService.LMS_Get_Loan_Reschedule_Proposed_Values(APIReq).subscribe((res: any) => {
      if (res[0].CODE == 0) {

        this.IsDuedateDisable = false;

        let ProposedReschedule = res[0] ? res.length > 0 ? res[0] : {} : {};
        this.ProposedRescheduledata = {
          "ImpactOn": this.ProposedRescheduledata.ImpactOn,
          "Reschedule_Type": this.ProposedRescheduledata.Reschedule_Type,
          "Reschedule_Date": moment(this.RescheduleDate).format('DD/MM/YYYY'),
          "AssetCost": ProposedReschedule.AssetCost ? ProposedReschedule.AssetCost : 0,
          "LoanAmount": ProposedReschedule.LoanAmount ? ProposedReschedule.LoanAmount : 0,
          "Net_Finance_OS": ProposedReschedule.Net_Finance_OS ? ProposedReschedule.Net_Finance_OS : "N/A",
          "No_Of_Installment": ProposedReschedule.No_Of_Installment ? ProposedReschedule.No_Of_Installment : 0,
          "No_Of_Installment_Decimal": ProposedReschedule.No_Of_Installment_Decimal ? ProposedReschedule.No_Of_Installment_Decimal : 0,
          "LoanTenure": ProposedReschedule.LoanTenure ? ProposedReschedule.LoanTenure : 0,
          "LoanTenure_Decimal": ProposedReschedule.LoanTenure_Decimal ? ProposedReschedule.LoanTenure_Decimal : 0,
          "AgreementValue": ProposedReschedule.AgreementValue ? ProposedReschedule.AgreementValue : 0,
          "InterestAmt": ProposedReschedule.InterestAmt ? ProposedReschedule.InterestAmt : 0,
          "LTV": ProposedReschedule.LTV ? ProposedReschedule.LTV : 0,
          "LoanEMIAmount": ProposedReschedule.LoanEMIAmount ? ProposedReschedule.LoanEMIAmount : 0,
          "EMIType": ProposedReschedule.EMIType ? ProposedReschedule.EMIType : 0,
          "ROI": ProposedReschedule.ROI ? ProposedReschedule.ROI : 0,
          "IRR_CalculateBy": ProposedReschedule.IRR_CalculateBy ? ProposedReschedule.IRR_CalculateBy : 0,
          "Case_IRR": ProposedReschedule.Case_IRR ? ProposedReschedule.Case_IRR : 0,
          "EMI_DueDate": ProposedReschedule.EMI_DueDate == '0001-01-01T00:00:00' ? '' : new Date((ProposedReschedule.EMI_DueDate.split('T')[0])),
          "No_Of_Days_Diff": ProposedReschedule.No_Of_Days_Diff ? ProposedReschedule.No_Of_Days_Diff : null,
          "No_Of_Days_Interest": ProposedReschedule.No_Of_Days_Interest ? ProposedReschedule.No_Of_Days_Interest : null,
          "Request_Status": ProposedReschedule.Request_Status ? ProposedReschedule.Request_Status : "",
          "Reschedule_RequestBy": ProposedReschedule.Reschedule_RequestBy ? ProposedReschedule.Reschedule_RequestBy : "",
          "Reschedule_RequestOn": ProposedReschedule.Reschedule_RequestOn ? ProposedReschedule.Reschedule_RequestOn : "",
          "Reschedule_Remark": ProposedReschedule.Reschedule_Remark ? ProposedReschedule.Reschedule_Remark : "",
          "Authorised_By": ProposedReschedule.Authorised_By ? ProposedReschedule.Authorised_By : "",
          "Authorised_On": ProposedReschedule.Authorised_On ? ProposedReschedule.Authorised_On : "",
          "Authorised_Remark": ProposedReschedule.Authorised_Remark ? ProposedReschedule.Authorised_Remark : "",
          "isRequestStatus": ProposedReschedule.Request_Status ? (ProposedReschedule.Request_Status == 'Authorized' || ProposedReschedule.Request_Status == 'Rejected') ? false : true : true,
          "Request_Doc": ProposedReschedule.Request_Doc ? ProposedReschedule.Request_Doc : "",
        };
      }
      else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].Msg, ...this.configSuccess });
        return;
      }
    });
    // }
    // else {
    //   this.IsDuedateDisable = false;
    //   let CurrentReschedule = this.DueDateCurrentDetail;

    //   this.ProposedRescheduledata = {
    //     "ImpactOn": "DUE DATE",
    //     "Reschedule_Type": "DUE DATE CHANGE",
    //     "Reschedule_Date": CurrentReschedule.Reschedule_Date ? CurrentReschedule.Reschedule_Date : "",
    //     "AssetCost": CurrentReschedule.AssetCost ? CurrentReschedule.AssetCost : 0,
    //     "LoanAmount": CurrentReschedule.LoanAmount ? CurrentReschedule.LoanAmount : 0,
    //     "Net_Finance_OS": CurrentReschedule.Net_Finance_OS ? CurrentReschedule.Net_Finance_OS : 0,
    //     "No_Of_Installment": CurrentReschedule.No_Of_Installment ? CurrentReschedule.No_Of_Installment : 0,
    //     "LoanTenure": CurrentReschedule.LoanTenure ? CurrentReschedule.LoanTenure : 0,
    //     "AgreementValue": CurrentReschedule.AgreementValue ? CurrentReschedule.AgreementValue : 0,
    //     "InterestAmt": CurrentReschedule.InterestAmt ? CurrentReschedule.InterestAmt : 0,
    //     "LTV": CurrentReschedule.LTV ? CurrentReschedule.LTV : 0,
    //     "LoanEMIAmount": CurrentReschedule.LoanEMIAmount ? CurrentReschedule.LoanEMIAmount : 0,
    //     "EMIType": CurrentReschedule.EMIType ? CurrentReschedule.EMIType : '',
    //     "ROI": CurrentReschedule.ROI ? CurrentReschedule.ROI : 0,
    //     "IRR_CalculateBy": CurrentReschedule.IRR_CalculateBy ? CurrentReschedule.IRR_CalculateBy : 0,
    //     "Case_IRR": CurrentReschedule.Case_IRR ? CurrentReschedule.Case_IRR : 0,
    //     "EMI_DueDate": CurrentReschedule.EMI_DueDate ? CurrentReschedule.EMI_DueDate : '',
    //     "No_Of_Days_Diff": CurrentReschedule.No_Of_Days_Diff ? CurrentReschedule.No_Of_Days_Diff : null,
    //     "No_Of_Days_Interest": CurrentReschedule.No_Of_Days_Interest ? CurrentReschedule.No_Of_Days_Interest : null,
    //     "Reschedule_RequestBy": CurrentReschedule.Reschedule_RequestBy ? CurrentReschedule.Reschedule_RequestBy : "",
    //     "Request_Status": CurrentReschedule.Request_Status ? CurrentReschedule.Request_Status : "",
    //     "Reschedule_RequestOn": CurrentReschedule.Reschedule_RequestOn ? CurrentReschedule.Reschedule_RequestOn : "",
    //     "Reschedule_Remark": CurrentReschedule.Reschedule_Remark ? CurrentReschedule.Reschedule_Remark : "",
    //     "Authorised_By": CurrentReschedule.Authorised_By ? CurrentReschedule.Authorised_By : "",
    //     "Authorised_On": CurrentReschedule.Authorised_On ? CurrentReschedule.Authorised_On : "",
    //     "Authorised_Remark": CurrentReschedule.Authorised_Remark ? CurrentReschedule.Authorised_Remark : "",
    //   };

    // }
  }

  OnRescheduleChange(event) {
    this.OnCalculateActive = false;
    if (event.target.value == "EMI AMOUNT CHANGE") {
      this.ProposedRescheduledata.ImpactOn = "EMI AMOUNT";
      this.TypeofRescheduedisable = true;
      this.IsDuedateDisable = true;
    }
    else if (event.target.value == "EMI AMOUNT CHANGE MANUAL") {
      this.ProposedRescheduledata.ImpactOn = "NO OF INSTALLMENT";
      this.TypeofRescheduedisable = true;
      this.IsDuedateDisable = true;
    }
    else if (event.target.value == "NO OF INSTALLMENT CHANGE") {
      this.ProposedRescheduledata.ImpactOn = "NO OF INSTALLMENT";
      this.TypeofRescheduedisable = true;
      this.IsDuedateDisable = true;
    }
    else if (event.target.value == "NO OF INSTALLMENT CHANGE MANUAL") {
      this.ProposedRescheduledata.ImpactOn = "EMI AMOUNT";
      this.TypeofRescheduedisable = true;
      this.IsDuedateDisable = true;
    }
    else if (event.target.value == "IRR CHANGE") {
      this.TypeofRescheduedisable = false;
      this.IsDuedateDisable = true;
      //  this.ProposedRescheduledata.ImpactOn.filter(x => x.value =="Due Date");       
      this.ProposedRescheduledata.ImpactOn = "";
    }
    else if (event.target.value == "DUE DATE CHANGE") {
      this.ProposedRescheduledata.ImpactOn = "DUE DATE";
      this.TypeofRescheduedisable = true;
      this.IsDuedateDisable = true;
    }
  }

  getPreRescheEMIAmountToatal(stepEMI) {
    if (stepEMI.length > 0) {
      let TotalEmi: any = 0, TotalAmount: any = 0
      for (let i = 0; i < stepEMI.length; i++) {
        if (stepEMI[i].EMIAmount) {
          TotalEmi = TotalEmi + (+stepEMI[i].ToEMI - +stepEMI[i].FromEMI + 1);
          TotalAmount = TotalAmount + ((+stepEMI[i].ToEMI - +stepEMI[i].FromEMI + 1) * +stepEMI[i].EMIAmount);
        }
      }
      return { totalEmi: TotalEmi, totalAmount: TotalAmount };
    } else {
      return {};
    }
  }

  getLoanRescheduleDetail() {
    this.stepEMI = [];
    this.showSpinner = true;
    this.lmsService.LMS_Get_Loan_Reschedule_Detail({ Loan_Id: JSON.parse(this.Loan_id), RequestId: JSON.parse(this.RequestId) }).subscribe((res: any) => {
      if (res != null) {
        this.LoanAllDetail = res.Item1;
       
        this.minRescheduleDate = new Date(this.LoanAllDetail[0].lastTrasactionDate);
        if (new Date(this.LoanAllDetail[0].lastTrasactionDate) > new Date()) {
          this.RescheduleDate = new Date(this.LoanAllDetail[0].lastTrasactionDate);
        } else {
          this.RescheduleDate = new Date();
        }
      
        this.LoanCustomerList = JSON.parse(JSON.stringify(res.Item2));
        this.dataSource = new MatTableDataSource(this.LoanCustomerList);
        this.dataSource.sort = this.sort;

        //let EMIAmountItem4 = res.item44;
        this.PreviousRescheduleList = res.Item3;
        this.PreviousRescheduleList.map((item) => {
          // item.EMIAmountList = EMIAmountItem4.filter(x => x.Rescheduled_Id == item.Rescheduled_Id);
          item.stepEmiData = this.getPreRescheEMIAmountToatal(item.EMIAmountList);
        });

        this.DueDateCurrentDetail = res.Item4 ? res.Item4.length > 0 ? res.Item4.filter(x => x.Type == "CURRENT")[0] : {} : {};
        let _day = new Date(this.DueDateCurrentDetail.EMI_DueDate).getDate();
        let _cday = new Date().getDate();
        if (_day <= _cday) {
          this.newDueDate = new Date();
          this.newDueDate.setMonth(new Date().getMonth() + 1);
          this.newDueDate.setDate(_day);
        }
        else {
          this.newDueDate = new Date()
          this.newDueDate.setDate(_day);
        }

        let CurrentReschedule = this.DueDateCurrentDetail;
        this.CurrentRescheduledata = {
          "Reschedule_Date": CurrentReschedule.Reschedule_Date ? CurrentReschedule.Reschedule_Date : "",
          "AssetCost": CurrentReschedule.AssetCost ? CurrentReschedule.AssetCost : 0,
          "LoanAmount": CurrentReschedule.LoanAmount ? CurrentReschedule.LoanAmount : 0,
          "Net_Finance_OS": CurrentReschedule.Net_Finance_OS ? CurrentReschedule.Net_Finance_OS : 0,
          "No_Of_Installment": CurrentReschedule.No_Of_Installment ? CurrentReschedule.No_Of_Installment : 0,
          "No_Of_Installment_Decimal": CurrentReschedule.No_Of_Installment_Decimal ? CurrentReschedule.No_Of_Installment_Decimal : 0,
          "LoanTenure": CurrentReschedule.LoanTenure ? CurrentReschedule.LoanTenure : 0,
          "LoanTenure_Decimal": CurrentReschedule.LoanTenure_Decimal ? CurrentReschedule.LoanTenure_Decimal : 0,
          "AgreementValue": CurrentReschedule.AgreementValue ? CurrentReschedule.AgreementValue : 0,
          "InterestAmt": CurrentReschedule.InterestAmt ? CurrentReschedule.InterestAmt : 0,
          "LTV": CurrentReschedule.LTV ? CurrentReschedule.LTV : 0,
          "LoanEMIAmount": CurrentReschedule.LoanEMIAmount ? CurrentReschedule.LoanEMIAmount : 0,
          "EMIType": CurrentReschedule.EMIType ? CurrentReschedule.EMIType : "",
          "ROI": CurrentReschedule.ROI ? CurrentReschedule.ROI : 0,
          "IRR_CalculateBy": CurrentReschedule.IRR_CalculateBy ? CurrentReschedule.IRR_CalculateBy : 0,
          "Case_IRR": CurrentReschedule.Case_IRR ? CurrentReschedule.Case_IRR : 0,
          "EMI_DueDate": (CurrentReschedule.EMI_DueDate && !CurrentReschedule.EMI_DueDate.includes("1900")) ? new Date(CurrentReschedule.EMI_DueDate) : '',
          "No_Of_Days_Diff": CurrentReschedule.No_Of_Days_Diff ? CurrentReschedule.No_Of_Days_Diff : null,
          "No_Of_Days_Interest": CurrentReschedule.No_Of_Days_Interest ? CurrentReschedule.No_Of_Days_Interest : null,
          "Request_Status": CurrentReschedule.Request_Status ? CurrentReschedule.Request_Status : "",
          "Reschedule_RequestBy": CurrentReschedule.Reschedule_RequestBy ? CurrentReschedule.Reschedule_RequestBy : "",
          "Reschedule_RequestOn": CurrentReschedule.Reschedule_RequestOn ? CurrentReschedule.Reschedule_RequestOn : "",
          "Reschedule_Remark": CurrentReschedule.Reschedule_Remark ? CurrentReschedule.Reschedule_Remark : "",
          "Authorised_By": CurrentReschedule.Authorised_By ? CurrentReschedule.Authorised_By : "",
          "Authorised_On": CurrentReschedule.Authorised_On ? CurrentReschedule.Authorised_On : "",
          "Authorised_Remark": CurrentReschedule.Authorised_Remark ? CurrentReschedule.Authorised_Remark : "",
        };

        let ProposedReschedule = res.Item4 ? res.Item4.length > 1 ? res.Item4.filter(x => x.Type == "PROPOSED")[0] : {} : {};
     
        if (Object.keys(ProposedReschedule).length > 0) {
          this.ProposedRescheduledata = {
            "ImpactOn": ProposedReschedule.ImpactOn ? ProposedReschedule.ImpactOn : "EMI AMOUNT",
            "Reschedule_Type": ProposedReschedule.Reschedule_Type ? ProposedReschedule.Reschedule_Type : "EMI AMOUNT CHANGE",
            "Reschedule_Date": ProposedReschedule.Reschedule_Date != '' && ProposedReschedule.Reschedule_Date != null ? ProposedReschedule.Reschedule_Date : moment(this.RescheduleDate).format('DD/MM/YYYY'),
            "Rescheduled_Id": ProposedReschedule.Rescheduled_Id ? ProposedReschedule.Rescheduled_Id : 0,
            "AssetCost": ProposedReschedule.AssetCost ? ProposedReschedule.AssetCost : 0,
            "LoanAmount": ProposedReschedule.LoanAmount ? ProposedReschedule.LoanAmount : 0,
            "Net_Finance_OS": ProposedReschedule.Net_Finance_OS ? ProposedReschedule.Net_Finance_OS : "0",
            "No_Of_Installment": ProposedReschedule.No_Of_Installment ? ProposedReschedule.No_Of_Installment : 0,
            "No_Of_Installment_Decimal": ProposedReschedule.No_Of_Installment_Decimal ? ProposedReschedule.No_Of_Installment_Decimal : 0,
            "LoanTenure": ProposedReschedule.LoanTenure ? ProposedReschedule.LoanTenure : 0,
            "LoanTenure_Decimal": ProposedReschedule.LoanTenure_Decimal ? ProposedReschedule.LoanTenure_Decimal : 0,
            "AgreementValue": ProposedReschedule.AgreementValue ? ProposedReschedule.AgreementValue : 0,
            "InterestAmt": ProposedReschedule.InterestAmt ? ProposedReschedule.InterestAmt : 0,
            "LTV": ProposedReschedule.LTV ? ProposedReschedule.LTV : 0,
            "LoanEMIAmount": ProposedReschedule.LoanEMIAmount ? ProposedReschedule.LoanEMIAmount : 0,
            "EMIType": ProposedReschedule.EMIType ? ProposedReschedule.EMIType : 0,
            "ROI": ProposedReschedule.ROI ? ProposedReschedule.ROI : 0,
            "IRR_CalculateBy": ProposedReschedule.IRR_CalculateBy ? ProposedReschedule.IRR_CalculateBy : 0,
            "Case_IRR": ProposedReschedule.Case_IRR ? ProposedReschedule.Case_IRR : 0,
            "EMI_DueDate": ProposedReschedule.EMI_DueDate == '0001-01-01T00:00:00' ? '' : new Date((ProposedReschedule.EMI_DueDate.split('T')[0])),

            "No_Of_Days_Diff": ProposedReschedule.No_Of_Days_Diff ? ProposedReschedule.No_Of_Days_Diff : null,
            "No_Of_Days_Interest": ProposedReschedule.No_Of_Days_Interest ? ProposedReschedule.No_Of_Days_Interest : null,
            "Request_Status": ProposedReschedule.Request_Status ? ProposedReschedule.Request_Status : "",
            "Reschedule_RequestBy": ProposedReschedule.Reschedule_RequestBy ? ProposedReschedule.Reschedule_RequestBy : "",
            "Reschedule_RequestOn": ProposedReschedule.Reschedule_RequestOn ? ProposedReschedule.Reschedule_RequestOn : "",
            "Reschedule_Remark": ProposedReschedule.Reschedule_Remark ? ProposedReschedule.Reschedule_Remark : "",
            "Authorised_By": ProposedReschedule.Authorised_By ? ProposedReschedule.Authorised_By : "",
            "Authorised_On": ProposedReschedule.Authorised_On ? ProposedReschedule.Authorised_On : "",
            "Authorised_Remark": ProposedReschedule.Authorised_Remark ? ProposedReschedule.Authorised_Remark : "",
            "Request_Status_new": ProposedReschedule.Request_Status ? ProposedReschedule.Request_Status != "Created" && ProposedReschedule.Request_Status != "" ? ProposedReschedule.Request_Status : "" : "",
            "Reschedule_By_Id": ProposedReschedule.Reschedule_By_Id ? ProposedReschedule.Reschedule_By_Id : "",
            "isRequestStatus": ProposedReschedule.Request_Status ? (ProposedReschedule.Request_Status == 'Authorized' || ProposedReschedule.Request_Status == 'Rejected') ? false : true : true,
            "Request_Doc": ProposedReschedule.Request_Doc ? ProposedReschedule.Request_Doc : "",
          };
          this.IRR_New1 = this.ProposedRescheduledata.Case_IRR;
          // this.EMIAmount_New=this.ProposedRescheduledata.EMIAmount_New;
          // this.NoOfInstallment_New=this.ProposedRescheduledata.Case_IRR;
          this.RescheduleDate = new Date(ProposedReschedule.Reschedule_Date);
        }

        if (ProposedReschedule.ChargesJson) {
          this.displayedColumnsODCharges = [
            "ChagesHead",
            "TranTypeDR",
            "TranTypeCR",
            "TranTypeTotal",
            "TranTypeTotalInclude",
            "excludedcharges"
          ]
          this.dataSourceLoanODCharges = new MatTableDataSource(
            JSON.parse(ProposedReschedule.ChargesJson)
          );
          this.dataSourceLoanODCharges.sort = this.sort;

          this.showSpinner = false;
        }
        else {
          this.chargesDetail();
          this.showSpinner = false;
        }
         
      }
      this.showSpinner = false;
    });
  }

  fileChangeEvent(fileInput) {
    let Ext = fileInput.target.files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(fileInput.target.files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      this.ProposedRescheduledata.Request_Doc = "";
      this.RequestDocName = "";
      this.RequestDocBase64 = "";
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.ProposedRescheduledata.Request_Doc = "";
      this.RequestDocName = "";
      this.RequestDocBase64 = "";
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Invalid file type`, ...this.configSuccess });
      return;
    }

    if (fileInput.target.files && fileInput.target.files.length) {
      let files = fileInput.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);
      fileReader.onload = (e) => {
        this.RequestDocName = "IMG_" + new Date().getTime() + '.png';
        this.RequestDocBase64 = fileReader.result.toString().split(";base64,").pop();
      };
    } else {
      this.ProposedRescheduledata.Request_Doc = "";
      this.RequestDocName = "";
      this.RequestDocBase64 = "";
    }
  }

  onRemoveImg() {
    this.ProposedRescheduledata.Request_Doc = "";
  }

  onCancel() {
    this.router.navigate([`loan/reschedule/${this.isMaker}`]);
  }

  onSaveReschedule() {
    if (this.ProposedRescheduledata.Reschedule_Date == null || this.ProposedRescheduledata.Reschedule_Date == '') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Reschedule date is not valid.", ...this.configSuccess });
      return;
    }

    // if (this.ProposedRescheduledata.AssetCost == null || this.ProposedRescheduledata.AssetCost == '' || this.ProposedRescheduledata.AssetCost == 0 || this.ProposedRescheduledata.AssetCost == '0') {
    //   this.snackBar.openFromComponent(SnackbarComponent, { data: "Asset Cost is not valid.", ...this.configSuccess });
    //   return;
    // }

    if (this.ProposedRescheduledata.LoanAmount == null || this.ProposedRescheduledata.LoanAmount == '' || this.ProposedRescheduledata.LoanAmount == 0 || this.ProposedRescheduledata.LoanAmount == '0') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Loan Amount is not valid.", ...this.configSuccess });
      return;
    }

    if (this.ProposedRescheduledata.Net_Finance_OS == null || this.ProposedRescheduledata.Net_Finance_OS == '' || this.ProposedRescheduledata.Net_Finance_OS == 0 || this.ProposedRescheduledata.Net_Finance_OS == '0') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Net Finance OS is not valid.", ...this.configSuccess });
      return;
    }

    if (this.ProposedRescheduledata.EMIType == null || this.ProposedRescheduledata.EMIType == '') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "EMI Type is not valid.", ...this.configSuccess });
      return;
    }

    if (this.ProposedRescheduledata.No_Of_Installment == null || this.ProposedRescheduledata.No_Of_Installment == '' || this.ProposedRescheduledata.No_Of_Installment == 0 || this.ProposedRescheduledata.No_Of_Installment == '0') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "No Of Installment is not valid.", ...this.configSuccess });
      return;
    }

    if (this.ProposedRescheduledata.LoanTenure == null || this.ProposedRescheduledata.LoanTenure == '' || this.ProposedRescheduledata.LoanTenure == 0 || this.ProposedRescheduledata.LoanTenure == '0') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Loan Tenure is not valid.", ...this.configSuccess });
      return;
    }

    if (this.ProposedRescheduledata.AgreementValue == null || this.ProposedRescheduledata.AgreementValue == '' || this.ProposedRescheduledata.AgreementValue == 0 || this.ProposedRescheduledata.AgreementValue == '0') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Agreement Value is not valid.", ...this.configSuccess });
      return;
    }

    if (this.ProposedRescheduledata.InterestAmt == null || this.ProposedRescheduledata.InterestAmt == '' || this.ProposedRescheduledata.InterestAmt == 0 || this.ProposedRescheduledata.InterestAmt == '0') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Interest Amount is not valid.", ...this.configSuccess });
      return;
    }

    if (this.ProposedRescheduledata.LTV == null || this.ProposedRescheduledata.LTV == '' || this.ProposedRescheduledata.LTV == 0 || this.ProposedRescheduledata.LTV == '0') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "LTV is not valid.", ...this.configSuccess });
      return;
    }

    if (this.ProposedRescheduledata.LoanEMIAmount == null || this.ProposedRescheduledata.LoanEMIAmount == '' || this.ProposedRescheduledata.LoanEMIAmount == 0 || this.ProposedRescheduledata.LoanEMIAmount == '0') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "EMI Amount is not valid.", ...this.configSuccess });
      return;
    }

    if (this.ProposedRescheduledata.Case_IRR == null || this.ProposedRescheduledata.Case_IRR == '' || this.ProposedRescheduledata.Case_IRR == 0 || this.ProposedRescheduledata.Case_IRR == '0') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Case IRR is not valid.", ...this.configSuccess });
      return;
    }

    if (this.ProposedRescheduledata.ROI == null || this.ProposedRescheduledata.ROI == '' || this.ProposedRescheduledata.ROI == 0 || this.ProposedRescheduledata.ROI == '0') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "ROI is not valid.", ...this.configSuccess });
      return;
    }

    if (this.ProposedRescheduledata.IRR_CalculateBy == null || this.ProposedRescheduledata.IRR_CalculateBy == '') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "IRR Calculate By is not valid.", ...this.configSuccess });
      return;
    }
    if (this.ProposedRescheduledata.EMI_DueDate == null || this.ProposedRescheduledata.EMI_DueDate == '') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "EMI Due Date is not valid.", ...this.configSuccess });
      return;
    }
    if (this.ProposedRescheduledata.Reschedule_Remark == null || this.ProposedRescheduledata.Reschedule_Remark == '') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please enter Reschedule Remark.", ...this.configSuccess });
      return;
    }
    if (this.isMaker == 'R') {
      let SaveRescheduleData = {
        "Reschedule": {
          "Id": this.ProposedRescheduledata.Rescheduled_Id,
          "Loan_Id": parseInt(this.Loan_id),
          "Request_Status": this.ProposedRescheduledata.Request_Status == '' || this.ProposedRescheduledata.Request_Status == 'Created' ? 'Created' : this.ProposedRescheduledata.Request_Status,
          "Reschedule_Type": this.ProposedRescheduledata.Reschedule_Type,
          "Reschedule_ImpactOn": this.ProposedRescheduledata.ImpactOn,
          "Net_Finance_OS_Plan": this.CurrentRescheduledata.Net_Finance_OS,
          "Net_Finance_OS_Voucher": this.ProposedRescheduledata.Net_Finance_OS,
          "AssetCost_NEW": this.ProposedRescheduledata.AssetCost,
          "AssetCost_OLD": this.CurrentRescheduledata.AssetCost,
          "LoanAmount_NEW": this.ProposedRescheduledata.LoanAmount,
          "LoanAmount_OLD": this.CurrentRescheduledata.LoanAmount,
          "No_Of_Installment_NEW": this.ProposedRescheduledata.No_Of_Installment,
          "No_Of_Installment_OLD": this.CurrentRescheduledata.No_Of_Installment,
          "No_Of_Installment_NEW_Decimal": this.ProposedRescheduledata.No_Of_Installment_Decimal,
          "No_Of_Installment_OLD_Decimal": this.CurrentRescheduledata.No_Of_Installment_Decimal,
          "LoanTenure_NEW": this.ProposedRescheduledata.LoanTenure,
          "LoanTenure_OLD": this.CurrentRescheduledata.LoanTenure,
          "LoanTenure_NEW_Decimal": this.ProposedRescheduledata.LoanTenure_Decimal,
          "LoanTenure_OLD_Decimal": this.CurrentRescheduledata.LoanTenure_Decimal,
          "AgreementValue_NEW": this.ProposedRescheduledata.AgreementValue,
          "AgreementValue_OLD": this.CurrentRescheduledata.AgreementValue,
          "InterestAmt_NEW": this.ProposedRescheduledata.InterestAmt,
          "InterestAmt_OLD": this.CurrentRescheduledata.InterestAmt,
          "LTV_NEW": this.ProposedRescheduledata.LTV,
          "LTV_OLD": this.CurrentRescheduledata.LTV,
          "EMIAmount_NEW": this.ProposedRescheduledata.LoanEMIAmount,
          "EMIAmount_OLD": this.CurrentRescheduledata.LoanEMIAmount,
          "EMIType_NEW": this.ProposedRescheduledata.EMIType,
          "EMIType_OLD": this.CurrentRescheduledata.EMIType,
          "ROI_NEW": this.ProposedRescheduledata.ROI,
          "ROI_OLD": this.CurrentRescheduledata.ROI,
          "IRR_CalculateBy_NEW": this.ProposedRescheduledata.IRR_CalculateBy,
          "IRR_CalculateBy_OLD": this.CurrentRescheduledata.IRR_CalculateBy,
          "Case_IRR_NEW": this.ProposedRescheduledata.Case_IRR,
          "Case_IRR_OLD": this.CurrentRescheduledata.Case_IRR,
          "EMI_DueDate_NEW": this.ProposedRescheduledata.EMI_DueDate,
          "EMI_DueDate_OLD": new Date(this.CurrentRescheduledata.EMI_DueDate),
          "No_Of_Days_Diff": this.ProposedRescheduledata.No_Of_Days_Diff,
          "No_Of_Days_Interest": this.ProposedRescheduledata.No_Of_Days_Interest,
          "LoginUserId": this.currentUser.userId,
          "Reschedule_Remark": this.ProposedRescheduledata.Reschedule_Remark,
          "Request_Doc": this.RequestDocName != "" ? this.RequestDocName : this.ProposedRescheduledata.Request_Doc,
          "ChargesJson": (JSON.stringify(this.dataSourceLoanODCharges.filteredData)).toString(),
          "Request_Date":this.RescheduleDate
        }
      }

      this.lmsService.LMS_Save_Reschedule_Request({ JSON: JSON.stringify(SaveRescheduleData) }).subscribe((res: any) => {
        if (res[0].CODE >= 0) {
          if (this.RequestDocBase64 != "") {
            let saveObj = {
              "DocName": this.RequestDocName,
              "LoanId": JSON.parse(this.Loan_id),
              "DocData": this.RequestDocBase64
            }
            this.lmsService.UploadLoanDoc(saveObj).subscribe((respose: any) => {
              if (respose) {
                this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
                this.router.navigate([`loan/reschedule/${this.isMaker}`]);
              }
            });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            this.router.navigate([`loan/reschedule/${this.isMaker}`]);
          }
        }
      })

    }
  }

  onSubmitReschedule() {
    if (this.ProposedRescheduledata.Request_Status_new == null || this.ProposedRescheduledata.Request_Status_new == '') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Select Request Status.", ...this.configSuccess });
      return;
    }
    if (this.ProposedRescheduledata.Authorised_Remark == null || this.ProposedRescheduledata.Authorised_Remark == '') {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Enter Authorised Remark.", ...this.configSuccess });
      return;
    }

    this.dialog.openConfirmDialog(`Are you sure you want to ${this.ProposedRescheduledata.Request_Status_new} ?`).afterClosed().subscribe(dialogResult => {
      if (dialogResult == false) {
        return;
      } else {
        let submitData = {
          "Loan_Id": parseInt(this.Loan_id),
          "RequestId": JSON.parse(this.RequestId),
          "Request_Status": this.ProposedRescheduledata.Request_Status_new,
          "Remark": this.ProposedRescheduledata.Authorised_Remark,
          "LoginUserId": this.currentUser.userId
        }
        this.lmsService.LMS_Authorized_Reschedule_Request(submitData).subscribe((res: any) => {
          if (res[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            this.router.navigate([`loan/reschedule/${this.isMaker}`]);
          }
          else if (res[0].CODE == -1) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            return;
          }
        })
      }
    });
  }

  onDeleteRequest() {
    this.dialog.openConfirmDialog(`Are you sure you want to remove?`).afterClosed().subscribe(dialogResult => {
      if (dialogResult == false) {
        return;
      } else {
        let deleteReq = {
          "Loan_Id": parseInt(this.Loan_id),
          "RequestId": JSON.parse(this.RequestId),
          "LoginUserId": this.currentUser.userId
        }
        this.lmsService.LMS_Delete_Reschedule_Request(deleteReq).subscribe((res: any) => {
          if (res[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
            this.router.navigate([`loan/reschedule/${this.isMaker}`]);
          }
        })
      }
    });
  }


  handleByChange(event: any) {
    this.emiIrr = undefined;
    if (this.cEIModel.IRR_CalculateBy == "ROI") {
      delete this.cEIModel.EMI_Amount;
    } else {
      delete this.cEIModel.Flat_Rate;
    }
    if (this.cEIModel.IRR_CalculateBy !== "STEP_EMI") {
      this.stepEMI = [];
    } else {
      this.stepEMI.push({
        FromEMI: 1,
        ToEMI: "",
        EMI_Amount: "",
        disable: false,
      });
    }
    this.stepEmiData = undefined;
  }

  setTenure() {
    if (this.cEIModel.No_Of_Inst) {
      if (this.cEIModel.EMI_Type == 'ByMonthly') {
        this.cEIModel.Tenure = 2 * +this.cEIModel.No_Of_Inst;
      }
      else if (this.cEIModel.EMI_Type == 'Quarterly') {
        this.cEIModel.Tenure = 3 * +this.cEIModel.No_Of_Inst;
      }
      else if (this.cEIModel.EMI_Type == 'HalfYearly') {
        this.cEIModel.Tenure = 6 * +this.cEIModel.No_Of_Inst;
      }
      else if (this.cEIModel.EMI_Type == 'Yearly') {
        this.cEIModel.Tenure = 12 * +this.cEIModel.No_Of_Inst;
      }
      else {
        this.cEIModel.Tenure = this.cEIModel.No_Of_Inst;
      }
    }
  }

  addNextStep(data: any) {
    if (+data.ToEMI <= 0 || +data.EMI_Amount < 0) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Fill All Data.", ...this.configSuccess });
      return;
    }
    else if (+data.ToEMI > +this.cEIModel.No_Of_Inst) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "To Emi can not be greater than number of installment.", ...this.configSuccess });
      return;
    }
    else {
      if (+data.FromEMI > +data.ToEMI) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "To EMI should be greater than From EMI.", ...this.configSuccess });
        return;
      }
      data.NoOfEMI = +data.ToEMI - +data.FromEMI + 1;
      data.TotalAmount = data.NoOfEMI * +data.EMI_Amount;
      if (+data.ToEMI < +this.cEIModel.No_Of_Inst) {
        data.disable = true;
        this.stepEMI.push({ FromEMI: +data.ToEMI + 1, ToEMI: '', EMI_Amount: '', disable: false });
      }
      this.getTotlaEmiAndAmount();
    }
  }

  getTotlaEmiAndAmount() {
    if (this.stepEMI.length > 1) {
      let TotalEmi: any = 0, TotalAmount: any = 0
      for (let i = 0; i < this.stepEMI.length; i++) {
        if (this.stepEMI[i].EMI_Amount) {
          TotalEmi = TotalEmi + (+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1);
          TotalAmount = TotalAmount + ((+this.stepEMI[i].ToEMI - +this.stepEMI[i].FromEMI + 1) * +this.stepEMI[i].EMI_Amount);
        }
      }
      this.stepEmiData = { totalEmi: TotalEmi, totalAmount: TotalAmount };
    } else {
      this.stepEmiData = undefined;
    }
  }
  removeLastStep(index: any) {
    this.stepEMI.splice(index, 1);
    this.stepEMI[index - 1].disable = false;
    this.getTotlaEmiAndAmount();
  }


  onCalculateEmiAndIRR() {
    let netAmount: any[] = [];
    let roi: any[] = [];

    if (+this.cEIModel.NetFinance_Amt <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Net Finance Amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.Flat_Rate < 0 &&
      this.cEIModel.IRR_CalculateBy == "ROI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Flat rate should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.EMI_Amount <= 0 &&
      this.cEIModel.IRR_CalculateBy == "FLAT_EMI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.No_Of_Inst <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.Tenure <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Tenure should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      this.cEIModel.IRR_CalculateBy == "FLAT_EMI" &&
      +this.cEIModel.EMI_Amount * +this.cEIModel.No_Of_Inst <
      +this.cEIModel.NetFinance_Amt
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data:
          "No of Installment multiply by EMI amount should be greater than or equal to Net Finance Amount.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.selectedScheme.Amount) {
      netAmount = this.selectedScheme.Amount.split("-");
      roi = this.selectedScheme.ROI.split("-");
      if (
        +this.cEIModel.NetFinance_Amt > +netAmount[1] ||
        +this.cEIModel.NetFinance_Amt < +netAmount[0]
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Net Finance Amount should be in between Scheme Amount.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        this.cEIModel.IRR_CalculateBy == "ROI" &&
        (+this.cEIModel.Flat_Rate > +roi[1] ||
          +this.cEIModel.Flat_Rate < +roi[0])
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Flate Rate should be in between Scheme ROI.",
          ...this.configSuccess,
        });
        return;
      }
      if (+this.cEIModel.Adv_Inst < +this.selectedScheme.AdvanceEMI) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Advance Installment can not be less than Scheme Advance EMI.",
          ...this.configSuccess,
        });
        return;
      }
      this.calculateEmiIrr();
    } else {

      this.calculateEmiIrr();
    }
  }

  calculateEmiIrr() {
    let stepArr: any[] = [];
    if (this.cEIModel.IRR_CalculateBy == "STEP_EMI") {
      this.cEIModel.IsStep = true;
      if (this.stepEMI.length < 1) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please add step EMI first",
          ...this.configSuccess,
        });
        return;
      }
      let lastEMI = this.stepEMI[this.stepEMI.length - 1].ToEMI;
      if (+lastEMI != +this.cEIModel.No_Of_Inst) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Total EMI Should Be Equal To No of Installment",
          ...this.configSuccess,
        });
        return;
      }

      //if (this.stepEmiData == undefined || this.stepEmiData == null) {
      //  this.snackBar.openFromComponent(SnackbarComponent, {
      //    data: "Please recalculate amount.",
      //    ...this.configSuccess,
      //  });
      //  return;
      //}
      if (this.stepEmiData != undefined && +this.stepEmiData.totalAmount < +this.cEIModel.NetFinance_Amt) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data:
            "Total EMI Amount should be  equal or Greater then  Net Finance Amount.",
          ...this.configSuccess,
        });
        return;
      }
      for (let i = 0; i < this.stepEMI.length; i++) {
        for (
          let j = +this.stepEMI[i].FromEMI;
          j <= +this.stepEMI[i].ToEMI;
          j++
        ) {
          if (+this.stepEMI[i].EMI_Amount >= 0) {
            stepArr.push(this.stepEMI[i].EMI_Amount);
          }
        }
      }
    } else {
      this.cEIModel.IsStep = false;
      this.cEIModel.STEP_IRR = 0;
    }
    let _data = { ...this.cEIModel };
    _data.cashflow = stepArr;
    if (this.cEIModel.IsStep) {
      _data.EMI_Amount = this.stepEmiData.totalAmount;
    }
    //  if (this.cEIModel.Adv_Inst > 0) {
    _data.DaysInYear = this.currentUser.DaysInYear;
    // this.lmsService.LMS_GetCalculateEMIIRR(_data).subscribe((res: any) => {
    //    if (res.length > 0) {
    //      _data.NetFinance_Amt = this.cEIModel.NetFinance_Amt - (this.cEIModel.Adv_Inst * res[0].EMI_Amt)
    //      _data.No_Of_Inst = this.cEIModel.No_Of_Inst - this.cEIModel.Adv_Inst;
    //      console.log("_data.NetFinance_Amt", _data.NetFinance_Amt);
    //      this.getCalculateEMIIRR(_data);
    //    }

    //  })
    //} else {
    this.getCalculateEMIIRR(_data);
    //}
  }

  getCalculateEMIIRR(data: any) {
    this.showSpinner = true;
    // data.ProductId = 2;
    data.DaysInYear = this.currentUser.DaysInYear;
    this.lmsService.LMS_GetCalculateEMIIRR(data).subscribe((res: any) => {
      this.showSpinner = false;
      this.emiIrr = res[0];
      setTimeout(() => {
        var elmntToView = document.getElementById("scrollto");
        elmntToView.scrollIntoView();
      }, 500);

      if (this.cEIModel.IRR_CalculateBy == "ROI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfEMI: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
        var amt = this.emiIrr.EMI_Amt.toFixed(2);
        $(function () {
          $("#EMI_Amounts").val(amt);
        });
      }

      if (this.cEIModel.IRR_CalculateBy == "FLAT_EMI") {
        this.stepEMI = [];

        this.stepEMI.push({
          FromEMI: 1,
          ToEMI: this.cEIModel.No_Of_Inst,
          EMI_Amount: this.emiIrr.EMI_Amt.toFixed(2),
          NoOfEMI: this.cEIModel.No_Of_Inst,
          TotalAmount: this.emiIrr.Agreement_Value.toFixed(2),
          disable: true,
        });
        var amt = this.emiIrr.ROI.toFixed(2);
        $(function () {
          $("#Flat_Rate").val(amt);
        });
      }
    });
  }

  onSaveApplication() {
    if (+this.cEIModel.NetFinance_Amt <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Net Finance Amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.Flat_Rate < 0 &&
      this.cEIModel.IRR_CalculateBy == "ROI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Flate rate should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (
      +this.cEIModel.EMI_Amount <= 0 &&
      this.cEIModel.IRR_CalculateBy == "EMI"
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "EMI amount should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.No_Of_Inst <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "No of Installment should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (+this.cEIModel.Tenure <= 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Tenure should be greater than 0.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.cEIModel.IRR_CalculateBy !== "STEP_EMI" &&
      +this.cEIModel.No_Of_Inst * +this.emiIrr.EMI_Amt <
      +this.cEIModel.NetFinance_Amt
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data:
          "EMI multiply with installment should be equal or grater than net finance.",
        ...this.configSuccess,
      });
      return;
    }
    if (this.cEIModel.IRR_Type == "Step") {
      let length = this.stepEMI.length;
      let ToEmi = this.stepEMI[length - 1].ToEMI;
      if (+ToEmi != +this.cEIModel.No_Of_Inst) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Add EMI step.",
          ...this.configSuccess,
        });
        return;
      }
      if (
        this.emiIrr.Disbursement_Amt + this.emiIrr.Interest_Amt !=
        +this.stepEmiData.totalAmount
      ) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data:
            "Total EMI Amount must be equal to  (Disbursement Amount  + Interest Amount)",
          ...this.configSuccess,
        });
        return;
      }
    }
    let _stepEmi: any[] = [];
    this.stepEMI.forEach((obj: any) => {
      _stepEmi.push(this.dataSharingService.cleanObject({ ...obj }));
    })

    if (this.emiIrr.Interest_Amt == null || this.emiIrr.Interest_Amt == 0 || this.emiIrr.Interest_Amt == '') {
      this.emiIrr.Interest_Amt = 0
      this.emiIrr.Case_IRR = 0;
      this.emiIrr.Disbursement_IRR = 0;

    }
    let data = {
      Application: this.dataSharingService.cleanObject({
        //InquiryNo: this.inquiryDetail.InquiryNo,
        InquiryNo: 5457,
        LoanId: this.Loan_id,
        BranchId: 1,
        RestructureRemark: this.RestructureRemark,
        ProductId: this.cEIModel.ProductId,
        Asset_Cost: this.cEIModel.Asset_Cost,
        NetFinance_Amt: this.cEIModel.NetFinance_Amt,
        Flat_Rate: this.emiIrr.ROI,
        No_Of_Inst: this.cEIModel.No_Of_Inst,
        Tenure: this.cEIModel.Tenure,
        Adv_Inst: this.cEIModel.Adv_Inst,
        MgmtFee: 0,  // this.cEIModel.MgmtFee,
        EMI_Type: this.cEIModel.EMI_Type,
        Disbursement_Amt: this.emiIrr.Disbursement_Amt.toFixed(2),
        Interest_Amt: this.emiIrr.Interest_Amt.toFixed(2),
        Agreement_Value: this.emiIrr.Agreement_Value.toFixed(2),
        LTV: this.emiIrr.LTV.toFixed(2),
        EMI_Amt: this.emiIrr.EMI_Amt,
        Case_IRR: this.emiIrr.Case_IRR.toFixed(2),
        Loan_Purpose: this.cEIModel.Purpose,
        Loan_SchemeId: this.selectedScheme.SchemeId,
        FirstEMIDate: new Date(),   // this.cEIModel.First_EMI_Date,
        IRR_Type: this.cEIModel.IRR_Type,
        LoginUserId: this.currentUser.userId,
        ROI: this.emiIrr.ROI,
        EMI_Amount: this.cEIModel.EMI_Amount,
        IRR_CalculateBy: this.cEIModel.IRR_CalculateBy,
      }),
      StepIRR: _stepEmi,
    };

    // this.lmsService.LMS_Update_Financial_Restructured({ JSON: JSON.stringify(data) }).subscribe((res: any) => {
    //   if (res) {
    //     if (res[0] ? res[0].CODE >= 0 : false) {
    //       this.snackBar.openFromComponent(SnackbarComponent, {
    //         data: res[0].MSG,
    //         ...this.configSuccess,
    //       });
    //     } else if (res[0]) {
    //       this.snackBar.openFromComponent(SnackbarComponent, {
    //         data: res[0].MSG,
    //         ...this.configSuccess,
    //       });
    //     }
    //   }
    // });
  }

  onViewLoanStatement() {
    this.loanSummary.LoanId = parseInt(this.Loan_id);
    this.loanSummary.ApplicationNo = this.LoanAllDetail[0].ApplicationNo;
    this.loanSummary.LoanNo = this.LoanAllDetail[0].LoanNo;
    this.dataSharingService.LmsViewLoanStatement.next(this.loanSummary);
    $('#ViewLoanStatementModel').modal('show');
    $("#ViewLoanStatementModel").css("z-index", "1050");
  }

  onCloseViewLoanStatement() {
    $('#ViewLoanStatementModel').modal('hide');
  }
  chargesDetail() {
    this.lmsService
      .Get_LMS_Loan_Restructure_Charges_Detail({
        ApplicationNo: this.LoanAllDetail[0].ApplicationNo,
        Loan_No: this.LoanAllDetail[0].LoanNo,
        VoucherDate: this.RescheduleDate
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.displayedColumnsODCharges = [
            "ChagesHead",
            "TranTypeDR",
            "TranTypeCR",
            "TranTypeTotal",
            "TranTypeTotalInclude",
            "excludedcharges"
          ]

          res.forEach((obj: any) => {
            obj.excludedcharges = false;
            obj.TranTypeTotalInclude = obj.TranTypeTotal;
            if (obj.HeadId == 32 && Number(obj.Balance) != 0) {
              this.errorShowChequeinTransit = true;
            }
          });
          this.dataSourceLoanODCharges = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.dataSourceLoanODCharges.sort = this.sort;

          this.showSpinner = false;
        }
      });
  }
  calculateChargesTranTypeDR(Type) {
    if (this.dataSourceLoanODCharges != null && Type == "OD") {
      let sum = 0.0;
      for (
        let i = 0;
        i < this.dataSourceLoanODCharges.filteredData.length;
        i++
      ) {
        //if(this.dataSourceLoanODCharges.filteredData[i]["excludedcharges"]!=true){

        sum += Number(
          this.dataSourceLoanODCharges.filteredData[i]["TranTypeDR"]
        );
        // }
      }
      return sum.toFixed(2);
    }

    return 0;
  }
  calculateChargesTranTypeCR(Type) {
    if (this.dataSourceLoanODCharges != null && Type == "OD") {
      let sum = 0.0;
      for (
        let i = 0;
        i < this.dataSourceLoanODCharges.filteredData.length;
        i++
      ) {
        //if(this.dataSourceLoanODCharges.filteredData[i]["excludedcharges"]!=true){

        sum +=
          Number(
            this.dataSourceLoanODCharges.filteredData[i]["TranTypeCR"]
          );
        //}
      }
      return sum.toFixed(2);
    }

    return 0;
  }
  calculateChargesTranTypeTotal(Type) {
    if (this.dataSourceLoanODCharges != null && Type == "OD") {
      let sum = 0;
      let DR = this.calculateChargesTranTypeDR("OD");
      let CR = this.calculateChargesTranTypeCR("OD");
      sum = Number(DR) - Number(CR);
      if (sum >= 0) {
        this.errorShow = false;
        return sum.toFixed(2).toString() + " DR";
      }
      else {
        this.errorShow = true;
        return sum.toFixed(2).toString().split("-")[1] + " CR"
      };
    }


    return 0;
  }
  calculateChargesTranTypeTotalInclude(Type) {
    if (this.dataSourceLoanODCharges != null && Type == "OD") {
      let sum = 0;
      let DR = this.calculateChargesTranTypeDR("OD");
      let CR = this.calculateChargesTranTypeCR("OD");
      sum = Number(DR) - Number(CR);
      for (
        let i = 0;
        i < this.dataSourceLoanODCharges.filteredData.length;
        i++
      ) {
        if (this.dataSourceLoanODCharges.filteredData[i]["excludedcharges"] == true) {
          this.dataSourceLoanODCharges.filteredData[i]["TranTypeTotalInclude"] = 0;
          sum -=( Number( this.dataSourceLoanODCharges.filteredData[i]["TranTypeDR"]) - Number( this.dataSourceLoanODCharges.filteredData[i]["TranTypeCR"] )) ;
        } else {
          this.dataSourceLoanODCharges.filteredData[i]["TranTypeTotalInclude"] = this.dataSourceLoanODCharges.filteredData[i]["TranTypeTotal"];
        }
        //if (this.dataSourceLoanODCharges.filteredData[i]["excludedcharges"] == true) {
        //  this.dataSourceLoanODCharges.filteredData[i]["TranTypeTotalInclude"] = 0;
        //  sum -= Number(
        //    this.dataSourceLoanODCharges.filteredData[i]["TranTypeCR"]
        //  );
        //} else {
        //  this.dataSourceLoanODCharges.filteredData[i]["TranTypeTotalInclude"] = this.dataSourceLoanODCharges.filteredData[i]["TranTypeTotal"];
        //}
      }
      if (sum >= 0) {
        this.errorShow = false;
        this.NewLoanAmount = sum;
        return sum.toFixed(2).toString() + " DR";
      }
      else {
        this.errorShow = true;
        return sum.toFixed(2).toString().split("-")[1] + " CR"
      };
    }


    return 0;
  }
  excludedcharges(row, event) {
    console.log("aaa", event)
    row.excludedcharges = event.target.checked;
    this.calculateChargesTranTypeTotal("OD")
  }
  AnyChange() {
    this.OnCalculateActive = false;
  }
  RescheduleDateChange() {
    this.OnCalculateActive = false;
    this.chargesDetail();
  }

  //getFormattedAmount(amount) {
  //  return this.decimalPipe.transform(amount, '1.2-2');
  //}
}
