import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "../../Shared/constantUrl";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { LmsService } from "../services/lms.service";
import { MasterService } from "../../Shared/app.Masters.Service";
import * as moment from "moment";

@Component({
  selector: "app-lms-tvr",
  templateUrl: "./lms-tvr.component.html",
  styleUrls: ["./lms-tvr.component.scss"],
})
export class LmsTvrComponent implements OnInit {
  currentUser: any;
  TvrProcessList: any[] = [];
  showSpinner: boolean = false;
  TvrinViewMode: boolean = true;
  view: boolean = false;
  status: any = "Pending";
  dataSource: any;
  EditTVR: boolean = false;
  //EditFI: boolean = false;
  today: Date = new Date();
  displayedColumns: string[] = [];
  CurrentDate: any = new Date();

  customerBaseUrl: any = constantUrl.customerProfilePicUrl;
  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  AllowExtension: any = constantUrl.AllowExtension;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  TVR_DoneBy: any;
  TVR_DoneOn: any = new Date();

  applicationCreatedDate: any = new Date();
  customerData: any = {};
  //customerFIData: any = {};
  private _encdec;
  tvrModel: any = { CustomerTVR: {}, TVRQuestionAnswer: [] };
  loading: boolean = false;
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  first: boolean = true;
  last: boolean = true;
  first1: boolean = true;
  last1: boolean = true;
  TvrcustomerData: any;
  TvrcustomerModel: any;
  FIcustomerModel: any;
  cusTvrSave: any = {};
  // cusFISave: any = {};
  questionData: any[] = [];
  TvrData: any;
  //FIData: any;
  FamilyMemberModel: any = {};
  CollectionExecutiveData: any = [];

  TVRDetails: any = {};
  //FIDetails: any = {};
  Fileurl: any;
  //LoanAcNo: any;
  TVRDetail: boolean = true;
  //FIDetail: boolean = true;
  viewFI: boolean = false;
  viewTVR: boolean = false;
  FormTVRShow: boolean = true;
  DetailTVRShow: boolean = false;
  // FormFIShow: boolean = true;
  // DetailFIShow: boolean = false;
  ProductList: any = [];
  getLmsHeaderProduct: string;

  PageAcess: any;

  addressDataSource: any;
  displayedAddressColumns: string[] = [
    "AddressId",
    "AddressType",
    "Address",
    "LandMark",
    "Tehsil_Name",
    "District_Name",
    "PinCode",
    "AddressRentBuy",
    "TotalYearsOnAddress",
    "IsCommunicationAddress",
  ];
  docDataSource: any;
  displayedDocColumns: string[] = [
    "Id",
    "Categoy",
    "Doc_Name",
    "KYC_DocNumber",
    "UploadOn",
    "View",
  ];
  familyDataSource: any;
  displayedFamilyColumns: string[] = [
    "Id",
    "ReletionWithCustomer",
    "MemberName",
    "ContactNumber",
    "GenderAge",
    "_OccupationType",
    "HowMuchEarn",
  ];
  customerOccupation: any;
  expenditureDataSource: any;
  displayedExpenditureColumns: string[] = [
    "Id",
    "ExpenditureType",
    "ExpenditureAmount",
    "Remark",
  ];
  loanDataSource: any;
  displayedLoanColumns: string[] = [
    "Id",
    "LoanType",
    "FirmName",
    "LoanAmount",
    "LoanTakenYear",
    "EMI",
    "Status",
  ];
  customerCRCDetail: any;
  tvrDataSource: any;
  tvrReferenceSource: any;
  tvrFISource: any;
  displayedTVRColumns: string[] = [
    "Id",
    "TVR_Question",
    "TVR_Question_Hindi",
    "TVR_QuestionAnswer",
  ];
  displayedRefernceColumns: string[] = [
    "Id",
    "Reference",
    "PersonName",
    "PersonAddress",
    "ContactNo",
    "Ref_NoYear",
    "Remark",
    "Ref_AddOn",
  ];
  displayedFIColumns: string[] = [
    "FI_QueId",
    "FI_Title",
    "FI_Title_Hindi",
    "FI_Answer",
  ];
  zoom: number = 13;
  lat: any = 26.92207;
  lng: any = 75.778885;
  ShowMapAddress: any = "";
  applicationId: any;
  customerId: any;
  customerDetail: any;
  private _MasterService;
  @Input() loanSummary: any;
  docModal: any = { DocumentImages: [] };
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  questionOptionData: any[] = [];
  inViewMode: boolean = false;

  //Final Submit
  Recommendation: any = "";
  Remarks: any;
  AllAnswer: any;

  CustomerId: any;
  ShowDiv: boolean = false;

  ApplicationDetail: any;

  IsSave: boolean = false;
  IsUpdate: boolean = false;
  IsEdit: boolean = false;
  IsDisabled: boolean = false;
  tvrDateString: any;
  TVR_DoneByName: any;

  constructor(
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService,
    private lmsService: LmsService,
    private route: ActivatedRoute,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this._encdec = encdec;
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("TVR");

    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.LMS_GetLoanDetails();

    this.GetCollectionExecutive();

    sessionStorage.removeItem("curFIpage");
    sessionStorage.removeItem("curTVRpage");

    let AllPage = JSON.parse(sessionStorage.getItem("AllPageAcess"));
    this.PageAcess = AllPage.filter((x) => x.Page_Name == "TVR");

    if (
      this.PageAcess == "" ||
      this.PageAcess == null ||
      this.PageAcess == undefined
    ) {
      this.RedirectTo();
    }
  }
  GetCollectionExecutive() {
    this.lmsService
      .Get_Lms_CollectionExecutive({
        Branch_Id: this.loanSummary.BranchId,
        ProductId: this.loanSummary.ProductId,
      })
      .subscribe((response) => {
        this.CollectionExecutiveData = response;
      });
  }

  onNext() {
    this._MasterService
      .GetProductListById({ ProductId: this.loanSummary.ProductId })
      .subscribe((result) => {
        this.ProductList = JSON.parse(JSON.stringify(result));
        var H = "",
          C = "",
          G = "",
          StatusTVR = false;
        H = ("" + this.ProductList[0].TVR.split(",")[0]).trim();
        C = ("" + this.ProductList[0].TVR.split(",")[1]).trim();
        G = ("" + this.ProductList[0].TVR.split(",")[2]).trim();
        var HFI = "",
          CFI = "",
          GFI = "",
          StatusFI = false;
        HFI = ("" + this.ProductList[0].FI.split(",")[0]).trim();
        CFI = ("" + this.ProductList[0].FI.split(",")[1]).trim();
        GFI = ("" + this.ProductList[0].FI.split(",")[2]).trim();

        for (let i = 0; i < this.customerData.length; i++) {
          if (this.customerData[i].CustomerType.charAt(0) == H.trim()) {
            if (this.customerData[i].TVR_Status == "Completed")
              StatusTVR = true;
            else {
              StatusTVR = false;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Please Fill Complete TVR Of Hirer",
                ...this.configSuccess,
              });
              break;
            }
          }
          if (this.customerData[i].CustomerType.charAt(0) == C.trim()) {
            if (this.customerData[i].TVR_Status == "Completed")
              StatusTVR = true;
            else {
              StatusTVR = false;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Please Fill Complete TVR Of Co-Borrower",
                ...this.configSuccess,
              });
              break;
            }
          }
          if (this.customerData[i].CustomerType.charAt(0) == G.trim()) {
            if (this.customerData[i].TVR_Status == "Completed")
              StatusTVR = true;
            else {
              StatusTVR = false;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Please Fill Complete TVR Of Guarantor",
                ...this.configSuccess,
              });
              break;
            }
          }
        }

        var Next = false;

        if (this.TVRDetail == true) {
          if (StatusTVR == true) Next = true;
          else Next = false;
        }
        if (Next == true) {
          this.lmsService
            .SaveNext_Loan({
              Loan_Id: this.loanSummary.LoanId,
              CommandName: "TVR",
              isStatus: 1,
            })
            .subscribe((response) => {
              this.action.emit("next");
            });
        }
        //else {
        //  this.snackBar.openFromComponent(SnackbarComponent, { data: "Please Fill Complete CRC", ...this.configSuccess });
        //}
      });
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: `los-tvr-process-${this.status}-list`,
      sheet: "tvr process",
    });
  }
  //EditFIdetail() {
  //  this.DetailFIShow = false;
  //  this.FormFIShow = true;
  //  this.EditFI = true;
  //}

  RedirectTo() {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: "You have not authorize to access this page ?",
      ...this.configSuccess,
    });
    this.router.navigate([`lms-dashboard/lms-modifyBooking-list`]);
    return;
  }

  LMS_GetLoanDetails() {
    this.showSpinner = true;
    this.lmsService
      .LMS_GetLoanDetails({ Loan_Id: this.loanSummary.LoanId })
      .subscribe((res: any) => {
        console.log("LMS_GetLoanDetails", res);
        this.showSpinner = false;
        this.applicationCreatedDate = res.Item1[0].Application_CreateDate;
        console.log("App Date", this.applicationCreatedDate);

        this.customerData = res.Item2;
        if (res.Item2[0]) {
          this.OnClickTVRQuestions(res.Item2[0]);
        }
      });
  }
  OnClickTVRQuestions(row) {
    this.ShowDiv = true;
    console.log("row", row);

    this.CustomerId = row.CustomerId;
    this.tvrModel.CustomerTVR.TVR_ApplicationId = this.loanSummary.LoanId;
    this.tvrModel.CustomerTVR.TVR_CustomerId = this.CustomerId;
    this.tvrModel.CustomerTVR.TVR_LoginUserId = this.currentUser.userId;
    this.tvrModel.CustomerTVR.TVR_Remark = this.Remarks;
    this.tvrModel.CustomerTVR.TVR_Recommendation = this.Recommendation;
    this.GetLosCustomerTVRQuestionAnswer();
  }

  GetLosCustomerTVRQuestionAnswer() {
    //this.showSpinner = true;
    this.losService
      .LOS_GetCustomerTVRDetails({
        ApplicationId: this.loanSummary.LoanId,
        CustomerId: this.CustomerId,
      })
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.ApplicationDetail = res.Item1[0];
        this.Recommendation = this.ApplicationDetail.Recommendation;
        this.Remarks = this.ApplicationDetail.Remark;
        this.TVR_DoneBy = this.ApplicationDetail.TVR_DoneBy;

        this.TVR_DoneByName = this.ApplicationDetail.TVR_DoneByName;
        // console.log("TVRRRR", this.TVR_DoneByName);

        this.TVR_DoneOn = this.ApplicationDetail.TVR_DoneOn
          ? new Date(this.ApplicationDetail.TVR_DoneOn)
          : new Date();

        this.inViewMode =
          this.ApplicationDetail.TVR_Status == "Completed" ||
          this.ApplicationDetail.TVR_Status == "Rejected";
        this.questionData = res.Item3;
        console.log("this.questionData", this.questionData);

        this.AllAnswer = res.Item3.filter(
          (x) => x.TVR_QuestionAnswer != "" && x.TVR_QuestionAnswer != null
        );
        console.log("this.AllAnswer", this.AllAnswer);
        if (this.Remarks != "" && this.Remarks != null) {
          this.IsEdit = true;
          this.IsSave = false;
          this.IsUpdate = false;
          this.IsDisabled = true;
        } else {
          this.IsEdit = false;
          this.IsSave = true;
          this.IsDisabled = false;
        }

        this.questionOptionData = res.Item4;

        this.questionData.forEach((obj: any) => {
          if (
            obj.QuestionType == "Multi-Selection" &&
            obj.TVR_QuestionAnswer != null
          ) {
            obj.TVR_QuestionAnswer = obj.TVR_QuestionAnswer.split(",");
          }
        });
      });
  }

  fileChangeListener(data: any, files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB) {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      data.TVR_QuestionAnswer = "";
      delete data.DocData;
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      data.TVR_QuestionAnswer = "";
      delete data.DocData;
      return;
    }

    let reader = new FileReader();
    let _pre = new Date().getTime();
    data.TVR_QuestionAnswer = _pre + "_" + files[0].name;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      data.DocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }

  removeFile(data: any, input: any) {
    input.value = "";
    data.TVR_QuestionAnswer = "";
    delete data.DocData;
  }

  onSaveTvr() {
    this.tvrModel.TVRQuestionAnswer = [];
    this.questionData.forEach((obj: any) => {
      if (obj.QuestionType == "Multi-Selection") {
        this.tvrModel.TVRQuestionAnswer.push({
          TVR_QuestionId: obj.TVR_QueId,
          TVR_QuestionAnswer: obj.TVR_QuestionAnswer.join(","),
        });
      } else if (obj.QuestionType == "Upload") {
        this.tvrModel.TVRQuestionAnswer.push({
          TVR_QuestionId: obj.TVR_QueId,
          TVR_QuestionAnswer: obj.TVR_QuestionAnswer,
        });
        this.uploadDoc({
          ApplicationNo: this.loanSummary.ApplicationNo,
          DocName: obj.TVR_QuestionAnswer,
          DocData: obj.DocData,
        });
      } else {
        this.tvrModel.TVRQuestionAnswer.push({
          TVR_QuestionId: obj.TVR_QueId,
          TVR_QuestionAnswer: obj.TVR_QuestionAnswer,
        });
      }
    });
    if (this.tvrModel.TVRQuestionAnswer.length == 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "TVR Question is required.",
        ...this.configSuccess,
      });
      return false;
    }
    this.tvrModel.CustomerTVR.TVR_ApplicationId = this.loanSummary.LoanId;
    this.tvrModel.CustomerTVR.TVR_CustomerId = this.CustomerId;
    this.tvrModel.CustomerTVR.TVR_LoginUserId = this.currentUser.userId;
    this.tvrModel.CustomerTVR.TVR_Remark = this.Remarks;
    this.tvrModel.CustomerTVR.TVR_Recommendation = this.Recommendation;
    this.tvrModel.CustomerTVR.TVR_DoneBy = this.TVR_DoneBy;
    this.tvrModel.CustomerTVR.TVR_DoneOn = this.TVR_DoneOn;

    this.loading = true;

    // console.log("hhhhh", new Date(this.applicationCreatedDate));
    // console.log("hhhhh tvr", new Date(this.TVR_DoneOn));

    if (new Date(this.applicationCreatedDate) <= new Date(this.TVR_DoneOn)) {
      this.losService
        .LOS_SaveCustomerTVR({ JSON: JSON.stringify(this.tvrModel) })
        .subscribe((res: any) => {
          this.loading = false;
          if (res[0].CODE >= 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
            this.GetLosCustomerTVRQuestionAnswer();
            this.LMS_GetLoanDetails();
            //this.QuickLoan_Save_PageProcess('TVR Questions');
            //this.onNext()
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: res[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "TVR Date always greater than Application Date.",
        ...this.configSuccess,
      });

      this.loading = false;
    }
  }

  uploadDoc(data: any) {
    this.losService.UploadCrcDoc(data).subscribe((res: any) => {});
  }

  onEditCustomerTVRAnswer() {
    this.IsEdit = false;
    this.IsUpdate = true;
    this.IsDisabled = false;
  }

  getExecutiveName(empId): string {
    // console.log("Finding executive name for ID:", empId);
    const executive = this.CollectionExecutiveData.find(
      (exec) => exec.EmpId == empId
    );
    // console.log("Found executive:", executive);
    return executive ? executive.Emp_FirstName : "";
  }
}
