<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div *ngIf="isCalEMIForm">
  <div [ngClass]="{ fixheight1: PageType != 'A', fixheight2: PageType == 'A' }">
    <div class="formborder mt-2">
      <div
        class="row mt-3 mb-2 m-0 h-b w-b align-items-center justify-content-between"
        style="width: 97%; height: 36px"
      >
        <div class="col-md-12">
          <h1 class="fs-12 m-0">Loan Financial Details</h1>
        </div>
      </div>

      <div class="row mb-4 m-0 mt-3">
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Asset Cost</h6>
          <p class="lead fs-12 fw-7">₹ {{ applicationDetail.AssetCost }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Net Finance</h6>
          <p class="lead fs-12">₹ {{ applicationDetail.NetFinance }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">ROI</h6>
          <p class="lead fs-12">{{ applicationDetail.Flat_Rate }}%</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Tenure (Months)</h6>
          <p class="lead fs-12">{{ applicationDetail.Tenure }} Months</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">No of Installment</h6>
          <p class="lead fs-12">{{ applicationDetail.No_Of_Instl }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Adv. Installment</h6>
          <p class="lead fs-12">{{ applicationDetail.Adv_Instl }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Disbursement Amount</h6>
          <p class="lead fs-12">₹ {{ applicationDetail.DisbursementAmt }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Agreemnent Value</h6>
          <p class="lead fs-12">₹ {{ applicationDetail.AgreementValue }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Interest Amount</h6>
          <p class="lead fs-12">₹ {{ applicationDetail.InterestAmt }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">EMI Amount</h6>
          <p class="lead fs-12">₹ {{ applicationDetail.EMIAmount }}</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Case IRR</h6>
          <p class="lead fs-12">{{ applicationDetail.Case_IRR.toFixed(2) }}%</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Disbursement IRR</h6>
          <p class="lead fs-12">
            {{ applicationDetail.Disbursement_IRR.toFixed(2) }}%
          </p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">LTV</h6>
          <p class="lead fs-12">{{ applicationDetail.LTV }}%</p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Margin</h6>
          <p class="lead fs-12">
            ₹ {{ applicationDetail.Margin < 0 ? 0 : applicationDetail.Margin }}
          </p>
        </div>
        <div class="col-md-2 p-0">
          <h6 class="fs-12">Expiry Date</h6>
          <p class="lead fs-12">{{ applicationDetail.ExpiryDate }}</p>
        </div>
      </div>
    </div>

    <div class="row m-0 mb-2 mt-2 align-items-center justify-content-between">
      <h1 class="fs-12 m-0">Customer Details</h1>
    </div>

    <div class="row m-0 mb-4">
      <div class="table-responsive">
        <mat-table [dataSource]="dataCustomerSource">
          <ng-container matColumnDef="Number">
            <mat-header-cell
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ i + 1 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerId" style="display: none">
            <mat-header-cell
              class="grid-header"
              style="max-width: 100px; max-height: 5px; display: none"
              *matHeaderCellDef
            >
              CustomerId
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="max-width: 100px; display: none; max-height: 5px"
            >
              {{ row.CustomerId }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Customer
            </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" class="grid-cell">
              <app-lms-customer-view-model
                [customerId]="row.CustomerId"
                [index]="i"
                >{{ row.Customer }}</app-lms-customer-view-model
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerType">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >CustomerType</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.CustomerType == "Hirer" ? "Borrower" : row.CustomerType }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="GenderAge">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Gender Age
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.GenderAge }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="PhoneNo">
            <mat-header-cell class="grid-header" *matHeaderCellDef
              >PhoneNo</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <div *ngIf="data.IsMaskingMobile">
                {{ row.PhoneNo | mask }}
              </div>

              <div *ngIf="!data.IsMaskingMobile">
                {{ row.PhoneNo }}
              </div>

              <i
                *ngIf="row.PhoneNoIsVerified.toLowerCase() == 'true'"
                class="fa fa-check-circle ml-2"
                style="color: green"
              ></i>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Relation_With_Hirer">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Relation With Borrower</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Relation_With_Hirer }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="ExistingCustomer">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Existing
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.ExistingCustomer }}
            </mat-cell>
          </ng-container>

          <!-- <ng-container matColumnDef="Action">
            <mat-header-cell class="grid-header j-c-center" style="max-width:60px" *matHeaderCellDef>
              Action
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell j-c-center" style="max-width:60px">
              <i class="fa fa-pencil-alt right" style="cursor: pointer;color:green;font-size:16px;" aria-label="true"
                (click)="Get_CustomerDetailsApp(row)"></i>
              <i class="fa fa-minus-circle font-size-15 ml-3" (click)="removeCustomerFromApp(row)"
                style="color:red;cursor: pointer;" aria-label="true"></i>
              <a href="javascript:void(0)" (click)="goToCustomerDetail(row)" *ngIf="inViewMode">view</a>
            </mat-cell>
          </ng-container> -->
          <mat-header-row
            *matHeaderRowDef="displayedCustomerColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedCustomerColumns"
          ></mat-row>
        </mat-table>
      </div>
    </div>

    <div class="row m-0 mb-2 align-items-center justify-content-between">
      <h1 class="fs-12 m-0">Customer Address Details</h1>
    </div>

    <div class="row m-0 mb-2">
      <div class="table-responsive">
        <mat-table [dataSource]="dataCustomerAddressSource">
          <ng-container matColumnDef="CustomerId">
            <mat-header-cell
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
            >
              #
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ i + 1 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Customer
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Present_Address">
            <mat-header-cell class="grid-header pl-2 pr-2" *matHeaderCellDef>
              Present Address
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell pl-2 pr-2">
              {{ row.Present_Address }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Rent_Own">
            <mat-header-cell
              class="grid-header"
              style="max-width: 80px"
              *matHeaderCellDef
            >
              Rent/Own
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="max-width: 80px"
            >
              {{ row.Rent_Own }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="TotalYearsOnAddress">
            <mat-header-cell
              class="grid-header"
              style="max-width: 100px"
              *matHeaderCellDef
            >
              How many years
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="max-width: 100px"
            >
              {{ row.TotalYearsOnAddress }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Permanent_Address">
            <mat-header-cell class="grid-header pl-2 pr-2" *matHeaderCellDef>
              Permanent Address</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell pl-2 pr-2">
              {{ row.Permanent_Address }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Work_Address">
            <mat-header-cell class="grid-header pl-2 pr-2" *matHeaderCellDef>
              Work Address
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell pl-2 pr-2">
              {{ row.Work_Address }}
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedCustomerAddressColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedCustomerAddressColumns"
          ></mat-row>
        </mat-table>
      </div>
    </div>

    <div class="row m-0 mb-2 align-items-center justify-content-between">
      <h1 class="fs-12 m-0">Customer KYC Details</h1>
    </div>

    <div class="row m-0 mb-2">
      <div class="table-responsive">
        <mat-table [dataSource]="dataCustomerDocSource">
          <ng-container matColumnDef="CustomerId">
            <mat-header-cell
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
            >
              #
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 50px; max-height: 5px"
            >
              {{ i + 1 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Customer
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Doc_Title">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Doc Title
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Doc_Title }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Doc_Number">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              Doc Number</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <div *ngIf="data.IsMasking">
                {{ row.Doc_Number | mask }}
              </div>

              <div *ngIf="!data.IsMasking">
                {{ row.Doc_Number }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Doc_FileName">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              View
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              <a
                href="{{ customerProfileBaseUrl }}{{ row.CustomerId }}/{{
                  row.Doc_FileName
                }}"
                target="_blank"
                *ngIf="row.Doc_FileName"
              >
                <i
                  *ngIf="row.Doc_FileName"
                  class="fa fa-eye"
                  style="font-size: large"
                ></i>
              </a>
              <a
                href="{{ customerProfileBaseUrl }}{{ row.CustomerId }}/{{
                  row.Doc_FileName1
                }}"
                class="ml-2"
                target="_blank"
                *ngIf="row.Doc_FileName1"
              >
                <i
                  *ngIf="row.Doc_FileName1"
                  class="fa fa-eye"
                  style="font-size: large"
                ></i>
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="UploadOn">
            <mat-header-cell class="grid-header" *matHeaderCellDef>
              UploadOn</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.UploadOn }}
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedCustomerDocColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedCustomerDocColumns"
          ></mat-row>
        </mat-table>
      </div>
    </div>
  </div>

  <div class="row col-md-12 m-0 mt-3 justify-content-end">
    <button
      type="button"
      class="ml-4"
      (click)="onSaveLoanInfo()"
      class="mt-3 btn font-size-12 button-btn"
      [class.spinner]="loading"
    >
      Next <i class="fa fa-arrow-right"></i>
    </button>
  </div>
</div>
