<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div
    class="row m-0 formborder d-flex align-items-center justify-content-between"
  >
    <div class="col-md-3 p-1">
      <select
        name="FilterStatusId"
        id="FilterStatusId"
        class="form-control input-text-css"
        (change)="LOS_GetMyLeadsList()"
        [(ngModel)]="status"
      >
        <option value="Assigned">Assigned</option>
        <option value="Hold">Hold</option>
        <option value="Completed">Completed</option>
        <option value="Rejected">Rejected</option>
      </select>
    </div>

    <div class="col-md-3 p-1 text-right">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="table-responsive mt-3">
    <mat-table
      [dataSource]="dataSource"
      matSort
      matTableExporter
      #exporter="matTableExporter"
      [hiddenColumns]="[
        displayedColumns.indexOf('Assign'),
        displayedColumns.indexOf('Reject')
      ]"
      style="overflow: auto; max-width: 100%; height: 60vh"
    >
      <ng-container matColumnDef="InquiryId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="InquiryNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Inquiry No
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.InquiryNo }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Branch">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Branch
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ContactNumber">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Number
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          <div *ngIf="currentUser.IsMaskingMobile">
            {{ row.ContactNumber | mask }}
          </div>

          <div *ngIf="!currentUser.IsMaskingMobile">
            {{ row.ContactNumber }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Customer
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerAddress">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Address
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerAddress }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="LoanAmount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Loan Amount
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          ₹ {{ row.LoanAmount.toFixed(2) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Purpose">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Purpose
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          data-toggle="tooltip"
          data-placement="left"
          title="{{ row.Purpose }}"
        >
          {{ row.Purpose.slice(0, 20)
          }}{{ row.Purpose.length > 20 ? "..." : "" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Source">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Source
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Source }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CreateOn">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          CreateOn
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CreateOn }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ApplicationNo" *ngIf="status == 'Completed'">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Application No
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.ApplicationNo }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="RejectionReason" *ngIf="status == 'Rejected'">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Rejection Reason
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          data-toggle="tooltip"
          data-placement="left"
          title="{{ row.Purpose }}"
        >
          {{ (row.RejectionReason || "").slice(0, 20)
          }}{{ (row.RejectionReason || "").length > 20 ? "..." : "" }}
        </mat-cell>
      </ng-container>

      <ng-container
        matColumnDef="Action"
        *ngIf="status == 'Assigned' || status == 'Hold'"
      >
        <mat-header-cell
          mat-sort-header
          class="grid-header j-c-center"
          style="max-width: 60px"
          *matHeaderCellDef
        >
          Action
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell j-c-center"
          style="max-width: 60px"
        >
          <i
            class="fa fa-arrow-circle-right arrow-circle action-btn"
            (click)="goToDetail(row)"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div class="row m-0 align-items-center">
      <div class="col-md-4">
        <button
          class="mt-3 btn font-size-12 button-btn"
          (click)="exportTable()"
        >
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<table
  id="quickloan_my_lead_list"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Inquiry No
    </th>
    <th
      *ngIf="status == 'Completed'"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      Application No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer Address
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Contact Number
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Purpose
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Create On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Branch
    </th>
    <th
      *ngIf="status == 'Rejected'"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      Rejected Reason
    </th>
  </tr>

  <tr *ngFor="let x of CreditAssignmentList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.InquiryNo }}</td>
    <td *ngIf="status == 'Completed'">{{ x.ApplicationNo }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.CustomerAddress }}</td>
    <td>
      <div *ngIf="currentUser.IsMaskingMobile">
        {{ x.ContactNumber | mask }}
      </div>

      <div *ngIf="!currentUser.IsMaskingMobile">
        {{ x.ContactNumber }}
      </div>
    </td>
    <td>{{ x.LoanAmount.toFixed(2) }}</td>
    <td>{{ x.Purpose }}</td>
    <td>{{ x.Source }}</td>
    <td>{{ x.CreateOn }}</td>
    <td>{{ x.Branch }}</td>
    <td *ngIf="status == 'Rejected'">
      {{ (x.RejectionReason || "").slice(0, 20)
      }}{{ (x.RejectionReason || "").length > 20 ? "..." : "" }}
    </td>
  </tr>
</table>
