<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div
    class="row m-0 mt-3"
    *ngIf="applicationDetail && TypeLoan != 'QuickLoan'"
  >
    <div class="col-md-3">
      <h6 class="fs-12">Application No</h6>
      <p class="fs-12 fw-7">{{ applicationDetail.ApplicationNo }}</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Loan Product</h6>
      <p class="fs-12">{{ applicationDetail.Product }}</p>
    </div>
    <div class="col-md-3">
      <h6 class="fs-12">Branch</h6>
      <p class="fs-12">{{ applicationDetail.Branch }}</p>
    </div>
  </div>
  <!-- <div class="col-md-3">
    <mat-form-field [hidden]="!dataSource">
      <input matInput  placeholder="Search ....">
    </mat-form-field>
  </div> -->
  <div class="table-responsive">
    <mat-table
      [dataSource]="dataSource"
      matSort
      matTableExporter
      #exporter="matTableExporter"
      [hiddenColumns]="[]"
    >
      <ng-container matColumnDef="ApplicationId">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >#</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Application_No">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Application No
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Application_No }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="display: none"
          *matHeaderCellDef
        >
          Customer Id</mat-header-cell
        >
        <mat-cell *matCellDef="let row" style="display: none" class="grid-cell">
          {{ row.CustomerId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Customer">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Customer</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Customer }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="DocumentId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="display: none"
          *matHeaderCellDef
        >
          Document Id</mat-header-cell
        >
        <mat-cell *matCellDef="let row" style="display: none" class="grid-cell">
          {{ row.DocumentId }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Category">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef
          >Category</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Category }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="DocName">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Document Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.DocName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Action">
        <mat-header-cell
          mat-sort-header
          class="grid-header j-c-center"
          style="max-width: 60px"
          *matHeaderCellDef
          >Action</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          class="grid-cell j-c-center"
          style="max-width: 60px"
        >
          <i
            class="fa fa-upload"
            (click)="onAddPendingDocument(row)"
            *ngIf="LoanStatus == 'P' || LoanStatus == 'R'"
            style="cursor: pointer; color: green; font-size: 16px"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div class="row m-0 align-items-center" style="background: #fff">
      <div
        class="col-md-4"
        *ngIf="ApplicationId == null || ApplicationId == undefined"
      >
        <button class="btn font-size-12 button-btn" (click)="back()">
          <i class="mr-1" style="font-size: medium; cursor: pointer"></i> Cancel
        </button>
      </div>
      <div [ngClass]="TypeLoan == 'QuickLoan' ? 'col-md-12' : 'col-md-8'">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<!--<div class="modal fade in" id="addPendingDocument" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     data-backdrop="false" style="background-color: rgba(0, 0, 0, 0.5);">
  <form #f="ngForm" (ngSubmit)="onSavePendingDocumentData()" novalidate>
    <div class="modal-dialog">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane"  class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px;">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">{{HeaderText}}s</h6>
          <button type="button" (click)="OnClose()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="far text-black fa-times-circle"></i></span>
          </button>
        </div>
        <div class="modal-body" *ngIf="kycModel">
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1"> Customer Name </div>
            <div class="col-md-8 p-1">{{Customer}}</div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1"> Pending Doc </div>
            <div class="col-md-8 p-1">{{DocName}}</div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-4 p-1"> <span class="required-lable">Doc No.</span> </div>
            <div class="col-md-8 p-1">
              <input type="text" #refKYC_DocNumber="ngModel" [(ngModel)]="kycModel.KYC_DocNumber"
                placeholder="Document Number" required *ngIf="kycModel.KYC_DocId!=1&&kycModel.KYC_DocId!=6"
                [ngClass]="{ 'is-invalid': f.submitted && refKYC_DocNumber.invalid, 'alert-warning': refKYC_DocNumber.invalid  && (refKYC_DocNumber.dirty || refKYC_DocNumber.touched || refKYC_DocNumber.untouched) }"
                name="KYC_DocNumber" id="KYC_DocNumber" class="form-control input-text-css">
              <input type="text" #refKYC_DocNumber="ngModel" [(ngModel)]="kycModel.KYC_DocNumber"
                placeholder="Document Number" required *ngIf="kycModel.KYC_DocId==1" maxlength="10" minlength="10"
                [ngClass]="{ 'is-invalid': f.submitted && refKYC_DocNumber.invalid, 'alert-warning': refKYC_DocNumber.invalid  && (refKYC_DocNumber.dirty || refKYC_DocNumber.touched || refKYC_DocNumber.untouched) }"
                name="KYC_DocNumber" id="KYC_DocNumber" class="form-control input-text-css" pattern="([A-Z]){5}([0-9]){4}([A-Z]){1}$">
              <input type="text" #refKYC_DocNumber="ngModel" numbersOnly [(ngModel)]="kycModel.KYC_DocNumber"
                placeholder="Document Number" required *ngIf="kycModel.KYC_DocId==6" maxlength="12" minlength="12"
                [ngClass]="{ 'is-invalid': f.submitted && refKYC_DocNumber.invalid, 'alert-warning': refKYC_DocNumber.invalid  && (refKYC_DocNumber.dirty || refKYC_DocNumber.touched || refKYC_DocNumber.untouched) }"
                name="KYC_DocNumber" id="KYC_DocNumber" class="form-control input-text-css">
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <div class="col-md-6" *ngIf="kycModel.KYC_DocId==3||kycModel.KYC_DocId==5">
              <span class="required-lable"> Date Of Issue</span>
              <input name="KYC_IssuedDate" id="KYC_IssuedDate" #refKYC_IssuedDate="ngModel" required *ngIf="kycModel.KYC_DocId==3||kycModel.KYC_DocId==5"
                [ngClass]="{ 'is-invalid': f.submitted && refKYC_IssuedDate.invalid, 'alert-warning': refKYC_IssuedDate.invalid  && (refKYC_IssuedDate.dirty || refKYC_IssuedDate.touched || refKYC_IssuedDate.untouched) }"
                [(ngModel)]="kycModel.KYC_IssuedDate" value="{{ kycModel.KYC_IssuedDate | date:'dd/MM/yyyy' }}" placeholder="DD/MM/YYYY"
                class="form-control input-text-css" [(bsValue)]="kycModel.KYC_IssuedDate" bsDatepicker>
            </div>
            <div class="col-md-6" *ngIf="kycModel.KYC_DocId==3||kycModel.KYC_DocId==5">
              <span class="required-lable"> Date Of Expire</span>
              <input name="KYC_ExpiredDate" id="KYC_ExpiredDate" #refKYC_ExpiredDate="ngModel" required *ngIf="kycModel.KYC_DocId==3||kycModel.KYC_DocId==5"
                [ngClass]="{ 'is-invalid': f.submitted && refKYC_ExpiredDate.invalid, 'alert-warning': refKYC_ExpiredDate.invalid  && (refKYC_ExpiredDate.dirty || refKYC_ExpiredDate.touched || refKYC_ExpiredDate.untouched) }"
                [(ngModel)]="kycModel.KYC_ExpiredDate" value="{{kycModel.KYC_ExpiredDate | date:'dd/MM/yyyy' }}" placeholder="DD/MM/YYYY"
                class="form-control input-text-css" [(bsValue)]="kycModel.KYC_ExpiredDate" bsDatepicker>
            </div>
          </div>
          <div class="row m-0 font-size-12 align-items-center">
            <span> Document Image</span>
            <p class="m-0 mt-2 row">
              <i class="fa fa-upload ml-2" *ngIf="!kycModel.KYC_DocFile" (click)="docFile.click()" aria-hidden="true"></i>
              <ng-container *ngIf="kycModel.KYC_DocFile">
                <div>
                  <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>{{kycModel.KYC_DocFile}}
                  <i class="fa fa-times ml-2" aria-hidden="true" style="cursor: pointer;" (click)="removeFile(kycModel,docFile)"></i>
                </div>
              </ng-container>
            </p>
            <input #docFile type="file" [multiple]="false" accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
              (change)="fileChangeListener(kycModel,docFile.files)" style="display: none;">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="OnClose()" class="btn btn-light font-size-12" data-dismiss="modal">
            Cancel
          </button>
          <button type="button" (click)="onSavePendingDocumentData()" mat-raised-button class="btn_default"
                  [class.spinner]="loading" [disabled]="!f.form.valid">
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>
</div>-->

<div
  class="modal fade in"
  id="addPendingDocument"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Upload Application Documents
        </h6>
        <button
          type="button"
          (click)="OnClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 col-md-12 p-1" *ngIf="docModal">
          <form
            #df="ngForm"
            class="row m-0 col-md-12 p-0"
            (ngSubmit)="onSaveDoc()"
            novalidate
          >
            <div class="row m-0 col-md-12 p-0">
              <div class="col-md-12 p-1">
                <span class="required-lable">Type</span>
                <select
                  name="Type"
                  id="Type"
                  #refType="ngModel"
                  [ngClass]="{
                    'is-invalid': df.submitted && refType.invalid,
                    'alert-warning':
                      refType.invalid &&
                      (refType.dirty || refType.touched || refType.untouched)
                  }"
                  [(ngModel)]="docModal.Type"
                  class="form-control input-text-css"
                  disabled
                  (change)="TypeChange($event)"
                  required
                >
                  <option value="Loan">Loan</option>
                  <option value="Customer">Customer</option>
                  <option value="Assets">Assets</option>
                </select>
              </div>
              <div class="col-md-12 p-1" *ngIf="docModal.Type == 'Customer'">
                <span class="required-lable">Customer Name</span>
                <select
                  name="Name"
                  id="Name"
                  #refName="ngModel"
                  [ngClass]="{
                    'is-invalid': df.submitted && refName.invalid,
                    'alert-warning':
                      refName.invalid &&
                      (refName.dirty || refName.touched || refName.untouched)
                  }"
                  [(ngModel)]="docModal.Name"
                  class="form-control input-text-css"
                 
                  required
                >
                  <option value="">Select Customer Name</option>
                  <option
                    *ngFor="let list of CustomerDropdown"
                    [value]="list.Customer + '-' + list.CustomerId"
                  >
                    {{ list.Customer }}
                  </option>
                </select>
              </div>
              <!--<div class="col-md-12 p-1" *ngIf="docModal.Type=='Assets'">
                <span class="required-lable">Select Assets Name</span>
                <select name="Name" id="Name" #refName="ngModel"
                  [ngClass]="{ 'is-invalid': df.submitted && refName.invalid, 'alert-warning': refName.invalid  && (refName.dirty || refName.touched || refName.untouched) }"
                  [(ngModel)]="docModal.Name" class="form-control input-text-css" required>
                  <option value="">Select Assets Name</option>
                  <option [value]="asset.ForInsurance" *ngFor="let asset of assetDropdown">{{asset.ForInsurance}}</option>
                </select>
              </div>
              <div class="col-md-12 p-1" *ngIf="docModal.Type=='Loan'">
                <span class="required-lable">Select Name</span>
                <select name="Name" id="Name" #refName="ngModel" disabled
                  [ngClass]="{ 'is-invalid': df.submitted && refName.invalid, 'alert-warning': refName.invalid  && (refName.dirty || refName.touched || refName.untouched) }"
                  [(ngModel)]="docModal.Name" class="form-control input-text-css" required>
                  <option value="">Select Name</option>
                  <option [value]="CA.Name" *ngFor="let CA of CustomerAssetsDropdown">{{CA.Name}}</option>
                  <option value="Other">Other</option>
                </select>
              </div>-->
              <div class="col-md-12 p-1">
                <span class="required-lable"> Document Category</span>
                <select
                  name="DocId"
                  id="DocId"
                  #refDocId="ngModel"
                  [ngClass]="{
                    'is-invalid': df.submitted && refDocId.invalid,
                    'alert-warning':
                      refDocId.invalid &&
                      (refDocId.dirty || refDocId.touched || refDocId.untouched)
                  }"
                  [(ngModel)]="docModal.DocId"
                  (change)="DocumentCategoryChange($event)"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Document Category</option>
                  <option
                    [value]="doc.DocumentId + '-' + doc.Doc_CategoryId"
                    *ngFor="let doc of docDropDown"
                  >
                    {{ doc.DocumentName }}
                  </option>
                </select>
              </div>
              <div
                class="col-md-6 p-1"
                *ngIf="
                  docModal.KYC_DocId == 3 ||
                  docModal.KYC_DocId == 5 ||
                  docModal.KYC_DocId == 7 ||
                  docModal.KYC_DocId == 21
                "
              >
                <span class="required-lable"> Date Of Issue</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="KYC_IssuedDate"
                    id="KYC_IssuedDate"
                    #refKYC_IssuedDate="ngModel"
                    required
                    (dateChange)="DateChange()"
                    *ngIf="
                      docModal.KYC_DocId == 3 ||
                      docModal.KYC_DocId == 5 ||
                      docModal.KYC_DocId == 7 ||
                      docModal.KYC_DocId == 21
                    "
                    [ngClass]="{
                      'is-invalid': df.submitted && refKYC_IssuedDate.invalid,
                      'alert-warning':
                        refKYC_IssuedDate.invalid &&
                        (refKYC_IssuedDate.dirty ||
                          refKYC_IssuedDate.touched ||
                          refKYC_IssuedDate.untouched)
                    }"
                    [(ngModel)]="docModal.KYC_IssuedDate"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <!-- <input
                  name="KYC_IssuedDate"
                  id="KYC_IssuedDate"
                  #refKYC_IssuedDate="ngModel"
                  required
                  (ngModelChange)="DateChange()"
                  *ngIf="
                    docModal.KYC_DocId == 3 ||
                    docModal.KYC_DocId == 5 ||
                    docModal.KYC_DocId == 7 ||
                    docModal.KYC_DocId == 21
                  "
                  [ngClass]="{
                    'is-invalid': df.submitted && refKYC_IssuedDate.invalid,
                    'alert-warning':
                      refKYC_IssuedDate.invalid &&
                      (refKYC_IssuedDate.dirty ||
                        refKYC_IssuedDate.touched ||
                        refKYC_IssuedDate.untouched)
                  }"
                  [(ngModel)]="docModal.KYC_IssuedDate"
                  value="{{ docModal.KYC_IssuedDate | date : 'dd/MM/yyyy' }}"
                  placeholder="DD/MM/YYYY"
                  class="form-control input-text-css"
                  [(bsValue)]="docModal.KYC_IssuedDate"
                  bsDatepicker
                /> -->
              </div>
              <div
                class="col-md-6 p-1"
                *ngIf="
                  docModal.KYC_DocId == 3 ||
                  docModal.KYC_DocId == 5 ||
                  docModal.KYC_DocId == 7 ||
                  docModal.KYC_DocId == 21
                "
              >
                <span class="required-lable"> Date Of Expire</span>
                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker2"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="KYC_ExpiredDate"
                    id="KYC_ExpiredDate"
                    #refKYC_ExpiredDate="ngModel"
                    required
                    (dateChange)="DateChange()"
                    *ngIf="
                      docModal.KYC_DocId == 3 ||
                      docModal.KYC_DocId == 5 ||
                      docModal.KYC_DocId == 7 ||
                      docModal.KYC_DocId == 21
                    "
                    [ngClass]="{
                      'is-invalid': df.submitted && refKYC_ExpiredDate.invalid,
                      'alert-warning':
                        refKYC_ExpiredDate.invalid &&
                        (refKYC_ExpiredDate.dirty ||
                          refKYC_ExpiredDate.touched ||
                          refKYC_ExpiredDate.untouched)
                    }"
                    [(ngModel)]="docModal.KYC_ExpiredDate"
                    class="form-control input-text-css"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker2"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </div>
                <!-- <input
                  name="KYC_ExpiredDate"
                  id="KYC_ExpiredDate"
                  #refKYC_ExpiredDate="ngModel"
                  required
                  (ngModelChange)="DateChange()"
                  *ngIf="
                    docModal.KYC_DocId == 3 ||
                    docModal.KYC_DocId == 5 ||
                    docModal.KYC_DocId == 7 ||
                    docModal.KYC_DocId == 21
                  "
                  [ngClass]="{
                    'is-invalid': df.submitted && refKYC_ExpiredDate.invalid,
                    'alert-warning':
                      refKYC_ExpiredDate.invalid &&
                      (refKYC_ExpiredDate.dirty ||
                        refKYC_ExpiredDate.touched ||
                        refKYC_ExpiredDate.untouched)
                  }"
                  [(ngModel)]="docModal.KYC_ExpiredDate"
                  value="{{ docModal.KYC_ExpiredDate | date : 'dd/MM/yyyy' }}"
                  placeholder="DD/MM/YYYY"
                  class="form-control input-text-css"
                  [(bsValue)]="docModal.KYC_ExpiredDate"
                  bsDatepicker
                /> -->
              </div>
              <div class="col-md-12 p-1">
                <span> Document Type</span>
                <div class="col-md-12 p-1">
                  <span class="form-check-inline">
                    <label class="form-check-label mr-4" for="copyDoc">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="copyDoc"
                        name="IsOrignalDoc"
                        #refIsOrignalDoc="ngModel"
                        [(ngModel)]="docModal.IsOrignalDoc"
                        required
                        value="1"
                        [ngClass]="{
                          'is-invalid': df.submitted && refIsOrignalDoc.invalid,
                          'alert-warning':
                            refIsOrignalDoc.invalid &&
                            (refIsOrignalDoc.dirty ||
                              refIsOrignalDoc.touched ||
                              refIsOrignalDoc.untouched)
                        }"
                      />
                      Copy
                    </label>
                    <label class="form-check-label" for="originalDoc">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="originalDoc"
                        name="IsOrignalDoc"
                        #refIsOrignalDoc="ngModel"
                        [ngClass]="{
                          'is-invalid': df.submitted && refIsOrignalDoc.invalid,
                          'alert-warning':
                            refIsOrignalDoc.invalid &&
                            (refIsOrignalDoc.dirty ||
                              refIsOrignalDoc.touched ||
                              refIsOrignalDoc.untouched)
                        }"
                        [(ngModel)]="docModal.IsOrignalDoc"
                        required
                        value="0"
                      />
                      Mark as LOD
                    </label>
                  </span>
                </div>
              </div>
              <div class="col-md-12 p-1">
                <span class="required-lable"> Document Title/Document No.</span>
                <input
                  type="text"
                  #refDocTitle="ngModel"
                  [(ngModel)]="docModal.DocTitle"
                  placeholder=" Document Title"
                  *ngIf="
                    docModal.DocId.split('-')[0] != 1 &&
                    docModal.DocId.split('-')[0] != 6
                  "
                  [ngClass]="{
                    'is-invalid': df.submitted && refDocTitle.invalid,
                    'alert-warning':
                      refDocTitle.invalid &&
                      (refDocTitle.dirty ||
                        refDocTitle.touched ||
                        refDocTitle.untouched)
                  }"
                  name="DocTitle"
                  id="DocTitle"
                  class="form-control input-text-css"
                  required
                />
                <input
                  type="text"
                  #refDocTitle="ngModel"
                  [(ngModel)]="docModal.DocTitle"
                  placeholder=" Document Title"
                  [ngClass]="{
                    'is-invalid': df.submitted && refDocTitle.invalid,
                    'alert-warning':
                      refDocTitle.invalid &&
                      (refDocTitle.dirty ||
                        refDocTitle.touched ||
                        refDocTitle.untouched)
                  }"
                  name="DocTitle"
                  id="DocTitle"
                  required
                  *ngIf="docModal.DocId.split('-')[0] == 1"
                  maxlength="10"
                  minlength="10"
                  class="form-control input-text-css"
                />
                <input
                  type="text"
                  numbersOnly
                  #refDocTitle="ngModel"
                  [(ngModel)]="docModal.DocTitle"
                  minlength="12"
                  class="form-control input-text-css"
                  placeholder=" Document Title"
                  name="DocTitle"
                  id="DocTitle"
                  required
                  *ngIf="docModal.DocId.split('-')[0] == 6"
                  maxlength="12"
                  [ngClass]="{
                    'is-invalid': df.submitted && refDocTitle.invalid,
                    'alert-warning':
                      refDocTitle.invalid &&
                      (refDocTitle.dirty ||
                        refDocTitle.touched ||
                        refDocTitle.untouched)
                  }"
                />
              </div>
              <div class="col-md-12 p-1">
                <span> No of pages</span>
                <input
                  type="text"
                  #refNo_Of_Pages="ngModel"
                  [(ngModel)]="docModal.No_Of_Pages"
                  name="No_Of_Pages"
                  id="No_Of_Pages"
                  placeholder=" No of pages"
                  numbersOnly="true"
                  class="form-control input-text-css"
                  required
                  [ngClass]="{
                    'is-invalid': df.submitted && refNo_Of_Pages.invalid,
                    'alert-warning':
                      refNo_Of_Pages.invalid &&
                      (refNo_Of_Pages.dirty ||
                        refNo_Of_Pages.touched ||
                        refNo_Of_Pages.untouched)
                  }"
                />
              </div>
              <div class="col-md-12 p-1">
                <span> Remark </span>
                <input
                  type="text"
                  #refRemark="ngModel"
                  [(ngModel)]="docModal.Remark"
                  placeholder=" Remark "
                  [ngClass]="{
                    'is-invalid': df.submitted && refRemark.invalid,
                    'alert-warning':
                      refRemark.invalid &&
                      (refRemark.dirty ||
                        refRemark.touched ||
                        refRemark.untouched)
                  }"
                  name="Remark"
                  id="Remark"
                  class="form-control input-text-css"
                />
              </div>
              <div class="row m-0 col-md-12 p-1 align-items-center upload">
                <p class="m-0 row align-items-center">
                  Browse Doc:<i
                    class="fa fa-upload ml-2"
                    *ngIf="docModal.DocumentImages.length == 0"
                    (click)="docUFile.click()"
                    aria-hidden="true"
                  ></i>
                  <ng-container *ngIf="docModal.DocumentImages.length > 0">
                    <div
                      *ngFor="let doc of docModal.DocumentImages; let i = index"
                    >
                      <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i
                      >{{ doc.DocFileName }}
                      <i
                        class="fa fa-times ml-2"
                        aria-hidden="true"
                        style="cursor: pointer"
                        (click)="removeDFile(i)"
                      ></i>
                    </div>
                  </ng-container>
                </p>
                <input
                  #docUFile
                  type="file"
                  [multiple]="true"
                  accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                  (change)="fileChangeListenerD(docUFile.files, docUFile)"
                  style="display: none"
                />
              </div>
            </div>
            <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
              <button
                type="button"
                (click)="OnClose()"
                class="btn font-size-12 button-btn mr-3"
              >
                Cancel
              </button>
              <button
                type="button"
                (click)="onSaveDoc()"
                *ngIf="!inViewMode"
                class="btn font-size-12 button-btn"
                [class.spinner]="loading"
                [disabled]="!df.form.valid"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
