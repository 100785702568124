<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keyup.enter)="getSearchCusList()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-3">
        <span> Search By: </span>
        <select
          name="FilterSearchBy"
          id="FilterSearchBy"
          class="form-control input-text-css"
          [(ngModel)]="FilterSearchBy"
        >
          <option value="">Select</option>
          <option value="Name">Customer First Name Starts With</option>
          <option value="Phone">Customer Phone</option>
          <option value="Pan">PAN Card</option>
          <option value="Passport">Passport Copy</option>
          <option value="VoterId">Voter ID Card</option>
          <option value="DL">Driving License</option>
          <option value="Aadhar">Aadhaar Card</option>
          <option value="ElectricityBill">Electricity Bill</option>
          <option value="RationCard">Ration Card</option>
        </select>
      </div>
      <div class="col-md-4">
        <span> value: </span>
        <input
          name="FilterSearchValue"
          id="FilterSearchValue"
          placeholder="Value"
          [(ngModel)]="FilterSearchValue"
          class="form-control input-text-css"
        />
      </div>
      <div class="col-md-1">
        <button
          class="mt-3 btn font-size-12 button-btn btn-padding"
          style="margin-top: 18px"
          (click)="getSearchCusList()"
        >
          Search
        </button>
      </div>
      <div class="col-md-2">
        <button
          class="mt-3 btn font-size-12 button-btn btn-padding"
          (click)="addCustomer()"
        >
          Add New Customer
        </button>
      </div>
      <div class="col-md-2" *ngIf="currentUser.IsCustomerImport">
        <button
          class="mt-3 btn font-size-12 button-btn btn-padding"
          (click)="ImportPopup()"
        >
          Import Customer
        </button>
      </div>
    </div>
  </form>

  <div class="mt-3" [hidden]="!dataSource">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSource"
          #sortList="matSort"
          matSort
          id="exportReport"
          style="max-width: 100%; overflow: auto"
        >
          <ng-container matColumnDef="CustomerId">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 5%"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 5%; max-height: 5px"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="CustomertName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="text-transform: capitalize"
              >{{ row.CustomertName }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="FatherName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Father Name</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="text-transform: capitalize"
              >{{ row.FatherName }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="Customer_Gender">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >Gender</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="max-width: 50px"
            >
              {{ row.Customer_Gender }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="GenderAge">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >Age</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="max-width: 50px"
              >{{ row.GenderAge }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="PhoneNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 150px; justify-content: center"
              *matHeaderCellDef
            >
              Phone No
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="max-width: 150px; justify-content: center"
            >
              <div *ngIf="currentUser.IsMaskingMobile">
                {{ row.PhoneNo | mask }}
              </div>

              <div *ngIf="!currentUser.IsMaskingMobile">
                {{ row.PhoneNo }}
              </div>

              <i
                *ngIf="row.Customer_PhoneNo_IsVerified == true"
                class="fa fa-check-circle ml-2"
                style="color: green"
              ></i>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="TotalApplication">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 150px; justify-content: center"
              *matHeaderCellDef
            >
              Total Application
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              class="grid-cell text-center"
              style="max-width: 150px; justify-content: center"
            >
              {{ row.TotalApplication }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="EditAction">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 100px; justify-content: center"
              *matHeaderCellDef
            >
              View Edit
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 100px; justify-content: center"
            >
              <app-lms-customer-view-model
                [customerId]="row.CustomerId"
                [index]="i"
              >
                <i
                  class="fa fa-eye"
                  style="font-size: large; cursor: pointer; margin-right: 15px"
                ></i>
              </app-lms-customer-view-model>
              <i
                class="fas fa-lg fa-edit margin-right-4xs cursor-style"
                (click)="getCustomerFullDetail(row)"
              ></i>
            </mat-cell>
          </ng-container>

          <mat-header-row
            class="sticky-footer"
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
          #paginatorRef
          #PaginatorList
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>
<!-- Add New Customer -->

<div
  class="modal fade in"
  id="addCustomer"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Customer
        </h6>
        <button
          type="button"
          (click)="onCloseCustomer()"
          class="close"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <ng-conatainer>
          <form
            class="formborder"
            #ancf="ngForm"
            id="ancf"
            (ngSubmit)="onRegisterNewCustomer()"
            novalidate
          >
            <div class="row m-0 mb-2">
              <div class="col-md-4">
                <span>Type</span>
                <select
                  name="Type"
                  id="Type"
                  #refType="ngModel"
                  [(ngModel)]="customerModel.Type"
                  class="form-control input-text-css"
                  (change)="changeCustomertype()"
                  [disabled]="isCustomerEdit"
                >
                  <option value="Indusial">Individual</option>
                  <option value="Company">Non-Individual</option>
                </select>
              </div>
              <div class="col-md-4" *ngIf="customerModel.Type == 'Company'">
                <span class="required-lable">Firm Type</span>
                <select
                  name="Firm_Type"
                  id="Firm_Type"
                  #refFirm_Type="ngModel"
                  (change)="changeCompanyFirm()"
                  [ngClass]="{
                    'is-invalid': ancf.submitted && refFirm_Type.invalid,
                    'alert-warning':
                      refFirm_Type.invalid &&
                      (refFirm_Type.dirty ||
                        refFirm_Type.touched ||
                        refFirm_Type.untouched)
                  }"
                  [(ngModel)]="customerModel.Firm_Type"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Firm type</option>
                  <option
                    *ngFor="let item of FirmTypeList"
                    [value]="item.Value"
                  >
                    {{ item.Value }}
                  </option>
                </select>
              </div>
            </div>

            <ng-container class="mt-2">
              <ng-container *ngIf="customerModel.Type == 'Indusial'">
                <ng-container>
                  <div class="row m-0 col-md-12">
                    <div class="col-md-12 bg-success1">KYC Documents:</div>
                  </div>
                  <div
                    class="row m-0 mt-2"
                    *ngFor="let kyc of CustomerKYCDoc; let i = index"
                  >
                    <div class="col-md-11">
                      <div class="row">
                        <div
                          [ngClass]="
                            kyc.KYC_DocId == 3 || kyc.KYC_DocId == 5
                              ? 'col-md-2'
                              : 'col-md-2'
                          "
                        >
                          <span class="required-lable">Document Type</span>
                          <ng-container *ngIf="TypeFrom != 'Deposit'">
                            <select
                              name="KYC_DocId{{ i }}"
                              id="KYC_DocId{{ i }}"
                              #refKYC_DocId="ngModel"
                              class="form-control input-text-css"
                              [ngClass]="{
                                'is-invalid':
                                  ancf.submitted && refKYC_DocId.invalid,
                                'alert-warning':
                                  refKYC_DocId.invalid &&
                                  (refKYC_DocId.dirty ||
                                    refKYC_DocId.touched ||
                                    refKYC_DocId.untouched)
                              }"
                              [(ngModel)]="kyc.KYC_DocId"
                              required
                              (change)="onChangeDocument(kyc, i)"
                            >
                              <option value="">Select Document type</option>
                              <option
                                *ngFor="let Doc of ProductDropdownList"
                                [value]="Doc.DocId"
                              >
                                {{ Doc.Doc_Name }}
                              </option>
                            </select>
                          </ng-container>
                          <ng-container *ngIf="TypeFrom == 'Deposit'">
                            <select
                              name="KYC_DocId{{ i }}"
                              id="KYC_DocId{{ i }}"
                              #refKYC_DocId="ngModel"
                              class="form-control input-text-css"
                              [ngClass]="{
                                'is-invalid':
                                  ancf.submitted && refKYC_DocId.invalid,
                                'alert-warning':
                                  refKYC_DocId.invalid &&
                                  (refKYC_DocId.dirty ||
                                    refKYC_DocId.touched ||
                                    refKYC_DocId.untouched)
                              }"
                              [(ngModel)]="kyc.KYC_DocId"
                              required
                              (change)="onChangeDocument(kyc, i)"
                            >
                              <option value="">Select Document type</option>
                              <option value="1">PAN Card</option>
                              <option value="2">Electricity Bill</option>
                              <option value="3">Passport Copy</option>
                              <option value="4">Voter ID Card</option>
                              <option value="5">Driving License</option>
                              <option value="6">Aadhaar Card</option>
                              <option value="7">Other Photo ID</option>
                              <option value="21">Ration Card</option>
                              <option value="22">DLl</option>
                            </select>
                          </ng-container>
                        </div>
                        <div
                          [ngClass]="
                            kyc.KYC_DocId == 3 || kyc.KYC_DocId == 5
                              ? 'col-md-2'
                              : 'col-md-2'
                          "
                        >
                          <span class="required-lable">Document Number</span>
                          <input
                            type="text"
                            #refKYC_DocNumber="ngModel"
                            [(ngModel)]="kyc.KYC_DocNumber"
                            placeholder="Document Number"
                            required
                            (blur)="checkDuplicate(kyc)"
                            *ngIf="kyc.KYC_DocId != 1 && kyc.KYC_DocId != 6"
                            (change)="Get_Alredy_Verified_KYC(kyc, i)"
                            [ngClass]="{
                              'is-invalid':
                                ancf.submitted && refKYC_DocNumber.invalid,
                              'alert-warning':
                                refKYC_DocNumber.invalid &&
                                (refKYC_DocNumber.dirty ||
                                  refKYC_DocNumber.touched ||
                                  refKYC_DocNumber.untouched)
                            }"
                            name="KYC_DocNumber{{ i }}"
                            id="KYC_DocNumber{{ i }}"
                            class="form-control input-text-css"
                            [disabled]="!kyc.KYC_DocId"
                          />
                          <input
                            type="text"
                            #refKYC_DocNumber="ngModel"
                            [(ngModel)]="kyc.KYC_DocNumber"
                            pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}"
                            *ngIf="kyc.KYC_DocId == 1"
                            placeholder="Document Number"
                            required
                            maxlength="10"
                            minlength="10"
                            (change)="Get_Alredy_Verified_KYC(kyc, i)"
                            (blur)="checkDuplicate(kyc)"
                            name="KYC_DocNumber{{ i }}"
                            id="KYC_DocNumber{{ i }}"
                            [ngClass]="{
                              'is-invalid':
                                ancf.submitted && refKYC_DocNumber.invalid,
                              'alert-warning':
                                refKYC_DocNumber.invalid &&
                                (refKYC_DocNumber.dirty ||
                                  refKYC_DocNumber.touched ||
                                  refKYC_DocNumber.untouched)
                            }"
                            class="form-control input-text-css"
                            [disabled]="!kyc.KYC_DocId"
                          />
                          <input
                            type="text"
                            #refKYC_DocNumber="ngModel"
                            numbersOnly
                            [(ngModel)]="kyc.KYC_DocNumber"
                            *ngIf="kyc.KYC_DocId == 6"
                            placeholder="Document Number"
                            required
                            (blur)="checkDuplicate(kyc)"
                            maxlength="12"
                            minlength="12"
                            (change)="Get_Alredy_Verified_KYC(kyc, i)"
                            [ngClass]="{
                              'is-invalid':
                                ancf.submitted && refKYC_DocNumber.invalid,
                              'alert-warning':
                                refKYC_DocNumber.invalid &&
                                (refKYC_DocNumber.dirty ||
                                  refKYC_DocNumber.touched ||
                                  refKYC_DocNumber.untouched)
                            }"
                            name="KYC_DocNumber{{ i }}"
                            id="KYC_DocNumber{{ i }}"
                            class="form-control input-text-css"
                            [disabled]="!kyc.KYC_DocId"
                          />
                        </div>
                        <ng-container
                          *ngIf="kyc.KYC_DocId == 3 || kyc.KYC_DocId == 5"
                        >
                          <div class="col-md-2">
                            <span class="required-lable">Date Of Issue</span>
                            <div class="datepicker_feild">
                              <input
                                [matDatepicker]="picker"
                                dateConvert
                                placeholder="DD/MM/YYYY"
                                name="KYC_IssuedDate{{ i }}"
                                id="KYC_IssuedDate{{ i }}"
                                #refKYC_IssuedDate="ngModel"
                                required
                                [(ngModel)]="kyc.KYC_IssuedDate"
                                class="form-control input-text-css"
                                [ngClass]="{
                                  'is-invalid':
                                    ancf.submitted && refKYC_IssuedDate.invalid,
                                  'alert-warning':
                                    refKYC_IssuedDate.invalid &&
                                    (refKYC_IssuedDate.dirty ||
                                      refKYC_IssuedDate.touched ||
                                      refKYC_IssuedDate.untouched)
                                }"
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="picker"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                            </div>
                            <!-- <input
                              name="KYC_IssuedDate{{ i }}"
                              id="KYC_IssuedDate{{ i }}"
                              #refKYC_IssuedDate="ngModel"
                              required
                              [(ngModel)]="kyc.KYC_IssuedDate"
                              value="{{
                                kyc.KYC_IssuedDate | date : 'dd/MM/yyyy'
                              }}"
                              [ngClass]="{
                                'is-invalid':
                                  ancf.submitted && refKYC_IssuedDate.invalid,
                                'alert-warning':
                                  refKYC_IssuedDate.invalid &&
                                  (refKYC_IssuedDate.dirty ||
                                    refKYC_IssuedDate.touched ||
                                    refKYC_IssuedDate.untouched)
                              }"
                              placeholder="DD/MM/YYYY"
                              class="form-control input-text-css"
                              [(bsValue)]="kyc.KYC_IssuedDate"
                              bsDatepicker
                            /> -->
                          </div>
                          <div class="col-md-2">
                            <span class="required-lable">Date Of Expire</span>
                            <div class="datepicker_feild">
                              <input
                                [matDatepicker]="picker2"
                                dateConvert
                                placeholder="DD/MM/YYYY"
                                name="KYC_ExpiredDate{{ i }}"
                                id="KYC_ExpiredDate{{ i }}"
                                #refKYC_ExpiredDate="ngModel"
                                required
                                class="form-control input-text-css"
                                [ngClass]="{
                                  'is-invalid':
                                    ancf.submitted &&
                                    refKYC_ExpiredDate.invalid,
                                  'alert-warning':
                                    refKYC_ExpiredDate.invalid &&
                                    (refKYC_ExpiredDate.dirty ||
                                      refKYC_ExpiredDate.touched ||
                                      refKYC_ExpiredDate.untouched)
                                }"
                                [(ngModel)]="kyc.KYC_ExpiredDate"
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="picker2"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #picker2></mat-datepicker>
                            </div>
                            <!-- <input
                              name="KYC_ExpiredDate{{ i }}"
                              id="KYC_ExpiredDate{{ i }}"
                              #refKYC_ExpiredDate="ngModel"
                              required
                              placeholder="DD/MM/YYYY"
                              class="form-control input-text-css"
                              [(bsValue)]="kyc.KYC_ExpiredDate"
                              [ngClass]="{
                                'is-invalid':
                                  ancf.submitted && refKYC_ExpiredDate.invalid,
                                'alert-warning':
                                  refKYC_ExpiredDate.invalid &&
                                  (refKYC_ExpiredDate.dirty ||
                                    refKYC_ExpiredDate.touched ||
                                    refKYC_ExpiredDate.untouched)
                              }"
                              [(ngModel)]="kyc.KYC_ExpiredDate"
                              value="{{
                                kyc.KYC_ExpiredDate | date : 'dd/MM/yyyy'
                              }}"
                              bsDatepicker
                            /> -->
                          </div>
                        </ng-container>
                        <div
                          [ngClass]="
                            kyc.KYC_DocId == 3 || kyc.KYC_DocId == 5
                              ? 'col-md-1'
                              : 'col-md-2'
                          "
                        >
                          <span> Document Image</span>
                          <p class="mt-1" style="overflow-wrap: break-word">
                            <i
                              class="fa fa-upload"
                              *ngIf="!kyc.KYC_DocFile"
                              (click)="docFile.click()"
                              aria-hidden="true"
                            ></i>
                            <ng-container *ngIf="kyc.KYC_DocFile">
                              <i
                                class="fa fa-file-pdf mr-2"
                                aria-hidden="true"
                              ></i>
                              {{ kyc.KYC_DocFile.split("_")[0].slice(0, 10)
                              }}{{
                                kyc.KYC_DocFile.split("_")[0].length > 10
                                  ? "..."
                                  : ""
                              }}

                              <i
                                class="fa fa-times ml-2"
                                aria-hidden="true"
                                style="cursor: pointer"
                                (click)="removeFile(kyc, docFile)"
                              ></i>
                            </ng-container>
                          </p>
                          <input
                            #docFile
                            type="file"
                            [multiple]="false"
                            accept="application/pdf,application/vnd.ms-excel"
                            (change)="fileChangeListener(kyc, docFile.files)"
                            style="display: none"
                          />
                        </div>
                        <div
                          [ngClass]="
                            kyc.KYC_DocId == 3 || kyc.KYC_DocId == 5
                              ? 'col-md-1'
                              : 'col-md-2'
                          "
                        >
                          <span> Document Image1</span>
                          <p class="mt-1" style="overflow-wrap: break-word">
                            <i
                              class="fa fa-upload"
                              *ngIf="!kyc.KYC_DocFile1"
                              (click)="docFile1.click()"
                              aria-hidden="true"
                            ></i>
                            <ng-container *ngIf="kyc.KYC_DocFile1">
                              <i
                                class="fa fa-file-pdf mr-1"
                                aria-hidden="true"
                              ></i>
                              {{ kyc.KYC_DocFile1.split("_")[0].slice(0, 10)
                              }}{{
                                kyc.KYC_DocFile1.split("_")[0].length > 10
                                  ? "..."
                                  : ""
                              }}

                              <i
                                class="fa fa-times ml-1"
                                aria-hidden="true"
                                style="cursor: pointer"
                                (click)="removeFile2(kyc, docFile1)"
                              ></i>
                            </ng-container>
                          </p>
                          <input
                            #docFile1
                            type="file"
                            [multiple]="false"
                            accept="application/pdf,application/vnd.ms-excel"
                            (change)="fileChangeListener2(kyc, docFile1.files)"
                            style="display: none"
                          />
                        </div>
                        <div
                          *ngIf="
                            kyc.KYC_DocId == 1 ||
                            kyc.KYC_DocId == 6 ||
                            kyc.KYC_DocId == 4 ||
                            kyc.KYC_DocId == 29 ||
                            kyc.KYC_DocId == 5 ||
                            kyc.KYC_DocId == 2
                          "
                          class="col-md-2"
                        >
                          <button
                            type="button"
                            *ngIf="kyc.Verified_Button"
                            [hidden]="
                              kyc.KYC_IsVerified == 1 || kyc.LastVerfiedDate
                            "
                            (click)="onVerification(kyc, i, 0)"
                            class="font-size-12 button-btn"
                          >
                            Verification
                          </button>
                          <i
                            class="fa fa-eye ml-2"
                            (click)="getdata(kyc, i)"
                            *ngIf="
                              kyc.KYC_DocNumber != '' &&
                              kyc.Verified_Button &&
                              (kyc.KYC_IsVerified == 1 || kyc.LastVerfiedDate)
                            "
                            style="color: green"
                          ></i>

                          <button
                            type="button"
                            *ngIf="
                              kyc.KYC_DocNumber != '' &&
                              kyc.Verified_Button &&
                              (kyc.KYC_IsVerified == 1 || kyc.LastVerfiedDate)
                            "
                            (click)="onVerification(kyc, i, 1)"
                            class="font-size-12 button-btn verify-button-btn"
                          >
                            RE-Verification
                          </button>
                          <span
                            *ngIf="
                              kyc.KYC_DocNumber != '' &&
                              kyc.Verified_Button &&
                              kyc.LastVerfiedDate
                            "
                            style="margin-top: 25px"
                          >
                            VERIFIED {{ kyc.LastVerfiedDate }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 pt-3" style="margin-top: 5px">
                      <button
                        type="button"
                        class="remove"
                        (click)="removeKYC(i)"
                        *ngIf="
                          i < CustomerKYCDoc.length && CustomerKYCDoc.length > 1
                        "
                      >
                        -
                      </button>
                      <button
                        type="button"
                        class="addmore"
                        (click)="addMoreKYC()"
                        *ngIf="i == CustomerKYCDoc.length - 1"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </ng-container>

                <div class="row m-0 mt-2 col-md-12">
                  <div class="col-md-12 bg-success1">
                    Demographic Information:
                  </div>
                </div>

                <div class="row m-0 mt-2">
                  <div class="col-md-10">
                    <div class="row">
                      <div class="col-md-3">
                        <span class="required-lable">First Name</span>
                        <input
                          type="text"
                          pattern="^[^\s].*"
                          #refCustomer_FirstName="ngModel"
                          [(ngModel)]="customerModel.Customer_FirstName"
                          placeholder="First Name"
                          required
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted && refCustomer_FirstName.invalid,
                            'alert-warning':
                              refCustomer_FirstName.invalid &&
                              (refCustomer_FirstName.dirty ||
                                refCustomer_FirstName.touched ||
                                refCustomer_FirstName.untouched)
                          }"
                          name="Customer_FirstName"
                          id="Customer_FirstName"
                          class="form-control input-text-css"
                        />
                      </div>
                      <div class="col-md-2">
                        <span>Last Name</span>
                        <input
                          pattern="^[^\s].*"
                          type="text"
                          #refCustomer_LastName="ngModel"
                          [(ngModel)]="customerModel.Customer_LastName"
                          placeholder="Last Name"
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted && refCustomer_LastName.invalid,
                            'alert-warning':
                              refCustomer_LastName.invalid &&
                              (refCustomer_LastName.dirty ||
                                refCustomer_LastName.touched ||
                                refCustomer_LastName.untouched)
                          }"
                          name="Customer_LastName"
                          id="Customer_LastName"
                          class="form-control input-text-css"
                        />
                      </div>
                      <div class="col-md-2">
                        <span class="required-lable">Relation</span>
                        <select
                          name="Customer_Relation_Type"
                          id="Customer_Relation_Type"
                          #refCustomer_Relation_Type="ngModel"
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted &&
                              refCustomer_Relation_Type.invalid,
                            'alert-warning':
                              refCustomer_Relation_Type.invalid &&
                              (refCustomer_Relation_Type.dirty ||
                                refCustomer_Relation_Type.touched ||
                                refCustomer_Relation_Type.untouched)
                          }"
                          [(ngModel)]="customerModel.Customer_Relation_Type"
                          class="form-control input-text-css"
                          required
                        >
                          <option value="">Select</option>
                          <option value="C/O">C/O</option>
                          <option value="S/O">S/O</option>
                          <option value="D/O">D/O</option>
                          <option value="W/O">W/O</option>
                        </select>
                      </div>
                      <div class="col-md-3">
                        <span class="required-lable">First Name</span>
                        <input
                          pattern="^[^\s].*"
                          type="text"
                          #refCustomer_Relation_FirstName="ngModel"
                          [(ngModel)]="
                            customerModel.Customer_Relation_FirstName
                          "
                          placeholder="First Name"
                          required
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted &&
                              refCustomer_Relation_FirstName.invalid,
                            'alert-warning':
                              refCustomer_Relation_FirstName.invalid &&
                              (refCustomer_Relation_FirstName.dirty ||
                                refCustomer_Relation_FirstName.touched ||
                                refCustomer_Relation_FirstName.untouched)
                          }"
                          name="Customer_Relation_FirstName"
                          id="Customer_Relation_FirstName"
                          class="form-control input-text-css"
                        />
                      </div>
                      <div class="col-md-2">
                        <span>Last Name</span>
                        <input
                          pattern="^[^\s].*"
                          type="text"
                          #refCustomer_Relation_LastName="ngModel"
                          [(ngModel)]="customerModel.Customer_Relation_LastName"
                          placeholder="Last Name"
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted &&
                              refCustomer_Relation_LastName.invalid,
                            'alert-warning':
                              refCustomer_Relation_LastName.invalid &&
                              (refCustomer_Relation_LastName.dirty ||
                                refCustomer_Relation_LastName.touched ||
                                refCustomer_Relation_LastName.untouched)
                          }"
                          name="Customer_Relation_LastName"
                          id="Customer_Relation_LastName"
                          class="form-control input-text-css"
                        />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-3">
                        <span class="required-lable">Gender</span>
                        <select
                          name="Customer_Gender"
                          id="Customer_Gender"
                          #refCustomer_Gender="ngModel"
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted && refCustomer_Gender.invalid,
                            'alert-warning':
                              refCustomer_Gender.invalid &&
                              (refCustomer_Gender.dirty ||
                                refCustomer_Gender.touched ||
                                refCustomer_Gender.untouched)
                          }"
                          [(ngModel)]="customerModel.Customer_Gender"
                          class="form-control input-text-css"
                          required
                        >
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                        </select>
                      </div>
                      <div class="col-md-2">
                        <span class="required-lable">DOB</span>
                        <div class="datepicker_feild">
                          <input
                            [matDatepicker]="picker3"
                            dateConvert
                            placeholder="DD/MM/YYYY"
                            name="Customer_DOB"
                            id="Customer_DOB"
                            #refCustomer_DOB="ngModel"
                            required
                            (dateChange)="getAge($event)"
                            [ngClass]="{
                              'is-invalid':
                                ancf.submitted && refCustomer_DOB.invalid,
                              'alert-warning':
                                refCustomer_DOB.invalid &&
                                (refCustomer_DOB.dirty ||
                                  refCustomer_DOB.touched ||
                                  refCustomer_DOB.untouched)
                            }"
                            [(ngModel)]="customerModel.Customer_DOB"
                            class="form-control input-text-css"
                          />
                          <mat-datepicker-toggle
                            matSuffix
                            [for]="picker3"
                          ></mat-datepicker-toggle>
                          <mat-datepicker #picker3></mat-datepicker>
                        </div>
                        <!-- <input
                          name="Customer_DOB"
                          id="Customer_DOB"
                          #refCustomer_DOB="ngModel"
                          required
                          (change)="getAge($event)"
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted && refCustomer_DOB.invalid,
                            'alert-warning':
                              refCustomer_DOB.invalid &&
                              (refCustomer_DOB.dirty ||
                                refCustomer_DOB.touched ||
                                refCustomer_DOB.untouched)
                          }"
                          [(ngModel)]="customerModel.Customer_DOB"
                          value="{{
                            customerModel.Customer_DOB | date : 'dd/MM/yyyy'
                          }}"
                          placeholder="DD/MM/YYYY"
                          class="form-control input-text-css"
                          [(bsValue)]="customerModel.Customer_DOB"
                          bsDatepicker
                        /> -->
                      </div>
                      <div class="col-md-2">
                        <span> Age</span>
                        <input
                          name="Age"
                          id="Age"
                          disabled
                          value="{{ Age }}"
                          placeholder="Age"
                          class="form-control input-text-css"
                        />
                      </div>

                      <div class="col-md-2">
                        <!-- [ngClass]="OTPButton || customerModel.Customer_PhoneNo_IsVerified ? 'col-md-2' : 'col-md-3'" -->
                        <span class="required-lable">Primary Contact No.</span>
                        <!-- (blur)="checkPhonenumber()" -->
                        <input
                          type="text"
                          numbersOnly
                          maxlength="10"
                          minlength="10"
                          (blur)="checkPhonenumber()"
                          #refCustomer_PhoneNo="ngModel"
                          [(ngModel)]="customerModel.Customer_PhoneNo"
                          placeholder="Primary No"
                          required
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted && refCustomer_PhoneNo.invalid,
                            'alert-warning':
                              refCustomer_PhoneNo.invalid &&
                              (refCustomer_PhoneNo.dirty ||
                                refCustomer_PhoneNo.touched ||
                                refCustomer_PhoneNo.untouched)
                          }"
                          name="Customer_PhoneNo"
                          id="Customer_PhoneNo"
                          class="form-control input-text-css"
                        />
                      </div>
                      <div
                        class="col-md-2"
                        *ngIf="
                          !customerModel.Customer_PhoneNo_IsVerified &&
                          toShowMobileOTPbutton
                        "
                      >
                        <span> &nbsp;</span>
                        <button
                          type="button"
                          (click)="ongetotp()"
                          mat-raised-button
                          class="btn font-size-12 button-btn"
                          color="primary"
                        >
                          Get OTP
                        </button>
                      </div>

                      <div
                        class="col-md-1"
                        *ngIf="customerModel.Customer_PhoneNo_IsVerified"
                      >
                        <i
                          class="fa fa-check-circle"
                          style="
                            color: green;
                            font-size: 24px;
                            margin: 19px 0 0 -20px;
                          "
                        ></i>
                      </div>
                      <div class="col-md-2">
                        <span> Alternate No.</span>
                        <input
                          type="text"
                          numbersOnly
                          maxlength="10"
                          minlength="10"
                          #refCustomer_PhoneNo1="ngModel"
                          [(ngModel)]="customerModel.Customer_PhoneNo1"
                          placeholder="Alternate No."
                          name="Customer_PhoneNo1"
                          id="Customer_PhoneNo1"
                          class="form-control input-text-css"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <span>Customer Photo</span>
                    <input
                      #cpFile
                      type="file"
                      [multiple]="false"
                      accept="image/*"
                      (change)="fileChangeListener1(cpFile.files)"
                      style="display: none"
                    />
                    <p class="mt-1" *ngIf="!customerModel.Customer_ProfilePic">
                      Upload Photo:
                      <i
                        class="fa fa-upload ml-2 mt-1"
                        (click)="cpFile.click()"
                        aria-hidden="true"
                      ></i>
                    </p>
                    <ng-container *ngIf="customerModel.Customer_ProfilePic">
                      <div class="c-img mr-2">
                        <img
                          src="data:image/png;base64,{{
                            customerModel.DocData
                          }}"
                          alt=""
                          *ngIf="customerModel.DocData"
                          height="85"
                        />

                        <img
                          src="{{ customerProfileBaseUrl }}{{
                            customerModel.CustomerId
                          }}/{{ customerModel.Customer_ProfilePic }}"
                          alt=""
                          *ngIf="!customerModel.DocData"
                          height="85"
                        />
                        <i
                          class="fa fa-times ml-1"
                          aria-hidden="true"
                          style="cursor: pointer; vertical-align: top"
                          (click)="removeFile1(cpFile)"
                        ></i>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="row m-0 mt-2">
                  <div class="col-md-10">
                    <div class="row">
                      <div class="col-md-3">
                        <span>Email</span>
                        <input
                          type="email"
                          #refCustomer_Email="ngModel"
                          [(ngModel)]="customerModel.Customer_Email"
                          placeholder="Email"
                          name="Customer_Email"
                          id="Customer_Email"
                          class="form-control input-text-css"
                        />
                      </div>
                      <div class="col-md-2">
                        <span> WhatsApp No.</span>
                        <input
                          type="text"
                          numbersOnly
                          maxlength="10"
                          minlength="10"
                          #refCustomer_WhatsAppNo="ngModel"
                          [(ngModel)]="customerModel.Customer_WhatsAppNo"
                          placeholder="WhatsApp No"
                          name="Customer_WhatsAppNo"
                          id="Customer_WhatsAppNo"
                          class="form-control input-text-css"
                        />
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Marital Status</span>
                        <select
                          name="Customer_MaritalStatus"
                          id="Customer_MaritalStatus"
                          #refCustomer_MaritalStatus="ngModel"
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted &&
                              refCustomer_MaritalStatus.invalid,
                            'alert-warning':
                              refCustomer_MaritalStatus.invalid &&
                              (refCustomer_MaritalStatus.dirty ||
                                refCustomer_MaritalStatus.touched ||
                                refCustomer_MaritalStatus.untouched)
                          }"
                          [(ngModel)]="customerModel.Customer_MaritalStatus"
                          class="form-control input-text-css"
                          required
                        >
                          <option value="">Select Status</option>
                          <option value="Married">Married</option>
                          <option value="UnMarried">UnMarried</option>
                          <option value="Widow">Widow/Widower</option>
                          <option value="Divorced">Divorced</option>
                          <option value="Separated">Separated</option>
                          <option value="Never Married">Never Married</option>
                        </select>
                      </div>

                      <div class="col-md-2">
                        <span class="required-lable">Customer Religion</span>
                        <select
                          name="Customer_Religion"
                          id="Customer_Religion"
                          #refCustomer_Religion="ngModel"
                          required
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted && refCustomer_Religion.invalid,
                            'alert-warning':
                              refCustomer_Religion.invalid &&
                              (refCustomer_Religion.dirty ||
                                refCustomer_Religion.touched ||
                                refCustomer_Religion.untouched)
                          }"
                          [(ngModel)]="customerModel.Customer_Religion"
                          class="form-control input-text-css"
                        >
                          <option value="">Select Religion</option>
                          <option value="Hindus">Hindus</option>
                          <option value="Muslims">Muslims</option>
                          <option value="Christians">Christians</option>
                          <option value="Sikhs">Sikhs</option>
                          <option value="Buddhists">Buddhists</option>
                          <option value="Jains">Jains</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      <div class="col-md-3">
                        <span class="required-lable">Customer Caste</span>
                        <select
                          name="Customer_Cast"
                          id="Customer_Cast"
                          #refCustomer_Cast="ngModel"
                          class="form-control input-text-css"
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted && refCustomer_Cast.invalid,
                            'alert-warning':
                              refCustomer_Cast.invalid &&
                              (refCustomer_Cast.dirty ||
                                refCustomer_Cast.touched ||
                                refCustomer_Cast.untouched)
                          }"
                          [(ngModel)]="customerModel.Customer_Cast"
                          required
                        >
                          <option value="">Select Caste</option>
                          <option value="General">General</option>
                          <option value="OBC">OBC</option>
                          <option value="SC">SC</option>
                          <option value="ST">ST</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row m-0 mt-2">
                  <div class="col-md-10">
                    <div class="row">
                      <div class="col-md-3">
                        <span class="required-lable">Customer Profile </span>
                        <select
                          name="Customer_Profile"
                          id="Customer_Profile"
                          #refCustomer_Profile="ngModel"
                          (change)="getCategoryDataList()"
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted && refCustomer_Profile.invalid,
                            'alert-warning':
                              refCustomer_Profile.invalid &&
                              (refCustomer_Profile.dirty ||
                                refCustomer_Profile.touched ||
                                refCustomer_Profile.untouched)
                          }"
                          [(ngModel)]="customerModel.Customer_Profile"
                          class="form-control input-text-css"
                          required
                        >
                          <option value="">Select Profile</option>
                          <option
                            *ngFor="let row of ProfileData"
                            [value]="row.Id"
                          >
                            {{ row.Value }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-2">
                        <span class="required-lable">Customer Category </span>
                        <select
                          name="Customer_Category"
                          id="Customer_Category"
                          #refCustomer_Category="ngModel"
                          (change)="getSubCategoryList()"
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted && refCustomer_Category.invalid,
                            'alert-warning':
                              refCustomer_Category.invalid &&
                              (refCustomer_Category.dirty ||
                                refCustomer_Category.touched ||
                                refCustomer_Category.untouched)
                          }"
                          [(ngModel)]="customerModel.Customer_Category"
                          class="form-control input-text-css"
                          required
                        >
                          <option value="">Select Category</option>
                          <option
                            *ngFor="let row of CategoryData"
                            [value]="row.Id"
                          >
                            {{ row.Value }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-2">
                        <span class="required-lable">Sub-Category </span>
                        <select
                          name="Customer_SubCategory"
                          id="Customer_SubCategory"
                          #refCustomer_SubCategory="ngModel"
                          (change)="getNatureofworkList()"
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted && refCustomer_SubCategory.invalid,
                            'alert-warning':
                              refCustomer_SubCategory.invalid &&
                              (refCustomer_SubCategory.dirty ||
                                refCustomer_SubCategory.touched ||
                                refCustomer_SubCategory.untouched)
                          }"
                          [(ngModel)]="customerModel.Customer_SubCategory"
                          class="form-control input-text-css"
                          required
                        >
                          <option value="">Select Sub-Category</option>
                          <option
                            *ngFor="let row of SubCategoryData"
                            [value]="row.Id"
                          >
                            {{ row.Value }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-2">
                        <span class="required-lable">Type</span>
                        <select
                          name="BusinessCategory"
                          id="BusinessCategory"
                          #refBusinessCategory="ngModel"
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted && refBusinessCategory.invalid,
                            'alert-warning':
                              refBusinessCategory.invalid &&
                              (refBusinessCategory.dirty ||
                                refBusinessCategory.touched ||
                                refBusinessCategory.untouched)
                          }"
                          [(ngModel)]="customerModel.BusinessCategory"
                          class="form-control input-text-css"
                          required
                        >
                          <option value="">Select Business Type</option>
                          <option
                            *ngFor="let item of BusinessCategoryList"
                            [value]="item.Value"
                          >
                            {{ item.Value }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-3">
                        <span class="required-lable">Nature of work </span>
                        <select
                          name="Customer_Natureofwork"
                          id="Customer_Natureofwork"
                          #refCustomer_Natureofwork="ngModel"
                          [ngClass]="{
                            'is-invalid':
                              ancf.submitted &&
                              refCustomer_Natureofwork.invalid,
                            'alert-warning':
                              refCustomer_Natureofwork.invalid &&
                              (refCustomer_Natureofwork.dirty ||
                                refCustomer_Natureofwork.touched ||
                                refCustomer_Natureofwork.untouched)
                          }"
                          [(ngModel)]="customerModel.Customer_Natureofwork"
                          class="form-control input-text-css"
                          required
                        >
                          <option value="">Select Nature of work</option>
                          <option
                            *ngFor="let row of NatureofworkData"
                            [value]="row.Id"
                          >
                            {{ row.Value }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <!-------------------------------------Customer address div start---------------------------------------------->
                <ng-container>
                  <!--  *ngIf="!isCustomerEdit" -->
                  <div class="row m-0 mt-2 col-md-12">
                    <div class="col-md-12 bg-success1">Customer Address:</div>
                  </div>
                  <div class="row m-0 mt-2">
                    <div class="col-md-12">
                      <h3 class="fw-7 fs-14">Present Address :</h3>
                    </div>
                  </div>
                  <div class="row m-0">
                    <div class="col-md-4">
                      <span class="required-lable">Address</span>
                      <input
                        type="text"
                        pattern="^[^\s].*"
                        #refPresentAddress="ngModel"
                        [(ngModel)]="customerAddressModel.Address"
                        placeholder="Address"
                        required
                        name="PresentAddress"
                        id="PresentAddress"
                        class="form-control input-text-css"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refPresentAddress.invalid,
                          'alert-warning':
                            refPresentAddress.invalid &&
                            (refPresentAddress.dirty ||
                              refPresentAddress.touched ||
                              refPresentAddress.untouched)
                        }"
                      />
                    </div>
                    <div class="col-md-3">
                      <span class="required-lable">Landmark</span>
                      <input
                        type="text"
                        pattern="^[^\s].*"
                        #refPresentLandMark="ngModel"
                        [(ngModel)]="customerAddressModel.LandMark"
                        placeholder="Landmark"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refPresentLandMark.invalid,
                          'alert-warning':
                            refPresentLandMark.invalid &&
                            (refPresentLandMark.dirty ||
                              refPresentLandMark.touched ||
                              refPresentLandMark.untouched)
                        }"
                        name="PresentCustLandMark"
                        id="PresentCustLandMark"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-2">
                      <span class="required-lable">PinCode</span>
                      <input
                        type="text"
                        #refPresentPinCode="ngModel"
                        [(ngModel)]="customerAddressModel.PinCode"
                        placeholder="PinCode"
                        required
                        maxlength="6"
                        minlength="6"
                        numbersOnly
                        name="PresentPinCode"
                        id="PresentPinCode"
                        class="form-control input-text-css"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refPresentPinCode.invalid,
                          'alert-warning':
                            refPresentPinCode.invalid &&
                            (refPresentPinCode.dirty ||
                              refPresentPinCode.touched ||
                              refPresentPinCode.untouched)
                        }"
                      />
                    </div>
                    <div class="col-md-3">
                      <span class="required-lable">State</span>
                      <select
                        name="PresentState"
                        id="PresentState"
                        #refPresentState="ngModel"
                        (change)="GetDistrickDropdownPresent()"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refPresentState.invalid,
                          'alert-warning':
                            refPresentState.invalid &&
                            (refPresentState.dirty ||
                              refPresentState.touched ||
                              refPresentState.untouched)
                        }"
                        [(ngModel)]="customerAddressModel.StateId"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select State</option>
                        <option
                          *ngFor="let state of stateDropdown"
                          [value]="state.StateId"
                        >
                          {{ state.State_Name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row m-0 mt-2">
                    <div class="col-md-2">
                      <span class="required-lable">District</span>
                      <select
                        name="PresentsDistrict"
                        id="PresentsDistrict"
                        #refPresentDistrict="ngModel"
                        (change)="GetTehasilDropdownPresent()"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refPresentDistrict.invalid,
                          'alert-warning':
                            refPresentDistrict.invalid &&
                            (refPresentDistrict.dirty ||
                              refPresentDistrict.touched ||
                              refPresentDistrict.untouched)
                        }"
                        [(ngModel)]="customerAddressModel.DistrictId"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select District</option>
                        <option
                          *ngFor="let district of districkDropdownPresent"
                          [value]="district.DistrictId"
                        >
                          {{ district.District_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <span class="required-lable">Tehsil</span>
                      <select
                        name="PresentsTehsil"
                        id="PresentsTehsil"
                        #refPresentTehsil="ngModel"
                        class="form-control input-text-css"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refPresentTehsil.invalid,
                          'alert-warning':
                            refPresentTehsil.invalid &&
                            (refPresentTehsil.dirty ||
                              refPresentTehsil.touched ||
                              refPresentTehsil.untouched)
                        }"
                        [(ngModel)]="customerAddressModel.TehsilId"
                      >
                        <option value="">Select Tehsil</option>
                        <option
                          *ngFor="let tehsil of tehasilDropdownPresent"
                          [value]="tehsil.TehsilId"
                        >
                          {{ tehsil.Tehsil_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <span class="required-lable">No of Year Living Here</span>
                      <input
                        type="text"
                        numbersOnly
                        maxlength="3"
                        name="PresentTotalYearsOnAddress"
                        id="PresentTotalYearsOnAddress"
                        class="form-control input-text-css"
                        required
                        #refPresentTotalYearsOnAddress="ngModel"
                        [(ngModel)]="customerAddressModel.NoOfLiving"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted &&
                            refPresentTotalYearsOnAddress.invalid,
                          'alert-warning':
                            refPresentTotalYearsOnAddress.invalid &&
                            (refPresentTotalYearsOnAddress.dirty ||
                              refPresentTotalYearsOnAddress.touched ||
                              refPresentTotalYearsOnAddress.untouched)
                        }"
                      />
                    </div>
                    <div class="col-md-1">
                      <span class="required-lable">Rent/Own</span>
                      <select
                        name="PresentAddressRentBuy"
                        id="PresentAddressRentBuy"
                        #refPresentAddressRentBuy="ngModel"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refPresentAddressRentBuy.invalid,
                          'alert-warning':
                            refPresentAddressRentBuy.invalid &&
                            (refPresentAddressRentBuy.dirty ||
                              refPresentAddressRentBuy.touched ||
                              refPresentAddressRentBuy.untouched)
                        }"
                        [(ngModel)]="customerAddressModel.RentOwn"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select</option>
                        <option value="Own">Own</option>
                        <option value="Rent">Rent</option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <span class="required-lable">Distance To Branch(KM)</span>
                      <input
                        type="text"
                        #refPresentNearstBranchDistance_KM="ngModel"
                        [(ngModel)]="customerAddressModel.DistanceBranch"
                        placeholder="Distance From Branch"
                        required
                        appTwoDigitDecimaNumber
                        class="form-control input-text-css"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted &&
                            refPresentNearstBranchDistance_KM.invalid,
                          'alert-warning':
                            refPresentNearstBranchDistance_KM.invalid &&
                            (refPresentNearstBranchDistance_KM.dirty ||
                              refPresentNearstBranchDistance_KM.touched ||
                              refPresentNearstBranchDistance_KM.untouched)
                        }"
                        name="PresentNearstBranchDistance_KM"
                        id="PresentNearstBranchDistance_KM"
                      />
                    </div>
                    <div class="col-md-3 pt-1">
                      <span> &nbsp;</span>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          name="PresentIsCommunicationAddress"
                          class="form-check-input"
                          id="PresentIsCommunicationAddress"
                          [(ngModel)]="
                            customerAddressModel.IsCommunicationAddress
                          "
                        />
                        <label
                          class="form-check-label"
                          for="PresentIsCommunicationAddress"
                          style="margin-top: 2px"
                        >
                          Is This Cummunication Address
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="row m-0">
                    <div class="col-md-12">
                      <hr />
                    </div>
                  </div>

                  <div class="row m-0 mt-1">
                    <div class="col-md-2">
                      <h3 class="fw-7 fs-14">Permanent Address :</h3>
                    </div>
                    <div class="col-md-10">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          name="exampleCheck2"
                          class="form-check-input"
                          id="exampleCheck2"
                          [(ngModel)]="
                            customerPermanentAddressModel.IfIsPermanentAddressSamePresentAddress
                          "
                          (change)="checkCAddressSame1($event)"
                        />
                        <label
                          class="form-check-label"
                          for="exampleCheck2"
                          style="margin-top: 2px"
                        >
                          Select if Permanent Address same as Present Address
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row m-0">
                    <div class="col-md-4">
                      <span class="required-lable">Address</span>
                      <input
                        pattern="^[^\s].*"
                        type="text"
                        #refPermanentAddress="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.Address"
                        placeholder="Address"
                        name="PermanentAddress"
                        id="PermanentAddress"
                        class="form-control input-text-css"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refPermanentAddress.invalid,
                          'alert-warning':
                            refPermanentAddress.invalid &&
                            (refPermanentAddress.dirty ||
                              refPermanentAddress.touched ||
                              refPermanentAddress.untouched)
                        }"
                      />
                    </div>
                    <div class="col-md-3">
                      <span class="required-lable">Landmark</span>
                      <input
                        pattern="^[^\s].*"
                        type="text"
                        #refPermanentLandMark="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.LandMark"
                        placeholder="Landmark"
                        name="PermanentLandMark"
                        id="PermanentLandMark"
                        class="form-control input-text-css"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refPermanentLandMark.invalid,
                          'alert-warning':
                            refPermanentLandMark.invalid &&
                            (refPermanentLandMark.dirty ||
                              refPermanentLandMark.touched ||
                              refPermanentLandMark.untouched)
                        }"
                      />
                    </div>
                    <div class="col-md-2">
                      <span class="required-lable">PinCode</span>
                      <input
                        type="text"
                        #refPermanentPinCode="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.PinCode"
                        placeholder="PinCode"
                        maxlength="6"
                        minlength="6"
                        numbersOnly
                        name="PermanentPinCode"
                        id="PermanentPinCode"
                        class="form-control input-text-css"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refPermanentPinCode.invalid,
                          'alert-warning':
                            refPermanentPinCode.invalid &&
                            (refPermanentPinCode.dirty ||
                              refPermanentPinCode.touched ||
                              refPermanentPinCode.untouched)
                        }"
                      />
                    </div>
                    <div class="col-md-3">
                      <span class="required-lable">State</span>
                      <select
                        name="PermanentState"
                        id="PermanentState"
                        #refPermanentState="ngModel"
                        class="form-control input-text-css"
                        [(ngModel)]="customerPermanentAddressModel.StateId"
                        (change)="GetDistrickDropdownPermanent()"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refPermanentState.invalid,
                          'alert-warning':
                            refPermanentState.invalid &&
                            (refPermanentState.dirty ||
                              refPermanentState.touched ||
                              refPermanentState.untouched)
                        }"
                      >
                        <option value="">Select State</option>
                        <option
                          *ngFor="let state of stateDropdown"
                          [value]="state.StateId"
                        >
                          {{ state.State_Name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row m-0 mt-2">
                    <div class="col-md-2">
                      <span class="required-lable">District</span>
                      <select
                        name="PermanentDistrict"
                        id="PermanentDistrict"
                        #refPermanentDistrict="ngModel"
                        class="form-control input-text-css"
                        [(ngModel)]="customerPermanentAddressModel.DistrictId"
                        (change)="GetTehasilDropdownPermanent()"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refPermanentDistrict.invalid,
                          'alert-warning':
                            refPermanentDistrict.invalid &&
                            (refPermanentDistrict.dirty ||
                              refPermanentDistrict.touched ||
                              refPermanentDistrict.untouched)
                        }"
                      >
                        <option value="">Select District</option>
                        <option
                          *ngFor="let district of districkDropdownPermanent"
                          [value]="district.DistrictId"
                        >
                          {{ district.District_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <span class="required-lable">Tehsil</span>
                      <select
                        name="PermanentTehsil"
                        id="PermanentTehsil"
                        #refPermanentTehsil="ngModel"
                        class="form-control input-text-css"
                        [(ngModel)]="customerPermanentAddressModel.TehsilId"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refPermanentTehsil.invalid,
                          'alert-warning':
                            refPermanentTehsil.invalid &&
                            (refPermanentTehsil.dirty ||
                              refPermanentTehsil.touched ||
                              refPermanentTehsil.untouched)
                        }"
                      >
                        <option value="">Select Tehsil</option>
                        <option
                          *ngFor="let tehsil of tehasilDropdownPermanent"
                          [value]="tehsil.TehsilId"
                        >
                          {{ tehsil.Tehsil_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <span class="required-lable">No of Year Living Here</span>
                      <input
                        type="text"
                        numbersOnly
                        maxlength="3"
                        name="PermanentTotalYearsOnAddress"
                        id="PermanentTotalYearsOnAddress"
                        class="form-control input-text-css"
                        #refPermanentTotalYearsOnAddress="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.NoOfLiving"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted &&
                            refPermanentTotalYearsOnAddress.invalid,
                          'alert-warning':
                            refPermanentTotalYearsOnAddress.invalid &&
                            (refPermanentTotalYearsOnAddress.dirty ||
                              refPermanentTotalYearsOnAddress.touched ||
                              refPermanentTotalYearsOnAddress.untouched)
                        }"
                      />
                    </div>
                    <div class="col-md-1">
                      <span class="required-lable">Rent/Own</span>
                      <select
                        name="PermanentAddressRentBuy"
                        id="PermanentAddressRentBuy"
                        class="form-control input-text-css"
                        #refPermanentAddressRentBuy="ngModel"
                        [(ngModel)]="customerPermanentAddressModel.RentOwn"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted &&
                            refPermanentAddressRentBuy.invalid,
                          'alert-warning':
                            refPermanentAddressRentBuy.invalid &&
                            (refPermanentAddressRentBuy.dirty ||
                              refPermanentAddressRentBuy.touched ||
                              refPermanentAddressRentBuy.untouched)
                        }"
                      >
                        <option value="">Select</option>
                        <option value="Own">Own</option>
                        <option value="Rent">Rent</option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <span class="required-lable">Distance To Branch(KM)</span>
                      <input
                        type="text"
                        #refPermanentNearstBranchDistance_KM="ngModel"
                        placeholder="Distance From Branch"
                        [(ngModel)]="
                          customerPermanentAddressModel.DistanceBranch
                        "
                        appTwoDigitDecimaNumber
                        name="PermanentNearstBranchDistance_KM"
                        id="PermanentNearstBranchDistance_KM"
                        class="form-control input-text-css"
                        required
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted &&
                            refPermanentNearstBranchDistance_KM.invalid,
                          'alert-warning':
                            refPermanentNearstBranchDistance_KM.invalid &&
                            (refPermanentNearstBranchDistance_KM.dirty ||
                              refPermanentNearstBranchDistance_KM.touched ||
                              refPermanentNearstBranchDistance_KM.untouched)
                        }"
                      />
                    </div>
                    <div class="col-md-3">
                      <span> &nbsp;</span>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          name="PermanentIsCommunicationAddress"
                          id="PermanentIsCommunicationAddress"
                          [(ngModel)]="
                            customerPermanentAddressModel.IsCommunicationAddress
                          "
                          class="form-check-input"
                        />
                        <label
                          class="form-check-label"
                          for="PermanentIsCommunicationAddress"
                          style="margin-top: 2px"
                        >
                          Is This Cummunication Address
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="row m-0">
                    <div class="col-md-12">
                      <hr />
                    </div>
                  </div>

                  <div class="row m-0 mt-1">
                    <div class="col-md-12">
                      <h3 class="fw-7 fs-14">Work Address :</h3>
                    </div>
                  </div>
                  <div class="row m-0">
                    <div class="col-md-4">
                      <span> Address</span>
                      <input
                        pattern="^[^\s].*"
                        type="text"
                        #refWorkAddress="ngModel"
                        [(ngModel)]="customerWorkAddressModel.Address"
                        placeholder="Address"
                        name="WorkAddress"
                        id="WorkAddress"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3">
                      <span> Landmark</span>
                      <input
                        pattern="^[^\s].*"
                        type="text"
                        #refWorkLandMark="ngModel"
                        [(ngModel)]="customerWorkAddressModel.LandMark"
                        placeholder="Landmark"
                        name="WorkLandMark"
                        id="WorkLandMark"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-2">
                      <span> PinCode</span>
                      <input
                        type="text"
                        #refWorkPinCode="ngModel"
                        [(ngModel)]="customerWorkAddressModel.PinCode"
                        placeholder="PinCode"
                        maxlength="6"
                        minlength="6"
                        numbersOnly
                        name="WorkPinCode"
                        id="WorkPinCode"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3">
                      <span> State</span>
                      <select
                        name="WorkState"
                        id="WorkState"
                        #refWorkState="ngModel"
                        [(ngModel)]="customerWorkAddressModel.StateId"
                        class="form-control input-text-css"
                        (change)="GetDistrickDropdownWork()"
                      >
                        <option value="">Select State</option>
                        <option
                          *ngFor="let state of stateDropdown"
                          [value]="state.StateId"
                        >
                          {{ state.State_Name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row m-0 mt-2">
                    <div class="col-md-2">
                      <span>District</span>
                      <select
                        name="WorkDistrict"
                        id="WorkDistrict"
                        #refWorkDistrict="ngModel"
                        [(ngModel)]="customerWorkAddressModel.DistrictId"
                        class="form-control input-text-css"
                        (change)="GetTehasilDropdownWork()"
                      >
                        <option value="">Select District</option>
                        <option
                          *ngFor="let district of districkDropdownWork"
                          [value]="district.DistrictId"
                        >
                          {{ district.District_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <span>Tehsil</span>
                      <select
                        name="WorkTehsil"
                        id="WorkTehsil"
                        #refWorkTehsil="ngModel"
                        [(ngModel)]="customerWorkAddressModel.TehsilId"
                        class="form-control input-text-css"
                      >
                        <option value="">Select Tehsil</option>
                        <option
                          *ngFor="let tehsil of tehasilDropdownWork"
                          [value]="tehsil.TehsilId"
                        >
                          {{ tehsil.Tehsil_Name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <span>No of Year Working Here</span>

                      <input
                        type="text"
                        numbersOnly
                        maxlength="3"
                        name="WorkTotalYearsOnAddress"
                        id="WorkTotalYearsOnAddress"
                        [(ngModel)]="customerWorkAddressModel.NoOfLiving"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-1">
                      <span>Rent/Own</span>
                      <select
                        name="WorkAddressRentBuy"
                        id="WorkAddressRentBuy"
                        #refWorkAddressRentBuy="ngModel"
                        [(ngModel)]="customerWorkAddressModel.RentOwn"
                        class="form-control input-text-css"
                      >
                        <option value="">Select</option>
                        <option value="Own">Own</option>
                        <option value="Rent">Rent</option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <span>Distance To Branch(KM)</span>
                      <input
                        type="text"
                        #refWorkNearstBranchDistance_KM="ngModel"
                        placeholder="Distance From Branch"
                        [(ngModel)]="customerWorkAddressModel.DistanceBranch"
                        appTwoDigitDecimaNumber
                        name="WorkNearstBranchDistance_KM"
                        id="WorkNearstBranchDistance_KM"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3">
                      <span> &nbsp;</span>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          name="WorkIsCommunicationAddress"
                          id="WorkIsCommunicationAddress"
                          class="form-check-input"
                          [(ngModel)]="
                            customerWorkAddressModel.IsCommunicationAddress
                          "
                        />
                        <label
                          class="form-check-label"
                          for="WorkIsCommunicationAddress"
                          style="margin-top: 2px"
                        >
                          Is This Cummunication Address
                        </label>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="customerModel.Type == 'Company'">
                <ng-container>
                  <div class="row m-0 col-md-12">
                    <div class="col-md-12 bg-success1">KYC Documents:</div>
                  </div>
                  <div
                    class="row mt-2 m-0"
                    *ngFor="let kyc of firmKYCDoc; let i = index"
                  >
                    <div class="col-md-2">
                      <span class="required-lable">Document Type</span>
                      <select
                        name="DocId{{ i }}"
                        id="DocId{{ i }}"
                        #refDocId="ngModel"
                        (change)="onChangeDocument(kyc, i)"
                        [ngClass]="{
                          'is-invalid': ancf.submitted && refDocId.invalid,
                          'alert-warning':
                            refDocId.invalid &&
                            (refDocId.dirty ||
                              refDocId.touched ||
                              refDocId.untouched)
                        }"
                        [(ngModel)]="kyc.KYC_DocId"
                        class="form-control input-text-css"
                        required
                      >
                        <option value="">Select Document type</option>
                        <option
                          *ngFor="let Doc of ProductDropdownList"
                          [value]="Doc.DocId"
                          [ngClass]="{
                            FontBold:
                              (customerModel.Customer_Type == 'H' &&
                                Doc.IsHMandatory == true) ||
                              (customerModel.Customer_Type == 'C' &&
                                Doc.IsCMandatory == true) ||
                              (customerModel.Customer_Type == 'G' &&
                                Doc.IsGMandatory == true)
                          }"
                        >
                          {{ Doc.Doc_Name }}
                        </option>
                        <!-- <option value="1"> PAN Card</option>
                        <option value="28"> Firm Registration No</option>
                        <option value="29"> GST No</option>
                        <option value="30"> TAN No</option> -->
                      </select>
                    </div>
                    <div class="col-md-3">
                      <span class="required-lable">Document Number</span>
                      <input
                        type="text"
                        #refDocNo="ngModel"
                        [(ngModel)]="kyc.KYC_DocNumber"
                        placeholder="Document Number"
                        required
                        *ngIf="kyc.KYC_DocId != 1"
                        name="DocNo{{ i }}"
                        id="DocNo{{ i }}"
                        class="form-control input-text-css"
                        (change)="Get_Alredy_Verified_KYC(kyc, i)"
                        [ngClass]="{
                          'is-invalid': ancf.submitted && refDocNo.invalid,
                          'alert-warning':
                            refDocNo.invalid &&
                            (refDocNo.dirty ||
                              refDocNo.touched ||
                              refDocNo.untouched)
                        }"
                      />
                      <input
                        type="text"
                        #refDocNo="ngModel"
                        [(ngModel)]="kyc.KYC_DocNumber"
                        placeholder="Document Number"
                        required
                        name="DocNo{{ i }}"
                        id="DocNo{{ i }}"
                        *ngIf="kyc.KYC_DocId == 1"
                        maxlength="10"
                        minlength="10"
                        (change)="Get_Alredy_Verified_KYC(kyc, i)"
                        [ngClass]="{
                          'is-invalid': ancf.submitted && refDocNo.invalid,
                          'alert-warning':
                            refDocNo.invalid &&
                            (refDocNo.dirty ||
                              refDocNo.touched ||
                              refDocNo.untouched)
                        }"
                        class="form-control input-text-css"
                        pattern="[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}"
                      />
                    </div>
                    <div class="col-md-2">
                      <span> Document Image</span>
                      <p class="m-0 mt-2 row" style="overflow-wrap: anywhere">
                        <i
                          class="fa fa-upload ml-2"
                          *ngIf="!kyc.KYC_DocFile"
                          (click)="docFile.click()"
                          aria-hidden="true"
                        ></i>
                        <ng-container *ngIf="kyc.KYC_DocFile">
                          <div>
                            <i
                              class="fa fa-file-pdf mr-2"
                              aria-hidden="true"
                            ></i
                            >{{ kyc.KYC_DocFile }}
                            <i
                              class="fa fa-times ml-2"
                              aria-hidden="true"
                              style="cursor: pointer"
                              (click)="removeFileFirm(kyc, KYC_DocFile)"
                            ></i>
                          </div>
                        </ng-container>
                      </p>
                      <input
                        #docFile
                        type="file"
                        [multiple]="false"
                        accept="application/pdf,application/vnd.ms-excel"
                        (change)="fileChangeListenerFirm(kyc, docFile.files)"
                        style="display: none"
                      />
                    </div>
                    <ng-container
                      *ngIf="
                        kyc.DocId == 1 || kyc.DocId == 29 || kyc.DocId == 28
                      "
                    >
                      <button
                        type="button"
                        *ngIf="kyc.Verified_Button"
                        [hidden]="
                          kyc.KYC_IsVerified == 1 || kyc.LastVerfiedDate
                        "
                        (click)="onVerification(kyc, i, 0)"
                        class="font-size-12 button-btn"
                      >
                        Verification
                      </button>
                      <i
                        class="fa fa-check-circle ml-2"
                        *ngIf="
                          kyc.Verified_Button &&
                          (kyc.KYC_IsVerified == 1 || kyc.LastVerfiedDate)
                        "
                        style="color: green; font-size: 24px; margin-top: 24px"
                      ></i>

                      <button
                        type="button"
                        *ngIf="
                          kyc.Verified_Button &&
                          (kyc.KYC_IsVerified == 1 || kyc.LastVerfiedDate)
                        "
                        (click)="onVerification(kyc, i, 1)"
                        class="font-size-12 button-btn verify-button-btn"
                      >
                        RE-Verification
                      </button>
                      <span
                        *ngIf="kyc.Verified_Button && kyc.LastVerfiedDate"
                        style="margin-top: 25px"
                      >
                        LAST VERIFIED
                        {{ kyc.LastVerfiedDate }}
                      </span>
                    </ng-container>
                    <div class="col-md-1 pt-3" style="margin-top: 5px">
                      <button
                        type="button"
                        class="remove"
                        (click)="removeFirmKYC(i)"
                        *ngIf="i < firmKYCDoc.length && firmKYCDoc.length > 1"
                      >
                        -
                      </button>
                      <button
                        type="button"
                        class="addmore"
                        (click)="addFirmKyc()"
                        *ngIf="i == firmKYCDoc.length - 1"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </ng-container>

                <div class="row m-0 mt-2 col-md-12">
                  <div class="col-md-12 bg-success1">
                    {{ FirmTypes }} Information:
                  </div>
                </div>
                <div class="row m-0 mt-2">
                  <!-- <div class="col-md-2">
                    <span class="required-lable">Firm Type</span>
                    <select name="Firm_Type" id="Firm_Type" #refFirm_Type="ngModel"
                      [ngClass]="{ 'is-invalid': ancf.submitted && refFirm_Type.invalid, 'alert-warning': refFirm_Type.invalid  && (refFirm_Type.dirty || refFirm_Type.touched || refFirm_Type.untouched) }"
                      [(ngModel)]="customerModel.Firm_Type" class="form-control input-text-css" required>
                      <option value=""> Select Firm type</option>
                      <option value="Firm"> Firm</option>
                      <option value=" Company-LTD(P)"> Company-LTD (P)</option>
                      <option value="Company–LLP"> Company–LLP</option>
                      <option value="Company-LTD"> Company-LTD</option>
                      <option value="Trust"> Trust</option>
                      <option value="HUF"> HUF</option>
                      <option value="Institute"> Institute</option>
                    </select>
                  </div> -->
                  <div class="col-md-3">
                    <span class="required-lable">{{ FirmTypes }} Name</span>
                    <input
                      type="text"
                      pattern="^[^\s].*"
                      #refFirm_Name="ngModel"
                      [(ngModel)]="customerModel.Firm_Name"
                      placeholder="{{ FirmTypes }} Name"
                      [ngClass]="{
                        'is-invalid': ancf.submitted && refFirm_Name.invalid,
                        'alert-warning':
                          refFirm_Name.invalid &&
                          (refFirm_Name.dirty ||
                            refFirm_Name.touched ||
                            refFirm_Name.untouched)
                      }"
                      name="Firm_Name"
                      id="Firm_Name"
                      class="form-control input-text-css"
                      required
                    />
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">Date of Incorporation</span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker4"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="Firm_Date_Of_Incorruptions"
                        id="Firm_Date_Of_Incorruptions"
                        #refFirm_Date_Of_Incorruptions="ngModel"
                        required
                        [(ngModel)]="customerModel.Firm_Date_Of_Incorruptions"
                        class="form-control input-text-css"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted &&
                            refFirm_Date_Of_Incorruptions.invalid,
                          'alert-warning':
                            refFirm_Date_Of_Incorruptions.invalid &&
                            (refFirm_Date_Of_Incorruptions.dirty ||
                              refFirm_Date_Of_Incorruptions.touched ||
                              refFirm_Date_Of_Incorruptions.untouched)
                        }"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker4"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker4></mat-datepicker>
                    </div>
                    <!-- <input
                      name="Firm_Date_Of_Incorruptions"
                      id="Firm_Date_Of_Incorruptions"
                      #refFirm_Date_Of_Incorruptions="ngModel"
                      required
                      [(ngModel)]="customerModel.Firm_Date_Of_Incorruptions"
                      placeholder="DD/MM/YYYY"
                      class="form-control input-text-css"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted &&
                          refFirm_Date_Of_Incorruptions.invalid,
                        'alert-warning':
                          refFirm_Date_Of_Incorruptions.invalid &&
                          (refFirm_Date_Of_Incorruptions.dirty ||
                            refFirm_Date_Of_Incorruptions.touched ||
                            refFirm_Date_Of_Incorruptions.untouched)
                      }"
                      value="{{
                        customerModel.Firm_Date_Of_Incorruptions
                          | date : 'dd/MM/yyyy'
                      }}"
                      [(bsValue)]="customerModel.Firm_Date_Of_Incorruptions"
                      bsDatepicker
                    /> -->
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">No. of {{ FirmHead }}</span>
                    <input
                      type="text"
                      numbersOnly
                      #refFirm_No_Of_Partner="ngModel"
                      required
                      [value]="1"
                      [(ngModel)]="customerModel.Firm_No_Of_Partner"
                      placeholder="No of {{ FirmHead }}"
                      maxlength="2"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_No_Of_Partner.invalid,
                        'alert-warning':
                          refFirm_No_Of_Partner.invalid &&
                          (refFirm_No_Of_Partner.dirty ||
                            refFirm_No_Of_Partner.touched ||
                            refFirm_No_Of_Partner.untouched)
                      }"
                      name="Firm_No_Of_Partner"
                      id="Firm_No_Of_Partner"
                      class="form-control input-text-css"
                    />
                  </div>
                  <!-- <div class="col-md-2">
                    <span class="required-lable">Firm No of Employee</span>
                    <input type="text" numbersOnly #refFirm_No_Of_Employee="ngModel" required placeholder="No of Employee" maxlength="5"
                      [(ngModel)]="customerModel.Firm_No_Of_Employee" class="form-control input-text-css"
                      [ngClass]="{ 'is-invalid': ancf.submitted && refFirm_No_Of_Employee.invalid, 'alert-warning': refFirm_No_Of_Employee.invalid  && (refFirm_No_Of_Employee.dirty || refFirm_No_Of_Employee.touched || refFirm_No_Of_Employee.untouched) }"
                      name="Firm_No_Of_Employee" id="Firm_No_Of_Employee">
                  </div> -->
                  <div class="col-md-2">
                    <span class="required-lable">Net of Fund</span>
                    <input
                      type="text"
                      numbersOnly
                      #refFirm_GrossValue="ngModel"
                      required
                      [(ngModel)]="customerModel.Firm_GrossValue"
                      placeholder="Net of Fund"
                      maxlength="12"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_GrossValue.invalid,
                        'alert-warning':
                          refFirm_GrossValue.invalid &&
                          (refFirm_GrossValue.dirty ||
                            refFirm_GrossValue.touched ||
                            refFirm_GrossValue.untouched)
                      }"
                      name="Firm_GrossValue"
                      id="Firm_GrossValue"
                      class="form-control input-text-css"
                    />
                  </div>

                  <div class="col-md-2">
                    <!-- [ngClass]="OTPButton || customerModel.Firm_PhoneNoIsVerified ? 'col-md-2' : 'col-md-3'" -->
                    <span class="required-lable">Primary Contact No.</span>
                    <input
                      type="text"
                      numbersOnly
                      maxlength="10"
                      minlength="10"
                      (blur)="checkFirmPhonenumber()"
                      #refFirm_PhoneNo="ngModel"
                      [(ngModel)]="customerModel.Firm_PhoneNo"
                      placeholder="Primary Contact No"
                      required
                      [ngClass]="{
                        'is-invalid': ancf.submitted && refFirm_PhoneNo.invalid,
                        'alert-warning':
                          refFirm_PhoneNo.invalid &&
                          (refFirm_PhoneNo.dirty ||
                            refFirm_PhoneNo.touched ||
                            refFirm_PhoneNo.untouched)
                      }"
                      name="Firm_PhoneNo"
                      id="Firm_PhoneNo"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div
                    class="col-md-1"
                    *ngIf="
                      !customerModel.Firm_PhoneNoIsVerified &&
                      toShowMobileOTPbutton
                    "
                  >
                    <span> &nbsp;</span>
                    <button
                      type="button"
                      (click)="ongetotp()"
                      class="font-size-12 button-btn"
                    >
                      Get OTP
                    </button>
                  </div>
                  <div
                    class="col-md-1"
                    *ngIf="customerModel.Firm_PhoneNoIsVerified"
                  >
                    <i
                      class="fa fa-check-circle ml-2"
                      style="
                        color: green;
                        font-size: 24px;
                        margin: 19px 0 0 -20px;
                      "
                    ></i>
                  </div>
                </div>
                <div class="row m-0 mt-2">
                  <div class="col-md-3">
                    <span>Alternet Contact No.</span>
                    <input
                      type="text"
                      numbersOnly
                      maxlength="10"
                      minlength="10"
                      #refFirm_PhoneNo1="ngModel"
                      [(ngModel)]="customerModel.Firm_PhoneNo1"
                      placeholder="Alternet Contact No"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_PhoneNo1.invalid,
                        'alert-warning':
                          refFirm_PhoneNo1.invalid &&
                          (refFirm_PhoneNo1.dirty ||
                            refFirm_PhoneNo1.touched ||
                            refFirm_PhoneNo1.untouched)
                      }"
                      name="Firm_PhoneNo1"
                      id="Firm_PhoneNo1"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-2">
                    <span>{{ FirmTypes }} Email</span>
                    <input
                      type="email"
                      #refFirm_Email="ngModel"
                      [(ngModel)]="customerModel.Firm_Email"
                      placeholder="{{ FirmTypes }} Email"
                      [ngClass]="{
                        'is-invalid': ancf.submitted && refFirm_Email.invalid,
                        'alert-warning':
                          refFirm_Email.invalid &&
                          (refFirm_Email.dirty ||
                            refFirm_Email.touched ||
                            refFirm_Email.untouched)
                      }"
                      name="Firm_Email"
                      id="Firm_Email"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-2">
                    <span>{{ FirmTypes }} Website</span>
                    <input
                      type="text"
                      #refFirm_Websitel="ngModel"
                      [(ngModel)]="customerModel.Firm_Website"
                      placeholder="{{ FirmTypes }} Website"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_Websitel.invalid,
                        'alert-warning':
                          refFirm_Websitel.invalid &&
                          (refFirm_Websitel.dirty ||
                            refFirm_Websitel.touched ||
                            refFirm_Websitel.untouched)
                      }"
                      name="Firm_Website"
                      id="Firm_Website"
                      class="form-control input-text-css"
                    />
                  </div>
                  <!--<div [ngClass]="FirmTypes == 'Company' ? 'col-md-3' : 'col-md-5'">
                    <span class="required-lable">{{FirmTypes}} Nature Of Business</span>
                    <input type="text" #refFirm_Nature_Of_Business="ngModel" required
                           [(ngModel)]="customerModel.Firm_Nature_Of_Business" placeholder="{{FirmTypes}} Nature Of Business"
                           [ngClass]="{ 'is-invalid': ancf.submitted && refFirm_Nature_Of_Business.invalid, 'alert-warning': refFirm_Nature_Of_Business.invalid  && (refFirm_Nature_Of_Business.dirty || refFirm_Nature_Of_Business.touched || refFirm_Nature_Of_Business.untouched) }"
                           name="Firm_Nature_Of_Business" id="Firm_Nature_Of_Business" class="form-control input-text-css">
                  </div>-->
                  <div class="col-md-2" *ngIf="FirmTypes == 'Company'">
                    <span class="required-lable">{{ FirmTypes }} CIN No.</span>
                    <input
                      type="text"
                      maxlength="21"
                      minlength="21"
                      #refFirm_CIN_No="ngModel"
                      required
                      [(ngModel)]="customerModel.Firm_CIN_No"
                      placeholder="{{ FirmTypes }} CIN No."
                      [ngClass]="{
                        'is-invalid': ancf.submitted && refFirm_CIN_No.invalid,
                        'alert-warning':
                          refFirm_CIN_No.invalid &&
                          (refFirm_CIN_No.dirty ||
                            refFirm_CIN_No.touched ||
                            refFirm_CIN_No.untouched)
                      }"
                      name="Firm_CIN_No"
                      id="Firm_CIN_No"
                      class="form-control input-text-css"
                    />
                  </div>
                  <!--<div class="col-md-3">
                    <span class="required-lable">Business Type</span>
                    <select name="BusinessType" id="BusinessType" #refBusinessType="ngModel"
                            [ngClass]="{ 'is-invalid': ancf.submitted && refBusinessType.invalid, 'alert-warning': refBusinessType.invalid  && (refBusinessType.dirty || refBusinessType.touched || refBusinessType.untouched) }"
                            [(ngModel)]="customerModel.BusinessType" class="form-control input-text-css" required>
                      <option value="">  Select Business Type </option>
                      <option *ngFor="let item of BusinessTypeList" [value]="item.Value">
                        {{item.Value}}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">Business Category</span>
                    <select name="BusinessCategory" id="BusinessCategory" #refBusinessCategory="ngModel"
                            [ngClass]="{ 'is-invalid': ancf.submitted && refBusinessCategory.invalid, 'alert-warning': refBusinessCategory.invalid  && (refBusinessCategory.dirty || refBusinessCategory.touched || refBusinessCategory.untouched) }"
                            [(ngModel)]="customerModel.BusinessCategory" class="form-control input-text-css" required>
                      <option value="">  Select Business Type </option>
                      <option *ngFor="let item of BusinessCategoryList" [value]="item.Value">
                        {{item.Value}}
                      </option>
                    </select>
                  </div>-->
                  <div class="col-md-3">
                    <span class="required-lable">Customer Profile </span>
                    <select
                      (change)="getCategoryDataList()"
                      name="Customer_Profile"
                      id="Customer_Profile"
                      #refCustomer_Profile="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refCustomer_Profile.invalid,
                        'alert-warning':
                          refCustomer_Profile.invalid &&
                          (refCustomer_Profile.dirty ||
                            refCustomer_Profile.touched ||
                            refCustomer_Profile.untouched)
                      }"
                      class="form-control input-text-css"
                      [(ngModel)]="customerModel.Customer_Profile"
                      required
                    >
                      <option value="">Select Profile</option>
                      <option *ngFor="let row of ProfileData" [value]="row.Id">
                        {{ row.Value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">Customer Category </span>
                    <select
                      name="Customer_Category"
                      id="Customer_Category"
                      #refCustomer_Category="ngModel"
                      (change)="getSubCategoryList()"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refCustomer_Category.invalid,
                        'alert-warning':
                          refCustomer_Category.invalid &&
                          (refCustomer_Category.dirty ||
                            refCustomer_Category.touched ||
                            refCustomer_Category.untouched)
                      }"
                      [(ngModel)]="customerModel.Customer_Category"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Category</option>
                      <option *ngFor="let row of CategoryData" [value]="row.Id">
                        {{ row.Value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">Sub-Category </span>
                    <select
                      name="Customer_SubCategory"
                      id="Customer_SubCategory"
                      #refCustomer_SubCategory="ngModel"
                      (change)="getNatureofworkList()"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refCustomer_SubCategory.invalid,
                        'alert-warning':
                          refCustomer_SubCategory.invalid &&
                          (refCustomer_SubCategory.dirty ||
                            refCustomer_SubCategory.touched ||
                            refCustomer_SubCategory.untouched)
                      }"
                      [(ngModel)]="customerModel.Customer_SubCategory"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Sub-Category</option>
                      <option
                        *ngFor="let row of SubCategoryData"
                        [value]="row.Id"
                      >
                        {{ row.Value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Nature of work </span>
                    <select
                      name="Customer_Natureofwork"
                      id="Customer_Natureofwork"
                      #refCustomer_Natureofwork="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refCustomer_Natureofwork.invalid,
                        'alert-warning':
                          refCustomer_Natureofwork.invalid &&
                          (refCustomer_Natureofwork.dirty ||
                            refCustomer_Natureofwork.touched ||
                            refCustomer_Natureofwork.untouched)
                      }"
                      [(ngModel)]="customerModel.Customer_Natureofwork"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Nature of work</option>
                      <option
                        *ngFor="let row of NatureofworkData"
                        [value]="row.Id"
                      >
                        {{ row.Value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">Business Category</span>
                    <select
                      name="BusinessCategory"
                      id="BusinessCategory"
                      #refBusinessCategory="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refBusinessCategory.invalid,
                        'alert-warning':
                          refBusinessCategory.invalid &&
                          (refBusinessCategory.dirty ||
                            refBusinessCategory.touched ||
                            refBusinessCategory.untouched)
                      }"
                      [(ngModel)]="customerModel.BusinessCategory"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Business Type</option>
                      <option
                        *ngFor="let item of BusinessCategoryList"
                        [value]="item.Value"
                      >
                        {{ item.Value }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row m-0">
                  <div class="col-md-12">
                    <hr />
                  </div>
                </div>

                <div class="row m-0 mt-2 col-md-12">
                  <div class="col-md-12 bg-success1">
                    {{ FirmTypes }} Address:
                  </div>
                </div>
                <div class="row m-0 mt-2">
                  <div class="col-md-12">
                    <h3 class="fw-7 fs-14">Registered Address :</h3>
                  </div>
                </div>

                <div class="row m-0">
                  <div class="col-md-4">
                    <span class="required-lable">Address</span>
                    <input
                      type="text"
                      pattern="^[^\s].*"
                      #refFirm_RegAddress="ngModel"
                      [(ngModel)]="customerModel.Firm_RegAddress"
                      placeholder="Reg Address"
                      required
                      name="Firm_RegAddress"
                      id="Firm_RegAddress"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_RegAddress.invalid,
                        'alert-warning':
                          refFirm_RegAddress.invalid &&
                          (refFirm_RegAddress.dirty ||
                            refFirm_RegAddress.touched ||
                            refFirm_RegAddress.untouched)
                      }"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Landmark</span>
                    <input
                      type="text"
                      pattern="^[^\s].*"
                      #refFirm_RegLandMark="ngModel"
                      [(ngModel)]="customerModel.Firm_RegLandMark"
                      placeholder="Reg LandMark"
                      required
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_RegLandMark.invalid,
                        'alert-warning':
                          refFirm_RegLandMark.invalid &&
                          (refFirm_RegLandMark.dirty ||
                            refFirm_RegLandMark.touched ||
                            refFirm_RegLandMark.untouched)
                      }"
                      name="Firm_RegLandMark"
                      id="Firm_RegLandMark"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">State</span>
                    <select
                      name="Firm_RegState"
                      id="Firm_RegState"
                      #refFirm_RegState="ngModel"
                      class="form-control input-text-css"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_RegState.invalid,
                        'alert-warning':
                          refFirm_RegState.invalid &&
                          (refFirm_RegState.dirty ||
                            refFirm_RegState.touched ||
                            refFirm_RegState.untouched)
                      }"
                      [(ngModel)]="customerModel.Firm_RegState"
                      required
                      (change)="GetDistrickDropdownReg()"
                    >
                      <option value="">Select State</option>
                      <option
                        *ngFor="let state of stateDropdown"
                        [value]="state.StateId"
                      >
                        {{ state.State_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">District</span>
                    <select
                      name="Firm_RegDistrict"
                      id="Firm_RegDistrict"
                      #refFirm_RegDistrict="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_RegDistrict.invalid,
                        'alert-warning':
                          refFirm_RegDistrict.invalid &&
                          (refFirm_RegDistrict.dirty ||
                            refFirm_RegDistrict.touched ||
                            refFirm_RegDistrict.untouched)
                      }"
                      [(ngModel)]="customerModel.Firm_RegDistrict"
                      class="form-control input-text-css"
                      required
                      (change)="GetTehasilDropdownReg()"
                    >
                      <option value="">Select District</option>
                      <option
                        *ngFor="let district of districkDropdownReg"
                        [value]="district.DistrictId"
                      >
                        {{ district.District_Name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row m-0 mt-2">
                  <div class="col-md-2">
                    <span class="required-lable">Tehsil</span>
                    <select
                      name="Firm_RegTehsil"
                      id="Firm_RegTehsil"
                      #refFirm_RegTehsil="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_RegTehsil.invalid,
                        'alert-warning':
                          refFirm_RegTehsil.invalid &&
                          (refFirm_RegTehsil.dirty ||
                            refFirm_RegTehsil.touched ||
                            refFirm_RegTehsil.untouched)
                      }"
                      [(ngModel)]="customerModel.Firm_RegTehsil"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Tehsil</option>
                      <option
                        *ngFor="let tehsil of tehasilDropdownReg"
                        [value]="tehsil.TehsilId"
                      >
                        {{ tehsil.Tehsil_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">PinCode</span>
                    <input
                      type="text"
                      #refFirm_RegPinCode="ngModel"
                      [(ngModel)]="customerModel.Firm_RegPinCode"
                      placeholder="Reg PinCode"
                      required
                      maxlength="6"
                      minlength="6"
                      numbersOnly
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_RegPinCode.invalid,
                        'alert-warning':
                          refFirm_RegPinCode.invalid &&
                          (refFirm_RegPinCode.dirty ||
                            refFirm_RegPinCode.touched ||
                            refFirm_RegPinCode.untouched)
                      }"
                      name="Firm_RegPinCode"
                      id="Firm_RegPinCode"
                      class="form-control input-text-css"
                    />
                  </div>

                  <div class="col-md-3">
                    <span class="required-lable">No of Year Living Here</span>
                    <input
                      type="text"
                      numbersOnly
                      maxlength="3"
                      name="Firm_RegTotalYearsOnAddress"
                      id="Firm_RegTotalYearsOnAddress"
                      #refFirm_RegTotalYearsOnAddress="ngModel"
                      [(ngModel)]="customerModel.Firm_RegTotalYearsOnAddress"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted &&
                          refFirm_RegTotalYearsOnAddress.invalid,
                        'alert-warning':
                          refFirm_RegTotalYearsOnAddress.invalid &&
                          (refFirm_RegTotalYearsOnAddress.dirty ||
                            refFirm_RegTotalYearsOnAddress.touched ||
                            refFirm_RegTotalYearsOnAddress.untouched)
                      }"
                      class="form-control input-text-css"
                      required
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable"
                      >Distance From Branch (KM)</span
                    >
                    <input
                      type="text"
                      name="Firm_RegNearstBranchDistance_KM"
                      id="Firm_RegNearstBranchDistance_KM"
                      #refFirm_RegNearstBranchDistance_KM="ngModel"
                      placeholder="Distance From Branch"
                      required
                      appTwoDigitDecimaNumber
                      [(ngModel)]="
                        customerModel.Firm_RegNearstBranchDistance_KM
                      "
                      class="form-control input-text-css"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted &&
                          refFirm_RegNearstBranchDistance_KM.invalid,
                        'alert-warning':
                          refFirm_RegNearstBranchDistance_KM.invalid &&
                          (refFirm_RegNearstBranchDistance_KM.dirty ||
                            refFirm_RegNearstBranchDistance_KM.touched ||
                            refFirm_RegNearstBranchDistance_KM.untouched)
                      }"
                    />
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">Rent/Own</span>
                    <select
                      name="Firm_RegAddressRentBuy"
                      id="Firm_RegAddressRentBuy"
                      #refFirm_RegAddressRentBuy="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_RegAddressRentBuy.invalid,
                        'alert-warning':
                          refFirm_RegAddressRentBuy.invalid &&
                          (refFirm_RegAddressRentBuy.dirty ||
                            refFirm_RegAddressRentBuy.touched ||
                            refFirm_RegAddressRentBuy.untouched)
                      }"
                      [(ngModel)]="customerModel.Firm_RegAddressRentBuy"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select</option>
                      <option value="Own">Own</option>
                      <option value="Rent">Rent</option>
                    </select>
                  </div>
                </div>

                <div class="row m-0">
                  <div class="col-md-12">
                    <hr />
                  </div>
                </div>

                <div class="row m-0 mt-1">
                  <div class="col-md-2">
                    <h3 class="fw-7 fs-14">Corporate Address :</h3>
                  </div>
                  <div class="col-md-10">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        name="exampleCheck1"
                        class="form-check-input"
                        id="exampleCheck1"
                        [(ngModel)]="customerModel.SameAddress"
                        (change)="checkAddressSame($event)"
                      />
                      <label
                        class="form-check-label"
                        for="exampleCheck1"
                        style="margin-top: 2px"
                      >
                        Corporate Address Same as Registered Address
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row m-0">
                  <div class="col-md-4">
                    <span class="required-lable">Address</span>
                    <input
                      pattern="^[^\s].*"
                      type="text"
                      #refFirm_CorpAddress="ngModel"
                      [(ngModel)]="customerModel.Firm_CorpAddress"
                      placeholder="Corp Address"
                      required
                      name="Firm_CorpAddress"
                      id="Firm_CorpAddress"
                      class="form-control input-text-css"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_CorpAddress.invalid,
                        'alert-warning':
                          refFirm_CorpAddress.invalid &&
                          (refFirm_CorpAddress.dirty ||
                            refFirm_CorpAddress.touched ||
                            refFirm_CorpAddress.untouched)
                      }"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">Landmark</span>
                    <input
                      type="text"
                      pattern="^[^\s].*"
                      #refFirm_CorpLandMark="ngModel"
                      [(ngModel)]="customerModel.Firm_CorpLandMark"
                      placeholder="Corp LandMark"
                      required
                      name="Firm_CorpLandMark"
                      id="Firm_CorpLandMark"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_CorpLandMark.invalid,
                        'alert-warning':
                          refFirm_CorpLandMark.invalid &&
                          (refFirm_CorpLandMark.dirty ||
                            refFirm_CorpLandMark.touched ||
                            refFirm_CorpLandMark.untouched)
                      }"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable">State</span>
                    <select
                      name="Firm_CorpState"
                      id="Firm_CorpState"
                      #refFirm_CorpState="ngModel"
                      class="form-control input-text-css"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_CorpState.invalid,
                        'alert-warning':
                          refFirm_CorpState.invalid &&
                          (refFirm_CorpState.dirty ||
                            refFirm_CorpState.touched ||
                            refFirm_CorpState.untouched)
                      }"
                      [(ngModel)]="customerModel.Firm_CorpState"
                      required
                      (change)="GetDistrickDropdownCorp()"
                    >
                      <option value="">Select State</option>
                      <option
                        *ngFor="let state of stateDropdown"
                        [value]="state.StateId"
                      >
                        {{ state.State_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">District</span>
                    <select
                      name="Firm_CorpDistrict"
                      id="Firm_CorpDistrict"
                      #refFirm_CorpDistrict="ngModel"
                      class="form-control input-text-css"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_CorpDistrict.invalid,
                        'alert-warning':
                          refFirm_CorpDistrict.invalid &&
                          (refFirm_CorpDistrict.dirty ||
                            refFirm_CorpDistrict.touched ||
                            refFirm_CorpDistrict.untouched)
                      }"
                      [(ngModel)]="customerModel.Firm_CorpDistrict"
                      required
                      (change)="GetTehasilDropdownCorp()"
                    >
                      <option value="">Select District</option>
                      <option
                        *ngFor="let district of districkDropdownCorp"
                        [value]="district.DistrictId"
                      >
                        {{ district.District_Name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row m-0 mt-2">
                  <div class="col-md-2">
                    <span class="required-lable">Tehsil</span>
                    <select
                      name="Firm_CorpTehsil"
                      id="Firm_CorpTehsil"
                      #refFirm_CorpTehsil="ngModel"
                      class="form-control input-text-css"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_CorpTehsil.invalid,
                        'alert-warning':
                          refFirm_CorpTehsil.invalid &&
                          (refFirm_CorpTehsil.dirty ||
                            refFirm_CorpTehsil.touched ||
                            refFirm_CorpTehsil.untouched)
                      }"
                      [(ngModel)]="customerModel.Firm_CorpTehsil"
                      required
                    >
                      <option value="">Select Tehsil</option>
                      <option
                        *ngFor="let tehsil of tehasilDropdownCorp"
                        [value]="tehsil.TehsilId"
                      >
                        {{ tehsil.Tehsil_Name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">PinCode</span>
                    <input
                      type="text"
                      #refFirm_CorpPinCode="ngModel"
                      [(ngModel)]="customerModel.Firm_CorpPinCode"
                      placeholder="Corp PinCode"
                      required
                      maxlength="6"
                      minlength="6"
                      numbersOnly
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_CorpPinCode.invalid,
                        'alert-warning':
                          refFirm_CorpPinCode.invalid &&
                          (refFirm_CorpPinCode.dirty ||
                            refFirm_CorpPinCode.touched ||
                            refFirm_CorpPinCode.untouched)
                      }"
                      name="Firm_CorpPinCode"
                      id="Firm_CorpPinCode"
                      class="form-control input-text-css"
                    />
                  </div>

                  <div class="col-md-3">
                    <span class="required-lable">No of Year Living Here</span>
                    <input
                      type="text"
                      numbersOnly
                      maxlength="3"
                      name="Firm_CorpTotalYearsOnAddress"
                      id="Firm_CorpTotalYearsOnAddress"
                      #refFirm_CorpTotalYearsOnAddress="ngModel"
                      [(ngModel)]="customerModel.Firm_CorpTotalYearsOnAddress"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted &&
                          refFirm_CorpTotalYearsOnAddress.invalid,
                        'alert-warning':
                          refFirm_CorpTotalYearsOnAddress.invalid &&
                          (refFirm_CorpTotalYearsOnAddress.dirty ||
                            refFirm_CorpTotalYearsOnAddress.touched ||
                            refFirm_CorpTotalYearsOnAddress.untouched)
                      }"
                      class="form-control input-text-css"
                      required
                    />
                  </div>
                  <div class="col-md-3">
                    <span class="required-lable"
                      >Distance From Branch (KM)</span
                    >
                    <input
                      type="text"
                      name="Firm_CorpNearstBranchDistance_KM"
                      id="Firm_CorpNearstBranchDistance_KM"
                      #refFirm_CorpNearstBranchDistance_KM="ngModel"
                      placeholder="Distance From Branch"
                      required
                      appTwoDigitDecimaNumber
                      [(ngModel)]="
                        customerModel.Firm_CorpNearstBranchDistance_KM
                      "
                      class="form-control input-text-css"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted &&
                          refFirm_CorpNearstBranchDistance_KM.invalid,
                        'alert-warning':
                          refFirm_CorpNearstBranchDistance_KM.invalid &&
                          (refFirm_CorpNearstBranchDistance_KM.dirty ||
                            refFirm_CorpNearstBranchDistance_KM.touched ||
                            refFirm_CorpNearstBranchDistance_KM.untouched)
                      }"
                    />
                  </div>
                  <div class="col-md-2">
                    <span class="required-lable">Rent/Own</span>
                    <select
                      name="Firm_CorpAddressRentBuy"
                      id="Firm_CorpAddressRentBuy"
                      #refFirm_CorpAddressRentBuy="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refFirm_CorpAddressRentBuy.invalid,
                        'alert-warning':
                          refFirm_CorpAddressRentBuy.invalid &&
                          (refFirm_CorpAddressRentBuy.dirty ||
                            refFirm_CorpAddressRentBuy.touched ||
                            refFirm_CorpAddressRentBuy.untouched)
                      }"
                      [(ngModel)]="customerModel.Firm_CorpAddressRentBuy"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select</option>
                      <option value="Own">Own</option>
                      <option value="Rent">Rent</option>
                    </select>
                  </div>
                </div>

                <div class="row m-0 mt-2 col-md-12">
                  <div class="col-md-12 bg-success1">
                    {{ FirmHead != "Chief" ? FirmHead + "'s" : FirmHead }}
                    Information:
                  </div>
                </div>
                <div
                  class="row m-0 mt-2"
                  *ngFor="let partner of firmPartners; let i = index"
                >
                  <div class="col-md-2">
                    <span class="required-lable">{{ FirmHead }} Name</span>
                    <!-- <input type="text" #refPartnerName="ngModel"  [(ngModel)]="partner.PartnerName" placeholder="{{FirmHead}} Name"
                    [ngClass]="{ 'is-invalid': ancf.submitted && refPartnerName.invalid, 'alert-warning': refPartnerName.invalid  && (refPartnerName.dirty || refPartnerName.touched || refPartnerName.untouched) }"
                    name="PartnerName{{i}}" id="PartnerName{{i}}" class="form-control input-text-css" required> -->

                    <input
                      style="display: inline-block; width: 80%"
                      required
                      type="text"
                      readonly
                      name="PartnerName{{ i }}"
                      id="PartnerName{{ i }}"
                      [(ngModel)]="partner.PartnerName"
                      class="form-control input-text-css"
                      (keyup.enter)="searchCustomer(partner, i)"
                    />
                    <button
                      type="button"
                      (click)="searchCustomer(partner, i)"
                      class="primary_btn ml-2 search"
                    >
                      <i
                        class="fa fa-search"
                        aria-hidden="true"
                        style="font-size: 11px"
                      ></i>
                    </button>
                  </div>
                  <div
                    [ngClass]="FirmTypes == 'Company' ? 'col-md-1' : 'col-md-2'"
                  >
                    <span class="required-lable">Gender </span>
                    <select
                      name="PartnerGender{{ i }}"
                      id="PartnerGender{{ i }}"
                      #refPartnerGender="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refPartnerGender.invalid,
                        'alert-warning':
                          refPartnerGender.invalid &&
                          (refPartnerGender.dirty ||
                            refPartnerGender.touched ||
                            refPartnerGender.untouched)
                      }"
                      [(ngModel)]="partner.PartnerGender"
                      class="form-control input-text-css"
                      required
                      disabled
                    >
                      <option value="">Select Gender</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                  </div>
                  <div class="col-md-1">
                    <span class="required-lable">Age</span>
                    <input
                      type="text"
                      numbersOnly
                      #refPartnerAge="ngModel"
                      maxlength="2"
                      minlength="2"
                      [(ngModel)]="partner.PartnerAge"
                      placeholder="Age"
                      required
                      readonly
                      [ngClass]="{
                        'is-invalid': ancf.submitted && refPartnerAge.invalid,
                        'alert-warning':
                          refPartnerAge.invalid &&
                          (refPartnerAge.dirty ||
                            refPartnerAge.touched ||
                            refPartnerAge.untouched)
                      }"
                      name="PartnerAge{{ i }}"
                      id="PartnerAge{{ i }}"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div
                    [ngClass]="
                      FirmTypes == 'Company' ||
                      customerModel.Firm_Type == 'PartnershipFirm'
                        ? 'col-md-2'
                        : 'col-md-3'
                    "
                  >
                    <span class="required-lable">Designation</span>
                    <!--<input type="text" #refPartnerDesignation="ngModel" [(ngModel)]="partner.PartnerDesignation" required
                           placeholder="Designation" name="PartnerDesignation{{i}}" id="PartnerDesignation{{i}}"
                           [ngClass]="{ 'is-invalid': ancf.submitted && refPartnerDesignation.invalid, 'alert-warning': refPartnerDesignation.invalid  && (refPartnerDesignation.dirty || refPartnerDesignation.touched || refPartnerDesignation.untouched) }"
                           class="form-control input-text-css" *ngIf="customerModel.Firm_Type != 'Trust' && customerModel.Firm_Type != 'NGO'">

                    <select name="PartnerDesignation{{i}}" id="PartnerDesignation{{i}}" #refPartnerDesignation="ngModel"
                            [ngClass]="{ 'is-invalid': ancf.submitted && refPartnerDesignation.invalid, 'alert-warning': refPartnerDesignation.invalid  && (refPartnerDesignation.dirty || refPartnerDesignation.touched || refPartnerDesignation.untouched) }"
                            [(ngModel)]="partner.PartnerDesignation" class="form-control input-text-css" required
                            *ngIf="customerModel.Firm_Type == 'Trust' || customerModel.Firm_Type == 'NGO'" [disabled]="customerModel.Firm_Type == 'NGO'">
                      <option value="">Select Designation</option>
                      <option value="Founder">Founder</option>
                      <option value="CoFounder">Co-Founder</option>
                      <option value="Member">Member</option>
                    </select>-->

                    <select
                      name="PartnerDesignation{{ i }}"
                      id="PartnerDesignation{{ i }}"
                      #refPartnerDesignation="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refPartnerDesignation.invalid,
                        'alert-warning':
                          refPartnerDesignation.invalid &&
                          (refPartnerDesignation.dirty ||
                            refPartnerDesignation.touched ||
                            refPartnerDesignation.untouched)
                      }"
                      [(ngModel)]="partner.PartnerDesignation"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Designation</option>
                      <option
                        *ngFor="let item of PartnerDesignationList"
                        [value]="item.Value"
                      >
                        {{ item.Value }}
                      </option>
                      <!--<option value=""> Select Firm type</option>
                      <option value="ProprietorFirm">Proprietor Firm</option>
                      <option value="PartnershipFirm">Partnership Firm</option>
                      <option value="Company-LTD(P)">Company-LTD (P)</option>
                      <option value="Company–LLP">Company–LLP</option>
                      <option value="Company-LTD">Company-LTD</option>
                      <option value="Trust">Trust</option>
                      <option value="HUF">HUF</option>
                      <option value="Institute">Institute</option>
                      <option value="NGO">NGO</option>-->
                    </select>
                  </div>
                  <div
                    [ngClass]="
                      FirmTypes == 'Company' ||
                      customerModel.Firm_Type == 'PartnershipFirm'
                        ? 'col-md-2'
                        : 'col-md-3'
                    "
                  >
                    <span class="required-lable">PhoneNo</span>
                    <input
                      type="text"
                      #refPartnerPhoneNo="ngModel"
                      numbersOnly
                      maxlength="10"
                      minlength="10"
                      [(ngModel)]="partner.PartnerPhoneNo"
                      placeholder="{{ FirmHead }} PhoneNo"
                      required
                      readonly
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refPartnerPhoneNo.invalid,
                        'alert-warning':
                          refPartnerPhoneNo.invalid &&
                          (refPartnerPhoneNo.dirty ||
                            refPartnerPhoneNo.touched ||
                            refPartnerPhoneNo.untouched)
                      }"
                      name="PartnerPhoneNo{{ i }}"
                      id="PartnerPhoneNo{{ i }}"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-2" *ngIf="FirmTypes == 'Company'">
                    <span class="required-lable">DIN No.</span>
                    <input
                      type="text"
                      numbersOnly
                      maxlength="8"
                      minlength="8"
                      #refPartnerDIN_No="ngModel"
                      required
                      [(ngModel)]="partner.PartnerDIN_No"
                      placeholder="DIN No."
                      [ngClass]="{
                        'is-invalid':
                          ancf.submitted && refPartnerDIN_No.invalid,
                        'alert-warning':
                          refPartnerDIN_No.invalid &&
                          (refPartnerDIN_No.dirty ||
                            refPartnerDIN_No.touched ||
                            refPartnerDIN_No.untouched)
                      }"
                      name="PartnerDIN_No{{ i }}"
                      id="PartnerDIN_No{{ i }}"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div
                    [ngClass]="FirmTypes == 'Company' ? 'col-md-1' : 'col-md-2'"
                    *ngIf="
                      (FirmTypes == 'Company' && FirmHead == 'Director') ||
                      (FirmTypes == 'Firm' && FirmHead == 'Partner')
                    "
                  >
                    <span class="required-lable">
                      {{
                        FirmTypes == "Company" ? "Share" : "Partner Ship Ratio"
                      }}
                      %
                    </span>
                    <input
                      type="text"
                      appTwoDigitDecimaNumber
                      #refPartnerShare="ngModel"
                      maxlength="4"
                      placeholder="Share %"
                      required
                      [(ngModel)]="partner.PartnerShare"
                      name="PartnerShare{{ i }}"
                      id="PartnerShare{{ i }}"
                      class="form-control input-text-css"
                      [ngClass]="{
                        'is-invalid': ancf.submitted && refPartnerShare.invalid,
                        'alert-warning':
                          refPartnerShare.invalid &&
                          (refPartnerShare.dirty ||
                            refPartnerShare.touched ||
                            refPartnerShare.untouched)
                      }"
                    />
                  </div>
                  <div
                    class="col-md-1 pt-3"
                    *ngIf="this.customerModel.Firm_Type != 'ProprietorFirm'"
                  >
                    <button
                      type="button"
                      class="remove"
                      (click)="removeFirmPartner(i)"
                      *ngIf="i < firmPartners.length && firmPartners.length > 1"
                    >
                      -
                    </button>
                    <button
                      type="button"
                      class="addmore"
                      (click)="addFirmPartner()"
                      *ngIf="i == firmPartners.length - 1"
                    >
                      +
                    </button>
                  </div>
                </div>
                <!--<div class="row m-0 mb-2 w-b p-0 col-md-12 align-items-center justify-content-between">
                  <h1 class="fs-12 m-0">Director's Information</h1>
                  <button type="button" class="primary-btn" (click)="addDirector()"> Add Director </button>
                </div>-->
              </ng-container>

              <div class="row m-0 mt-3 col-md-12">
                <div class="col-md-10 bg-success1">Bank Details:</div>
                <div class="col-md-2 bg-success1">
                  <button
                    type="button"
                    class="btn-warning"
                    *ngIf="Bank_Verification"
                    (click)="BankVerify()"
                  >
                    Bank Add/Verify
                  </button>
                </div>
              </div>
              <div
                class="row m-0 mt-2"
                *ngFor="let CBank of BankDetails; let i = index"
              >
                <div class="col-md-9">
                  <div class="row">
                    <div class="col-md-2">
                      <span
                        style="font-size: 13px"
                        [ngClass]="
                          CBank.BeneficiaryName != '' ? 'required-lable' : ''
                        "
                        >Beneficiary Name</span
                      >
                      <textarea
                        rows="1"
                        pattern="^[^\s].*"
                        name="BeneficiaryName{{ i }}"
                        id="BeneficiaryName{{ i }}"
                        [(ngModel)]="CBank.BeneficiaryName"
                        #refBeneficiaryName="ngModel"
                        placeholder="Beneficiary"
                        class="form-control input-text-css"
                        [required]="CBank.BeneficiaryName != ''"
                        [disabled]="CBank.IsEdit == 0 || Bank_Verification"
                        [ngClass]="{
                          'is-invalid':
                            ancf.submitted && refBeneficiaryName.invalid,
                          'alert-warning':
                            CBank.BeneficiaryName != '' &&
                            refBeneficiaryName.invalid &&
                            (refBeneficiaryName.dirty ||
                              refBeneficiaryName.touched ||
                              refBeneficiaryName.untouched)
                        }"
                      ></textarea>
                    </div>

                    <div class="col-md-2">
                      <span
                        [ngClass]="
                          CBank.BeneficiaryName != '' ? 'required-lable' : ''
                        "
                        >A/C No</span
                      >
                      <textarea
                        pattern="^[^\s].*"
                        rows="1"
                        name="AccountNo{{ i }}"
                        id="AccountNo{{ i }}"
                        [(ngModel)]="CBank.AccountNo"
                        #refAccountNo="ngModel"
                        placeholder="A/C No"
                        class="form-control input-text-css"
                        (change)="ChangeACNo(CBank, i)"
                        maxlength="20"
                        [required]="CBank.BeneficiaryName != ''"
                        [disabled]="CBank.IsEdit == 0 || Bank_Verification"
                        [ngClass]="{
                          'is-invalid': ancf.submitted && refAccountNo.invalid,
                          'alert-warning':
                            CBank.BeneficiaryName != '' &&
                            refAccountNo.invalid &&
                            (refAccountNo.dirty ||
                              refAccountNo.touched ||
                              refAccountNo.untouched)
                        }"
                      ></textarea>
                    </div>

                    <div class="col-md-2">
                      <span
                        [ngClass]="
                          CBank.BeneficiaryName != '' ? 'required-lable' : ''
                        "
                        >Bank Name</span
                      >
                      <textarea
                        rows="1"
                        pattern="^[^\s].*"
                        name="BankName{{ i }}"
                        id="BankName{{ i }}"
                        [(ngModel)]="CBank.BankName"
                        #refBankName="ngModel"
                        placeholder="Bank Name"
                        class="form-control input-text-css"
                        [required]="CBank.BeneficiaryName != ''"
                        [disabled]="CBank.IsEdit == 0 || Bank_Verification"
                        [ngClass]="{
                          'is-invalid': ancf.submitted && refBankName.invalid,
                          'alert-warning':
                            CBank.BeneficiaryName != '' &&
                            refBankName.invalid &&
                            (refBankName.dirty ||
                              refBankName.touched ||
                              refBankName.untouched)
                        }"
                      ></textarea>
                    </div>

                    <div class="col-md-2">
                      <span
                        [ngClass]="
                          CBank.BeneficiaryName != '' ? 'required-lable' : ''
                        "
                        >Bank Branch</span
                      >
                      <textarea
                        rows="1"
                        name="BankBranch{{ i }}"
                        id="BankBranch{{ i }}"
                        [(ngModel)]="CBank.BankBranch"
                        #refBankBranch="ngModel"
                        placeholder="Bank Branch"
                        class="form-control input-text-css"
                        [required]="CBank.BeneficiaryName != ''"
                        [disabled]="CBank.IsEdit == 0 || Bank_Verification"
                        [ngClass]="{
                          'is-invalid': ancf.submitted && refBankBranch.invalid,
                          'alert-warning':
                            CBank.BeneficiaryName != '' &&
                            refBankBranch.invalid &&
                            (refBankBranch.dirty ||
                              refBankBranch.touched ||
                              refBankBranch.untouched)
                        }"
                      ></textarea>
                    </div>

                    <div class="col-md-2">
                      <span
                        [ngClass]="
                          CBank.BeneficiaryName != '' ? 'required-lable' : ''
                        "
                        >A/C Type</span
                      >
                      <select
                        name="BankAcType{{ i }}"
                        id="BankAcType{{ i }}"
                        [(ngModel)]="CBank.BankAcType"
                        #refBankAcType="ngModel"
                        class="form-control input-text-css"
                        [required]="CBank.BeneficiaryName != ''"
                        [disabled]="CBank.IsEdit == 0 || CBank.Id > 0"
                        [ngClass]="{
                          'is-invalid': ancf.submitted && refBankAcType.invalid,
                          'alert-warning':
                            CBank.BeneficiaryName != '' &&
                            refBankAcType.invalid &&
                            (refBankAcType.dirty ||
                              refBankAcType.touched ||
                              refBankAcType.untouched)
                        }"
                      >
                        <option value="">Select A/C Type</option>
                        <option value="SB">Saving A/C</option>
                        <option value="CC">Cash Credit A/C</option>
                        <option value="OD">Over Draft A/C</option>
                        <option value="CA">Current A/C</option>
                      </select>
                    </div>

                    <div class="col-md-2">
                      <span
                        [ngClass]="
                          CBank.BeneficiaryName != '' ? 'required-lable' : ''
                        "
                        >IFSC Code</span
                      >
                      <input
                        pattern="^[^\s].*"
                        type="text"
                        name="BankIFSC{{ i }}"
                        id="BankIFSC{{ i }}"
                        [(ngModel)]="CBank.BankIFSC"
                        #refBankIFSC="ngModel"
                        placeholder="IFSC Code"
                        class="form-control input-text-css"
                        maxlength="11"
                        minlength="11"
                        [required]="CBank.BeneficiaryName != ''"
                        [disabled]="CBank.IsEdit == 0 || Bank_Verification"
                        [ngClass]="{
                          'is-invalid': ancf.submitted && refBankIFSC.invalid,
                          'alert-warning':
                            CBank.BeneficiaryName != '' &&
                            refBankIFSC.invalid &&
                            (refBankIFSC.dirty ||
                              refBankIFSC.touched ||
                              refBankIFSC.untouched)
                        }"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <span>MICR Code</span>
                  <input
                    type="text"
                    name="BankMICRCode{{ i }}"
                    id="BankMICRCode{{ i }}"
                    [(ngModel)]="CBank.BankMICRCode"
                    #refBankMICRCode="ngModel"
                    placeholder="MICR Code"
                    class="form-control input-text-css"
                    [disabled]="CBank.IsEdit == 0 || Bank_Verification"
                    maxlength="9"
                    numbersOnly
                  />
                </div>
                <div class="col-md-1 pt-3">
                  <i
                    *ngIf="CBank.IsVerified == 1"
                    class="fa fa-check-circle"
                    style="color: green; font-size: 19px; margin-right: 2px"
                  ></i>

                  <button
                    type="button"
                    class="remove"
                    (click)="removeBankDetail(i)"
                    *ngIf="
                      i < BankDetails.length &&
                      BankDetails.length > 1 &&
                      CBank.IsEdit == 1 &&
                      BankDetails.length > 0
                    "
                  >
                    -
                  </button>
                  <button
                    type="button"
                    class="addmore"
                    (click)="addBankDetail()"
                    *ngIf="i == BankDetails.length - 1 && !Bank_Verification"
                  >
                    +
                  </button>
                </div>
              </div>
            </ng-container>

            <div class="row m-0">
              <div class="col-md-12">
                <hr />
              </div>
            </div>

            <div class="row col-md-12 m-0 mt-3 justify-content-end">
              <button
                type="button"
                (click)="onCloseCustomer()"
                class="mt-3 btn font-size-12 button-btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                id="btnSaveCust"
                #btnRegisterNewCustomer
                (click)="onRegisterNewCustomer()"
                class="ml-4 mt-3 btn font-size-12 button-btn"
                [class.spinner]="loading"
                [disabled]="!ancf.form.valid"
              >
                Save
              </button>

              <!-- if updating full information of customer -->
              <!-- <button type="button" *ngIf="isUpdateAddress" (click)="onUpdateCustomerAddress()" class="ml-4"
                style="background-color: #28a745;color: #FFFFFF;font-size: 12px;" mat-raised-button color="primary"
                [class.spinner]="loading" [disabled]="!ancf.form.valid">
                Update
              </button> -->
            </div>
          </form>
        </ng-conatainer>
      </div>
    </div>
  </div>
</div>

<!-- Search new Customer Popup -->
<div
  class="modal fade in"
  id="CustomerData"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Search Customer
        </h6>
        <button
          type="button"
          (click)="OnCloseSearch()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form #abcf="ngForm" (keyup.enter)="getSearchCusList()" novalidate>
          <div class="row m-0 align-items-end">
            <div class="col-md-3 p1">
              <span> Search By: </span>
              <select
                name="FilterSearchBy"
                id="FilterSearchBy"
                class="form-control input-text-css"
                #refFilterSearchBy="ngModel"
                [(ngModel)]="FilterSearchBy"
                required
                [ngClass]="{
                  'is-invalid': abcf.submitted && refFilterSearchBy.invalid,
                  'alert-warning':
                    refFilterSearchBy.invalid &&
                    (refFilterSearchBy.dirty ||
                      refFilterSearchBy.touched ||
                      refFilterSearchBy.untouched)
                }"
              >
                <option value="">Select</option>
                <option value="Name">Customer Name</option>
                <option value="Phone">Customer Phone</option>
                <option value="Pan">PAN Card</option>
                <option value="Passport">Passport Copy</option>
                <option value="VoterId">Voter ID Card</option>
                <option value="DL">Driving License</option>
                <option value="Aadhar">Aadhaar Card</option>
              </select>
            </div>
            <div class="col-md-3">
              <span> Value: </span>
              <input
                name="FilterSearchValue"
                id="FilterSearchValue"
                placeholder="Value"
                [(ngModel)]="FilterSearchValue"
                #refFilterSearchValue="ngModel"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': abcf.submitted && refFilterSearchValue.invalid,
                  'alert-warning':
                    refFilterSearchValue.invalid &&
                    (refFilterSearchValue.dirty ||
                      refFilterSearchValue.touched ||
                      refFilterSearchValue.untouched)
                }"
              />
            </div>
            <div class="col-md-3">
              <button
                class="btn btn-success font-size-12"
                (click)="getSearchCusList()"
                [disabled]="!abcf.form.valid"
              >
                Search
              </button>
            </div>
          </div>
        </form>

        <div class="row m-0">
          <div class="col-md-12">
            <hr />
          </div>
        </div>

        <div class="row m-0" *ngIf="dataSource?.data.length > 0">
          <div class="col-md-12">
            <div class="table-responsive mt-1">
              <mat-table
                [dataSource]="dataSource"
                id="exportReport"
                style="height: 330px; max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="CustomerId">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell"
                    style="max-width: 50px; max-height: 5px"
                  >
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="CustomertName">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Customer</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.CustomertName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="RelationName">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Relation Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.RelationName }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Customer_Gender">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                    >Gender</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 50px"
                    >{{ row.Customer_Gender }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="GenderAge">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 50px"
                    *matHeaderCellDef
                    >Age</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 50px"
                    >{{ row.GenderAge }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="PhoneNo">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 150px; justify-content: center"
                    *matHeaderCellDef
                  >
                    Phone No
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 150px; justify-content: center"
                  >
                    <div *ngIf="currentUser.IsMaskingMobile">
                      {{ row.PhoneNo | mask }}
                    </div>

                    <div *ngIf="!currentUser.IsMaskingMobile">
                      {{ row.PhoneNo }}
                    </div>

                    <i
                      *ngIf="row.Customer_PhoneNo_IsVerified == true"
                      class="fa fa-check-circle ml-2"
                      style="color: green"
                    ></i>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Present_Address">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 150px; justify-content: center"
                    *matHeaderCellDef
                  >
                    Present Address
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 150px; justify-content: center"
                  >
                    {{ row.Present_Address }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Select">
                  <mat-header-cell
                    class="grid-header"
                    style="max-width: 100px; justify-content: center"
                    *matHeaderCellDef
                  >
                    Select
                  </mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="grid-cell"
                    style="max-width: 100px; justify-content: center"
                  >
                    <input
                      type="checkbox"
                      [value]="row.CustomerId"
                      [checked]="customerModel.CustomerId == row.CustomerId"
                      (change)="onCustomerChange($event, row)"
                    />
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="insidetable"></mat-header-row>
                <mat-row *matRowDef="let row; columns: insidetable"></mat-row>
              </mat-table>
            </div>
          </div>
        </div>

        <div class="row m-0" [hidden]="!dataSource">
          <div class="col-md-12">
            <hr />
          </div>
        </div>

        <div class="row m-0" [hidden]="!dataSource">
          <div class="col-md-12 text-right">
            <button
              type="button"
              (click)="onSearchExistingCustomer()"
              class="mt-3 btn font-size-12 button-btn"
              [class.spinner]="loading"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="Reponse_Verified_Detail"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Document Detail
        </h6>
        <button
          type="button"
          (click)="OnCloseReponse_Verified_Detail()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 col-md-12 p-1">
          <div
            class="col-md-12"
            *ngIf="
              verify_DocId == 1 || verify_DocId == 29 || verify_DocId == 28
            "
          >
            <h6 class="fs-12">Document Number</h6>
            <p class="lead fs-12">{{ APIResponse.DoucumentNumber }}</p>
          </div>
          <div class="col-md-3" *ngIf="verify_DocId == 1">
            <h6 class="fs-12">Full Name</h6>
            <p class="lead fs-12">{{ APIResponse.Full_name }}</p>
          </div>
          <div class="col-md-4" *ngIf="verify_DocId == 6">
            <h6 class="fs-12"></h6>
            <p class="lead fs-12">
              <img
                src="{{ 'data:image/jpg;base64,' + APIResponse.profile_image }}"
                class="rounded-circle"
                style="width: 122px"
                alt="user image"
              />
            </p>
          </div>
          <div class="col-md-8 margin-user-detail" *ngIf="verify_DocId == 6">
            <div class="row m-0 col-md-12 p-1">
              <div class="col-md-5">
                <h6 class="fs-12">Document Number</h6>
              </div>
              <div class="col-md-7">
                <p class="lead fs-12">{{ APIResponse.DoucumentNumber }}</p>
              </div>
            </div>
            <div class="row m-0 col-md-12 p-1">
              <div class="col-md-5">
                <h6 class="fs-12">Full Name</h6>
              </div>
              <div class="col-md-7">
                <p class="lead fs-12">{{ APIResponse.Full_name }}</p>
              </div>
            </div>
            <div class="row m-0 col-md-12 p-1">
              <div class="col-md-5">
                <h6 class="fs-12">C/O</h6>
              </div>
              <div class="col-md-7">
                <p class="lead fs-12">{{ APIResponse.care_of }}</p>
              </div>
            </div>
          </div>
          <div
            class="col-md-4"
            *ngIf="verify_DocId == 4 || verify_DocId == 6 || verify_DocId == 1"
          >
            <h6 class="fs-12">Status</h6>
            <p class="lead fs-12">
              {{
                APIResponse.message_code == "success"
                  ? "Verified"
                  : "NOT-Verified"
              }}
            </p>
          </div>
          <div class="col-md-4" *ngIf="verify_DocId == 4">
            <h6 class="fs-12">Document Number</h6>
            <p class="lead fs-12">{{ APIResponse.DoucumentNumber }}</p>
          </div>
          <div class="col-md-4" *ngIf="verify_DocId == 4">
            <h6 class="fs-12">Full Name</h6>
            <p class="lead fs-12">{{ APIResponse.Full_name }}</p>
          </div>
          <div class="col-md-4" *ngIf="verify_DocId == 4">
            <h6 class="fs-12">C/O</h6>
            <p class="lead fs-12">{{ APIResponse.care_of }}</p>
          </div>
          <div class="col-md-3 margin" *ngIf="verify_DocId == 6">
            <h6 class="fs-12">DOB</h6>
            <p class="lead fs-12">{{ APIResponse.dob }}</p>
          </div>
          <div class="col-md-3" *ngIf="verify_DocId == 1">
            <h6 class="fs-12">category</h6>
            <p class="lead fs-12">{{ APIResponse.category }}</p>
          </div>

          <div class="col-md-3" *ngIf="verify_DocId == 6 || verify_DocId == 4">
            <h6 class="fs-12">Gender</h6>
            <p class="lead fs-12">{{ APIResponse.gender }}</p>
          </div>
          <div class="col-md-3" *ngIf="verify_DocId == 4">
            <h6 class="fs-12">Age</h6>
            <p class="lead fs-12">{{ APIResponse.age }}</p>
          </div>

          <div class="col-md-12" *ngIf="verify_DocId == 6">
            <h6 class="fs-12">Address</h6>
            <p class="lead fs-12">
              {{ APIResponse.house }},{{ APIResponse.street }},
              {{ APIResponse.po }},{{ APIResponse.loc }},{{
                APIResponse.vtc
              }},{{ APIResponse.subdist }},
              {{ APIResponse.landmark }}
            </p>
          </div>
          <div class="col-md-4" *ngIf="verify_DocId == 6">
            <h6 class="fs-12">Pincode</h6>
            <p class="lead fs-12">{{ APIResponse.zip }}</p>
          </div>
          <div class="col-md-3 margin" *ngIf="verify_DocId == 4">
            <h6 class="fs-12">Area</h6>
            <p class="lead fs-12">{{ APIResponse.area }}</p>
          </div>
          <div class="col-md-3 margin" *ngIf="verify_DocId == 6">
            <h6 class="fs-12">District</h6>
            <p class="lead fs-12">{{ APIResponse.dist }}</p>
          </div>
          <div class="col-md-3" *ngIf="verify_DocId == 6 || verify_DocId == 4">
            <h6 class="fs-12">state</h6>
            <p class="lead fs-12">{{ APIResponse.state }}</p>
          </div>
        </div>

        <div class="row m-0 col-md-12 p-1" *ngIf="verify_DocId == 29">
          <div class="col-md-3" *ngIf="verify_DocId == 29">
            <h6 class="fs-12">Status</h6>
            <p class="lead fs-12">
              {{
                APIResponse.message_code == "success"
                  ? "Verified"
                  : "NOT-Verified"
              }}
            </p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">PAN Number</h6>
            <p class="lead fs-12">{{ APIResponse.pan_number }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Orgnization Name</h6>
            <p class="lead fs-12">{{ APIResponse.business_name }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Signing Autority</h6>
            <p class="lead fs-12">{{ APIResponse.SigningAutority }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Firm Type</h6>
            <p class="lead fs-12">{{ APIResponse.FirmType }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Gst Registration Date</h6>
            <p class="lead fs-12">{{ APIResponse.Gst_Registration_Date }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Status</h6>
            <p class="lead fs-12">{{ APIResponse.gst_status }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Nature Bus Activities</h6>
            <p class="lead fs-12">{{ APIResponse.nature_bus_activities }}</p>
          </div>
          <div class="col-md-12">
            <h6 class="fs-12">Address</h6>
            <p class="lead fs-12">{{ APIResponse.address }}</p>
          </div>
        </div>

        <div class="row m-0 col-md-12 p-1" *ngIf="verify_DocId == 28">
          <div class="col-md-3">
            <h6 class="fs-12">Status</h6>
            <p class="lead fs-12">
              {{
                APIResponse.message_code == "success"
                  ? "Verified"
                  : "NOT-Verified"
              }}
            </p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Company Name</h6>
            <p class="lead fs-12">{{ APIResponse.company_name }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Registration Number</h6>
            <p class="lead fs-12">{{ APIResponse.registration_number }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Company Category</h6>
            <p class="lead fs-12">{{ APIResponse.company_category }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Company Sub Category</h6>
            <p class="lead fs-12">{{ APIResponse.company_sub_category }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Class Of Company</h6>
            <p class="lead fs-12">{{ APIResponse.class_of_company }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Company Status</h6>
            <p class="lead fs-12">{{ APIResponse.company_status }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Authorized Capital</h6>
            <p class="lead fs-12">{{ APIResponse.authorized_capital }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Paid Up Capital</h6>
            <p class="lead fs-12">{{ APIResponse.paid_up_capital }}</p>
          </div>
          <div class="col-md-3">
            <h6 class="fs-12">Date Of Incorporation</h6>
            <p class="lead fs-12">{{ APIResponse.date_of_incorporation }}</p>
          </div>
          <div class="col-md-12">
            <h6 class="fs-12">Address</h6>
            <p class="lead fs-12">{{ APIResponse.address }}</p>
          </div>
        </div>
        <div
          class="row m-0 mb-2 col-md-12 align-items-center justify-content-between"
          [hidden]="!Directors"
          *ngIf="verify_DocId == 28"
        >
          <h1 class="fs-12 m-0">Directors Details</h1>
        </div>
        <div class="row m-0" [hidden]="!Directors" *ngIf="verify_DocId == 28">
          <div class="col-md-12">
            <div class="table-responsive">
              <mat-table
                [dataSource]="Directors"
                matSort
                matTableExporter
                #exporter="matTableExporter"
                style="max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="din_number">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Din Number</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.din_number }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="director_name">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Director Name</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.director_name }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="start_date">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Start Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.start_date }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="end_date">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >End Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.end_date }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="surrendered_din">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Surrendered Din
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.surrendered_din }}
                  </mat-cell>
                </ng-container>
                <mat-header-row
                  *matHeaderRowDef="displayedDirectorsColumns; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="
                    let row;
                    let i = index;
                    columns: displayedDirectorsColumns
                  "
                ></mat-row>
              </mat-table>
            </div>
          </div>
        </div>

        <div
          class="row m-0 mb-2 col-md-12 align-items-center justify-content-between"
          [hidden]="!Charges"
          *ngIf="verify_DocId == 28"
          style="margin-top: 25px !important; font-weight: bold"
        >
          <h1 class="fs-12 m-0">Charges Details</h1>
        </div>
        <div class="row m-0" [hidden]="!Charges" *ngIf="verify_DocId == 28">
          <div class="col-md-12">
            <div class="table-responsive">
              <mat-table
                [dataSource]="Charges"
                matSort
                matTableExporter
                #exporter="matTableExporter"
                style="max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="assets_under_charge">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Assets Under Charge
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.assets_under_charge }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="charge_amount">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Charge Amount</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.charge_amount }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="date_of_creation">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Date Of Creation
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.date_of_creation }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="date_of_modification">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                  >
                    Date Of Modification
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.date_of_modification }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                  <mat-header-cell
                    mat-sort-header
                    class="grid-header"
                    *matHeaderCellDef
                    >Status</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">
                    {{ row.status }}
                  </mat-cell>
                </ng-container>
                <mat-header-row
                  *matHeaderRowDef="displayedChargesColumns; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="
                    let row;
                    let i = index;
                    columns: displayedChargesColumns
                  "
                ></mat-row>
              </mat-table>
            </div>
          </div>
        </div>

        <div
          class="row col-md-12 m-0 mt-1 justify-content-end"
          *ngIf="verify_DocId == 6 || verify_DocId == 29"
        >
          <button
            type="button"
            (click)="OnCloseReponse_Verified_Detail()"
            class="mt-3 btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onGetData()"
            class="ml-4 font-size-12 button-btn"
            [class.spinner]="loading"
          >
            Apply Data
          </button>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="OTPModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          OTP Verification
        </h6>
        <button
          type="button"
          (click)="onCloseOTP()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form #cotpM="ngForm" novalidate class="col-md-12 row m-0 p-0">
          <div class="row m-0">
            <div class="col-md-12">
              <span class="required-lable"> Enter OTP</span>
              <input
                type="text"
                #refOTPM="ngModel"
                numbersOnly
                maxlength="5"
                minlength="5"
                [(ngModel)]="OTP"
                placeholder="Enter OTP"
                required
                name="OTP"
                id="OTPM"
                class="form-control input-text-css"
                [ngClass]="{
                  'is-invalid': cotpM.submitted && refOTPM.invalid,
                  'alert-warning':
                    refOTPM.invalid &&
                    (refOTPM.dirty || refOTPM.touched || refOTPM.untouched)
                }"
              />
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onCloseOTP()"
              class="mt-3 btn font-size-12 button-btn"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              (click)="onSAVEOTP()"
              class="ml-4 mt-3 btn font-size-12 button-btn"
              [class.spinner]="loading"
              [disabled]="!cotpM.form.valid"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="KYCOTPModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          OTP Verification
        </h6>
        <button
          type="button"
          (click)="onCloseKYCOTP()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #cotp="ngForm"
          (ngSubmit)="onSAVEKYCOTP()"
          novalidate
          class="col-md-12 row m-0 p-0"
        >
          <div class="row m-0">
            <div class="col-md-12">
              <span class="required-lable"> Enter OTP</span>
              <input
                type="text"
                #refOTP="ngModel"
                numbersOnly
                maxlength="6"
                minlength="6"
                [(ngModel)]="OTP"
                placeholder="Enter OTP"
                required
                [ngClass]="{
                  'is-invalid': cotp.submitted && refOTP.invalid,
                  'alert-warning':
                    refOTP.invalid &&
                    (refOTP.dirty || refOTP.touched || refOTP.untouched)
                }"
                name="OTP"
                id="OTP"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onSAVEKYCOTP()"
              class="ml-4 mt-3 btn font-size-12 button-btn"
              [class.spinner]="loading"
              [disabled]="!cotp.form.valid"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  id="modalOverlay"
  class="modal fade in"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  style="font-size: small"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow-y: auto"
>
  <div class="modal-dialog modal-dialog-centered" style="width: 315px">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle>
        <div class="modal-content">
          <div class="modal-header" style="padding: 6px">
            <h5 class="modal-title" style="font-size: small">
              Customer Information
            </h5>
            <button
              type="button"
              (click)="onClosemodalOverlay()"
              class="close"
              data-dismiss="modal"
            >
              &times;
            </button>
          </div>
          <div class="modal-body" style="padding: 13px">
            <div class="card">
              <img
                src="{{ 'data:image/jpg;base64,' + APIResponse.profile_image }}"
                *ngIf="APIResponse.KYC_DocId == 6 || APIResponse.KYC_DocId == 5"
                alt="Customer Image"
                class="mt-2"
                style="
                  border-radius: 50%;
                  width: 100px;
                  height: 100px;
                  margin: auto;
                "
              />
              <div
                class="card-body"
                *ngIf="APIResponse.KYC_DocId == 6 || APIResponse.KYC_DocId == 1"
              >
                <div class="text-center" style="font-weight: bolder">
                  {{ APIResponse.DoucumentNumber }}
                </div>
                <h6 class="card-title">Name:</h6>
                <p class="card-text">{{ APIResponse.Full_name }}</p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  Relation
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.care_of | slice : 0 : 4 }}
                  {{ APIResponse.care_of | slice : 4 }}
                </p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  DOB:
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.dob }}
                </p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  Gender:
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.gender }}
                </p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  Address:
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.house }},{{ APIResponse.street }},
                  {{ APIResponse.po }},{{ APIResponse.loc }},{{
                    APIResponse.vtc
                  }},{{ APIResponse.subdist }},
                  {{ APIResponse.landmark }}
                </p>
                <br />
                <h6 class="card-title" *ngIf="APIResponse.KYC_DocId == 6">
                  Pin Code:
                </h6>
                <p class="card-text" *ngIf="APIResponse.KYC_DocId == 6">
                  {{ APIResponse.zip }}
                </p>
                <br />
                <h6
                  class="card-title"
                  *ngIf="APIResponse.KYC_DocId == 6 && Verify_PAN_No"
                >
                  Verify PAN No
                </h6>
                <p
                  class="card-text"
                  *ngIf="APIResponse.KYC_DocId == 6 && Verify_PAN_No"
                >
                  {{ Verify_PAN_No }}
                </p>

                <br />
              </div>
              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 4">
                <div class="text-center" style="font-weight: bolder">
                  {{ APIResponse.DoucumentNumber }}
                </div>
                <h6 class="card-title">Name:</h6>
                <p class="card-text">{{ APIResponse.Full_name }}</p>
                <br />
                <h6 class="card-title">Relation:</h6>
                <p class="card-text">
                  {{ APIResponse.relation_type + " " }}
                  {{ APIResponse.relation_name }}
                </p>
                <br />

                <h6 class="card-title">age:</h6>
                <p class="card-text">{{ APIResponse.age }}</p>
                <br />
                <h6 class="card-title">Gender:</h6>
                <p class="card-text">
                  {{ APIResponse.gender == "F" ? "Female" : "Male" }}
                </p>
                <br />
                <h6 class="card-title">Area:</h6>
                <p class="card-text">
                  {{
                    APIResponse.area +
                      " " +
                      APIResponse.dist +
                      " " +
                      APIResponse.state
                  }}
                </p>
                <br />
              </div>
              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 29">
                <h6 class="card-title">Status:</h6>
                <p class="card-text">
                  {{
                    APIResponse.message_code == "success"
                      ? "Verified"
                      : "NOT-Verified"
                  }}
                </p>
                <br />

                <h6 class="card-title">PAN Number:</h6>
                <p class="card-text">{{ APIResponse.pan_number }}</p>
                <br />

                <h6 class="card-title">Orgnization Name:</h6>
                <p class="card-text">{{ APIResponse.business_name }}</p>
                <br />

                <h6 class="card-title">Signing Autority:</h6>
                <p class="card-text">{{ APIResponse.SigningAutority }}</p>
                <br />

                <h6 class="card-title">Firm Type:</h6>
                <p class="card-text">{{ APIResponse.FirmType }}</p>
                <br />

                <h6 class="card-title">Gst Registration Date:</h6>
                <p class="card-text">{{ APIResponse.Gst_Registration_Date }}</p>
                <br />
                <h6 class="card-title">Status:</h6>
                <p class="card-text">{{ APIResponse.gst_status }}</p>
                <br />

                <h6 class="card-title">Nature Bus Activities:</h6>
                <p class="card-text">{{ APIResponse.Gst_Registration_Date }}</p>
                <br />

                <h6 class="card-title">Address</h6>
                <p class="card-text">{{ APIResponse.address }}</p>

                <br />
              </div>
              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 28">
                <h6 class="card-title">Status:</h6>
                <p class="card-text">
                  {{
                    APIResponse.message_code == "success"
                      ? "Verified"
                      : "NOT-Verified"
                  }}
                </p>
                <br />
                <h6 class="card-title">Company Name</h6>
                <p class="card-text">{{ APIResponse.company_name }}</p>
                <br />
                <h6 class="card-title">Registration Number</h6>
                <p class="card-text">{{ APIResponse.registration_number }}</p>
                <br />
                <h6 class="card-title">Company Category</h6>
                <p class="card-text">{{ APIResponse.company_category }}</p>
                <br />
                <h6 class="card-title">Company Sub Category</h6>
                <p class="card-text">{{ APIResponse.company_sub_category }}</p>
                <br />
                <h6 class="card-title">Class Of Company</h6>
                <p class="card-text">{{ APIResponse.class_of_company }}</p>
                <br />
                <h6 class="card-title">Company Status</h6>
                <p class="card-text">{{ APIResponse.company_status }}</p>
                <br />
                <h6 class="card-title">Authorized Capital</h6>
                <p class="card-text">{{ APIResponse.authorized_capital }}</p>
                <br />
                <h6 class="card-title">Paid Up Capital</h6>
                <p class="card-text">{{ APIResponse.paid_up_capital }}</p>
                <br />
                <h6 class="card-title">Date Of Incorporation</h6>
                <p class="card-text">{{ APIResponse.date_of_incorporation }}</p>
                <br />
                <h6 class="card-title">Address</h6>
                <p class="card-text">{{ APIResponse.address }}</p>
                <br />

                <div
                  class="row m-0 mb-2 col-md-12 align-items-center justify-content-between"
                  [hidden]="!Directors"
                  *ngIf="verify_DocId == 28"
                >
                  <h1 class="fs-12 m-0">Directors Details</h1>
                </div>
                <div
                  class="row m-0"
                  [hidden]="!Directors"
                  *ngIf="verify_DocId == 28"
                >
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <mat-table
                        [dataSource]="Directors"
                        matSort
                        matTableExporter
                        #exporter="matTableExporter"
                        style="max-width: 100%; overflow: auto"
                      >
                        <ng-container matColumnDef="din_number">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Din Number</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.din_number }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="director_name">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Director Name</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.director_name }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="start_date">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Start Date</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.start_date }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="end_date">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >End Date</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.end_date }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="surrendered_din">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                          >
                            Surrendered Din
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.surrendered_din }}
                          </mat-cell>
                        </ng-container>
                        <mat-header-row
                          *matHeaderRowDef="
                            displayedDirectorsColumns;
                            sticky: true
                          "
                        ></mat-header-row>
                        <mat-row
                          *matRowDef="
                            let row;
                            let i = index;
                            columns: displayedDirectorsColumns
                          "
                        ></mat-row>
                      </mat-table>
                    </div>
                  </div>
                </div>
                <div
                  class="row m-0 mb-2 col-md-12 align-items-center justify-content-between"
                  [hidden]="!Charges"
                  *ngIf="verify_DocId == 28"
                  style="margin-top: 25px !important; font-weight: bold"
                >
                  <h1 class="fs-12 m-0">Charges Details</h1>
                </div>
                <div
                  class="row m-0"
                  [hidden]="!Charges"
                  *ngIf="verify_DocId == 28"
                >
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <mat-table
                        [dataSource]="Charges"
                        matSort
                        matTableExporter
                        #exporter="matTableExporter"
                        style="max-width: 100%; overflow: auto"
                      >
                        <ng-container matColumnDef="assets_under_charge">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                          >
                            Assets Under Charge
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.assets_under_charge }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="charge_amount">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Charge Amount</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.charge_amount }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="date_of_creation">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                          >
                            Date Of Creation
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.date_of_creation }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="date_of_modification">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                          >
                            Date Of Modification
                          </mat-header-cell>
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.date_of_modification }}
                          </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="status">
                          <mat-header-cell
                            mat-sort-header
                            class="grid-header"
                            *matHeaderCellDef
                            >Status</mat-header-cell
                          >
                          <mat-cell *matCellDef="let row" class="grid-cell">
                            {{ row.status }}
                          </mat-cell>
                        </ng-container>
                        <mat-header-row
                          *matHeaderRowDef="
                            displayedChargesColumns;
                            sticky: true
                          "
                        ></mat-header-row>
                        <mat-row
                          *matRowDef="
                            let row;
                            let i = index;
                            columns: displayedChargesColumns
                          "
                        ></mat-row>
                      </mat-table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 5">
                <div class="text-center" style="font-weight: bolder">
                  {{ APIResponse.DoucumentNumber }}
                </div>
                <h6 class="card-title">Name:</h6>
                <p class="card-text">{{ APIResponse.Full_name }}</p>
                <br />
                <b class="card-title">permanent address:</b>

                <p class="card-text">{{ APIResponse.address }}</p>
                <br />
                <h6 class="card-title">DOB:</h6>
                <p class="card-text">{{ APIResponse.dob }}</p>
                <br />
                <h6 class="card-title">Issue Date:</h6>
                <p class="card-text">{{ APIResponse.doi }}</p>
                <br />
                <h6 class="card-title">Expiry Date:</h6>
                <p class="card-text">{{ APIResponse.doe }}</p>
                <br />
                <h6 class="card-title">Issued By:</h6>
                <p class="card-text">{{ APIResponse.ola_name }}</p>
                <br />

                <h6 class="card-title">vehicle classes:</h6>
                <p class="card-text">{{ APIResponse.vehicle_classes }}</p>
                <br />
              </div>
              <div class="card-body" *ngIf="APIResponse.KYC_DocId == 2">
                <div class="text-center" style="font-weight: bolder">
                  {{ APIResponse.DoucumentNumber }}
                </div>
                <h6 class="card-title">Name:</h6>
                <p class="card-text">{{ APIResponse.Full_name }}</p>
                <br />
                <h6 class="card-title">Address:</h6>
                <p class="card-text">{{ APIResponse.address }}</p>
                <br />

                <h6 class="card-title">Mobile:</h6>
                <p class="card-text">{{ APIResponse.mobile }}</p>
                <br />
                <h6 class="card-title">State:</h6>
                <p class="card-text">{{ APIResponse.state }}</p>
                <br />
                <h6 class="card-title">Operator Code:</h6>
                <p class="card-text">{{ APIResponse.operator_code }}</p>
                <br />
                <h6 class="card-title">Status:</h6>
                <p class="card-text">
                  {{
                    APIResponse.message_code == "success"
                      ? "Verified"
                      : "NOT-Verified"
                  }}
                </p>
                <br />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              data-dismiss="modal"
              *ngIf="APIResponse.KYC_DocId == 6 || APIResponse.KYC_DocId == 29"
              (click)="onGetData()"
              class="ml-4 font-size-12 button-btn"
              [class.spinner]="loading"
            >
              Apply Data
            </button>
            <button
              type="button"
              (click)="onClosemodalOverlay()"
              data-dismiss="modal"
              class="ml-4 font-size-12 button-btn"
              color="secondary"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="duplicateData{{ index }}"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Customer Details
        </h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0" *ngIf="Duplicate.length > 0">
          <div class="row m-0">
            <div class="col-md-12 p-0">
              <div class="alert alert-danger" *ngIf="!NotUpdate">
                Customer already exist with this entered {{ duplicateType }}. Do
                you want to update this customer detail?
              </div>
              <div class="alert alert-danger" *ngIf="NotUpdate">
                Customer already exist with this entered
                {{ duplicateType }}.Please add existing customer.
              </div>
            </div>
          </div>

          <table class="table table-bordered">
            <tr>
              <th style="width: 130px">
                <h6>Name</h6>
              </th>
              <td colspan="2">
                <h6>
                  {{ duplicateData.customer.Customer_FirstName }}
                  {{ duplicateData.customer.Customer_LastName }}
                </h6>
              </td>
              <td rowspan="2">
                <div class="profileImg">
                  <img
                    src="{{ customerProfileBaseUrl }}{{
                      duplicateData.customer.CustomerId
                    }}/{{ duplicateData.customer.Customer_ProfilePic }}"
                    alt=""
                    onerror="this.onerror=null;this.src='assets/images/default-user.png'"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <h6>Relation Name</h6>
              </th>
              <td colspan="2">
                <h6>
                  {{ duplicateData.customer.Customer_Relation_Type }}
                  {{ duplicateData.customer.Customer_Relation_FirstName }}
                  {{ duplicateData.customer.Customer_Relation_LastName }}
                </h6>
              </td>
            </tr>

            <tr>
              <th>
                <h6>Gender</h6>
              </th>
              <td>
                <h6>
                  {{
                    duplicateData.customer.Customer_Gender == "M"
                      ? "Male"
                      : "Female"
                  }}
                </h6>
              </td>
              <th>
                <h6>DOB</h6>
              </th>
              <td>
                <h6>
                  {{
                    duplicateData.customer.Customer_DOB | date : "dd/MM/yyyy"
                  }}
                </h6>
              </td>
            </tr>
            <tr>
              <th>
                <h6>Alternate Number</h6>
              </th>
              <td>
                <h6>{{ duplicateData.customer.Customer_PhoneNo1 }}</h6>
              </td>
              <th>
                <h6>WhatsApp Number</h6>
              </th>
              <td>
                <h6>{{ duplicateData.customer.Customer_WhatsAppNo }}</h6>
              </td>
            </tr>
            <tr>
              <th>
                <h6>E-mail</h6>
              </th>
              <td colspan="3">
                <h6>{{ duplicateData.customer.Customer_Email }}</h6>
              </td>
            </tr>
            <tr *ngIf="duplicateData.address[0].AddressType == 'Present'">
              <th>
                <h6>Present Address</h6>
              </th>
              <td colspan="3">
                <h6>
                  {{ duplicateData.address[0].Address }}
                  {{ duplicateData.address[0].Landmark }},{{
                    duplicateData.address[0].Tehsil_Name
                  }},{{ duplicateData.address[0].District_Name }},{{
                    duplicateData.address[0].State_Name
                  }}- {{ duplicateData.address[0].Pincode }}
                </h6>
              </td>
            </tr>
            <tr *ngIf="duplicateData.address[1].AddressType == 'Permanent'">
              <th>
                <h6>Permanent Address</h6>
              </th>
              <td colspan="3">
                <h6>
                  {{ duplicateData.address[1].Address
                  }}{{ duplicateData.address[1].Landmark }},{{
                    duplicateData.address[1].Tehsil_Name
                  }},{{ duplicateData.address[1].District_Name }},{{
                    duplicateData.address[1].State_Name
                  }}-{{ duplicateData.address[1].Pincode }}
                </h6>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          (click)="onNoClick()"
          *ngIf="!NotUpdate"
          class="btn btn-light font-size-12"
        >
          No
        </button>
        <button
          type="button"
          (click)="onYesClick()"
          *ngIf="!NotUpdate"
          class="ml-4 font-size-12 button-btn"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="duplicateFirmData{{ index }}"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Customer Details
        </h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row m-0 mt-3" *ngIf="DuplicateFirm.length > 0">
          <div class="row m-0 col-md-12">
            <div
              class="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              Customer already exist with this entered {{ duplicateType }}. Are
              you want to update this customer details?
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="row m-0 col-md-12">
            <div class="profileImg" *ngIf="duplicateFirmData">
              <img
                src="{{ customerProfileBaseUrl }}{{
                  duplicateFirmData.customer.CustomerId
                }}/{{ duplicateFirmData.customer.Customer_ProfilePic }}"
                alt=""
                onerror="this.onerror=null;this.src='assets/images/default-user.png';"
              />
            </div>
          </div>
          <div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">First Name</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12" *ngIf="duplicateFirmData">
                {{ duplicateFirmData.customer.Customer_FirstName }}
              </p>
            </div>
          </div>
          <!--<div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">Last Name</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12">{{duplicateFirmData.customer.Customer_LastName}}</p>
            </div>
          </div>-->
          <div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">Father/Spouse Name</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12" *ngIf="duplicateFirmData">
                {{ duplicateFirmData.customer.Customer_FatherName }}
              </p>
            </div>
          </div>
          <div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">Gender</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12" *ngIf="duplicateFirmData">
                {{ duplicateFirmData.customer.Customer_Gender }}
              </p>
            </div>
          </div>
          <div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">DOB</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12" *ngIf="duplicateFirmData">
                {{ duplicateFirmData.customer.Customer_DOB }}
              </p>
            </div>
          </div>
          <div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">Primary Number</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12" *ngIf="duplicateFirmData">
                {{ duplicateFirmData.customer.Customer_PhoneNo }}
              </p>
            </div>
          </div>
          <div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">Alternate Number</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12" *ngIf="duplicateFirmData">
                {{ duplicateFirmData.customer.Customer_PhoneNo1 }}
              </p>
            </div>
          </div>
          <div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">WhatsApp Number</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12" *ngIf="duplicateFirmData">
                {{ duplicateFirmData.customer.Customer_WhatsAppNo }}
              </p>
            </div>
          </div>
          <div class="row m-0 col-md-12">
            <div class="col-md-4">
              <h6 class="fs-12">Email</h6>
            </div>
            <div class="col-md-8">
              <p class="lead fs-12" *ngIf="duplicateFirmData">
                {{ duplicateFirmData.customer.Customer_Email }}
              </p>
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-3 justify-content-center">
            <button
              type="button"
              (click)="onNoFirmClick()"
              class="btn btn-light font-size-12"
            >
              No
            </button>
            <button
              type="button"
              (click)="onYesFirmClick()"
              class="ml-4 font-size-12 button-btn"
            >
              yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="DLModelVerified"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Driving License Verification
        </h6>
        <button
          type="button"
          (click)="onCloseDLModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #dlf="ngForm"
          (ngSubmit)="onVerifiedDrivingLicence()"
          novalidate
          class="col-md-12 row m-0 p-0"
        >
          <div class="row m-0">
            <div class="col-md-12">
              <span class="required-lable"> Enter DOB</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker5"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="DL_DOB"
                  id="DL_DOB"
                  #refDL_DOB="ngModel"
                  required
                  [ngClass]="{
                    'is-invalid': dlf.submitted && refDL_DOB.invalid,
                    'alert-warning':
                      refDL_DOB.invalid &&
                      (refDL_DOB.dirty ||
                        refDL_DOB.touched ||
                        refDL_DOB.untouched)
                  }"
                  [(ngModel)]="DL_DOB"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker5"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker5></mat-datepicker>
              </div>
              <!-- <input
                name="DL_DOB"
                id="DL_DOB"
                #refDL_DOB="ngModel"
                required
                [ngClass]="{
                  'is-invalid': dlf.submitted && refDL_DOB.invalid,
                  'alert-warning':
                    refDL_DOB.invalid &&
                    (refDL_DOB.dirty ||
                      refDL_DOB.touched ||
                      refDL_DOB.untouched)
                }"
                [(ngModel)]="DL_DOB"
                value="{{ DL_DOB | date : 'dd/MM/yyyy' }}"
                placeholder="DD/MM/YYYY"
                class="form-control input-text-css"
                [(bsValue)]="DL_DOB"
                bsDatepicker
              /> -->
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onVerifiedDrivingLicence()"
              class="ml-4 font-size-12 button-btn"
              [class.spinner]="loading"
              [disabled]="!dlf.form.valid"
            >
              Verify
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade in"
  id="ElectricityModelVerified"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Electricity Verification
        </h6>
        <button
          type="button"
          (click)="onCloseElectricityModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #elf="ngForm"
          (ngSubmit)="onVerifiedElectricity()"
          novalidate
          class="col-md-12 row m-0 p-0"
        >
          <div class="row m-0">
            <div class="col-md-12">
              <span class="required-lable">Select State</span>
              <select
                name="ELState"
                id="ELState"
                [(ngModel)]="ELState"
                #refELState="ngModel"
                class="form-control input-text-css"
                required
                (change)="getElectricityOpCodelist()"
                [ngClass]="{
                  'is-invalid': elf.submitted && refELState.invalid,
                  'alert-warning':
                    refELState.invalid &&
                    (refELState.dirty ||
                      refELState.touched ||
                      refELState.untouched)
                }"
              >
                <option value="">Select State</option>
                <option
                  [value]="cus.state"
                  *ngFor="let cus of ElectricityStatelist"
                >
                  {{ cus.state }}
                </option>
              </select>
            </div>
            <div class="col-md-12">
              <span class="required-lable">Select Operator Code</span>
              <select
                name="OperatorCode"
                id="OperatorCode"
                [(ngModel)]="OperatorCode"
                #refOperatorCode="ngModel"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': elf.submitted && refOperatorCode.invalid,
                  'alert-warning':
                    refOperatorCode.invalid &&
                    (refOperatorCode.dirty ||
                      refOperatorCode.touched ||
                      refOperatorCode.untouched)
                }"
              >
                <option value="">Select Operator Code</option>
                <option
                  [value]="cus.Operator_code"
                  *ngFor="let cus of ElectricityOpCodelist"
                >
                  {{ cus.Operator_code }}
                </option>
              </select>
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onVerifiedElectricity()"
              class="ml-4 font-size-12 button-btn"
              [class.spinner]="loading"
              [disabled]="!elf.form.valid"
            >
              Verify
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="BankDetailModelVerified"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Bank Verification
        </h6>
        <button
          type="button"
          (click)="onCloseBankModel()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #blf="ngForm"
          *ngIf="BankForm"
          novalidate
          class="col-md-12 row m-0 p-0"
        >
          <div class="row m-0">
            <div class="col-md-12">
              <span class="required-lable">Account Number</span>
              <input
                type="text"
                #refVerifyAccountNo="ngModel"
                [(ngModel)]="VerifyAccountNo"
                placeholder="Enter Account Number "
                required
                [ngClass]="{
                  'is-invalid': blf.submitted && refVerifyAccountNo.invalid,
                  'alert-warning':
                    refVerifyAccountNo.invalid &&
                    (refVerifyAccountNo.dirty ||
                      refVerifyAccountNo.touched ||
                      refVerifyAccountNo.untouched)
                }"
                name="VerifyAccountNo"
                id="VerifyAccountNo"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-12">
              <span class="required-lable">IFSC Code</span>
              <input
                type="text"
                #refVerifyIFSC="ngModel"
                [(ngModel)]="VerifyIFSC"
                placeholder="Enter IFSC "
                required
                [ngClass]="{
                  'is-invalid': blf.submitted && refVerifyIFSC.invalid,
                  'alert-warning':
                    refVerifyIFSC.invalid &&
                    (refVerifyIFSC.dirty ||
                      refVerifyIFSC.touched ||
                      refVerifyIFSC.untouched)
                }"
                name="VerifyIFSC"
                id="VerifyIFSC"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row col-md-12 m-0 mt-1 justify-content-end">
            <button
              type="button"
              (click)="onVerifiedBankDetail()"
              class="ml-4 font-size-12 button-btn"
              [class.spinner]="loading"
              [disabled]="!blf.form.valid"
            >
              Verify
            </button>
          </div>
        </form>

        <form
          #bbff="ngForm"
          *ngIf="!BankForm"
          novalidate
          class="col-md-12 row m-0 p-0 formborder"
        >
          <div
            class="card-body"
            *ngIf="!BankForm"
            [ngClass]="{ 'pending-danger': APIResponse.IsVerified == false }"
          >
            <div class="text-center" style="font-weight: bolder">
              {{ APIResponse.DoucumentNumber }}
            </div>
            <h6 class="card-title">Remark:</h6>
            <p class="card-text">{{ APIResponse.Remark }}</p>
            <br />

            <h6 class="card-title">contact:</h6>
            <p class="card-text">{{ APIResponse.contact }}</p>
            <br />
            <h6 class="card-title">account_exists:</h6>
            <p class="card-text">{{ APIResponse.account_exists }}</p>
            <br />
            <h6 class="card-title">address:</h6>
            <p class="card-text">{{ APIResponse.address }}</p>
            <br />
            <h6 class="card-title">Name:</h6>

            <p
              class="card-text"
              *ngIf="
                APIResponseData.Full_name != '' &&
                APIResponseData.Full_name != undefined &&
                APIResponseData.Full_name != null
              "
            >
              {{ APIResponse.Full_name }}
            </p>
            <p
              class="card-text"
              *ngIf="
                !(
                  APIResponseData.Full_name != '' &&
                  APIResponseData.Full_name != undefined &&
                  APIResponseData.Full_name != null
                )
              "
            >
              <input
                type="text"
                name="BankResname"
                id="BankResname"
                [(ngModel)]="APIResponse.Full_name"
                #refBankResFullname="ngModel"
                placeholder="Full Name"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': bbff.submitted && refBankResFullname.invalid,
                  'alert-warning':
                    refBankResFullname.invalid &&
                    (refBankResFullname.dirty ||
                      refBankResFullname.touched ||
                      refBankResFullname.untouched)
                }"
              />
            </p>

            <br />

            <h6 class="card-title">Bank Name:</h6>
            <p
              class="card-text"
              *ngIf="
                APIResponseData.bank_name != '' &&
                APIResponseData.bank_name != undefined &&
                APIResponseData.bank_name != null
              "
            >
              {{ APIResponse.bank_name }}
            </p>

            <p
              class="card-text"
              *ngIf="
                !(
                  APIResponseData.bank_name != '' &&
                  APIResponseData.bank_name != undefined &&
                  APIResponseData.bank_name != null
                )
              "
            >
              <input
                type="text"
                name="BankResbankname"
                id="BankResbankname"
                [(ngModel)]="APIResponse.bank_name"
                #refBankResbank="ngModel"
                placeholder="Bank Name"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': bbff.submitted && refBankResbank.invalid,
                  'alert-warning':
                    refBankResbank.invalid &&
                    (refBankResbank.dirty ||
                      refBankResbank.touched ||
                      refBankResbank.untouched)
                }"
              />
            </p>
            <br />
            <h6 class="card-title">branch:</h6>
            <p
              class="card-text"
              *ngIf="
                APIResponseData.branch != '' &&
                APIResponseData.branch != undefined &&
                APIResponseData.branch != null
              "
            >
              {{ APIResponse.branch }}
            </p>

            <p
              class="card-text"
              *ngIf="
                !(
                  APIResponseData.branch != '' &&
                  APIResponseData.branch != undefined &&
                  APIResponseData.branch != null
                )
              "
            >
              <input
                type="text"
                name="BankResbranch"
                id="BankResbranch"
                [(ngModel)]="APIResponse.branch"
                #refBankResbranch="ngModel"
                placeholder="Bank Branch"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': bbff.submitted && refBankResbranch.invalid,
                  'alert-warning':
                    refBankResbranch.invalid &&
                    (refBankResbranch.dirty ||
                      refBankResbranch.touched ||
                      refBankResbranch.untouched)
                }"
              />
            </p>
            <br />

            <h6 class="card-title">micr:</h6>
            <p
              class="card-text"
              *ngIf="
                APIResponseData.micr != '' &&
                APIResponseData.branch != micr &&
                APIResponseData.micr != null
              "
            >
              {{ APIResponse.micr }}
            </p>

            <p
              class="card-text"
              *ngIf="
                !(
                  APIResponseData.micr != '' &&
                  APIResponseData.branch != micr &&
                  APIResponseData.micr != null
                )
              "
            >
              <input
                type="text"
                name="micr"
                id="micr"
                [(ngModel)]="APIResponse.micr"
                placeholder="micr"
                class="form-control input-text-css"
              />
            </p>
            <br />
            <h6 class="card-title">ifsc:</h6>
            <p
              class="card-text"
              *ngIf="
                APIResponseData.ifsc != '' &&
                APIResponseData.ifsc != undefined &&
                APIResponseData.ifsc != null
              "
            >
              {{ APIResponse.ifsc }}
            </p>

            <p
              class="card-text"
              *ngIf="
                !(
                  APIResponseData.ifsc != '' &&
                  APIResponseData.ifsc != undefined &&
                  APIResponseData.ifsc != null
                )
              "
            >
              <input
                type="text"
                name="BankResIFSC"
                id="BankResIFSC"
                [(ngModel)]="APIResponse.ifsc"
                #refResBankIFSC="ngModel"
                placeholder="IFSC Code"
                class="form-control input-text-css"
                required
                maxlength="11"
                [ngClass]="{
                  'is-invalid': bbff.submitted && refResBankIFSC.invalid,
                  'alert-warning':
                    refResBankIFSC.invalid &&
                    (refResBankIFSC.dirty ||
                      refResBankIFSC.touched ||
                      refResBankIFSC.untouched)
                }"
              />
            </p>
            <br />
            <h6 class="card-title">rtgs:</h6>
            <p class="card-text">{{ APIResponse.rtgs }}</p>

            <br />
            <h6 class="card-title">neft:</h6>
            <p class="card-text">{{ APIResponse.neft }}</p>

            <br />
            <h6 class="card-title">imps:</h6>
            <p class="card-text">{{ APIResponse.imps }}</p>

            <br />
            <h6 class="card-title">message:</h6>
            <p class="card-text">{{ APIResponse.message }}</p>

            <br />
            <div class="row col-md-12 m-0 mt-1 justify-content-end">
              <button
                type="button"
                (click)="AddBankDetailApi()"
                class="ml-4 mt-3 btn font-size-12 button-btn"
                [disabled]="!bbff.form.valid"
                [class.spinner]="loading"
              >
                Bank Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Import Data  ---->
<div
  class="modal fade in"
  id="Import1Model"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Import Customer
        </h6>
        <button
          type="button"
          (click)="onCloseImport1Model()"
          class="close"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 col-md-12 p-0">
          <div class="col-md-4">
            <button
              class="mt-3 btn font-size-12 button-btn btn-padding"
              (click)="ExportTemplate()"
            >
              Export (Customer Import Template)
            </button>
          </div>
          <div class="col-md-4">
            <span class="button-wrap">
              <label
                class="mt-3 btn font-size-12 button-btn"
                for="ImportCustomerData"
                >Import Customer Data</label
              >
              <input
                #fileInput
                type="file"
                id="ImportCustomerData"
                (change)="ImportCustomerData($event)"
                style="display: none"
                accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </span>
          </div>
          <div class="col-md-4">
            <button
              class="mt-3 btn font-size-12 button-btn btn-padding"
              (click)="ExportLocationData()"
            >
              Export Location Data
            </button>
          </div>
        </div>
        <h6 *ngIf="dataSourceBlankDataFilldata != null">
          Please Fill Blank Data
        </h6>
        <div
          class="table-responsive"
          *ngIf="dataSourceBlankDataFilldata != null"
        >
          <mat-table
            [dataSource]="dataSourceBlankDataFilldata"
            matSort
            matTableExporter
            #exporterTable="matTableExporter"
          >
            <ng-container matColumnDef="Id">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Id</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Id == null || row.Id == '' ? 'pending-danger' : ''
                "
                class="grid-cell"
              >
                {{ row.Id }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_FirstName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_FirstName</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_FirstName == null || row.Customer_FirstName == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_FirstName }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Customer_LastName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_LastName</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_LastName == null || row.Customer_LastName == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_LastName }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Gender">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Gender</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Gender == null || row.Customer_Gender == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Gender }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_DOB_Year">
              <mat-header-cell
                mat-sort-header
                class="grid-header 0"
                *matHeaderCellDef
                >Customer_DOB_Year</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_DOB_Year == null || row.Customer_DOB_Year == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_DOB_Year }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_DOB_Month">
              <mat-header-cell
                mat-sort-header
                class="grid-header 0"
                *matHeaderCellDef
                >Customer_DOB_Month</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_DOB_Month == null || row.Customer_DOB_Month == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_DOB_Month }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_DOB_Day">
              <mat-header-cell
                mat-sort-header
                class="grid-header 0"
                *matHeaderCellDef
                >Customer_DOB_Day</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_DOB_Day == null || row.Customer_DOB_Day == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_DOB_Day }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_PhoneNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_PhoneNo</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_PhoneNo == null || row.Customer_PhoneNo == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_PhoneNo }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_WhatsAppNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_WhatsAppNo</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Customer_WhatsAppNo }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Customer_Email">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Email</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Customer_Email }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Website">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Website</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Customer_Website }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Religion">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Religion</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Religion == null || row.Customer_Religion == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Religion }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Cast">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Cast</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Cast == null || row.Customer_Cast == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Cast }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_MaritalStatus">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_MaritalStatus</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_MaritalStatus == null ||
                  row.Customer_MaritalStatus == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_MaritalStatus }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Relation">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Relation</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Relation == null || row.Customer_Relation == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Relation }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Relation_FirstName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Relation_FirstName</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Relation_FirstName == null ||
                  row.Customer_Relation_FirstName == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Relation_FirstName }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Relation_LastName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Relation_LastName</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Relation_LastName == null ||
                  row.Customer_Relation_LastName == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Relation_LastName }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_Address">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_Address</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_Address == null || row.Present_Address == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_Address }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_LandMark">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_LandMark</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_LandMark == null || row.Present_LandMark == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_LandMark }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_TehsilId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_TehsilId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_TehsilId == null || row.Present_TehsilId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_TehsilId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_DistrictId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_DistrictId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_DistrictId == null || row.Present_DistrictId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_DistrictId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_StateId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_StateId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_StateId == null || row.Present_StateId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_StateId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_PinCode">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_PinCode</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_PinCode == null || row.Present_PinCode == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_PinCode }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_NearstBranchDistance_KM">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_NearstBranchDistance_KM</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Present_NearstBranchDistance_KM }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Present_TotalYearsOnAddress">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_TotalYearsOnAddress</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Present_TotalYearsOnAddress }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_AddressRentBuy">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_AddressRentBuy</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_AddressRentBuy == null ||
                  row.Present_AddressRentBuy == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_AddressRentBuy }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_IsCommunicationAddress">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_IsCommunicationAddress</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Present_IsCommunicationAddress }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_Address">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_Address</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_Address == null || row.Permanent_Address == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_Address }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_LandMark">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_LandMark</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_LandMark == null || row.Permanent_LandMark == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_LandMark }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_TehsilId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_TehsilId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_TehsilId == null || row.Permanent_TehsilId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_TehsilId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_DistrictId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_DistrictId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_DistrictId == null ||
                  row.Permanent_DistrictId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_DistrictId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_StateId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_StateId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_StateId == null || row.Permanent_StateId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_StateId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_PinCode">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_PinCode</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_PinCode == null || row.Permanent_PinCode == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_PinCode }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_NearstBranchDistance_KM">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_NearstBranchDistance_KM</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Permanent_NearstBranchDistance_KM }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_TotalYearsOnAddress">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_TotalYearsOnAddress</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Permanent_TotalYearsOnAddress }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_AddressRentBuy">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_AddressRentBuy</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_AddressRentBuy == null ||
                  row.Permanent_AddressRentBuy == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_AddressRentBuy }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_IsCommunicationAddress">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_IsCommunicationAddress</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Permanent_IsCommunicationAddress }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Aadhaar_No">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Aadhaar_No</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  (row.Aadhaar_No == '' || row.Aadhaar_No == null) &&
                  (row.PAN_No == '' || row.PAN_No == null) &&
                  (row.VoterId_No == '' || row.VoterId_No == null)
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Aadhaar_No }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="PAN_No">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >PAN_No</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  (row.Aadhaar_No == '' || row.Aadhaar_No == null) &&
                  (row.PAN_No == '' || row.PAN_No == null) &&
                  (row.VoterId_No == '' || row.VoterId_No == null)
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.PAN_No }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="VoterId_No">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >VoterId_No</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  (row.Aadhaar_No == '' || row.Aadhaar_No == null) &&
                  (row.PAN_No == '' || row.PAN_No == null) &&
                  (row.VoterId_No == '' || row.VoterId_No == null)
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.VoterId_No }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Electricity_KNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Electricity_KNo</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Electricity_KNo }}
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayImportData"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayImportData"></mat-row>
          </mat-table>
        </div>

        <h6 *ngIf="dataSourceDuplicate != null">Please Edit Duplicate Data</h6>
        <div class="table-responsive" *ngIf="dataSourceDuplicate != null">
          <mat-table
            [dataSource]="dataSourceDuplicate"
            matSort
            matTableExporter
            #exporterTable="matTableExporter"
          >
            <ng-container matColumnDef="Id">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Id</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Id != '' &&
                  row.Id != undefined &&
                  row.Id_Noduplicate == true
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Id }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_FirstName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_FirstName</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_FirstName == null || row.Customer_FirstName == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_FirstName }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Customer_LastName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_LastName</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_LastName == null || row.Customer_LastName == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_LastName }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Gender">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Gender</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Gender == null || row.Customer_Gender == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Gender }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_DOB">
              <mat-header-cell
                mat-sort-header
                class="grid-header 0"
                *matHeaderCellDef
                >Customer_DOB</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_DOB == null || row.Customer_DOB == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_DOB }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_DOB_Year">
              <mat-header-cell
                mat-sort-header
                class="grid-header 0"
                *matHeaderCellDef
                >Customer_DOB_Year</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="{
                  'pending-danger':
                    !NumberValidation(row.Customer_DOB_Year, 4, null) ||
                    (!isGreaterThan(row.Customer_DOB_Year, null) &&
                      row.Customer_DOB_Year != '' &&
                      row.Customer_DOB_Year != null)
                }"
                class="grid-cell"
              >
                {{ row.Customer_DOB_Year }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_DOB_Month">
              <mat-header-cell
                mat-sort-header
                class="grid-header 0"
                *matHeaderCellDef
                >Customer_DOB_Month</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="{
                  'pending-danger':
                    !isGreaterThan(row.Customer_DOB_Month, null) &&
                    row.Customer_DOB_Month != '' &&
                    row.Customer_DOB_Month != null
                }"
                class="grid-cell"
              >
                {{ row.Customer_DOB_Month }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_DOB_Day">
              <mat-header-cell
                mat-sort-header
                class="grid-header 0"
                *matHeaderCellDef
                >Customer_DOB_Day</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="{
                  'pending-danger':
                    !isGreaterThan(row.Customer_DOB_Day, null) &&
                    row.Customer_DOB_Day != '' &&
                    row.Customer_DOB_Day != null
                }"
                class="grid-cell"
              >
                {{ row.Customer_DOB_Day }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_PhoneNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_PhoneNo</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_PhoneNo == null || row.Customer_PhoneNo == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_PhoneNo }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_WhatsAppNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_WhatsAppNo</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Customer_WhatsAppNo }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Customer_Email">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Email</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Customer_Email }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Website">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Website</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Customer_Website }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Religion">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Religion</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Religion == null || row.Customer_Religion == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Religion }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Cast">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Cast</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Cast == null || row.Customer_Cast == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Cast }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_MaritalStatus">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_MaritalStatus</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_MaritalStatus == null ||
                  row.Customer_MaritalStatus == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_MaritalStatus }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Relation">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Relation</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Relation == null || row.Customer_Relation == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Relation }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Relation_FirstName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Relation_FirstName</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Relation_FirstName == null ||
                  row.Customer_Relation_FirstName == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Relation_FirstName }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Relation_LastName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Relation_LastName</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Relation_LastName == null ||
                  row.Customer_Relation_LastName == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Relation_LastName }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_Address">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_Address</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_Address == null || row.Present_Address == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_Address }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_LandMark">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_LandMark</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_LandMark == null || row.Present_LandMark == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_LandMark }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_TehsilId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_TehsilId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_TehsilId == null || row.Present_TehsilId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_TehsilId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_DistrictId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_DistrictId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_DistrictId == null || row.Present_DistrictId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_DistrictId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_StateId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_StateId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_StateId == null || row.Present_StateId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_StateId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_PinCode">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_PinCode</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_PinCode == null || row.Present_PinCode == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_PinCode }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_NearstBranchDistance_KM">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_NearstBranchDistance_KM</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Present_NearstBranchDistance_KM }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Present_TotalYearsOnAddress">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_TotalYearsOnAddress</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Present_TotalYearsOnAddress }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_AddressRentBuy">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_AddressRentBuy</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_AddressRentBuy == null ||
                  row.Present_AddressRentBuy == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_AddressRentBuy }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_IsCommunicationAddress">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_IsCommunicationAddress</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Present_IsCommunicationAddress }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_Address">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_Address</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_Address == null || row.Permanent_Address == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_Address }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_LandMark">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_LandMark</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_LandMark == null || row.Permanent_LandMark == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_LandMark }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_TehsilId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_TehsilId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_TehsilId == null || row.Permanent_TehsilId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_TehsilId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_DistrictId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_DistrictId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_DistrictId == null ||
                  row.Permanent_DistrictId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_DistrictId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_StateId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_StateId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_StateId == null || row.Permanent_StateId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_StateId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_PinCode">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_PinCode</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_PinCode == null || row.Permanent_PinCode == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_PinCode }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_NearstBranchDistance_KM">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_NearstBranchDistance_KM</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Permanent_NearstBranchDistance_KM }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_TotalYearsOnAddress">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_TotalYearsOnAddress</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Permanent_TotalYearsOnAddress }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_AddressRentBuy">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_AddressRentBuy</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_AddressRentBuy == null ||
                  row.Permanent_AddressRentBuy == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_AddressRentBuy }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_IsCommunicationAddress">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_IsCommunicationAddress</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Permanent_IsCommunicationAddress }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Aadhaar_No">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Aadhaar_No</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Aadhaar_No != '' &&
                  row.Aadhaar_No != undefined &&
                  row.Aadhaar_Noduplicate == true
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Aadhaar_No }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="PAN_No">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >PAN_No</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.PAN_No != '' &&
                  row.PAN_No != undefined &&
                  row.PAN_Noduplicate == true
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.PAN_No }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="VoterId_No">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >VoterId_No</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.VoterId_No != '' &&
                  row.VoterId_No != undefined &&
                  row.VoterId_Noduplicate == true
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.VoterId_No }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Electricity_KNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Electricity_KNo</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Electricity_KNo }}
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayImportData"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayImportData"></mat-row>
          </mat-table>
        </div>

        <h6
          *ngIf="
            dataSourceNotValiddata != null &&
            (InValidDataInDataBase == 0 || InValidDataInDataBase == 1)
          "
        >
          Please Fill Valid Data
        </h6>
        <h6
          *ngIf="dataSourceNotValiddata != null && InValidDataInDataBase == 2"
        >
          Correct Data
        </h6>

        <div class="table-responsive" *ngIf="dataSourceNotValiddata != null">
          <mat-table
            [dataSource]="dataSourceNotValiddata"
            matSort
            matTableExporter
            #exporterTable="matTableExporter"
          >
            <ng-container matColumnDef="Id">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Id</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Id }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_FirstName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_FirstName</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_FirstName == null || row.Customer_FirstName == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_FirstName }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Customer_LastName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_LastName</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_LastName == null || row.Customer_LastName == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_LastName }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Gender">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Gender</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="{
                  'pending-danger':
                    !isGenderValid(row.Customer_Gender, null) &&
                    row.Customer_Gender != '' &&
                    row.Customer_Gender != null
                }"
                class="grid-cell"
              >
                {{ row.Customer_Gender }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_DOB">
              <mat-header-cell
                mat-sort-header
                class="grid-header 0"
                *matHeaderCellDef
                >Customer_DOB</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_DOB == null || row.Customer_DOB == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_DOB }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_DOB_Year">
              <mat-header-cell
                mat-sort-header
                class="grid-header 0"
                *matHeaderCellDef
                >Customer_DOB_Year</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="{
                  'pending-danger':
                    !NumberValidation(row.Customer_DOB_Year, 4, null) ||
                    (!isGreaterThan(row.Customer_DOB_Year, null) &&
                      row.Customer_DOB_Year != '' &&
                      row.Customer_DOB_Year != null)
                }"
                class="grid-cell"
              >
                {{ row.Customer_DOB_Year }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_DOB_Month">
              <mat-header-cell
                mat-sort-header
                class="grid-header 0"
                *matHeaderCellDef
                >Customer_DOB_Month</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="{
                  'pending-danger':
                    !isGreaterThan(row.Customer_DOB_Month, null) &&
                    row.Customer_DOB_Month != '' &&
                    row.Customer_DOB_Month != null
                }"
                class="grid-cell"
              >
                {{ row.Customer_DOB_Month }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_DOB_Day">
              <mat-header-cell
                mat-sort-header
                class="grid-header 0"
                *matHeaderCellDef
                >Customer_DOB_Day</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="{
                  'pending-danger':
                    !isGreaterThan(row.Customer_DOB_Day, null) &&
                    row.Customer_DOB_Day != '' &&
                    row.Customer_DOB_Day != null
                }"
                class="grid-cell"
              >
                {{ row.Customer_DOB_Day }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_PhoneNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_PhoneNo</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="{
                  'pending-danger':
                    !NumberValidation(row.Customer_PhoneNo, 10, null) &&
                    row.Customer_PhoneNo != '' &&
                    row.Customer_PhoneNo != null
                }"
                class="grid-cell"
              >
                {{ row.Customer_PhoneNo }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_WhatsAppNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_WhatsAppNo</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="{
                  'pending-danger':
                    !NumberValidation(row.Customer_WhatsAppNo, 10, null) &&
                    row.Customer_WhatsAppNo != '' &&
                    row.Customer_WhatsAppNo != null
                }"
                class="grid-cell"
              >
                {{ row.Customer_WhatsAppNo }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Customer_Email">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Email</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Customer_Email }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Website">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Website</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Customer_Website }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Religion">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Religion</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Religion == null || row.Customer_Religion == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Religion }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Cast">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Cast</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Cast == null || row.Customer_Cast == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Cast }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_MaritalStatus">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_MaritalStatus</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_MaritalStatus == null ||
                  row.Customer_MaritalStatus == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_MaritalStatus }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Relation">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Relation</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Relation == null || row.Customer_Relation == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Relation }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Relation_FirstName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Relation_FirstName</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Relation_FirstName == null ||
                  row.Customer_Relation_FirstName == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Relation_FirstName }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Customer_Relation_LastName">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Customer_Relation_LastName</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Customer_Relation_LastName == null ||
                  row.Customer_Relation_LastName == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Customer_Relation_LastName }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_Address">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_Address</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_Address == null || row.Present_Address == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_Address }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_LandMark">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_LandMark</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_LandMark == null || row.Present_LandMark == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_LandMark }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_TehsilId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_TehsilId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_TehsilId == null || row.Present_TehsilId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_TehsilId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_DistrictId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_DistrictId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_DistrictId == null || row.Present_DistrictId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_DistrictId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_StateId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_StateId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_StateId == null || row.Present_StateId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_StateId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_PinCode">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_PinCode</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="{
                  'pending-danger':
                    !NumberValidation(row.Present_PinCode, 6, null) &&
                    row.Present_PinCode != '' &&
                    row.Present_PinCode != null
                }"
                class="grid-cell"
              >
                {{ row.Present_PinCode }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_NearstBranchDistance_KM">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_NearstBranchDistance_KM</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Present_NearstBranchDistance_KM }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Present_TotalYearsOnAddress">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_TotalYearsOnAddress</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Present_TotalYearsOnAddress }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_AddressRentBuy">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_AddressRentBuy</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Present_AddressRentBuy == null ||
                  row.Present_AddressRentBuy == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Present_AddressRentBuy }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Present_IsCommunicationAddress">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Present_IsCommunicationAddress</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Present_IsCommunicationAddress }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_Address">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_Address</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_Address == null || row.Permanent_Address == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_Address }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_LandMark">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_LandMark</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_LandMark == null || row.Permanent_LandMark == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_LandMark }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_TehsilId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_TehsilId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_TehsilId == null || row.Permanent_TehsilId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_TehsilId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_DistrictId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_DistrictId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_DistrictId == null ||
                  row.Permanent_DistrictId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_DistrictId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_StateId">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_StateId</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_StateId == null || row.Permanent_StateId == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_StateId }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_PinCode">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_PinCode</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="{
                  'pending-danger':
                    !NumberValidation(row.Permanent_PinCode, 6, null) &&
                    row.Permanent_PinCode != '' &&
                    row.Permanent_PinCode != null
                }"
                class="grid-cell"
              >
                {{ row.Permanent_PinCode }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_NearstBranchDistance_KM">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_NearstBranchDistance_KM</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Permanent_NearstBranchDistance_KM }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_TotalYearsOnAddress">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_TotalYearsOnAddress</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Permanent_TotalYearsOnAddress }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_AddressRentBuy">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_AddressRentBuy</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.Permanent_AddressRentBuy == null ||
                  row.Permanent_AddressRentBuy == ''
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.Permanent_AddressRentBuy }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Permanent_IsCommunicationAddress">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Permanent_IsCommunicationAddress</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Permanent_IsCommunicationAddress }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Aadhaar_No">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Aadhaar_No</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="{
                  'pending-danger':
                    !NumberValidation(row.Aadhaar_No, 12, null) &&
                    row.Aadhaar_No != '' &&
                    row.Aadhaar_No != null
                }"
                class="grid-cell"
              >
                {{ row.Aadhaar_No }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="PAN_No">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >PAN_No</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="{
                  'pending-danger':
                    !isPanNumberValid(row.PAN_No, null) &&
                    row.PAN_No != '' &&
                    row.PAN_No != undefined
                }"
                class="grid-cell"
              >
                {{ row.PAN_No }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="VoterId_No">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >VoterId_No</mat-header-cell
              >
              <mat-cell
                *matCellDef="let row"
                [ngClass]="
                  row.VoterId_No != '' &&
                  row.VoterId_No != undefined &&
                  row.VoterId_Noduplicate == true
                    ? 'pending-danger'
                    : ''
                "
                class="grid-cell"
              >
                {{ row.VoterId_No }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Electricity_KNo">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Electricity_KNo</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.Electricity_KNo }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="NotMach">
              <mat-header-cell
                mat-sort-header
                class="grid-header"
                *matHeaderCellDef
                >Error</mat-header-cell
              >
              <mat-cell *matCellDef="let row" class="grid-cell">
                {{ row.NotMach }}
              </mat-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayImportData"
            ></mat-header-row>
            <mat-row
              [ngClass]="
                row.NotMach != '' && row.NotMach != null ? 'pending-danger' : ''
              "
              *matRowDef="let row; columns: displayImportData"
            ></mat-row>
          </mat-table>
        </div>

        <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
          <button
            type="button"
            *ngIf="InValidDataInDataBase == 2"
            (click)="OnSaveCustomer()"
            [class.spinner]="loading"
            class="btn font-size-12 button-btn"
          >
            Save Customer
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
