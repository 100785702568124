import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { LosService } from "src/app/_LOS/services/los.service";
import { QuickLoanService } from "src/app/_QuickLoan/services/quickLoan.service";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { MasterService } from "src/app/Shared/app.Masters.Service";
import { constantUrl } from "src/app/Shared/constantUrl";
import { DialogService } from "src/app/Shared/dialog.service";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { LmsService } from "../services/lms.service";
import { AnyObject } from "chart.js/types/basic";

@Component({
  selector: "app-lms-file-checking",
  templateUrl: "./lms-file-checking.component.html",
  styleUrls: ["./lms-file-checking.component.scss"],
})
export class LmsFileCheckingComponent implements OnInit {
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  configSuccess1: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  configSuccess2: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  showSpinner: boolean = false;
  currentUser: any;
  loading: boolean = false;
  IsSave: boolean = false;
  IsUpdate: boolean = false;
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  @Input() Revert_IsEdit: any;

  PageAcess: any;

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  @Input() loanSummary: any;
  @Input() Processid: any;
  @Input() PageData: any;
  @Input() isNew: boolean;
  today: Date = new Date();

  IsEdit: boolean = false;
  IsDisabled: boolean = false;
  @Input() Status: any;
  @Input() ProcessName: any;

  //BtnAddView: any = 'Fill';

  // FOIRItems: { IsEligible: boolean; FOIR_Amount: number }[] = [];
  docBaseUrl: any = constantUrl.customerProfilePicUrl;
  AllowExtension: any = constantUrl.AllowExtension;
  totalFOIR: number = 0;
  totalFOIRExpense: number = 0;

  resultItem1CurrentEmi: number = 0;
  isCheckboxChecked: boolean = false;
  resultItem1FoirId: any = 0;
  isEligibleVariable: any;
  totalMonthlyExpenseCalculated: { total: number };
  totalMonthlyIncomeCalculated: { total: number };
  CustomerList: any = 0;
  questionData: any[] = [];
  questionOptionData: any[] = [];
  constructor(
    private _Route: Router,
    private dialog: DialogService,
    private dataSharingService: DataSharingService,
    private LmsService: LmsService,
    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private losService: LosService,

    private _EncrDecrService: EncrDecrService,
    private _QuickLoanService: QuickLoanService
  ) {
    let AllPage = JSON.parse(sessionStorage.getItem("AllPageAcess"));
    this.PageAcess = AllPage.filter(
      (x) => x.Page_Name == "File Checking"
    );
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.dataSharingService.HeaderTitle.next(this.ProcessName);
    this.getFileCheckingData();
    console.log("PageData", this.PageData);
  }

  getFileCheckingData() {
    this._QuickLoanService
      .QuickLoan_GetFileChecking({ LoanId: this.loanSummary.LoanId })
      .subscribe((res: any) => {
        console.log("RES :", res);
        if (res.length > 0) {
          this.showSpinner = false;
          this.questionData = res;
          this.IsEdit = res[0].IsExists == 1 ? true : false;
          this.IsSave = res[0].IsExists == 1 ? false : true;
          this.IsDisabled = res[0].IsExists == 1 ? true : false;
          this.questionData.forEach((obj: any) => {
            obj.Remark = obj.Remark;
            obj.FileCheckingName = obj.FileCheckingName;

            obj.FileCheckingDropdown = obj.FileCheckingOptions.split(",");
            obj.SelectOption = obj.SelectOption;
          });
        }
      });
  }

  onSaveCustomerFileChecking() {
    this._QuickLoanService
      .Quick_Save_FileChecking({
        JSON: JSON.stringify(this.questionData),
        LoanId: this.loanSummary.LoanId,
        LoginUserId: this.currentUser.userId,
      })
      .subscribe((response: any) => {
        //console.log(response);
        if (response[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.getFileCheckingData();

          this.action.emit("next");
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
        this.loading = false;
      });
  }

  onEditFileChecking() {
    this.IsEdit = false;
    this.IsUpdate = true;
    this.IsSave = false;
    this.IsDisabled = false;
  }

  onNext() {
    this.LmsService.SaveNext_Loan({
      Loan_Id: this.loanSummary.LoanId,
      CommandName: "File Checking",
      isStatus: 1,
    }).subscribe((response) => {
      this.action.emit("next");
    });
  }
}
