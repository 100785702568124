import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MasterService } from "../../Shared/app.Masters.Service";
import { RequestModel } from "../../Shared/Models/app.MasterRequestModel";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { LmsService } from "../../_Lms/services/lms.service";
import { constantUrl } from "../../Shared/constantUrl";
import { NgStyle } from "@angular/common";

@Component({
  selector: "app-systemconfiguration",
  templateUrl: "./systemconfiguration.component.html",
  styleUrls: ["./systemconfiguration.component.scss"],
})
export class SystemconfigurationComponent implements OnInit {
  RequestModel: RequestModel = new RequestModel();
  private data: any;
  private _MasterService;

  showSpinner: boolean = false;
  CompanyName: string = "";
  CompanyRegNoName: string = "";
  ReopenDays: number = 0;
  ApplicationNoPrefix: string = "";
  BranchCodePrefix: string = "";
  EnquiryNoPrefix: string = "";
  LoanAppNo_Prefix: string = "";
  DaysInYear: any = "";
  Data_Freeze: any;
  LoanSeries: any;
  systemconfig: any = [];
  Is_NOC_Block: boolean = false;
  IsMasking: boolean = false;
  IsMaskingMobile: boolean = false;
  ComapnyEmail: string;
  CompanyDateofIncorporation: any;
  CompanyGSTNo: string;
  CompanyHOAddress: string;
  companyCorporateAddress: any;
  CompanyPANNo: string;
  CompanyPhoneNo: string;
  CompanyPhoneNo1: string;
  CompanyRegNo: string;
  CompanyStateCode: string;
  CompanyTANNo: string;
  CompanyWhatAppNo: string;
  FilterStateList: any = [];
  FilterStateId: any = 29;
  LimitOfCashPayment: any;
  LimitOfCashReceipt: any;
  CRC_Agency: string = "";
  CRC_Agency_Bind: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  UploadMaxSize_In_KB: any;
  UploadDocsSize: any = 2048;
  NPA_DPD: any;
  CompanyLogo: any;
  UploadImageBase64: any = "";
  UploadImageName: string = "";

  constructor(
    private LmsService: LmsService,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private MasterService: MasterService
  ) {
    this._MasterService = MasterService;
    this.data = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Company Information");
    this._MasterService.GetState().subscribe((result) => {
      this.FilterStateList = JSON.parse(JSON.stringify(result));
    });
    this.getAgency();
    this.getSystemConfiguration();
  }
  getAgency() {
    this._MasterService
      .Commaon_Master({ Commands: "Select", Type: "CRC AGENCY" })
      .subscribe((res: any) => {
        console.log(res);
        this.CRC_Agency_Bind = res;
      });
  }
  getSystemConfiguration() {
    this.showSpinner = true;
    this._MasterService.GetConfigurationDetails().subscribe((result) => {
      this.systemconfig = JSON.parse(JSON.stringify(result));
      console.log(this.systemconfig);
      this.CompanyName = this.systemconfig[0].CompanyName;
      this.CompanyRegNoName = this.systemconfig[0].CompanyRegNo;
      this.ReopenDays = this.systemconfig[0].RejectedAppReopen_Days;
      this.ApplicationNoPrefix = this.systemconfig[0].ApplicationNo_Prefix;
      this.BranchCodePrefix = this.systemconfig[0].BranchCode_Prefix;
      this.EnquiryNoPrefix = this.systemconfig[0].EnquireNo_Prefix;
      this.LoanAppNo_Prefix = this.systemconfig[0].LoanAppNo_Prefix;
      this.DaysInYear = this.systemconfig[0].DaysInYear;
      this.Data_Freeze = new Date(this.systemconfig[0].Data_Freeze);
      this.Is_NOC_Block = this.systemconfig[0].Is_NOC_Block == 1 ? true : false;
      this.IsMasking = this.systemconfig[0].IsMasking == 1 ? true : false;
      this.IsMaskingMobile = this.systemconfig[0].IsMaskingMobile == 1 ? true : false;
      this.ComapnyEmail = this.systemconfig[0].ComapnyEmail;
      this.CompanyDateofIncorporation = new Date(
        this.systemconfig[0].CompanyDateofIncorporation
      );
      this.companyCorporateAddress =
        this.systemconfig[0].CompanyCorporateOfficeAddress;
      // console.log("Company corporate address",this.companyCorporateaddress)
      this.CompanyGSTNo = this.systemconfig[0].CompanyGSTNo;
      this.CompanyHOAddress = this.systemconfig[0].CompanyHeadOfficeAddress;
      this.CompanyPANNo = this.systemconfig[0].CompanyPANNo;
      this.CompanyPhoneNo = this.systemconfig[0].CompanyPhoneNo;
      this.CompanyPhoneNo1 = this.systemconfig[0].CompanyPhoneNo1;
      //this.CompanyStateCode = this.systemconfig[0].CompanyStateCode;
      this.FilterStateId = this.systemconfig[0].CompanyStateCode;
      this.CompanyTANNo = this.systemconfig[0].CompanyTANNo;
      this.CompanyWhatAppNo = this.systemconfig[0].CompanyWhatAppNo;
      this.CRC_Agency = this.systemconfig[0].DefaultCRCAgency;
      this.LoanSeries = this.systemconfig[0].LoanSeries;
      this.UploadMaxSize_In_KB = this.systemconfig[0].UploadMaxSize_In_KB;
      this.CompanyLogo = this.systemconfig[0].CompanyLogo;
      this.NPA_DPD = this.systemconfig[0].NPA_DPD;
      this.LimitOfCashPayment = this.systemconfig[0].LimitOfCashPayment;
      this.LimitOfCashReceipt = this.systemconfig[0].LimitOfCashReceipt;

      this.UploadImageBase64 =
        this.systemconfig[0].CompanyLogo != ""
          ? constantUrl.apiProfilePicUrl + this.systemconfig[0].CompanyLogo
          : "";
      this.showSpinner = false;
    });
  }

  onSaveData() {
    if (this.UploadMaxSize_In_KB > this.UploadDocsSize) {
      this.UploadMaxSize_In_KB = "";
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Upload file size less than or equal to ${this.UploadDocsSize} KB`,
        ...this.configSuccess,
      });
    }

    if (this.ApplicationNoPrefix == this.LoanAppNo_Prefix) {
      this.ApplicationNoPrefix = "";
      this.LoanAppNo_Prefix = "";
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "LOS and LMS Application Prefix can not be same!!!",
        ...this.configSuccess,
      });
    } else {
      this.RequestModel.CompanyName = this.CompanyName;
      this.RequestModel.CompanyRegNo = this.CompanyRegNoName;
      this.RequestModel.RejectReopen_Days = this.ReopenDays;
      this.RequestModel.ApplicationNo_Prefix = this.ApplicationNoPrefix;
      this.RequestModel.BranchCode_Prefix = this.BranchCodePrefix;
      this.RequestModel.EnquireNo_Prefix = this.EnquiryNoPrefix;
      this.RequestModel.LoanAppNo_Prefix = this.LoanAppNo_Prefix;
      this.RequestModel.DaysInYear = this.DaysInYear;
      this.RequestModel.Data_Freeze = this.Data_Freeze;
      this.RequestModel.ComapnyEmail = this.ComapnyEmail;
      this.RequestModel.CompanyWhatAppNo = this.CompanyWhatAppNo;
      this.RequestModel.CompanyPhoneNo = this.CompanyPhoneNo;
      this.RequestModel.CompanyPhoneNo1 = this.CompanyPhoneNo1;
      this.RequestModel.CompanyStateCode = this.FilterStateId;
      this.RequestModel.CompanyHeadOfficeAddress = this.CompanyHOAddress;
      this.RequestModel.CompanyCorporateOfficeAddress =
        this.companyCorporateAddress;
      this.RequestModel.CompanyDateofIncorporation =
        this.CompanyDateofIncorporation;
      this.RequestModel.CompanyGSTNo = this.CompanyGSTNo;
      this.RequestModel.CompanyTANNo = this.CompanyTANNo;
      this.RequestModel.CompanyPANNo = this.CompanyPANNo;
      this.RequestModel.DefaultCRCAgency = this.CRC_Agency;
      this.RequestModel.LoanSeries = this.LoanSeries;
      this.RequestModel.UploadMaxSize_In_KB = this.UploadMaxSize_In_KB;
      this.RequestModel.CompanyLogo = this.UploadImageName;
      this.RequestModel.NPA_DPD = this.NPA_DPD;
      this.RequestModel.LimitOfCashReceipt = this.LimitOfCashReceipt;
      this.RequestModel.LimitOfCashPayment = this.LimitOfCashPayment;
      this.RequestModel.Is_NOC_Block = this.Is_NOC_Block == true ? 1 : 0;
      this.RequestModel.IsMasking = this.IsMasking == true ? 1 : 0;

      if (this.CompanyLogo != "") {
        this.RequestModel.DocData =
          this.UploadImageBase64.split(";base64,").pop();
        this.RequestModel.DocName = this.UploadImageName;
        this._MasterService
          .UploadProfilePic(this.RequestModel)
          .subscribe((response) => {
            //console.log("UploadProfilePic",response);
          });
      }

      this._MasterService
        .LOS_SaveConfigurationDetails(this.RequestModel)
        .subscribe((response) => {
          if (response[0].CODE == 0) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: response[0].MSG,
              ...this.configSuccess,
            });
          }
        });
    }
  }

  handleUpload = (event) => {
    const file = event.target.files[0];
    if (file == undefined) {
      this.UploadImageName = "";
      this.UploadImageBase64 = "";
      this.CompanyLogo = "";
      return;
    }
    let extension = file.name.split(".").pop().toLowerCase();
    if (extension == "jpg" || extension == "jpeg" || extension == "png") {
      let imgsize = Math.round(file.size / 1024 / 1024);
      if (imgsize <= 5) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.UploadImageBase64 = reader.result;
          this.UploadImageName = "logo.png";
        };
      } else {
        this.CompanyLogo = "";
        this.UploadImageName = "";
        this.UploadImageBase64 = "";
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Company Logo uploaded less than and equal 5MB.",
          ...this.configSuccess,
        });
      }
    } else {
      this.CompanyLogo = "";
      this.UploadImageName = "";
      this.UploadImageBase64 = "";
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Company Logo not uploaded, because only accept .png, .jpg and .jpeg.",
        ...this.configSuccess,
      });
    }
  };
  Add_Current_FinancialYear() {
    this._MasterService.Add_Current_FinancialYear().subscribe((res: any) => {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: res[0].MSG,
        ...this.configSuccess,
      });
    });
  }
}
