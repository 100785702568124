<section class="main-content-wrapper">
  <div
    class="watermark"
    *ngIf="
      loanSummary &&
      loanSummary.Watermark_Status &&
      loanSummary.Watermark_Status == 'Closed'
    "
  >
    Closed
  </div>
  <div
    class="watermark"
    *ngIf="
      loanSummary &&
      loanSummary.Watermark_Status &&
      loanSummary.Watermark_Status == 'Legal'
    "
  >
    Legal
  </div>
  <div
    class="watermark"
    *ngIf="
      loanSummary &&
      loanSummary.Watermark_Status &&
      loanSummary.Watermark_Status == 'NPA'
    "
  >
    NPA
  </div>
  <div
    class="watermark"
    *ngIf="
      loanSummary &&
      loanSummary.Watermark_Status &&
      loanSummary.Watermark_Status == 'Legal/NPA'
    "
  >
    Legal/NPA
  </div>
  <div
    class="watermark"
    *ngIf="
      loanSummary &&
      loanSummary.Watermark_Status &&
      loanSummary.Watermark_Status == 'Repossessed'
    "
  >
    Repossessed
  </div>
  <div
    class="watermark"
    *ngIf="
      loanSummary &&
      loanSummary.Watermark_Status &&
      loanSummary.Watermark_Status == 'Legal/Repossessed'
    "
  >
    Legal/Repossessed
  </div>
  <div
    class="watermark"
    *ngIf="
      loanSummary &&
      loanSummary.Watermark_Status &&
      loanSummary.Watermark_Status == 'NPA/Legal/Repossessed'
    "
  >
    NPA/Legal/Repossessed
  </div>

  <div *ngIf="loanSummary" class="loanSummary">
    <!-- style="position: sticky; top: -10px; z-index: 100;" -->
    <div class="row m-0 mt-0">
      <div
        class="col-md-12 mb-1"
        [ngClass]="loanSummary.CloseDate != null ? 'alert alert-danger' : ''"
      >
        <div class="row">
          <div class="col-md-3">
            <span class="fs-14"
              ><B>Loan No : {{ loanSummary.LoanNo }}</B></span
            >
          </div>
          <div class="col-md-3">
            <span class="fs-12"
              ><B>Loan Date :</B> {{ loanSummary.LoanDate }}</span
            >
          </div>
          <div class="col-md-3">
            <span class="fs-12"
              ><B>Application No :</B> {{ loanSummary.ApplicationNo }}</span
            >
          </div>
          <div class="col-md-3">
            <span class="fs-12"
              ><B>Application Date :</B>
              {{ loanSummary.Application_Date }}</span
            >
          </div>

          <div class="col-md-3">
            <span class="fs-12"><B>Branch :</B> {{ loanSummary.Branch }}</span>
          </div>
          <div class="col-md-3">
            <span class="fs-12"
              ><B>Loan Amount :</B> {{ loanSummary.LoanAmount }}</span
            >
          </div>
          <div class="col-md-3">
            <span class="fs-12"
              ><B>Product :</B> {{ loanSummary.Product }}</span
            >
          </div>
          <div class="col-md-3">
            <span class="fs-12"
              ><B>Borrower :</B> {{ loanSummary.Customer }}</span
            >
          </div>

          <div class="col-md-3">
            <span class="fs-12">
              <B>Tenure :</B>
              {{ loanSummary.Tenure > 0 ? loanSummary.Tenure : "" }} (
              {{ loanSummary.Tenure > 0 ? Tenure_Name : "" }})
            </span>
          </div>
          <div class="col-md-3">
            <span class="fs-12"
              ><B>Expiry Date :</B> {{ loanSummary.ExpiryDate }}</span
            >
          </div>
          <div class="col-md-3">
            <span class="fs-12"
              ><B>Close Date :</B> {{ loanSummary.CloseDate }}</span
            >
          </div>
          <div class="col-md-3">
            <span class="fs-12"
              ><B>Loan A/C Category :</B>
              {{ loanSummary.Loan_Acc_Category }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-tab-group class="Loan_Layout"
                 *ngIf="isGetDetail"
                 [selectedIndex]="currentTab"
                 (selectedTabChange)="onTabChange($event)">
    <mat-tab *ngIf="isNew || loanSummary.IsLoanInfoTab">
      <ng-template mat-tab-label>
        <span [ngClass]="
            loanSummary.IsFITab || loanSummary.IsTVRTab
              ? 'minwidth1'
              : 'minwidth2'
          ">Loan Info</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <app-lms-loan-info [Type]="Type"
                               [ApplicationId]="LoanId"
                               [isNew]="isNew"
                               [loanSummary]="loanSummary"
                               (action)="onChildAction($event)">
            </app-lms-loan-info>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="isNew || lastFillTab < CustomerCount" *ngIf="isNew || loanSummary.IsCustomerInfoTab">
      <ng-template mat-tab-label>
        <span [ngClass]="
            loanSummary.IsFITab || loanSummary.IsTVRTab
              ? 'minwidth1'
              : 'minwidth2'
          ">Customer Info</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <app-lms-customer-entry [loanSummary]="loanSummary"
                                    (action)="onChildAction($event)"></app-lms-customer-entry>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="isNew || lastFillTab < AccountCount" *ngIf="isNew || loanSummary.IsAccountInfoTab">
      <ng-template mat-tab-label>
        <span [ngClass]="
            loanSummary.IsFITab || loanSummary.IsTVRTab
              ? 'minwidth1'
              : 'minwidth2'
          ">Account Info</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <app-lms-account [loanSummary]="loanSummary"
                             (action)="onChildAction($event)"></app-lms-account>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="isNew || lastFillTab < AssetsCount"
             *ngIf="loanSummary.IsAssetTab">
      <ng-template mat-tab-label>
        <span [ngClass]="
            loanSummary.IsFITab || loanSummary.IsTVRTab
              ? 'minwidth1'
              : 'minwidth2'
          ">Asset Info</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <app-lms-asset-info [loanSummary]="loanSummary"
                                (action)="onChildAction($event)"></app-lms-asset-info>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="isNew || lastFillTab < CRCCount"
             *ngIf="loanSummary.IsCRCTab">
      <ng-template mat-tab-label>
        <span [ngClass]="
            loanSummary.IsFITab || loanSummary.IsTVRTab
              ? 'minwidth1'
              : 'minwidth2'
          ">CRC</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12">
            <app-lms-crc [loanSummary]="loanSummary"
                         (action)="onChildAction($event)"></app-lms-crc>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="isNew || lastFillTab < TVRCount"
             *ngIf="loanSummary.IsTVRTab">
      <ng-template mat-tab-label>
        <span [ngClass]="loanSummary.IsTVRTab ? 'minwidth1' : 'minwidth2'">TVR</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12">
            <app-lms-tvr [loanSummary]="loanSummary"
                         (action)="onChildAction($event)"></app-lms-tvr>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="isNew || lastFillTab < FICount"
             *ngIf="loanSummary.IsFITab">
      <ng-template mat-tab-label>
        <span [ngClass]="loanSummary.IsFITab ? 'minwidth1' : 'minwidth2'">FI</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12">
            <app-lms-fi [loanSummary]="loanSummary"
                        (action)="onChildAction($event)"></app-lms-fi>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="isNew || lastFillTab < IncomeCount"
             *ngIf="loanSummary.IsIncomeTab">
      <ng-template mat-tab-label>
        <span [ngClass]="minwidth1">Income & Exp.</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <app-lms-income-expenditure [loanSummary]="loanSummary"
                                        (action)="onChildAction($event)"></app-lms-income-expenditure>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="isNew || lastFillTab < BankAnalysCount"
             *ngIf="loanSummary.IsBankAnalysisTab">
      <ng-template mat-tab-label>
        <span [ngClass]="minwidth1">Bank Analysis</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <app-lms-bank-analysis [loanSummary]="loanSummary"
                                   (action)="onChildAction($event)"></app-lms-bank-analysis>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="isNew || lastFillTab < FOIRCount"
             *ngIf="loanSummary.IsFOIRTab">
      <ng-template mat-tab-label>
        <span [ngClass]="minwidth1">FOIR</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <app-lms-loan-foir [loanSummary]="loanSummary"
                               (action)="onChildAction($event)"></app-lms-loan-foir>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="isNew || lastFillTab < FamilyCount"
             *ngIf="loanSummary.IsFamilyTab">
      <ng-template mat-tab-label>
        <span [ngClass]="minwidth1">Family Member</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <app-lms-family-member [loanSummary]="loanSummary"
                                   (action)="onChildAction($event)"></app-lms-family-member>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="isNew || lastFillTab < ChequeCount"
             *ngIf="loanSummary.IsChequeTab">
      <ng-template mat-tab-label>
        <span [ngClass]="
            loanSummary.IsFITab || loanSummary.IsTVRTab
              ? 'minwidth1'
              : 'minwidth2'
          ">Cheque & Repayment</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12">
            <app-lms-cheque-details [loanSummary]="loanSummary"
                                    (action)="onChildAction($event)"></app-lms-cheque-details>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="isNew || lastFillTab < insuranceCount"
             *ngIf="loanSummary.IsinsuranceTab">
      <ng-template mat-tab-label>
        <span [ngClass]="
            loanSummary.IsFITab || loanSummary.IsTVRTab
              ? 'minwidth1'
              : 'minwidth2'
          ">Insurance</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12">
            <app-lms-insurance [loanSummary]="loanSummary"
                               (action)="onChildAction($event)"></app-lms-insurance>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="isNew || lastFillTab < PDCount"
             *ngIf="loanSummary.IsPDTab">
      <ng-template mat-tab-label>
        <span [ngClass]="
            loanSummary.IsFITab || loanSummary.IsTVRTab
              ? 'minwidth1'
              : 'minwidth2'
          ">Personal Discussion</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12">
            <app-lms-personal-discussion [loanSummary]="loanSummary"
                                         (action)="onChildAction($event)"></app-lms-personal-discussion>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="isNew || lastFillTab < SanctionConditionCount"
             *ngIf="loanSummary.IsSanctionConditionTab">
      <ng-template mat-tab-label>
        <span [ngClass]="
            loanSummary.IsFITab || loanSummary.IsTVRTab
              ? 'minwidth1'
              : 'minwidth2'
          ">Sanction Condition</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12">
            <app-lms-sanction-condition [loanSummary]="loanSummary"
                                        (action)="onChildAction($event)"></app-lms-sanction-condition>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="isNew || lastFillTab < FileCheckingCount"
             *ngIf="loanSummary.IsFileCheckingTab">
      <ng-template mat-tab-label>
        <span [ngClass]="
            loanSummary.IsFITab || loanSummary.IsTVRTab
              ? 'minwidth1'
              : 'minwidth2'
          ">File Checking</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12">
            <app-lms-file-checking [loanSummary]="loanSummary"
                                   (action)="onChildAction($event)"></app-lms-file-checking>
          </div>
        </div>
      </ng-template>
    </mat-tab>

    <!-- <mat-tab [disabled]="isNew||lastFillTab<5">
    <ng-template mat-tab-label>
      <span>DV Detail</span>
    </ng-template>
    <ng-template matTabContent>
      <h2>DV Detail</h2>
    </ng-template>
  </mat-tab> -->
    <mat-tab [disabled]="isNew || lastFillTab < AttachmentCount" *ngIf="loanSummary.IsAttachmentTab">
      <ng-template mat-tab-label>
        <span [ngClass]="
            loanSummary.IsFITab || loanSummary.IsTVRTab
              ? 'minwidth1'
              : 'minwidth2'
          ">Attachment</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <app-lms-attachment [loanSummary]="loanSummary"
                                (action)="onChildAction($event)"></app-lms-attachment>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab [disabled]="isNew || lastFillTab < AttachmentCount + 1" *ngIf="loanSummary.IsIsNoteRecommendationTab">
      <ng-template mat-tab-label>
        <span [ngClass]="
            loanSummary.IsFITab || loanSummary.IsTVRTab
              ? 'minwidth1'
              : 'minwidth2'
          ">Notes-Chat</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <app-lms-Note-Recommendation [loanSummary]="loanSummary"
                                         (action)="onChildAction($event)"></app-lms-Note-Recommendation>
          </div>
        </div>
      </ng-template>
    </mat-tab>


    <mat-tab [disabled]="isNew || lastFillTab < AttachmentCount + 2" *ngIf="loanSummary.IsLoanGenerateTab">
      <ng-template mat-tab-label>
        <span [ngClass]="
              loanSummary.IsFITab || loanSummary.IsTVRTab
                ? 'minwidth1'
                : 'minwidth2'
            ">Generate Loan No</span>
      </ng-template>
      <ng-template matTabContent>
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <app-lms-loanno-generate [loanSummary]="loanSummary"
                                     (action)="onChildAction($event)"></app-lms-loanno-generate>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <ng-container *ngIf="loanSummary.LoanNo">
      <mat-tab [disabled]="isNew || lastFillTab < AttachmentCount + 3" *ngIf="loanSummary.AmortizationChartTab">
        <ng-template mat-tab-label>
          <span [ngClass]="
              loanSummary.IsFITab || loanSummary.IsTVRTab
                ? 'minwidth1'
                : 'minwidth2'
            ">Amortization Chart</span>
        </ng-template>
        <ng-template matTabContent>
          <div class="row m-0">
            <div class="col-md-12 p-0">
              <app-lms-amortization [loanSummary]="loanSummary"
                                    (action)="onChildAction($event)"></app-lms-amortization>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab [disabled]="isNew || lastFillTab < AttachmentCount + 3" *ngIf="loanSummary.DisbursmentDetailTab">
        <ng-template mat-tab-label>
          <span [ngClass]="
              loanSummary.IsFITab || loanSummary.IsTVRTab
                ? 'minwidth1'
                : 'minwidth2'
            ">DV Detail</span>
        </ng-template>
        <ng-template matTabContent>
          <div class="row m-0">
            <div class="col-md-12 p-0">
              <app-lms-loan-disbursment-detail [loanSummary]="loanSummary"
                                               (action)="onChildAction($event)"></app-lms-loan-disbursment-detail>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab [disabled]="isNew || lastFillTab < AttachmentCount + 3" *ngIf="loanSummary.ActivityLogTab">
        <ng-template mat-tab-label>
          <span [ngClass]="
              loanSummary.IsFITab || loanSummary.IsTVRTab
                ? 'minwidth1'
                : 'minwidth2'
            ">Activity Log</span>
        </ng-template>
        <ng-template matTabContent>
          <div class="row m-0">
            <div class="col-md-12 p-0">
              <app-lms-case-history [loanSummary]="loanSummary"
                                    (action)="onChildAction($event)"></app-lms-case-history>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab [disabled]="isNew || lastFillTab < AttachmentCount + 3" *ngIf="loanSummary.LoanStatementTab">
        <ng-template mat-tab-label>
          <span [ngClass]="
              loanSummary.IsFITab || loanSummary.IsTVRTab
                ? 'minwidth1'
                : 'minwidth2'
            ">Loan Statement</span>
        </ng-template>
        <ng-template matTabContent>
          <div class="row m-0">
            <div class="col-md-12 p-0">
              <app-lms-loanStatement [loanSummary]="loanSummary"
                                     (action)="onChildAction($event)"></app-lms-loanStatement>
            </div>
          </div>
        </ng-template>
      </mat-tab>


    </ng-container>
  </mat-tab-group>
</section>

<div
  class="modal fade in"
  id="noteModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="max-width: 1140px"
  >
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Pending Loan Note Recommendation
        </h6>
        <button
          type="button"
          (click)="OnNoteClose()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 mt-2">
          <div class="col-md-12">
            <div class="table-responsive mt-1">
              <mat-table
                [dataSource]="dataSourceNotes"
                matSort
                style="height: 250px; max-width: 100%; overflow: auto"
              >
                <ng-container matColumnDef="Id">
                  <mat-header-cell class="grid-header mw50" *matHeaderCellDef
                    >#</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let row; let i = index"
                    class="grid-cell mw50"
                    >{{ i + 1 }}</mat-cell
                  >
                </ng-container>
                <ng-container matColumnDef="Type">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Type
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Remarks">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Remarks</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Remarks
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Executive">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Created By</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.CreatedBy
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="CreatedOn">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Created On</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.CreatedOn
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Title">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Title</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Title
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Date">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >FollowUp Date</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Date
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="FollowUpBy">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Followup By</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.FollowupBy_Name
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="CompletedOn">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Completed On</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.CompletedOn
                  }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Status">
                  <mat-header-cell class="grid-header" *matHeaderCellDef
                    >Status</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="grid-cell">{{
                    row.Status
                  }}</mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumnsNotes; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumnsNotes"
                ></mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
