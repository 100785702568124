<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div>
    <form #df="ngForm" novalidate (keyup.enter)="onSearchProcess()">
      <div class="row m-0 col-md-12 p-0 my-3 formborder">
        <div class="col-md-2">
          <span>Status</span>
          <select
            name="SearchStatus"
            id="SearchStatus"
            [(ngModel)]="SearchStatus"
            (change)="StatusChange()"
            class="form-control input-text-css"
          >
            <option value="Pending">Pending</option>
            <option value="All">All</option>
            <option value="Post">Post</option>
            <option value="Bounce">Bounce</option>
            <option value="Clear">Clear</option>
            <option value="Hold">Hold</option>
          </select>
        </div>
        <div class="col-md-2" *ngIf="SearchStatus == 'Post'">
          <span>Post Date From</span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="PostDateFrom"
              id="PostDateFrom"
              class="form-control input-text-css"
              [(ngModel)]="PostDateFrom"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
        <div class="col-md-2" *ngIf="SearchStatus == 'Post'">
          <span>Post Date To</span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker2"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="PostDateTo"
              id="PostDateTo"
              class="form-control input-text-css"
              [(ngModel)]="PostDateTo"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </div>
          <!-- <input
      name="PostDateTo"
      [(ngModel)]="PostDateTo"
      id="PostDateTo"
      placeholder="Post To"
      class="form-control input-text-css"
      bsDatepicker
    /> -->
        </div>
        <div class="col-md-2" *ngIf="SearchStatus == 'Bounce'">
          <span>Bounce Date From</span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker3"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="BounceDateFrom"
              id="BounceDateFrom"
              class="form-control input-text-css"
              [(ngModel)]="BounceDateFrom"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker3"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </div>
          <!-- <input
      name="BounceDateFrom"
      [(ngModel)]="BounceDateFrom"
      id="BounceDateFrom"
      placeholder="Bounce From"
      class="form-control input-text-css"
      bsDatepicker
    /> -->
        </div>
        <div class="col-md-2" *ngIf="SearchStatus == 'Bounce'">
          <span>Bounce Date To</span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker4"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="BounceDateTo"
              id="BounceDateTo"
              class="form-control input-text-css"
              [(ngModel)]="BounceDateTo"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker4"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
          </div>

          <!-- <input
      name="BounceDateTo"
      [(ngModel)]="BounceDateTo"
      id="BounceDateTo"
      placeholder="Bounce To"
      class="form-control input-text-css"
      bsDatepicker
    /> -->
        </div>
        <div class="col-md-2" *ngIf="SearchStatus == 'Clear'">
          <span>Clear Date From</span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker5"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="ClearDateFrom"
              id="ClearDateFrom"
              class="form-control input-text-css"
              [(ngModel)]="ClearDateFrom"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker5"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker5></mat-datepicker>
          </div>
          <!-- <input
      name="ClearDateFrom"
      [(ngModel)]="ClearDateFrom"
      id="ClearDateFrom"
      placeholder="Clear From"
      class="form-control input-text-css"
      bsDatepicker
    /> -->
        </div>
        <div class="col-md-2" *ngIf="SearchStatus == 'Clear'">
          <span>Clear Date To</span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker6"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="ClearDateTo"
              id="ClearDateTo"
              class="form-control input-text-css"
              [(ngModel)]="ClearDateTo"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker6"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker6></mat-datepicker>
          </div>
        </div>
        <div class="col-md-2" *ngIf="SearchStatus == 'Hold'">
          <span>Hold Date From</span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker7"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="HoldDateFrom"
              id="HoldDateFrom"
              class="form-control input-text-css"
              [(ngModel)]="HoldDateFrom"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker7"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker7></mat-datepicker>
          </div>
        </div>
        <div class="col-md-2" *ngIf="SearchStatus == 'Hold'">
          <span>Hold Date To</span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker8"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="HoldDateTo"
              id="HoldDateTo"
              class="form-control input-text-css"
              [(ngModel)]="HoldDateTo"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker8"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker8></mat-datepicker>
          </div>
        </div>
        <div
          [ngClass]="{
            'col-md-2': SearchStatus != 'Pending' && SearchStatus != '',
            'col-md-4': SearchStatus == 'Pending' || SearchStatus == '',
            'is-invalid alert-warning': DueDateFrom == null || DueDateFrom == ''
          }"
        >
          <span for="DueDateFrom">Due Date From</span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker9"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="DueDateFrom"
              id="DueDateFrom"
              class="form-control input-text-css"
              [(ngModel)]="DueDateFrom"
              [ngClass]="{
                'is-invalid alert-warning':
                  DueDateFrom == null || DueDateFrom == ''
              }"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker9"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker9></mat-datepicker>
          </div>
        </div>
        <div
          [ngClass]="{
            'col-md-2': SearchStatus != 'Pending' && SearchStatus != '',
            'col-md-4': SearchStatus == 'Pending' || SearchStatus == '',
            'is-invalid alert-warning': DueDateTo == null || DueDateTo == ''
          }"
        >
          <span for="DueDateTo">Due Date To</span>
          <div class="datepicker_feild">
            <input
              [matDatepicker]="picker10"
              dateConvert
              placeholder="DD/MM/YYYY"
              name="DueDateTo"
              id="DueDateTo"
              class="form-control input-text-css"
              [(ngModel)]="DueDateTo"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker10"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker10></mat-datepicker>
          </div>
        </div>
        <div class="col-md-3" *ngIf="SearchStatus != 'Pending'">
          <span>Company Bank</span>
          <select
            name="BankInCompany"
            id="BankInCompany"
            #refPresentBankInCompany="ngModel"
            [(ngModel)]="BankInCompany"
            class="form-control input-text-css"
          >
            <option value="" selected>Select Present Bank In Company</option>
            <option
              *ngFor="let bank of BankList"
              [value]="bank.AccountId + '_' + bank.Account_Name"
            >
              {{ bank.Account_Name }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <span>Source Branch</span>
          <select
            name="SelectBranch"
            id="SelectBranch"
            #refSelectGender="ngModel"
            [(ngModel)]="SelectBranch"
            class="form-control input-text-css"
          >
            <option value="" selected>Select Source Branch</option>
            <option
              *ngFor="let branch of BranchesList"
              [value]="branch.BranchId"
            >
              {{ branch.Branch_Name }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <span>Product</span>
          <select
            name="SelectProduct"
            id="SelectProduct"
            #refSelectGender="ngModel"
            [(ngModel)]="SelectProduct"
            class="form-control input-text-css"
          >
            <option value="" selected>Select Product-</option>
            <option
              *ngFor="let product of ProductList"
              [value]="product.ProductId"
            >
              {{ product.Product }}
            </option>
          </select>
        </div>
        <div class="col-md-3" *ngIf="SearchStatus != 'Pending'">
          <span>Customer Bank</span>
          <input
            name="CustomerBank"
            [(ngModel)]="CustomerBank"
            id="CustomerBank"
            placeholder="Customer Bank"
            class="form-control input-text-css"
          />
        </div>

        <div class="col-md-3">
          <form action="" class="search-text mt-2">
            <div class="bg-light rounded rounded-pill shadow-sm">
              <div class="input-group">
                <input
                  type="text"
                  id="FilterInputSearch"
                  (keyup)="applyFilter($event.target.value)"
                  name="FilterInputSearch"
                  placeholder="What're you searching for?"
                  class="form-control border-0 bg-light"
                />
                <div class="input-group-append">
                  <button
                    id="button-addon1"
                    type="submit"
                    class="btn btn-link text-primary"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-1">
          <button
            [disabled]="df.form.invalid"
            type="button"
            (click)="onSearchProcess()"
            class="mt-3 btn font-size-12 button-btn"
          >
            Search
          </button>
        </div>
        <div class="col-md-1 justify-content-end">
          <button
            type="button"
            (click)="exportExcel()"
            class="ml-3 mt-3 btn font-size-12 button-btn"
          >
            Export
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="mt-3" *ngIf="dataSourceLoan">
    <div class="">
      <div class="table-responsive">
        <mat-table
          [dataSource]="dataSourceLoan"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          [hiddenColumns]="[displayedColumnsLoan.indexOf('Assign')]"
          style="overflow: auto; max-width: 100%; height: 50vh"
        >
          <ng-container matColumnDef="Id" sticky>
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell FreezColumn"
              style="min-width: 50px; max-height: 5px"
            >
              {{ i + 1 }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="CaseNo" sticky>
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan No.</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell FreezColumn">{{
              row.LoanAcNo
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="CustomerName" sticky>
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="width: 60px"
              *matHeaderCellDef
              >Customer Name</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              style="width: 60px"
              class="grid-cell FreezColumn"
            >
              {{ row.CustomerName }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="margin-left: 16px"
              >Total:
            </mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="EMINo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="min-width: 65px"
              *matHeaderCellDef
              >EMI No</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell"
              style="min-width: 65px"
            >
              {{ row.Period }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="Customer_Address">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Customer Address
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Customer_Address }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="Customer_PhoneNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Customer Phone No
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
             
              <div *ngIf="currentUser.IsMaskingMobile">
                {{ row.Customer_PhoneNo | mask }}
              </div>

              <div *ngIf="!currentUser.IsMaskingMobile">
                {{ row.Customer_PhoneNo }}
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="Loan_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Case Date
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Loan_Date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="ChqueNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Cheque No.</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.ChequeNo }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="EMI_Amount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >EMI Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.EMI_Amount }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{
              calculateTotal()
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="DueDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Due Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.DueDateFormat }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="PostDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              style="min-width: 130px"
              >Post Date</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell j-c-center"
              style="min-width: 130px"
            >
              <!--<input name="PostDate{{row.Id}}" id="PostDate{{row.Id}}" style="height: calc(1.0em + 0.25rem + 1px) !important; width:90px;" disabled={{row.Posthold}} [(ngModel)]="row.PostDate" (ngModelChange)="PostDateChange(row)"
      placeholder="DD/MM/YYYY" [minDate]="minDate" refPostDate="ngModel" class="form-control input-text-css" bsDatepicker>-->
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker11"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="PostDate{{ row.Id }}"
                  [min]="minDate"
                  id="PostDate{{ row.Id }}"
                  disabled="{{ row.Posthold }}"
                  [(ngModel)]="row.PostDate"
                  (ngModelChange)="PostDateChange(row)"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker11"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker11></mat-datepicker>
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="ComapnyAccount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Company Account</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.ComapnyAccount }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Clearing">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Clearing/Bounce</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <mat-select
                [(value)]="row.ClearingType"
                style="
                  height: calc(1.3em + 0.25rem + 1px) !important;
                  padding: 0;
                  text-align: center;
                "
                name="type{{ row.Id }}"
                id="type{{ row.Id }}"
                disabled="{{ row.Clearinghold }}"
                class="form-control input-text-css"
                (selectionChange)="ClearingChange($event, row)"
              >
                <mat-option value=""> select </mat-option>
                <mat-option value="Bounce"> Bounce </mat-option>
                <mat-option value="Clear"> Clear </mat-option>
              </mat-select>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="ClearBounceDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Clear/ Bounce Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.ClearBounceDate }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="BounceReason">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Bounce Reason</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.BounceReason }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="BounceCharge">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Bounce Charge</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.BounceCharge }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{
              calculateBounceTotal()
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Undo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >HOLD/UNDO</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
              <ng-container *ngIf="loanSummary == undefined">
                <button
                  type="button"
                  *ngIf="
                    (row.IsStop == 0 ||
                      row.IsStop == '' ||
                      row.IsStop == null) &&
                    row.PostDate == '' &&
                    row.RepresentId == 0
                  "
                  (click)="onHold(row)"
                  style="
                    background-color: #ee2005;
                    color: #ffffff;
                    line-height: 15px !important;
                    font-size: 11px;
                    margin: 4px;
                  "
                  mat-raised-button
                  color="primary"
                >
                  HOLD
                </button>
                <button
                  type="button"
                  (click)="Undo(row)"
                  *ngIf="
                    row.Status != '' &&
                    (row.RepresentId == 0 || row.RepresentId == null)
                  "
                  mat-raised-button
                  color="primary"
                  style="
                    background-color: #506b97;
                    color: #ffffff;
                    font-size: 11px;
                    line-height: 18px !important;
                    margin: 4px;
                  "
                >
                  UNDO
                </button>
              </ng-container>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="HoldDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Hold Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.HoldDate }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="HoldReason">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Hold Reason</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.HoldReason }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Represent">
            <mat-header-cell
              mat-sort-headRepresenter
              class="grid-header"
              *matHeaderCellDef
              >Represent</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <button
                type="button"
                *ngIf="
                  (row.RepresentId == 0 ||
                    row.RepresentId == '' ||
                    row.RepresentId == null) &&
                  row.Status == 'Bounce'
                "
                (click)="onRepresent(row)"
                class="btn font-size-12 button-btn represent"
                mat-raised-button
                color="primary"
              >
                Represent
              </button>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="displayedColumnsLoan; sticky: true"
          ></mat-header-row>
          <mat-row
            (click)="highlightRow(row)"
            [ngClass]="{
              Post: row.Status == 'Post',
              Hold: row.Status == 'Hold',
              Bounce: row.Status == 'Bounce',
              Clear: row.Status == 'Clear',
              duedate: DateConvert(row.DueDate, row.Status),
              highlight: row.Id == selectedId
            }"
            *matRowDef="let row; columns: displayedColumnsLoan"
          ></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumnsLoan; sticky: true"
            style="background: #4dc3a3"
          ></mat-footer-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="PostDetailModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-bg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Post Voucher
        </h6>
        <button
          type="button"
          (click)="onClosePostVoucher()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #f="ngForm"
          (ngSubmit)="onPost()"
          novalidate
          class="row m-0 col-md-12 p-0"
        >
          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-12">
              <span>Present Bank In Company</span>
              <select
                name="PresentBankInCompany"
                id="PresentBankInCompany"
                #refPresentBankInCompany="ngModel"
                [(ngModel)]="PresentBankInCompany"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': f.submitted && refPresentBankInCompany.invalid,
                  'alert-warning':
                    refPresentBankInCompany.invalid &&
                    (refPresentBankInCompany.dirty ||
                      refPresentBankInCompany.touched ||
                      refPresentBankInCompany.untouched)
                }"
              >
                <option value="" selected>
                  Select Present Bank In Company
                </option>
                <option *ngFor="let bank of BankList" [value]="bank.AccountId">
                  {{ bank.Account_Name }}
                </option>
              </select>
            </div>
            <div class="col-md-12">
              <span>Select Cheque</span>
              <select
                name="Cheque"
                id="Cheque"
                #refCheque="ngModel"
                [(ngModel)]="ChequeDetail"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': f.submitted && refCheque.invalid,
                  'alert-warning':
                    refCheque.invalid &&
                    (refCheque.dirty ||
                      refCheque.touched ||
                      refCheque.untouched)
                }"
              >
                <option value="" selected>Select Cheque</option>
                <option
                  *ngFor="let Cheque of ChequeList"
                  [value]="Cheque.Chq_No"
                >
                  {{ Cheque.Chq }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-3"></div>
            <div class="col-md-3">
              <button
                type="button"
                [disabled]="!f.form.valid"
                (click)="onPost()"
                [class.spinner]="showSpinner"
                class="btn font-size-12 button-btn"
              >
                Yes
              </button>
            </div>
            <div class="col-md-3">
              <button
                type="button"
                (click)="onClosePostVoucher()"
                class="btn font-size-12 button-btn"
                [class.spinner]="showSpinner"
              >
                No
              </button>
            </div>
            <div class="col-md-3"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="HoldDetailModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Hold Details
        </h6>
        <button
          type="button"
          (click)="onCloseHoldDetail()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #ff="ngForm"
          (ngSubmit)="onHoldOk()"
          novalidate
          class="row m-0 col-md-12 p-0"
        >
          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-6">
              <span>Hold Date</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker12"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="HoldDate"
                  [(ngModel)]="HoldDate"
                  required
                  id="HoldDate"
                  class="form-control input-text-css"
                  #refHoldDate="ngModel"
                  [ngClass]="{
                    'is-invalid': ff.submitted && refHoldDate.invalid,
                    'alert-warning':
                      refHoldDate.invalid &&
                      (refHoldDate.dirty ||
                        refHoldDate.touched ||
                        refHoldDate.untouched)
                  }"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker12"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker12></mat-datepicker>
              </div>
              <!-- <input
                name="HoldDate"
                [(ngModel)]="HoldDate"
                required
                id="HoldDate"
                placeholder="DD/MM/YYYY"
                #refHoldDate="ngModel"
                [ngClass]="{
                  'is-invalid': ff.submitted && refHoldDate.invalid,
                  'alert-warning':
                    refHoldDate.invalid &&
                    (refHoldDate.dirty ||
                      refHoldDate.touched ||
                      refHoldDate.untouched)
                }"
                class="form-control input-text-css"
                bsDatepicker
              /> -->
            </div>
            <div class="col-md-6">
              <span>Hold Reason</span>
              <input
                name="HoldReason"
                [(ngModel)]="HoldReason"
                required
                id="HoldReason"
                placeholder="Hold Reason"
                #refHoldReason="ngModel"
                [ngClass]="{
                  'is-invalid': ff.submitted && refHoldReason.invalid,
                  'alert-warning':
                    refHoldReason.invalid &&
                    (refHoldReason.dirty ||
                      refHoldReason.touched ||
                      refHoldReason.untouched)
                }"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row m-0 mt-3 col-md-12 p-0 justify-content-end">
            <button
              type="button"
              (click)="onHoldOk()"
              [class.spinner]="showSpinner"
              class="btn font-size-12 button-btn"
              [disabled]="!ff.form.valid"
            >
              OK
            </button>
            <button
              type="button"
              (click)="onCloseHoldDetail()"
              class="btn font-size-12 button-btn"
              [class.spinner]="showSpinner"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="BounceDetailModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ Type }} Details
        </h6>
        <button
          type="button"
          (click)="onCloseBounceDetail()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #fr="ngForm"
          (ngSubmit)="onBounce()"
          novalidate
          class="row m-0 col-md-12 p-0"
        >
          <div class="row m-0 col-md-12 p-0" *ngIf="Type == 'Bounce'">
            <div class="col-md-4">
              <span>Bounce Date</span>

              <div class="datepicker_feild">
                <input
                  [matDatepicker]="pickerminBounceDate"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  required
                  name="BounceDate"
                  [min]="minClearDate"
                  id="BounceDate"
                  #refBounceDate="ngModel"
                  [ngClass]="{
                    'is-invalid': fr.submitted && refBounceDate.invalid,
                    'alert-warning':
                      refBounceDate.invalid &&
                      (refBounceDate.dirty ||
                        refBounceDate.touched ||
                        refBounceDate.untouched)
                  }"
                  [(ngModel)]="BounceDate"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="pickerminBounceDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerminBounceDate></mat-datepicker>
              </div>
            </div>
            <div class="col-md-4">
              <span>Bounce Reason</span>

              <select
                name="BounceReason"
                id="BounceReason"
                #refBounceReason="ngModel"
                [(ngModel)]="BounceReason"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid': fr.submitted && refBounceReason.invalid,
                  'alert-warning':
                    refBounceReason.invalid &&
                    (refBounceReason.dirty ||
                      refBounceReason.touched ||
                      refBounceReason.untouched)
                }"
              >
                <option value="" selected>Select Bounce Reason</option>
                <option
                  *ngFor="let BounceR of Bounce_ReasonList"
                  [value]="BounceR.Value"
                >
                  {{ BounceR.Value }}
                </option>
              </select>
            </div>
            <div class="col-md-4">
              <span>Bounce Charge</span>
              <input
                name="BounceCharge"
                [(ngModel)]="BounceCharge"
                #refBounceCharge="ngModel"
                id="refBounceCharge"
                class="form-control input-text-css"
                disabled
              />
            </div>
          </div>
          <div
            class="row m-0 mt-3 col-md-12 p-0 justify-content-end"
            *ngIf="Type == 'Bounce'"
          >
            <button
              type="button"
              [disabled]="!fr.form.valid"
              (click)="onBounce()"
              [class.spinner]="loading"
              class="btn font-size-12 button-btn"
            >
              Save
            </button>
            <button
              type="button"
              (click)="onCloseBounceDetail()"
              class="btn font-size-12 button-btn"
              [class.spinner]="loading"
            >
              Cancel
            </button>
          </div>
        </form>
        <form
          #fC="ngForm"
          (ngSubmit)="onClear()"
          novalidate
          class="row m-0 col-md-12 p-0"
        >
          <div class="row m-0 col-md-12 p-0" *ngIf="Type == 'Clear'">
            <div class="col-md-6">
              <span>Clear Date</span>

              <div class="datepicker_feild">
                <input
                  [matDatepicker]="pickerminClearDate"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  required
                  name="ClearDate1"
                  [min]="minClearDate"
                  id="ClearDate1"
                  #refClearDate="ngModel"
                  [ngClass]="{
                    'is-invalid': fC.submitted && refClearDate.invalid,
                    'alert-warning':
                      refClearDate.invalid &&
                      (refClearDate.dirty ||
                        refClearDate.touched ||
                        refClearDate.untouched)
                  }"
                  [(ngModel)]="ClearDate"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="pickerminClearDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerminClearDate></mat-datepicker>
              </div>
            </div>
            <div class="col-md-6 justify-content-end" *ngIf="Type == 'Clear'">
              <button
                type="button"
                [disabled]="!fC.form.valid"
                (click)="onClear()"
                [class.spinner]="loading"
                class="btn font-size-12 button-btn"
              >
                Save
              </button>
              <button
                type="button"
                (click)="onCloseBounceDetail()"
                class="btn font-size-12 button-btn"
                [class.spinner]="loading"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="RepresentModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-bg modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Represent
        </h6>
        <button
          type="button"
          (click)="onCloseRepresent()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #fff="ngForm"
          (ngSubmit)="onRepresentBounce()"
          novalidate
          class="row m-0 col-md-12 p-0"
        >
          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-12" *ngIf="IsnextEMIData">
              <span class="form-check-inline">
                <label class="form-check-label" for="Group_IsSameEMI">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="Group_IsSameEMI"
                    name="Group_IsEMI"
                    (change)="ChangeIsSameEMI('1')"
                    #refIsSameEMI="ngModel"
                    [ngClass]="{
                      'is-invalid': fff.submitted && refIsSameEMI.invalid,
                      'alert-warning':
                        refIsSameEMI.invalid &&
                        (refIsSameEMI.dirty ||
                          refIsSameEMI.touched ||
                          refIsSameEMI.untouched)
                    }"
                    [(ngModel)]="IsSameEMI"
                    required
                    value="1"
                  />
                  Same EMI
                </label>
              </span>
              <span class="form-check-inline" *ngIf="IsnextEMIData">
                <label class="form-check-label" for="Group_IsOtherEMI">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="Group_IsOtherEMI"
                    name="Group_IsEMI"
                    (change)="ChangeIsSameEMI('0')"
                    #refIsSameEMI="ngModel"
                    [ngClass]="{
                      'is-invalid': fff.submitted && refIsSameEMI.invalid,
                      'alert-warning':
                        refIsSameEMI.invalid &&
                        (refIsSameEMI.dirty ||
                          refIsSameEMI.touched ||
                          refIsSameEMI.untouched)
                    }"
                    [(ngModel)]="IsSameEMI"
                    required
                    value="0"
                  />
                  Next EMI
                </label>
              </span>
            </div>
            <div class="col-md-12">
              <span>Post Date</span>
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  required
                  name="RepresentPostDate"
                  [min]="minRepresentPostDate"
                  id="RepresentPostDate"
                  #refRepresentPostDate="ngModel"
                  [ngClass]="{
                    'is-invalid': fff.submitted && refRepresentPostDate.invalid,
                    'alert-warning':
                      refRepresentPostDate.invalid &&
                      (refRepresentPostDate.dirty ||
                        refRepresentPostDate.touched ||
                        refRepresentPostDate.untouched)
                  }"
                  [(ngModel)]="RepresentPostDate"
                  (ngModelChange)="RepresentPostDateChange()"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
            </div>
            <div class="col-md-12">
              <span>Present Bank In Company</span>
              <select
                name="PresentBankInCompany12"
                id="PresentBankInCompany12"
                #refPresentBankInCompany="ngModel"
                [(ngModel)]="PresentBankInCompany"
                class="form-control input-text-css"
                required
                [ngClass]="{
                  'is-invalid':
                    fff.submitted && refPresentBankInCompany.invalid,
                  'alert-warning':
                    refPresentBankInCompany.invalid &&
                    (refPresentBankInCompany.dirty ||
                      refPresentBankInCompany.touched ||
                      refPresentBankInCompany.untouched)
                }"
              >
                <option value="" selected>
                  Select Present Bank In Company
                </option>
                <option *ngFor="let bank of BankList" [value]="bank.AccountId">
                  {{ bank.Account_Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row m-0 mt-3" *ngIf="IsSameEMI == '0'">
            <div class="col-md-3">
              <h6 class="fs-12">Next EMI</h6>
              <p class="lead fs-12 fw-7">{{ nextEMIData.Period }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Next EMI Amount</h6>
              <p class="lead fs-12 fw-7">{{ nextEMIData.Emi_Amount }}</p>
            </div>
            <div class="col-md-3">
              <h6 class="fs-12">Next Due Date</h6>
              <p class="lead fs-12 fw-7">{{ nextEMIData.DueDate }}</p>
            </div>
          </div>

          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-3"></div>
            <div class="col-md-3">
              <button
                type="button"
                [disabled]="!fff.form.valid"
                *ngIf="IsRepresentAllow"
                (click)="onRepresentBounce()"
                [class.spinner]="showSpinner"
                class="btn font-size-12 button-btn"
              >
                Yes
              </button>
            </div>
            <div class="col-md-3">
              <button
                type="button"
                (click)="onCloseRepresent()"
                class="btn font-size-12 button-btn"
                [class.spinner]="showSpinner"
              >
                No
              </button>
            </div>
            <div class="col-md-3"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
