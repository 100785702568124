<div
  class="formborder row m-0 mt-1 p-1 align-items-center justify-content-between"
>
  <div class="col-md-5">
    <h6 class="m-0">Sanction Conditions</h6>
  </div>

  <div class="">
    <button
      type="button"
      class="btn font-size-12 button-btn"
      (click)="addSanctionConditions()"
      *ngIf="PageAcess && PageAcess[0].Add == 1"
    >
      Add Sanction Conditions
    </button>
  </div>
</div>

<table class="table table-bordered mt-2" id="Sanction">
  <thead>
    <tr>
      <th>Header</th>
      <th class="WD-66">Details</th>
      <th class="WD-16">Completed</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let vdData of Headers; let i = index">
      <td>{{ vdData.Header }}</td>
      <td>
        <table>
          <tr *ngFor="let data of vdData.Condition; let i = index">
            <td>{{ data.Detail }}</td>
          </tr>
        </table>
      </td>
      <td
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <mat-checkbox
          name="Account_IsLoanChargeable"
          [(ngModel)]="vdData.IsCompleted"
        >
        </mat-checkbox>
        <button
          type="button"
          id="Edit"
          (click)="OnEditSanctionConditions(vdData)"
          class="ml-4 btn font-size-12 button-btn"
          mat-raised-button
          [class.spinner]="loading"
          *ngIf="PageAcess && PageAcess[0].Edit == 1"
        >
          Edit
        </button>
        <button
          type="button"
          id="Delete"
          (click)="OnDeleteSanctionConditions(vdData.Id)"
          class="ml-4 btn font-size-12 button-btn"
          mat-raised-button
          [class.spinner]="loading"
          *ngIf="PageAcess && PageAcess[0].Delete == 1"
        >
          Delete
        </button>
      </td>
    </tr>
  </tbody>
</table>

<div
  class="modal fade in"
  id="SanctionConditions"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ Value }} Sanction Conditions
        </h6>
        <button
          type="button"
          (click)="onCloseSanctionConditions()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-body">
          <form #f="ngForm" novalidate class="formborder">
            <div class="row m-0 mt-1">
              <div class="col-md-12">
                <span class="required-lable"> Header </span>
                <input
                  type="text"
                  #refHeader="ngModel"
                  required
                  [(ngModel)]="Header"
                  placeholder="Header"
                  name="Header"
                  id="Header"
                  class="form-control input-text-css"
                  [ngClass]="{
                    'is-invalid': f.submitted && refHeader.invalid,
                    'alert-warning':
                      refHeader.invalid &&
                      (refHeader.dirty ||
                        refHeader.touched ||
                        refHeader.untouched)
                  }"
                />
              </div>
            </div>
            <form #df="ngForm" novalidate>
              <div
                class="row m-0 add-remove"
                *ngFor="let vdData of SanctionConditionsDetails; let i = index"
                style="margin-top: 8px !important"
              >
                <div class="col-md-12 row m-0 type-column">
                  <div
                    [ngClass]="{ 'bg-success1': i == 0 }"
                    *ngIf="i == 0"
                    style="width: 100%"
                  >
                    Conditions
                  </div>
                  <input
                    type="text"
                    name="Conditions{{ i }}"
                    id="Conditions{{ i }}"
                    class="form-control input-text-css"
                    #refConditions="ngModel"
                    required
                    [ngClass]="{
                      'is-invalid': df.submitted && refConditions.invalid,
                      'alert-warning':
                        refConditions.invalid &&
                        (refConditions.dirty ||
                          refConditions.touched ||
                          refConditions.untouched),
                      'mt-1': i == 0
                    }"
                    [(ngModel)]="vdData.Detail"
                  />
                </div>
                <button
                  type="button"
                  class="remove"
                  (click)="removeData(i)"
                  [ngClass]="{ 'no-head': i != 0 }"
                  *ngIf="SanctionConditionsDetails.length > 1"
                >
                  -
                </button>
                <button
                  type="button"
                  class="addmore"
                  (click)="addMoreData(i)"
                  [ngClass]="{
                    both: SanctionConditionsDetails.length > 1,
                    'no-head': i != 0
                  }"
                  [disabled]="!df.form.valid"
                  *ngIf="i == SanctionConditionsDetails.length - 1"
                >
                  +
                </button>
              </div>
              <div class="row col-md-12 m-0 mt-3 justify-content-end">
                <button
                  type="button"
                  (click)="onCloseSanctionConditions()"
                  class="btn font-size-12 button-btn"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  id="btnSanctionConditions"
                  #btnRegisterNewCustomer
                  (click)="OnSaveSanctionConditions()"
                  class="ml-4 btn font-size-12 button-btn"
                  [class.spinner]="loading"
                  [disabled]="!f.form.valid && !df.form.valid"
                >
                  {{ BtnValue }}
                </button>
              </div>
            </form>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-md-12">
  <button
    type="button"
    (click)="onNext()"
    class="ml-4 mt-2 btn font-size-12 button-btn"
    [class.spinner]="loading"
    style="float: right"
  >
    Next <i class="fa fa-arrow-right"></i>
  </button>
</div>
