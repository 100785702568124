import { Component, OnInit, ViewChild } from "@angular/core";
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataSharingService } from '../../AuthGuard/DataSharingService';
import { MasterService } from '../../Shared/app.Masters.Service';
import { LmsService } from '../../_Lms/services/lms.service';
import { SnackbarComponent } from '../../snackbar/snackbar.component';
import { Router, ActivatedRoute, Params } from '@angular/router';
declare var $: any;
import { ToWords } from 'to-words';
import { LosService } from '../../_LOS/services/los.service';
import * as moment from 'moment';
import { PartnerService } from "../../_Partner/services/partner.service";
import { constantUrl } from "../../Shared/constantUrl";


@Component({
  selector: 'app-journal-voucher',
  templateUrl: './journal-voucher.component.html',
  styleUrls: ['./journal-voucher.component.scss']
})
export class JournalVoucherComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  loading: boolean = false;
  CurrentDate: any = new Date();
  configSuccess: MatSnackBarConfig = {
    panelClass: 'style-success',
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  docModal: any = { DocumentImages: [] };
  today: Date = new Date();
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  accountTagDropdown: any[] = [];
  chargesHeadDropdown: any[] = [];
  accountHeadDropdown: any[] = [];
  empBranchDropdown: any[] = [];
  selectedData: any = {};
  dataSource: any = new MatTableDataSource([]);
  displayedColumns: string[] = ['CustomerId', 'Type', 'Application_No', 'LoanAcNo', 'CustomertName', 'FatherName', 'Customer_Gender', 'GenderAge', 'PhoneNo', 'EditAction'];
  Customer: any = {};
  Type: any = "0";
  CustomerList: any[] = [];
  totalCalculator: any;
  AccountingSelect: any = {};
  Data_FreezeDate: any;
  currentindex: any;
  selectedSearchIndex:any;
  Days_AllowedDateMin: any = new Date();
  Days_AllowedDateMax: any = new Date();
  CheckDate: any;
  Days_Allowed: any;
  IsGSTEnable: any;
  GSTModel: any = {};
  AccountHeadForGST: any[] = [];
  stateDropdown: any[] = [];
  slabList: any[] = [];
  voucherAdd: boolean = true;
  Tax_Slab_Data: any[] = [];
  Bind_Voucher: any = 1;

  SelectType : any = '';
  BranchesList: any = [];
  SalesExecutiveData: any = [];
  CollectionExecutiveData: any = []; 
  SourceAreaData: any = [];
  areaData: any = [];
  collectionArea: any = [];
  subAreaData: any = [];
  AccCatDropdown: any[] = [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  FyearId: any;
  maxDate: any;
  Min_Date: any;

  AllowExtension: any = constantUrl.AllowExtension;

  constructor(
    private _Route: Router, private dataSharingService: DataSharingService, private _Partnerservice: PartnerService,
    public snackBar: MatSnackBar, private _MasterService: MasterService,
    private lmsService: LmsService,
    private losService: LosService,
    private route: ActivatedRoute,
  ) {
    this.CurrentDate = new Date();
    this.dataSharingService.HeaderTitle.next("Voucher Journal");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  }

  ngOnInit() {


    this._MasterService.Get_Page_Rights_Of_EMP({ MMID: '196', LoginUserId: this.currentUser.userId }).subscribe((result: any) => {

      if (result.length > 0) {
        let AllPage = result;

        if (AllPage.filter(x => x.Page_Name.trim() == "Vouchers Entry - Journal") == '' || AllPage.filter(x => x.Page_Name.trim() == "Vouchers Entry - Journal") == null) {

          //this.snackBar.openFromComponent(SnackbarComponent, { data: "You have not authorize to access this page ?", ...this.configSuccess });
          //this.dataSharingService.RefreshLoginPage.next('false');
          //sessionStorage.removeItem('currentUser');
          //sessionStorage.clear();
          //this._Route.navigate(['Login']);
          //return;
          let FyearId = JSON.parse(sessionStorage.getItem('FyearId'));
          this._Route.navigate(['/lms-accounting/voucherNew/voucher-contra'], { queryParams: { fyear: FyearId, BranchId: 0 } });
        }
        else if (AllPage.filter(x => x.Page_Name.trim() == "Vouchers Entry - Journal") != '' && AllPage.filter(x => x.Page_Name.trim() == "Vouchers Entry - Journal") != null) {
          if (AllPage.filter(x => x.Page_Name == "Vouchers Entry - Journal")[0].View != 1) {
            //this.snackBar.openFromComponent(SnackbarComponent, { data: "You have not authorize to access this page ?", ...this.configSuccess });
            //this.dataSharingService.RefreshLoginPage.next('false');
            //sessionStorage.removeItem('currentUser');
            //sessionStorage.clear();
            //this._Route.navigate(['Login']);
            //return;
            let FyearId = JSON.parse(sessionStorage.getItem('FyearId'));
            this._Route.navigate(['/lms-accounting/voucherNew/voucher-contra'], { queryParams: { fyear: FyearId, BranchId: 0 } });
          }
        }
      }
    })
    if (new Date(this.currentUser.SubscriptionDate) <= new Date()) {
      this.voucherAdd = false;
    } else {
      this.voucherAdd = true;
    }
    this.Data_FreezeDate = new Date(this.currentUser.Data_Freeze.split('T')[0]);
    this.Days_Allowed = this.currentUser.Days_Allowed;
    //var Days_AllowedDate = new Date();
    this.Days_AllowedDateMin.setDate(this.Days_AllowedDateMin.getDate() - this.Days_Allowed);
    this.Days_AllowedDateMax.setDate(this.Days_AllowedDateMax.getDate() + this.Days_Allowed);

    if (this.Days_AllowedDateMin > this.Data_FreezeDate) {
      this.CheckDate = this.Days_AllowedDateMin;
    } else {
      this.CheckDate = this.Data_FreezeDate;
    }
    this.initializeVoucher();
    this.initializeVoucherDetail();
    this.Get_Acc_Accounts_OtherThan_BankCash();
    this.Get_EmployeeBranchForDropdown();

    this.Get_AccountForGST_Dropdown();
    this.GetStateDropdown();
    this.Get_TaxSlab_Dropdown();

    this.Customer.CloseCase = '';
    this.getBranchesList();
    this.GetCollectionExecutive();
    this.GetSalesExecutive();
    this.getAreaByUserId();
    this.getSubAreaByUserId();
    this.GetLoanAccCategoryDropdown();

    this.getFyear();

    this.route.queryParams.subscribe(
      params => {
        this.FyearId = params['fyear'];
        this.lmsService.Get_FinancialYear({ Id: this.FyearId }).subscribe((res: any) => {
          this.Min_Date = new Date(moment(res[0].fystart).format('MM/DD/YYYY'));
          this.maxDate = new Date(moment(res[0].fyent).format('MM/DD/YYYY'));

          if (this.maxDate > new Date() && this.Min_Date < new Date())
            this.voucherModel.Voucher.Voucher_Date = new Date();
          else
            this.voucherModel.Voucher.Voucher_Date = this.maxDate ;
        });
      }
    )
  }
  getBranchesList() {
    this.lmsService.GetBranches({ Emp_Id: this.currentUser.userId }).subscribe((result) => {
      this.BranchesList = JSON.parse(JSON.stringify(result));
    });
  }

  GetCollectionExecutive() {
    this.lmsService.Get_Collection_Ex_By_UserId({ LoginUserId: this.currentUser.userId, BranchId: '' }).subscribe((res) => {
      this.CollectionExecutiveData = JSON.parse(JSON.stringify(res));
    });
  }

  GetSalesExecutive() {
    this.lmsService.Get_Collection_Ex_By_UserId({ LoginUserId: this.currentUser.userId, BranchId: '' }).subscribe((res) => {
      this.SalesExecutiveData = JSON.parse(JSON.stringify(res));
    })
  }

  getAreaByUserId() {
    this.lmsService.Get_Area_By_UserId({ BranchId: '', LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
      this.areaData = JSON.parse(JSON.stringify(res));
    })
  }

  getSubAreaByUserId() {
    this.lmsService.Get_SubArea_By_UserId({ BranchId: '', LoginUserId: this.currentUser.userId}).subscribe((res: any) => {
      this.subAreaData = JSON.parse(JSON.stringify(res));
    })
  }

  GetLoanAccCategoryDropdown() {
    this.lmsService.Get_Loan_Accounting_Category_ForDropdown({ ProductId: 0}).subscribe((res: any) => {
      this.AccCatDropdown = JSON.parse(JSON.stringify(res));
    })
  }

  SearchChange() {
    this.Customer = {
      CloseCase: '',
      BranchId : '',
      Area : '',
      SubArea : '',
      SalesEx : '',
      CollectionEx : '',
      LoanAccountingCatId : '',
    };
  }

  initializeVoucher() {
    this.voucherModel.Voucher = {
      VoucherId: 0,
      Voucher_Type: 'Journal',
      Voucher_Sub_Type: 'Accounting',
      Voucher_Tag: '',
      Voucher_Date: new Date(),
      DRAccountId: "",
      Voucher_Mode_of_Payment: "",
      Voucher_Total_Credit_Amount: "",
      Voucher_Total_Debit_Amount: "",
      Voucher_Total_Tax: "",
      Voucher_Narration: "",
      Voucher_Cheque_No: "",
      Voucher_Cheque_Date: "",
      Voucher_Bank_Name: "",
      Voucher_Cheque_Clear_Date: "",
      Voucher_E_Transaction_Date: "",
      Voucher_E_Ref_No: "",
      Voucher_ReceiptNo_Book: "",
      Voucher_ReceiptDate: "",
      LoginUserId: this.currentUser.userId
    };
    setTimeout(() => {
      this.GetVoucherNo();
      //this.Get_Acc_Accounts_By_Tag();
    }, 500)

    if (this.maxDate > new Date() && this.Min_Date < new Date())
      this.voucherModel.Voucher.Voucher_Date = new Date();
    else this.voucherModel.Voucher.Voucher_Date = this.maxDate;
  }
  initializeVoucherDetail() {
    this.voucherModel.Voucher_Detail.push({
      VoucherId: 0,
      CaseNo: "",
      CustomerId: "",
      Is_LOSAppliaction: "0",
      ChargeHeadId: "",
      ChargeHeadCategory: "",
      AccountId: "",
      BranchId: "",
      TranType: this.totalCalculator && this.totalCalculator.totalCR > this.totalCalculator.totalDR ? 'DR' : 'CR',
      IsTax: 0,
      Tax_Per: 0,
      Amount: "",
      TaxSlab: [],
      GSTModel: {},
      Bind_Voucher : this.Bind_Voucher,
      Type : ""
    });
  }
  getChargeshead() {
    if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Partner') {
      this._Partnerservice.PartnerGet_ChargesHeads_ForDropdown({ LoanAccountingId: this.selectedData.LoanAccountingId }).subscribe((res: any) => {
        var result = [];
        res.forEach(val => result.push(Object.assign({}, val)));
        this.voucherModel.Voucher_Detail.forEach((obj: any) => {
          result = [];
          res.forEach(val => result.push(Object.assign({}, val)));
          if (obj.TranType == 'CR') {
            for (var i = 0; i < result.length; i++) {
              if (result[i].Id == 34) {
                result.splice(i, 1);
              }
            }
          }
          
        });

        this.chargesHeadDropdown[this.voucherModel.Voucher_Detail.length - 1] = result;
      });
    }
    else {
      this._MasterService.Get_ChargesHeadsByProduct_ForDropdow({ ProductId: this.selectedData.ProductId }).subscribe((res: any) => {
        var result=[];
        res.forEach(val => result.push(Object.assign({}, val)));
       
        // Delete charges head of installment if TranType DR start here
        this.voucherModel.Voucher_Detail.forEach((obj: any) => {
          result = [];
          res.forEach(val => result.push(Object.assign({}, val)));
          if (obj.TranType == 'DR') {
            for (var i = 0; i < result.length; i++) {
              if (result[i].Id == 34 || result[i].Id == 27) {
                result.splice(i, 1);
              }
            }
          }

          if (obj.TranType == 'CR') {
            for (var i = 0; i < result.length; i++) {
              if (result[i].Id == 28 || result[i].Id == 27) {
                result.splice(i, 1);
              }
            }
          }
        });
        // Delete charges head of installment if TranType DR end here

        this.chargesHeadDropdown[this.voucherModel.Voucher_Detail.length - 1] = result;
      });
    }
  }
  Get_EmployeeBranchForDropdown() {
    this._MasterService.Get_EmployeeBranchForDropdown({ EmployeeId: this.currentUser.userId }).subscribe((res: any) => {
      this.empBranchDropdown = res;
    });
  }
  Get_Acc_Accounts_OtherThan_BankCash() {
    this.lmsService.Get_Acc_Accounts_ForJVandContra({ IsJournal: 1, isAccounting: this.voucherModel.Voucher.Voucher_Sub_Type =='Accounting'?1:0 }).subscribe((res: any) => {
      this.accountHeadDropdown = res;
    });
  }
  onChangeMode() {
    if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque') {
      this.voucherModel.Voucher.Voucher_Cheque_No = "";
      this.voucherModel.Voucher.Voucher_Cheque_Date = "";
      this.voucherModel.Voucher.Voucher_Bank_Name = "";
      this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    }
    else {
      this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
      this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    }
  }
  GetVoucherNo() {
    this.lmsService.GetVoucherNo({ VoucherType: this.voucherModel.Voucher.Voucher_Type, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, VoucherDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
      this.voucherModel.Voucher.Voucher_No = res[0].VoucherNo;
    })
  }
  Get_Acc_Accounts_By_Tag() {
    this.voucherModel.Voucher.DRAccountId = "";
    this.lmsService.Get_Acc_Accounts_By_Tag({ LoginUserId: this.currentUser.userId, Account_Tag: this.voucherModel.Voucher.Voucher_Tag, Voucher_Date: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {
      this.accountTagDropdown = res;
    })
  }
  Get_Acc_Search_LoanNo() {
    this.showSpinner = true;
    if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Partner') {
      this.displayedColumns = ['CustomerId', 'Type', 'Partner_LoanAcNo', 'Customer_LoanAcNo', 'CustomertName', 'FatherName', 'Customer_Gender', 'GenderAge', 'PhoneNo', 'EditAction'];

      this._MasterService.Get_SearchCustomerByPartner({ JSON: JSON.stringify(this.Customer) }).subscribe((res: any) => {
        //console.log(res);
        this.CustomerList = JSON.parse(JSON.stringify(res));

        this.dataSource = new MatTableDataSource(this.CustomerList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
    }
    else {
      this.displayedColumns = ['CustomerId', 'Type', 'Application_No', 'LoanAcNo', 'CustomertName', 'FatherName', 'Customer_Gender', 'GenderAge', 'PhoneNo', 'EditAction'];

      this._MasterService.Get_SearchCustomerByLMSLOS({ JSON: JSON.stringify(this.Customer) }).subscribe((res: any) => {
        //console.log(res);
        this.CustomerList = JSON.parse(JSON.stringify(res));
        if (this.Type != "0") {
          var type;
          if (this.Type == "1")
            type = "LOS";
          else
            type = "LMS";
          this.CustomerList = this.CustomerList.filter(item => item.Type == type);
        }
        this.dataSource = new MatTableDataSource(this.CustomerList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.showSpinner = false;
      });
    }
  }
  selectSearchData(data: any) {
    if (data.LoanAcNo != '' && data.LoanAcNo != null)
      this.selectedData.CaseNo = data.LoanAcNo;
    else
      this.selectedData.CaseNo = data.Application_No;

    this.selectedData.LoanAccountingId = data.LoanAccountingId;

    this.selectedData.Customer = data.CustomertName;
    this.selectedData.CustomerId = data.CustomerId;
    this.selectedData.ProductId = data.ProductId;
    this.selectedData.BranchId = data.BranchId;
    this.selectedData.Branch_Name = data.Branch_Name;
    this.selectedData.Is_LOSAppliaction = data.Type == 'LOS' ? '1' : '0';
    this.selectedData.ChargeHeadId = "";
    this.selectedData.Amount = "";
    this.selectedData.TaxSlab = [];
    this.selectedData.GST_No = data.GST_No;
    this.selectedData.StateId = data.StateId;
    this.selectedData.Branch_StateId = data.Branch_StateId;
    this.getChargeshead();
    this.OnCloseSearch();
  }
  onVoucherSubTypeChange() {
    this.GetVoucherNo();
    this.Get_Acc_Accounts_OtherThan_BankCash();
    this.voucherModel.Voucher_Detail = [];
    this.Tax_Slab_Data = null;
    this.initializeVoucherDetail();
    //this.getTotalCalculation();
  }
  onVoucherTagChange() {
    this.Get_Acc_Accounts_By_Tag();
  }
  onVoucherDateChange() {
    if(this.voucherModel.Voucher.Voucher_Date < this.Min_Date || this.voucherModel.Voucher.Voucher_Date > this.maxDate){
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Enter Voucher Date between selected financial year.", ...this.configSuccess });
      this.voucherModel.Voucher.Voucher_Date = '';
    }
    this.GetVoucherNo();
    // this.Get_Acc_Accounts_By_Tag();
  }
  searchCustomer(data: any,index:any) {
    this.selectedSearchIndex=index;
    this.selectedData = data;
    if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Partner') {
      this.Type = "3";
    }
    this.dataSource = new MatTableDataSource([]);
    $("#CustomerReceipt").modal("show");
    $("#CustomerReceipt").css("z-index", "1050");
    setTimeout(()=>{
      $("#SearchValue").focus();
    },100)
  }
  OnCloseSearch() {
    $("#CustomerReceipt").modal("hide");
    $("#Customer"+this.selectedSearchIndex).focus();
    this.selectedData.SearchValue = "";
  }

  onChangeChargesHead(data: any, dindex: any) {
    let ChargeId, ChargeData1;
    if(this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'){
      let ChargeData = this.accountHeadDropdown.filter(x => x.Account_Name == data.ChargeHeadCategory);
      //ChargeId = ChargeData.length > 0 ? ChargeData[0].AccountId : '';
      //ChargeData1 = ChargeData.length > 0 ? true : false;

      if(ChargeData.length > 0){
        ChargeId = ChargeData[0].AccountId;
        ChargeData1 = true;
        this.lmsService.Get_Account_Balance({ AccountId: ChargeData[0].AccountId, ToDate: this.voucherModel.Voucher.Voucher_Date }).subscribe((res: any) => {

          this.snackBar.openFromComponent(SnackbarComponent, { data: "Current Balance of " + ChargeData[0].Account_Name + ' is ' + res[0].Balance, ...this.configSuccess });

        })
      }
      else{
        ChargeId = '';
       
        data.ChargeHeadCategory = "";
        ChargeData1 = false;
        this.snackBar.openFromComponent(SnackbarComponent, { data: "Account Head not found", ...this.configSuccess });
        return
      }
    }
    else{
      ChargeData1 = true;
      ChargeId = data.ChargeHeadId;
    }
    
    if(ChargeData1){
      if(this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'){
        if (!data.ChargeHeadCategory) {
          data.AccountId = "";
          data.ChargeHeadCategory = "";
          data.TaxSlab = [];
          return;
        }
      }
      else{
        if (!data.ChargeHeadId) {
          data.AccountId = "";
          data.ChargeHeadCategory = "";
          data.TaxSlab = [];
          return;
        }
      }
      
      let _find = this.voucherModel.Voucher_Detail.find((obj: any, index: any) => {
        //if(this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'){
        //  return index != dindex && obj.ChargeHeadCategory == data.ChargeHeadCategory;
        //}
        if (this.voucherModel.Voucher.Voucher_Sub_Type != 'Accounting') {
          return index != dindex && obj.CaseNo == data.CaseNo &&   obj.ChargeHeadId == data.ChargeHeadId;
        }
        //return index != dindex && Number(obj.ChargeHeadId) == Number(data.ChargeHeadId)
      });
      if (_find) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: "This Account head already selected.", ...this.configSuccess });
        data.ChargeHeadId = "";
        this.voucherModel.Voucher_Detail[dindex].ChargeHeadId = "";
        data.Amount = "";
        data.AccountId = "";
        data.ChargeHeadCategory = "";
        data.TaxSlab = [];
        data.GSTModel = {};
        if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
          data.BranchId = "";
        }
        //this.getTotalCalculation();
        return;
      }
      data.Amount = "";
      data.AccountId = "";
      data.ChargeHeadCategory = "";
      data.TaxSlab = []; 
      data.TaxSlab1 = [];
      
      //console.log("ChargeId : ",ChargeId);
      this.lmsService.Get_AccountHead_For_VoucherEntry({ 
        HeadId: ChargeId, VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type, 
        ProductId: this.voucherModel.Voucher.Voucher_Sub_Type == 'Partner' ? data.LoanAccountingId : data.ProductId, TaxType: data.TranType }).subscribe((res: any) => {
          //console.log("res : ",res);
        if (res.Item1[0]) {
          let _findA = this.accountHeadDropdown.find((obj: any) => { return obj.AccountId == res.Item1[0].AccountId });
          if (_findA) {
            data.AccountId = res.Item1[0].AccountId;

            if(this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'){
              data.ChargeHeadId = res.Item1[0].AccountId;
            }

            data.ChargeHeadCategory = res.Item1[0].AccountName;
            data.TaxSlab = res.Item2;
            this.IsGSTEnable = res.Item1[0].IsGSTEnable;
          }
          else {
            this.snackBar.openFromComponent(SnackbarComponent, { data: "Account head not found.", ...this.configSuccess });
          }
        }
        else {
          this.snackBar.openFromComponent(SnackbarComponent, { data: "No data Found.", ...this.configSuccess });
        }
      });
    }
  }

  onChangeAccountHead(data: any, index: any) {
    if(data.CaseNo == ''){
      this.IsGSTEnable = false;
      data.AccountId = this.accountHeadDropdown.filter(x => x.Account_Name == data.Account_Name)[0].AccountId;
    }
    
    if (!data.AccountId) {
      data.Amount = "";
     
      delete data.IsLoanChargeable;
      //this.getTotalCalculation();
      return;
    }
    
    let _find = this.accountHeadDropdown.find((obj: any) => { return obj.AccountId == Number(data.AccountId) })
    data.IsLoanChargeable = _find.IsLoanChargeable;

    data.TaxSlab = []; 
    data.TaxSlab1 = [];

    if (_find.IsLoanChargeable) {
      data.AccountId = "";
      data.Account_Name = "";
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Please search Application and choose category for this account head.", ...this.configSuccess });
    }
    data.ChargeHeadId = "";
    data.Amount = "";
    data.CaseNo = "";
    data.Customer = "";
    data.CustomerId = "";
    data.ProductId = "";
    data.BranchId = "";
    data.Branch_Name = "";
    //this.getTotalCalculation();
  }

  onChangeCRDR(data: any) {
    data.CaseNo = "";
    data.CustomerId = "";
    data.Is_LOSAppliaction = "1";
    data.ChargeHeadId = "";
    data.ChargeHeadCategory = "";
    data.AccountId = "";
    data.BranchId = "";
    data.IsTax = 0;
    data.Tax_Per = 0;
    data.Amount = "";
    data.Customer= "";
    data.Branch_Name= "";
    data.TaxSlab = [];
    this.getTotalCalculation();
  }

  onRefresh(data: any) {
    data.IsLoanChargeable=false;
    data.CaseNo = "";
    data.CustomerId = "";
    data.Is_LOSAppliaction = "1";
    data.ChargeHeadId = "";
    data.ChargeHeadCategory = "";
    data.AccountId = "";
    data.BranchId = "";
    data.IsTax = 0;
    data.Tax_Per = 0;
    data.Amount = "";
    data.Customer= "";
    data.Branch_Name= "";
    data.TaxSlab = [];
    this.getTotalCalculation();
  }

  onChangeAmount(event: any, data: any, dindex: any) {
    if (event) {
      event.preventDefault();
    }
    if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Loan' && data.ChargeHeadId == 28 && data.TranType=='DR') {
      this.lmsService.lms_Check_InstallmentAmount({ CaseNo: data.CaseNo, VoucherId: 0 }).subscribe((res: any) => {
        if (res.length > 0) {
          if (res[0].IsDvCreated == 0) {
            if (parseFloat(res[0].LoanAmount) - parseFloat(res[0].DVAmountDR) < parseFloat(data.Amount)) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Please Amount allocated on other Head", ...this.configSuccess
              });
              data.Amount = '';
              this.getTotalCalculation();
              return;
            }
          }
          else if (res[0].IsDvCreated == 1) {
            if (parseFloat(res[0].DVAmoutCR) - parseFloat(res[0].DVAmountDR) < parseFloat(data.Amount)) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Please Amount allocated on other Head", ...this.configSuccess
              });
              data.Amount = '';
              this.getTotalCalculation();
              return;
            }
          }

        }
      })
    }
    //if(this.IsGSTEnable && data.TaxSlab != ''){
    if (((data.TaxSlab != undefined && data.TaxSlab.length > 0) || (data.TaxSlab1 != undefined && data.TaxSlab1.length > 0))) {
      //this.Tax_Slab_Data = null;
      this.currentindex = dindex;
      console.log("this.currentindex", this.currentindex);

      //this.Tax_Slab_Data = null;
      $("#GSTModalWindow").modal("show");
      $("#GSTModalWindow").css("z-index", "1050");

      let SelectBranch, StateId;
      if(this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'){
        SelectBranch = this.empBranchDropdown.filter(x => x.Branch_Name == data.Branch_Name);
        this.voucherModel.Voucher_Detail.BranchId = SelectBranch[0].BranchId;
        StateId = SelectBranch[0].StateId;
        this.AccountingSelect = { StateId: StateId, Branch_Name: SelectBranch[0].Branch_Name };
        //let BranchName = this.empBranchDropdown.filter(item => item.BranchId == data.BranchId)[0].Branch_Name;
      }
      //console.log("this.voucherModel.Voucher.Voucher_Sub_Type", this.voucherModel.Voucher.Voucher_Sub_Type);
      //console.log("this.selectedData", this.selectedData);
      if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Loan' || this.voucherModel.Voucher.Voucher_Sub_Type == 'Partner'){
        this.GSTModel = {
          Slab_Tax : '',
          AccountHeadId : '',
          PartyType : 'Existing_Party',
          Customer: this.selectedData.Customer,
          Acc_GSTNo : this.selectedData.GST_No,
          StateId : this.selectedData.StateId,
          Charge_head: data.ChargeHeadCategory,
          Amount : data.Amount,
          TranType : data.TranType,
        }
      }
      else{
        this.GSTModel = {
          Slab_Tax : '',
          AccountHeadId : '',
          PartyType : 'Existing_Party',
          Customer: data.Customer,
          StateId : StateId,
          Charge_head: data.ChargeHeadCategory,
          Amount : data.Amount,
          TranType : data.TranType,
        }
      }
    }

    /*data.TaxSlab.forEach((obj: any) => {
      obj.TaxAmount = ((parseFloat(data.Amount) * obj.TaxRate) / 100).toFixed(2);
    });*/
    this.getTotalCalculation();
  }
  addMoreData() {
    this.Bind_Voucher = this.Bind_Voucher + 1;
    this.initializeVoucherDetail();
  }
  removeData(index: any) {
    this.voucherModel.Voucher_Detail.splice(index, 1);

    this.getTotalCalculation();
  }
  saveVoucher() {
    let _vouvher: any = { ...this.voucherModel.Voucher };
    let _voucheretail: any[] = [];
    // let _find = this.accountTagDropdown.find((obj: any) => { return obj.AccountId == this.voucherModel.Voucher.DRAccountId; });
    //let _total: any = 0;
    let _totalCR: any = 0;
    let _totalDR: any = 0;
    let _totalTXCR: any = 0;
    let _totalTXDR: any = 0;
    let TaxType = '';
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      if (obj.TranType == "CR") {
        _totalCR = _totalCR + parseFloat(obj.Amount);
        TaxType = obj.TaxSlab1.length > 0 ? "Output" : '';
      }
      else {
        _totalDR = _totalDR + parseFloat(obj.Amount);
        TaxType = obj.TaxSlab1.length > 0 ? "Input" : '';
      }

      var BranchId, ChargeId, Party_AcName, ChargeHeadCategory;
      if(this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting'){
        BranchId = this.empBranchDropdown.filter(x => x.Branch_Name == obj.Branch_Name)[0].BranchId;
        ChargeHeadCategory = obj.ChargeHeadCategory
        ChargeId = '';
        Party_AcName = obj.GSTModel.PartyType == 'New_Party' ? obj.GSTModel.Customer : obj.GSTModel.Account_Name;
      }
      else if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Loan' || this.voucherModel.Voucher.Voucher_Sub_Type == 'Partner'){
        if (obj.CaseNo == '') {
          BranchId = this.empBranchDropdown.filter(x => x.Branch_Name == obj.Branch_Name)[0].BranchId;
          ChargeHeadCategory = obj.Account_Name;

        } else {
          BranchId = obj.BranchId;
          ChargeHeadCategory = obj.ChargeHeadCategory;

        }
        ChargeId = obj.ChargeHeadId;
        Party_AcName = obj.GSTModel.Customer;
      }

      _voucheretail.push(this.dataSharingService.cleanObject({
        VoucherId: obj.VoucherId,
        CaseNo: obj.CaseNo,
        CustomerId: obj.CustomerId,
        Is_LOSAppliaction: obj.Is_LOSAppliaction,
        ChargeHeadId: ChargeId,
        ChargeHeadCategory: ChargeHeadCategory,
        AccountId: obj.AccountId,
        BranchId: BranchId,
        TranType: obj.TranType,
        IsTax: obj.IsTax,
        Tax_Per: obj.Tax_Per,
        Amount: obj.Amount,
        GST_Type: obj.TaxSlab1.length > 0 ? obj.GSTModel.PartyType : '',
        GST_No: obj.TaxSlab1.length > 0 ? obj.GSTModel.Acc_GSTNo : '',
        Party_AccountName: obj.TaxSlab1.length > 0 ? Party_AcName : '',
        HSN_Code: obj.TaxSlab1.length > 0 ? obj.GSTModel.HSNCode : '',
        Slab_Name: obj.TaxSlab1.length > 0 ? obj.GSTModel.Slab_Tax : '',
        Bind_Voucher : obj.Bind_Voucher,
        Type : TaxType
      }));
      obj.TaxSlab1.forEach((tobj: any) => {
        if (obj.TranType == "CR") {
          _totalTXCR = _totalTXCR + parseFloat(tobj.TaxAmount);
          _totalCR = _totalCR + parseFloat(tobj.TaxAmount);
        }
        else {
          _totalTXDR = _totalTXDR + parseFloat(tobj.TaxAmount);
          _totalDR = _totalDR + parseFloat(tobj.TaxAmount);
        }
        _voucheretail.push(this.dataSharingService.cleanObject({
          VoucherId: obj.VoucherId,
          CaseNo: obj.CaseNo,
          CustomerId: obj.CustomerId,
          Is_LOSAppliaction: obj.Is_LOSAppliaction,
          ChargeHeadId: ChargeId,
          ChargeHeadCategory: tobj.TaxSlab_Type,
          AccountId: tobj.AccountId,
          BranchId: BranchId,
          TranType: obj.TranType,
          IsTax: 1,
          Tax_Per: tobj.TaxSlab_Rate,
          Amount: tobj.TaxAmount,
          Bind_Voucher : obj.Bind_Voucher,
          Type : ''
        }))
      });
    });

    _vouvher.Voucher_Total_Credit_Amount = _totalCR;
    _vouvher.Voucher_Total_Debit_Amount = _totalDR;
    _vouvher.Voucher_Total_Tax = _totalTXCR + _totalTXDR;
    console.log("CR", _totalCR.toFixed(2));
    console.log("DR", _totalDR.toFixed(2));
    if (_totalCR.toFixed(2) != _totalDR.toFixed(2)) {
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Total Debit Amount must be equal to Total Credit Amount.", ...this.configSuccess });
      return;
    }

    let Attachment: any[] = [];
    if (this.docModal.DocumentImages.length) {
      for (let i = 0; i < this.docModal.DocumentImages.length; i++) {
        Attachment.push({ Doc_path: this.docModal.DocumentImages[i].DocFileName });
      }
    }
    let _finalData = {
      Voucher: this.dataSharingService.cleanObject({ ..._vouvher }),
      Voucher_Detail: _voucheretail,
      Voucher_Attachment: Attachment
    }

    //console.log("_finalData :", _finalData);
    this.lmsService.Acc_Save_Voucher({ JSON: JSON.stringify(_finalData) }).subscribe((res: any) => {
      if (res[0].CODE >= 0) {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
        this.onSaveDoc();
        this.initializeVoucher();
        this.voucherModel.Voucher_Detail = [];
        this.initializeVoucherDetail();
        this.totalCalculator = undefined;
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { data: res[0].MSG, ...this.configSuccess });
      }
    });
  }
  onChangeTax() {
    this.getTotalCalculation();
  }
  getTotalCalculation() {
    let _totalCR: any = 0;
    let _totalDR: any = 0;
    let _totalCRTX: any = 0;
    let _totalDRTX: any = 0;
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      if (obj.TranType == "CR") {
        _totalCR = _totalCR + parseFloat(obj.Amount) || 0;
      }
      else {
        _totalDR = _totalDR + parseFloat(obj.Amount) || 0;
      }
      if(obj.TaxSlab1.length > 0){
        obj.TaxSlab1.forEach((tobj: any) => {
          if (obj.TranType == "CR") {
            _totalCR = _totalCR + parseFloat(tobj.TaxAmount) || 0;
            _totalCRTX = _totalCRTX + parseFloat(tobj.TaxAmount) || 0;
          }
          else {
            _totalDR = _totalDR + parseFloat(tobj.TaxAmount) || 0;
            _totalDRTX = _totalDRTX + parseFloat(tobj.TaxAmount) || 0;
          }
        });
      }
    });
    this.totalCalculator = {
      totalCR: parseFloat(_totalCR),
      totalDR: parseFloat(_totalDR),
      totalCRTX: parseFloat(_totalCRTX),
      totalDRTX: parseFloat(_totalDRTX),
      totalCRInWord: new ToWords().convert(parseFloat(_totalCR)),
      totalDRInWord: new ToWords().convert(parseFloat(_totalDR))
    }
  }
  fileChangeListenerD(files: any, input: any) {
    let Ext = files[0].name.split('.').pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024,1));
    
    if(FileSize > this.currentUser.UploadMaxSize_In_KB){
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, { data: Msg, ...this.configSuccess });
      files.value = "";
      this.docModal.DocumentImages.DocFileName = "";
      delete this.docModal.DocumentImages.DocData;
      return;
    }

    if(!this.AllowExtension.includes(Ext)){
      this.snackBar.openFromComponent(SnackbarComponent, { data: `Invalid file type`, ...this.configSuccess });
      files.value = "";
      this.docModal.DocumentImages.DocFileName = "";
      delete this.docModal.DocumentImages.DocData;
      return;
    }

    let $this = this;
    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader();
      let _pre = this.today.getTime();
      let _docImg: any = {};
      _docImg.DocFileName = _pre + "_" + files[i].name;
      reader.readAsDataURL(files[i]);
      reader.onload = function () {
        _docImg.DocData = reader.result.toString().split(";base64,").pop();
      };
      this.docModal.DocumentImages.push(_docImg);
    }
    input.value = "";
  }
  removeDFile(index: any) {
    this.docModal.DocumentImages.splice(index, 1);
  }
  onSaveDoc() {
    let _data = { ...this.docModal };

    if (this.docModal.DocumentImages.length) {


      for (let i = 0; i < this.docModal.DocumentImages.length; i++) {
        let _err: boolean = false;
        this.lmsService
          .UploadAccountDoc({
            DocName: this.docModal.DocumentImages[i].DocFileName,
            DocData: this.docModal.DocumentImages[i].DocData,
          })
          .subscribe(
            (res: any) => {
              if ((i + 1) == this.docModal.DocumentImages.length) {
                //this.saveDoc();
              }
            },
            (err: any) => {
              _err = true;
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "File not uploaded.",
                ...this.configSuccess,
              });
            }
          );
        if (_err) {
          break;
        }

      }
      this.docModal = { DocumentImages: [] };
    }
  }

  OnCloseGSTModal(){
    $("#GSTModalWindow").modal("hide");
    this.voucherModel.Voucher_Detail[this.currentindex].TaxSlab1 = [];
    this.voucherModel.Voucher_Detail[this.currentindex].GSTModel = {};
  }

  Get_AccountForGST_Dropdown() {
    this._MasterService.Get_AccountForGST_Dropdown({ LoginUserId: this.currentUser.userId }).subscribe((res: any) => {
      this.AccountHeadForGST = res;
    });
  }
  GetStateDropdown() {
    this.losService.GetStateDropdown({}).subscribe((res: any) => {
      this.stateDropdown = res;
    });
  }

  Get_TaxSlab_Dropdown() {
    this.lmsService.Get_TaxSlab_Dropdown({}).subscribe((res: any) => {
      this.slabList = res;
      this.slabList = this.slabList.reduce((acc, current) => {
        const x = acc.find(item => item.TaxSlab_Name === current.TaxSlab_Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    })
  }

  onPartyTypeChange(){
    if(this.GSTModel.PartyType == 'New_Party'){
      this.GSTModel = {
        PartyType: this.GSTModel.PartyType,
        Slab_Tax : '',
        AccountHeadId : '',
        Customer : '',
        Acc_GSTNo : '',
        StateId: '',
        Amount: this.GSTModel.Amount,
        TranType: this.GSTModel.TranType
      }
    }
    else{
      this.GSTModel = {
        Slab_Tax : '',
        AccountHeadId : '',
        PartyType: this.GSTModel.PartyType,
        Customer: this.selectedData.Customer,
        Acc_GSTNo: this.selectedData.GST_No,
        Amount: this.GSTModel.Amount,
        TranType: this.GSTModel.TranType
        //StateId : this.BranchData.StateId,
      }
    }
  }
  
  onAccountHeadChange(){
    let data = this.AccountHeadForGST.filter(item => item.AccountId == this.GSTModel.AccountHeadId);

    this.GSTModel = {
      AccountHeadId : this.GSTModel.AccountHeadId,
      Slab_Tax : this.GSTModel.Slab_Tax,
      Acc_GSTNo : data[0].Acc_GSTNo,
      StateId : data[0].StateId,
      HSNCode: data[0].HSNCode,
      Account_Name: data[0].Account_Name,
      PartyType: this.GSTModel.PartyType,
      Amount: this.GSTModel.Amount,
      TranType : this.GSTModel.TranType,
    }
    /*this.AccountHeadForGST.forEach((obj: any) => {
      obj.Amount = '';
    });*/
  }

  saveGSTDetails(){
    $("#GSTModalWindow").modal("hide");
    //console.log("this.GSTModel", this.GSTModel);
    this.lmsService.Get_TaxSlab_Details({ TaxSlab: this.GSTModel.Slab_Tax }).subscribe((res: any) => {
      let Br_StateId, BranchName;
      if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
        //var Br_Data = this.empBranchDropdown.filter(x => x.BranchId == this.BranchData.BranchId);
        // var Br_Data = this.empBranchDropdown.filter(x => x.Branch_Name == this.voucherModel.Voucher_Detail[0].Branch_Name);
        Br_StateId = this.AccountingSelect.StateId;
        BranchName = this.AccountingSelect.Branch_Name;
      }
      else{
        Br_StateId = this.selectedData.Branch_StateId;
        BranchName = this.selectedData.Branch_Name;
      }

      let Tax_Type = this.GSTModel.TranType == "DR" ? "Input" : "Output";
      if(Br_StateId == this.GSTModel.StateId){
        this.Tax_Slab_Data = res.filter(item => item.TaxType == Tax_Type && item.IsInterState == 'With in State');
      }
      else{
        this.Tax_Slab_Data = res.filter(item => item.TaxType == Tax_Type && item.IsInterState == 'Inter state');
      }

      this.Tax_Slab_Data.forEach(x => {
        x.BranchName = BranchName;
        x.Amount = this.GSTModel.Amount;
        x.TaxAmount = (Number(this.GSTModel.Amount) * Number(x.TaxSlab_Rate) / 100).toFixed(2);
        
        // Search value (SGST) from a string 
        x.TypeGST = x.TaxSlab_Type.includes('SGST');
        x.TypeIGST = x.TaxSlab_Type.includes('IGST');
      });


      //this.voucherModel.Voucher_Detail.forEach(x => {
      //  if(x.TaxSlab.length > 0 && x.Bind_Voucher == this.Bind_Voucher){
      //    x.TaxSlab1 = this.Tax_Slab_Data;
      //    x.GSTModel = this.GSTModel;
      //  }
      //});
      if (this.voucherModel.Voucher_Detail[this.currentindex].TaxSlab1 != undefined) {
        this.voucherModel.Voucher_Detail[this.currentindex].TaxSlab1 = [];
      }
      this.voucherModel.Voucher_Detail[this.currentindex].GSTModel = {};

      this.voucherModel.Voucher_Detail[this.currentindex].TaxSlab1 = this.Tax_Slab_Data;
      this.voucherModel.Voucher_Detail[this.currentindex].GSTModel = this.GSTModel;

      this.getTotalCalculation();
    });
  }

  getFyear() {
    this.lmsService.Get_FinancialYear({ Id: 0 }).subscribe((res: any) => {
      let FyearDropdown = res;
      let FyearId = FyearDropdown[FyearDropdown.length - 1].Id;
      var Url = this._Route.url.split('?')[0];
      let FyearIdsessionStorage = parseInt(JSON.parse(sessionStorage.getItem('FyearId')));
      this._Route.navigate([Url], { queryParams: { fyear: FyearIdsessionStorage > 0 ? FyearIdsessionStorage : FyearId, BranchId: 0 } });
    });
  }
  onChangebranch(data) {
    let BranchId, BranchData1;
    let BranchData = this.empBranchDropdown.filter(x => x.Branch_Name == data.Branch_Name);
    //ChargeId = ChargeData.length > 0 ? ChargeData[0].AccountId : '';

    //console.log("ChargeData", ChargeData);

    if (BranchData.length > 0) {
      BranchId = BranchData[0].AccountId;
    }
    else {
      BranchId = '';
      data.Amount = "";
      data.Branch_Name = "";
     // data.TaxSlab = [];
      data.TaxSlab1 = [];
      data.GSTModel = {};
      this.getTotalCalculation();
      this.snackBar.openFromComponent(SnackbarComponent, { data: "Branch Name not found", ...this.configSuccess });
      return
    }
  }
}
