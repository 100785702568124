<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <div
    class="row m-0 formborder d-flex align-items-center justify-content-between"
  >
    <div class="col-md-3 p-1">
      <select
        name="FilterStatusId"
        id="FilterStatusId"
        class="form-control input-text-css"
        (change)="getInquiryList()"
        [(ngModel)]="status"
      >
        <option value="Pending">Pending</option>
        <option value="Assigned">Assigned</option>
        <option value="Completed">Completed</option>
        <option value="Rejected">Rejected</option>
      </select>
    </div>
    <div class="col-md-6 p-1"></div>
    <div class="col-md-3 p-1 text-right">
      <form action="" class="search-text">
        <div class="bg-light rounded rounded-pill shadow-sm">
          <div class="input-group">
            <input
              type="text"
              id="FilterInputSearch"
              (keyup)="applyFilter($event.target.value)"
              name="FilterInputSearch"
              placeholder="What're you searching for?"
              class="form-control border-0 bg-light"
            />
            <div class="input-group-append">
              <button
                id="button-addon1"
                type="submit"
                class="btn btn-link text-primary"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="table-responsive mt-3">
    <mat-table
      [dataSource]="dataSource"
      matSort
      matTableExporter
      #exporter="matTableExporter"
      [hiddenColumns]="[
        displayedColumns.indexOf('Assign'),
        displayedColumns.indexOf('Reject')
      ]"
      style="max-width: 100%; overflow: auto; height: 60vh"
    >
      <ng-container matColumnDef="InquiryId">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 50px"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = index"
          class="grid-cell"
          style="max-width: 50px; max-height: 5px"
        >
          {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="InquiryNo">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Inquiry No
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.InquiryNo }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Branch" *ngIf="status == 'Assigned'">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Branch
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Branch }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="SaleExcutive" *ngIf="status == 'Assigned'">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          SaleExcutive
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.SaleExcutive }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ContactNumber">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Contact Number
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          <div *ngIf="currentUser.IsMaskingMobile">
            {{ row.ContactNumber | mask }}
          </div>

          <div *ngIf="!currentUser.IsMaskingMobile">
            {{ row.ContactNumber }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerName">
        <mat-header-cell
          mat-sort-header
          class="grid-header"
          style="max-width: 150px"
          *matHeaderCellDef
        >
          Customer
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          style="max-width: 150px"
        >
          {{ row.CustomerName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="CustomerAddress">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Address
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CustomerAddress }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="LoanAmount">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Loan Amount
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          ₹ {{ row.LoanAmount }}.00
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Purpose">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Purpose
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          data-toggle="tooltip"
          data-placement="left"
          title="{{ row.Purpose }}"
        >
          {{ row.Purpose.slice(0, 20)
          }}{{ row.Purpose.length > 20 ? "..." : "" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="RejectedBy">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Rejected By
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.RejectedBy }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="RejectionReason">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Rejection Reason
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.RejectionReason }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Source">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Source
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Source }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="TAT">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          TAT(hr.)
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          <span *ngIf="row.Is_TAT_Over == true" style="color: red">
            {{ row.TAT }}
          </span>
          <span *ngIf="row.Is_TAT_Over == false">
            {{ row.TAT }}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CreateOn">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Create On
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CreateOn }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="CreatedBy">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Created By
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.CreatedBy }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Status" *ngIf="status == 'Pending'">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Status
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="grid-cell">
          {{ row.Status }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Remarks" *ngIf="status == 'Pending'">
        <mat-header-cell mat-sort-header class="grid-header" *matHeaderCellDef>
          Remarks
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell"
          data-toggle="tooltip"
          data-placement="left"
          title="{{ row.RejectedBy }}, {{ row.RejectionReason }}"
        >
          {{ row.RejectedBy }}, {{ row.RejectionReason.slice(0, 10)
          }}{{ row.RejectionReason.length > 10 ? "..." : "" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Assign" *ngIf="status == 'Pending'">
        <mat-header-cell
          mat-sort-header
          class="grid-header j-c-center"
          style="max-width: 60px"
          *matHeaderCellDef
        >
          Assign
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell j-c-center"
          style="max-width: 60px"
        >
          <i
            class="fa fa-arrow-circle-right arrow-circle action-btn"
            (click)="OnOpenAssignModal(row, $event)"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Reject" *ngIf="status == 'Pending'">
        <mat-header-cell
          mat-sort-header
          class="grid-header j-c-center"
          style="max-width: 60px"
          *matHeaderCellDef
        >
          Reject
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          class="grid-cell j-c-center"
          style="max-width: 60px"
        >
          <i
            class="fa fa-trash style-delete fas"
            (click)="onOpenRejectModal(row, $event)"
            aria-label="true"
          ></i>
        </mat-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div class="row m-0 align-items-center">
      <div class="col-md-4">
        <button class="3 btn font-size-12 button-btn" (click)="exportTable()">
          <i
            class="fa fa-file-export mr-1"
            style="font-size: medium; cursor: pointer"
          ></i>
          Export
        </button>
      </div>
      <div class="col-md-8">
        <mat-paginator
          #paginatorRef
          [pageSizeOptions]="[20, 50]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="assignToInquiry"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveAssignment()" novalidate>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Inquiry Assignment
          </h6>
          <button
            type="button"
            (click)="OnClose()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder">
          <div class="row m-0">
            <div class="col-md-12 p-1">
              <span> Inquiry No </span>
              <input
                readonly
                type="text"
                #refInquiryNo="ngModel"
                [(ngModel)]="assignModel.InquiryNo"
                placeholder="Inquiry No"
                name="InquiryNo"
                id="InquiryNo"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-12 p-1">
              <span class="required-lable"> Branch </span>
              <select
                name="BranchId"
                id="BranchId"
                #refBranchId="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refBranchId.invalid,
                  'alert-warning':
                    refBranchId.invalid &&
                    (refBranchId.dirty ||
                      refBranchId.touched ||
                      refBranchId.untouched)
                }"
                [(ngModel)]="assignModel.BranchId"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Branch</option>
                <option
                  *ngFor="let branch of branchDropdown"
                  [value]="branch.BranchId"
                >
                  {{ branch.Branch_Name }}
                </option>
              </select>
            </div>

            <div class="col-md-12 p-1">
              <span class="required-lable"> Loan Product </span>
              <select
                name="LoanProduct_Id"
                id="LoanProduct_Id"
                #refLoanProduct_Id="ngModel"
                required
                (change)="getEmployeeDropdown()"
                [ngClass]="{
                  'is-invalid': f.submitted && refLoanProduct_Id.invalid,
                  'alert-warning':
                    refLoanProduct_Id.invalid &&
                    (refLoanProduct_Id.dirty ||
                      refLoanProduct_Id.touched ||
                      refLoanProduct_Id.untouched)
                }"
                [(ngModel)]="assignModel.Product_Id"
                class="form-control input-text-css"
              >
                <option value="">Select Products</option>
                <ng-container *ngFor="let productg of ProductList">
                  <option
                    *ngIf="productg.Product_IsActive == true"
                    [value]="productg.ProductId"
                  >
                    {{ productg.Product }}
                  </option>
                </ng-container>
              </select>
            </div>
            <div class="col-md-12 p-1">
              <span class="required-lable"> Employee </span>
              <select
                name="AssignedTo"
                id="AssignedTo"
                #refAssignedTo="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refAssignedTo.invalid,
                  'alert-warning':
                    refAssignedTo.invalid &&
                    (refAssignedTo.dirty ||
                      refAssignedTo.touched ||
                      refAssignedTo.untouched)
                }"
                [(ngModel)]="assignModel.AssignedTo"
                class="form-control input-text-css"
                required
              >
                <option value="">Select Employee</option>
                <option
                  *ngFor="let emp of employeeDropdown"
                  [value]="emp.EmpId"
                >
                  {{ emp.EmpName }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnClose()"
            class="btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveAssignment()"
            class="btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade in"
  id="rejectInquiry"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #rf="ngForm" (ngSubmit)="onRejectInquiry()" novalidate>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Reject Inquiry
          </h6>
          <button
            type="button"
            (click)="OnCloseReject()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder">
          <div class="row m-0">
            <div class="col-md-12 p-1">
              <span class="required-lable"> Reject Reason </span>
              <textarea
                required
                rows="3"
                #refRejectReason="ngModel"
                [ngClass]="{
                  'is-invalid': rf.submitted && refRejectReason.invalid,
                  'alert-warning':
                    refRejectReason.invalid &&
                    (refRejectReason.dirty ||
                      refRejectReason.touched ||
                      refRejectReason.untouched)
                }"
                [(ngModel)]="rejectModel.Reason"
                placeholder="Enter Reject Reason"
                name="rejectReason"
                id="rejectReason"
                class="form-control input-text-css"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseReject()"
            class="btn font-size-12 button-btn"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onRejectInquiry()"
            class="btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!rf.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<table
  id="quickloan_inquiry_list"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Inquiry No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Customer
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Address
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Contact Number
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Loan Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Purpose
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Source
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      TAT(hr.)
    </th>
    <th
      *ngIf="status == 'Pending'"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      Status
    </th>
    <th
      *ngIf="status == 'Pending'"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      Remarks
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Created On
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Created By
    </th>
    <th
      *ngIf="status == 'Assigned'"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      Branch
    </th>
    <th
      *ngIf="status == 'Assigned'"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      Sales Executive
    </th>
    <th
      *ngIf="status == 'Rejected'"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      Rejected By
    </th>
    <th
      *ngIf="status == 'Rejected'"
      style="background: #4dc3a3 !important; border: 1px solid white"
    >
      Rejected Reason
    </th>
  </tr>

  <tr *ngFor="let x of InquiryList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.InquiryNo }}</td>
    <td>{{ x.CustomerName }}</td>
    <td>{{ x.CustomerAddress }}</td>
    <td>
      <div *ngIf="currentUser.IsMaskingMobile">
        {{ x.ContactNumber | mask }}
      </div>

      <div *ngIf="!currentUser.IsMaskingMobile">
        {{ x.ContactNumber }}
      </div>
    </td>
    <td>{{ x.LoanAmount }}</td>
    <td>{{ x.Purpose }}</td>
    <td>{{ x.Source }}</td>
    <td>{{ x.TAT }}</td>
    <td *ngIf="status == 'Pending'">{{ x.Status }}</td>
    <td *ngIf="status == 'Pending'">
      {{ x.RejectedBy }}, {{ x.RejectionReason.slice(0, 10)
      }}{{ x.RejectionReason.length > 10 ? "..." : "" }}
    </td>

    <td>{{ x.CreateOn }}</td>
    <td>{{ x.CreatedBy }}</td>
    <td *ngIf="status == 'Assigned'">{{ x.Branch }}</td>
    <td *ngIf="status == 'Assigned'">{{ x.SaleExcutive }}</td>
    <td *ngIf="status == 'Rejected'">{{ x.RejectedBy }}</td>
    <td *ngIf="status == 'Rejected'">{{ x.RejectionReason }}</td>
  </tr>
</table>
