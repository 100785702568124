<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    class="formborder"
    novalidate
    (keydown.enter)="onSearchPDDDetail()"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-2">
        <span class="required-lable">Product</span>
        <select
          name="SelectProduct"
          id="SelectProduct"
          #refSelectPR="ngModel"
          [(ngModel)]="SelectProduct"
          class="form-control input-text-css"
          required
          [ngClass]="{
            'is-invalid': df.submitted && refSelectPR.invalid,
            'alert-warning':
              refSelectPR.invalid &&
              (refSelectPR.dirty ||
                refSelectPR.touched ||
                refSelectPR.untouched)
          }"
        >
          <option value="">Select Type</option>
          <option
            [hidden]="product.Category != 'Vehicle Loan'"
            *ngFor="let product of ProductList"
            value="{{ product.ProductId }}and{{ product.Category }}"
          >
            {{ product.Product_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <span>Product Type</span>
        <select
          name="ProductType"
          id="ProductType"
          #refProductType="ngModel"
          [(ngModel)]="ProductType"
          class="form-control input-text-css"
          (change)="filterTableDataAccoType()"
        >
          <option value="">All</option>
          <option value="New-Non-Commercial">Non-Commercial New</option>
          <option value="Used-Non-Commercial">Non-Commercial Used</option>
          <option value="New-Commercial">Commercial New</option>
          <option value="Used-Commercial">Commercial Used</option>
        </select>
      </div>
      <div class="col-md-2">
        <span class="required-lable">Type</span>
        <select
          name="SelectType"
          id="SelectType"
          #refSelectType="ngModel"
          (change)="filterSelectType()"
          [(ngModel)]="SelectType"
          required
          class="form-control input-text-css"
          [ngClass]="{
            'is-invalid': df.submitted && refSelectType.invalid,
            'alert-warning':
              refSelectType.invalid &&
              (refSelectType.dirty ||
                refSelectType.touched ||
                refSelectType.untouched)
          }"
        >
          <option value="">Select Type</option>
          <option value="RC">RC</option>
          <option
            [hidden]="
              ProductType == 'Used-Non-Commercial' ||
              ProductType == 'Used-Commercial'
            "
            value="Invoice"
          >
            Invoice
          </option>
          <option value="Insurance">Insurance</option>
        </select>
      </div>
      <div class="col-md-2">
        <span class="required-lable">Sub Type</span>
        <select
          name="SelectSubType"
          id="SelectSubType"
          #refSelectSubType="ngModel"
          (change)="filterSelectType()"
          [(ngModel)]="SelectSubType"
          required
          class="form-control input-text-css"
          [ngClass]="{
            'is-invalid': df.submitted && refSelectSubType.invalid,
            'alert-warning':
              refSelectSubType.invalid &&
              (refSelectType.dirty ||
                refSelectSubType.touched ||
                refSelectSubType.untouched)
          }"
        >
          <option value="">Select Sub Type</option>
          <option value="Completed">Completed</option>
          <option value="Pending">Pending</option>
        </select>
      </div>
      <div class="col-md-2">
        <span> Branch</span>
        <select
          name="SelectBranch"
          id="SelectBranch"
          #refSelectGender="ngModel"
          [(ngModel)]="SelectBranch"
          class="form-control input-text-css"
        >
          <option value="0">All Branch</option>
          <option *ngFor="let branch of BranchesList" [value]="branch.BranchId">
            {{ branch.Branch_Name }}
          </option>
        </select>
      </div>
      <div class="col-md-1 mb-1" *ngIf="SelectType == 'Renewal'">
        <span> To Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            [(ngModel)]="ToDate"
            name="EMI_DueDate1"
            id="EMI_DueDate1"
            class="form-control input-text-css"
            style="width: 85px"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          type="text"
          [(ngModel)]="ToDate"
          name="EMI_DueDate1"
          id="EMI_DueDate1"
          placeholder="DD/MM/YYYY"
          [(bsValue)]="ToDate"
          class="form-control input-text-css"
          bsDatepicker
          style="width: 85px"
        /> -->
      </div>
      <div class="ml-1 col-md-1 text-right">
        <span>&nbsp;</span>
        <button
          type="button"
          (click)="onSearchPDDDetail()"
          class="btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="mt-3 formborder" *ngIf="showDiv">
    <div class="">
      <div class="">
        <mat-table
          [dataSource]="DataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="width: 100%; overflow: auto; min-width: 100%"
        >
          <ng-container matColumnDef="Id">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 50px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw50"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 50px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Application_No">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Application No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Application_No
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="LoanAcNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Ac No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.LoanAcNo
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="text-left grid-header"
              >Total</mat-footer-cell
            >
          </ng-container>

          <ng-container matColumnDef="Loan_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Loan_Date | date : "dd-MM-YYYY"
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Customer" class="text-break">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Customer</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Customer
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="Customer_PhoneNo" class="text-break">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Phone No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              <div *ngIf="currentUser.IsMaskingMobile">
                {{ row.Customer_PhoneNo | mask }}
              </div>

              <div *ngIf="!currentUser.IsMaskingMobile">
                {{ row.Customer_PhoneNo }}
              </div>
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Type">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Type
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="VehicleModelName">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Model Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.VehicleModelName
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="Collateral_Type">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Collateral Type</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Collateral_Type
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Application_LoanAmount">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Loan Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Application_LoanAmount
            }}</mat-cell>
            <mat-footer-cell *matFooterCellDef class="grid-header">{{
              totaltypewise("Application_LoanAmount")
            }}</mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="Product_Name">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Product Name</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.Product_Name
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="VehicleRegistationNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Vehicle No</mat-header-cell
            >
            <mat-cell
              [ngClass]="
                row.VehicleRegistationNo == null ||
                row.VehicleRegistationNo == ''
                  ? 'pending-danger'
                  : ''
              "
              *matCellDef="let row"
              class="grid-cell"
              >{{
                row.VehicleRegistationNo == null ||
                row.VehicleRegistationNo == ""
                  ? "Pending"
                  : row.VehicleRegistationNo
              }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="InvoiceNo">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Invoice No</mat-header-cell
            >
            <mat-cell
              [ngClass]="
                row.InvoiceNo == null || row.InvoiceNo == ''
                  ? 'pending-danger'
                  : ''
              "
              *matCellDef="let row"
              class="grid-cell"
              >{{
                row.InvoiceNo == null || row.InvoiceNo == ""
                  ? "Pending"
                  : row.InvoiceNo
              }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="FitnessUpto">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Fitness Upto</mat-header-cell
            >
            <mat-cell
              [ngClass]="{
                'pending-danger':
                  row.FitnessUpto == null || row.FitnessUpto == '',
                'bg-warning':
                  row.FitnessUpto &&
                  calculateDifferenceDate(row.FitnessUpto) < 0
              }"
              *matCellDef="let row"
              class="grid-cell"
              >{{
                row.FitnessUpto == null || row.FitnessUpto == ""
                  ? "Pending"
                  : row.FitnessUpto
              }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="PermitUpto">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Permit Upto</mat-header-cell
            >
            <mat-cell
              [ngClass]="{
                'pending-danger':
                  row.PermitUpto == null || row.PermitUpto == '',
                'bg-warning':
                  row.PermitUpto && calculateDifferenceDate(row.PermitUpto) < 0
              }"
              *matCellDef="let row"
              class="grid-cell"
              >{{
                row.PermitUpto == null || row.PermitUpto == ""
                  ? "Pending"
                  : row.PermitUpto
              }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="Insurance">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Insurance</mat-header-cell
            >
            <mat-cell
              [ngClass]="{
                'pending-danger': row.Insurance == null || row.Insurance == '',
                'bg-warning':
                  row.Insurance && calculateDifferenceDate(row.Insurance) < 0
              }"
              *matCellDef="let row"
              class="grid-cell"
              >{{
                row.Insurance == null || row.Insurance == ""
                  ? "Pending"
                  : row.Insurance
              }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="AssetInsuranceEndorsed">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Insurance Endorsed</mat-header-cell
            >
            <mat-cell
              [ngClass]="
                row.AssetInsuranceEndorsed == 0 ||
                row.AssetInsuranceEndorsed == null ||
                row.AssetInsuranceEndorsed == '' ||
                row.AssetInsuranceEndorsed == 'No'
                  ? 'pending-danger'
                  : ''
              "
              *matCellDef="let row"
              class="grid-cell"
              >{{
                row.AssetInsuranceEndorsed == 0 ||
                row.AssetInsuranceEndorsed == null ||
                row.AssetInsuranceEndorsed == "" ||
                row.AssetInsuranceEndorsed == "No"
                  ? "Pending"
                  : "Completed"
              }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="AssetRenewalDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Renewal Date</mat-header-cell
            >
            <mat-cell
              [ngClass]="
                row.AssetRenewalDate == null || row.AssetRenewalDate == ''
                  ? 'pending-danger'
                  : ''
              "
              *matCellDef="let row"
              class="grid-cell"
              >{{
                row.AssetRenewalDate == null || row.AssetRenewalDate == ""
                  ? "Pending"
                  : row.AssetRenewalDate
              }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="RegistationExpiryDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Registation Expiry Date
            </mat-header-cell>
            <mat-cell
              [ngClass]="{
                'pending-danger':
                  row.RegistationExpiryDate == null ||
                  row.RegistationExpiryDate == '',
                'bg-warning':
                  row.RegistationExpiryDate &&
                  calculateDifferenceDate(row.RegistationExpiryDate) < 0
              }"
              *matCellDef="let row"
              class="grid-cell"
              >{{
                row.RegistationExpiryDate == null ||
                row.RegistationExpiryDate == ""
                  ? "Pending"
                  : row.RegistationExpiryDate
              }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="RegistationDate">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Registation Date
            </mat-header-cell>
            <mat-cell
              [ngClass]="{
                'pending-danger':
                  row.RegistationDate == null || row.RegistationDate == '',
                'bg-warning':
                  row.RegistationDate &&
                  calculateDifferenceDate(row.RegistationDate) < 0
              }"
              *matCellDef="let row"
              class="grid-cell"
              >{{
                row.RegistationDate == null || row.RegistationDate == ""
                  ? "Pending"
                  : row.RegistationDate
              }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="RC_HPNEndorsment">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >RC Endorsement</mat-header-cell
            >
            <mat-cell
              [ngClass]="
                row.RC_HPNEndorsment == false ||
                row.RC_HPNEndorsment == null ||
                row.RC_HPNEndorsment == ''
                  ? 'pending-danger'
                  : ''
              "
              *matCellDef="let row"
              class="grid-cell"
              >{{
                row.RC_HPNEndorsment == false ||
                row.RC_HPNEndorsment == null ||
                row.RC_HPNEndorsment == ""
                  ? "Pending"
                  : "Completed"
              }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="Invoice_HPNEndorsment">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Invoice Endorsement</mat-header-cell
            >
            <mat-cell
              [ngClass]="
                row.Invoice_HPNEndorsment == false ||
                row.Invoice_HPNEndorsment == null ||
                row.Invoice_HPNEndorsment == ''
                  ? 'pending-danger'
                  : ''
              "
              *matCellDef="let row"
              class="grid-cell"
              >{{
                row.Invoice_HPNEndorsment == false ||
                row.Invoice_HPNEndorsment == null ||
                row.Invoice_HPNEndorsment == ""
                  ? "Pending"
                  : "Completed"
              }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="PDD">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >PDD</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">{{
              row.PDD
            }}</mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="Action">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 60px"
              *matHeaderCellDef
              >Action</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell j-c-center"
              style="max-width: 60px"
            >
              <i
                class="fa fa-pencil mx-2"
                (click)="showEditModalSheet(row)"
                *ngIf="SelectSubType == 'Pending'"
                aria-label="true"
              ></i>
              <!-- <i class="fa fa-eye btn_detail" (click)="ViewData(row)" aria-label="true"></i> -->
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="ViewAsset">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 60px"
              *matHeaderCellDef
              >View</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell j-c-center"
              style="max-width: 60px"
            >
              <!-- <i class="fa fa-pencil mx-2" (click)="showEditModalSheet(row)" aria-label="true"></i> -->
              <i
                class="fa fa-eye btn_detail"
                (click)="ViewDetail(row)"
                aria-label="true"
              ></i>
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="ViewInsurance">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 60px"Customer_PhoneNo
              *matHeaderCellDef
              >View Insurance</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row"
              class="grid-cell j-c-center"
              style="max-width: 60px"
            >
              <!-- <i class="fa fa-pencil mx-2" (click)="showEditModalSheet(row)" aria-label="true"></i>-->
              <i
                *ngIf="row.InsuranceId > 0"
                class="fa fa-eye btn_detail"
                (click)="goToDetail(row)"
                aria-label="true"
              ></i>
            </mat-cell>
            <mat-footer-cell
              *matFooterCellDef
              class="grid-header"
            ></mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row
            (click)="highlightRow(row)"
            [class.highlight]="row.Loan_Id == selectedId"
            *matRowDef="let row; columns: displayedColumns"
          ></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></mat-footer-row>
        </mat-table>
        <div class="row m-0 align-items-center">
          <div class="col-md-4">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportTable(exporter)"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>
          <div class="col-md-8">
            &nbsp;
            <mat-paginator
              #paginatorRef
              [pageSizeOptions]="[10, 20, 50]"
              class="mat-paginator-sticky"
              showFirstLastButtons
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="View"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          {{ Collateral_Type }} [{{ Collateral }}]
        </h6>
        <button
          type="button"
          (click)="onCloseView()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body formborder">
        <ng-container *ngIf="SelectType == 'RC'">
          <div class="row m-0 mt-3">
            <div class="col-md-6">
              <h6 class="fs-12">Vehicle Model Name</h6>
              <p class="lead fs-12">{{ assetsDetail?.VehicleModelName }}</p>
            </div>
            <div class="col-md-6">
              <h6 class="fs-12">Vehicle Registation No</h6>
              <p class="lead fs-12">{{ assetsDetail?.VehicleRegistationNo }}</p>
            </div>
            <div class="col-md-6">
              <h6 class="fs-12">Registation Date</h6>
              <p class="lead fs-12">{{ assetsDetail?.RegistationDate }}</p>
            </div>
            <div class="col-md-6">
              <h6 class="fs-12">Registation Expiry Date</h6>
              <p class="lead fs-12">
                {{ assetsDetail?.RegistationExpiryDate }}
              </p>
            </div>

            <div class="col-md-6">
              <h6 class="fs-12">Engine No</h6>
              <p class="lead fs-12">{{ Info?.EngineNo }}</p>
            </div>
            <div class="col-md-6">
              <h6 class="fs-12">Chassis No</h6>
              <p class="lead fs-12">{{ Info?.ChassisNo }}</p>
            </div>
            <div class="col-md-6">
              <h6 class="fs-12">RC Endorsment</h6>
              <p class="lead fs-12">
                {{
                  assetsDetail?.RC_HPNEndorsment == false ||
                  assetsDetail?.RC_HPNEndorsment == null ||
                  assetsDetail?.RC_HPNEndorsment == ""
                    ? "Pending"
                    : "Completed"
                }}
              </p>
            </div>
            <div class="col-md-6">
              <h6 class="fs-12">Modify By</h6>
              <p class="lead fs-12">{{ assetsDetail?.ModifyBy }}</p>
            </div>
            <div class="col-md-6">
              <h6 class="fs-12">Modify On</h6>
              <p class="lead fs-12">{{ assetsDetail?.ModifyOn }}</p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="SelectType == 'Invoice'">
          <div class="row m-0 mt-3">
            <div class="col-md-6">
              <h6 class="fs-12">Vehicle Model Name</h6>
              <p class="lead fs-12">{{ assetsDetail?.VehicleModelName }}</p>
            </div>
            <div class="col-md-6">
              <h6 class="fs-12">Invoice No</h6>
              <p class="lead fs-12">{{ assetsDetail?.InvoiceNo }}</p>
            </div>
            <div class="col-md-6">
              <h6 class="fs-12">Invoice Date</h6>
              <p class="lead fs-12">{{ assetsDetail?.InvoiceDate }}</p>
            </div>

            <div class="col-md-6">
              <h6 class="fs-12">Engine No</h6>
              <p class="lead fs-12">{{ Info?.EngineNo }}</p>
            </div>
            <div class="col-md-6">
              <h6 class="fs-12">Chassis No</h6>
              <p class="lead fs-12">{{ Info?.ChassisNo }}</p>
            </div>
            <div class="col-md-6">
              <h6 class="fs-12">Invoice Endorsment</h6>
              <p class="lead fs-12">
                {{
                  assetsDetail?.Invoice_HPNEndorsment == false ||
                  assetsDetail?.Invoice_HPNEndorsment == null ||
                  assetsDetail?.Invoice_HPNEndorsment == ""
                    ? "Pending"
                    : "Completed"
                }}
              </p>
            </div>
            <div class="col-md-6">
              <h6 class="fs-12">Modify By</h6>
              <p class="lead fs-12">{{ assetsDetail?.ModifyBy }}</p>
            </div>
            <div class="col-md-6">
              <h6 class="fs-12">Modify On</h6>
              <p class="lead fs-12">{{ assetsDetail?.ModifyOn }}</p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="SelectType == 'Insurance'">
          <div class="row m-0 mt-3">
            <div class="col-md-4">
              <h6 class="fs-12">Vehicle Model Name</h6>
              <p class="lead fs-12">{{ assetsDetail?.VehicleModelName }}</p>
            </div>
            <div class="col-md-4">
              <h6 class="fs-12">Insurance Type</h6>
              <p class="lead fs-12">{{ Type }}</p>
            </div>
            <div class="col-md-4">
              <h6 class="fs-12">{{ Type }} Name</h6>
              <p class="lead fs-12">{{ InsuranceInfo["Name"] }}</p>
            </div>
            <div class="col-md-4">
              <h6 class="fs-12">Insurance Company</h6>
              <p class="lead fs-12">
                {{ InsuranceInfo["AssetInsuranceCompanyName"] }}
              </p>
            </div>

            <div class="col-md-4">
              <h6 class="fs-12">Insurance Date</h6>
              <p class="lead fs-12">
                {{ InsuranceInfo["AssetInsuranceDate"] }}
              </p>
            </div>
            <div class="col-md-4">
              <h6 class="fs-12">Renewal Date</h6>
              <p class="lead fs-12">{{ InsuranceInfo["AssetRenewalDate"] }}</p>
            </div>
            <div class="col-md-4">
              <h6 class="fs-12">Cover Note No/Policy No.</h6>
              <p class="lead fs-12">{{ InsuranceInfo["AssetCoverNoteNo"] }}</p>
            </div>
            <div class="col-md-4">
              <h6 class="fs-12">Policy Type</h6>
              <p class="lead fs-12">
                {{ InsuranceInfo["AssetPolicyTypeName"] }}
              </p>
            </div>
            <div class="col-md-4">
              <h6 class="fs-12">IDV Amount</h6>
              <p class="lead fs-12">{{ InsuranceInfo["AssetIdvAmount"] }}</p>
            </div>
            <div class="col-md-4">
              <h6 class="fs-12">Premium Amount</h6>
              <p class="lead fs-12">
                {{ InsuranceInfo["AssetPremiumAmount"] }}
              </p>
            </div>

            <div class="col-md-4">
              <h6 class="fs-12">Insurance endorsed</h6>
              <p class="lead fs-12">
                {{ InsuranceInfo["AssetInsuranceEndorsed"] }}
              </p>
            </div>
            <div class="col-md-4">
              <h6 class="fs-12">Engine No</h6>
              <p class="lead fs-12">{{ Info?.EngineNo }}</p>
            </div>
            <div class="col-md-4">
              <h6 class="fs-12">Chassis No</h6>
              <p class="lead fs-12">{{ Info?.ChassisNo }}</p>
            </div>

            <div class="col-md-4">
              <h6 class="fs-12">Modify By</h6>
              <p class="lead fs-12">{{ assetsDetail?.ModifyBy }}</p>
            </div>
            <div class="col-md-4">
              <h6 class="fs-12">Modify On</h6>
              <p class="lead fs-12">{{ assetsDetail?.ModifyOn }}</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="duplicateData"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Vehicle Registration Number already exist .
        </h6>
        <button
          type="button"
          (click)="onCloseduplicateData()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row m-0 mt-3" *ngIf="duplicateData">
          <div class="table-responsive mt-3">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Loan Number</th>
                  <th scope="col">Loan Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of duplicateData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ data.CustomerName }}</td>
                  <td>{{ data.LoanAcNo }}</td>
                  <td>{{ data.LoanStatus }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--<div class="row col-md-12 m-0 mt-3 justify-content-center">
            <button type="button" (click)="onNoClick()" class="btn btn-light font-size-12">
              No
            </button>
            <button type="button" (click)="onYesClick()" class="ml-4"
              style="background-color: #28a745;color: #FFFFFF;font-size: 12px;" mat-raised-button color="primary">
              yes
            </button>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="additionalcollateral"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Additional collateral
        </h6>
        <button
          type="button"
          (click)="onCloseKYC()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <!-- *ngIf="AssetsForms=='Forms'" -->
        <ng-container>
          <form #f="ngForm" novalidate>
            <div class="col-md-3 p-1">
              <span>Add Additional Collateral </span>
              <select
                name="refLoanProduct"
                (change)="selectProducts($event)"
                id="refLoanProduct"
                [disabled]="Addi_ProductCat"
                #refLoanProduct="ngModel"
                [(ngModel)]="ProductCatId"
                class="form-control input-text-css"
              >
                <option value="">Select Accounting</option>
                <option
                  *ngFor="let productCategory of ProductCategoryDropdown"
                  [value]="productCategory.ProductCatId"
                >
                  {{ productCategory.Product_Category }}
                </option>
              </select>
            </div>

            <!-- <div class="row mt-4 ml-3" *ngIf="AgricultureForms=='AgricultureForms'">
              <div class="col-md-6">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" checked (change)="selectAgricultureForm('Property')" type="radio"
                    name="inlineRadioOptionsA" id="inlineRadioA1" value="Property">
                  <label class="form-check-label" for="inlineRadioA1">Property</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" (change)="selectAgricultureForm('Vehicle')" type="radio"
                    name="inlineRadioOptionsA" id="inlineRadioA2" value="Vehicle">
                  <label class="form-check-label" for="inlineRadioA2">Vehicle</label>
                </div>
              </div>
            </div> -->

            <ng-container *ngIf="propertyForms == 'Vehicles'">
              <div class="row mt-2">
                <div class="col-md-6">
                  <div class="form-check-inline">
                    <input
                      class="form-check-input"
                      [(ngModel)]="vehicle_type"
                      (change)="selectVehicleType('New')"
                      type="radio"
                      name="inlineRadioOptions"
                      id="NewVehicle1"
                      value="New"
                    />
                    <label class="form-check-label" for="NewVehicle1"
                      >New Vehicle</label
                    >
                  </div>
                  <div class="form-check-inline">
                    <input
                      class="form-check-input"
                      [(ngModel)]="vehicle_type"
                      (change)="selectVehicleType('Used')"
                      type="radio"
                      name="inlineRadioOptions"
                      id="UsedVehicle1"
                      value="Used"
                    />
                    <label class="form-check-label" for="UsedVehicle1"
                      >Used Vehicle</label
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check-inline">
                    <input
                      class="form-check-input"
                      [(ngModel)]="commercialVehicle"
                      (change)="selectVehicleType('Commercial')"
                      type="radio"
                      name="inlineRadioOptions1"
                      id="Commercial1"
                      value="Commercial"
                    />
                    <label class="form-check-label" for="Commercial1"
                      >Commercial</label
                    >
                  </div>
                  <div class="form-check-inline">
                    <input
                      class="form-check-input"
                      [(ngModel)]="commercialVehicle"
                      (change)="selectVehicleType('Non-Commercial')"
                      type="radio"
                      name="inlineRadioOptions1"
                      id="NonCommercial1"
                      value="Non-Commercial"
                    />
                    <label class="form-check-label" for="NonCommercial1"
                      >Non Commercial</label
                    >
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="required-lable">Vehicle Manufacture</span>
                  <select
                    name="VehicleManufacture0"
                    id="VehicleManufacture0"
                    #refVehicleManufacture0="ngModel"
                    (change)="LMS_GetVehicleModelMaster($event)"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refVehicleManufacture0.invalid,
                      'alert-warning':
                        refVehicleManufacture0.invalid &&
                        (refVehicleManufacture0.dirty ||
                          refVehicleManufacture0.touched ||
                          refVehicleManufacture0.untouched)
                    }"
                    [(ngModel)]="VehicleManufacture"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Vehicle Manufacture</option>
                    <option
                      *ngFor="let data of ManufactureData"
                      [value]="data.Id"
                    >
                      {{ data.Manufacture }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Vehicle Category</span>
                  <select
                    name="VehicleCategory1"
                    id="VehicleCategory1"
                    (change)="LMS_GetVehicleModelMaster($event)"
                    #refVehicleCategory1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refVehicleCategory1.invalid,
                      'alert-warning':
                        refVehicleCategory1.invalid &&
                        (refVehicleCategory1.dirty ||
                          refVehicleCategory1.touched ||
                          refVehicleCategory1.untouched)
                    }"
                    [(ngModel)]="SelectVehicleCategory"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Vehicle Category</option>
                    <option
                      *ngFor="let cat of vehicleCategoryData"
                      [value]="cat.Id"
                    >
                      {{ cat.Vehicle_Category }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Vehicle Model Name</span>
                  <select
                    name="VehicleModelName1"
                    id="VehicleModelName1"
                    #refVehicleModelName1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refVehicleModelName1.invalid,
                      'alert-warning':
                        refVehicleModelName1.invalid &&
                        (refVehicleModelName1.dirty ||
                          refVehicleModelName1.touched ||
                          refVehicleModelName1.untouched)
                    }"
                    [(ngModel)]="VehicleModel"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Vehicle Model Name</option>
                    <option
                      *ngFor="let data of vehicleModelData"
                      [value]="data.Id"
                    >
                      {{ data.Model_Name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="required-lable">Variant</span>
                  <input
                    required
                    type="text"
                    #refVariant1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refVariant1.invalid,
                      'alert-warning':
                        refVariant1.invalid &&
                        (refVariant1.dirty ||
                          refVariant1.touched ||
                          refVariant1.untouched)
                    }"
                    [(ngModel)]="Variant"
                    name="Variant1"
                    id="Variant1"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-4">
                  <span class="">Manufacture Year</span>
                  <input
                    name="MefgYear1"
                    id="MefgYear1"
                    #refMefgYear1="ngModel"
                    maxlength="4"
                    minlength="4"
                    numbersOnly
                    [ngClass]="{
                      'is-invalid': f.submitted && refMefgYear1.invalid,
                      'alert-warning':
                        refMefgYear1.invalid &&
                        (refMefgYear1.dirty ||
                          refMefgYear1.touched ||
                          refMefgYear1.untouched)
                    }"
                    [(ngModel)]="ManufactureYear"
                    class="form-control input-text-css"
                  />
                  <!-- <input name="MefgYear1" id="MefgYear1" #refMefgYear1="ngModel"
                  [ngClass]="{ 'is-invalid': f.submitted && refMefgYear1.invalid, 'alert-warning': refMefgYear1.invalid  && (refMefgYear1.dirty || refMefgYear1.touched || refMefgYear1.untouched) }"
                  [(ngModel)]="ManufactureYear" value="{{ ManufactureYear | date:'yyyy' }}"
                  class="form-control input-text-css" [(bsValue)]="ManufactureYear" bsDatepicker> -->
                </div>
                <div class="col-md-4">
                  <span class="">Vehicle Registation No</span>
                  <input
                    type="text"
                    #refVehicleRegistationNo1="ngModel"
                    (blur)="checkRegNonumber()"
                    (keypress)="onKeyPress($event)"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refVehicleRegistationNo1.invalid,
                      'alert-warning':
                        refVehicleRegistationNo1.invalid &&
                        (refVehicleRegistationNo1.dirty ||
                          refVehicleRegistationNo1.touched ||
                          refVehicleRegistationNo1.untouched)
                    }"
                    [(ngModel)]="VehicleRegistationNo"
                    name="VehicleRegistationNo1"
                    id="VehicleRegistationNo1"
                    class="form-control input-text-css"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-4">
                  <span class="">Registation Date</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker3"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="RegistationDate1"
                      id="RegistationDate1"
                      #refRegistationDate1="ngModel"
                      (dateChange)="ChangeRegistationDate($event)"
                      [min]="ManufactureYear"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refRegistationDate1.invalid,
                        'alert-warning':
                          '' &&
                          (refRegistationDate1.dirty ||
                            refRegistationDate1.touched ||
                            refRegistationDate1.untouched)
                      }"
                      [(ngModel)]="RegistationDate"
                      class="form-control input-text-css"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker3"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                  </div>
                  <!-- <input
                    name="RegistationDate1"
                    id="RegistationDate1"
                    #refRegistationDate1="ngModel"
                    (ngModelChange)="ChangeRegistationDate($event)"
                    [minDate]="ManufactureYear"
                    [ngClass]="{
                      'is-invalid': f.submitted && refRegistationDate1.invalid,
                      'alert-warning':
                        '' &&
                        (refRegistationDate1.dirty ||
                          refRegistationDate1.touched ||
                          refRegistationDate1.untouched)
                    }"
                    [(ngModel)]="RegistationDate"
                    value="{{ RegistationDate | date : 'dd/MM/yyyy' }}"
                    class="form-control input-text-css"
                    [(bsValue)]="RegistationDate"
                    bsDatepicker
                  /> -->
                </div>
                <div class="col-md-4">
                  <span class="">Registation Expiry Date</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker4"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="RegistationExpiryDate1"
                      id="RegistationExpiryDate1"
                      #refRegistationExpiryDate1="ngModel"
                      (dateChange)="ChangeExpiryDate($event)"
                      [min]="RegistationDate"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refRegistationExpiryDate1.invalid,
                        'alert-warning':
                          '' &&
                          (refRegistationExpiryDate1.dirty ||
                            refRegistationExpiryDate1.touched ||
                            refRegistationExpiryDate1.untouched)
                      }"
                      [(ngModel)]="RegistationExpiryDate"
                      class="form-control input-text-css"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker4"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker4></mat-datepicker>
                  </div>
                  <!-- <input
                    name="RegistationExpiryDate1"
                    id="RegistationExpiryDate1"
                    #refRegistationExpiryDate1="ngModel"
                    (ngModelChange)="ChangeExpiryDate($event)"
                    [minDate]="RegistationDate"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refRegistationExpiryDate1.invalid,
                      'alert-warning':
                        '' &&
                        (refRegistationExpiryDate1.dirty ||
                          refRegistationExpiryDate1.touched ||
                          refRegistationExpiryDate1.untouched)
                    }"
                    [(ngModel)]="RegistationExpiryDate"
                    value="{{ RegistationExpiryDate | date : 'dd/MM/yyyy' }}"
                    class="form-control input-text-css"
                    [(bsValue)]="RegistationExpiryDate"
                    bsDatepicker
                  /> -->
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Fuel Type</span>
                  <select
                    name="FuelType1"
                    id="FuelType1"
                    #refFuelType1="ngModel"
                    [(ngModel)]="SelectFuelType"
                    [ngClass]="{
                      'is-invalid': f.submitted && refFuelType1.invalid,
                      'alert-warning':
                        refFuelType1.invalid &&
                        (refFuelType1.dirty ||
                          refFuelType1.touched ||
                          refFuelType1.untouched)
                    }"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Fuel Type</option>
                    <option
                      *ngFor="let Type of FuelTypeOption"
                      [value]="Type.Id"
                    >
                      {{ Type.Value }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4" *ngIf="commercialVehicle == 'Commercial'">
                  <span class="">Road Tax Upto</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker5"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="RoadTaxUpto1"
                      id="RoadTaxUpto1"
                      #refRoadTaxUpto1="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refRoadTaxUpto1.invalid,
                        'alert-warning':
                          '' &&
                          (refRoadTaxUpto1.dirty ||
                            refRoadTaxUpto1.touched ||
                            refRoadTaxUpto1.untouched)
                      }"
                      [(ngModel)]="RoadTaxUpto"
                      class="form-control input-text-css"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker5"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker5></mat-datepicker>
                  </div>
                  <!-- <input
                    name="RoadTaxUpto1"
                    id="RoadTaxUpto1"
                    #refRoadTaxUpto1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refRoadTaxUpto1.invalid,
                      'alert-warning':
                        '' &&
                        (refRoadTaxUpto1.dirty ||
                          refRoadTaxUpto1.touched ||
                          refRoadTaxUpto1.untouched)
                    }"
                    [(ngModel)]="RoadTaxUpto"
                    value="{{ RoadTaxUpto | date : 'dd/MM/yyyy' }}"
                    class="form-control input-text-css"
                    [(bsValue)]="RoadTaxUpto"
                    bsDatepicker
                  /> -->
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Color</span>
                  <input
                    required
                    type="text"
                    #refClr1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refClr1.invalid,
                      'alert-warning':
                        refClr1.invalid &&
                        (refClr1.dirty || refClr1.touched || refClr1.untouched)
                    }"
                    [(ngModel)]="Color"
                    name="Clr1"
                    id="Clr1"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-4" *ngIf="commercialVehicle == 'Commercial'">
                  <span class="">Fitness Upto</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker6"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="FitnessUpto1"
                      id="FitnessUpto1"
                      #refFitnessUpto1="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refFitnessUpto1.invalid,
                        'alert-warning':
                          '' &&
                          (refFitnessUpto1.dirty ||
                            refFitnessUpto1.touched ||
                            refFitnessUpto1.untouched)
                      }"
                      [(ngModel)]="FitnessUpto"
                      class="form-control input-text-css"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker6"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker6></mat-datepicker>
                  </div>
                  <!-- <input
                    name="FitnessUpto1"
                    id="FitnessUpto1"
                    #refFitnessUpto1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refFitnessUpto1.invalid,
                      'alert-warning':
                        '' &&
                        (refFitnessUpto1.dirty ||
                          refFitnessUpto1.touched ||
                          refFitnessUpto1.untouched)
                    }"
                    [(ngModel)]="FitnessUpto"
                    value="{{ FitnessUpto | date : 'dd/MM/yyyy' }}"
                    class="form-control input-text-css"
                    [(bsValue)]="FitnessUpto"
                    bsDatepicker
                  /> -->
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4" *ngIf="commercialVehicle == 'Commercial'">
                  <span class="">Permit Upto</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker7"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="PermitUpto1"
                      id="PermitUpto1"
                      #refPermitUpto1="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refPermitUpto1.invalid,
                        'alert-warning':
                          '' &&
                          (refPermitUpto1.dirty ||
                            refPermitUpto1.touched ||
                            refPermitUpto1.untouched)
                      }"
                      [(ngModel)]="PermitUpto"
                      class="form-control input-text-css"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker7"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker7></mat-datepicker>
                  </div>
                  <!-- <input
                    name="PermitUpto1"
                    id="PermitUpto1"
                    #refPermitUpto1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refPermitUpto1.invalid,
                      'alert-warning':
                        '' &&
                        (refPermitUpto1.dirty ||
                          refPermitUpto1.touched ||
                          refPermitUpto1.untouched)
                    }"
                    [(ngModel)]="PermitUpto"
                    value="{{ PermitUpto | date : 'dd/MM/yyyy' }}"
                    class="form-control input-text-css"
                    [(bsValue)]="PermitUpto"
                    bsDatepicker
                  /> -->
                </div>

                <div class="col-md-4">
                  <span class="required-lable">Vehicle Cost</span>
                  <input
                    required
                    type="number"
                    numbersOnly
                    min="0"
                    #reVC1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && reVC1.invalid,
                      'alert-warning':
                        reVC1.invalid &&
                        (reVC1.dirty || reVC1.touched || reVC1.untouched)
                    }"
                    [(ngModel)]="VehicleCost"
                    name="VC1"
                    id="VC1"
                    class="form-control input-text-css"
                  />
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-4" *ngIf="commercialVehicle == 'Commercial'">
                  <span class="">Route</span>
                  <input
                    type="text"
                    [(ngModel)]="Route"
                    name="Route1"
                    id="Route1"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-4">
                  <span class="">Engine No</span>
                  <input
                    type="text"
                    [(ngModel)]="EngineNo"
                    #refEngineNo1="ngModel"
                    name="ENO1"
                    [required]="
                      (InvoiceNo != '' && InvoiceNo != null) ||
                      (VehicleRegistationNo != '' &&
                        VehicleRegistationNo != null)
                    "
                    [ngClass]="{
                      'is-invalid': f.submitted && refEngineNo1.invalid,
                      'alert-warning':
                        refEngineNo1.invalid &&
                        (refEngineNo1.dirty ||
                          refEngineNo1.touched ||
                          refEngineNo1.untouched)
                    }"
                    id="ENO1"
                    (blur)="checkDuplication_Vehicle('EngineNo')"
                    class="form-control input-text-css"
                    placeholder="Engine No"
                  />
                </div>
                <div class="col-md-4">
                  <span class="">Chassis No</span>
                  <input
                    type="text"
                    #refChassisNo1="ngModel"
                    [(ngModel)]="ChassisNo"
                    name="CNO1"
                    [required]="
                      (InvoiceNo != '' && InvoiceNo != null) ||
                      (VehicleRegistationNo != '' &&
                        VehicleRegistationNo != null)
                    "
                    [ngClass]="{
                      'is-invalid': f.submitted && refChassisNo1.invalid,
                      'alert-warning':
                        refChassisNo1.invalid &&
                        (refChassisNo1.dirty ||
                          refChassisNo1.touched ||
                          refChassisNo1.untouched)
                    }"
                    id="CNO1"
                    (blur)="checkDuplication_Vehicle('ChassisNo')"
                    class="form-control input-text-css"
                    placeholder="Chassis No"
                  />
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="">Key No</span>
                  <input
                    type="text"
                    [(ngModel)]="KeyNo"
                    name="KEYNO1"
                    id="KEYNO1"
                    class="form-control input-text-css"
                  />
                </div>
              </div>
              <div class="col-md-3 mt-4">
                <mat-checkbox
                  [(ngModel)]="RC_HPNEndorsment"
                  id="RC_HPNEndorsment"
                  name="RC_HPNEndorsment"
                >
                  RC HPNEndorsment
                </mat-checkbox>
              </div>
              <div class="col-md-3 mt-4" *ngIf="newVehicle">
                <mat-checkbox
                  [(ngModel)]="Invoice_HPNEndorsment"
                  id="Invoice_HPNEndorsment"
                  name="Invoice_HPNEndorsment"
                >
                  Invoice HPNEndorsment
                </mat-checkbox>
              </div>
              <hr />
              <ng-container *ngIf="newVehicle">
                <div class="row mt-2">
                  <div class="col-md-12">
                    <h5 style="color: black">New Vehicle</h5>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-4">
                    <span class="required-lable">DO. No.</span>
                    <input
                      required
                      type="text"
                      #refDONo1="ngModel"
                      [(ngModel)]="DeliveryorderNo"
                      [ngClass]="{
                        'is-invalid': f.submitted && refDONo1.invalid,
                        'alert-warning':
                          refDONo1.invalid &&
                          (refDONo1.dirty ||
                            refDONo1.touched ||
                            refDONo1.untouched)
                      }"
                      name="DoNO1"
                      id="DoNO1"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable">DO. Do Date.</span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker8"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        required
                        #refDODoDate1="ngModel"
                        [(ngModel)]="DeliveryorderDate"
                        name="DODoDate1"
                        id="DODoDate1"
                        [ngClass]="{
                          'is-invalid': f.submitted && refDODoDate1.invalid,
                          'alert-warning':
                            refDODoDate1.invalid &&
                            (refDODoDate1.dirty ||
                              refDODoDate1.touched ||
                              refDODoDate1.untouched)
                        }"
                        (dateChange)="ChangeDeliveryorderDate($event)"
                        [max]="RegistationDate"
                        class="form-control input-text-css"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker8"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker8></mat-datepicker>
                    </div>
                    <!-- <input
                      required
                      type="text"
                      #refDODoDate1="ngModel"
                      [(ngModel)]="DeliveryorderDate"
                      name="DODoDate1"
                      id="DODoDate1"
                      [ngClass]="{
                        'is-invalid': f.submitted && refDODoDate1.invalid,
                        'alert-warning':
                          refDODoDate1.invalid &&
                          (refDODoDate1.dirty ||
                            refDODoDate1.touched ||
                            refDODoDate1.untouched)
                      }"
                      (ngModelChange)="ChangeDeliveryorderDate($event)"
                      [maxDate]="RegistationDate"
                      value="{{ DeliveryorderDate | date : 'dd/MM/yyyy' }}"
                      placeholder="DD/MM/YYYY"
                      class="form-control input-text-css"
                      [(bsValue)]="DeliveryorderDate"
                      bsDatepicker
                    /> -->
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable">Estimation Amount</span>
                    <input
                      required
                      type="number"
                      min="0"
                      numbersOnly
                      #refEstimationAmount1="ngModel"
                      [(ngModel)]="EstimationAmount"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refEstimationAmount1.invalid,
                        'alert-warning':
                          refEstimationAmount1.invalid &&
                          (refEstimationAmount1.dirty ||
                            refEstimationAmount1.touched ||
                            refEstimationAmount1.untouched)
                      }"
                      name="EstimationAmount1"
                      id="EstimationAmount1"
                      class="form-control input-text-css"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-4">
                    <span class="">Invoice No.</span>
                    <input
                      type="text"
                      #refInvoiceNo1="ngModel"
                      [(ngModel)]="InvoiceNo"
                      [ngClass]="{
                        'is-invalid': f.submitted && refInvoiceNo1.invalid,
                        'alert-warning':
                          refInvoiceNo1.invalid &&
                          (refInvoiceNo1.dirty ||
                            refInvoiceNo1.touched ||
                            refInvoiceNo1.untouched)
                      }"
                      name="InvoiceNo1"
                      id="InvoiceNo1"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="">Invoice Date</span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker9"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        #refInvoiceDate1="ngModel"
                        [(ngModel)]="InvoiceDate"
                        name="InvoiceDate1"
                        id="InvoiceDate1"
                        (dateChange)="ChangeInvoiveDateDate($event)"
                        [min]="DeliveryorderDate"
                        [max]="RegistationDate"
                        class="form-control input-text-css"
                        [ngClass]="{
                          'is-invalid': f.submitted && refInvoiceDate1.invalid,
                          'alert-warning':
                            refInvoiceDate1.invalid &&
                            (refInvoiceDate1.dirty ||
                              refInvoiceDate1.touched ||
                              refInvoiceDate1.untouched)
                        }"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker9"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker9></mat-datepicker>
                    </div>
                    <!-- <input
                      type="text"
                      #refInvoiceDate1="ngModel"
                      [(ngModel)]="InvoiceDate"
                      name="InvoiceDate1"
                      id="InvoiceDate1"
                      (ngModelChange)="ChangeInvoiveDateDate($event)"
                      [minDate]="DeliveryorderDate"
                      [maxDate]="RegistationDate"
                      value="{{ InvoiveDate | date : 'dd/MM/yyyy' }}"
                      placeholder="DD/MM/YYYY"
                      class="form-control input-text-css"
                      [ngClass]="{
                        'is-invalid': f.submitted && refInvoiceDate1.invalid,
                        'alert-warning':
                          refInvoiceDate1.invalid &&
                          (refInvoiceDate1.dirty ||
                            refInvoiceDate1.touched ||
                            refInvoiceDate1.untouched)
                      }"
                      [(bsValue)]="InvoiveDate"
                      bsDatepicker
                    /> -->
                  </div>
                  <div class="col-md-4">
                    <span class="">Invoice Value</span>
                    <input
                      type="text"
                      #refInvoiceValue1="ngModel"
                      [(ngModel)]="InvoiceValue"
                      [ngClass]="{
                        'is-invalid': f.submitted && refInvoiceValue1.invalid,
                        'alert-warning':
                          refInvoiceValue1.invalid &&
                          (refInvoiceValue1.dirty ||
                            refInvoiceValue1.touched ||
                            refInvoiceValue1.untouched)
                      }"
                      name="InvoiceValue1"
                      id="InvoiceValue1"
                      class="form-control input-text-css"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-4">
                    <span class="required-lable">Dealer Contact Person</span>
                    <input
                      required
                      type="text"
                      #refDealerContactPerson1="ngModel"
                      [(ngModel)]="DealerContactPerson"
                      name="DealerContactPerson1"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refDealerContactPerson1.invalid,
                        'alert-warning':
                          refDealerContactPerson1.invalid &&
                          (refDealerContactPerson1.dirty ||
                            refDealerContactPerson1.touched ||
                            refDealerContactPerson1.untouched)
                      }"
                      id="DealerContactPerson1"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable">Dealer Contact No.</span>
                    <input
                      required
                      type="text"
                      #refDealerContactNo1="ngModel"
                      [(ngModel)]="DealerContactNo"
                      numbersOnly
                      maxlength="10"
                      minlength="10"
                      name="DealerContactNo1"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refDealerContactNo1.invalid,
                        'alert-warning':
                          refDealerContactNo1.invalid &&
                          (refDealerContactNo1.dirty ||
                            refDealerContactNo1.touched ||
                            refDealerContactNo1.untouched)
                      }"
                      id="DealerContactNo1"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable">Quotation in favour of</span>
                    <select
                      name="DOIssuedinfavourof1"
                      #refDOIssuedinfavourof1="ngModel"
                      [(ngModel)]="IssuedInFavourOf"
                      id="DOIssuedinfavourof1"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refDOIssuedinfavourof1.invalid,
                        'alert-warning':
                          refDOIssuedinfavourof1.invalid &&
                          (refDOIssuedinfavourof1.dirty ||
                            refDOIssuedinfavourof1.touched ||
                            refDOIssuedinfavourof1.untouched)
                      }"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Customer</option>
                      <option
                        [value]="cus.CustomerId"
                        *ngFor="let cus of CustomerList"
                      >
                        {{ cus.Customer }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-4">
                    <span class="required-lable">Remark</span>
                    <input
                      required
                      type="text"
                      #refRemark1="ngModel"
                      [(ngModel)]="Remark"
                      [ngClass]="{
                        'is-invalid': f.submitted && refRemark1.invalid,
                        'alert-warning':
                          refRemark1.invalid &&
                          (refRemark1.dirty ||
                            refRemark1.touched ||
                            refRemark1.untouched)
                      }"
                      name="Remark1"
                      id="Remark1"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable">Dealer</span>
                    <select
                      name="DealerId1"
                      id="DealerId1"
                      #refDealerId1="ngModel"
                      [(ngModel)]="Dealer"
                      [ngClass]="{
                        'is-invalid': f.submitted && refDealerId1.invalid,
                        'alert-warning':
                          refDealerId1.invalid &&
                          (refDealerId1.dirty ||
                            refDealerId1.touched ||
                            refDealerId1.untouched)
                      }"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Dealer</option>
                      <option
                        *ngFor="let collection of DealerList"
                        [value]="collection.PartnerId"
                      >
                        {{ collection.Partner }}
                      </option>
                    </select>
                  </div>
                </div>
                <hr />
              </ng-container>

              <ng-container *ngIf="usedVehicle">
                <div class="row mt-2">
                  <div class="col-md-12">
                    <h5 style="color: black">Used Vehicles</h5>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-4">
                    <span class="">Valuation Done By</span>
                    <select
                      name="ValuationDoneBy1"
                      id="ValuationDoneBy1"
                      #refValuationDoneBy1="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refValuationDoneBy1.invalid,
                        'alert-warning':
                          refValuationDoneBy1.invalid &&
                          (refValuationDoneBy1.dirty ||
                            refValuationDoneBy1.touched ||
                            refValuationDoneBy1.untouched)
                      }"
                      [(ngModel)]="ValuationDoneBy"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Valuation Done By</option>
                      <option
                        *ngFor="let collection of CollectionExecutiveData"
                        [value]="collection.EmpId"
                      >
                        {{ collection.Emp_FirstName }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-4">
                    <span class="">Valuation Date</span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker10"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        #refValuationDate1="ngModel"
                        [(ngModel)]="ValuationDate"
                        name="ValuationDate1"
                        id="ValuationDate1"
                        [ngClass]="{
                          'is-invalid':
                            f.submitted && refValuationDate1.invalid,
                          'alert-warning':
                            refValuationDate1.invalid &&
                            (refValuationDate1.dirty ||
                              refValuationDate1.touched ||
                              refValuationDate1.untouched)
                        }"
                        class="form-control input-text-css"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker10"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker10></mat-datepicker>
                    </div>
                    <!-- <input
                      type="text"
                      #refValuationDate1="ngModel"
                      [(ngModel)]="ValuationDate"
                      name="ValuationDate1"
                      id="ValuationDate1"
                      [ngClass]="{
                        'is-invalid': f.submitted && refValuationDate1.invalid,
                        'alert-warning':
                          refValuationDate1.invalid &&
                          (refValuationDate1.dirty ||
                            refValuationDate1.touched ||
                            refValuationDate1.untouched)
                      }"
                      value="{{ ValuationDate | date : 'dd/MM/yyyy' }}"
                      placeholder="DD/MM/YYYY"
                      class="form-control input-text-css"
                      [(bsValue)]="ValuationDate"
                      bsDatepicker
                    /> -->
                  </div>
                  <div class="col-md-4">
                    <span class="">Valuation Amount</span>
                    <input
                      type="number"
                      min="0"
                      numbersOnly
                      #refValuationAmount1="ngModel"
                      [(ngModel)]="ValutionAmount"
                      name="ValuationAmount1"
                      id="ValuationAmount1"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refValuationAmount1.invalid,
                        'alert-warning':
                          refValuationAmount1.invalid &&
                          (refValuationAmount1.dirty ||
                            refValuationAmount1.touched ||
                            refValuationAmount1.untouched)
                      }"
                      class="form-control input-text-css"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-4">
                    <span class="">Valuation Type</span>
                    <select
                      name="ValuationType1"
                      id="ValuationType1"
                      #refValuationType1="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refValuationType1.invalid,
                        'alert-warning':
                          refValuationType1.invalid &&
                          (refValuationType1.dirty ||
                            refValuationType1.touched ||
                            refValuationType1.untouched)
                      }"
                      [(ngModel)]="ValuationType"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Valuation Type</option>
                      <option value="Valuation">Valuation</option>
                      <option value="Grid">Grid</option>
                      <option value="IDV">IDV</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <span class="">Other Valuation Type</span>
                    <input
                      type="text"
                      #refOtherValuationType1="ngModel"
                      [(ngModel)]="OtherValuationType"
                      name="OtherValuationType1"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refOtherValuationType1.invalid,
                        'alert-warning':
                          refOtherValuationType1.invalid &&
                          (refOtherValuationType1.dirty ||
                            refOtherValuationType1.touched ||
                            refOtherValuationType1.untouched)
                      }"
                      id="OtherValuationType1"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="">Other Valuation Amount</span>
                    <input
                      type="number"
                      min="0"
                      numbersOnly
                      #refOtherValuationAmt1="ngModel"
                      [(ngModel)]="OtherValuationAmount"
                      name="OtherValuationAmt1"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refOtherValuationAmt1.invalid,
                        'alert-warning':
                          refOtherValuationAmt1.invalid &&
                          (refOtherValuationAmt1.dirty ||
                            refOtherValuationAmt1.touched ||
                            refOtherValuationAmt1.untouched)
                      }"
                      id="OtherValuationAmt1"
                      class="form-control input-text-css"
                    />
                  </div>
                </div>
                <hr />
              </ng-container>
              <ng-container>
                <div class="row mt-2">
                  <div class="col-md-12 mt-2 upload">
                    <B> Upload Vehicle Images </B>
                    <input
                      #docFile
                      type="file"
                      [multiple]="false"
                      accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                      (change)="
                        fileChangeListenerAttachment(docFile.files, docFile)
                      "
                      style="display: none"
                    />
                    <div
                      class="row m-0 mt-2 align-items-center"
                      *ngFor="let report of AssetsAttachment; let i = index"
                    >
                      <div class="col-md-1 pl-0">
                        {{ i + 1 }}
                      </div>
                      <div class="col-md-3">
                        <input
                          type="text"
                          placeholder="Title"
                          name="title{{ i }}"
                          id="title{{ i }}"
                          #refTitle="ngModel"
                          [(ngModel)]="report.Title"
                          class="form-control input-text-css"
                        />
                      </div>
                      <div class="col-md-3">
                        <p class="m-0 row">
                          <i
                            class="fa fa-upload ml-2"
                            *ngIf="!report.DocFileName"
                            (click)="docFile.click()"
                            aria-hidden="true"
                          ></i>
                          <ng-container *ngIf="report.DocFileName">
                            <div>
                              <i
                                class="fa fa-file-pdf mr-2"
                                aria-hidden="true"
                              ></i
                              >{{ report.DocFileName }}
                              <i
                                class="fa fa-times ml-2"
                                aria-hidden="true"
                                style="cursor: pointer"
                                (click)="removeFileAttachment(i)"
                              ></i>
                            </div>
                          </ng-container>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="propertyForms == 'Propertys'">
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="required-lable">Property Owner Name</span>
                  <select
                    name="CustomerId1"
                    id="CustomerId1"
                    #refCustomerId1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refCustomerId1.invalid,
                      'alert-warning':
                        refCustomerId1.invalid &&
                        (refCustomerId1.dirty ||
                          refCustomerId1.touched ||
                          refCustomerId1.untouched)
                    }"
                    [(ngModel)]="PropertyOwnerName"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Owner</option>
                    <option
                      [value]="cus.CustomerId"
                      *ngFor="let cus of CustomerList"
                    >
                      {{ cus.Customer }}
                    </option>
                  </select>
                </div>
                <div class="col-md-8">
                  <span class="required-lable">Address Of Property</span>
                  <textarea
                    required
                    type="text"
                    #reAddress1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && reAddress1.invalid,
                      'alert-warning':
                        reAddress1.invalid &&
                        (reAddress1.dirty ||
                          reAddress1.touched ||
                          reAddress1.untouched)
                    }"
                    [(ngModel)]="AddressOfProperty"
                    name="Address1"
                    id="Address1"
                    class="form-control input-text-css"
                  ></textarea>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="required-lable">Reg State</span>
                  <select
                    name="State1"
                    id="State1"
                    #refState1="ngModel"
                    [(ngModel)]="customerModel.Firm_RegState"
                    class="form-control input-text-css"
                    required
                    [ngClass]="{
                      'is-invalid': f.submitted && refState1.invalid,
                      'alert-warning':
                        refState1.invalid &&
                        (refState1.dirty ||
                          refState1.touched ||
                          refState1.untouched)
                    }"
                    (change)="GetDistrickDropdownReg($event)"
                  >
                    <option value="">Select State</option>
                    <option
                      *ngFor="let state of stateDropdown"
                      [value]="state.StateId"
                    >
                      {{ state.State_Name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Reg District</span>
                  <select
                    name="District1"
                    id="District1"
                    #refDistrict1="ngModel"
                    [(ngModel)]="customerModel.Firm_RegDistrict"
                    class="form-control input-text-css"
                    required
                    [ngClass]="{
                      'is-invalid': f.submitted && refDistrict1.invalid,
                      'alert-warning':
                        refDistrict1.invalid &&
                        (refDistrict1.dirty ||
                          refDistrict1.touched ||
                          refDistrict1.untouched)
                    }"
                    (change)="GetTehasilDropdownReg($event)"
                  >
                    <option value="">Select District</option>
                    <option
                      *ngFor="let district of districkDropdownReg"
                      [value]="district.DistrictId"
                    >
                      {{ district.District_Name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Reg Tehsil</span>
                  <select
                    name="Tasil1"
                    id="Tasil1"
                    #refTasil1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refTasil1.invalid,
                      'alert-warning':
                        refTasil1.invalid &&
                        (refTasil1.dirty ||
                          refTasil1.touched ||
                          refTasil1.untouched)
                    }"
                    [(ngModel)]="customerModel.Firm_RegTehsil"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Tehsil</option>
                    <option
                      *ngFor="let tehsil of tehasilDropdownReg"
                      [value]="tehsil.TehsilId"
                    >
                      {{ tehsil.Tehsil_Name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="required-lable">Type Of Property</span>
                  <select
                    name="TypeofProperty1"
                    id="TypeofProperty1"
                    #refTypeofProperty1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refTypeofProperty1.invalid,
                      'alert-warning':
                        refTypeofProperty1.invalid &&
                        (refTypeofProperty1.dirty ||
                          refTypeofProperty1.touched ||
                          refTypeofProperty1.untouched)
                    }"
                    [(ngModel)]="TypeOfProperty"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Type Of Property</option>
                    <option
                      *ngFor="let Type of TypeOfPropertyList"
                      [value]="Type.Id"
                    >
                      {{ Type.Value }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Nature Of Property</span>
                  <select
                    name="NatureofProperty1"
                    id="NatureofProperty1"
                    #refNatureofProperty1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refNatureofProperty1.invalid,
                      'alert-warning':
                        refNatureofProperty1.invalid &&
                        (refNatureofProperty1.dirty ||
                          refNatureofProperty1.touched ||
                          refNatureofProperty1.untouched)
                    }"
                    [(ngModel)]="NatureOfProperty"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Type Of Property</option>
                    <option
                      *ngFor="let Type of NatureOfPropertyList"
                      [value]="Type.Id"
                    >
                      {{ Type.Value }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Ownership Document</span>
                  <select
                    name="OwnershipDocument1"
                    id="OwnershipDocument1"
                    #refOwnershipDocument1="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refOwnershipDocument1.invalid,
                      'alert-warning':
                        refOwnershipDocument1.invalid &&
                        (refOwnershipDocument1.dirty ||
                          refOwnershipDocument1.touched ||
                          refOwnershipDocument1.untouched)
                    }"
                    [(ngModel)]="OwnershipDocument"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Ownership Document</option>
                    <option
                      *ngFor="let Type of OwnershipDocumentList"
                      [value]="Type.Id"
                    >
                      {{ Type.Value }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="required-lable">Ownership Type</span>
                  <select
                    name="OwnershipType1"
                    id="OwnershipType1"
                    #refOwnershipType1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refOwnershipType1.invalid,
                      'alert-warning':
                        refOwnershipType1.invalid &&
                        (refOwnershipType1.dirty ||
                          refOwnershipType1.touched ||
                          refOwnershipType1.untouched)
                    }"
                    [(ngModel)]="OwnershipType"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Ownership Type</option>
                    <option
                      *ngFor="let Type of OwnershipTypeList"
                      [value]="Type.Id"
                    >
                      {{ Type.Value }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Unit Of Measurement </span>
                  <select
                    name="UnitofMeasurement1"
                    id="UnitofMeasurement1"
                    #refUnitofMeasurement1="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refUnitofMeasurement1.invalid,
                      'alert-warning':
                        refUnitofMeasurement1.invalid &&
                        (refUnitofMeasurement1.dirty ||
                          refUnitofMeasurement1.touched ||
                          refUnitofMeasurement1.untouched)
                    }"
                    [(ngModel)]="UnitOfMeasurement"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Unit Of Measurement</option>
                    <option value="Sq. Ft">Sq. Ft</option>
                    <option value="Sq. Yards">Sq. Yards</option>
                    <option value="Sq. Metre">Sq. Metre</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Total Area </span>
                  <input
                    required
                    type="text"
                    appTwoDigitDecimaNumber
                    #reTotalArea1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && reTotalArea1.invalid,
                      'alert-warning':
                        reTotalArea1.invalid &&
                        (reTotalArea1.dirty ||
                          reTotalArea1.touched ||
                          reTotalArea1.untouched)
                    }"
                    [(ngModel)]="TotalArea"
                    name="TotalArea1"
                    id="TotalArea1"
                    class="form-control input-text-css"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="required-lable">Constructed Area </span>
                  <input
                    required
                    type="text"
                    appTwoDigitDecimaNumber
                    #reConstructedArea1="ngModel"
                    [(ngModel)]="ConstructedArea"
                    name="ConstructedArea1"
                    id="ConstructedArea1"
                    (change)="GetConstructedArea($event)"
                    [ngClass]="{
                      'is-invalid': f.submitted && reConstructedArea1.invalid,
                      'alert-warning':
                        reConstructedArea1.invalid &&
                        (reConstructedArea1.dirty ||
                          reConstructedArea1.touched ||
                          reConstructedArea1.untouched)
                    }"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-4">
                  <span class="">Type Of Mortgage </span>
                  <select
                    name="TypeofMortgage1"
                    id="TypeofMortgage1"
                    #refTypeofMortgage1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refTypeofMortgage1.invalid,
                      'alert-warning':
                        refTypeofMortgage1.invalid &&
                        (refTypeofMortgage1.dirty ||
                          refTypeofMortgage1.touched ||
                          refTypeofMortgage1.untouched)
                    }"
                    [(ngModel)]="TypeOfMortgage"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Type Of Mortgage</option>
                    <option value="Registered">Registered</option>
                    <option value="Equitable">Equitable</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="">Mortgage singed by</span>
                  <select
                    name="MortgageSingedBy1"
                    id="MortgageSingedBy1"
                    #refMortgageSingedBy1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refMortgageSingedBy1.invalid,
                      'alert-warning':
                        refMortgageSingedBy1.invalid &&
                        (refMortgageSingedBy1.dirty ||
                          refMortgageSingedBy1.touched ||
                          refMortgageSingedBy1.untouched)
                    }"
                    [(ngModel)]="MortgageSingedBy"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Mortgage singed by</option>
                    <option
                      *ngFor="let collection of CollectionExecutiveData"
                      [value]="collection.EmpId"
                    >
                      {{ collection.Emp_FirstName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="required-lable">Valution Amount</span>
                  <input
                    required
                    type="number"
                    min="0"
                    numbersOnly
                    #refValuationAmount1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refValuationAmount1.invalid,
                      'alert-warning':
                        refValuationAmount1.invalid &&
                        (refValuationAmount1.dirty ||
                          refValuationAmount1.touched ||
                          refValuationAmount1.untouched)
                    }"
                    [(ngModel)]="ValutionAmount"
                    name="ValuationAmount1"
                    id="ValuationAmount1"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-4">
                  <span class="">Valution Done By</span>
                  <select
                    name="ValuationDoneBy1"
                    id="ValuationDoneBy1"
                    #refValuationDoneBy1="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refValuationDoneBy1.invalid,
                      'alert-warning':
                        refValuationDoneBy1.invalid &&
                        (refValuationDoneBy1.dirty ||
                          refValuationDoneBy1.touched ||
                          refValuationDoneBy1.untouched)
                    }"
                    [(ngModel)]="ValuationDoneBy"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Valuation Done By</option>
                    <option
                      *ngFor="let collection of CollectionExecutiveData"
                      [value]="collection.EmpId"
                    >
                      {{ collection.Emp_FirstName }}
                    </option>

                    <option value="0">Other</option>
                  </select>
                </div>
                <div
                  class="col-md-4"
                  *ngIf="ValuationDoneBy != '' && ValuationDoneBy == 0"
                >
                  <span class="">Valution Done By Name</span>
                  <input
                    type="text"
                    #refValuationDoneByOther1="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refValuationDoneByOther1.invalid,
                      'alert-warning':
                        refValuationDoneByOther1.invalid &&
                        (refValuationDoneByOther1.dirty ||
                          refValuationDoneByOther1.touched ||
                          refValuationDoneByOther1.untouched)
                    }"
                    [(ngModel)]="ValuationDoneByOther"
                    name="ValuationDoneByOther1"
                    id="ValuationDoneByOther1"
                    class="form-control input-text-css"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <span class="">Valution Remark</span>
                  <textarea
                    type="text"
                    #refValuationRemark1="ngModel"
                    [(ngModel)]="ValuationRemark"
                    name="ValuationRemark1"
                    id="ValuationRemark1"
                    [ngClass]="{
                      'is-invalid': f.submitted && refValuationRemark1.invalid,
                      'alert-warning':
                        refValuationRemark1.invalid &&
                        (refValuationRemark1.dirty ||
                          refValuationRemark1.touched ||
                          refValuationRemark1.untouched)
                    }"
                    class="form-control input-text-css"
                  ></textarea>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="">Search Report Done By</span>
                  <select
                    name="SearchReportDoneBy1"
                    id="SearchReportDoneBy1"
                    #refSearchReportDoneBy1="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refSearchReportDoneBy1.invalid,
                      'alert-warning':
                        refSearchReportDoneBy1.invalid &&
                        (refSearchReportDoneBy1.dirty ||
                          refSearchReportDoneBy1.touched ||
                          refSearchReportDoneBy1.untouched)
                    }"
                    [(ngModel)]="SearchReportDoneBy"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Report Done By</option>
                    <option
                      *ngFor="let collection of CollectionExecutiveData"
                      [value]="collection.EmpId"
                    >
                      {{ collection.Emp_FirstName }}
                    </option>

                    <option value="0">Other</option>
                  </select>
                </div>
                <div
                  class="col-md-4"
                  *ngIf="SearchReportDoneBy != '' && SearchReportDoneBy == 0"
                >
                  <span class="">Search Report Done By Name</span>
                  <input
                    type="text"
                    #refSearchReportDoneByOther1="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refSearchReportDoneByOther1.invalid,
                      'alert-warning':
                        refSearchReportDoneByOther1.invalid &&
                        (refSearchReportDoneByOther1.dirty ||
                          refSearchReportDoneByOther1.touched ||
                          refSearchReportDoneByOther1.untouched)
                    }"
                    [(ngModel)]="SearchReportDoneByOther"
                    name="SearchReportDoneByOther1"
                    id="SearchReportDoneByOther1"
                    class="form-control input-text-css"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <span class="">Search Report Remark</span>
                  <textarea
                    type="text"
                    #refSearchReportRemark1="ngModel"
                    [(ngModel)]="SearchReportRemark"
                    name="SearchReportRemark1"
                    id="SearchReportRemark1"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refSearchReportRemark1.invalid,
                      'alert-warning':
                        refSearchReportRemark1.invalid &&
                        (refSearchReportRemark1.dirty ||
                          refSearchReportRemark1.touched ||
                          refSearchReportRemark1.untouched)
                    }"
                    class="form-control input-text-css"
                  ></textarea>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-2">
                  <b style="color: black"> Location </b>
                </div>
                <div class="col-md-4">
                  <span class="">Latitude</span>
                  <input
                    type="number"
                    step="00.000001"
                    #refLatitude1="ngModel"
                    placeholder="Latitude"
                    [ngClass]="{
                      'is-invalid': f.submitted && refLatitude1.invalid,
                      'alert-warning':
                        refLatitude1.invalid &&
                        (refLatitude1.dirty ||
                          refLatitude1.touched ||
                          refLatitude1.untouched)
                    }"
                    [(ngModel)]="latitude"
                    name="Latitude1"
                    id="Latitude1"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-4">
                  <span class="">Longitude</span>
                  <input
                    type="number"
                    step="00.000001"
                    #refLongitude1="ngModel"
                    placeholder="Longitude"
                    [ngClass]="{
                      'is-invalid': f.submitted && refLongitude1.invalid,
                      'alert-warning':
                        refLongitude1.invalid &&
                        (refLongitude1.dirty ||
                          refLongitude1.touched ||
                          refLongitude1.untouched)
                    }"
                    [(ngModel)]="longitude"
                    name="Longitude1"
                    id="Longitude1"
                    class="form-control input-text-css"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-6">
                  <p class="m-0 row">
                    Upload Valuation Report:<i
                      class="fa fa-upload ml-2"
                      *ngIf="!ValuationRemarkDoc"
                      (click)="docFile.click()"
                      aria-hidden="true"
                    ></i>
                    <ng-container *ngIf="ValuationRemarkDoc">
                      <div>
                        <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                        <ng-container *ngIf="ValuationDocData">{{
                          ValuationRemarkDoc
                        }}</ng-container>
                        <a
                          href="{{ docBaseUrl }}{{ LoanAcNo }}/{{
                            ValuationRemarkDoc
                          }}"
                          class="ml-3"
                          target="_blank"
                          *ngIf="!ValuationDocData"
                        >
                          {{ ValuationRemarkDoc }}
                        </a>
                        <i
                          class="fa fa-times ml-2"
                          aria-hidden="true"
                          style="cursor: pointer"
                          (click)="removeFile(docFile)"
                        ></i>
                      </div>
                    </ng-container>
                  </p>
                  <input
                    #docFile
                    type="file"
                    [multiple]="false"
                    accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                    (change)="fileChangeListener(docFile.files)"
                    style="display: none"
                  />
                </div>
                <div class="col-md-6">
                  <p class="m-0 row">
                    Upload Search Report:<i
                      class="fa fa-upload ml-2"
                      *ngIf="!SearchReportRemarkDoc"
                      (click)="docFile1.click()"
                      aria-hidden="true"
                    ></i>
                    <ng-container *ngIf="SearchReportRemarkDoc">
                      <div>
                        <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                        <ng-container *ngIf="SearchDocData">{{
                          SearchReportRemarkDoc
                        }}</ng-container>
                        <a
                          href="{{ docBaseUrl }}{{ LoanAcNo }}/{{
                            SearchReportRemarkDoc
                          }}"
                          class="ml-3"
                          target="_blank"
                          *ngIf="!SearchDocData"
                        >
                          {{ SearchReportRemarkDoc }}
                        </a>
                        <i
                          class="fa fa-times ml-2"
                          aria-hidden="true"
                          style="cursor: pointer"
                          (click)="removeFile1(docFile1)"
                        ></i>
                      </div>
                    </ng-container>
                  </p>
                  <input
                    #docFile1
                    type="file"
                    [multiple]="false"
                    accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                    (change)="fileChangeListener1(docFile1.files)"
                    style="display: none"
                  />
                </div>
              </div>
              <hr />
              <div class="row mt-2">
                <div class="col-md-12 mt-2 upload">
                  <B> Upload Property Images </B>
                  <input
                    #docFile
                    type="file"
                    [multiple]="false"
                    accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                    (change)="
                      fileChangeListenerAttachment(docFile.files, docFile)
                    "
                    style="display: none"
                  />
                  <div
                    class="row m-0 mt-2 align-items-center"
                    *ngFor="let report of AssetsAttachment; let i = index"
                  >
                    <div class="col-md-1 pl-0">
                      {{ i + 1 }}
                    </div>
                    <div class="col-md-3">
                      <input
                        type="text"
                        placeholder="Title"
                        name="title{{ i }}"
                        id="title{{ i }}"
                        #refTitle="ngModel"
                        [(ngModel)]="report.Title"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3">
                      <p class="m-0 row">
                        <i
                          class="fa fa-upload ml-2"
                          *ngIf="!report.DocFileName"
                          (click)="docFile.click()"
                          aria-hidden="true"
                        ></i>
                        <ng-container *ngIf="report.DocFileName">
                          <div>
                            <i
                              class="fa fa-file-pdf mr-2"
                              aria-hidden="true"
                            ></i
                            >{{ report.DocFileName }}
                            <i
                              class="fa fa-times ml-2"
                              aria-hidden="true"
                              style="cursor: pointer"
                              (click)="removeFileAttachment(i)"
                            ></i>
                          </div>
                        </ng-container>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </form>
        </ng-container>

        <div
          *ngIf="saveBtn"
          class="text-right mb-2"
          style="margin-right: 50px; margin-top: 5px"
        >
          <button
            type="button"
            [disabled]="!f.form.valid"
            (click)="saveAssetInfo('Additional Collateral')"
            class="font-size-12 button-btn"
          >
            Save
          </button>
        </div>

        <div
          *ngIf="isEdit"
          class="text-right mb-2"
          style="margin-right: 50px; margin-top: 5px"
        >
          <button
            type="button"
            (click)="editAssetInfo()"
            class="font-size-12 button-btn"
            [class.spinner]="loading"
          >
            Edit
          </button>
        </div>

        <div
          *ngIf="updateBtn"
          class="text-right mb-2"
          style="margin-right: 50px; margin-top: 5px"
        >
          <button
            type="button"
            [disabled]="!f.form.valid"
            (click)="saveAssetInfo('Additional Collateral')"
            class="font-size-12 button-btn"
            [class.spinner]="loading"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="kycModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Add Primary Collateral
        </h6>
        <button
          type="button"
          (click)="onCloseKYC()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="AssetsForm == 'Form'">
          <form #f="ngForm" novalidate>
            <div
              class="row mt-4 ml-3"
              *ngIf="AgricultureForm == 'AgricultureForm'"
            >
              <div class="col-md-6">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    checked
                    (change)="selectAgricultureForm('Property')"
                    type="radio"
                    name="inlineRadioOptionsA"
                    id="inlineRadioA1"
                    value="Property"
                  />
                  <label class="form-check-label" for="inlineRadioA1"
                    >Property</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    (change)="selectAgricultureForm('Vehicle')"
                    type="radio"
                    name="inlineRadioOptionsA"
                    id="inlineRadioA2"
                    value="Vehicle"
                  />
                  <label class="form-check-label" for="inlineRadioA2"
                    >Vehicle</label
                  >
                </div>
              </div>
            </div>

            <ng-container *ngIf="propertyForm == 'Vehicle'">
              <div class="row mt-2">
                <div class="col-md-6">
                  <div class="form-check-inline">
                    <input
                      class="form-check-input"
                      [(ngModel)]="vehicle_type"
                      (change)="selectVehicleType('New')"
                      type="radio"
                      name="inlineRadioOptions"
                      id="NewVehicle"
                      value="New"
                    />
                    <label class="form-check-label" for="NewVehicle"
                      >New Vehicle</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      [(ngModel)]="vehicle_type"
                      (change)="selectVehicleType('Used')"
                      type="radio"
                      name="inlineRadioOptions"
                      id="UsedVehicle"
                      value="Used"
                    />
                    <label class="form-check-label" for="UsedVehicle"
                      >Used Vehicle</label
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check-inline">
                    <input
                      class="form-check-input"
                      [(ngModel)]="commercialVehicle"
                      (change)="selectVehicleType('Commercial')"
                      type="radio"
                      name="inlineRadioOptions1"
                      id="Commercial"
                      value="Commercial"
                    />
                    <label class="form-check-label" for="Commercial"
                      >Commercial</label
                    >
                  </div>
                  <div class="form-check-inline">
                    <input
                      class="form-check-input"
                      [(ngModel)]="commercialVehicle"
                      (change)="selectVehicleType('Non-Commercial')"
                      type="radio"
                      name="inlineRadioOptions1"
                      id="NonCommercial"
                      value="Non-Commercial"
                    />
                    <label class="form-check-label" for="NonCommercial"
                      >Non Commercial</label
                    >
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="required-lable">Vehicle Manufacture</span>
                  <!--<select name="VehicleManufacture1" id="VehicleManufacture1" #refVehicleManufacture1="ngModel" (change)="LMS_GetVehicleModelMaster($event)"
                          [ngClass]="{ 'is-invalid': f.submitted && refVehicleManufacture1.invalid, 'alert-warning': ''  && (refVehicleManufacture1.dirty || refVehicleManufacture1.touched || refVehicleManufacture1.untouched) }"
                          [(ngModel)]="VehicleManufacture" class="form-control input-text-css" required>
                    <option value=""> Vehicle Manufacture </option>
                    <option *ngFor="let data of ManufactureData" [value]="data.Id"> {{data.Manufacture}} </option>
                  </select>-->

                  <select
                    name="VehicleManufacture1"
                    id="VehicleManufacture1"
                    #refVehicleManufacture1="ngModel"
                    (change)="LMS_GetVehicleModelMaster($event)"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refVehicleManufacture1.invalid,
                      'alert-warning':
                        refVehicleManufacture1.invalid &&
                        (refVehicleManufacture1.dirty ||
                          refVehicleManufacture1.touched ||
                          refVehicleManufacture1.untouched)
                    }"
                    [(ngModel)]="VehicleManufacture"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Vehicle Manufacture</option>
                    <option
                      *ngFor="let data of ManufactureData"
                      [value]="data.Id"
                    >
                      {{ data.Manufacture }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Vehicle Category</span>
                  <select
                    name="SelectVehicleCategory"
                    id="SelectVehicleCategory"
                    (change)="LMS_GetVehicleModelMaster($event)"
                    #refSelectVehicleCategory="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refSelectVehicleCategory.invalid,
                      'alert-warning':
                        refSelectVehicleCategory.invalid &&
                        (refSelectVehicleCategory.dirty ||
                          refSelectVehicleCategory.touched ||
                          refSelectVehicleCategory.untouched)
                    }"
                    [(ngModel)]="SelectVehicleCategory"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Vehicle Category</option>
                    <option
                      *ngFor="let cat of vehicleCategoryData"
                      [value]="cat.Id"
                    >
                      {{ cat.Vehicle_Category }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Vehicle Model Name</span>
                  <select
                    name="VehicleModel"
                    id="VehicleModel"
                    #refVehicleModel="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refVehicleModel.invalid,
                      'alert-warning':
                        refVehicleModel.invalid &&
                        (refVehicleModel.dirty ||
                          refVehicleModel.touched ||
                          refVehicleModel.untouched)
                    }"
                    [(ngModel)]="VehicleModel"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Vehicle Model Name</option>
                    <option
                      *ngFor="let data of vehicleModelData"
                      [value]="data.Id"
                    >
                      {{ data.Model_Name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="required-lable">Variant</span>
                  <input
                    required
                    type="text"
                    #refVariant="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refVariant.invalid,
                      'alert-warning':
                        refVariant.invalid &&
                        (refVariant.dirty ||
                          refVariant.touched ||
                          refVariant.untouched)
                    }"
                    [(ngModel)]="Variant"
                    name="Variant"
                    id="Variant"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-4">
                  <span class="">Manufacture Year</span>
                  <input
                    name="ManufactureYear"
                    id="ManufactureYear"
                    #refManufactureYear="ngModel"
                    maxlength="4"
                    minlength="4"
                    numbersOnly
                    [ngClass]="{
                      'is-invalid': f.submitted && refManufactureYear.invalid,
                      'alert-warning':
                        '' &&
                        (refManufactureYear.dirty ||
                          refManufactureYear.touched ||
                          refManufactureYear.untouched)
                    }"
                    [(ngModel)]="ManufactureYear"
                    class="form-control input-text-css"
                  />
                  <!-- <input name="ManufactureYear" id="ManufactureYear" #refManufactureYear="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && refManufactureYear.invalid, 'alert-warning': ''  && (refManufactureYear.dirty || refManufactureYear.touched || refManufactureYear.untouched) }"
                         [(ngModel)]="ManufactureYear" value="{{ ManufactureYear | date:'dd/MM/yyyy' }}"
                         class="form-control input-text-css" [(bsValue)]="ManufactureYear" bsDatepicker> -->
                </div>
                <div class="col-md-4">
                  <span class="">Vehicle Registation No</span>
                  <input
                    required
                    type="text"
                    #refFirstName="ngModel"
                    (blur)="checkRegNonumber()"
                    [ngClass]="{
                      'is-invalid': f.submitted && refFirstName.invalid,
                      'alert-warning':
                        '' &&
                        (refFirstName.dirty ||
                          refFirstName.touched ||
                          refFirstName.untouched)
                    }"
                    [(ngModel)]="VehicleRegistationNo"
                    name="FirstName"
                    id="FirstName"
                    class="form-control input-text-css"
                  />
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-4">
                  <span class="">Registation Date</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker12"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="RegistationDate"
                      id="RegistationDate"
                      #refRegistationDate="ngModel"
                      (dateChange)="ChangeRegistationDate($event)"
                      [min]="ManufactureYear"
                      [ngClass]="{
                        'is-invalid': f.submitted && refRegistationDate.invalid,
                        'alert-warning':
                          '' &&
                          (refRegistationDate.dirty ||
                            refRegistationDate.touched ||
                            refRegistationDate.untouched)
                      }"
                      [(ngModel)]="RegistationDate"
                      class="form-control input-text-css"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker12"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker12></mat-datepicker>
                  </div>
                  <!-- <input
                    name="RegistationDate"
                    id="RegistationDate"
                    #refRegistationDate="ngModel"
                    (ngModelChange)="ChangeRegistationDate($event)"
                    [minDate]="ManufactureYear"
                    [ngClass]="{
                      'is-invalid': f.submitted && refRegistationDate.invalid,
                      'alert-warning':
                        '' &&
                        (refRegistationDate.dirty ||
                          refRegistationDate.touched ||
                          refRegistationDate.untouched)
                    }"
                    [(ngModel)]="RegistationDate"
                    value="{{ RegistationDate | date : 'dd/MM/yyyy' }}"
                    class="form-control input-text-css"
                    [(bsValue)]="RegistationDate"
                    bsDatepicker
                  /> -->
                </div>
                <div class="col-md-4">
                  <span class="">Registation Expiry Date</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker13"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="RegistationExpiryDate"
                      id="RegistationExpiryDate"
                      #refRegistationExpiryDate="ngModel"
                      (dateChange)="ChangeExpiryDate($event)"
                      [min]="RegistationDate"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refRegistationExpiryDate.invalid,
                        'alert-warning':
                          '' &&
                          (refRegistationExpiryDate.dirty ||
                            refRegistationExpiryDate.touched ||
                            refRegistationExpiryDate.untouched)
                      }"
                      [(ngModel)]="RegistationExpiryDate"
                      class="form-control input-text-css"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker13"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker13></mat-datepicker>
                  </div>
                  <!-- <input
                    name="RegistationExpiryDate"
                    id="RegistationExpiryDate"
                    #refRegistationExpiryDate="ngModel"
                    (ngModelChange)="ChangeExpiryDate($event)"
                    [minDate]="RegistationDate"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refRegistationExpiryDate.invalid,
                      'alert-warning':
                        '' &&
                        (refRegistationExpiryDate.dirty ||
                          refRegistationExpiryDate.touched ||
                          refRegistationExpiryDate.untouched)
                    }"
                    [(ngModel)]="RegistationExpiryDate"
                    value="{{ RegistationExpiryDate | date : 'dd/MM/yyyy' }}"
                    class="form-control input-text-css"
                    [(bsValue)]="RegistationExpiryDate"
                    bsDatepicker
                  /> -->
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Fuel Type</span>
                  <select
                    name="FuelType"
                    id="SelectGender"
                    #refFuelType="ngModel"
                    [(ngModel)]="SelectFuelType"
                    [ngClass]="{
                      'is-invalid': f.submitted && refFuelType.invalid,
                      'alert-warning':
                        refFuelType.invalid &&
                        (refFuelType.dirty ||
                          refFuelType.touched ||
                          refFuelType.untouched)
                    }"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Fuel Type</option>
                    <option
                      *ngFor="let Type of FuelTypeOption"
                      [value]="Type.Id"
                    >
                      {{ Type.Value }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-4" *ngIf="commercialVehicle == 'Commercial'">
                  <span class="">Road Tax Upto</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker14"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="RoadTaxUpto"
                      id="RoadTaxUpto"
                      #refRoadTaxUpto="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refRoadTaxUpto.invalid,
                        'alert-warning':
                          '' &&
                          (refRoadTaxUpto.dirty ||
                            refRoadTaxUpto.touched ||
                            refRoadTaxUpto.untouched)
                      }"
                      [(ngModel)]="RoadTaxUpto"
                      class="form-control input-text-css"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker14"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker14></mat-datepicker>
                  </div>
                  <!-- <input
                    name="RoadTaxUpto"
                    id="RoadTaxUpto"
                    #refRoadTaxUpto="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refRoadTaxUpto.invalid,
                      'alert-warning':
                        '' &&
                        (refRoadTaxUpto.dirty ||
                          refRoadTaxUpto.touched ||
                          refRoadTaxUpto.untouched)
                    }"
                    [(ngModel)]="RoadTaxUpto"
                    value="{{ RoadTaxUpto | date : 'dd/MM/yyyy' }}"
                    class="form-control input-text-css"
                    [(bsValue)]="RoadTaxUpto"
                    bsDatepicker
                  /> -->
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Color</span>
                  <input
                    required
                    type="text"
                    #refClr="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refClr.invalid,
                      'alert-warning':
                        refClr.invalid &&
                        (refClr.dirty || refClr.touched || refClr.untouched)
                    }"
                    [(ngModel)]="Color"
                    name="Clr"
                    id="Clr"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-4" *ngIf="commercialVehicle == 'Commercial'">
                  <span class="">Fitness Upto</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker15"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="FitnessUpto"
                      id="FitnessUpto"
                      #refFitnessUpto="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refFitnessUpto.invalid,
                        'alert-warning':
                          '' &&
                          (refFitnessUpto.dirty ||
                            refFitnessUpto.touched ||
                            refFitnessUpto.untouched)
                      }"
                      [(ngModel)]="FitnessUpto"
                      class="form-control input-text-css"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker15"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker15></mat-datepicker>
                  </div>
                  <!-- <input
                    name="FitnessUpto"
                    id="FitnessUpto"
                    #refFitnessUpto="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refFitnessUpto.invalid,
                      'alert-warning':
                        '' &&
                        (refFitnessUpto.dirty ||
                          refFitnessUpto.touched ||
                          refFitnessUpto.untouched)
                    }"
                    [(ngModel)]="FitnessUpto"
                    value="{{ FitnessUpto | date : 'dd/MM/yyyy' }}"
                    class="form-control input-text-css"
                    [(bsValue)]="FitnessUpto"
                    bsDatepicker
                  /> -->
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-4" *ngIf="commercialVehicle == 'Commercial'">
                  <span class="">Permit Upto</span>
                  <div class="datepicker_feild">
                    <input
                      [matDatepicker]="picker16"
                      dateConvert
                      placeholder="DD/MM/YYYY"
                      name="PermitUpto"
                      id="PermitUpto"
                      #refPermitUpto="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refPermitUpto.invalid,
                        'alert-warning':
                          '' &&
                          (refPermitUpto.dirty ||
                            refPermitUpto.touched ||
                            refPermitUpto.untouched)
                      }"
                      [(ngModel)]="PermitUpto"
                      class="form-control input-text-css"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker16"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker16></mat-datepicker>
                  </div>
                  <!-- <input
                    name="PermitUpto"
                    id="PermitUpto"
                    #refPermitUpto="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refPermitUpto.invalid,
                      'alert-warning':
                        '' &&
                        (refPermitUpto.dirty ||
                          refPermitUpto.touched ||
                          refPermitUpto.untouched)
                    }"
                    [(ngModel)]="PermitUpto"
                    value="{{ PermitUpto | date : 'dd/MM/yyyy' }}"
                    class="form-control input-text-css"
                    [(bsValue)]="PermitUpto"
                    bsDatepicker
                  /> -->
                </div>

                <div class="col-md-4">
                  <span class="required-lable">Vehicle Cost</span>
                  <input
                    required
                    type="number"
                    #reVC="ngModel"
                    min="0"
                    numbersOnly
                    [ngClass]="{
                      'is-invalid': f.submitted && reVC.invalid,
                      'alert-warning':
                        reVC.invalid &&
                        (reVC.dirty || reVC.touched || reVC.untouched)
                    }"
                    [(ngModel)]="VehicleCost"
                    name="VC"
                    id="VC"
                    class="form-control input-text-css"
                  />
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-4" *ngIf="commercialVehicle == 'Commercial'">
                  <span class="">Route</span>
                  <input
                    type="text"
                    #refFirstName="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refFirstName.invalid,
                      'alert-warning':
                        '' &&
                        (refFirstName.dirty ||
                          refFirstName.touched ||
                          refFirstName.untouched)
                    }"
                    [(ngModel)]="Route"
                    name="FirstName"
                    id="FirstName"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-3">
                  <span class="">Engine No</span>
                  <input
                    type="text"
                    #refEngineNo="ngModel"
                    [required]="
                      (InvoiceNo != '' && InvoiceNo != null) ||
                      (VehicleRegistationNo != '' &&
                        VehicleRegistationNo != null)
                    "
                    placeholder="Engine No"
                    (change)="checkDuplication_Vehicle('EngineNo')"
                    [ngClass]="{
                      'is-invalid': f.submitted && refEngineNo.invalid,
                      'alert-warning':
                        refEngineNo.invalid &&
                        (refEngineNo.dirty ||
                          refEngineNo.touched ||
                          refEngineNo.untouched)
                    }"
                    [(ngModel)]="EngineNo"
                    name="ENO"
                    id="ENO"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-3">
                  <span class="">Chassis No</span>
                  <input
                    type="text"
                    #refChassisNo="ngModel"
                    placeholder="Chassis No"
                    [required]="
                      (InvoiceNo != '' && InvoiceNo != null) ||
                      (VehicleRegistationNo != '' &&
                        VehicleRegistationNo != null)
                    "
                    (change)="checkDuplication_Vehicle('ChassisNo')"
                    [ngClass]="{
                      'is-invalid': f.submitted && refChassisNo.invalid,
                      'alert-warning':
                        refChassisNo.invalid &&
                        (refChassisNo.dirty ||
                          refChassisNo.touched ||
                          refChassisNo.untouched)
                    }"
                    [(ngModel)]="ChassisNo"
                    name="CNO"
                    id="CNO"
                    class="form-control input-text-css"
                  />
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="">Key No</span>
                  <input
                    type="text"
                    #refFirstName="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refFirstName.invalid,
                      'alert-warning':
                        '' &&
                        (refFirstName.dirty ||
                          refFirstName.touched ||
                          refFirstName.untouched)
                    }"
                    [(ngModel)]="KeyNo"
                    name="KEYNO"
                    id="KEYNO"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-4 mt-4">
                  <mat-checkbox
                    [(ngModel)]="RC_HPNEndorsment"
                    id="RC_HPNEndorsment1"
                    name="RC_HPNEndorsment1"
                  >
                    RC HPNEndorsment
                  </mat-checkbox>
                </div>
                <div class="col-md-4 mt-4" *ngIf="newVehicle">
                  <mat-checkbox
                    [(ngModel)]="Invoice_HPNEndorsment"
                    id="Invoice_HPNEndorsment"
                    name="Invoice_HPNEndorsment"
                  >
                    Invoice HPNEndorsment
                  </mat-checkbox>
                </div>
              </div>

              <hr />
              <ng-container *ngIf="newVehicle">
                <div class="row mt-2">
                  <div class="col-md-12">
                    <h5 style="color: black">New Vehicle</h5>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-4">
                    <span class="required-lable">DO. No.</span>
                    <input
                      required
                      type="text"
                      #refDONo="ngModel"
                      [(ngModel)]="DeliveryorderNo"
                      [ngClass]="{
                        'is-invalid': f.submitted && refDONo.invalid,
                        'alert-warning':
                          refDONo.invalid &&
                          (refDONo.dirty ||
                            refDONo.touched ||
                            refDONo.untouched)
                      }"
                      name="DoNO"
                      id="DoNO"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable">DO. Do Date.</span>
                    <div class="datepicker_feild">
                      <input
                        required
                        [matDatepicker]="picker17"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        #refDODoDate="ngModel"
                        [(ngModel)]="DeliveryorderDate"
                        name="DODoDate"
                        id="DODoDate"
                        [ngClass]="{
                          'is-invalid': f.submitted && refDODoDate.invalid,
                          'alert-warning':
                            refDODoDate.invalid &&
                            (refDODoDate.dirty ||
                              refDODoDate.touched ||
                              refDODoDate.untouched)
                        }"
                        (dateChange)="ChangeDeliveryorderDate($event)"
                        [max]="RegistationDate"
                        class="form-control input-text-css"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker17"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker17></mat-datepicker>
                    </div>
                    <!-- <input
                      required
                      type="text"
                      #refDODoDate="ngModel"
                      [(ngModel)]="DeliveryorderDate"
                      name="DODoDate"
                      id="DODoDate"
                      [ngClass]="{
                        'is-invalid': f.submitted && refDODoDate.invalid,
                        'alert-warning':
                          refDODoDate.invalid &&
                          (refDODoDate.dirty ||
                            refDODoDate.touched ||
                            refDODoDate.untouched)
                      }"
                      (ngModelChange)="ChangeDeliveryorderDate($event)"
                      [maxDate]="RegistationDate"
                      value="{{ DeliveryorderDate | date : 'dd/MM/yyyy' }}"
                      placeholder="DD/MM/YYYY"
                      class="form-control input-text-css"
                      [(bsValue)]="DeliveryorderDate"
                      bsDatepicker
                    /> -->
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable">Estimation Amount</span>
                    <input
                      required
                      type="number"
                      min="0"
                      numbersOnly
                      #refEstimationAmount="ngModel"
                      [(ngModel)]="EstimationAmount"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refEstimationAmount.invalid,
                        'alert-warning':
                          refEstimationAmount.invalid &&
                          (refEstimationAmount.dirty ||
                            refEstimationAmount.touched ||
                            refEstimationAmount.untouched)
                      }"
                      name="EstimationAmount"
                      id="EstimationAmount"
                      class="form-control input-text-css"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-4">
                    <span class="">Invoice No.</span>
                    <input
                      type="text"
                      #refInvoiceNo="ngModel"
                      [(ngModel)]="InvoiceNo"
                      [ngClass]="{
                        'is-invalid': f.submitted && refInvoiceNo.invalid,
                        'alert-warning':
                          refInvoiceNo.invalid &&
                          (refInvoiceNo.dirty ||
                            refInvoiceNo.touched ||
                            refInvoiceNo.untouched)
                      }"
                      name="InvoiceNo"
                      id="InvoiceNo"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="">Invoice Date</span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker18"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        name="Voucher_Date"
                        id="Voucher_Date"
                        [(ngModel)]="InvoiceDate"
                        #refInvoiceDate="ngModel"
                        [(ngModel)]="InvoiceDate"
                        name="InvoiceDate"
                        id="InvoiceDate"
                        (dateChange)="ChangeInvoiveDateDate($event)"
                        [min]="DeliveryorderDate"
                        [max]="RegistationDate"
                        class="form-control input-text-css"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker18"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker18></mat-datepicker>
                    </div>
                    <!-- <input
                      type="text"
                      #refInvoiceDate="ngModel"
                      [(ngModel)]="InvoiceDate"
                      name="InvoiceDate"
                      id="InvoiceDate"
                      (ngModelChange)="ChangeInvoiveDateDate($event)"
                      [minDate]="DeliveryorderDate"
                      [maxDate]="RegistationDate"
                      value="{{ InvoiveDate | date : 'dd/MM/yyyy' }}"
                      placeholder="DD/MM/YYYY"
                      class="form-control input-text-css"
                      [ngClass]="{
                        'is-invalid': f.submitted && refInvoiceDate.invalid,
                        'alert-warning':
                          refInvoiceDate.invalid &&
                          (refInvoiceDate.dirty ||
                            refInvoiceDate.touched ||
                            refInvoiceDate.untouched)
                      }"
                      [(bsValue)]="InvoiveDate"
                      bsDatepicker
                    /> -->
                  </div>
                  <div class="col-md-4">
                    <span class="">Invoice Value</span>
                    <input
                      type="text"
                      #refInvoiceValue="ngModel"
                      [(ngModel)]="InvoiceValue"
                      [ngClass]="{
                        'is-invalid': f.submitted && refInvoiceValue.invalid,
                        'alert-warning':
                          refInvoiceValue.invalid &&
                          (refInvoiceValue.dirty ||
                            refInvoiceValue.touched ||
                            refInvoiceValue.untouched)
                      }"
                      name="InvoiceValue"
                      id="InvoiceValue"
                      class="form-control input-text-css"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-4">
                    <span class="required-lable">Dealer Contact Person</span>
                    <input
                      required
                      type="text"
                      #refDealerContactPerson="ngModel"
                      [(ngModel)]="DealerContactPerson"
                      name="DealerContactPerson"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refDealerContactPerson.invalid,
                        'alert-warning':
                          refDealerContactPerson.invalid &&
                          (refDealerContactPerson.dirty ||
                            refDealerContactPerson.touched ||
                            refDealerContactPerson.untouched)
                      }"
                      id="DealerContactPerson"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable">Dealer Contact No.</span>
                    <input
                      required
                      type="text"
                      #refDealerContactNo="ngModel"
                      [(ngModel)]="DealerContactNo"
                      numbersOnly
                      maxlength="10"
                      minlength="10"
                      name="DealerContactNo"
                      [ngClass]="{
                        'is-invalid': f.submitted && refDealerContactNo.invalid,
                        'alert-warning':
                          refDealerContactNo.invalid &&
                          (refDealerContactNo.dirty ||
                            refDealerContactNo.touched ||
                            refDealerContactNo.untouched)
                      }"
                      id="DealerContactNo"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable">Quotation in favour of</span>
                    <select
                      name="DOIssuedinfavourof"
                      #refDOIssuedinfavourof="ngModel"
                      [(ngModel)]="IssuedInFavourOf"
                      id="DOIssuedinfavourof"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refDOIssuedinfavourof.invalid,
                        'alert-warning':
                          refDOIssuedinfavourof.invalid &&
                          (refDOIssuedinfavourof.dirty ||
                            refDOIssuedinfavourof.touched ||
                            refDOIssuedinfavourof.untouched)
                      }"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Customer</option>
                      <option
                        [value]="cus.CustomerId"
                        *ngFor="let cus of CustomerList"
                      >
                        {{ cus.Customer }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-4">
                    <span class="required-lable">Remark</span>
                    <input
                      required
                      type="text"
                      #refRemark="ngModel"
                      [(ngModel)]="Remark"
                      [ngClass]="{
                        'is-invalid': f.submitted && refRemark.invalid,
                        'alert-warning':
                          refRemark.invalid &&
                          (refRemark.dirty ||
                            refRemark.touched ||
                            refRemark.untouched)
                      }"
                      name="Remark"
                      id="Remark"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="required-lable">Dealer</span>
                    <select
                      name="DealerId"
                      id="DealerId"
                      #refDealerId="ngModel"
                      [(ngModel)]="Dealer"
                      [ngClass]="{
                        'is-invalid': f.submitted && refDealerId.invalid,
                        'alert-warning':
                          refDealerId.invalid &&
                          (refDealerId.dirty ||
                            refDealerId.touched ||
                            refDealerId.untouched)
                      }"
                      class="form-control input-text-css"
                      required
                    >
                      <option value="">Select Dealer</option>
                      <option
                        *ngFor="let collection of DealerList"
                        [value]="collection.PartnerId"
                      >
                        {{ collection.Partner }}
                      </option>
                    </select>
                  </div>
                </div>
                <hr />
              </ng-container>

              <ng-container *ngIf="usedVehicle">
                <div class="row mt-2">
                  <div class="col-md-12">
                    <h5 style="color: black">Used Vehicles</h5>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-4">
                    <span class="">Valuation Done By</span>
                    <select
                      name="ValuationDoneBy"
                      id="ValuationDoneBy"
                      #refValuationDoneBy="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refValuationDoneBy.invalid,
                        'alert-warning':
                          refValuationDoneBy.invalid &&
                          (refValuationDoneBy.dirty ||
                            refValuationDoneBy.touched ||
                            refValuationDoneBy.untouched)
                      }"
                      [(ngModel)]="ValuationDoneBy"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Valuation Done By</option>
                      <option
                        *ngFor="let collection of CollectionExecutiveData"
                        [value]="collection.EmpId"
                      >
                        {{ collection.Emp_FirstName }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-4">
                    <span class="">Valuation Date</span>
                    <div class="datepicker_feild">
                      <input
                        [matDatepicker]="picker19"
                        dateConvert
                        placeholder="DD/MM/YYYY"
                        #refValuationDate="ngModel"
                        [(ngModel)]="ValuationDate"
                        name="ValuationDate"
                        id="ValuationDate"
                        [ngClass]="{
                          'is-invalid': f.submitted && refValuationDate.invalid,
                          'alert-warning':
                            refValuationDate.invalid &&
                            (refValuationDate.dirty ||
                              refValuationDate.touched ||
                              refValuationDate.untouched)
                        }"
                        class="form-control input-text-css"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker19"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker19></mat-datepicker>
                    </div>
                    <!-- <input
                      type="text"
                      #refValuationDate="ngModel"
                      [(ngModel)]="ValuationDate"
                      name="ValuationDate"
                      id="ValuationDate"
                      [ngClass]="{
                        'is-invalid': f.submitted && refValuationDate.invalid,
                        'alert-warning':
                          refValuationDate.invalid &&
                          (refValuationDate.dirty ||
                            refValuationDate.touched ||
                            refValuationDate.untouched)
                      }"
                      value="{{ ValuationDate | date : 'dd/MM/yyyy' }}"
                      placeholder="DD/MM/YYYY"
                      class="form-control input-text-css"
                      [(bsValue)]="ValuationDate"
                      bsDatepicker
                    /> -->
                  </div>
                  <div class="col-md-4">
                    <span class="">Valuation Amount</span>
                    <input
                      type="number"
                      min="0"
                      numbersOnly
                      #refValuationAmount="ngModel"
                      [(ngModel)]="ValutionAmount"
                      name="ValuationAmount"
                      id="ValuationAmount"
                      [ngClass]="{
                        'is-invalid': f.submitted && refValuationAmount.invalid,
                        'alert-warning':
                          refValuationAmount.invalid &&
                          (refValuationAmount.dirty ||
                            refValuationAmount.touched ||
                            refValuationAmount.untouched)
                      }"
                      class="form-control input-text-css"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-4">
                    <span class="">Valuation Type</span>
                    <select
                      name="ValuationType"
                      id="ValuationType"
                      #refValuationType="ngModel"
                      [ngClass]="{
                        'is-invalid': f.submitted && refValuationType.invalid,
                        'alert-warning':
                          refValuationType.invalid &&
                          (refValuationType.dirty ||
                            refValuationType.touched ||
                            refValuationType.untouched)
                      }"
                      [(ngModel)]="ValuationType"
                      class="form-control input-text-css"
                    >
                      <option value="">Select Valuation Type</option>
                      <option value="Valuation">Valuation</option>
                      <option value="Grid">Grid</option>
                      <option value="IDV">IDV</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <span class="">Other Valuation Type</span>
                    <input
                      type="text"
                      #refOtherValuationType="ngModel"
                      [(ngModel)]="OtherValuationType"
                      name="OtherValuationType"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refOtherValuationType.invalid,
                        'alert-warning':
                          refOtherValuationType.invalid &&
                          (refOtherValuationType.dirty ||
                            refOtherValuationType.touched ||
                            refOtherValuationType.untouched)
                      }"
                      id="OtherValuationType"
                      class="form-control input-text-css"
                    />
                  </div>
                  <div class="col-md-4">
                    <span class="">Other Valuation Amount</span>
                    <input
                      type="number"
                      min="0"
                      numbersOnly
                      #refOtherValuationAmt="ngModel"
                      [(ngModel)]="OtherValuationAmount"
                      name="OtherValuationAmt"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refOtherValuationAmt.invalid,
                        'alert-warning':
                          refOtherValuationAmt.invalid &&
                          (refOtherValuationAmt.dirty ||
                            refOtherValuationAmt.touched ||
                            refOtherValuationAmt.untouched)
                      }"
                      id="OtherValuationAmt"
                      class="form-control input-text-css"
                    />
                  </div>
                </div>
                <hr />
              </ng-container>

              <ng-container>
                <div class="row mt-2">
                  <div class="col-md-12 mt-2 upload">
                    <B> Upload Vehicle Images </B>
                    <input
                      #docFile
                      type="file"
                      [multiple]="false"
                      accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                      (change)="
                        fileChangeListenerAttachment(docFile.files, docFile)
                      "
                      style="display: none"
                    />
                    <div
                      class="row m-0 mt-2 align-items-center"
                      *ngFor="let report of AssetsAttachment; let i = index"
                    >
                      <div class="col-md-1 pl-0">
                        {{ i + 1 }}
                      </div>
                      <div class="col-md-3">
                        <input
                          type="text"
                          placeholder="Title"
                          name="title{{ i }}"
                          id="title{{ i }}"
                          #refTitle="ngModel"
                          [(ngModel)]="report.Title"
                          class="form-control input-text-css"
                        />
                      </div>
                      <div class="col-md-3">
                        <p class="m-0 row">
                          <i
                            class="fa fa-upload ml-2"
                            *ngIf="!report.DocFileName"
                            (click)="docFile.click()"
                            aria-hidden="true"
                          ></i>
                          <ng-container *ngIf="report.DocFileName">
                            <div>
                              <i
                                class="fa fa-file-pdf mr-2"
                                aria-hidden="true"
                              ></i
                              >{{ report.DocFileName }}
                              <i
                                class="fa fa-times ml-2"
                                aria-hidden="true"
                                style="cursor: pointer"
                                (click)="removeFileAttachment(i)"
                              ></i>
                            </div>
                          </ng-container>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="propertyForm == 'Property'">
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="required-lable">Property Owner Name</span>
                  <select
                    name="CustomerId"
                    id="CustomerId"
                    #refCustomerId="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refCustomerId.invalid,
                      'alert-warning':
                        refCustomerId.invalid &&
                        (refCustomerId.dirty ||
                          refCustomerId.touched ||
                          refCustomerId.untouched)
                    }"
                    [(ngModel)]="PropertyOwnerName"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Owner</option>
                    <option
                      [value]="cus.CustomerId"
                      *ngFor="let cus of CustomerList"
                    >
                      {{ cus.Customer }}
                    </option>
                  </select>
                </div>
                <div class="col-md-8">
                  <span class="required-lable">Address Of Property</span>
                  <textarea
                    required
                    type="text"
                    #reAddress="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && reAddress.invalid,
                      'alert-warning':
                        reAddress.invalid &&
                        (reAddress.dirty ||
                          reAddress.touched ||
                          reAddress.untouched)
                    }"
                    [(ngModel)]="AddressOfProperty"
                    name="Address"
                    id="Address"
                    class="form-control input-text-css"
                  ></textarea>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="required-lable">Reg State</span>
                  <select
                    name="State"
                    id="State"
                    #refState="ngModel"
                    [(ngModel)]="customerModel.Firm_RegState"
                    class="form-control input-text-css"
                    required
                    [ngClass]="{
                      'is-invalid': f.submitted && refState.invalid,
                      'alert-warning':
                        refState.invalid &&
                        (refState.dirty ||
                          refState.touched ||
                          refState.untouched)
                    }"
                    (change)="
                      GetDistrickDropdownReg(customerModel.Firm_RegState)
                    "
                  >
                    <option value="">Select State</option>
                    <option
                      *ngFor="let state of stateDropdown"
                      [value]="state.StateId"
                    >
                      {{ state.State_Name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Reg District</span>
                  <select
                    name="District"
                    id="District"
                    #refDistrict="ngModel"
                    [(ngModel)]="customerModel.Firm_RegDistrict"
                    class="form-control input-text-css"
                    required
                    [ngClass]="{
                      'is-invalid': f.submitted && refDistrict.invalid,
                      'alert-warning':
                        refDistrict.invalid &&
                        (refDistrict.dirty ||
                          refDistrict.touched ||
                          refDistrict.untouched)
                    }"
                    (change)="
                      GetTehasilDropdownReg(customerModel.Firm_RegDistrict)
                    "
                  >
                    <option value="">Select District</option>
                    <option
                      *ngFor="let district of districkDropdownReg"
                      [value]="district.DistrictId"
                    >
                      {{ district.District_Name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Reg Tehsil</span>
                  <select
                    name="Tasil"
                    id="Tasil"
                    #refTasil="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refTasil.invalid,
                      'alert-warning':
                        refTasil.invalid &&
                        (refTasil.dirty ||
                          refTasil.touched ||
                          refTasil.untouched)
                    }"
                    [(ngModel)]="customerModel.Firm_RegTehsil"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Tehsil</option>
                    <option
                      *ngFor="let tehsil of tehasilDropdownReg"
                      [value]="tehsil.TehsilId"
                    >
                      {{ tehsil.Tehsil_Name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="required-lable">Type Of Property</span>
                  <select
                    name="TypeofProperty"
                    id="TypeofProperty"
                    #refTypeofProperty="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refTypeofProperty.invalid,
                      'alert-warning':
                        refTypeofProperty.invalid &&
                        (refTypeofProperty.dirty ||
                          refTypeofProperty.touched ||
                          refTypeofProperty.untouched)
                    }"
                    [(ngModel)]="TypeOfProperty"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Type Of Property</option>
                    <option
                      *ngFor="let Type of TypeOfPropertyList"
                      [value]="Type.Id"
                    >
                      {{ Type.Value }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Nature Of Property</span>
                  <select
                    name="NatureofProperty"
                    id="NatureofProperty"
                    #refNatureofProperty="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refNatureofProperty.invalid,
                      'alert-warning':
                        refNatureofProperty.invalid &&
                        (refNatureofProperty.dirty ||
                          refNatureofProperty.touched ||
                          refNatureofProperty.untouched)
                    }"
                    [(ngModel)]="NatureOfProperty"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Type Of Property</option>
                    <option
                      *ngFor="let Type of NatureOfPropertyList"
                      [value]="Type.Id"
                    >
                      {{ Type.Value }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Ownership Document</span>
                  <select
                    name="OwnershipDocument"
                    id="OwnershipDocument"
                    #refOwnershipDocument="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refOwnershipDocument.invalid,
                      'alert-warning':
                        refOwnershipDocument.invalid &&
                        (refOwnershipDocument.dirty ||
                          refOwnershipDocument.touched ||
                          refOwnershipDocument.untouched)
                    }"
                    [(ngModel)]="OwnershipDocument"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Ownership Document</option>
                    <option
                      *ngFor="let Type of OwnershipDocumentList"
                      [value]="Type.Id"
                    >
                      {{ Type.Value }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="required-lable">Ownership Type</span>
                  <select
                    name="OwnershipType"
                    id="OwnershipType"
                    #refOwnershipType="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refOwnershipType.invalid,
                      'alert-warning':
                        refOwnershipType.invalid &&
                        (refOwnershipType.dirty ||
                          refOwnershipType.touched ||
                          refOwnershipType.untouched)
                    }"
                    [(ngModel)]="OwnershipType"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Ownership Type</option>
                    <option
                      *ngFor="let Type of OwnershipTypeList"
                      [value]="Type.Id"
                    >
                      {{ Type.Value }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Unit Of Measurement </span>
                  <select
                    name="UnitofMeasurement"
                    id="UnitofMeasurement"
                    #refUnitofMeasurement="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refUnitofMeasurement.invalid,
                      'alert-warning':
                        refUnitofMeasurement.invalid &&
                        (refUnitofMeasurement.dirty ||
                          refUnitofMeasurement.touched ||
                          refUnitofMeasurement.untouched)
                    }"
                    [(ngModel)]="UnitOfMeasurement"
                    class="form-control input-text-css"
                    required
                  >
                    <option value="">Select Unit Of Measurement</option>
                    <option value="Sq. Ft">Sq. Ft</option>
                    <option value="Sq. Yards">Sq. Yards</option>
                    <option value="Sq. Metre">Sq. Metre</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="required-lable">Total Area </span>
                  <input
                    required
                    type="text"
                    appTwoDigitDecimaNumber
                    #reTotalArea="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && reTotalArea.invalid,
                      'alert-warning':
                        reTotalArea.invalid &&
                        (reTotalArea.dirty ||
                          reTotalArea.touched ||
                          reTotalArea.untouched)
                    }"
                    [(ngModel)]="TotalArea"
                    name="TotalArea"
                    id="TotalArea"
                    class="form-control input-text-css"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="required-lable">Constructed Area </span>
                  <input
                    required
                    type="text"
                    appTwoDigitDecimaNumber
                    #reConstructedArea="ngModel"
                    [(ngModel)]="ConstructedArea"
                    name="ConstructedArea"
                    id="ConstructedArea"
                    (change)="GetConstructedArea($event)"
                    [ngClass]="{
                      'is-invalid': f.submitted && reConstructedArea.invalid,
                      'alert-warning':
                        reConstructedArea.invalid &&
                        (reConstructedArea.dirty ||
                          reConstructedArea.touched ||
                          reConstructedArea.untouched)
                    }"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-4">
                  <span class="">Type Of Mortgage </span>
                  <select
                    name="TypeofMortgage"
                    id="TypeofMortgage"
                    #refTypeofMortgage="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refTypeofMortgage.invalid,
                      'alert-warning':
                        refTypeofMortgage.invalid &&
                        (refTypeofMortgage.dirty ||
                          refTypeofMortgage.touched ||
                          refTypeofMortgage.untouched)
                    }"
                    [(ngModel)]="TypeOfMortgage"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Type Of Mortgage</option>
                    <option value="Registered">Registered</option>
                    <option value="Equitable">Equitable</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div class="col-md-4">
                  <span class="">Mortgage singed by</span>
                  <select
                    name="MortgageSingedBy"
                    id="MortgageSingedBy"
                    #refMortgageSingedBy="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refMortgageSingedBy.invalid,
                      'alert-warning':
                        refMortgageSingedBy.invalid &&
                        (refMortgageSingedBy.dirty ||
                          refMortgageSingedBy.touched ||
                          refMortgageSingedBy.untouched)
                    }"
                    [(ngModel)]="MortgageSingedBy"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Valuation Done By</option>
                    <option
                      *ngFor="let collection of CollectionExecutiveData"
                      [value]="collection.EmpId"
                    >
                      {{ collection.Emp_FirstName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="required-lable">Valution Amount</span>
                  <input
                    required
                    type="number"
                    min="0"
                    numbersOnly
                    #refValuationAmount="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refValuationAmount.invalid,
                      'alert-warning':
                        refValuationAmount.invalid &&
                        (refValuationAmount.dirty ||
                          refValuationAmount.touched ||
                          refValuationAmount.untouched)
                    }"
                    [(ngModel)]="ValutionAmount"
                    name="ValuationAmount"
                    id="ValuationAmount"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-4">
                  <span class="">Valution Done By</span>
                  <select
                    name="ValuationDoneBy"
                    id="ValuationDoneBy"
                    #refValuationDoneBy="ngModel"
                    [ngClass]="{
                      'is-invalid': f.submitted && refValuationDoneBy.invalid,
                      'alert-warning':
                        refValuationDoneBy.invalid &&
                        (refValuationDoneBy.dirty ||
                          refValuationDoneBy.touched ||
                          refValuationDoneBy.untouched)
                    }"
                    [(ngModel)]="ValuationDoneBy"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Valuation Done By</option>
                    <option
                      *ngFor="let collection of CollectionExecutiveData"
                      [value]="collection.EmpId"
                    >
                      {{ collection.Emp_FirstName }}
                    </option>

                    <option value="0">Other</option>
                  </select>
                </div>
                <div
                  class="col-md-4"
                  *ngIf="ValuationDoneBy != '' && ValuationDoneBy == 0"
                >
                  <span class="">Valution Done By Name</span>
                  <input
                    type="text"
                    #refValuationDoneByOther="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refValuationDoneByOther.invalid,
                      'alert-warning':
                        refValuationDoneByOther.invalid &&
                        (refValuationDoneByOther.dirty ||
                          refValuationDoneByOther.touched ||
                          refValuationDoneByOther.untouched)
                    }"
                    [(ngModel)]="ValuationDoneByOther"
                    name="ValuationDoneByOther"
                    id="ValuationDoneByOther"
                    class="form-control input-text-css"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <span class="">Valution Remark</span>
                  <textarea
                    type="text"
                    #refValuationRemark="ngModel"
                    [(ngModel)]="ValuationRemark"
                    name="ValuationRemark"
                    id="ValuationRemark"
                    [ngClass]="{
                      'is-invalid': f.submitted && refValuationRemark.invalid,
                      'alert-warning':
                        refValuationRemark.invalid &&
                        (refValuationRemark.dirty ||
                          refValuationRemark.touched ||
                          refValuationRemark.untouched)
                    }"
                    class="form-control input-text-css"
                  ></textarea>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-4">
                  <span class="">Search Report Done By</span>
                  <select
                    name="SearchReportDoneBy"
                    id="SearchReportDoneBy"
                    #refSearchReportDoneBy="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refSearchReportDoneBy.invalid,
                      'alert-warning':
                        refSearchReportDoneBy.invalid &&
                        (refSearchReportDoneBy.dirty ||
                          refSearchReportDoneBy.touched ||
                          refSearchReportDoneBy.untouched)
                    }"
                    [(ngModel)]="SearchReportDoneBy"
                    class="form-control input-text-css"
                  >
                    <option value="">Select Report Done By</option>
                    <option
                      *ngFor="let collection of CollectionExecutiveData"
                      [value]="collection.EmpId"
                    >
                      {{ collection.Emp_FirstName }}
                    </option>

                    <option value="0">Other</option>
                  </select>
                </div>
                <div
                  class="col-md-4"
                  *ngIf="SearchReportDoneBy != '' && SearchReportDoneBy == 0"
                >
                  <span class="">Search Report Done By Name</span>
                  <input
                    type="text"
                    #refSearchReportDoneByOther="ngModel"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refSearchReportDoneByOther.invalid,
                      'alert-warning':
                        refSearchReportDoneByOther.invalid &&
                        (refSearchReportDoneByOther.dirty ||
                          refSearchReportDoneByOther.touched ||
                          refSearchReportDoneByOther.untouched)
                    }"
                    [(ngModel)]="SearchReportDoneByOther"
                    name="SearchReportDoneByOther"
                    id="SearchReportDoneByOther"
                    class="form-control input-text-css"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <span class="">Search Report Remark</span>
                  <textarea
                    type="text"
                    #refSearchReportRemark="ngModel"
                    [(ngModel)]="SearchReportRemark"
                    name="SearchReportRemark"
                    id="SearchReportRemark"
                    [ngClass]="{
                      'is-invalid':
                        f.submitted && refSearchReportRemark.invalid,
                      'alert-warning':
                        refSearchReportRemark.invalid &&
                        (refSearchReportRemark.dirty ||
                          refSearchReportRemark.touched ||
                          refSearchReportRemark.untouched)
                    }"
                    class="form-control input-text-css"
                  ></textarea>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-2">
                  <b style="color: black"> Location </b>
                </div>
                <div class="col-md-4">
                  <span class="">Latitude</span>
                  <input
                    type="number"
                    step="00.000001"
                    #refLatitude="ngModel"
                    placeholder="Latitude"
                    [ngClass]="{
                      'is-invalid': f.submitted && refLatitude.invalid,
                      'alert-warning':
                        refLatitude.invalid &&
                        (refLatitude.dirty ||
                          refLatitude.touched ||
                          refLatitude.untouched)
                    }"
                    [(ngModel)]="latitude"
                    name="Latitude"
                    id="Latitude"
                    class="form-control input-text-css"
                  />
                </div>
                <div class="col-md-4">
                  <span class="">Longitude</span>
                  <input
                    type="number"
                    step="00.000001"
                    #refLongitude="ngModel"
                    placeholder="Longitude"
                    [ngClass]="{
                      'is-invalid': f.submitted && refLongitude.invalid,
                      'alert-warning':
                        refLongitude.invalid &&
                        (refLongitude.dirty ||
                          refLongitude.touched ||
                          refLongitude.untouched)
                    }"
                    [(ngModel)]="longitude"
                    name="Longitude"
                    id="Longitude"
                    class="form-control input-text-css"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-6">
                  <p class="m-0 row">
                    Upload Valuation Report:<i
                      class="fa fa-upload ml-2"
                      *ngIf="!ValuationRemarkDoc"
                      (click)="docFile.click()"
                      aria-hidden="true"
                    ></i>
                    <ng-container *ngIf="ValuationRemarkDoc">
                      <div>
                        <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                        <ng-container *ngIf="ValuationDocData">{{
                          ValuationRemarkDoc
                        }}</ng-container>
                        <a
                          href="{{ docBaseUrl }}{{ LoanAcNo }}/{{
                            ValuationRemarkDoc
                          }}"
                          class="ml-3"
                          target="_blank"
                          *ngIf="!ValuationDocData"
                        >
                          {{ ValuationRemarkDoc }}
                        </a>
                        <i
                          class="fa fa-times ml-2"
                          aria-hidden="true"
                          style="cursor: pointer"
                          (click)="removeFile(docFile)"
                        ></i>
                      </div>
                    </ng-container>
                  </p>
                  <input
                    #docFile
                    type="file"
                    [multiple]="false"
                    accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                    (change)="fileChangeListener(docFile.files)"
                    style="display: none"
                  />
                </div>
                <div class="col-md-6">
                  <p class="m-0 row">
                    Upload Search Report:<i
                      class="fa fa-upload ml-2"
                      *ngIf="!SearchReportRemarkDoc"
                      (click)="docFile1.click()"
                      aria-hidden="true"
                    ></i>
                    <ng-container *ngIf="SearchReportRemarkDoc">
                      <div>
                        <i class="fa fa-file-pdf mr-2" aria-hidden="true"></i>
                        <ng-container *ngIf="SearchDocData">{{
                          SearchReportRemarkDoc
                        }}</ng-container>
                        <a
                          href="{{ docBaseUrl }}{{ LoanAcNo }}/{{
                            SearchReportRemarkDoc
                          }}"
                          class="ml-3"
                          target="_blank"
                          *ngIf="!SearchDocData"
                        >
                          {{ SearchReportRemarkDoc }}
                        </a>
                        <i
                          class="fa fa-times ml-2"
                          aria-hidden="true"
                          style="cursor: pointer"
                          (click)="removeFile1(docFile1)"
                        ></i>
                      </div>
                    </ng-container>
                  </p>
                  <input
                    #docFile1
                    type="file"
                    [multiple]="false"
                    accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                    (change)="fileChangeListener1(docFile1.files)"
                    style="display: none"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12 mt-2 upload">
                  <b> Upload Property Images </b>
                  <input
                    #docFile
                    type="file"
                    [multiple]="false"
                    accept="application/pdf,application/vnd.ms-excel,image/jpeg, image/png"
                    (change)="
                      fileChangeListenerAttachment(docFile.files, docFile)
                    "
                    style="display: none"
                  />
                  <div
                    class="row m-0 mt-2 align-items-center"
                    *ngFor="let report of AssetsAttachment; let i = index"
                  >
                    <div class="col-md-1 pl-0">
                      {{ i + 1 }}
                    </div>
                    <div class="col-md-3">
                      <input
                        type="text"
                        placeholder="Title"
                        name="title{{ i }}"
                        id="title{{ i }}"
                        #refTitle="ngModel"
                        [(ngModel)]="report.Title"
                        class="form-control input-text-css"
                      />
                    </div>
                    <div class="col-md-3">
                      <p class="m-0 row">
                        <i
                          class="fa fa-upload ml-2"
                          *ngIf="!report.DocFileName"
                          (click)="docFile.click()"
                          aria-hidden="true"
                        ></i>
                        <ng-container *ngIf="report.DocFileName">
                          <div>
                            <i
                              class="fa fa-file-pdf mr-2"
                              aria-hidden="true"
                            ></i
                            >{{ report.DocFileName }}
                            <i
                              class="fa fa-times ml-2"
                              aria-hidden="true"
                              style="cursor: pointer"
                              (click)="removeFileAttachment(i)"
                            ></i>
                          </div>
                        </ng-container>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
            </ng-container>
          </form>
        </ng-container>

        <div *ngIf="saveBtn" class="text-right mb-2" style="margin-top: 6px">
          <button
            type="button"
            (click)="saveAssetInfo('Primary Collateral')"
            [disabled]="!f.form.valid"
            class="font-size-12 button-btn"
            [class.spinner]="loading"
          >
            Save
          </button>
        </div>

        <div
          *ngIf="isEdit"
          class="text-right mb-2"
          style="margin-right: 50px; margin-top: 5px"
        >
          <button
            type="button"
            (click)="editAssetInfo()"
            class="font-size-12 button-btn"
            [class.spinner]="loading"
          >
            Edit
          </button>
        </div>

        <div
          *ngIf="updateBtn"
          class="text-right mb-2"
          style="margin-right: 50px; margin-top: 5px"
        >
          <button
            type="button"
            (click)="saveAssetInfo('Primary Collateral')"
            class="font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="editPddDe"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">Edit</h6>
        <button
          type="button"
          (click)="closePddEditModal()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>
      <div classj="modal-body">
        <!--<ng-container *ngIf="Collateral=='Vehicle'">
          <div class="row m-0 mt-3" *ngIf="Info">
            <div class="col-md-12">
              <h1 class="fs-12 h-b">{{ Info.Vehicle_SubType }}</h1>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Vehicle Manufacture</h6>
              <p class="lead fs-12">{{ Info.VehicleManufacture }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Vehicle Category</h6>
              <p class="lead fs-12">{{ Info.VehicleCategory }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Vehicle Model Name</h6>
              <p class="lead fs-12">{{ Info.VehicleModelName }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Variant</h6>
              <p class="lead fs-12">{{ Info.Variant }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Manufacture Year</h6>
              <p class="lead fs-12">{{ Info.MefgYear }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Vehicle Registation No</h6>
              <p class="lead fs-12">{{ Info.VehicleRegistationNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Registation Date</h6>
              <p class="lead fs-12">{{ Info.RegistationDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Registation Expiry Date</h6>
              <p class="lead fs-12">{{ Info.RegistationExpiryDate }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Fuel Type</h6>
              <p class="lead fs-12">{{ Info.FuelType }}</p>
            </div>
            <div class="col-md-2" *ngIf="Info.Vehicle_SubType=='Commercial'">
              <h6 class="fs-12">Road Tax Upto</h6>
              <p class="lead fs-12">{{ Info.RoadTaxUpto }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Color</h6>
              <p class="lead fs-12">{{ Info.Color }}</p>
            </div>
            <div class="col-md-2" *ngIf="Info.Vehicle_SubType=='Commercial'">
              <h6 class="fs-12">Fitness Upto</h6>
              <p class="lead fs-12">{{ Info.FitnessUpto }}</p>
            </div>
            <div class="col-md-2" *ngIf="Info.Vehicle_SubType=='Commercial'">
              <h6 class="fs-12">Permit Upto</h6>
              <p class="lead fs-12">{{ Info.PermitUpto }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Vehicle Cost</h6>
              <p class="lead fs-12">{{ Info.VehicleCost }}</p>
            </div>
            <div class="col-md-2" *ngIf="Info.Vehicle_SubType=='Commercial'">
              <h6 class="fs-12">Route</h6>
              <p class="lead fs-12">{{ Info.Rout }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Engine No</h6>
              <p class="lead fs-12">{{ Info.EngineNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Chassis No</h6>
              <p class="lead fs-12">{{ Info.ChassisNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Key No</h6>
              <p class="lead fs-12">{{ Info.KeyNo }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">RC HPNEndorsment</h6> <p class="lead fs-12">{{ Info.RC_HPNEndorsment==true?'YES':'NO' }}</p>
            </div>
            <div class="col-md-2" *ngIf="Info.Vehicle_Type=='New'">
              <h6 class="fs-12">Invoice HPNEndorsment</h6> <p class="lead fs-12">{{ Info.Invoice_HPNEndorsment ==true?'YES':'NO'}}</p>
            </div>
          </div>
          <div *ngIf="Info != '' && Info != undefined">
            <div class="row m-0 mt-3" *ngIf="Info.Vehicle_Type=='Used'">
              <div class="col-md-12">
                <h1 class="fs-12 h-b">{{ Info.Vehicle_Type }} Vehicle</h1>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Valuation Done By</h6>
                <p class="lead fs-12">{{ Info.ValuationDoneBy }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Valuation Date</h6>
                <p class="lead fs-12">{{ Info.ValuationDate }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Valuation Amount</h6>
                <p class="lead fs-12">{{ Info.ValuationAmount }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Valuation Type</h6>
                <p class="lead fs-12">{{ Info.ValuationType }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Other Valuation Type</h6>
                <p class="lead fs-12">{{ Info.OtherValuationType }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Other Valuation Amount</h6>
                <p class="lead fs-12">{{ Info.OtherValuationAmt }}</p>
              </div>
            </div>
          </div>

          <div *ngIf="Info != '' && Info != undefined">
            <div class="row m-0 mt-3" *ngIf="Info.Vehicle_Type=='New'">
              <div class="col-md-12">
                <h1 class="fs-12 h-b">{{ Info.Vehicle_Type }} Vehicle</h1>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">DO. No.</h6>
                <p class="lead fs-12">{{ Info.DONo }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">DO. Do Date</h6>
                <p class="lead fs-12">{{ Info.DODoDate }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Estimation Amount</h6>
                <p class="lead fs-12">{{ Info.EstimationAmount }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Invoice No.</h6>
                <p class="lead fs-12">{{ Info.InvoiceNo }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Invoice Date</h6>
                <p class="lead fs-12">{{ Info.InvoiceDate }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Invoice Value</h6>
                <p class="lead fs-12">{{ Info.InvoiceValue }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Dealer Contact Person</h6>
                <p class="lead fs-12">{{ Info.DealerContactPerson }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Dealer Contact No.</h6>
                <p class="lead fs-12">{{ Info.DealerContactNo }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Quotation in favour of</h6>
                <p class="lead fs-12">{{ Info.DOIssuedinfavourof }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Remark</h6>
                <p class="lead fs-12">{{ Info.Remark }}</p>
              </div>
              <div class="col-md-2">
                <h6 class="fs-12">Dealer</h6>
                <p class="lead fs-12">{{ Info.DealerId }}</p>
              </div>
            </div>
          </div>
          <div class="row mt-2 mx-1">
            <div class="col-md-12 mt-2  upload" *ngIf="AssetsAttachment.length>0">
              <b> Uploaded Vehicle Images </b>

              <div class="row m-0 mt-2 align-items-center" *ngFor="let report of AssetsAttachment; let i = index">
                <div class="col-md-1 pl-0">
                  {{ i + 1 }}
                </div>
                <div class="col-md-3">
                  <p class="lead fs-12">{{report.Title }}</p>
                </div>
                <div class="col-md-3">
                  <p class="m-0 row">
                    <a href="{{applicationDocUrl}}{{ApplicationNo}}/{{report.DocFileName}}" target="_blank"
                       *ngIf="report.DocFileName">{{report.DocFileName}}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>-->
        <!-- <div class="text-right mb-2" style="margin-right: 50px;">
            <button type="button" (click)="editAssetInfo(Info.AssetId)" mat-raised-button color="primary"
              [class.spinner]="loading" style="background-color: #28a745;color: #FFFFFF;font-size: 12px;">
              Edit
            </button>
          </div> -->
        <!--</ng-container>

        <ng-container *ngIf="Collateral=='Property'">
          <div class="row m-0 mt-3 mx-1" *ngIf="Info">
            <div class="col-md-2">
              <h6 class="fs-12">Property Owner Name</h6>
              <p class="lead fs-12">{{ Info.CustomerId }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Address Of Property</h6>
              <p class="lead fs-12">{{ Info.Address}}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">State</h6>
              <p class="lead fs-12">{{ Info.State }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">District</h6>
              <p class="lead fs-12">{{ Info.District }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Tehsil</h6>
              <p class="lead fs-12">{{ Info.tasil }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Type of Property</h6>
              <p class="lead fs-12">{{ Info.TypeofProperty }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Nature of Property</h6>
              <p class="lead fs-12">{{ Info.NatureofProperty }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Ownership Type</h6>
              <p class="lead fs-12">{{ Info.OwnershipType }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Ownership Document</h6>
              <p class="lead fs-12">{{ Info.OwnershipDocument }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">UnitofMeasurement</h6>
              <p class="lead fs-12">{{ Info.UnitofMeasurement }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Total Area</h6>
              <p class="lead fs-12">{{ Info.TotalArea }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Constructed Area</h6>
              <p class="lead fs-12">{{Info.ConstructedArea }}</p>
            </div>

            <div class="col-md-2">
              <h6 class="fs-12">Type of Mortgage</h6>
              <p class="lead fs-12">{{Info.TypeofMortgage }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Mortgage Singed By</h6>
              <p class="lead fs-12">{{Info.MortgageSingedBy }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Amount</h6>
              <p class="lead fs-12">{{Info.ValuationAmount }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Done By</h6>
              <p class="lead fs-12">{{Info.ValuationDoneBy }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Valuation Remark</h6>
              <p class="lead fs-12">{{Info.ValuationRemark }}</p>
            </div>
            <div class="col-md-2" *ngIf="Info.ValuationDoneByID!=''&&Info.ValuationDoneByID==0">
              <h6 class="fs-12">Valution Done By Name</h6>
              <p class="lead fs-12">{{Info.ValuationDoneByOther }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Search Report Done By</h6>
              <p class="lead fs-12">{{Info.SearchReportDoneBy }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Search Report Remark</h6>
              <p class="lead fs-12">{{Info.SearchReportRemark }}</p>
            </div>
            <div class="col-md-2" *ngIf="Info.SearchReportDoneByID!=''&&Info.SearchReportDoneByID==0">
              <h6 class="fs-12">Search Report Done By Name</h6>
              <p class="lead fs-12">{{Info.SearchReportDoneByOther }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">GeoLocation</h6>
              <p class="lead fs-12">{{Info.GeoLocation }}</p>
            </div>
          </div>
          <div class="row mt-2 mx-1">
            <div class="col-md-12 mt-2  upload" *ngIf="AssetsAttachment.length>0">
              <b> Uploaded Property Images </b>

              <div class="row m-0 mt-2 align-items-center" *ngFor="let report of AssetsAttachment; let i = index">
                <div class="col-md-1 pl-0">{{ i + 1 }}</div>
                <div class="col-md-3">
                  <p class="lead fs-12">{{report.Title }}</p>
                </div>
                <div class="col-md-3">
                  <p class="m-0 row">
                    <a href="{{applicationDocUrl}}{{ApplicationNo}}/{{report.DocFileName}}" target="_blank"
                       *ngIf="report.DocFileName">{{report.DocFileName}}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right mb-2" style="margin-right: 50px;">
            <button type="button" (click)="editAssetInfo(Info.Id)" mat-raised-button color="primary"
                    [class.spinner]="loading" style="background-color: #28a745;color: #FFFFFF;font-size: 12px;">
              Edit
            </button>
          </div>
        </ng-container>-->
        <div class="mx-1" id="updatePDD">
          <!--<div class="col-md-12">
            <h1 class="fs-12 h-b">Update Data</h1>
          </div>-->
          <!--<div class="row m-2" *ngIf="colDisplay.includes('Insurance')">
            <div class="col-md-6">
              <label for="InsuranceDetails">Insurance Details</label><br>
              <span><b>Update Insurance Details in Loan Master</b></span>

            </div>
          </div>-->
          <div class="row m-2">
            <div class="col-md-6">
              <label for="RegistrationNumber">Type</label>
              <select
                class="form-control input-text-css"
                disabled
                (change)="ChangeUpdateType()"
                name="UpdateType"
                id="UpdateType"
                [(ngModel)]="UpdateType"
              >
                <option value="">Select Type</option>
                <option value="RC">RC</option>
                <option value="Invoice">Invoice</option>
                <option value="Insurance">Insurance</option>
              </select>
            </div>
          </div>

          <form #tf="ngForm" novalidate>
            <div class="row m-2" *ngIf="UpdateType == 'RC'">
              <div class="col-md-6">
                <label for="RegistrationNumber">Vehicle No</label>
                <input
                  name="RegistrationNumber"
                  id="RegistrationNumber"
                  required
                  [(ngModel)]="UpdatedPDDDetails['VehicleRegistationNo']"
                  [disabled]="Info.VehicleRegistationNo"
                  [ngClass]="{
                    'is-invalid': tf.submitted && refRegistrationNumber.invalid,
                    'alert-warning':
                      refRegistrationNumber.invalid &&
                      (refRegistrationNumber.dirty ||
                        refRegistrationNumber.touched ||
                        refRegistrationNumber.untouched)
                  }"
                  (change)="RCVerification()"
                  #refRegistrationNumber="ngModel"
                  placeholder="Enter Vehicle Number"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-6">
                <label for="RegistrationIssueDate">
                  Registration Issue Date
                </label>

                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="RegistrationIssueDate"
                    id="RegistrationIssueDate"
                    #refRegistrationIssueDate="ngModel"
                    required
                    [disabled]="Info.RegistationDate"
                    [ngClass]="{
                      'is-invalid':
                        tf.submitted && refRegistrationIssueDate.invalid,
                      'alert-warning':
                        refRegistrationIssueDate.invalid &&
                        (refRegistrationIssueDate.dirty ||
                          refRegistrationIssueDate.touched ||
                          refRegistrationIssueDate.untouched)
                    }"
                    class="form-control input-text-css"
                    [(ngModel)]="UpdatedPDDDetails['RegistrationIssueDate']"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>

                <!--<input name="RegistrationIssueDate" id="RegistrationIssueDate" required [(ngModel)]="UpdatedPDDDetails['RegistrationIssueDate']"
        [(bsValue)]="UpdatedPDDDetails['RegistrationIssueDate']" placeholder="DD/MM/YYYY"
        [ngClass]="{ 'is-invalid': tf.submitted && refRegistrationIssueDate.invalid, 'alert-warning': refRegistrationIssueDate.invalid  && (refRegistrationIssueDate.dirty || refRegistrationIssueDate.touched || refRegistrationIssueDate.untouched) }"
        #refRegistrationIssueDate="ngModel"
        class="form-control input-text-css" bsDatepicker>-->
              </div>
              <div class="col-md-6">
                <label for="RegistrationExpiryDate">
                  Registration Expiry Date
                </label>

                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="picker1"
                    [disabled]="Info.RegistationExpiryDate"
                    [min]="UpdatedPDDDetails['RegistrationIssueDate']"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="RegistrationExpiryDate"
                    id="RegistrationExpiryDate"
                    #refRegistrationExpiryDate="ngModel"
                    required
                    [ngClass]="{
                      'is-invalid':
                        tf.submitted && refRegistrationExpiryDate.invalid,
                      'alert-warning':
                        refRegistrationExpiryDate.invalid &&
                        (refRegistrationExpiryDate.dirty ||
                          refRegistrationExpiryDate.touched ||
                          refRegistrationExpiryDate.untouched)
                    }"
                    class="form-control input-text-css"
                    [(ngModel)]="UpdatedPDDDetails['RegistrationExpiryDate']"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker1"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </div>
                <!--<input name="RegistrationExpiryDate" id="RegistrationExpiryDate" [(ngModel)]="UpdatedPDDDetails['RegistrationExpiryDate']"
        [ngClass]="{ 'is-invalid': tf.submitted && refRegistrationExpiryDate.invalid, 'alert-warning': refRegistrationExpiryDate.invalid  && (refRegistrationExpiryDate.dirty || refRegistrationExpiryDate.touched || refRegistrationExpiryDate.untouched) }"
        #refRegistrationExpiryDate="ngModel"
        [(bsValue)]="UpdatedPDDDetails['RegistrationExpiryDate']" placeholder="DD/MM/YYYY"
        class="form-control input-text-css" bsDatepicker required>-->
              </div>
              <div class="col-md-6">
                <label class="">Engine No</label>
                <input
                  type="text"
                  #refEngineNo="ngModel"
                  required
                  placeholder="Engine No"
                  (change)="checkDuplication_Vehicle('EngineNo')"
                  [ngClass]="{
                    'is-invalid': tf.submitted && refEngineNo.invalid,
                    'alert-warning':
                      (refEngineNo.invalid &&
                        (refEngineNo.dirty ||
                          refEngineNo.touched ||
                          refEngineNo.untouched)) ||
                      (Info.EngineNo != '' &&
                        Info.EngineNo != null &&
                        Info.EngineNo != UpdatedPDDDetails['EngineNo'])
                  }"
                  [(ngModel)]="UpdatedPDDDetails['EngineNo']"
                  name="ENO"
                  id="ENO"
                  class="form-control input-text-css"
                />
                <i
                  class="fa fa-times wrong-data"
                  aria-hidden="true"
                  *ngIf="
                    Info.EngineNo != '' &&
                    UpdatedPDDDetails['EngineNo'] != '' &&
                    UpdatedPDDDetails['EngineNo'] != null &&
                    Info.EngineNo != null &&
                    Info.EngineNo != UpdatedPDDDetails['EngineNo']
                  "
                ></i>
                <i
                  class="fa fa-check correct-data"
                  *ngIf="
                    Info.EngineNo != '' &&
                    UpdatedPDDDetails['EngineNo'] != '' &&
                    UpdatedPDDDetails['EngineNo'] != null &&
                    Info.EngineNo != null &&
                    Info.EngineNo == UpdatedPDDDetails['EngineNo']
                  "
                ></i>
              </div>
              <div class="col-md-6">
                <label class="">Chassis No</label>
                <input
                  type="text"
                  #refChassisNo="ngModel"
                  placeholder="Chassis No"
                  required
                  (change)="checkDuplication_Vehicle('ChassisNo')"
                  [ngClass]="{
                    'is-invalid': tf.submitted && refChassisNo.invalid,
                    'alert-warning':
                      (refChassisNo.invalid &&
                        (refChassisNo.dirty ||
                          refChassisNo.touched ||
                          refChassisNo.untouched)) ||
                      (Info.ChassisNo != '' &&
                        Info.ChassisNo != null &&
                        Info.ChassisNo != UpdatedPDDDetails['ChassisNo'])
                  }"
                  [(ngModel)]="UpdatedPDDDetails['ChassisNo']"
                  name="CNO"
                  id="CNO"
                  class="form-control input-text-css"
                />
                <i
                  class="fa fa-times wrong-data"
                  aria-hidden="true"
                  *ngIf="
                    Info.ChassisNo != '' &&
                    UpdatedPDDDetails['ChassisNo'] != '' &&
                    Info.ChassisNo != null &&
                    UpdatedPDDDetails['ChassisNo'] != null &&
                    Info.ChassisNo != UpdatedPDDDetails['ChassisNo']
                  "
                ></i>
                <i
                  class="fa fa-check correct-data"
                  *ngIf="
                    Info.ChassisNo != '' &&
                    Info.ChassisNo != null &&
                    UpdatedPDDDetails['ChassisNo'] != '' &&
                    UpdatedPDDDetails['ChassisNo'] != null &&
                    Info.ChassisNo == UpdatedPDDDetails['ChassisNo']
                  "
                ></i>
              </div>
              <div class="col-md-6 mt-4">
                <mat-checkbox
                  id="RC_HPNEndorsment"
                  [(ngModel)]="UpdatedPDDDetails['RC_HPNEndorsment']"
                  name="RC_HPNEndorsment"
                >
                  RC HPNEndorsment
                </mat-checkbox>
              </div>

              <div class="col-md-12 mt-2">
                <button
                  type="button"
                  *ngIf="RCVerified_Button"
                  [hidden]="RCKYC_IsVerified == 1 || RCLastVerfiedDate"
                  (click)="onVerification(0)"
                  style="
                    margin-left: 5px;
                    font-size: 12px;
                    width: 105px;
                    height: 35px;
                    margin-top: 16px;
                    padding: 0px;
                  "
                  class="btn font-size-12 button-btn"
                >
                  RC Verification
                </button>
                <button
                  type="button"
                  *ngIf="
                    UpdatedPDDDetails['VehicleRegistationNo'] != '' &&
                    RCVerified_Button &&
                    (RCKYC_IsVerified == 1 || RCLastVerfiedDate)
                  "
                  (click)="onVerification(1)"
                  class="btn font-size-6 button-btn"
                  style="width: max-content !important"
                >
                  RC RE-Verification
                </button>
                <span
                  *ngIf="
                    UpdatedPDDDetails['VehicleRegistationNo'] != '' &&
                    RCVerified_Button &&
                    RCLastVerfiedDate
                  "
                  style="margin-top: 25px"
                >
                  VERIFIED {{ RCLastVerfiedDate }}
                </span>
              </div>

              <div
                class="row mt-2 formborder ml-2 mr-2 mt-2"
                style="background-color: #d2ebec"
                *ngIf="
                  UpdatedPDDDetails['VehicleRegistationNo'] != '' &&
                  RCVerified_Button &&
                  (RCKYC_IsVerified == 1 || RCLastVerfiedDate)
                "
              >
                <div class="col-md-12 bg-success1">Verified RC Data</div>

                <div class="col-md-2 border-div">
                  <h6>Vehicle Registation No</h6>
                  <p>
                    {{ APIResponse.DoucumentNumber }}
                  </p>
                </div>

                <div class="col-md-2 border-div">
                  <h6>Fit Up To</h6>
                  <p>
                    {{ APIResponse.fit_up_to }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Registration Date</h6>
                  <p>
                    {{ APIResponse.registration_date }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Owner Name</h6>
                  <p>
                    {{ APIResponse.owner_name }}
                  </p>
                </div>

                <div class="col-md-2 border-div">
                  <h6>Father Name</h6>
                  <p>
                    {{ APIResponse.father_name }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Mobile Number</h6>
                  <p>
                    {{ APIResponse.mobile_number }}
                  </p>
                </div>

                <div class="col-md-2 border-div">
                  <h6>Vehicle Category</h6>
                  <p>
                    {{ APIResponse.vehicle_category }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Vehicle Chasi Number</h6>
                  <p>
                    {{ APIResponse.vehicle_chasi_number }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Vehicle Engine Number</h6>
                  <p>
                    {{ APIResponse.vehicle_engine_number }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Maker Description</h6>
                  <p>
                    {{ APIResponse.maker_description }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Maker Model</h6>
                  <p>
                    {{ APIResponse.maker_model }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Body Type</h6>
                  <p>
                    {{ APIResponse.body_type }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Fuel Type</h6>
                  <p>
                    {{ APIResponse.fuel_type }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Color</h6>
                  <p>
                    {{ APIResponse.color }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Norms Type</h6>
                  <p>
                    {{ APIResponse.norms_type }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Financer</h6>
                  <p>
                    {{ APIResponse.financer }}
                  </p>
                </div>

                <div class="col-md-2 border-div">
                  <h6>financed</h6>
                  <p>
                    {{ APIResponse.financed }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Insurance Company</h6>
                  <p>
                    {{ APIResponse.insurance_company }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Insurance Policy Number</h6>
                  <p>
                    {{ APIResponse.insurance_policy_number }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Insurance Upto</h6>
                  <p>
                    {{ APIResponse.insurance_upto }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Manufacturing Date</h6>
                  <p>
                    {{ APIResponse.manufacturing_date }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Registered At</h6>
                  <p>
                    {{ APIResponse.registered_at }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Tax Upto</h6>
                  <p>
                    {{ APIResponse.tax_upto }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Tax Paid Upto</h6>
                  <p>
                    {{ APIResponse.tax_paid_upto }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Seat Capacity</h6>
                  <p>
                    {{ APIResponse.seat_capacity }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Vehicle Category Description</h6>
                  <p>
                    {{ APIResponse.vehicle_category_description }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Pucc Number</h6>
                  <p>
                    {{ APIResponse.pucc_number }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Pucc Upto</h6>
                  <p>
                    {{ APIResponse.pucc_upto }}
                  </p>
                </div>
                <div class="col-md-2 border-div">
                  <h6>Owner Number</h6>
                  <p>
                    {{ APIResponse.owner_number }}
                  </p>
                </div>
                <div class="col-md-12 border-div">
                  <h6>Permanent Address</h6>
                  <p>
                    {{ APIResponse.permanent_address }}
                  </p>
                </div>
              </div>
            </div>

            <div class="row m-2" *ngIf="UpdateType == 'Invoice'">
              <div class="col-md-6">
                <label for="InvoiceNo">Invoice No</label>
                <input
                  name="InvoiceNo"
                  id="InvoiceNo"
                  required
                  [(ngModel)]="UpdatedPDDDetails['InvoiceNo']"
                  [disabled]="Info.InvoiceNo"
                  [ngClass]="{
                    'is-invalid': tf.submitted && refInvoiceNo.invalid,
                    'alert-warning':
                      refInvoiceNo.invalid &&
                      (refInvoiceNo.dirty ||
                        refInvoiceNo.touched ||
                        refInvoiceNo.untouched)
                  }"
                  #refInvoiceNo="ngModel"
                  placeholder="Enter Invoice No"
                  class="form-control input-text-css"
                />
              </div>

              <div class="col-md-6">
                <label for="InvoiceDate"> Invoice Date</label>

                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="DDInvoiceDate"
                    [disabled]="Info.InvoiceDate"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="InvoiceDate"
                    id="InvoiceDate"
                    #refInvoiceDate="ngModel"
                    required
                    [ngClass]="{
                      'is-invalid': tf.submitted && refInvoiceDate.invalid,
                      'alert-warning':
                        refInvoiceDate.invalid &&
                        (refInvoiceDate.dirty ||
                          refInvoiceDate.touched ||
                          refInvoiceDate.untouched)
                    }"
                    class="form-control input-text-css"
                    [(ngModel)]="UpdatedPDDDetails['InvoiceDate']"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="DDInvoiceDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #DDInvoiceDate></mat-datepicker>
                </div>
              </div>
              <div class="col-md-6">
                <label class="">Engine No</label>
                <input
                  type="text"
                  #refEngineNo="ngModel"
                  required
                  placeholder="Engine No"
                  (change)="checkDuplication_Vehicle('EngineNo')"
                  [ngClass]="{
                    'is-invalid': tf.submitted && refEngineNo.invalid,
                    'alert-warning':
                      (refEngineNo.invalid &&
                        (refEngineNo.dirty ||
                          refEngineNo.touched ||
                          refEngineNo.untouched)) ||
                      (Info.EngineNo != '' &&
                        Info.EngineNo != null &&
                        Info.EngineNo != UpdatedPDDDetails['EngineNo'])
                  }"
                  [(ngModel)]="UpdatedPDDDetails['EngineNo']"
                  name="ENO"
                  id="ENO"
                  class="form-control input-text-css"
                />
                <i
                  class="fa fa-times wrong-data"
                  aria-hidden="true"
                  *ngIf="
                    Info.EngineNo != '' &&
                    UpdatedPDDDetails['EngineNo'] != '' &&
                    UpdatedPDDDetails['EngineNo'] != null &&
                    Info.EngineNo != null &&
                    Info.EngineNo != UpdatedPDDDetails['EngineNo']
                  "
                ></i>
                <i
                  class="fa fa-check correct-data"
                  *ngIf="
                    Info.EngineNo != '' &&
                    UpdatedPDDDetails['EngineNo'] != '' &&
                    UpdatedPDDDetails['EngineNo'] != null &&
                    Info.EngineNo != null &&
                    Info.EngineNo == UpdatedPDDDetails['EngineNo']
                  "
                ></i>
              </div>
              <div class="col-md-6">
                <label class="">Chassis No</label>
                <input
                  type="text"
                  #refChassisNo="ngModel"
                  placeholder="Chassis No"
                  required
                  (change)="checkDuplication_Vehicle('ChassisNo')"
                  [ngClass]="{
                    'is-invalid': tf.submitted && refChassisNo.invalid,
                    'alert-warning':
                      (refChassisNo.invalid &&
                        (refChassisNo.dirty ||
                          refChassisNo.touched ||
                          refChassisNo.untouched)) ||
                      (Info.ChassisNo != '' &&
                        Info.ChassisNo != null &&
                        Info.ChassisNo != UpdatedPDDDetails['ChassisNo'])
                  }"
                  [(ngModel)]="UpdatedPDDDetails['ChassisNo']"
                  name="CNO"
                  id="CNO"
                  class="form-control input-text-css"
                />
                <i
                  class="fa fa-times wrong-data"
                  aria-hidden="true"
                  *ngIf="
                    Info.ChassisNo != '' &&
                    Info.ChassisNo != null &&
                    UpdatedPDDDetails['ChassisNo'] != '' &&
                    UpdatedPDDDetails['ChassisNo'] != null &&
                    Info.ChassisNo != UpdatedPDDDetails['ChassisNo']
                  "
                ></i>
                <i
                  class="fa fa-check correct-data"
                  *ngIf="
                    Info.ChassisNo != '' &&
                    Info.ChassisNo != null &&
                    UpdatedPDDDetails['ChassisNo'] != '' &&
                    UpdatedPDDDetails['ChassisNo'] != null &&
                    Info.ChassisNo == UpdatedPDDDetails['ChassisNo']
                  "
                ></i>
              </div>
              <div class="col-md-6 mt-4">
                <mat-checkbox
                  id="Invoice_HPNEndorsment"
                  [(ngModel)]="UpdatedPDDDetails['Invoice_HPNEndorsment']"
                  name="Invoice_HPNEndorsment"
                >
                  Invoice HPNEndorsment
                </mat-checkbox>
              </div>
            </div>

            <div class="row m-2" *ngIf="UpdateType == 'Insurance'">
              <div class="col-md-6">
                <label for="InvoiceNo">Insurance Company</label>
                <select
                  name="AssetInsuranceCompany"
                  id="AssetInsuranceCompany"
                  #refAssetInsuranceCompany="ngModel"
                  [ngClass]="{
                    'is-invalid':
                      tf.submitted && refAssetInsuranceCompany.invalid,
                    'alert-warning':
                      refAssetInsuranceCompany.invalid &&
                      (refAssetInsuranceCompany.dirty ||
                        refAssetInsuranceCompany.touched ||
                        refAssetInsuranceCompany.untouched)
                  }"
                  [(ngModel)]="UpdatedPDDDetails['AssetInsuranceCompany']"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Insurance Company</option>
                  <option
                    *ngFor="let list of Insurance_Company_List"
                    [value]="list.Int_Id"
                  >
                    {{ list.Insurance_company }}
                  </option>
                </select>
              </div>

              <div class="col-md-6">
                <label for="AssetInsuranceDate">Insurance Date</label>

                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="AssetInsuranceDate"
                    (ngModelChange)="changeAssetRenewalDate($event)"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="AssetInsuranceDate"
                    id="AssetInsuranceDate"
                    #refAssetInsuranceDate="ngModel"
                    required
                    [ngClass]="{
                      'is-invalid':
                        tf.submitted && refAssetInsuranceDate.invalid,
                      'alert-warning':
                        refAssetInsuranceDate.invalid &&
                        (refAssetInsuranceDate.dirty ||
                          refAssetInsuranceDate.touched ||
                          refAssetInsuranceDate.untouched)
                    }"
                    class="form-control input-text-css"
                    [(ngModel)]="UpdatedPDDDetails['AssetInsuranceDate']"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="AssetInsuranceDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #AssetInsuranceDate></mat-datepicker>
                </div>
              </div>
              <div class="col-md-6">
                <label for="AssetRenewalDate">Renewal Date</label>

                <div class="datepicker_feild">
                  <input
                    [matDatepicker]="AssetRenewalDate"
                    dateConvert
                    placeholder="DD/MM/YYYY"
                    name="AssetRenewalDate"
                    id="AssetRenewalDate"
                    (ngModelChange)="changeRenewalDate($event)"
                    #refAssetRenewalDate="ngModel"
                    required
                    [ngClass]="{
                      'is-invalid': tf.submitted && refAssetRenewalDate.invalid,
                      'alert-warning':
                        refAssetRenewalDate.invalid &&
                        (refAssetRenewalDate.dirty ||
                          refAssetRenewalDate.touched ||
                          refAssetRenewalDate.untouched)
                    }"
                    class="form-control input-text-css"
                    [(ngModel)]="UpdatedPDDDetails['AssetRenewalDate']"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="AssetRenewalDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #AssetRenewalDate></mat-datepicker>
                </div>
              </div>
              <div class="col-md-6">
                <label for="AssetCoverNoteNo">Policy No</label>
                <input
                  name="AssetCoverNoteNo"
                  id="AssetCoverNoteNo"
                  required
                  [(ngModel)]="UpdatedPDDDetails['AssetCoverNoteNo']"
                  [ngClass]="{
                    'is-invalid': tf.submitted && refAssetCoverNoteNo.invalid,
                    'alert-warning':
                      refAssetCoverNoteNo.invalid &&
                      (refAssetCoverNoteNo.dirty ||
                        refAssetCoverNoteNo.touched ||
                        refAssetCoverNoteNo.untouched)
                  }"
                  #refAssetCoverNoteNo="ngModel"
                  placeholder="Enter Policy No"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-6">
                <label for="InvoiceNo">Policy Type</label>
                <select
                  name="policyType"
                  id="policyType"
                  #refpolicyType="ngModel"
                  [ngClass]="{
                    'is-invalid': tf.submitted && refpolicyType.invalid,
                    'alert-warning':
                      refpolicyType.invalid &&
                      (refpolicyType.dirty ||
                        refpolicyType.touched ||
                        refpolicyType.untouched)
                  }"
                  [(ngModel)]="UpdatedPDDDetails['AssetPolicyType']"
                  class="form-control input-text-css"
                  required
                >
                  <option value="">Select Policy Type</option>
                  <option
                    *ngFor="let Type of PolicyTypeAssetsOption"
                    [value]="Type.Id"
                  >
                    {{ Type.Value }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <label for="AssetIDVAmount">IDV Amount</label>
                <input
                  name="AssetIDVAmount"
                  id="AssetIDVAmount"
                  required
                  onkeydown="notDecimal(event)"
                  oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                  [(ngModel)]="UpdatedPDDDetails['AssetIDVAmount']"
                  [ngClass]="{
                    'is-invalid': tf.submitted && refAssetIDVAmount.invalid,
                    'alert-warning':
                      refAssetIDVAmount.invalid &&
                      (refAssetIDVAmount.dirty ||
                        refAssetIDVAmount.touched ||
                        refAssetIDVAmount.untouched)
                  }"
                  #refAssetIDVAmount="ngModel"
                  placeholder="Enter IDV Amount"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-6">
                <label for="AssetPremiumAmount">Premium Amount</label>
                <input
                  name="AssetPremiumAmount"
                  id="AssetPremiumAmount"
                  required
                  onkeydown="notDecimal(event)"
                  oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                  [(ngModel)]="UpdatedPDDDetails['AssetPremiumAmount']"
                  [ngClass]="{
                    'is-invalid': tf.submitted && refAssetPremiumAmount.invalid,
                    'alert-warning':
                      refAssetPremiumAmount.invalid &&
                      (refAssetPremiumAmount.dirty ||
                        refAssetPremiumAmount.touched ||
                        refAssetPremiumAmount.untouched)
                  }"
                  #refAssetPremiumAmount="ngModel"
                  placeholder="Enter  Premium Amount"
                  class="form-control input-text-css"
                />
              </div>
              <div class="col-md-6">
                <label class="">Engine No</label>
                <input
                  type="text"
                  #refEngineNo="ngModel"
                  required
                  placeholder="Engine No"
                  (change)="checkDuplication_Vehicle('EngineNo')"
                  [ngClass]="{
                    'is-invalid': tf.submitted && refEngineNo.invalid,
                    'alert-warning':
                      (refEngineNo.invalid &&
                        (refEngineNo.dirty ||
                          refEngineNo.touched ||
                          refEngineNo.untouched)) ||
                      (Info.EngineNo != '' &&
                        Info.EngineNo != null &&
                        Info.EngineNo != UpdatedPDDDetails['EngineNo'])
                  }"
                  [(ngModel)]="UpdatedPDDDetails['EngineNo']"
                  name="ENO"
                  id="ENO"
                  class="form-control input-text-css"
                />
                <i
                  class="fa fa-times wrong-data"
                  aria-hidden="true"
                  *ngIf="
                    Info.EngineNo != '' &&
                    UpdatedPDDDetails['EngineNo'] != '' &&
                    UpdatedPDDDetails['EngineNo'] != null &&
                    Info.EngineNo != null &&
                    Info.EngineNo != UpdatedPDDDetails['EngineNo']
                  "
                ></i>
                <i
                  class="fa fa-check correct-data"
                  *ngIf="
                    Info.EngineNo != '' &&
                    UpdatedPDDDetails['EngineNo'] != '' &&
                    UpdatedPDDDetails['EngineNo'] != null &&
                    Info.EngineNo != null &&
                    Info.EngineNo == UpdatedPDDDetails['EngineNo']
                  "
                ></i>
              </div>
              <div class="col-md-6">
                <label class="">Chassis No</label>
                <input
                  type="text"
                  #refChassisNo="ngModel"
                  placeholder="Chassis No"
                  required
                  (change)="checkDuplication_Vehicle('ChassisNo')"
                  [ngClass]="{
                    'is-invalid': tf.submitted && refChassisNo.invalid,
                    'alert-warning':
                      (refChassisNo.invalid &&
                        (refChassisNo.dirty ||
                          refChassisNo.touched ||
                          refChassisNo.untouched)) ||
                      (Info.ChassisNo != '' &&
                        Info.ChassisNo != null &&
                        Info.ChassisNo != UpdatedPDDDetails['ChassisNo'])
                  }"
                  [(ngModel)]="UpdatedPDDDetails['ChassisNo']"
                  name="CNO"
                  id="CNO"
                  class="form-control input-text-css"
                />
                <i
                  class="fa fa-times wrong-data"
                  aria-hidden="true"
                  *ngIf="
                    Info.ChassisNo != '' &&
                    Info.ChassisNo != null &&
                    UpdatedPDDDetails['ChassisNo'] != '' &&
                    UpdatedPDDDetails['ChassisNo'] != null &&
                    Info.ChassisNo != UpdatedPDDDetails['ChassisNo']
                  "
                ></i>
                <i
                  class="fa fa-check correct-data"
                  *ngIf="
                    Info.ChassisNo != '' &&
                    Info.ChassisNo != null &&
                    UpdatedPDDDetails['ChassisNo'] != '' &&
                    UpdatedPDDDetails['ChassisNo'] != null &&
                    Info.ChassisNo == UpdatedPDDDetails['ChassisNo']
                  "
                ></i>
              </div>
              <div class="col-md-6 mt-4">
                <mat-checkbox
                  id="AssetInsuranceEndorsed"
                  [(ngModel)]="UpdatedPDDDetails['AssetInsuranceEndorsed']"
                  name="AssetInsuranceEndorsed"
                >
                  Insurance Endorsed
                </mat-checkbox>
              </div>
            </div>
          </form>
          <!--<div class="row m-2 " *ngIf="colDisplay.includes('VehicleRegistationNo')">


      <div class="col-md-6">
        <label for="RegistrationNumber">Vehicle No</label>
        <input name="RegistrationNumber" id="RegistrationNumber"
               [(ngModel)]="UpdatedPDDDetails['VehicleRegistationNo']" placeholder="Enter Vehicle Number"
               class="form-control input-text-css">
      </div>

    </div>
    <div class="row m-2" *ngIf="colDisplay.includes('InvoiceNo')">
      <div class="col-md-6">
        <label for="Invoice"> Invoice Number</label>
        <input name="Invoice" id="Invoice" [(ngModel)]="UpdatedPDDDetails['InvoiceNo']"
               placeholder="Enter invoice Number" class="form-control input-text-css">
      </div>

    </div>
    <div class="row m-2" *ngIf="colDisplay.includes('AssetInsuranceEndorsed')">
      <div class="col-md-6">
        <label for="InsuranceEnd"> Insurance Endorsement</label>
        <select class="form-control input-text-css" name="InsuranceEnd" id="InsuranceEnd" [(ngModel)]="UpdatedPDDDetails['InsuranceEnd']">
          <option value="undefined">Select Type</option>
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
      </div>
    </div>
    <div class="row m-2" *ngIf="colDisplay.includes('RC_HPNEndorsment')">

      <div class="col-md-6">
        <label for="RCEnd"> RC Endorcement</label>
        <select class="form-control input-text-css" name="RCEnd" id="RCEnd" [(ngModel)]="UpdatedPDDDetails['RC']">
          <option value="undefined">Select Type</option>
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
      </div>

    </div>
    <div class="row m-2" *ngIf="colDisplay.includes('FitnessUpto')">

      <div class="col-md-6">
        <label for="FitnessUpto"> Fitness Upto</label>
        <input name="FitnessUpto" id="FitnessUpto" [(ngModel)]="UpdatedPDDDetails['FitnessUpto']"
               [(bsValue)]="UpdatedPDDDetails['FitnessUpto']" placeholder="DD/MM/YYYY"
               class="form-control input-text-css" bsDatepicker required>
      </div>
    </div>
    <div class="row m-2" *ngIf="colDisplay.includes('PermitUpto')">

      <div class="col-md-6">
        <label for="PermitUpto"> Permit Upto</label>
        <input name="PermitUpto" id="PermitUpto" [(ngModel)]="UpdatedPDDDetails['PermitUpto']"
               [(bsValue)]="UpdatedPDDDetails['PermitUpto']" placeholder="DD/MM/YYYY"
               class="form-control input-text-css" bsDatepicker required>
      </div>
    </div>
    <div class="row m-2" *ngIf="colDisplay.includes('RegistationExpiryDate')">

      <div class="col-md-6">
        <label for="RegistationExpiryDate"> Registration Expiry Date</label>
        <input name="RegistationExpiryDate" id="RegistationExpiryDate" [(ngModel)]="UpdatedPDDDetails['RegistationExpiryDate']"
               [bsConfig]="{dateInputFormat: 'DD-MM-YYYY'}" [(bsValue)]="UpdatedPDDDetails['RegistationExpiryDate']" placeholder="DD/MM/YYYY"
               class="form-control input-text-css" bsDatepicker required>
      </div>
    </div>-->
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          (click)="onSaveEditedPDDDetail()"
          *ngIf="saveBtn && UpdateType"
          class="mt-3 font-size-12 button-btn"
          [class.spinner]="loading"
          [disabled]="
            !tf.form.valid ||
            (Info.EngineNo != '' &&
              Info.EngineNo != null &&
              Info.EngineNo != UpdatedPDDDetails['EngineNo']) ||
            (Info.ChassisNo != '' &&
              Info.ChassisNo != null &&
              Info.ChassisNo != UpdatedPDDDetails['ChassisNo'])
          "
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade in"
  id="InsuranceDetailModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Insurance Details
        </h6>
        <button
          type="button"
          (click)="onCloseInsuranceDetail()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i class="far text-black fa-times-circle"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <h1 class="fs-12 h-b">Insurance Details</h1>
        <div class="row m-0 mt-3" [hidden]="!AssetsDetailShow">
          <div class="col-md-2">
            <h6 class="fs-12">Insurance Type</h6>
            <p class="lead fs-12">{{ Type }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">{{ Type }} Name</h6>
            <p class="lead fs-12">{{ InsuranceInfo["Name"] }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Insurance Company</h6>
            <p class="lead fs-12">
              {{ InsuranceInfo["AssetInsuranceCompanyName"] }}
            </p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Insurance Company Branch</h6>
            <p class="lead fs-12">
              {{ InsuranceInfo["AssetInsuranceCompanyBranchName"] }}
            </p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Agent Name</h6>
            <p class="lead fs-12">{{ InsuranceInfo["AssetAgentName"] }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Insurance Date</h6>
            <p class="lead fs-12">{{ InsuranceInfo["AssetInsuranceDate"] }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Renewal Date</h6>
            <p class="lead fs-12">{{ InsuranceInfo["AssetRenewalDate"] }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Cover Note No/Policy No.</h6>
            <p class="lead fs-12">{{ InsuranceInfo["AssetCoverNoteNo"] }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Policy Type</h6>
            <p class="lead fs-12">{{ InsuranceInfo["AssetPolicyTypeName"] }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">IDV Amount</h6>
            <p class="lead fs-12">{{ InsuranceInfo["AssetIdvAmount"] }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Premium Amount</h6>
            <p class="lead fs-12">{{ InsuranceInfo["AssetPremiumAmount"] }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Nominee Name</h6>
            <p class="lead fs-12">{{ InsuranceInfo["AssetNomineeName"] }}</p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Nominee Relation with Borrower</h6>
            <p class="lead fs-12">
              {{ InsuranceInfo["AssetNomineeRelationwithHirerName"] }}
            </p>
          </div>
          <div class="col-md-2">
            <h6 class="fs-12">Insurance endorsed</h6>
            <p class="lead fs-12">
              {{ InsuranceInfo["AssetInsuranceEndorsed"] }}
            </p>
          </div>
          <!-- <div class="col-md-2">
            <h6 class="fs-12">Premium deducted</h6>
            <p class="lead fs-12"> {{InsuranceInfo["AssetPremiumAmountLoan}}</p>
          </div> -->
        </div>

        <div class="row m-0 col-md-12 p-0" [hidden]="!CustomerDetailShow">
          <div class="row m-0 col-md-12 p-0">
            <div class="col-md-2">
              <h6 class="fs-12">Insurance Type</h6>
              <p class="lead fs-12">{{ Type }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">{{ Type }} Name</h6>
              <p class="lead fs-12">{{ InsuranceInfo["Name"] }}</p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Insurance Company</h6>
              <p class="lead fs-12">
                {{ InsuranceInfo["CustomerInsHirerLifeInsCmnyName"] }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Insurance Company Branch</h6>
              <p class="lead fs-12">
                {{
                  InsuranceInfo["CustomerInsAssetInsuranceCompanyBranchName"]
                }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Agent Name</h6>
              <p class="lead fs-12">
                {{ InsuranceInfo["CustomerInsAgentName"] }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Insurance Date</h6>
              <p class="lead fs-12">
                {{ InsuranceInfo["CustomerInsInsuranceDate"] }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Expiry Date</h6>
              <p class="lead fs-12">
                {{ InsuranceInfo["CustomerInsExpireDate"] }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Policy No.</h6>
              <p class="lead fs-12">
                {{ InsuranceInfo["CustomerInsPolicyNo"] }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Policy Type</h6>
              <p class="lead fs-12">
                {{ InsuranceInfo["CustomerInsPolicyTypeName"] }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Sum Assured</h6>
              <p class="lead fs-12">
                {{ InsuranceInfo["CustomerInsSumAssured"] }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Premium Amount</h6>
              <p class="lead fs-12">
                {{ InsuranceInfo["CustomerInsPremiumAmount"] }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Nominee Name</h6>
              <p class="lead fs-12">
                {{ InsuranceInfo["CustomerInsNomineeName"] }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Nominee Relation with Borrower</h6>
              <p class="lead fs-12">
                {{ InsuranceInfo["AssetNomineeRelationwithHirerName"] }}
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="fs-12">Insurance endorsed</h6>
              <p class="lead fs-12">
                {{ InsuranceInfo["CustomerInsNomineeRelationHirer"] }}
              </p>
            </div>
            <!-- <div class="col-md-2">
              <h6 class="fs-12">Premium deducted</h6>
              <p class="lead fs-12"> {{InsuranceInfo["CustomerInsPremiumAmountDeducted"]}}</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
