import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

import { QuickLoanService } from "src/app/_QuickLoan/services/quickLoan.service";
import { DataSharingService } from "src/app/AuthGuard/DataSharingService";
import { EncrDecrService } from "src/app/AuthGuard/EncrDecrService";
import { DialogService } from "src/app/Shared/dialog.service";
declare var $: any;

import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { SnackbarComponent } from "src/app/snackbar/snackbar.component";
import { LmsService } from "../services/lms.service";

@Component({
  selector: "app-lms-sanction-condition",
  templateUrl: "./lms-sanction-condition.component.html",
  styleUrls: ["./lms-sanction-condition.component.scss"],
})
export class LmsSanctionConditionComponent implements OnInit {
  @Input() loanSummary: any;

  showSpinner: boolean = false;
  PageId: any = null;
  Value: any = "Add";
  BtnValue: any = "Save";

  SanctionConditionsDetails: any = [];
  SanctionConditionsData: any;
  loading: boolean = false;
  Headers: any = [];
  Id: any = 0;
  VoucherId: string;
  LoanId: string;
  currentUser: any;
  Header: string;
  PageAcess: any;

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _Route: Router,
    private activedRoute: ActivatedRoute,
    private dataSharingService: DataSharingService,
    public snackBar: MatSnackBar,
    private LmsService: LmsService,
    private DecriptService: EncrDecrService,
    private dialog: DialogService,
    private _QuickLoanService: QuickLoanService,
    private _EncrDecrService: EncrDecrService,
    private route: ActivatedRoute // private lmsService: LmsService
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.LoanId = decodeURIComponent(param.get("id"));
      this.VoucherId = this._EncrDecrService.decrypt(
        decodeURIComponent(param.get("vid"))
      );
      this.PageId = decodeURIComponent(param.get("page"));

      let AllPage = JSON.parse(sessionStorage.getItem("AllPageAcess"));
      this.PageAcess = AllPage.filter(
        (x) => x.Page_Name == "Sanction Conditions"
      );

      
    });
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    this.GetSanctionConditions();
  }

  addSanctionConditions() {
    this.Id = 0;
    this.Header = "";
    this.SanctionConditionsDetails = [];
    this.initializeDetail();
    $("#SanctionConditions").modal("show");
    $("#SanctionConditions").css("z-index", "1050");
  }
  onCloseSanctionConditions() {
    $("#SanctionConditions").modal("hide");
  }
  initializeDetail() {
    this.SanctionConditionsDetails.push({
      Detail: "",
    });
  }
  addMoreData() {
    this.initializeDetail();
  }
  removeData(index: any) {
    this.SanctionConditionsDetails.splice(index, 1);
  }
  OnSaveSanctionConditions() {
    this.onCloseSanctionConditions();
    this.showSpinner = true;
    var Details = { Data: this.SanctionConditionsDetails };
    // console.log("SanctionConditionsDetails", JSON.stringify(Details));
    this._QuickLoanService
      .QuickLoan_Save_Sanction_Conditions({
        JSON: JSON.stringify(Details),
        Header: this.Header,
        Loan_Id: this.loanSummary.LoanId,
        LoginUserId: this.currentUser.userId,
        Id: this.Id,
      })
      .subscribe((response: any) => {
        //console.log(response);
        if (response[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
          this.GetSanctionConditions();
          // this.QuickLoan_Save_PageProcess("Sanction Conditions");
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: response[0].MSG,
            ...this.configSuccess,
          });
        }
        this.showSpinner = false;
      });
  }

  GetSanctionConditions() {
    this.Headers = [];
    this._QuickLoanService
      .QuickLoan_Get_Sanction_Conditions({ Loan_Id: this.loanSummary.LoanId })
      .subscribe((response: any) => {
        // console.log(response);
        if (response.length > 0) {
          this.SanctionConditionsData = response;
          this.SanctionConditionsData.forEach((obj: any) => {
            if (
              this.Headers &&
              this.Headers.find((result) => result.Id === obj.Id)
            ) {
              this.Headers.find(
                (result) => result.Id === obj.Id
              ).Condition.push({ Detail: obj.Detail });
            } else {
              this.Headers.push({
                Id: obj.Id,
                Header: obj.Particulars,
                IsCompleted: obj.Is_Completed,
                Condition: [{ Detail: obj.Detail }],
              });
            }
          });
        }
      });
    // console.log("Header", this.Headers);
  }

  OnEditSanctionConditions(vdData) {
    this.Header = "";
    this.SanctionConditionsDetails = [];
    this.BtnValue = "Edit";
    this.Id = vdData.Id;
    this.Header = vdData.Header;
    vdData.Condition.forEach((obj: any) => {
      this.SanctionConditionsDetails.push({
        Detail: obj.Detail,
      });
    });
    $("#SanctionConditions").modal("show");
    $("#SanctionConditions").css("z-index", "1050");
  }
  OnDeleteSanctionConditions(Id) {
    this.dialog
      .openConfirmDialog(`Are you sure you want to Delete Sanction Conditions.`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this._QuickLoanService
            .QuickLoan_Delete_Sanction_Conditions({
              Loan_Id: this.loanSummary.LoanId,
              Id: Id,
            })
            .subscribe((response: any) => {
              // console.log(response);
              if (response[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: response[0].MSG,
                  ...this.configSuccess,
                });
                this.GetSanctionConditions();

                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: response[0].MSG,
                  ...this.configSuccess,
                });
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: response[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }

  onNext() {
    this.LmsService.SaveNext_Loan({
      Loan_Id: this.loanSummary.LoanId,
      CommandName: "Sanction Condition",
      isStatus: 1,
    }).subscribe((response) => {
      this.action.emit("next");
    });
  }
}
