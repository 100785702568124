import { Component, OnInit, ViewChild, Injectable } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { MasterService } from "../../Shared/app.Masters.Service";
import { ReportsService } from "../services/reports.service";
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DomSanitizer } from "@angular/platform-browser";

// Excel export code start here
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
@Injectable()
// Excel export code end here

@Component({
  selector: "app-crcreport",
  templateUrl: "./crcreport.component.html",
  styleUrls: ["./crcreport.component.scss"],
})
export class CrcreportComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  SlabData: any[] = [];
  DataSource: any;
  JSONData: any[] = [];

  //Filter Parameter
  CRCModel: any = {
    NewCode: "",
    NewShortName: "",
    OldCode: "",
    OldShortName: "",
    CloseFromDate: "",
    ReportDate: "",
    SlabId: "",
  };
  fileUrl;
  tudfString: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = [
    "Id",
    "Customer_Name",
    "Customer_DOB",
    "Gender",
    "Income_Tax_Number",
    "Passport_Number",
    "Passport_IssueDate",
    "Passport_ExpiredDate",
    "Voter_Id_Number",
    "Driving_License_Number",
    "Driving_License_IssueDate",
    "Driving_License_ExpiredDate",
    "Ration_Card_Number",
    "Universal_Id_Number",
    "Additional_Id1_Number",
    "Additional_Id2_Number",
    "Telephone_No_Mobile",
    "Telephone_No_Residence",
    "Telephone_No_Office",
    "Extention_Office",
    "Telephone_No_Other",
    "Email_Id1",
    "Email_Id2",
    "Address1",
    "State_Code1",
    "Pincode1",
    "Address_Category1",
    "Residence_Code1",
    "Address2",
    "State_Code2",
    "Pincode2",
    "Address_Category2",
    "Residence_Code2",
    "New_Member_Code",
    "New_Member_Short_Name",
    "New_Account_Number",
    "New_Account_Type",
    "New_Ownerhip_Indicator",
    "Date_Opened",
    "Date_Of_Last_Payment",
    "Date_Closed",
    "DateReported",
    "High_Credit",
    "Current_Balance",
    "Amount_Overdue",
    "DPD_Days",
    "OldCode",
    "OldShort_Name",
    "Old_Account_Number",
    "Old_Account_Type",
    "Old_Ownerhip_Indicator",
    "Suit_Filed",
    "WriteOff_Settled_Status",
    "Assets_Classification",
    "value_Of_collateral",
    "Collateral_Type",
    "Credit_Limit",
    "Cash_Limit",
    "RateOf_Interest",
    "Repayment_Tenure",
    "EMI_Amount",
    "Writen_Of_Amount",
    "Writen_Of_Principle",
    "Settlement_Amount",
    "Payment_Frequency",
    "Actual_Payment_Amount",
    "Customer_Occupation",
    "Income",
    "Net_Gross_Income_Indicator",
    "Monthly_Annual_Income_Indicator",
    "CKYC",
    "Nrega_Card_Number",
  ];

  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };

  selectedId: any;

  constructor(
    private dataSharingService: DataSharingService,
    private masterService: MasterService,
    private reportsService: ReportsService,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Cibil Consumer");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getSlabsList();
  }

  getSlabsList() {
    this.masterService
      .Get__Slab_dropdown({ Type: "NPA", LoginUserId: this.currentUser.userId })
      .subscribe((res) => {
        this.SlabData = JSON.parse(JSON.stringify(res));
      });
  }

  onSearchCRCReport() {
    this.showSpinner = true;
    this.reportsService
      .Report_Cibil_Consumer(this.CRCModel)
      .subscribe((res: any) => {
        console.log("Result of api call", res);
        if (res.length > 0) {
          this.JSONData = res;
          this.DataSource = new MatTableDataSource(
            JSON.parse(JSON.stringify(res))
          );
          this.DataSource.paginator = this.paginator;
          this.DataSource.sort = this.sort;
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data found",
            ...this.configSuccess,
          });
        }
        this.showSpinner = false;
      });
  }

  // Excel export in backend start here
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    //this.saveAsExcelFile(excelBuffer, excelFileName);

    const data: Blob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      excelFileName + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  renameKey(obj, oldKey, newKey) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }
  splitStringIntoChunks(str: string, size: number): string[] {
    let chunks: string[] = [];
    let words = str.split(" ");
    let currentChunk = "";

    for (let word of words) {
      if (currentChunk.length + word.length + 1 <= size) {
        currentChunk += (currentChunk ? " " : "") + word;
      } else {
        if (currentChunk) {
          chunks.push(currentChunk);
        }
        currentChunk = word;
      }
    }

    if (currentChunk) {
      chunks.push(currentChunk);
    }

    return chunks;
  }
  exportExcel() {
    this.JSONData.forEach((obj: any) => {
      this.renameKey(obj, "Customer_Name", "Consumer Name");
      this.renameKey(obj, "Customer_DOB", "Date of Birth");
      this.renameKey(obj, "Gender", "Gender ");
      this.renameKey(obj, "Income_Tax_Number", "Income Tax ID Number");
      this.renameKey(obj, "Passport_Number", "Passport Number");

      this.renameKey(obj, "Passport_IssueDate", "Passport Issue Date");
      this.renameKey(obj, "Passport_ExpiredDate", "Passport Expiry Date");
      this.renameKey(obj, "Voter_Id_Number", "Voter ID Number");
      this.renameKey(obj, "Driving_License_Number", "Driving License Number");

      this.renameKey(
        obj,
        "Driving_License_IssueDate",
        "Driving License Issue Date"
      );
      this.renameKey(
        obj,
        "Driving_License_ExpiredDate",
        "Driving License Expiry Date"
      );
      this.renameKey(obj, "Ration_Card_Number", "Ration Card Number");
      this.renameKey(obj, "Universal_Id_Number", "Universal ID Number");

      this.renameKey(obj, "Additional_Id1_Number", "Additional ID #1");
      this.renameKey(obj, "Additional_Id2_Number", "Additional ID #2");
      this.renameKey(obj, "Telephone_No_Mobile", "Telephone No.Mobile");
      this.renameKey(obj, "Telephone_No_Residence", "Telephone No.Residence");

      this.renameKey(obj, "Telephone_No_Office", "Telephone No.Office");
      this.renameKey(obj, "Extention_Office", "Extension Office");
      this.renameKey(obj, "Telephone_No_Other", "Telephone No.Other ");
      this.renameKey(obj, "ExtensionOther", "Extension Other");

      this.renameKey(obj, "Email_Id1", "Email ID 1");
      this.renameKey(obj, "Email_Id2", "Email ID 2");
      this.renameKey(obj, "Address1", "Address 1");
      this.renameKey(obj, "State_Code1", "State Code 1");

      this.renameKey(obj, "Pincode1", "PIN Code 1");
      this.renameKey(obj, "Address_Category1", "Address Category 1");
      this.renameKey(obj, "Residence_Code1", "Residence Code 1");
      this.renameKey(obj, "Address2", "Address 2");

      this.renameKey(obj, "State_Code2", "State Code 2");
      this.renameKey(obj, "Pincode2", "PIN Code 2");
      this.renameKey(obj, "Address_Category2", "Address Category 2");
      this.renameKey(obj, "Residence_Code2", "Residence Code 2");

      this.renameKey(obj, "New_Member_Code", "Current/New Member Code");
      this.renameKey(
        obj,
        "New_Member_Short_Name",
        "Current/New Member Short Name"
      );
      this.renameKey(obj, "New_Account_Number", "Curr/New Account No");
      this.renameKey(obj, "New_Account_Type", "Account Type");

      this.renameKey(obj, "New_Ownerhip_Indicator", "Ownership Indicator");
      this.renameKey(obj, "Date_Opened", "Date Opened/Disbursed");
      this.renameKey(obj, "Date_Of_Last_Payment", "Date of Last Payment");
      this.renameKey(obj, "Date_Closed", "Date Closed");

      this.renameKey(obj, "DateReported", "Date Reported");
      this.renameKey(obj, "High_Credit", "High Credit/Sanctioned Amt");
      this.renameKey(obj, "Current_Balance", "Current  Balance");
      this.renameKey(obj, "Amount_Overdue", "Amt Overdue");

      this.renameKey(obj, "DPD_Days", "No of Days Past Due");
      this.renameKey(obj, "OldCode", "Old Mbr Code");
      this.renameKey(obj, "OldShort_Name", "Old Mbr Short Name");
      this.renameKey(obj, "Old_Account_Number", "Old Acc No");

      this.renameKey(obj, "Old_Account_Type", "Old Acc Type");
      this.renameKey(obj, "Old_Ownerhip_Indicator", "Old Ownership Indicator");
      this.renameKey(obj, "Suit_Filed", "Suit Filed / Wilful Default");
      this.renameKey(obj, "WriteOff_Settled_Status", "Credit Facility Status");

      this.renameKey(obj, "Assets_Classification", "Asset Classification");
      this.renameKey(obj, "value_Of_collateral", "Value of Collateral");
      this.renameKey(obj, "Collateral_Type", "Type of Collateral");
      this.renameKey(obj, "Credit_Limit", "Credit Limit");

      this.renameKey(obj, "Cash_Limit", "Cash Limit");
      this.renameKey(obj, "RateOf_Interest", "Rate of Interest");
      this.renameKey(obj, "Repayment_Tenure", "RepaymentTenure");
      this.renameKey(obj, "EMI_Amount", "EMI Amount");

      this.renameKey(obj, "Writen_Of_Amount", "Written- off Amount (Total) ");
      this.renameKey(
        obj,
        "Writen_Of_Principle",
        "Written- off Principal Amount"
      );
      this.renameKey(obj, "Settlement_Amount", "Settlement Amt");
      this.renameKey(obj, "Payment_Frequency", "Payment Frequency");

      this.renameKey(obj, "Actual_Payment_Amount", "Actual Payment Amt");
      this.renameKey(obj, "Customer_Occupation", "Occupation Code");
      this.renameKey(obj, "Income", "Income ");
      this.renameKey(
        obj,
        "Net_Gross_Income_Indicator",
        "Net/Gross Income Indicator"
      );
      this.renameKey(
        obj,
        "Monthly_Annual_Income_Indicator",
        "Monthly/Annual Income Indicator"
      );
      this.renameKey(obj, "CKYC", "CKYC ");
      this.renameKey(obj, "Nrega_Card_Number", "Nrega Card Number ");
      
    });
    this.exportAsExcelFile(this.JSONData, "cibil-consumer");
  }

  public highlightRow(row) {
    var RowIndex = this.DataSource.data.indexOf(row, 0);
    this.selectedId = RowIndex;
  }

  applyFilter(filterValue: string) {
    this.DataSource.filter = filterValue.trim().toLowerCase();
  }
  dowloadTxtFile() {
    this.tudfString = "TUDF12";
    var ReportingPassword = "TEST1234";
    this.tudfString = this.tudfString + this.CRCModel.NewCode.padEnd(30, " ");
    this.tudfString =
      this.tudfString + this.CRCModel.NewShortName.padEnd(16, " ");
    this.tudfString = this.tudfString + "  ";
    this.tudfString =
      this.tudfString + moment(this.CRCModel.ReportDate).format("DDMMYYYY");
    this.tudfString = this.tudfString + ReportingPassword.padEnd(30, " ");
    this.tudfString = this.tudfString + "L";
    this.tudfString = this.tudfString + "00000";
    this.tudfString = this.tudfString + "".padEnd(48, " ");
    for (var i = 0; i < this.JSONData.length; i++) {
      console.log("test ", i);
      this.tudfString = this.tudfString + "PN03N01";
      this.tudfString =
        this.tudfString +
        "01" +
        (Number(this.JSONData[i].Customer_Name.length) <= 9
          ? "0" + this.JSONData[i].Customer_Name.length
          : this.JSONData[i].Customer_Name.length) +
        this.JSONData[i].Customer_Name;
      this.tudfString =
        this.tudfString + "0708" + this.JSONData[i].Customer_DOB;
      this.tudfString = this.tudfString + "0801" + this.JSONData[i].Gender;
      var k = 0;

      if (
        this.JSONData[i].Income_Tax_Number != null &&
        this.JSONData[i].Income_Tax_Number != ""
      ) {
        this.tudfString = this.tudfString + "ID03I0";
        k = k + 1;
        this.tudfString = this.tudfString + k.toString() + "010201";
        this.tudfString =
          this.tudfString +
          "02" +
          (Number(this.JSONData[i].Income_Tax_Number.length) <= 9
            ? "0" + this.JSONData[i].Income_Tax_Number.length
            : this.JSONData[i].Income_Tax_Number.length) +
          this.JSONData[i].Income_Tax_Number;
      }

      if (
        this.JSONData[i].Passport_Number != null &&
        this.JSONData[i].Passport_Number != ""
      ) {
        this.tudfString = this.tudfString + "ID03I0";
        k = k + 1;
        this.tudfString = this.tudfString + k.toString() + "010202";
        this.tudfString =
          this.tudfString +
          "02" +
          (Number(this.JSONData[i].Passport_Number.length) <= 9
            ? "0" + this.JSONData[i].Passport_Number.length
            : this.JSONData[i].Passport_Number.length) +
          this.JSONData[i].Passport_Number;
        //this.tudfString = this.tudfString + '03' + this.JSONData[i].Passport_IssueDate.length + this.JSONData[i].Passport_IssueDate;
        //this.tudfString = this.tudfString + '04' + this.JSONData[i].Passport_ExpiredDate.length + this.JSONData[i].Passport_ExpiredDate;
      }

      if (
        this.JSONData[i].Voter_Id_Number != null &&
        this.JSONData[i].Voter_Id_Number != ""
      ) {
        this.tudfString = this.tudfString + "ID03I0";
        k = k + 1;
        this.tudfString = this.tudfString + k.toString() + "010203";
        this.tudfString =
          this.tudfString +
          "02" +
          (Number(this.JSONData[i].Voter_Id_Number.length) <= 9
            ? "0" + this.JSONData[i].Voter_Id_Number.length
            : this.JSONData[i].Voter_Id_Number.length) +
          this.JSONData[i].Voter_Id_Number;
      }

      if (
        this.JSONData[i].Driving_License_Number != null &&
        this.JSONData[i].Driving_License_Number != ""
      ) {
        this.tudfString = this.tudfString + "ID03I0";
        k = k + 1;
        this.tudfString = this.tudfString + k.toString() + "010204";
        this.tudfString =
          this.tudfString +
          "02" +
          (Number(this.JSONData[i].Driving_License_Number.length) <= 9
            ? "0" + this.JSONData[i].Driving_License_Number.length
            : this.JSONData[i].Driving_License_Number.length) +
          this.JSONData[i].Driving_License_Number;
        // this.tudfString = this.tudfString + '03' + this.JSONData[i].Driving_License_IssueDate.length + this.JSONData[i].Driving_License_IssueDate;
        // this.tudfString = this.tudfString + '04' + this.JSONData[i].Driving_License_ExpiredDate.length + this.JSONData[i].Driving_License_ExpiredDate;
      }

      if (
        this.JSONData[i].Ration_Card_Number != null &&
        this.JSONData[i].Ration_Card_Number != ""
      ) {
        this.tudfString = this.tudfString + "ID03I0";
        k = k + 1;
        this.tudfString = this.tudfString + k.toString() + "010205";
        this.tudfString =
          this.tudfString +
          "02" +
          (Number(this.JSONData[i].Ration_Card_Number.length) <= 9
            ? "0" + this.JSONData[i].Ration_Card_Number.length
            : this.JSONData[i].Ration_Card_Number.length) +
          this.JSONData[i].Ration_Card_Number;
      }

      if (
        this.JSONData[i].Universal_Id_Number != null &&
        this.JSONData[i].Universal_Id_Number != ""
      ) {
        this.tudfString = this.tudfString + "ID03I0";
        k = k + 1;
        this.tudfString = this.tudfString + k.toString() + "010206";
        this.tudfString =
          this.tudfString +
          "02" +
          (Number(this.JSONData[i].Universal_Id_Number.length) <= 9
            ? "0" + this.JSONData[i].Universal_Id_Number.length
            : this.JSONData[i].Universal_Id_Number.length) +
          this.JSONData[i].Universal_Id_Number;
      }
      this.tudfString = this.tudfString + "PT03T01";
      this.tudfString =
        this.tudfString +
        "01" +
        (Number(this.JSONData[i].Telephone_No_Mobile.length) <= 9
          ? "0" + this.JSONData[i].Telephone_No_Mobile.length
          : this.JSONData[i].Telephone_No_Mobile.length) +
        this.JSONData[i].Telephone_No_Mobile;
      this.tudfString = this.tudfString + "030200";
      if (
        this.JSONData[i].Telephone_No_Other != null &&
        this.JSONData[i].Telephone_No_Other != ""
      ) {
        this.tudfString = this.tudfString + "PT03T02";
        this.tudfString =
          this.tudfString +
          "01" +
          (Number(this.JSONData[i].Telephone_No_Other.length) <= 9
            ? "0" + this.JSONData[i].Telephone_No_Other.length
            : this.JSONData[i].Telephone_No_Other.length) +
          this.JSONData[i].Telephone_No_Other;
        this.tudfString = this.tudfString + "030200";
      }
      if (
        this.JSONData[i].Email_Id1 != null &&
        this.JSONData[i].Email_Id1 != ""
      ) {
        this.tudfString = this.tudfString + "EC03C01";
        this.tudfString =
          this.tudfString +
          "01" +
          (Number(this.JSONData[i].Email_Id1.length) <= 9
            ? "0" + this.JSONData[i].Email_Id1.length
            : this.JSONData[i].Email_Id1.length) +
          this.JSONData[i].Email_Id1;
      }

      var chunks = this.splitStringIntoChunks(this.JSONData[i].Address1, 35);
      if (chunks[0] != undefined) {
        this.tudfString = this.tudfString + "PA03A01";
        this.tudfString =
          this.tudfString +
          "01" +
          (Number(chunks[0].length) <= 9
            ? "0" + chunks[0].length
            : chunks[0].length) +
          chunks[0];
      }
      if (chunks[1] != undefined) {
        this.tudfString =
          this.tudfString +
          "02" +
          (Number(chunks[1].length) <= 9
            ? "0" + chunks[1].length
            : chunks[1].length) +
          chunks[1];
      }
      if (chunks[2] != undefined) {
        this.tudfString =
          this.tudfString +
          "03" +
          (Number(chunks[1].length) <= 9
            ? "0" + chunks[1].length
            : chunks[1].length) +
          chunks[2];
      }
      if (chunks[3] != undefined) {
        this.tudfString =
          this.tudfString +
          "04" +
          (Number(chunks[1].length) <= 9
            ? "0" + chunks[1].length
            : chunks[1].length) +
          chunks[3];
      }
      if (chunks[4] != undefined) {
        this.tudfString =
          this.tudfString +
          "05" +
          (Number(chunks[1].length) <= 9
            ? "0" + chunks[1].length
            : chunks[1].length) +
          chunks[4];
      }
      this.tudfString =
        this.tudfString +
        "06" +
        (Number(this.JSONData[i].State_Code1.length) <= 9
          ? "0" + this.JSONData[i].State_Code1.length
          : this.JSONData[i].State_Code1.length) +
        this.JSONData[i].State_Code1;
      this.tudfString =
        this.tudfString +
        "07" +
        (Number(this.JSONData[i].Pincode1.length) <= 9
          ? "0" + this.JSONData[i].Pincode1.length
          : this.JSONData[i].Pincode1.length) +
        this.JSONData[i].Pincode1;
      this.tudfString =
        this.tudfString +
        "08" +
        (Number(this.JSONData[i].Address_Category1.length) <= 9
          ? "0" + this.JSONData[i].Address_Category1.length
          : this.JSONData[i].Address_Category1.length) +
        this.JSONData[i].Address_Category1;
      this.tudfString =
        this.tudfString +
        "09" +
        (Number(this.JSONData[i].Residence_Code1.length) <= 9
          ? "0" + this.JSONData[i].Residence_Code1.length
          : this.JSONData[i].Residence_Code1.length) +
        this.JSONData[i].Residence_Code1;

      var chunks1 = this.splitStringIntoChunks(this.JSONData[i].Address2, 35);
      if (chunks1[0] != undefined) {
        this.tudfString = this.tudfString + "PA03A02";
        this.tudfString =
          this.tudfString +
          "01" +
          (Number(chunks1[0].length) <= 9
            ? "0" + chunks1[0].length
            : chunks1[0].length) +
          chunks1[0];
      }
      if (chunks1[1] != undefined) {
        this.tudfString =
          this.tudfString +
          "02" +
          (Number(chunks1[1].length) <= 9
            ? "0" + chunks1[1].length
            : chunks1[1].length) +
          chunks1[1];
      }
      if (chunks1[2] != undefined) {
        this.tudfString =
          this.tudfString +
          "03" +
          (Number(chunks1[1].length) <= 9
            ? "0" + chunks1[1].length
            : chunks1[1].length) +
          chunks1[2];
      }
      if (chunks1[3] != undefined) {
        this.tudfString =
          this.tudfString +
          "04" +
          (Number(chunks1[1].length) <= 9
            ? "0" + chunks1[1].length
            : chunks1[1].length) +
          chunks1[3];
      }
      if (chunks1[4] != undefined) {
        this.tudfString =
          this.tudfString +
          "05" +
          (Number(chunks1[1].length) <= 9
            ? "0" + chunks1[1].length
            : chunks1[1].length) +
          chunks1[4];
      }
      this.tudfString =
        this.tudfString +
        "06" +
        (Number(this.JSONData[i].State_Code2.length) <= 9
          ? "0" + this.JSONData[i].State_Code2.length
          : this.JSONData[i].State_Code2.length) +
        this.JSONData[i].State_Code2;
      this.tudfString =
        this.tudfString +
        "07" +
        (Number(this.JSONData[i].Pincode2.length) <= 9
          ? "0" + this.JSONData[i].Pincode2.length
          : this.JSONData[i].Pincode2.length) +
        this.JSONData[i].Pincode2;
      this.tudfString =
        this.tudfString +
        "08" +
        (Number(this.JSONData[i].Address_Category2.length) <= 9
          ? "0" + this.JSONData[i].Address_Category2.length
          : this.JSONData[i].Address_Category2.length) +
        this.JSONData[i].Address_Category2;
      this.tudfString =
        this.tudfString +
        "09" +
        (Number(this.JSONData[i].Residence_Code2.length) <= 9
          ? "0" + this.JSONData[i].Residence_Code2.length
          : this.JSONData[i].Residence_Code2.length) +
        this.JSONData[i].Residence_Code2;

      this.tudfString = this.tudfString + "TL04T001";
      this.tudfString =
        this.tudfString +
        "01" +
        (Number(this.JSONData[i].New_Member_Code.length) <= 9
          ? "0" + this.JSONData[i].New_Member_Code.length
          : this.JSONData[i].New_Member_Code.length) +
        this.JSONData[i].New_Member_Code;
      this.tudfString =
        this.tudfString +
        "02" +
        (Number(this.JSONData[i].New_Member_Short_Name.length) <= 9
          ? "0" + this.JSONData[i].New_Member_Short_Name.length
          : this.JSONData[i].New_Member_Short_Name.length) +
        this.JSONData[i].New_Member_Short_Name;
      this.tudfString =
        this.tudfString +
        "03" +
        (Number(this.JSONData[i].New_Account_Number.length) <= 9
          ? "0" + this.JSONData[i].New_Account_Number.length
          : this.JSONData[i].New_Account_Number.length) +
        this.JSONData[i].New_Account_Number;
      this.tudfString =
        this.tudfString +
        "04" +
        (Number(this.JSONData[i].New_Account_Type.length) <= 9
          ? "0" + this.JSONData[i].New_Account_Type.length
          : this.JSONData[i].New_Account_Type.length) +
        this.JSONData[i].New_Account_Type;

      this.tudfString =
        this.tudfString +
        "05" +
        (Number(this.JSONData[i].New_Ownerhip_Indicator.length) <= 9
        ? "0" + this.JSONData[i].New_Ownerhip_Indicator.length
        : this.JSONData[i].New_Ownerhip_Indicator.length) +
        this.JSONData[i].New_Ownerhip_Indicator;

      this.tudfString =
        this.tudfString +
        "08" +
        (Number(this.JSONData[i].Date_Opened.length) <= 9
          ? "0" + this.JSONData[i].Date_Opened.length
          : this.JSONData[i].Date_Opened.length) +
        this.JSONData[i].Date_Opened;
      if (
        this.JSONData[i].Date_Of_Last_Payment != null &&
        this.JSONData[i].Date_Of_Last_Payment != ""
      ) {
        this.tudfString =
          this.tudfString +
          "09" +
          (Number(this.JSONData[i].Date_Of_Last_Payment.length) <= 9
            ? "0" + this.JSONData[i].Date_Of_Last_Payment.length
            : this.JSONData[i].Date_Of_Last_Payment.length) +
          this.JSONData[i].Date_Of_Last_Payment;
      }
      if (
        this.JSONData[i].Date_Closed != null &&
        this.JSONData[i].Date_Closed != ""
      ) {
        this.tudfString =
          this.tudfString +
          "10" +
          (Number(this.JSONData[i].Date_Closed.length) <= 9
            ? "0" + this.JSONData[i].Date_Closed.length
            : this.JSONData[i].Date_Closed.length) +
          this.JSONData[i].Date_Closed;
      }
      this.tudfString =
        this.tudfString +
        "11" +
        (Number(this.JSONData[i].DateReported.length) <= 9
          ? "0" + this.JSONData[i].DateReported.length
          : this.JSONData[i].DateReported.length) +
        this.JSONData[i].DateReported;

      this.tudfString =
        this.tudfString +
        "12" +
        (Number(this.JSONData[i].High_Credit.length) <= 9
          ? "0" + this.JSONData[i].High_Credit.length
          : this.JSONData[i].High_Credit.length) +
        this.JSONData[i].High_Credit;
      this.tudfString =
        this.tudfString +
        "13" +
        (Number(this.JSONData[i].Current_Balance.length) <= 9
          ? "0" + this.JSONData[i].Current_Balance.length
          : this.JSONData[i].Current_Balance.length) +
        this.JSONData[i].Current_Balance;
      this.tudfString =
        this.tudfString +
        "14" +
        (Number(this.JSONData[i].Amount_Overdue.length) <= 9
          ? "0" + this.JSONData[i].Amount_Overdue.length
          : this.JSONData[i].Amount_Overdue.length) +
        this.JSONData[i].Amount_Overdue;
      this.tudfString =
        this.tudfString +
        "15" +
        (Number(this.JSONData[i].DPD_Days.length) <= 9
          ? "0" + this.JSONData[i].DPD_Days.length
          : this.JSONData[i].DPD_Days.length) +
        this.JSONData[i].DPD_Days;

      if (this.JSONData[i].OldCode != null && this.JSONData[i].OldCode != "") {
        this.tudfString =
          this.tudfString +
          "16" +
          (Number(this.JSONData[i].OldCode.length) <= 9
            ? "0" + this.JSONData[i].OldCode.length
            : this.JSONData[i].OldCode.length) +
          this.JSONData[i].OldCode;
      }
      if (
        this.JSONData[i].OldShort_Name != null &&
        this.JSONData[i].OldShort_Name != ""
      ) {
        this.tudfString =
          this.tudfString +
          "17" +
          (Number(this.JSONData[i].OldShort_Name.length) <= 9
            ? "0" + this.JSONData[i].OldShort_Name.length
            : this.JSONData[i].OldShort_Name.length) +
          this.JSONData[i].OldShort_Name;
      }
      if (
        this.JSONData[i].Old_Account_Number != null &&
        this.JSONData[i].Old_Account_Number != ""
      ) {
        this.tudfString =
          this.tudfString +
          "18" +
          (Number(this.JSONData[i].Old_Account_Number.length) <= 9
            ? "0" + this.JSONData[i].Old_Account_Number.length
            : this.JSONData[i].Old_Account_Number.length) +
          this.JSONData[i].Old_Account_Number;
      }
      if (
        this.JSONData[i].Old_Account_Type != null &&
        this.JSONData[i].Old_Account_Type != ""
      ) {
        this.tudfString =
          this.tudfString +
          "19" +
          (Number(this.JSONData[i].Old_Account_Type.length) <= 9
            ? "0" + this.JSONData[i].Old_Account_Type.length
            : this.JSONData[i].Old_Account_Type.length) +
          this.JSONData[i].Old_Account_Type;
      }
      if (
        this.JSONData[i].Old_Ownerhip_Indicator != null &&
        this.JSONData[i].Old_Ownerhip_Indicator != ""
      ) {
        this.tudfString =
          this.tudfString +
          "20" +
          (Number(this.JSONData[i].Old_Ownerhip_Indicator.length) <= 9
            ? "0" + this.JSONData[i].Old_Ownerhip_Indicator.length
            : this.JSONData[i].Old_Ownerhip_Indicator.length) +
          this.JSONData[i].Old_Ownerhip_Indicator;
      }

      if (
        this.JSONData[i].Suit_Filed != null &&
        this.JSONData[i].Suit_Filed != ""
      ) {
        this.tudfString =
          this.tudfString +
          "21" +
          (Number(this.JSONData[i].Suit_Filed.length) <= 9
            ? "0" + this.JSONData[i].Suit_Filed.length
            : this.JSONData[i].Suit_Filed.length) +
          this.JSONData[i].Suit_Filed;
      }
      if (
        this.JSONData[i].WriteOff_Settled_Status != null &&
        this.JSONData[i].WriteOff_Settled_Status != ""
      ) {
        this.tudfString =
          this.tudfString +
          "22" +
          (Number(this.JSONData[i].WriteOff_Settled_Status.length) <= 9
            ? "0" + this.JSONData[i].WriteOff_Settled_Status.length
            : this.JSONData[i].WriteOff_Settled_Status.length) +
          this.JSONData[i].WriteOff_Settled_Status;
      }

      if (
        this.JSONData[i].Assets_Classification != null &&
        this.JSONData[i].Assets_Classification != ""
      ) {
        this.tudfString =
          this.tudfString +
          "26" +
          (Number(this.JSONData[i].Assets_Classification.length) <= 9
            ? "0" + this.JSONData[i].Assets_Classification.length
            : this.JSONData[i].Assets_Classification.length) +
          this.JSONData[i].Assets_Classification;
      }

      if (
        this.JSONData[i].value_Of_collateral != null &&
        this.JSONData[i].value_Of_collateral != ""
      ) {
        this.tudfString =
          this.tudfString +
          "34" +
          (Number(this.JSONData[i].value_Of_collateral.length) <= 9
            ? "0" + this.JSONData[i].value_Of_collateral.length
            : this.JSONData[i].value_Of_collateral.length) +
          this.JSONData[i].value_Of_collateral;
      }
      if (
        this.JSONData[i].Collateral_Type != null &&
        this.JSONData[i].Collateral_Type != ""
      ) {
        this.tudfString =
          this.tudfString +
          "35" +
          (Number(this.JSONData[i].Collateral_Type.length) <= 9
            ? "0" + this.JSONData[i].Collateral_Type.length
            : this.JSONData[i].Collateral_Type.length) +
          this.JSONData[i].Collateral_Type;
      }
      if (
        this.JSONData[i].Credit_Limit != null &&
        this.JSONData[i].Credit_Limit != ""
      ) {
        this.tudfString =
          this.tudfString +
          "36" +
          (Number(this.JSONData[i].Credit_Limit.length) <= 9
            ? "0" + this.JSONData[i].Credit_Limit.length
            : this.JSONData[i].Credit_Limit.length) +
          this.JSONData[i].Credit_Limit;
      }
      if (
        this.JSONData[i].Cash_Limit != null &&
        this.JSONData[i].Cash_Limit != ""
      ) {
        this.tudfString =
          this.tudfString +
          "37" +
          (Number(this.JSONData[i].Cash_Limit.length) <= 9
            ? "0" + this.JSONData[i].Cash_Limit.length
            : this.JSONData[i].Cash_Limit.length) +
          this.JSONData[i].Cash_Limit;
      }

      if (
        this.JSONData[i].RateOf_Interest != null &&
        this.JSONData[i].RateOf_Interest != ""
      ) {
        this.tudfString =
          this.tudfString +
          "38" +
          (Number(this.JSONData[i].RateOf_Interest.length) <= 9
            ? "0" + this.JSONData[i].RateOf_Interest.length
            : this.JSONData[i].RateOf_Interest.length) +
          this.JSONData[i].RateOf_Interest;
      }
      if (
        this.JSONData[i].Repayment_Tenure != null &&
        this.JSONData[i].Repayment_Tenure != ""
      ) {
        this.tudfString =
          this.tudfString +
          "39" +
          (Number(this.JSONData[i].Repayment_Tenure.length) <= 9
            ? "0" + this.JSONData[i].Repayment_Tenure.length
            : this.JSONData[i].Repayment_Tenure.length) +
          this.JSONData[i].Repayment_Tenure;
      }
      if (
        this.JSONData[i].EMI_Amount != null &&
        this.JSONData[i].EMI_Amount != ""
      ) {
        this.tudfString =
          this.tudfString +
          "40" +
          (Number(this.JSONData[i].EMI_Amount.length) <= 9
            ? "0" + this.JSONData[i].EMI_Amount.length
            : this.JSONData[i].EMI_Amount.length) +
          this.JSONData[i].EMI_Amount;
      }
      if (
        this.JSONData[i].Writen_Of_Amount != null &&
        this.JSONData[i].Writen_Of_Amount != ""
      ) {
        this.tudfString =
          this.tudfString +
          "41" +
          (Number(this.JSONData[i].Writen_Of_Amount.length) <= 9
            ? "0" + this.JSONData[i].Writen_Of_Amount.length
            : this.JSONData[i].Writen_Of_Amount.length) +
          this.JSONData[i].Writen_Of_Amount;
      }
      if (
        this.JSONData[i].Writen_Of_Principle != null &&
        this.JSONData[i].Writen_Of_Principle != ""
      ) {
        this.tudfString =
          this.tudfString +
          "42" +
          (Number(this.JSONData[i].Writen_Of_Principle.length) <= 9
            ? "0" + this.JSONData[i].Writen_Of_Principle.length
            : this.JSONData[i].Writen_Of_Principle.length) +
          this.JSONData[i].Writen_Of_Principle;
      }

      if (
        this.JSONData[i].Settlement_Amount != null &&
        this.JSONData[i].Settlement_Amount != ""
      ) {
        this.tudfString =
          this.tudfString +
          "43" +
          (Number(this.JSONData[i].Settlement_Amount.length) <= 9
            ? "0" + this.JSONData[i].Settlement_Amount.length
            : this.JSONData[i].Settlement_Amount.length) +
          this.JSONData[i].Settlement_Amount;
      }
      if (
        this.JSONData[i].Payment_Frequency != null &&
        this.JSONData[i].Payment_Frequency != ""
      ) {
        this.tudfString =
          this.tudfString +
          "44" +
          (Number(this.JSONData[i].Payment_Frequency.length) <= 9
            ? "0" + this.JSONData[i].Payment_Frequency.length
            : this.JSONData[i].Payment_Frequency.length) +
          this.JSONData[i].Payment_Frequency;
      }
      if (
        this.JSONData[i].Actual_Payment_Amount != null &&
        this.JSONData[i].Actual_Payment_Amount != ""
      ) {
        this.tudfString =
          this.tudfString +
          "45" +
          (Number(this.JSONData[i].Actual_Payment_Amount.length) <= 9
            ? "0" + this.JSONData[i].Actual_Payment_Amount.length
            : this.JSONData[i].Actual_Payment_Amount.length) +
          this.JSONData[i].Actual_Payment_Amount;
      }
      if (
        this.JSONData[i].Customer_Occupation != null &&
        this.JSONData[i].Customer_Occupation != ""
      ) {
        this.tudfString =
          this.tudfString +
          "46" +
          (Number(this.JSONData[i].Customer_Occupation.length) <= 9
            ? "0" + this.JSONData[i].Customer_Occupation.length
            : this.JSONData[i].Customer_Occupation.length) +
          this.JSONData[i].Customer_Occupation;
      }
      if (this.JSONData[i].Income != null && this.JSONData[i].Income != "") {
        this.tudfString =
          this.tudfString +
          "47" +
          (Number(this.JSONData[i].Income.length) <= 9
            ? "0" + this.JSONData[i].Income.length
            : this.JSONData[i].Income.length) +
          this.JSONData[i].Income;
      }
      if (
        this.JSONData[i].Net_Gross_Income_Indicator != null &&
        this.JSONData[i].Net_Gross_Income_Indicator != ""
      ) {
        this.tudfString =
          this.tudfString +
          "48" +
          (Number(this.JSONData[i].Net_Gross_Income_Indicator.length) <= 9
            ? "0" + this.JSONData[i].Net_Gross_Income_Indicator.length
            : this.JSONData[i].Net_Gross_Income_Indicator.length) +
          this.JSONData[i].Net_Gross_Income_Indicator;
      }
      if (
        this.JSONData[i].Monthly_Annual_Income_Indicator != null &&
        this.JSONData[i].Monthly_Annual_Income_Indicator != ""
      ) {
        this.tudfString =
          this.tudfString +
          "49" +
          (Number(this.JSONData[i].Monthly_Annual_Income_Indicator.length) <= 9
            ? "0" + this.JSONData[i].Monthly_Annual_Income_Indicator.length
            : this.JSONData[i].Monthly_Annual_Income_Indicator.length) +
          this.JSONData[i].Monthly_Annual_Income_Indicator;
      }
      this.tudfString = this.tudfString + "ES02**";
    }

    this.tudfString = this.tudfString + "TRLR";
    console.log("tudf", this.tudfString);
    const blob = new Blob([this.tudfString], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "TUDFFile";
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}
